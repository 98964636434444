import {ModelFactory} from "@shared/factories/model.factory";
import {OfficerCompanyType} from "@generated/graphql";
import {OfficerCompany} from "@modules/officer/models/officer-company.model";

export class OfficerCompanyFactory extends ModelFactory<OfficerCompanyType, OfficerCompany> {
  create(state: OfficerCompanyType): OfficerCompany {
    return new OfficerCompany(state);
  }

  fake(): OfficerCompany {
    throw new Error('This method is not implemented')
  }
}

export const officerCompanyFactory = new OfficerCompanyFactory()
