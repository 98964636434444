import {Component, Input, OnInit} from '@angular/core';
import {Job} from "@modules/job/models/job.model";
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-change-fye-date-info',
  templateUrl: './change-fye-date-info.component.html',
  styleUrls: ['./change-fye-date-info.component.scss']
})
export class ChangeFyeDateInfoComponent implements OnInit {
  @Input() public job: Job;

  constructor() { }

  ngOnInit(): void {
  }
}
