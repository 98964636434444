import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BaseLayoutComponent} from '@shared/layout/base-layout/base-layout.component';
import {AuthGuard} from '@modules/auth/guards/auth.guard';
import {MainLayoutComponent} from '@shared/layout/main-layout/main-layout.component';
import {RoleEnum} from '@generated/graphql';
import {RoleGuard} from '@modules/auth/guards/role.guard';
import {Routes} from '@shared/types/route.type';
import {ActiveUserResolver} from '@modules/auth/resolvers/active-user.resolver';
import {WireTransferBanksResolver} from "@modules/wire-transfer-bank/resolvers/wire-transfer-banks.resolver";
import {PaymentProviderResolver} from '@modules/payment/resolvers/payment-provider.resolver';
import {ActiveBusinessPlanResolver} from "@modules/account-settings/pages/resolvers/active-business-plan.resolver";
import {PaymentGuard} from "@modules/payment/guards/payment.guard";
import {OfficerRequiredDocumentsResolver} from "@modules/officer/resolvers/officer-required-documents.resolver";
import {AuthLayoutComponent} from "@shared/layout/auth-layout/auth-layout.component";
import {AddressTypesResolver} from "@modules/address/resolvers/address-types.resolver";


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    component: MainLayoutComponent,
    path: '',
    resolve: {
      //TODO Will implement event to refresh new auth.
      activeUser: ActiveUserResolver,
      wireTransferBanks: WireTransferBanksResolver,
      paymentProviders: PaymentProviderResolver,
      officerRequiredDocuments: OfficerRequiredDocumentsResolver,
      businessPlan: ActiveBusinessPlanResolver,
      addressTypes: AddressTypesResolver
    },
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app/modules/dashboard/dashboard.module').then((e) => e.DashboardModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app/modules/dashboard/dashboard.module').then((e) => e.DashboardModule),
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app/modules/account-settings/account-settings.module').then(e => e.AccountSettingsModule),
      },
      {
        path: 'team',
        canActivate: [RoleGuard],
        data: {
          roles: [RoleEnum.SuperAdmin, RoleEnum.Admin],
        },
        // loadChildren: () => import('@modules/team/team.module').then((e) => e.TeamModule),
        loadComponent: () => import('@modules/user/pages/corporate-experts-page/corporate-experts-page.component').then(e => e.CorporateExpertsPageComponent),
      },
      {
        path: 'customers',
        canActivate: [RoleGuard],
        data: {
          roles: [RoleEnum.SuperAdmin, RoleEnum.Admin, RoleEnum.Expert],
        },
        loadComponent: () => import('@modules/user/pages/customers-page/customers-page.component').then(e => e.CustomersPageComponent),
      },
      {
        path: 'job',
        canActivate: [AuthGuard],
        loadChildren: () => import('@modules/job/job.module').then((e) => e.JobModule),
      },
      {
        path: 'companies',
        canActivate: [AuthGuard],
        loadChildren: () => import('@modules/company/company.module').then((e) => e.CompanyModule),
      },
      {
        path: 'payments',
        canActivate: [RoleGuard, PaymentGuard],
        canActivateChild: [PaymentGuard],
        data: {
          roles: [RoleEnum.SuperAdmin, RoleEnum.Admin, RoleEnum.Customer],
        },
        loadChildren: () => import('@modules/payment/payment.module').then((e) => e.PaymentModule),
      },
    ],
  },
  {
    path: '**',
    component: BaseLayoutComponent,
    loadChildren: () => import('@modules/error/error.module').then((e) => e.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
