import { ModelFactory } from '@shared/factories/model.factory';
import { JobDetailType } from '@generated/graphql';
import { JobDetail } from '@modules/job/models/job-detail.model';

export class JobDetailFactory extends ModelFactory<JobDetailType, JobDetail> {
  create (state: JobDetailType): JobDetail {
    return new JobDetail(state);
  }

  fake (): JobDetail {
    throw new Error('Method not implemented');
  }
}

export const jobDetailFactory = new JobDetailFactory()
