import { Injectable } from '@angular/core';
import { SnackBarService } from "@shared/services/snack-bar.service";
import { ErrorService } from "@shared/services/error.service";
import { BehaviorSubject, Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";

@Injectable()
export class CommonService {
  protected readonly _loading$ = new BehaviorSubject(false)

  get loading$ (): Observable<boolean> {
    return this._loading$.asObservable()
  }

  constructor (
    public readonly snackBarService: SnackBarService,
    public readonly errorService: ErrorService,
    public readonly dialog: MatDialog,
    public readonly fb: FormBuilder
  ) {
  }

  setLoading (value: boolean): void {
    this._loading$.next(value);
  }
}
