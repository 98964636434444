import {BaseModel} from "@shared/models/base.model";
import {CompanyShareholderType, OfficerDocumentStatusEnum, ShareholdingDetailType} from "@generated/graphql";
import {Company} from "@modules/company/models/company.model";
import {companyFactory} from "@modules/company/factories/company.factory";
import {officerFactory} from "@modules/officer/factories/officer.factory";
import {Officer} from "@modules/officer/models/officer.model";
import {startCase} from "lodash-es";
import {AppStatusType} from "@shared/component/common/status/status.component";
import {CompanyIndirectShareholder} from "@modules/company/models/company-indirect-shareholder.model";
import {companyIndirectShareholderFactory} from "@modules/company/factories/company-indirect-shareholder.factory";

export class CompanyShareholder extends BaseModel implements CompanyShareholderType {
  //TODO Should get this value from BE
  static readonly UBO = 0.25

  id: string;
  company: Company;
  canUploadDocument: boolean;
  canDeleteDocument: boolean;
  officer: Officer;
  canCreateShareholder: boolean
  isUBO: boolean
  isNominee: boolean;
  ownership: number;
  cessationDate: Date;
  effectiveDate: Date;
  companyId: string;
  documentsStatus: OfficerDocumentStatusEnum;
  shareholders: CompanyIndirectShareholder[];
  isRequiredKyc: boolean
  canViewShareholders: boolean
  remainingPercentageOfShares: number
  remainingPercentageOfSharesForUpdating: number
  shareholdingDetails: ShareholdingDetailType[]
  _model: string;
  __typename: 'CompanyShareholderType';

  constructor(state: CompanyShareholderType) {
    super(state);

    this.officer = officerFactory.tryCreating(state.officer)

    this.company = companyFactory.tryCreating(state.company)

    this.shareholders = companyIndirectShareholderFactory.createEntities(state.shareholders)

    this.cessationDate = state.cessationDate ? new Date(state.cessationDate) : null;

    this.effectiveDate = state.effectiveDate ? new Date(state.effectiveDate) : null;
  }

  get documentStatusLabel(): string {
    return startCase(this.documentsStatus.toLowerCase());
  }

  get documentsStatusStyle(): AppStatusType {
    switch (this.documentsStatus) {
      case OfficerDocumentStatusEnum.AwaitingReview: {
        return 'warn';
      }
      case OfficerDocumentStatusEnum.Verified: {
        return 'success';
      }
      case OfficerDocumentStatusEnum.Rejected: {
        return 'error';
      }
      default:
        return 'default';
    }
  }

  get title(): string {
    return 'Shareholder'
  }

  get name(): string {
    return this.isNominee ? 'Nominee' : this.officer.name;
  }

  get renderOfficerRole(): string {
    return 'Shareholder'
  }

  get corporateShareholders(): CompanyIndirectShareholder[] {
    return this.shareholders.filter(item => item.officer.officerProfile.__typename === 'OfficerIndirectCompanyType')
  }

  get individualShareholders(): CompanyIndirectShareholder[] {
    return this.shareholders.filter(item => item.officer.officerProfile.__typename === 'OfficerIndirectPersonType')
  }

  getModelName(): string {
    return CompanyShareholder.name;
  }
}
