import {PaymentUserType} from "@generated/graphql";
import {InjectionToken, Provider} from "@angular/core";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import {PaymentUser} from "@modules/payment/models/payment-user.model";

export class PaymentUserStore extends EntityStore<EntityFeatureNameEnum.PaymentUser, PaymentUserType, PaymentUser> {
  get selectState(): SelectState<PaymentUserType> {
    return this.feature.selectPaymentUserFeatureState;
  }

  getEntry(): EntityEntry<EntityFeatureNameEnum.PaymentUser> {
    return 'PAYMENT_USER'
  }

  getFeatureName(): EntityFeatureName<EntityFeatureNameEnum.PaymentUser> {
    return 'paymentUserFeature';
  }
}

export const paymentUserStore = new PaymentUserStore();

export const PAYMENT_USER_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.PaymentUserStore);

export const PAYMENT_USER_STORE_CONFIG: Provider = {
  provide: PAYMENT_USER_STORE,
  useValue: paymentUserStore,
};
