import { Country } from '@app/modules/country/models/country.model';
import { ProfileType, SalutationEnum } from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';
import { countryFactory } from '@modules/country/factories/country.factory';

export class Profile extends BaseModel implements ProfileType {
  id: string;
  avatar?: string;
  avatarUrl?: string;
  salutation: SalutationEnum;
  nationality: Country;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  apartment: string;
  city: string;
  state: string;
  zip: string;
  country?: Country;
  languages: string;
  responsibilities: Country[];
  createdAt: Date;
  updatedAt: Date;

  constructor(state: ProfileType) {
    super(state);

    this.responsibilities = state.responsibilities
      ? state.responsibilities.map((item) => countryFactory.create(item))
      : [];
  }

  get fullName() {
    return [this.firstName, this.lastName].filter((item) => item).join(' ');
  }

  getModelName(): string {
    return Profile.name;
  }
}
