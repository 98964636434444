import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JobObjectDetail} from '@modules/job/models/job-object-detail.model';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {IncorporateTypeEnum, JobMatterEnum, ServiceEnum,} from '@generated/graphql';
import {CountryFlagComponent} from '@shared/component/common/country-flag/country-flag.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {Observable, tap} from 'rxjs';
import {Country} from '@modules/country/models/country.model';
import {CountryService} from '@modules/country/services/country.service';
import {SharedComponentModule} from '@shared/component/component.module';
import {CompanyIncorporationManagement} from '@app/modules/job/classes/company-incorporation-management.class';
import {Job} from '@app/modules/job/models/job.model';
import {startCase} from "lodash-es";
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {User} from "@modules/user/models/user.model";
import {LetModule} from "@ngrx/component";
import {
  KYCReminderMessageComponent
} from "@shared/component/message/kyc-reminder-message/kyc-reminder-message.component";
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";

@Component({
  selector: 'app-company-incorporation-info',
  standalone: true,
  imports: [
    CommonModule,
    SectionWpComponent,
    CountryFlagComponent,
    SharedComponentModule,
    CalloutComponent,
    LetModule,
    KYCReminderMessageComponent,
  ],
  templateUrl: './company-incorporation-info.component.html',
  styleUrls: ['./company-incorporation-info.component.scss'],
})
export class CompanyIncorporationInfoComponent implements OnInit {
  @Input() public job: Job;

  private _jobDetail: JobObjectDetail;

  private country$: Observable<Country>;

  businessProfile$ = this.businessProfileService.activeEntity$

  get jobDetail() {
    return this.job.detailObject;
  }

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    public companyIncorporationManagement: CompanyIncorporationManagement,
    public readonly businessProfileService: BusinessProfileService,
    protected readonly commonHelper: CommonHelper,
    private readonly countryService: CountryService,
  ) {
  }

  ngOnInit(): void {
    this.companyIncorporationManagement.type = this.isIncorporateRequest()
      ? IncorporateTypeEnum.Register
      : IncorporateTypeEnum.Transfer;

    this.companyIncorporationManagement.countryOfIncorporation =
      this.job.supportedCountry;

    this.companyIncorporationManagement.orderServiceManagement.incorporationServices =
      this.job.detailObject.jobServiceOrder.mainServices.map((s) => s.service);

    this.companyIncorporationManagement.createForm().addInitialService();

    this.companyIncorporationManagement.setDataFromJobInput(
      this.job.detailObject.input,
    );
  }

  public isIncorporateRequest() {
    return (
      this.job.service.name === ServiceEnum.CompanyRegistration ||
      this.job.service.name === ServiceEnum.TransferCompany
    );
  }

  public isRegisterCompany() {
    const {matter} = this.jobDetail.input.jobInput;
    return matter === startCase(JobMatterEnum.RegisterCompany);
  }

  public isTransferCompany() {
    const {matter} = this.jobDetail.input.jobInput;
    return matter === startCase(JobMatterEnum.TransferCompany);
  }

  public get companyInput() {
    return this.jobDetail.input.companyInput;
  }

  public renderRegisteredAddress(): string {
    const {address, apartment, zip, city, state, countryId} =
      this.companyInput.registeredAddress;
    let country: Country;
    this.countryService
      .find$(countryId)
      .pipe(tap((value) => (country = value)))
      .subscribe();
    return [apartment, address, city, state, country.name, zip]
      .filter(Boolean)
      .join(', ');
  }
}
