import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ConfirmPaymentData, loadStripe, SetupIntent, Stripe, StripeElements} from '@stripe/stripe-js';
import {
  ConfirmPaymentOptions, ReturnTypeOfConfirmPayment,
  StripeService
} from "@modules/payment/services/stripe.service";
import {BehaviorSubject, combineLatestWith, filter, first, forkJoin, map, Observable, of, switchMap, tap} from "rxjs";
import {environment} from "@environment/environment";
import {
  ACTIVE_USER
} from "@modules/auth/providers/auth.provider";
import {
  User
} from "@modules/user/models/user.model";
import {
  SnackBarService
} from "@shared/services/snack-bar.service";
import {
  OrderService
} from "@modules/order/services/order.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  PaymentService
} from "@shared/component/payments/services/payment.service";
import {PaymentProviderAccountStatusEnum, PaymentProviderNameEnum} from "@generated/graphql";
import {
  PaymentProviderAccountService
} from "@modules/payment/services/payment-provider-account.service";
import {
  PaymentProviderAccount
} from "@modules/payment/models/payment-provider-account.model";
import {
  CalloutComponent
} from "@shared/component/message/callout/callout.component";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet} from "@angular/common";
import {
  BillingAddressService
} from "@modules/address/services/billing-address.service";
import {MatInputModule} from "@angular/material/input";
import {LetModule} from "@ngrx/component";
import {
  PaymentMethod
} from "@modules/payment/models/payment-method.model";
import {
  PaymentMethodService
} from "@modules/payment/services/payment-method.service";
import {
  billingDetailsAdapter
} from "@modules/address/adapters/billing-details.adapter";
import {
  StripeBillingDetails
} from "@modules/payment/models/stripe-billing-details.model";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {
  StripeSetupFormComponent
} from "@modules/payment/components/setup-forms/stripe-setup-form/stripe-setup-form.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {AddressCardInput} from "@shared/component/common/address-card/address-card.component";

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-stripe-payment-form',
  templateUrl: './stripe-payment-form.component.html',
  styleUrls: ['./stripe-payment-form.component.scss'],
  imports: [
    CalloutComponent,
    FormsModule,
    NgTemplateOutlet,
    AsyncPipe,
    MatInputModule,
    NgIf,
    LetModule,
    NgForOf,
    NgOptimizedImage,
    NgClass,
    MatDialogModule,
    StripeSetupFormComponent,
    MatRadioModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class StripePaymentFormComponent implements OnInit {
  linkAuthenticationElementId = "link-authentication-element";
  paymentElementId = 'payment-element';
  canStartAcceptingPayment$: Observable<boolean>;
  paymentMethodsSubject = new BehaviorSubject<PaymentMethod[]>([]);
  paymentMethods$: Observable<PaymentMethod[]> = this.paymentMethodsSubject.asObservable();
  selectedPaymentMethod: PaymentMethod;
  selectedBillingAddress: AddressCardInput;
  returnUrl: string;
  @ViewChild('addCardDialog') private addCardDialog: TemplateRef<any>;
  private stripe: Stripe;
  private elements: StripeElements;

  constructor(
    private readonly stripeService: StripeService,
    private readonly snackbarService: SnackBarService,
    private readonly orderService: OrderService,
    private readonly paymentService: PaymentService,
    private readonly paymentProviderAccountService: PaymentProviderAccountService,
    @Inject(ACTIVE_USER) private readonly activeUser$: Observable<User>,
    private readonly billingAddressService: BillingAddressService,
    private readonly paymentMethodService: PaymentMethodService,
    private readonly dialog: MatDialog,
    private readonly snackBarService: SnackBarService
  ) {
  }

  private _loading$ = new BehaviorSubject<boolean>(false);

  get loading$() {
    return this._loading$.asObservable();
  }

  get selectedBillingAddress$() {
    return this.billingAddressService.selectedBillingAddress$;
  }

  get invalid$() {
    return this.selectedBillingAddress$.pipe(
      combineLatestWith(this.loading$),
      map(([selectedBillingAddress, loading]) => !selectedBillingAddress || loading)
    );
  }

  get button(): HTMLButtonElement | null {
    return (document.querySelector("#submit") as HTMLButtonElement);
  }

  ngOnInit() {
    this.canStartAcceptingPayment$ = this.checkCanStartAcceptingPayment();
    this.canStartAcceptingPayment$.pipe(
      filter(v => Boolean(v)),
      switchMap(_ => this.initialize())
    ).subscribe();

    this.selectedBillingAddress$.pipe(
      untilDestroyed(this),
      combineLatestWith(this.orderService.returnUrl$),
      tap(([address, returnUrl]) => {
        this.selectedBillingAddress = address
        this.returnUrl = returnUrl
      })
    ).subscribe()


  }

  async makePayment() {
    this.setLoading(true);

    if (this.selectedPaymentMethod) {
      this.paymentProviderAccountService.getPaymentProviderAccount(PaymentProviderNameEnum.Stripe).pipe(
        filter(stripeAccount => stripeAccount?.status === PaymentProviderAccountStatusEnum.Active),
        switchMap<PaymentProviderAccount, Promise<Stripe>>(
          stripeAccount => loadStripe(environment.stripe.publishableKey, {stripeAccount: stripeAccount.providerAccountId})),
        switchMap(stripe => {
          this.stripe = stripe;

          return this.orderService.activeEntity$;
        }),
        switchMap(order => this.stripeService.getPaymentIntentClientSecret(order.id, this.selectedPaymentMethod.id)),
        switchMap(clientSecret => this.confirmPayment$(clientSecret))
      ).subscribe();
    } else {
      this.confirmPayment$().subscribe()
    }
  }

  selectPaymentMethod(paymentMethod: PaymentMethod) {
    this.selectedPaymentMethod = paymentMethod;
  }

  addNewPaymentMethod() {
    this.dialog.open(this.addCardDialog, {});
  }

  onSetupSuccess(setupIntent: SetupIntent) {
    this.paymentMethodService.syncAndGetPaymentMethod(setupIntent.payment_method.toString(), PaymentProviderNameEnum.Stripe)
      .subscribe((paymentMethod: PaymentMethod) => {
        this.paymentMethodsSubject.next([...this.paymentMethodsSubject.value, paymentMethod]);
        this.selectPaymentMethod(paymentMethod);
        this.dialog.closeAll();
        this.snackBarService.pushSuccessAlert('Payment method added successfully');
      });
  }

  private confirmPayment$(clientSecret?: string): Observable<Awaited<ReturnTypeOfConfirmPayment>> {
    return this.activeUser$.pipe(
      tap(_ => this.setLoading(true)),
      switchMap(user => {

        const options: ConfirmPaymentOptions = {
          clientSecret: undefined,
          confirmParams: {
            return_url: this.returnUrl,
            receipt_email: user.email,
          }
        };

        if (this.selectedBillingAddress) {
          options.confirmParams.payment_method_data = {
            ...options.confirmParams.payment_method_data,
            billing_details: this.selectedBillingAddress instanceof StripeBillingDetails
              ? this.selectedBillingAddress
              : billingDetailsAdapter.create(this.selectedBillingAddress)
          };
        }

        if (clientSecret) {
          options.clientSecret = clientSecret
        } else {
          options.elements = this.elements
        }

        return this.stripe.confirmPayment(options);
      }),
      tap(({error}) => {
        if (error.type === "card_error" || error.type === "validation_error") {
          this.snackbarService.pushAlert(error.message);
        } else {
          this.snackbarService.pushErrorMessage();
        }

        this.setLoading(false);
      })
    )
  }

  private getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethodService.getUserPaymentMethods().pipe(
      tap(paymentMethods => {
        const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isDefault);

        this.selectPaymentMethod(this.selectedPaymentMethod || defaultPaymentMethod || paymentMethods[0]);
      })
    );
  }

  private checkCanStartAcceptingPayment() {
    return this.paymentService.checkCanStartAcceptingPayment(PaymentProviderNameEnum.Stripe);
  }

  private initialize() {
    this.getPaymentMethods().subscribe(paymentMethods => this.paymentMethodsSubject.next(paymentMethods));

    return this.paymentMethods$.pipe(
      switchMap(paymentMethods => {
          if (paymentMethods.length) {
            return of(paymentMethods);
          } else {
            return this.orderService.activeEntity$.pipe(
              untilDestroyed(this),
              switchMap(order =>
                forkJoin([
                  this.paymentProviderAccountService.getPaymentProviderAccount(PaymentProviderNameEnum.Stripe)
                    .pipe(
                      filter(stripeAccount => stripeAccount?.status === PaymentProviderAccountStatusEnum.Active),
                      switchMap<PaymentProviderAccount, Promise<Stripe>>(stripeAccount =>
                        loadStripe(
                          environment.stripe.publishableKey,
                          {stripeAccount: stripeAccount.providerAccountId}
                        )
                      )
                    ),
                  this.stripeService.getPaymentIntentClientSecret(order.id)
                ])
              ),
              combineLatestWith(this.activeUser$),
              tap(([[stripe, clientSecret], user]) => {
                this.stripe = stripe;
                this.elements = stripe.elements({clientSecret});

                const linkAuthenticationElement = this.elements.create('linkAuthentication', {
                  defaultValues: {
                    email: user.email
                  }
                });

                const paymentElement = this.elements.create('payment', {
                  layout: 'tabs',
                  fields: {billingDetails: 'never'}
                });

                linkAuthenticationElement.mount(`#${this.linkAuthenticationElementId}`);
                paymentElement.mount(`#${this.paymentElementId}`);
              })
            );
          }
        }
      )
    );
  }

  private setLoading(isLoading: boolean) {
    this._loading$.next(isLoading);
    // if (isLoading) {
    //   // Disable the button and show a spinner
    //   this.setDisable(true)
    //   document.querySelector("#spinner").classList.remove("hidden");
    //   document.querySelector("#button-text").classList.add("hidden");
    // } else {
    //   this.setDisable(false);
    //   document.querySelector("#spinner").classList.add("hidden");
    //   document.querySelector("#button-text").classList.remove("hidden");
    // }
  }
}
