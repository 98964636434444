import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  constructor (private tokenExtractor: HttpXsrfTokenExtractor) {
  }

  intercept (
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const headerName = 'X-XSRF-TOKEN';
    let token = this.tokenExtractor.getToken() as string;
    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({headers: req.headers.set(headerName, token)});
    }
    return next.handle(req);
  }
}

export const HTTP_XSRF_CONFIG: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpXsrfInterceptor,
  multi: true,
};
