import { BaseModel } from "@shared/models/base.model";
import { BusinessPlanReminderType } from "@generated/graphql";
import { BusinessPlan } from "@modules/account-settings/pages/business-profile/models/business-plan.model";

export class BusinessPlanReminder extends BaseModel implements BusinessPlanReminderType {
  id: string;
  reminderAt: Date;
  businessPlan: BusinessPlan;


  constructor (state: BusinessPlanReminderType) {
    super(state);
  }

  getModelName (): string {
    return BusinessPlanReminder.name;
  }
}
