import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ExtraJobDialogComponent } from "@shared/component/job/dialog/extra-job-dialog/extra-job-dialog.component";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";

export type ExtraJobDetailDialogData = {
  extraJob: ExtraJob
}

@Component({
  selector: 'app-extra-job-detail-dialog',
  templateUrl: './extra-job-detail-dialog.component.html',
  styleUrls: ['./extra-job-detail-dialog.component.scss']
})
export class ExtraJobDetailDialogComponent implements OnInit {
  static width = '48rem';

  constructor (
    private readonly dialog: MatDialogRef<ExtraJobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ExtraJobDetailDialogData
  ) {
  }

  get extraJob (): ExtraJob {
    return this.data.extraJob
  }

  ngOnInit (): void {
  }

  close () {
    this.dialog.close()
  }
}
