<ng-container *ngrxLet="activeUser$ as activeUser">

  <!--KYC REMINDER-->
  <ng-container *ngIf="activeUser.isCustomer()">
    <app-kyc-reminder-message [companyID]="job.company.id"></app-kyc-reminder-message>
  </ng-container>

  <section class="mb-4">
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Current Director Information
    </p>
    <app-officer-input-review-information
      [officerType]="'CompanyDirectorType'"
      [formType]="getOfficerType()"
      [officerInput]="getCurrentOfficerInputValue()"
    ></app-officer-input-review-information>
  </section>
  <section>
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      New Director Information
    </p>
    <app-officer-input-review-information
      [officerType]="'CompanyDirectorType'"
      [formType]="getOfficerType()"
      [officerInput]="getNewOfficerInputValue()"
    ></app-officer-input-review-information>
  </section>
</ng-container>
