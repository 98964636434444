import { BaseModel } from "@shared/models/base.model";
import { CompanySecretaryType } from "@generated/graphql";
import { User } from "@modules/user/models/user.model";
import { Company } from "@modules/company/models/company.model";
import { companyFactory } from "@modules/company/factories/company.factory";
import { userFactory } from "@modules/user/factories/user.factory";
import { officerFactory } from "@modules/officer/factories/officer.factory";
import { Officer } from "@modules/officer/models/officer.model";

export class CompanySecretary extends BaseModel implements CompanySecretaryType {
  id: string;
  company: Company;
  companyId: string;
  createdBy: User;
  createdById: string;
  dateFrom: Date;
  dateTo: Date;
  officer: Officer;
  officerId: string;
  canUpdate: boolean
  canDelete: boolean
  _model: string;

  constructor (state: CompanySecretaryType) {
    super(state);

    this.company = state.company ? companyFactory.create(state.company) : null

    this.createdBy = state.createdBy ? userFactory.create(state.createdBy) : null

    this.officer = state.officer ? officerFactory.create(state.officer) : null

    this.dateFrom = state.dateFrom ? new Date(state.dateFrom) : null

    this.dateTo = state.dateTo ? new Date(state.dateTo) : null
  }

  getModelName (): string {
    return CompanySecretary.name;
  }
}
