<div class="KYC-reminder-message-wp">
  <app-callout customContent class="block mb-4 !text-sub" appearance="new-light">
    <p>
      Our experts currently processing your request, and we'll send you an email
      notification once it's complete.
    </p>
    <p>
      In the meantime, we encourage you to complete your KYC verification
      <span *ngIf="companyID">
      (click <span [routerLink]="'/companies/' + companyID"
                   class="cursor-pointer hover:underline">here</span>)
      </span>
      for the new shareholders if you haven't already. This will help us to
      process your request more quickly.
    </p>
    <p class="!mb-0">Thanks for your cooperation!</p>
  </app-callout>
</div>
