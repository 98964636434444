import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'table-no-record',
  standalone: true,
  templateUrl: './no-record.component.html',
  styleUrls: ['./no-record.component.scss'],
  imports: [CommonModule],
})
export class NoRecordComponent implements OnInit {
  private _notFoundMessage: string = 'No records found.';
  @Input()
  get notFoundMessage(): string {
    return this._notFoundMessage;
  }

  set notFoundMessage(message: string) {
    this._notFoundMessage = message;
  }

  constructor() { }

  ngOnInit(): void { }
}
