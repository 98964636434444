import { ModelFactory } from "@shared/factories/model.factory";
import { BusinessPlanReminderType } from "@generated/graphql";
import { BusinessPlanReminder } from "@modules/account-settings/pages/business-profile/models/business-plan-reminder.model";

export class BusinessPlanReminderFactory extends ModelFactory<BusinessPlanReminderType, BusinessPlanReminder> {
  create (state: BusinessPlanReminderType): BusinessPlanReminder {
    return new BusinessPlanReminder(state);
  }

  fake (): BusinessPlanReminder {
    throw new Error('Method not implemented')
  }
}

export const businessPlanReminderFactory = new BusinessPlanReminderFactory();
