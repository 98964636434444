import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import {AddressTypeType} from "@generated/graphql";
import {InjectionToken, Provider} from "@angular/core";
import {AddressType} from "@modules/address/models/address-type.model";

export class AddressTypeStore extends EntityStore<EntityFeatureNameEnum.AddressType, AddressTypeType, AddressType> {
  get selectState(): SelectState<AddressTypeType> {
    return this.feature.selectAddressTypeFeatureState;
  }

  getEntry(): EntityEntry<EntityFeatureNameEnum.AddressType> {
    return 'ADDRESS_TYPE';
  }

  getFeatureName(): EntityFeatureName<EntityFeatureNameEnum.AddressType> {
    return 'addressTypeFeature';
  }
}

export const addressTypeStore = new AddressTypeStore()

export const ADDRESS_TYPE_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.AddressTypeStore)

export const ADDRESS_TYPE_STORE_CONFIG: Provider = {
  provide: ADDRESS_TYPE_STORE,
  useValue: addressTypeStore,
}
