import { ModelFactory } from "@shared/factories/model.factory";
import { OrderBillingAddressType } from "@generated/graphql";
import { OrderBillingAddress } from "@modules/order/models/order-billing-address.model";

export class OrderBillingAddressFactory extends ModelFactory<OrderBillingAddressType, OrderBillingAddress> {
  create (state: OrderBillingAddressType): OrderBillingAddress {
    return new OrderBillingAddress(state);
  }

  fake (): OrderBillingAddress {
    throw new Error('Method is not implemented')
  }
}

export const orderBillingAddressFactory = new OrderBillingAddressFactory()

