import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {CompanyCapitalFormType} from '@modules/company/models/form/company-capital-form-type';
import {Validators} from '@config/validators.config';
import {MatButtonModule} from '@angular/material/button';
import {Store} from '@ngrx/store';
import {countryStore} from '@modules/country/store/country.store';
import {Observable, tap} from 'rxjs';
import {MatSelectModule} from '@angular/material/select';
import {ShareTypeType} from '@generated/graphql';
import {ShareTypeService} from '@modules/share-type/services/share-type.service';
import {CommonHelper} from '@shared/helpers/common.helper';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  standalone: true,
  selector: 'app-register-company-capital',
  templateUrl: './register-company-capital.component.html',
  styleUrls: ['./register-company-capital.component.scss'],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
  ],
})
export class RegisterCompanyCapitalComponent implements OnInit {
  private _viewOnly: boolean = false;
  public listCurrencies$: Observable<string[]>;
  public capitalForm = this.fb.group({
    capitals: this.fb.array<FormGroup<CompanyCapitalFormType>>([]),
  });
  public shareTypes: ShareTypeType[];
  public companyCapitalColumns: string[] = [
    'amount',
    'numberOfShares',
    'currency',
    'shareType',
    'action',
  ];
  public dataSource: MatTableDataSource<any>;

  @Input()
  get viewOnly(): boolean {
    return this._viewOnly;
  }

  set viewOnly(value: BooleanInput) {
    this._viewOnly = coerceBooleanProperty(value);
  }

  constructor(
    private fb: FormBuilder,
    private shareTypeService: ShareTypeService,
    private store: Store,
    public readonly commonHelper: CommonHelper,
  ) {
  }

  ngOnInit(): void {
    this.listCurrencies$ = this.store
      .select(countryStore.selectReserveCurrencies)
      .pipe(tap((currency) => [...new Set(currency)]));
    this.shareTypeService
      .getAll()
      .subscribe((shareTypes) => (this.shareTypes = shareTypes));
    this.initializeFormArray();
  }

  public initializeFormArray() {
    const tableRows = this.capitalForm.controls.capitals;
    tableRows.push(this.initialRows());
    this.dataSource = new MatTableDataSource<any>(tableRows.controls);
  }

  public addNewRow() {
    const tableRows = this.capitalForm.controls.capitals;
    tableRows.push(this.initialRows());
    this.dataSource.data = tableRows.controls;
  }

  public removeRow(rowIndex) {
    const tableRows = this.capitalForm.controls.capitals;
    tableRows.removeAt(rowIndex);
    this.dataSource.data = tableRows.controls;
  }

  public isAllowRemove() {
    const tableRows = this.capitalForm.controls.capitals;
    return tableRows.length === 1;
  }

  public onCurrencyChange(source, index) {
    const {value} = source;
    const tableRows = this.capitalForm.controls.capitals;
    const shareTypeControl = tableRows.at(index).get('shareTypeId');
    if (value) {
      shareTypeControl.setValue('');
      shareTypeControl.enable();
    } else {
      shareTypeControl.setValue('');
      shareTypeControl.disable();
    }
  }

  public isShareTypeDisabled(shareTypeId, index): boolean {
    const tableRows = this.capitalForm.controls.capitals;
    const found = tableRows.controls.filter(
      (source, sourceIndex) =>
        index !== sourceIndex &&
        tableRows.controls[index].get('currency').value ===
        tableRows.controls[sourceIndex].get('currency').value &&
        shareTypeId ===
        tableRows.controls[sourceIndex].get('shareTypeId').value,
    );
    return !!found.length;
  }

  public getShareTypeName(id: string): string {
    const shareType = this.shareTypes.find((t) => t.id === id);

    if (!shareType) {
      return '';
    }

    return shareType.name;
  }

  private initialRows(): FormGroup {
    return new FormGroup<CompanyCapitalFormType>({
      totalAmount: new FormControl(0, [Validators.required, Validators.min(1)]),
      totalShares: new FormControl(0, [Validators.required, Validators.min(1)]),
      currency: new FormControl('', Validators.required),
      shareTypeId: new FormControl(
        {value: '', disabled: true},
        Validators.required,
      ),
    });
  }
}
