export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};


export const dirname = (path: string): string => {
  const parts = path.split('/')

  parts.pop()

  return parts.join('/')
}

export const basename = (path: string): string => {
  const parts = path.split('/');

  return parts.pop() || '';
}

export const generatePathArr = (path: string): string[] => {
  const parts = path.split('/');
  const result: string[] = [];

  for (let i = 0; i < parts.length; i++) {
    result.push(parts.slice(0, i + 1).join('/'));
  }

  return result;
}
