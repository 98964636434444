<div class="section-wrapper">
  <mat-card class="!p-0 !shadow-none !rounded-2xl">
    <mat-card-content class="!flex !flex-row w-full">
      <div class="grow-0 basis-1/5 w-1/5 p-4 bg-primary-900 rounded-l-2xl header-container">
        <p class="mat-title !text-xbase text-white section-title"
               [ngClass]="{'!mb-2':sectionCaption}">{{sectionTitle}}</p>
        <p *ngIf="sectionCaption"
           class="mat-subheading-2 !text-sub text-grey-100 !mb-0 section-caption">{{sectionCaption}}</p>
      </div>
      <!--      <mat-divider [vertical]="true"></mat-divider>-->
      <div class="grow basis-4/5 p-6 w-4/5 overflow-hidden content-container">
        <ng-content></ng-content>
      </div>
    </mat-card-content>
  </mat-card>
</div>
