import { Injectable } from "@angular/core";
import {
  BusinessProfileForPublicQuery, BusinessProfileInput,
  BusinessProfileQuery,
  BusinessProfileType,
  UpdateBusinessProfileMutation
} from "@generated/graphql";
import { map, Observable } from "rxjs";
import { BusinessProfile } from "@modules/account-settings/pages/business-profile/models/business-profile.model";

@Injectable({
  providedIn: 'root'
})
export class BusinessProfileApi {
  constructor (
    private readonly _getBusinessProfile: BusinessProfileQuery,
    private readonly _getBusinessProfileForPublic: BusinessProfileForPublicQuery,
    private readonly _updateBusinessProfile: UpdateBusinessProfileMutation
  ) {
    //
  }

  getBusinessProfile (): Observable<BusinessProfile> {
    return this._getBusinessProfile
      .fetch(undefined, {
        fetchPolicy: 'no-cache'
      }).pipe(
        map(
          (response) => new BusinessProfile(response.data.businessProfile as BusinessProfileType)
        )
      );
  }

  getBusinessProfileForPublic (): Observable<BusinessProfileType> {
    return this._getBusinessProfileForPublic
      .fetch(undefined, {
        fetchPolicy: 'no-cache'
      }).pipe(
        map(
          (response) =>
            response.data.businessProfile as BusinessProfileType
        )
      );
  }

  updateBusinessProfile (
    id: string,
    input: BusinessProfileInput
  ): Observable<BusinessProfile> {
    return this._updateBusinessProfile
      .mutate(
        {
          id,
          input,
        },
        {
          fetchPolicy: 'no-cache',
          context: {
            useMultipart: true,
          }
        }
      )
      .pipe(
        map(
          (response) =>
            new BusinessProfile(
              response.data.updateBusinessProfile as BusinessProfileType
            )
        )
      );
  }
}
