import {BaseModel} from "@shared/models/base.model";
import {OfficerIndirectCompanyType} from "@generated/graphql";
import {Country} from "@modules/country/models/country.model";
import {countryFactory} from "@modules/country/factories/country.factory";

export class OfficerIndirectCompany extends BaseModel implements OfficerIndirectCompanyType {
  id: string;
  country: Country;
  name: string;
  _model: string;
  __typename: 'OfficerIndirectCompanyType'

  constructor(state: OfficerIndirectCompanyType) {
    super(state);

    this.country = countryFactory.tryCreating(state.country)
  }

  getModelName(): string {
    return OfficerIndirectCompany.name;
  }
}
