<div class="mt-3 mb-6 border border-solid p-4 border-dashed border-darkGray text-center">
  <p class="mat-title text-lightGray !text-base !mb-1">
    You don't have billing address yet.
  </p>
  <button
    (click)="onAdd()"
    mat-button
    color="accent">
    <span class="underline">Click here to add your address</span>
  </button>
</div>
