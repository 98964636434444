<div class="address-selection-wrapper">
  <h1 mat-dialog-title
      class="mb-0 !text-[2.2rem] text-darkGray">Billing Address</h1>

  <mat-dialog-content>
    <!--    ADD NEW ADDRESS-->
    <div class="w-full inline-flex justify-end mb-4">
      <button
        (click)="onAddNewAddress()"
        mat-button
        color="primary">
        <mat-icon matPrefix>add</mat-icon>
        Add new address
      </button>
    </div>

    <ng-container *ngFor="let address of (billingAddresses$|async)">
      <mat-radio-group
        aria-label="Select an address"
        class="group-selection-outline mb-4"
        [formControl]="control">
        <mat-radio-button
          class="select-option !w-full !mb-3 !last:mb-0"
          [checked]="address.id === control.value?.id"
          [value]="address">
          <div class="w-full">
            <div class="flex flex-row justify-between align-middle w-full mb-1">
              <div class="inline-flex align-middle items-start w-full">
                <p class="mat-title !text-base text-darkGray !mb-0 !mr-2 break-all">{{address.fullName}}</p>
                <span *ngIf="address.isDefault"
                      class="inline-flex items-center text-green-default text-sub">
                     <mat-icon>verified</mat-icon>
                      <span>default</span>
                </span>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="inline-flex gap-2 items-start mb-1">
                <mat-icon class="flex-shrink-0 text-darkGray opacity-70">place</mat-icon>
                <p class="!mb-1 w-[80%] break-words">
                  {{address.addressToShow}}
                </p>
              </div>
              <div class="inline-flex gap-2 items-start">
                <mat-icon class="flex-shrink-0 text-darkGray opacity-70">phone</mat-icon>
                <p class="!mb-0 text-darkGray w-full">{{address.phone}}</p>
              </div>
            </div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      class="w-32 bg-grey-800 text-white"
      mat-dialog-close>Close
    </button>
    <button
      (ok)="confirm()"
      appConfirmDialog
      mat-raised-button
      class="w-32"
      color="primary">Update
    </button>
  </mat-dialog-actions>
</div>
