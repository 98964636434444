import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharesTransferDetailInput, ShareTypeType } from '@generated/graphql';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ACTIVE_USER } from '@app/modules/auth/providers/auth.provider';
import { User } from '@app/modules/user/models/user.model';
import { ShareTypeService } from '@app/modules/share-type/services/share-type.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';

@UntilDestroy()
@Component({
  selector: 'app-shares-transfer-detail-review',
  standalone: true,
  templateUrl: './shares-transfer-detail-review.component.html',
  imports: [CommonModule, LetModule, MatTableModule],
})
export class SharesTransferDetailReviewComponent implements OnInit {
  @Input() public sharesTransferDetails: SharesTransferDetailInput[];

  protected displayedColumns: string[] = [
    'allotmentNumber',
    'certificateNumber',
    'shareType',
    'currency',
    'numberOfShares',
    'amountPaidAmount',
    'amountPaidCurrency',
  ];

  public shareTypes: ShareTypeType[];

  public dataSource: MatTableDataSource<any>;

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    private shareTypeService: ShareTypeService,
  ) { }

  ngOnInit(): void {
    this.activeUser$.subscribe((user) => {
      const defaultDisplayedColumns: string[] = [
        'shareType',
        'currency',
        'numberOfShares',
        'amountPaidAmount',
        'amountPaidCurrency',
      ];

      if (user.isCustomer()) {
        this.displayedColumns = defaultDisplayedColumns;
      } else {
        this.displayedColumns = [
          ...['allotmentNumber', 'certificateNumber'],
          ...defaultDisplayedColumns,
        ];
      }
    });

    this.dataSource = new MatTableDataSource(this.sharesTransferDetails);

    this.shareTypeService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((shareTypes) => {
        this.shareTypes = shareTypes;
      });
  }

  public getShareTypeName(id: string): string {
    const shareType = this.shareTypes.find((s) => s.id === id);

    return shareType.name ?? '';
  }
}
