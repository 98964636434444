import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ConversationMessage } from '@shared/classes/conversation/message-type';
import { SharePipesModule } from '@shared/share-pipes/share-pipes.module';

@Component({
  selector: 'app-file-message',
  standalone: true,
  imports: [CommonModule, MatIconModule, SharePipesModule],
  templateUrl: './file-message.component.html',
  styleUrls: ['./file-message.component.scss'],
})
export class FileMessageComponent implements OnInit {
  private _message: ConversationMessage;

  public get message(): ConversationMessage {
    return this._message;
  }

  @Input()
  public set message(value: ConversationMessage) {
    this._message = value;
  }

  @Output()
  public onDownload = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
}
