import { MissingKycDocumentType } from "@generated/graphql";
import { ModelConstructor } from "@shared/models/base.model";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";
import { officerRequiredDocumentFactory } from "@modules/officer/factories/officer-required-document.factory";
import { MissingKycDocumentItem } from "@modules/officer/models/form/missing-kyc-document-item.model";
import { missingKycDocumentItemFactory } from "@modules/officer/factories/missing-kyc-document-item.factory";

export class MissingKycDocument extends ModelConstructor implements MissingKycDocumentType {
  officerRequiredDocument: OfficerRequiredDocument;
  items: MissingKycDocumentItem[];
  _model: string;

  constructor (state: MissingKycDocumentType) {
    super(state)

    this.officerRequiredDocument = officerRequiredDocumentFactory.tryCreating(state.officerRequiredDocument)

    this.items = missingKycDocumentItemFactory.createEntities(state.items)
  }

  showInitialError (): void {
    throw new Error('Something wrong! when create new ' + MissingKycDocument.name + ' instance');
  }
}
