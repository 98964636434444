import { ModelFactory } from '@shared/factories/model.factory';
import { PaymentProviderType } from '@generated/graphql';
import { PaymentProvider } from '@modules/payment/models/payment-provider.model';

export class PaymentProviderFactory extends ModelFactory<PaymentProviderType, PaymentProvider> {
  create (state: PaymentProviderType): PaymentProvider {
    return new PaymentProvider(state);
  }

  fake (): PaymentProvider {
    throw new Error('Method not implemented yet!');
  }
}

export const paymentProviderFactory = new PaymentProviderFactory();
