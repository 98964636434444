<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="company$ as company">
    <div class="officer-resolutions-wrapper">
      <div *ngIf="shareTypes" class="table-container table-container-limited-scroll mat-elevation-z1">
        <table mat-table class="table-custom" [dataSource]="listOfficers">
          <!--Officer Type-->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="!text-white w-20 !hidden">
              Type
            </th>
            <td mat-cell *matCellDef="let element" class="w-20">
              <mat-icon class="text-darkGray opacity-70" matTooltip="{{
                  element.officer.isIndividual ? 'Individual' : 'Corporate'
                }}" matTooltipClass="capitalize">
                {{
                element.officerType === officerType.Individual
                ? "person"
                : "apartment"
                }}
              </mat-icon>
            </td>
          </ng-container>

          <!--Officer Name-->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="!text-white !hidden">
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="w-[90%]">
                <ng-container *ngIf="element.officerType === officerType.Individual">
                  <p class="line-clamp-3" [ngClass]="element.isNominee ? '!mb-0' : '!mb-1'">
                    {{
                    element.officer.officerPerson.firstName +
                    " " +
                    element.officer.officerPerson.lastName
                    }}
                  </p>
                  <p class="mat-caption line-clamp-2 text-lightGray !mb-0">
                    {{
                    element.officer.officerPerson.identification
                    .identificationNumber
                    }}
                  </p>
                </ng-container>
                <ng-container *ngIf="element.officerType === officerType.Corporate">
                  <p class="line-clamp-3" [ngClass]="element.isNominee ? '!mb-0' : '!mb-1'">
                    {{ element.officer.officerCompany.name }}
                  </p>
                  <p class="mat-caption line-clamp-2 text-lightGray !mb-0">
                    {{ element.officer.officerCompany.registrationNumber }}
                  </p>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <!--Officer Nationality / Country of Incorporation-->
          <ng-container matColumnDef="nationality">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center !hidden">
              Nationality/<br />Incorporation Country
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <p class="!mb-0">{{ getCountryName(element) }}</p>
            </td>
          </ng-container>

          <!--Officer Residence-->
          <ng-container matColumnDef="residence">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center !hidden">
              Residence Country
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <p class="!mb-0">{{ getResidenceCountryName(element) }}</p>
            </td>
          </ng-container>

          <!--Ordinary Shares-->
          <ng-container matColumnDef="ordinary" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-l border-r border-t">
              Ordinary
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <ng-container *ngIf="
                  element.sharesTransferDetails &&
                    element.sharesTransferDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    getOrdinaryShares(element.sharesTransferDetails).length;
                    else noOrdinaryShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of getOrdinaryShares(
                        element.sharesTransferDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">{{
                        shareInfo.currency
                        }}</span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noOrdinaryShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Preference Shares-->
          <ng-container matColumnDef="preference">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-r border-t">
              Preference
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <ng-container *ngIf="
                  element.sharesTransferDetails &&
                    element.sharesTransferDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    getPreferenceShares(element.sharesTransferDetails).length;
                    else noPreferenceShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of getPreferenceShares(
                        element.sharesTransferDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">{{
                        shareInfo.currency
                        }}</span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noPreferenceShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Other Shares-->
          <ng-container matColumnDef="other">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-r border-t">
              Other
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <ng-container *ngIf="
                  element.sharesTransferDetails &&
                    element.sharesTransferDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    getOtherShares(element.sharesTransferDetails).length;
                    else noOtherShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of getOtherShares(
                        element.sharesTransferDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">{{
                        shareInfo.currency
                        }}</span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noOtherShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Officer Percentage of distribution-->
          <ng-container matColumnDef="ownership">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center !hidden">
              Percentage of distribution
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ getOwnerShip(element) | percent: "1.0-2" }}
            </td>
          </ng-container>

          <!--Actions-->
          <ng-container *ngIf="showEdit" matColumnDef="actions" [stickyEnd]="true">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center !hidden">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-end" width="5%">
              <ng-container>
                <button mat-icon-button (click)="
                    onEmitResolutionAction(officerResolutionAction.EditInfo, i)
                  " matTooltip="Edit Info">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>

          <ng-container *ngIf="!showEdit" matColumnDef="actions" [stickyEnd]="true">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center !hidden">
              Actions
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-end" [ngClass]="{ hidden: hideAction }">
              <ng-container>
                <button mat-icon-button (click)="
                    onEmitResolutionAction(officerResolutionAction.EditInfo, i)
                  " matTooltip="Edit Info">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="
                    onEmitResolutionAction(officerResolutionAction.Remove, i)
                  " matTooltip="Remove" color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>

          <!--          CUSTOM HEADER-->

          <!--          TYPE GROUP-->
          <ng-container matColumnDef="type-group">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!w-20 !text-center !text-white">
              Type
            </th>
          </ng-container>

          <!--GROUP  NAME-->
          <ng-container matColumnDef="name-group">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white">
              Name
            </th>
          </ng-container>

          <!--   GROUP  NATIONALITY/ COUNTRY OF INCORPORATION-->
          <ng-container matColumnDef="country-group">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white !text-center">
              Nationality/ <br />Incorporation Country
            </th>
          </ng-container>

          <!--GROUP COUNTRY OF RESIDENCE-->
          <ng-container matColumnDef="residence-group">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white !text-center">
              Residence Country
            </th>
          </ng-container>

          <!--GROUP NUMBER OF SHARES-->
          <ng-container matColumnDef="shareholding-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="!text-center !text-white">
              Number of Shares
            </th>
          </ng-container>

          <!--GROUP PERCENTAGE-->
          <ng-container matColumnDef="percentage-group">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-center !text-white">
              Percentage of distribution
            </th>
          </ng-container>

          <!--GROUP ACTIONS-->
          <ng-container matColumnDef="actions-group" stickyEnd>
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-center !text-white"
              [ngClass]="{ hidden: hideAction && !showEdit }">
              Actions
            </th>
          </ng-container>

          <!--          ADD CUSTOM GROUP TO HEADER/FOOTER-->
          <ng-container>
            <tr mat-header-row *matHeaderRowDef="[
                'type-group',
                'name-group',
                'country-group',
                'residence-group',
                'shareholding-group',
                'percentage-group',
                'actions-group'
              ]" class="bg-primary-800 group-header"></tr>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row class="bg-primary-800"></tr>

          <tr *matRowDef="let row; columns: displayedColumns" class="row-hover !cursor-default" mat-row></tr>

          <tr *matNoDataRow class="mat-row">
            <td [colSpan]="
                hideAction
                  ? displayedColumns.length - 1
                  : displayedColumns.length
              ">
              <table-no-record></table-no-record>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
</ng-container>
