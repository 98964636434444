import { BaseModel } from "@shared/models/base.model";
import { CompanyAuditorType } from "@generated/graphql";
import { Company } from "@modules/company/models/company.model";
import { User } from "@modules/user/models/user.model";
import { companyFactory } from "@modules/company/factories/company.factory";
import { userFactory } from "@modules/user/factories/user.factory";

export class CompanyAuditor extends BaseModel implements CompanyAuditorType {
  id: string
  name: string;
  companyId: string;
  createdById: string;
  company: Company;
  createdBy: User;
  dateFrom: Date;
  dateTo: Date;
  canDelete: boolean
  canUpdate: boolean
  _model: string;

  constructor (state: CompanyAuditorType) {
    super(state);

    this.company = state.company ? companyFactory.create(state.company) : null

    this.createdBy = state.createdBy ? userFactory.create(state.createdBy) : null

    this.dateFrom = state.dateFrom ? new Date(state.dateFrom) : null

    this.dateTo = state.dateTo ? new Date(state.dateTo) : null
  }

  getModelName (): string {
    return CompanyAuditor.name;
  }
}
