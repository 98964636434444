import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { CommonHelper } from '@shared/helpers/common.helper';
import { OfficerInfoDialogComponent } from '@shared/component/dialog/officer/officer-info-dialog/officer-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import {
  OfficerTypeEnum,
  SharesTransferDetailInput,
  ShareTypeEnum,
  ShareTypeType,
} from '@generated/graphql';
import { ShareTypeService } from '@modules/share-type/services/share-type.service';
import { OfficerResolutionActionEnum } from '@shared/types/officerEnum';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { CountryFlagComponent } from '@shared/component/common/country-flag/country-flag.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusComponent } from '@shared/component/common/status/status.component';
import { MatButtonModule } from '@angular/material/button';
import { NoRecordComponent } from '@shared/component/common/table/no-record/no-record.component';
import { LetModule } from '@ngrx/component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Company } from '@modules/company/models/company.model';
import { CompanyEventService } from '@modules/company/services/company-event.service';
import { ShareHolderListViewTableType } from '../../classes/share-holder-list-view-table-type';
import { CountryService } from '@app/modules/country/services/country.service';
import { Country } from '@app/modules/country/models/country.model';

@UntilDestroy()
@Component({
  selector: 'app-share-holder-list-view-table',
  standalone: true,
  templateUrl: './share-holder-list-view-table.component.html',
  imports: [
    CommonModule,
    CountryFlagComponent,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    StatusComponent,
    MatButtonModule,
    NoRecordComponent,
    LetModule,
  ],
})
export class ShareHolderListViewTableComponent implements OnInit {
  @Input() public listOfficers: BehaviorSubject<ShareHolderListViewTableType[]>;

  @Input() totalCapitalShares: number;

  @Output() officerResolutionEvent = new EventEmitter<{
    action: OfficerResolutionActionEnum;
    index: number;
  }>();

  private _isHideActions: boolean = false;

  @Input()
  get hideAction(): boolean {
    return this._isHideActions;
  }
  set hideAction(value: BooleanInput) {
    this._isHideActions = coerceBooleanProperty(value);
  }

  private _showEdit: boolean = false;
  @Input()
  public set showEdit(val: BooleanInput) {
    this._showEdit = coerceBooleanProperty(val);
  }
  public get showEdit() {
    return this._showEdit;
  }

  private _shareTypes: ShareTypeType[];
  public get shareTypes(): ShareTypeType[] {
    return this._shareTypes;
  }

  public isShowOwnership: boolean = true;

  public officerHelper: OfficerHelper;

  public commonHelper: CommonHelper;

  public displayedColumns: string[] = [
    'type',
    'name',
    'nationality',
    'residence',
    'ordinary',
    'preference',
    'other',
    'ownership',
    'actions',
  ];

  protected officerResolutionAction = OfficerResolutionActionEnum;

  public officerType = OfficerTypeEnum;

  public countries: Country[] = [];

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    private shareTypeService: ShareTypeService,
    private dialog: MatDialog,
    private readonly companyEventService: CompanyEventService,
    private _countryService: CountryService,
  ) { }

  ngOnInit(): void {
    this.shareTypeService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((shareTypes) => (this._shareTypes = shareTypes));

    this._countryService.entities$
      .pipe(untilDestroyed(this))
      .subscribe((countries) => (this.countries = countries));

    this.officerHelper = OfficerHelper.getInstance();

    this.commonHelper = CommonHelper.getInstance();
  }

  public get company$(): Observable<Company> {
    return this.companyEventService.company$;
  }

  onViewOfficerInfo(officerInfo) {
    return this.dialog.open(OfficerInfoDialogComponent, {
      autoFocus: false,
      width: '64rem',
      data: {
        officerInfo: officerInfo,
      },
    });
  }

  public onEmitResolutionAction(
    action: OfficerResolutionActionEnum,
    index: number,
  ) {
    this.officerResolutionEvent.emit({
      action: action,
      index,
    });
  }

  public calculateColSpan(): number {
    const columnLength = this.displayedColumns.length;
    let colSpan: number = columnLength;

    colSpan = columnLength - 5;

    return colSpan;
  }

  public getOrdinaryShares(sharesTransferDetails: SharesTransferDetailInput[]) {
    const shareType = this.shareTypes.find(
      (s) => s.name === ShareTypeEnum.Ordinary.toString(),
    );

    return sharesTransferDetails
      .filter((s) => s.shareTypeId === shareType.id)
      .map((s) => ({
        currency: s.currency,
        numberOfShares: s.numberOfShares,
      }));
  }

  public getPreferenceShares(
    sharesTransferDetails: SharesTransferDetailInput[],
  ) {
    const shareType = this.shareTypes.find(
      (s) => s.name === ShareTypeEnum.Preference.toString(),
    );

    return sharesTransferDetails
      .filter((s) => s.shareTypeId === shareType.id)
      .map((s) => ({
        currency: s.currency,
        numberOfShares: s.numberOfShares,
      }));
  }

  public getOtherShares(sharesTransferDetails: SharesTransferDetailInput[]) {
    const shareType = this.shareTypes.find(
      (s) => s.name === ShareTypeEnum.Other.toString(),
    );

    return sharesTransferDetails
      .filter((s) => s.shareTypeId === shareType.id)
      .map((s) => ({
        currency: s.currency,
        numberOfShares: s.numberOfShares,
      }));
  }

  public getOwnerShip(item: ShareHolderListViewTableType) {
    const totalNumberOfShares = item.sharesTransferDetails.reduce(
      (sum, item) => (sum += item.numberOfShares),
      0,
    );

    return totalNumberOfShares / this.totalCapitalShares;
  }

  public getCountryName(item: ShareHolderListViewTableType) {
    return this.countries.find(
      (c) =>
        c.id === item.officer.officerPerson?.countryId ||
        c.id === item.officer.officerCompany?.countryId,
    ).name;
  }

  public getResidenceCountryName(item: ShareHolderListViewTableType) {
    return this.countries.find(
      (c) =>
        c.id === item.officer.officerPerson?.identification.nationalityId ||
        c.id ===
        item.officer.officerCompany?.representative.identification
          .nationalityId,
    ).name;
  }
}
