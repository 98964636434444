import { OrderServiceManagement } from '@app/modules/job/services/order-service-management.service';
import { ServiceEnum } from '@generated/graphql';

type ServiceToggleType = 'add' | 'remove';

export class JobOrderServiceManagement extends OrderServiceManagement {
  toggleRegisteredAddressService(type: ServiceToggleType): this {
    if (type === 'add') {
      this.addOrUpdateService(
        ServiceEnum.RegisteredAddress,
        'additionalServices',
      );
    } else if (type === 'remove') {
      this.removeService(ServiceEnum.RegisteredAddress, 'additionalServices');
    }

    return this;
  }

  toggleUserNomineeService(type: ServiceToggleType): this {
    if (type === 'add') {
      this.addOrUpdateService(
        ServiceEnum.NomineeDirector,
        'additionalServices',
      );
    } else if (type === 'remove') {
      this.removeService(ServiceEnum.NomineeDirector, 'additionalServices');
    }

    return this;
  }
}
