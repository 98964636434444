<div class="snackbar-container flex flex-row w-full relative">
  <div class="snackbar-type mr-3">
    <div class="">
      <mat-icon>
        {{ alertIcon }}
      </mat-icon>
    </div>

  </div>
  <div class="snackbar-message flex-grow">
    <p *ngIf="alertTitle"
       class="message-title !mb-1 font-['OpenSans_Semibold'] !text-base">
      {{ alertTitle }}
    </p>
    <p class="message-content !mb-0 whitespace-pre-line">{{ alertMessage }}</p>
  </div>
  <button mat-icon-button
          (click)="closeSnackbar()"
          class="absolute -top-3 right-0">
    <mat-icon>close</mat-icon>
  </button>
</div>
