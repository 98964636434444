import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { JobType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { Job } from "@modules/job/models/job.model";

export class JobStore extends EntityStore<EntityFeatureNameEnum.Job, JobType, Job> {
  get selectState (): SelectState<JobType> {
    return this.feature.selectJobFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Job> {
    return 'JOB';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Job> {
    return 'jobFeature';
  }
}

export const jobStore = new JobStore()

export const JOB_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.JobStore)

export const JOB_STORE_CONFIG: Provider = {
  provide: JOB_STORE,
  useValue: jobStore,
}
