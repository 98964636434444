import { PaymentDetailType } from '@generated/graphql';
import { ModelConstructor } from '@shared/models/base.model';

export class PaymentDetail extends ModelConstructor implements PaymentDetailType {
  description?: string;

  constructor (state: PaymentDetailType) {
    super(state);
  }

  showInitialError () {
    throw new Error('Something wrong! when create new ' + PaymentDetail.name + ' instance');
  }
}
