<ng-container *ngrxLet="activeUser$ as activeUser">
  <div class="table-container">
    <table *ngIf="shareTypes" mat-table class="table-custom w-full" [dataSource]="dataSource">
      <!--      ALLOTMENT NUMBER-->
      <ng-container *ngIf="!activeUser.isCustomer()" matColumnDef="allotmentNumber">
        <th mat-header-cell *matHeaderCellDef class="!text-white">
          Allotment No.
        </th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'text-center':!element.transferNumber}">
          {{ element.transferNumber ?? '-' }}
        </td>
      </ng-container>

      <!--      CERTIFICATE NUMBER-->
      <ng-container *ngIf="!activeUser.isCustomer()" matColumnDef="certificateNumber">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Certificate No.
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.shareCertNumber ?? '-'}}
        </td>
      </ng-container>

      <!--      SHARE TYPE-->
      <ng-container matColumnDef="shareType">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">Share Type</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          <span class="capitalize">{{ getShareTypeName(element.shareTypeId).toLowerCase() }}</span>
        </td>
      </ng-container>

      <!--      CURRENCY-->
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">Currency</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.currency }}
        </td>
      </ng-container>

      <!--      NUMBER OF SHARES-->
      <ng-container matColumnDef="numberOfShares">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Number of Shares
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.numberOfShares | number }}
        </td>
      </ng-container>

      <!--      PAID AMOUNT-->
      <ng-container matColumnDef="amountPaidAmount">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Consideration Amount
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.paidAmount | number }}
        </td>
      </ng-container>

      <!--      PAID AMOUNT  CURRENCY-->
      <ng-container matColumnDef="amountPaidCurrency">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">Currency</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.paidAmountCurrency }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="bg-primary-800"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
