<ng-container *ngIf="selectedItem; else shareholders">
  <app-company-indirect-shareholder-container
    (checkingKyc)="checkKyc($event)"
    (backTo)="backToLayer($event)"
    (selected)="select($event)"
    (deleting)="delete($event)"
    (updating)="update($event)"
    (adding)="add($event)"
    [company]="company"
    (exist)="onExist()"
    [selectedCorporates]="selectedCorporates">
  </app-company-indirect-shareholder-container>
</ng-container>

<ng-template #shareholders>
  <div class="relative">
    <mat-toolbar color="primary" class="sticky top-0 z-10">
      <div class="inline overflow-auto py-2">
        <ng-container *ngIf="company">
          <button mat-button (click)="onExist()">
            <mat-icon>apartment</mat-icon>
            {{ company.name }}
          </button>
          <mat-icon class="align-middle text-white">keyboard_arrow_right</mat-icon>
        </ng-container>

        <ng-container *ngIf="selectedCorporates">
          <ng-container *ngFor="let sh of selectedCorporates; let i = index; let last = last">
            <button mat-button (click)="backToLayer(i)">
              <mat-icon>apartment</mat-icon>
              {{ sh.officer.name }}
            </button>
            <mat-icon class="align-middle text-white" *ngIf="!last">keyboard_arrow_right</mat-icon>
          </ng-container>
        </ng-container>
      </div>

      <span class="example-spacer"></span>

      <button mat-icon-button (click)="add(lastCorporate)" [matTooltip]="'Add new shareholder'"
              *ngIf="lastCorporate?.canCreateShareholder">
        <mat-icon class="text-white">person_add</mat-icon>
      </button>

      <span class="px-2"></span>

      <button
        mat-icon-button
        appConfirmDialog
        confirmMessage="Do you want to back to the top layer?"
        (ok)="onExist()"
        [matTooltip]="'Exist'">
        <mat-icon class="text-white">logout</mat-icon>
      </button>
    </mat-toolbar>
    <mat-tab-group *ngIf="corporateShareholders?.length || individualShareholders?.length; else empty" class="mt-4">
      <mat-tab *ngIf="corporateShareholders?.length">
        <ng-template matTabLabel>
          <mat-icon class="mr-2">business</mat-icon>
          <span>Corporate Shareholders</span>
        </ng-template>
        <ng-template matTabContent>
          <app-company-indirect-shareholder-table
            [company]="company"
            (checkingKyc)="checkKyc($event)"
            (deleting)="delete($event)"
            (updating)="update($event)"
            [type]="'OfficerIndirectCompanyType'"
            (selected)="select($event)"
            [shareholders]="corporateShareholders"></app-company-indirect-shareholder-table>
        </ng-template>
      </mat-tab>

      <mat-tab *ngIf="individualShareholders?.length">
        <ng-template matTabLabel>
          <mat-icon class="mr-2">supervised_user_circle</mat-icon>
          <span>Individual Shareholders</span>
        </ng-template>
        <ng-template matTabContent>
          <app-company-indirect-shareholder-table
            [company]="company"
            (checkingKyc)="checkKyc($event)"
            (deleting)="delete($event)"
            (updating)="update($event)"
            [type]="'OfficerIndirectPersonType'"
            (selected)="select($event)"
            [shareholders]="individualShareholders"></app-company-indirect-shareholder-table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <ng-template #empty>
      <div class="p-4">
        <ng-container *ngIf="isLimitDeep; else normalEmpty">
          <app-callout
            appearance="primary"
            [message]="limitDeepMessage"></app-callout>
        </ng-container>

        <ng-template #normalEmpty>
          <app-callout
            *ngIf="!lastCorporate?.canCreateShareholder; else noRecords"
            appearance="primary"
            [message]="noNeedToListShareholdersMessage"></app-callout>
          <ng-template #noRecords>
            <table-no-record></table-no-record>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </div>
</ng-template>
