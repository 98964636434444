<div class="credit-card-wrapper">
    <mat-card class="card-container w-full relative">
        <div class="!inline-flex items-center w-full">
            <img ngSrc="assets/images/logo/{{paymentMethod.cardBrand}}.svg"
                 class="w-14 mr-4"
                 alt="card-type"
                 height="48"
                 width="70">
            <div class="flex flex-col w-3/4">
                <mat-card-title class="text-darkGray !text-base !mb-0">
                    <span class="!mat-subheading-1 font-['OpenSans_Semibold'] !font-semibold !text-base capitalize">{{paymentMethod.cardBrand}}</span>&nbsp;
                    <span class="!font-primary !text-base">ending in {{paymentMethod.cardLastFour}} </span>
                </mat-card-title>
                <mat-card-subtitle class="text-sub !mb-0">
                    Expiry {{[paymentMethod.cardExpiryMonth, paymentMethod.cardExpiryYear].join('/')}}</mat-card-subtitle>
            </div>
        </div>
        <mat-icon *ngIf="paymentMethod.isDefault"
                  class="text-green-default absolute top-2 right-3">verified
        </mat-icon>
        <mat-card-actions *ngIf="visibleActions$"
                          align="end"
                          class="!pt-0">
            <button
                    (click)="onUpdate()"
                    *ngIf="visibleUpdateAction$ | async"
                    [disabled]="loading$ | async"
                    color="accent"
                    mat-button>Edit
            </button>
            <button
                    (ok)="onDelete()"
                    *ngIf="visibleDeleteAction$ | async"
                    [confirmMessage]="'Are you sure you want to delete this payment method?'"
                    [disabled]="loading$ | async"
                    appConfirmDialog
                    color="warn"
                    mat-button>Delete
            </button>
            <button
                    (click)="onSetDefault()"
                    *ngIf="visibleSetDefaultAction$ | async"
                    [disabled]="loading$ | async"
                    color="primary"
                    mat-button>Set as default
            </button>
        </mat-card-actions>
    </mat-card>
</div>
