import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionWpComponent } from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import { CountryFlagComponent } from '@shared/component/common/country-flag/country-flag.component';
import { Observable } from 'rxjs';
import { SharedComponentModule } from '@shared/component/component.module';
import { Job } from '@app/modules/job/models/job.model';
import { CalloutComponent } from '@shared/component/message/callout/callout.component';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { User } from '@modules/user/models/user.model';
import { LetModule } from '@ngrx/component';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-default-service-review',
  standalone: true,
  imports: [
    CommonModule,
    SectionWpComponent,
    CountryFlagComponent,
    SharedComponentModule,
    CalloutComponent,
    LetModule,
    MatListModule,
  ],
  templateUrl: './default-service-review.component.html',
})
export class DefaultServiceReviewComponent implements OnInit {
  @Input() public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  constructor(@Inject(ACTIVE_USER) readonly activeUser$: Observable<User>) {}

  ngOnInit(): void {}
}
