import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserType } from '@generated/graphql';

export const authUserActions = createActionGroup({
  source: 'Auth',
  events: {
    'Set auth user': props<{user: UserType}>(),
    'Reset auth user': emptyProps(),
  },
});
