import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { BillingAddressType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { BillingAddress } from "@modules/address/models/billing-address.model";
import { createSelector } from "@ngrx/store";
import { billingAddressFactory } from "@modules/address/factories/billing-address.factory";

export class BillingAddressStore extends EntityStore<EntityFeatureNameEnum.BillingAddress, BillingAddressType, BillingAddress> {
  get selectState (): SelectState<BillingAddressType> {
    return this.feature.selectBillingAddressFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.BillingAddress> {
    return 'BILLING_ADDRESS';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.BillingAddress> {
    return 'billingAddressFeature';
  }

  selectAllWithoutActiveEntity = createSelector(
    this.selectedEntityId,
    this.selectIds,
    this.selectEntities,
    (activeId, ids, entities) => ids.filter(id => !id.includes(activeId)).map(id => billingAddressFactory.create(entities[id]))
  )
}

export const billingAddressStore = new BillingAddressStore()

export const BILLING_ADDRESS_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.BillingAddressStore)

export const BILLING_ADDRESS_STORE_CONFIG: Provider = {
  provide: BILLING_ADDRESS_STORE,
  useValue: billingAddressStore,
}
