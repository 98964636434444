import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { ConversationMessage } from '@shared/classes/conversation/message-type';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ShareDirectivesModule } from '@shared/share-directives/share-directives.module';

@Component({
  selector: 'app-text-message',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatTabsModule,
    NgOptimizedImage,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ShareDirectivesModule,
  ],
  templateUrl: './text-message.component.html',
  styleUrls: ['./text-message.component.scss'],
})
export class TextMessageComponent implements OnInit {
  private _message: ConversationMessage;

  public get message(): ConversationMessage {
    return this._message;
  }

  @Input()
  public set message(value: ConversationMessage) {
    this._message = value;
  }

  constructor() { }

  ngOnInit(): void { }
}
