<div class="message-wrapper">
  <div class="message-item w-full flex flex-row mb-2"
    [ngClass]="{ 'justify-end': message.isMe, 'mt-4': message.avatarVisible }">
    <div class="message-container flex flex-row max-w-[80%]">
      <div class="message-container__avatar mr-2">
        <img *ngIf="!message.isMe && message.avatarVisible" [ngSrc]="
            message.sender && message.sender.avatarUrl
              ? message.sender.avatarUrl
              : '../assets/images/icons/profile.svg'
          " class="w-8 h-8 rounded-full object-cover" height="32" width="32" alt="" />
        <div *ngIf="!message.avatarVisible && !message.isMe" class="w-8 h-8"></div>
      </div>
      <div class="message-container__text overflow-hidden">
        <p *ngIf="!message.isMe && message.nameVisible"
          class="font-['OpenSans_Medium'] font-medium text-sub block !mb-1 text-grey-900">
          {{ message.sender.displayName }}
        </p>
        <div class="flex flex-row items-center" [ngClass]="{ 'flex-row-reverse': message.isMe }">
          <div class="text-darkGray px-4 py-2 rounded-lg overflow-hidden flex flex-col" [ngClass]="{
              'bg-primary-default text-white': message.isMe,
              'bg-[#f0f0f0] text-darkGray': !message.isMe,
              '!bg-[#f6f6f6] !text-red-300': message.deletedAt
            }">
            <ng-container *ngIf="!message.deletedAt; else deletedMessage">
              <ng-container *ngIf="message.replied">
                <div *ngIf="message.replied" class="flex flex-col cursor-pointer"
                  (click)="gotoMessage.emit(message.replied.messageId)">
                  <mat-icon>reply</mat-icon>
                  <p class="!mb-0 truncate text-sub italic">
                    {{ message.replied.body }}
                  </p>
                  <p class="!mb-0 mat-caption italic">
                    {{ message.replied.displayName }},
                    {{ message.replied.repliedAt | date }}
                  </p>
                  <mat-divider class="!my-2" [ngClass]="{ 'border-white': message.isMe }"></mat-divider>
                </div>
              </ng-container>

              <!--            TEXT MESSAGE-->
              <ng-container *ngIf="isTextMessage">
                <app-text-message [message]="message"></app-text-message>
              </ng-container>

              <!--FILE MESSAGE-->
              <ng-container *ngIf="isFileMessage">
                <app-file-message [message]="message" (onDownload)="
                    onActionOnMessage(MessageActionEnum.Download, message)
                  "></app-file-message>
              </ng-container>
            </ng-container>

            <ng-template #deletedMessage>
              <p class="!text-sub !mb-1 !italic">This message was deleted</p>
            </ng-template>

            <span class="text-xs italic text-right" [ngClass]="{
                'text-[#E0E0E0]': message.isMe,
                'text-grey-400': !message.isMe
              }">
              <mat-icon *ngIf="!message.deletedAt" class="!h-4 !w-4 !text-sub">schedule</mat-icon>
              <span *ngIf="!message.updatedAt && !message.deletedAt">
                {{ message.createdAt | date: "H:mm" }}
              </span>
              <span *ngIf="message.updatedAt && !message.deletedAt">
                Edited: {{ message.updatedAt | date: "H:mm" }}
              </span>
            </span>
          </div>

          <!--          ACTIONS-->
          <ng-container *ngIf="!message.deletedAt">
            <div class="inline-flex items-center group-buttons">
              <button disableRipple mat-icon-button (click)="onActionOnMessage(MessageActionEnum.Reply, message)">
                <mat-icon class="!text-xbase text-lightGray">reply</mat-icon>
              </button>
              <button disableRipple mat-icon-button [matMenuTriggerFor]="moreActions" *ngIf="message.isMe">
                <mat-icon class="!text-xbase text-lightGray">more_vert</mat-icon>
              </button>
              <mat-menu #moreActions="matMenu">
                <button mat-menu-item class="text-sub text-darkGray !h-8 !leading-[3.2rem]"
                  (click)="onActionOnMessage(MessageActionEnum.Edit, message)"
                  [disabled]="message.type === MessageType.File">
                  Edit
                </button>
                <button mat-menu-item class="text-sub text-red-600 !h-8 !leading-[3.2rem]" appConfirmDialog
                  title="Remove Message" confirmMessage="Are you sure you want to remove this message?"
                  (ok)="deleteMessage.emit()">
                  Remove
                </button>
              </mat-menu>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
