<div
  class="register-company-capital-wrapper table-container table-container-limited-scroll !max-h-[28rem] mat-elevation-z1">
  <ng-container *ngIf="listCurrencies$ | async as listCurrencies">
    <table mat-table class="table-custom" [dataSource]="dataSource">
      <!--    AMOUNT COLUMN-->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Capital Amount
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Capital amount</mat-label>
            <input matInput [formControl]="element.get('totalAmount')" type="number" />
            <mat-error *ngIf="element.get('totalAmount')?.hasError('required')">
              capital amount required
            </mat-error>
            <mat-error *ngIf="element.get('totalAmount')?.hasError('min')">
              Capital must at least 1.
            </mat-error>
          </mat-form-field>
        </td>
      </ng-container>

      <!--    NUMBER OF SHARES COLUMN-->
      <ng-container matColumnDef="numberOfShares">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Number of Shares
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Number of shares</mat-label>
            <input matInput [formControl]="element.get('totalShares')" type="number" />
            <mat-error *ngIf="element.get('totalShares')?.hasError('required')">
              number of shares required
            </mat-error>
            <mat-error *ngIf="element.get('totalShares')?.hasError('min')">
              Shares number must at least 1.
            </mat-error>
          </mat-form-field>
        </td>
      </ng-container>

      <!--    CURRENCY COLUMN-->
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Currency
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Select currency</mat-label>
            <mat-select [formControl]="element.get('currency')" (selectionChange)="onCurrencyChange($event, i)">
              <mat-option>Select currency</mat-option>
              <mat-option *ngFor="let currency of listCurrencies" [value]="currency">
                {{ currency }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="element.get('currency')?.hasError('required')">
              Currency required
            </mat-error>
          </mat-form-field>
        </td>
      </ng-container>

      <!--    SHARE TYPE COLUMN-->
      <ng-container matColumnDef="shareType">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Share Type
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!capitalize">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Share type</mat-label>
            <mat-select [formControl]="element.get('shareTypeId')">
              <mat-select-trigger>
                <span class="capitalize">
                  {{
                  element.get("shareTypeId").value
                  ? getShareTypeName(
                  element.get("shareTypeId").value
                  ).toLocaleLowerCase()
                  : ""
                  }}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let type of shareTypes" [value]="type.id" [disabled]="isShareTypeDisabled(type.id, i)"
                [ngClass]="{
                  '!cursor-not-allowed': isShareTypeDisabled(type.id, i)
                }">
                <div class="inline-flex items-center">
                  <span class="mr-2 capitalize">{{
                    type.name.toLowerCase()
                    }}</span>
                  <mat-icon class="text-darkGray text-xbase opacity-60"
                    matTooltip="{{ commonHelper.getShareTypeHint(type.name) }}">
                    info
                  </mat-icon>
                </div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="element.get('shareTypeId')?.hasError('required')">
              Share type required
            </mat-error>
          </mat-form-field>
        </td>
      </ng-container>

      <!--    ACTION COLUMN-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="!text-white w-20" [ngClass]="{ hidden: viewOnly }">
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ hidden: viewOnly }">
          <button mat-icon-button color="warn" [disabled]="isAllowRemove()" (click)="removeRow(i)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="companyCapitalColumns; sticky: true" class="bg-primary-800"></tr>
      <tr mat-row *matRowDef="let row; columns: companyCapitalColumns"></tr>
    </table>
  </ng-container>
</div>
<div class="flex flex-row justify-end" *ngIf="!viewOnly">
  <button mat-button color="primary" class="text-sub" (click)="addNewRow()">
    <mat-icon>add</mat-icon>
    add new
  </button>
</div>
