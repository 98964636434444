<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="businessProfile$ as businessProfile">
    <ul class="!text-sub px-8 py-4 border border-solid rounded-2xl border-[#0000001f]">
      <li>
        <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}}using {{ businessProfile.name }}
                           accounting service&colon;</span>
        <span class="mat-body-2 !text-sub">{{
          holdAgmInput.usingTenantAccount ? "Yes" : "No"
          }}</span>
      </li>
      <ng-container *ngIf="!holdAgmInput.usingTenantAccount">
        <ng-container *ngIf="companyInfo.country.code === 'SG'">
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'the customer company'}} exempted from audit requirements&colon;</span>
            <span class="mat-body-2 !text-sub">{{holdAgmInput.exemptedFromAuditRequirement ? "Yes" : "No"}}</span>
          </li>
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'the customer company'}} an exempt private company&colon;</span>
            <span class="mat-body-2 !text-sub">{{
              holdAgmInput.exemptPrivateCompany ? "Yes" : "No"
              }}</span>
          </li>
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare financial statement&colon;</span>
            <span class="mat-body-2 !text-sub">{{holdAgmInput.prepareFinancialStatement ? "Yes" : "No"}}</span>
          </li>
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare XBRL report&colon;</span>
            <span class="mat-body-2 !text-sub">{{holdAgmInput.prepareXbrlReport ? "Yes" : "No"}}</span>
          </li>
        </ng-container>

        <ng-container *ngIf="companyInfo.country.code === 'HK'">
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare audited financial statement&colon;</span>
            <span class="mat-body-2 !text-sub">{{holdAgmInput.prepareAuditedFinancialStatements ? "Yes" : "No"}}</span>
          </li>
        </ng-container>

        <li>
          <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare management account&colon;</span>
          <span class="mat-body-2 !text-sub">{{holdAgmInput.prepareManagementAccount ? "Yes" : "No"}}</span>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>
