import { ModelFactory } from '@shared/factories/model.factory';
import { SupportedCountryType } from '@generated/graphql';
import { faker } from '@faker-js/faker';
import { SupportedCountry } from '@modules/supported-country/models/supported-country.model';
import { countryFactory } from '@modules/country/factories/country.factory';

export class SupportedCountryFactory extends ModelFactory<SupportedCountryType, SupportedCountry> {
  create (state: SupportedCountryType): SupportedCountry {
    return new SupportedCountry(state);
  }

  fake (): SupportedCountry {
    return this.create({
      id: faker.datatype.uuid(),
      currency: SupportedCountry.defaultCurrency,
      createdAt: new Date(faker.date.past()),
      updatedAt: new Date(faker.date.future()),
      country: countryFactory.fake(),
      includeTax: faker.datatype.boolean(),
      taxRate: faker.datatype.number({min: 0, max: 10}),
      isActive: faker.datatype.boolean(),
    });
  }
}

export const supportedCountryFactory = new SupportedCountryFactory();
