import { ModelFactory } from "@shared/factories/model.factory";
import { CompanySecretaryType } from "@generated/graphql";
import { CompanySecretary } from "@modules/company/models/company-secretary.model";

export class CompanySecretaryFactory extends ModelFactory<CompanySecretaryType, CompanySecretary> {
  create (state: CompanySecretaryType): CompanySecretary {
    return new CompanySecretary(state);
  }

  fake (): CompanySecretary {
    throw new Error('This method is not implemented')
  }
}

export const companySecretaryFactory = new CompanySecretaryFactory()
