import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  OfficerIndividualFormType,
  OfficerIndividualIdentificationFormType,
} from '@app/modules/officer/models/form/officer-individual-form-type';
import {
  OfficerPersonInput,
  SalutationEnum,
  ShareholdingDetailInput,
} from '@generated/graphql';
import { AbstractOfficerForm } from './abstract-officer-form.class';
import { PhoneValidator } from '@utils/validation/app.validation';

export class OfficerIndividualForm extends AbstractOfficerForm {
  officerForm: FormGroup<OfficerIndividualFormType>;

  constructor() {
    super();
  }

  createOfficerForm(): void {
    this.officerForm = this.fb.group<OfficerIndividualFormType>({
      salutation: new FormControl<string | null>(
        SalutationEnum.Mr,
        Validators.required,
      ),
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      countryId: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>('', [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl<string | null>('', [
        Validators.required,
        PhoneValidator,
      ]),
      address: new FormControl<string | null>('', Validators.required),
      apartment: new FormControl<string | null>(''),
      zip: new FormControl<string | null>(''),
      city: new FormControl<string | null>(''),
      state: new FormControl<string | null>(''),
      identification: this.fb.group<OfficerIndividualIdentificationFormType>({
        officerIdentificationTypeId: new FormControl<string | null>(
          '',
          Validators.required,
        ),
        nationalityId: new FormControl<string | null>('', Validators.required),
        identificationNumber: new FormControl<string | null>(
          '',
          Validators.required,
        ),
        expiryDate: new FormControl<Date | null>(null),
      }),
    });
  }

  setEditData(
    formData: OfficerPersonInput,
    shareholding: ShareholdingDetailInput[] = [],
  ): void {
    if (!formData) {
      return;
    }

    this.officerForm.patchValue({
      salutation: formData.salutation,
      firstName: formData.firstName,
      lastName: formData.lastName,
      countryId: formData.countryId,
      email: formData.email,
      phone: formData.phone,
      address: formData.address,
      apartment: formData.apartment,
      zip: formData.zip,
      city: formData.city,
      state: formData.state,
      identification: {
        officerIdentificationTypeId:
          formData.identification.officerIdentificationTypeId,
        nationalityId: formData.identification.nationalityId,
        identificationNumber: formData.identification.identificationNumber,
        expiryDate: formData.identification.expiryDate ?? null,
      },
    });

    if (shareholding.length) {
      this.setShareDistribution(shareholding);
    }
  }
}
