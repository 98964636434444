import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { OfficerRequiredDocumentType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";

export class OfficerRequiredDocumentStore extends EntityStore<EntityFeatureNameEnum.OfficerRequiredDocument, OfficerRequiredDocumentType, OfficerRequiredDocument> {
  get selectState (): SelectState<OfficerRequiredDocumentType> {
    return this.feature.selectOfficerRequiredDocumentFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.OfficerRequiredDocument> {
    return 'OFFICER_REQUIRED_DOCUMENT';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.OfficerRequiredDocument> {
    return 'officerRequiredDocumentFeature';
  }
}

export const officerRequiredDocumentStore = new OfficerRequiredDocumentStore()

export const OFFICER_REQUIRED_DOCUMENT_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.OfficerRequiredDocumentStore)

export const OFFICER_REQUIRED_DOCUMENT_STORE_CONFIG: Provider = {
  provide: OFFICER_REQUIRED_DOCUMENT_STORE,
  useValue: officerRequiredDocumentStore,
}
