import { ModelFactory } from "@shared/factories/model.factory";
import { OfficerPersonType } from "@generated/graphql";
import { OfficerPerson } from "@modules/officer/models/officer-person.model";

export class OfficerPersonFactory extends ModelFactory<OfficerPersonType, OfficerPerson> {
  create (state: OfficerPersonType): OfficerPerson {
    return new OfficerPerson(state);
  }

  fake (): OfficerPerson {
    throw new Error('This method is not implemented')
  }
}

export const officerPersonFactory = new OfficerPersonFactory()
