import {environment} from "@environment/environment";

export const isExternalUrl = (urlString): boolean => {
  try {
    const url = new URL(urlString) // THROW ON MISSING SCHEME

    // DOES THIS URL ORIGINATE FROM THIS WEBSITE?
    if (url.origin !== new URL(document.URL, document.baseURI).origin) {
      return true // IS EXTERNAL URL
    }
  } catch (_e) {
    // THROWS WHEN URL DOES NOT HAVE A SCHEME
    new URL(urlString, document.baseURI) // THROW AN EXCEPTION IF THE URL IS TRULY MALFORMED IN SOME WAY
  }

  return false
}


export const fromTrustedUrls = (urlString): boolean => {
  return new URL(urlString).origin === new URL(environment.apiUrl).origin
}

export const isTrustedExternalUrl = (urlString): boolean => {
  return isExternalUrl(urlString) && fromTrustedUrls(urlString)
}
