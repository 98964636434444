import {ModelFactory} from "@shared/factories/model.factory";
import {GstReturnType} from "@generated/graphql";
import {GstReturn} from "@modules/gst-return/models/gst-return.model";

export class GstReturnFactory extends ModelFactory<GstReturnType, GstReturn> {
  create(state: GstReturnType): GstReturn {
    return new GstReturn(state);
  }
}

export const gstReturnFactory = new GstReturnFactory()
