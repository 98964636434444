import {InjectionToken, Provider} from '@angular/core';
import {Job} from "@modules/job/models/job.model";
import {ExtraJob} from "@modules/extra-job/models/extra-job.model";

export type RootRoutes = 'root'

export type AuthRoutes = 'login' |
  'signUp' |
  'forgotPassword' |
  'resetPassword'

export type DashboardRoutes = 'dashboard'

export type AccountRoutes =
  'profileSettings'
  | 'businessSettings'
  | 'serviceManagement'
  | 'billingAddresses'
  | 'paymentProviderDetail'
  | 'managePaymentInfo'

export type BusinessManagementRoutes = 'bankManagement'

export type TeamRoutes = 'team'

export type JobRoutes = 'jobs' | 'registerCompany' | 'transferCompany' | 'jobDetail'

export type ExtraJobRoutes = 'extraJobCheckout'

export type PaymentRoutes = 'payments' | 'jobCheckout' | 'paymentMethodManagement'

export type CustomerRoutes = 'customers'

export type CompanyRoutes =
  'companies' |
  'company' |
  'companyKyc' |
  'registerCompany' |
  'transferCompany'

export type Routes =
  TeamRoutes
  | AuthRoutes
  | CompanyRoutes
  | DashboardRoutes
  | CustomerRoutes
  | PaymentRoutes
  | JobRoutes
  | AccountRoutes
  | RootRoutes
  | BusinessManagementRoutes
  | ExtraJobRoutes

export type AppRoute = (data?) => any[]

export type AppRoutes = Record<Routes, AppRoute>


export const appRoutes: AppRoutes = {
  root: () => ['/'],

  login: () => ['/', 'login'],

  signUp: () => ['/', 'register'],

  forgotPassword: () => ['/', 'forgot-password'],

  resetPassword: () => ['/', 'reset-password'],

  dashboard: () => ['/dashboard'],

  customers: () => ['/', 'customers'],

  payments: () => ['/payments'],

  jobCheckout: (id: Job['id']) => ['/', 'job', id, 'checkout'],

  extraJobCheckout: (data: {
    jobId: Job['id']
    extraJobId: ExtraJob['id']
  }) => ['/', 'job', data.jobId, 'extra-jobs', data.extraJobId, 'checkout'],

  companies: () => ['/companies'],

  company: id => ['/', 'companies', id],

  team: () => ['/team'],

  jobs: () => ['/job', 'list-jobs'],

  jobDetail: (id: string) => ['/job', id],

  registerCompany: () => ['/job', 'register-company',],

  transferCompany: () => ['/job', 'transfer-company'],

  companyKyc: id => ['/', 'company', id, 'kyc'],

  profileSettings: () => ['/account', 'profile-settings'],

  businessSettings: () => ['/account', 'business-settings'],

  serviceManagement: id => ['/account', 'business-settings', 'country', id],

  paymentProviderDetail: (id: string) => ['/account', 'business-settings', 'payment-providers', id],

  billingAddresses: () => ['/account', 'billing-address'],

  managePaymentInfo: () => ['/account', 'manage-payment-info'],

  bankManagement: () => ['/account', 'business-settings', 'bank-account-management'],

  paymentMethodManagement: () => ['/account', 'payment-method-management'],
} as const;


export const APP_ROUTES = new InjectionToken('APP_ROUTES');

export const APP_ROUTES_CONFIG = {
  provide: APP_ROUTES,
  useValue: appRoutes,
} as Provider;
