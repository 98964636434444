import { Inject, Injectable } from '@angular/core';
import {
  BusinessProfileInput,
  BusinessProfileType,
} from '@generated/graphql';
import { Observable } from 'rxjs';
import { BusinessProfile } from 'src/app/modules/account-settings/pages/business-profile/models/business-profile.model';
import { BusinessProfileApi } from "@modules/account-settings/pages/business-profile/api/business-profile.api";
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import {
  BUSINESS_PROFILE_STORE,
  BusinessProfileStore
} from "@modules/account-settings/pages/business-profile/store/business-profile.store";
import { Store } from "@ngrx/store";
import {
  BusinessProfileFactory,
  businessProfileFactory
} from "@modules/account-settings/pages/business-profile/factories/business-profile.factory";

@Injectable({
  providedIn: 'root',
})
export class BusinessProfileService extends EntityService<EntityFeatureNameEnum.BusinessProfile, BusinessProfileType, BusinessProfile> {
  constructor (
    @Inject(BUSINESS_PROFILE_STORE) protected readonly entityStore: BusinessProfileStore,
    protected readonly store: Store,
    private readonly businessProfileApi: BusinessProfileApi,
  ) {
    super(store, entityStore)
  }

  get factory (): BusinessProfileFactory {
    return businessProfileFactory;
  }

  getBusinessProfile (): Observable<BusinessProfile> {
    return this.businessProfileApi.getBusinessProfile()
  }

  getBusinessProfileForPublic (): Observable<BusinessProfileType> {
    return this.businessProfileApi.getBusinessProfileForPublic()
  }

  updateBusinessProfile (
    id: string,
    input: BusinessProfileInput
  ): Observable<BusinessProfile> {
    return this.businessProfileApi.updateBusinessProfile(id, input)
  }
}
