import { User } from '@app/modules/user/models/user.model';
import { Database, onValue, ref, set, update } from '@firebase/database';
import { RoleEnum } from '@generated/graphql';
import { FirebaseApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { ConversationMember } from './message-type';

export class ConversationMemberManagement {
  private _db: Database;
  public get db(): Database {
    return this._db;
  }
  public set db(value: Database) {
    this._db = value;
  }

  private _members: ConversationMember[] = [];
  public get members(): ConversationMember[] {
    return this._members;
  }
  public set members(value: ConversationMember[]) {
    this._members = value;
  }

  public members$ = new BehaviorSubject<ConversationMember[]>([]);

  /**
   * Current user
   */
  private _sender: User;
  public get sender(): User {
    return this._sender;
  }
  public set sender(value: User) {
    this._sender = value;
  }

  /**
   * All participants of current job
   */
  private _users: User[];
  public get users(): User[] {
    return this._users;
  }
  public set users(value: User[]) {
    this._users = value;
  }

  private _dbRefPath: string;
  public get dbRefPath(): string {
    return this._dbRefPath;
  }
  public set dbRefPath(value: string) {
    this._dbRefPath = value;
  }

  private _app: FirebaseApp;
  public get app(): FirebaseApp {
    return this._app;
  }
  public set app(value: FirebaseApp) {
    this._app = value;
  }

  public isMissingExperts$ = new BehaviorSubject(false);

  public constructor() {}

  public async signInSender() {
    const auth = getAuth(this.app);

    await signInAnonymously(auth);
  }

  public async joinSender() {
    const member: ConversationMember = {
      id: this.sender.id,
      displayName: this.sender.profile.fullName,
      avatarUrl: this.sender.profile.avatarUrl ?? null,
      roles: this.sender.roles ? this.sender.roles.map((r) => r.name) : [],
    };

    await update(
      ref(this.db, `${this.dbRefPath}/members/${member.id}`),
      member,
    );
  }

  public async joinUsers() {
    this.users.forEach(async (currentMember) => {
      const member: ConversationMember = {
        id: currentMember.id,
        displayName: currentMember.profile.fullName,
        avatarUrl: currentMember.profile.avatarUrl ?? null,
        roles: currentMember.roles
          ? currentMember.roles.map((r) => r.name)
          : [],
      };

      await update(
        ref(this.db, `${this.dbRefPath}/members/${member.id}`),
        member,
      );
    });
  }

  public async upsertMember() {
    const currentMember = this.sender;
    const member: ConversationMember = {
      id: currentMember.id,
      displayName: currentMember.profile.fullName,
      avatarUrl: currentMember.profile.avatarUrl ?? null,
      roles: currentMember.roles ? currentMember.roles.map((r) => r.name) : [],
    };

    return onValue(
      ref(this.db, `${this.dbRefPath}/members`),
      async (snapshot) => {
        const firebaseMembers = snapshot.val();

        if (!snapshot.exists()) {
          await set(
            ref(this.db, `${this.dbRefPath}/members/${member.id}`),
            member,
          );
        } else {
          for (let id in firebaseMembers) {
            if (id === member.id) {
              const inputObj = {};

              inputObj[`${this.dbRefPath}/members/${member.id}`] = member;

              await update(ref(this.db), inputObj);
            } else {
              await set(
                ref(this.db, `${this.dbRefPath}/members/${member.id}`),
                member,
              );
            }
          }
        }
      },
    );
  }

  public getMembers() {
    return onValue(
      ref(this.db, `${this.dbRefPath}/members`),
      async (snapshot) => {
        if (snapshot.exists()) {
          const members = snapshot.val();

          for (let id in members) {
            if (!this.members.map((m) => m.id).includes(id)) {
              const firebaseMember = members[id] as ConversationMember;

              const localMember = this.users.find((u: User) => u.id === id);

              if (localMember) {
                firebaseMember.avatarUrl = localMember.profile.avatarUrl;
                firebaseMember.displayName = localMember.profile.fullName;
              }

              this.members.push(firebaseMember);
            }

            this.members$.next(this.members);
          }

          const experts = this.members.filter((u) =>
            u.roles.includes(RoleEnum.Expert.toString()),
          );

          this.isMissingExperts$.next(experts.length === 0);
        } else {
          this.isMissingExperts$.next(true);
        }
      },
    );
  }

  public async updateLastReadMessageIdForSender(id: string) {
    this.updateLastReadMessageId(id, this.sender.id);
  }

  public async updateLastReadMessageId(id: string, memberId: string) {
    const inputObj = {};

    inputObj[`${this.dbRefPath}/members/${memberId}/lastReadMessageId`] = id;

    await update(ref(this.db), inputObj);
  }
}
