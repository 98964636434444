import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { Job } from '@modules/job/models/job.model';
import { File as ServerFile, RoleEnum } from '@generated/graphql';
import { ConversationService } from '../../services/conversation.service';
import { SharePipesModule } from '@shared/share-pipes/share-pipes.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConversationMember } from '@shared/classes/conversation/message-type';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-conversation-info',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatTabsModule,
    SharePipesModule,
    MatTooltipModule,
  ],
  templateUrl: './conversation-info.component.html',
  styleUrls: ['./conversation-info.component.scss'],
})
export class ConversationInfoComponent implements OnInit, OnDestroy {
  private _job: Job;
  @Input()
  get job(): Job {
    return this._job;
  }

  @Input()
  members$: BehaviorSubject<ConversationMember[]>;

  set job(value: Job) {
    this._job = value;
  }

  get companyName() {
    return this.job.company.companyName;
  }

  get chatMembers() {
    return this.job.participants;
  }

  public UserRoleEnum = RoleEnum;

  constructor(protected _conversationService: ConversationService) { }

  ngOnInit(): void {
    this._conversationService
      .getFiles(`job_${this.job.id}`)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public getCompanyShortName(companyName: string) {
    const words = companyName.split(' ');
    const shortName = words
      .slice(0, 2)
      .map((word) => word.charAt(0))
      .join('');
    return shortName.toUpperCase();
  }

  ngOnDestroy(): void {
    this._conversationService.files$.complete();
  }

  public downloadFile(file: ServerFile) {
    window.open(file.downloadUrl, '_blank');
  }
}
