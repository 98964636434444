export interface Pagination<T> {
  /** Current page of the cursor */
  currentPage: number;
  /** List of items on the current page */
  data?: T[];
  /** Number of the first item returned */
  from?: number;
  /** Determines if cursor has more pages after the current page */
  hasMorePages: boolean;
  /** The last page (number of pages) */
  lastPage: number;
  /** Number of items returned per page */
  perPage: number;
  /** Number of the last item returned */
  to?: number;
  /** Number of total items selected by the query */
  total: number;
}

export class PaginationData<T> implements Pagination<T> {
  data: T[];
  currentPage: number;
  hasMorePages: boolean;
  lastPage: number;
  perPage: number;
  total: number;

  constructor (
    state: Pagination<T>,
  ) {
    if (!state) {
      throw new Error(`Can't create pagination data instance`);
    }

    Object.assign(this, state);
  }
}
