import { Injectable } from "@angular/core";
import {
  CompanySecretaryInput,
  CompanySecretaryType,
  CreateCompanySecretaryMutation,
  UpdateCompanySecretaryInput, UpdateCompanySecretaryMutation, DeleteCompanySecretaryMutation
} from "@generated/graphql";
import { CreateContract } from "@shared/contracts/create.contract";
import { CompanySecretary } from "@modules/company/models/company-secretary.model";
import { map, Observable } from "rxjs";
import { companySecretaryFactory } from "@modules/company/factories/company-secretary.factory";
import { UpdateContract } from "@shared/contracts/update.contract";
import { DeleteContract } from "@shared/contracts/delete.contract";

@Injectable({
  providedIn: 'root'
})
export class CompanySecretaryApi implements CreateContract<CompanySecretaryInput, CompanySecretary>,
  UpdateContract<UpdateCompanySecretaryInput, Observable<CompanySecretary>>,
  DeleteContract<string, Observable<boolean>> {

  constructor (
    private readonly companySecretaryMutation: CreateCompanySecretaryMutation,
    private readonly updateCompanySecretaryMutation: UpdateCompanySecretaryMutation,
    private readonly deleteCompanySecretaryMutation: DeleteCompanySecretaryMutation
  ) {
  }

  delete$ (id: string): Observable<boolean> {
    return this.deleteCompanySecretaryMutation.mutate({id}).pipe(
      map(res => res.data.deleteCompanySecretary)
    )
  }

  create$ (input: CompanySecretaryInput): Observable<CompanySecretary> {
    return this.companySecretaryMutation.mutate({input}).pipe(
      map(res => res.data.createCompanySecretary
        ? companySecretaryFactory.create(res.data.createCompanySecretary as CompanySecretaryType)
        : null
      )
    )
  }

  update$ (input: UpdateCompanySecretaryInput): Observable<CompanySecretary> {
    return this.updateCompanySecretaryMutation.mutate({input}).pipe(
      map(res => res.data.updateCompanySecretary
        ? companySecretaryFactory.create(res.data.updateCompanySecretary as CompanySecretaryType)
        : null
      )
    )
  }
}
