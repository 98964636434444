import { ModelFactory } from "@shared/factories/model.factory";
import { OfficerIdentificationType } from "@generated/graphql";
import { OfficerPersonIdentification } from "@modules/officer/models/officer-person-identification.model";

export class OfficerPersonIdentificationFactory extends ModelFactory<OfficerIdentificationType, OfficerPersonIdentification> {
  create (state: OfficerIdentificationType): OfficerPersonIdentification {
    return new OfficerPersonIdentification(state);
  }

  fake (): OfficerPersonIdentification {
    throw new Error('Method is not implemented');
  }
}

export const officerPersonIdentificationFactory = new OfficerPersonIdentificationFactory()
