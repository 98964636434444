import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonHelper } from "@shared/helpers/common.helper";
import { MatDialog } from "@angular/material/dialog";
import {
  AddressSelectionDialogComponent, AddressSelectionDialogComponentData, AddressSelectionDialogComponentResult
} from "@shared/component/payments/components/address-selection/address-selection-dialog.component";
import { BillingAddress } from "@modules/address/models/billing-address.model";
import { BehaviorSubject, tap } from "rxjs";
import { cloneDeep } from "lodash-es";
import { StripeBillingDetails } from "@modules/payment/models/stripe-billing-details.model";
import { ModelFactory } from "@shared/factories/model.factory";
import { OrderBillingAddress } from "@modules/order/models/order-billing-address.model";

export interface AddressCardComponentConfig {
  showEditAction: boolean
}

export const addressCardComponentConfig: AddressCardComponentConfig = {
  showEditAction: true
}

export type AddressCardInput = BillingAddress | OrderBillingAddress | StripeBillingDetails

export class AddressCard {
  constructor (
    public fullName: string,
    public phone: string,
    public addressToShow: string,
  ) {
  }
}

export class AddressCardFactory extends ModelFactory<AddressCardInput, AddressCard> {
  create (state: AddressCardInput): AddressCard {
    if (state instanceof OrderBillingAddress || state instanceof BillingAddress) {
      return new AddressCard(
        state.fullName,
        state.phone,
        state.addressToShow,
      )
    }

    return new AddressCard(
      state.name,
      state.phone,
      state.address?.addressToShow,
    );
  }

  fake (): AddressCard {
    throw new Error('Method is not implemented');
  }
}

export const addressCardFactory = new AddressCardFactory();

@Component({
  selector: 'app-address-card[address]',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit, OnChanges {
  @Input() config: AddressCardComponentConfig = cloneDeep(addressCardComponentConfig);
  @Output() changeAddress = new EventEmitter<BillingAddress>()

  private _address: any;
  private _addressCard$ = new BehaviorSubject<AddressCard>(null)

  @Input()
  get address (): AddressCardInput {
    return this._address;
  }

  set address (address) {
    this._address = address;
  }

  get addressCard$ () {
    return this._addressCard$.asObservable()
  }

  constructor (
    protected readonly commonHelper: CommonHelper,
    private dialog: MatDialog
  ) {
  }

  ngOnInit (): void {
    this.setAddressCard(this.address)
  }

  ngOnChanges () {
    this.setAddressCard(this.address)
  }

  public onChangeBillingAddress () {
    if (this.address instanceof BillingAddress) {
      const addressSelectionDialog = this.dialog.open<
        AddressSelectionDialogComponent,
        AddressSelectionDialogComponentData,
        AddressSelectionDialogComponentResult
      >(AddressSelectionDialogComponent, {
        autoFocus: false,
        width: '60%',
        maxWidth: '70%',
        data: {
          selectedAddress: this.address
        }
      });

      addressSelectionDialog
        .afterClosed()
        .pipe(
          tap(address => {
            this.changeAddress.emit(address)
          })
        ).subscribe()
    }
  }

  setAddressCard (address: AddressCardInput): void {
    this._addressCard$.next(address ? addressCardFactory.create(address) : null)
  }
}
