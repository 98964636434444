import { Pipe, PipeTransform } from '@angular/core';
import { formatPhone } from '@shared/helpers/phone.helpers';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform (value: string): string {
    return formatPhone(value);
  }
}
