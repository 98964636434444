import { ModelFactory } from "@shared/factories/model.factory";
import { BusinessProfileType } from "@generated/graphql";
import { BusinessProfile } from "@modules/account-settings/pages/business-profile/models/business-profile.model";

export class BusinessProfileFactory extends ModelFactory<BusinessProfileType, BusinessProfile> {
  create (state: BusinessProfileType): BusinessProfile {
    return new BusinessProfile(state);
  }

  fake (): BusinessProfile {
    throw new Error('Method not implemented')
  }
}

export const businessProfileFactory = new BusinessProfileFactory()
