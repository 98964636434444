import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLinkWithHref } from '@angular/router';
import { AppRoutes, APP_ROUTES } from '@config/app-routes.config';
import { UserType } from '@generated/graphql';
import {
  ACTIVE_USER,
  IMPERSONATING_USER,
} from '@modules/auth/providers/auth.provider';
import { AuthService } from '@modules/auth/services/auth.service';
import { User } from '@modules/user/models/user.model';
import { LetModule } from '@ngrx/component';
import { SharedComponentModule } from '@shared/component/component.module';
import { NotificationsComponent } from '@shared/layout/main-layout/components/notifications/notifications.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-menu-component',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    SharedComponentModule,
    LetModule,
    RouterLinkWithHref,
    MatBadgeModule,
    NotificationsComponent,
  ],
  templateUrl: './top-menu-component.component.html',
  styleUrls: ['./top-menu-component.component.scss'],
})
export class TopMenuComponentComponent implements OnInit {
  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    @Inject(IMPERSONATING_USER)
    public readonly impersonatingUser$: Observable<UserType>,
    @Inject(APP_ROUTES) public appRoutes: AppRoutes,
    protected authService: AuthService,
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  stopImpersonate() {
    this.authService.stopImpersonate();
  }
}
