import { createFeature } from '@ngrx/store';
import {
  authFeatureName,
  authReducer,
} from '@modules/auth/store/reducers/auth.reducer';

export const authFeature = createFeature({
  name: authFeatureName,
  reducer: authReducer,
});

export const {
  selectAuthState,
  selectImpersonatingUser,
  selectUser,
  selectLoginErrorMessages,
  selectMustVerifyEmail,
  selectLoginLoading,
  selectResendVerificationEmailLoading,
  selectRegisterError,
  selectRegisterLoading,
  selectIsRegisterSuccess,
  selectIsResendVerificationEmailSuccess,
  selectVerifyEmailLoading,
  selectIsVerifyEmailSuccess,
  selectIsVerifyEmailError,
  selectSendResetPasswordsLinkLoading,
  selectIsSendResetPasswordsLinkError,
  selectIsSendResetPasswordsLinkSuccess,
  selectSendResetPasswordsLinkError,
  selectIsResetPasswordError,
  selectIsResetPasswordSuccess,
  selectResetPasswordErrorMessages,
  selectResetPasswordLoading
} = authFeature;
