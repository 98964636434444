import {Injectable} from '@angular/core';
import {
  PaymentIntentClientSecretQuery,
  SetupIntentClientSecretQuery,
  StripeOnboardingRequirementsErrors,
  StripeOnboardingRequirementsErrorsQuery
} from "@generated/graphql";
import {map, Observable} from "rxjs";
import {Params} from "@angular/router";
import {Stripe} from "@stripe/stripe-js";


export interface StripeQueryParams extends Params {
  payment_intent?: string
  payment_intent_client_secret?: string
  redirect_status?: string
}

export type ConfirmPaymentOptions = Parameters<Stripe['confirmPayment']>[0]

export type ReturnTypeOfConfirmPayment = ReturnType<Stripe['confirmPayment']>

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(
    private readonly paymentIntentClientSecretQuery: PaymentIntentClientSecretQuery,
    private readonly stripeOnboardingRequirementsErrorsQuery: StripeOnboardingRequirementsErrorsQuery,
    private readonly setupIntentClientSecretQuery: SetupIntentClientSecretQuery,
  ) {
  }

  getPaymentIntentClientSecret(orderId: string, paymentMethodId?: string): Observable<string> {
    return this.paymentIntentClientSecretQuery.fetch({orderId, paymentMethodId}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.paymentIntentClientSecret)
    );
  }

  getOnboardingRequirementsErrors(): Observable<StripeOnboardingRequirementsErrors[]> {
    return this.stripeOnboardingRequirementsErrorsQuery.fetch().pipe(
      map(res => res.data.stripeOnboardingRequirementsErrors)
    );
  }

  getSetupIntentClientSecret(): Observable<string> {
    return this.setupIntentClientSecretQuery.fetch({}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.setupIntentClientSecret)
    );
  }
}
