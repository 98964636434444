import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CreateJobStoreState } from './create-job.reducer';

export const selectCreateJobState =
  createFeatureSelector<CreateJobStoreState>('createJob');

export const selectOperationCountry = createSelector(
  selectCreateJobState,
  (state: CreateJobStoreState) => state.countryOfIncorporation
);
