import {Component, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {filter, tap} from "rxjs";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AuthService} from "@modules/auth/services/auth.service";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tenant-fe';

  constructor(
    private readonly router: Router,
    private readonly screenSpinnerService: ScreenSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      untilDestroyed(this),
      filter(e =>
        e instanceof NavigationStart ||
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationError
      ),
      tap(e => {
        this.screenSpinnerService.setLoading(e instanceof NavigationStart)
      })
    ).subscribe();

    this.route.queryParams.pipe(
      untilDestroyed(this),
      tap(params => {
        if (params.returnUrl) {
          this.authService.returnUrl = params.returnUrl
        }
      })
    ).subscribe()
  }
}
