import { PaymentProviderType, PaymentTypeEnum } from "@generated/graphql";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum, EntityStore, EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { InjectionToken, Provider } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { PaymentProvider } from "@modules/payment/models/payment-provider.model";

export class PaymentProviderStore extends EntityStore<EntityFeatureNameEnum.PaymentProvider, PaymentProviderType, PaymentProvider> {
  readonly selectByName = (name: string) => createSelector(
    this.selectIds,
    this.selectEntities,
    (ids, entities) => ids.map((id) => entities[id]).find(item => item.name === name)
  );

  get selectState (): SelectState<PaymentProviderType> {
    return this.feature.selectPaymentProviderFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.PaymentProvider> {
    return 'PAYMENT_PROVIDER';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.PaymentProvider> {
    return 'paymentProviderFeature';
  }
}

export const paymentProviderStore = new PaymentProviderStore()

export const PAYMENT_PROVIDER_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.PaymentProviderStore)
export const PAYMENT_PROVIDER_STORE_CONFIG: Provider = {
  provide: PAYMENT_PROVIDER_STORE,
  useValue: paymentProviderStore,
}
