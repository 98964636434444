import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApolloModule} from 'apollo-angular';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from 'src/shared/layout/layout.module';
import {AppStoreModule} from '@app/app-store.module';
import {HttpClientModule,} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {APOLLO_CONFIG} from 'src/config/apollo.config';
import {APP_ROUTES_CONFIG} from '@config/app-routes.config';
import {ACTIVE_USER_CONFIG, IMPERSONATING_USER_CONFIG} from '@modules/auth/providers/auth.provider';
import {APP_DB_CONFIG} from '@config/app-db.config';
import {HTTP_XSRF_CONFIG} from '@config/http-xsrf-interceptor';
import {APP_SIDEBAR_CONFIG} from '@config/sidebar.config';
import {APP_VALIDATORS_CONFIG} from '@config/validators.config';
import {ScreenSpinnerComponent} from "@shared/component/full-screen-spinner/screen-spinner.component";
import {MatDatepickerConfig} from "@config/mat-datepicker.config";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApolloModule,
    AppStoreModule,
    ScreenSpinnerComponent,
    MatDatepickerConfig
  ],
  providers: [
    CookieService,
    APP_VALIDATORS_CONFIG,
    APP_DB_CONFIG,
    APOLLO_CONFIG,
    APP_ROUTES_CONFIG,
    ACTIVE_USER_CONFIG,
    APP_SIDEBAR_CONFIG,
    IMPERSONATING_USER_CONFIG,
    HTTP_XSRF_CONFIG,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
