export enum ApprovalEnum {
  Approve = 'APPROVE',
  Decline = 'DECLINE',
}

export enum UseDetailOfEnum {
  New = 'NEW',
  Existing = 'USE_EXISTING',
  Nominee = 'USE_NOMINEE'
}

export enum SnackBarTypeEnum {
  default = 'DEFAULT',
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
}
