import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import {
  createSelectOptionsFromEnum,
  SelectOption,
} from '@shared/helpers/convention.helpers';
import {
  OfficerIdentificationTypeType,
  SalutationEnum,
  SupportedCountryType,
} from '@generated/graphql';
import { FormGroup, Validators } from '@angular/forms';
import { OfficerService } from '@app/modules/officer/services/officer.service';
import { Store } from '@ngrx/store';
import { selectOperationCountry } from '@app/modules/job/store/create-job.selectors';
import { MatSelectChange } from '@angular/material/select';
import { OfficerIndividualFormType } from '@app/modules/officer/models/form/officer-individual-form-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterIdentificationTypesByCountryCode } from '@shared/helpers/identification.helpers';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@UntilDestroy()
@Component({
  selector: 'app-individual-info',
  templateUrl: './individual-info.component.html',
  styleUrls: ['./individual-info.component.scss'],
})
export class IndividualInfoComponent implements OnInit {
  @Input() formGroup: FormGroup<OfficerIndividualFormType>;
  @Input() type = 'person';
  @Input() countryOfIncorporationOverride?: SupportedCountryType;

  @ViewChildren(NgxMatIntlTelInputComponent)
  phoneInputs: QueryList<NgxMatIntlTelInputComponent>;

  salutations$: Observable<SelectOption[]> = of(
    createSelectOptionsFromEnum(SalutationEnum),
  );

  officerIdentificationTypes: OfficerIdentificationTypeType[];
  countryOfIncorporation: SupportedCountryType;

  constructor(
    private officerService: OfficerService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store
      .select(selectOperationCountry)
      .pipe(
        untilDestroyed(this),
        tap((country) => {
          if (this.countryOfIncorporationOverride) {
            this.countryOfIncorporation = this.countryOfIncorporationOverride;

            return;
          }

          this.countryOfIncorporation = country;
        }),
      )
      .subscribe();

    this.officerService.getIdentificationType().subscribe((data) => {
      this.setFilteredIdentificationTypes(data);
    });
  }

  setFilteredIdentificationTypes(data: OfficerIdentificationTypeType[]) {
    this.officerIdentificationTypes = filterIdentificationTypesByCountryCode(
      data,
      this.countryOfIncorporation.country.code,
    );

    if (
      this.formGroup?.controls?.identification?.controls
        ?.officerIdentificationTypeId
    ) {
      this.checkTypeWithoutExpiryDate(
        this.formGroup.controls.identification.controls
          .officerIdentificationTypeId.value,
      );
    }
  }

  onIdentificationTypeChanged({ value: id }: MatSelectChange) {
    this.checkTypeWithoutExpiryDate(id);
  }

  private checkTypeWithoutExpiryDate(id: string): void {
    const typeWithoutExpiry = this.getNRICType(id);
    const control = this.formGroup.controls.identification.controls.expiryDate;

    if (typeWithoutExpiry) {
      control.setValue(null);
      control.removeValidators(Validators.required);
      control.disable();
    } else {
      control.addValidators(Validators.required);
      control.enable();
    }
  }

  private getNRICType(typeId: string) {
    return this.officerIdentificationTypes.find(
      (t) => t.id === typeId && t.name === 'NRIC',
    );
  }

  public resetForm(): void {
    this.phoneInputs.forEach((i) => i.reset());
  }
}
