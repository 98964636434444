import {Component, Input, OnInit}            from '@angular/core';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule}                        from "@angular/common";


export declare type CallOutStyle = 'default' | 'primary' | 'new' | 'new-light' | 'warn' | 'success' | 'error' | 'cancelled' | 'hold'
                                   | 'action'
                                   | 'action-light'
                                   | string

@Component({
               selector: 'app-callout',
               standalone: true,
               templateUrl: './callout.component.html',
               imports: [CommonModule],
               styleUrls: ['./callout.component.scss']
           })
export class CalloutComponent implements OnInit {

    private _style: CallOutStyle = 'default';
    private _customContent: boolean = false;
    private _message: string = '';

    @Input()
    get appearance(): CallOutStyle {
        return this._style;
    }

    set appearance(value: CallOutStyle) {
        this._style = value;
    }

    @Input()
    get customContent(): boolean {
        return this._customContent;
    }

    set customContent(value: BooleanInput) {
        this._customContent = coerceBooleanProperty(value);
    }

    @Input()
    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    constructor() {
    }

    ngOnInit(): void {
    }
}
