import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {AddressType} from "@modules/address/models/address-type.model";
import {AddressTypesQuery} from "@generated/graphql";
import {addressTypeFactory} from "@modules/address/factories/address-type.factory";

@Injectable({
  providedIn: 'root',
})
export class AddressTypeApi {
  constructor(
    private readonly addressTypesQuery: AddressTypesQuery
  ) {
  }

  get$(): Observable<AddressType[]> {
    return this.addressTypesQuery.fetch().pipe(
      map(res => addressTypeFactory.createEntities(res.data.addressTypes))
    )
  }
}
