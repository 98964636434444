import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserType, VerifyEmailInput } from '@generated/graphql';

export const verifyEmailActions = createActionGroup({
  source: 'Email Verification',
  events: {
    'Verify Email': props<{input: VerifyEmailInput}>(),
    'Verify Email Success': props<{user: UserType}>(),
    'Verify Email Error': emptyProps(),
  },
});
