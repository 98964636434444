import { ModelFactory } from "@shared/factories/model.factory";
import { OfficerDocumentType } from "@generated/graphql";
import { OfficerDocument } from "@modules/officer/models/officer-document.model";

export class OfficerDocumentFactory extends ModelFactory<OfficerDocumentType, OfficerDocument> {
  create (state: OfficerDocumentType): OfficerDocument {
    return new OfficerDocument(state);
  }

  createEntities (state: OfficerDocumentType[]): OfficerDocument[] {
    return state ? state.map(item => this.create(item)) : []
  }

  fake (): OfficerDocument {
    throw new Error('This method is not implemented')
  }
}

export const officerDocumentFactory = new OfficerDocumentFactory()
