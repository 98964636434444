import { Injectable } from '@angular/core';
import { OfficerTypeEnum } from '@generated/graphql';
import { OfficerCorporateForm } from './officer-corporate-form.class';
import { OfficerIndividualForm } from './officer-individual-form.class';
import { OfficerAuditorForm } from '@shared/classes/officer/officer-auditor-form.class';

export const officerFormMapper = {
  [OfficerTypeEnum.Individual]: () => new OfficerIndividualForm(),
  [OfficerTypeEnum.Secretary]: () => new OfficerIndividualForm(),
  [OfficerTypeEnum.Corporate]: () => new OfficerCorporateForm(),
  [OfficerTypeEnum.Auditor]: () => new OfficerAuditorForm()
} as const

export type TypeofOfficerFormMapper = typeof officerFormMapper

@Injectable({
  providedIn: 'root',
})
export class OfficerFormFactory {
  constructor () {
  }

  static createForm (type: OfficerTypeEnum): ReturnType<TypeofOfficerFormMapper[keyof TypeofOfficerFormMapper]> {
    const createFormFn = officerFormMapper[type]

    if (!createFormFn) {
      throw Error('Wrong form type');
    }

    return createFormFn()
  }
}
