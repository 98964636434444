import { CountryType } from '@generated/graphql';
import { Country } from '@modules/country/models/country.model';
import { InjectionToken, Provider } from '@angular/core';
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState,
} from '@shared/contracts/entity-store.contract';
import { createSelector } from '@ngrx/store';

export class CountryStore extends EntityStore<
  EntityFeatureNameEnum.Country,
  CountryType,
  Country
> {
  get selectState(): SelectState<CountryType> {
    return this.feature.selectCountryFeatureState;
  }

  getEntry(): EntityEntry<EntityFeatureNameEnum.Country> {
    return 'COUNTRY';
  }

  getFeatureName(): EntityFeatureName<EntityFeatureNameEnum.Country> {
    return 'countryFeature';
  }

  selectReserveCurrencies = createSelector(
    this.selectAllEntity,
    (countries: CountryType[]) => {
      const reserveCurrencies = ['SGD', 'USD', 'EUR', 'HKD', 'JPY'];

      return [
        ...new Set([
          ...reserveCurrencies,
          ...countries.map((c) => c.currency).sort(),
        ]),
      ];
    },
  );
}

export const countryStore = new CountryStore();

export const COUNTRY_STORE = new InjectionToken<EntityStoreEnum>(
  EntityStoreEnum.CountryStore,
);
export const COUNTRY_STORE_CONFIG: Provider = {
  provide: COUNTRY_STORE,
  useValue: countryStore,
};
