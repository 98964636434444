import {cloneDeep} from "lodash-es";

export interface ShowInitialError {
  showInitialError (): void
}

export abstract class ModelConstructor implements ShowInitialError {
  protected constructor (state: object) {
    try {
      if (state) {
        Object.assign(this, state);
      } else {
        this.showInitialError();
      }
    } catch (e) {
      this.showInitialError();
    }
  }

  abstract showInitialError (): void

  copy (): this {
    return cloneDeep(this)
  }
}

export abstract class BaseModel extends ModelConstructor {
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;

  constructor (state: object) {
    super(state);
  }

  abstract getModelName (): string

  showInitialError (): string {
    throw new Error('Something wrong! when create new ' + this.getModelName() + ' instance');
  }
}
