import { ModelFactory } from '@shared/factories/model.factory';
import { StripeBillingDetailsType } from '@generated/graphql';
import { StripeBillingDetails } from "@modules/payment/models/stripe-billing-details.model";

export class StripeBillingDetailsFactory extends ModelFactory<StripeBillingDetailsType, StripeBillingDetails> {
  create (state: StripeBillingDetailsType): StripeBillingDetails {
    return new StripeBillingDetails(state);
  }

  fake (): StripeBillingDetails {
    throw new Error('Method not implemented yet!');
  }
}

export const stripeBillingDetailsFactory = new StripeBillingDetailsFactory();
