import { createAction, emptyProps, props } from '@ngrx/store';

export enum LogoutActionTypes {
  Logout = '[Auth] Logout',
  LogoutError = '[Auth] Logout error',
  LogoutSuccess = '[Auth] Logout success',
}

export const logout = createAction(
  LogoutActionTypes.Logout,
  emptyProps,
);

export const logoutSuccess = createAction(
  LogoutActionTypes.LogoutSuccess,
  props<any>(),
);

export const logoutError = createAction(
  LogoutActionTypes.LogoutError,
  props<any>(),
);

