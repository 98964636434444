import { DateTime } from 'luxon';
// import { DurationPickerOptions } from 'ngx-duration-picker/lib/duration-picker.model';

// export const DEFAULT_DURATION_PICKER_OPTIONS: Partial<DurationPickerOptions> = {
//   showMinutes: false,
//   showSeconds: false,
//   showPreview: false,
//   labels: {
//     years: 'Year(s)',
//     days: 'Day(s)',
//     months: 'Month(s)',
//     weeks: 'Week(s)',
//     hours: 'Hour(s)',
//   },
// };


export enum DateTimeFormat {
  DateTime = 'yyyy-MM-dd HH:mm:ss'
}

export const formatDate = (date: Date, format: string = 'MM/dd/y'): string => {
  try {
    return DateTime.fromJSDate(date).toFormat(format);
  } catch (e) {
    throw new Error('Invalid Date');
  }
};

export const formatDateTime = (date: Date): string => {
  const format = DateTimeFormat.DateTime

  try {
    return DateTime.fromJSDate(date).toFormat(format).toString();
  } catch (e) {
    console.error(e)
    throw new Error('Invalid Date');
  }
};


export const getCurrentYear = () => new Date().getFullYear();

export const minDate = new Date(getCurrentYear() - 100, 0, 1)

export const maxDate = new Date(getCurrentYear() + 100, 12, 31);
