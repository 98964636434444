<div class="main-layout-wrapper" *ngIf="activeUser$ | async as activeUser">
  <ng-container *ngrxLet="businessPlan$ as businessPlan">
    <mat-sidenav-container class="bc-mat-sidenav" *ngIf="sidebar$ | async as sidebar">
      <mat-sidenav mode="side" class="sidebar-wrapper" [ngClass]="{ 'mat-drawer-closed': !sidebar.isOpenSidebar }"
                   [(opened)]="sidebar.isOpenSidebar">
        <section class="tenant-logo-wrapper">
          <app-tenant-logo></app-tenant-logo>
          <button mat-mini-fab class="collapse-btn cursor-pointer" (click)="sidebarHandler(sidebar.isOpenSidebar)">
            <mat-icon *ngIf="!sidebar.isOpenSidebar">navigate_next</mat-icon>
            <mat-icon *ngIf="sidebar.isOpenSidebar">navigate_before</mat-icon>
          </button>
        </section>
        <mat-divider></mat-divider>
        <div class="side-menu-wrapper">
          <section>
            <mat-list class="side-menu-inner" *ngFor="let section of menuSections$ | async">
              <span matSubheader>{{ section.name }}</span>
              <ng-container *ngIf="section.items.length > 0">
                <a mat-list-item [routerLink]="menuItem.navigateTo()" routerLinkActive="active"
                   class="menu-item-wrapper cursor-pointer !text-darkGray"
                   *ngFor="let menuItem of filterHidden(section.items)">
                  <mat-icon mat-list-icon [matTooltipDisabled]="sidebar.isOpenSidebar" matTooltip="{{ menuItem.name }}"
                            matTooltipPosition="right">{{ menuItem.icon }}
                  </mat-icon>
                  <div mat-line>{{ menuItem.name }}</div>
                  <mat-divider class="!mt-2" *ngIf="menuItem.divide"></mat-divider>
                </a>
                <mat-divider class="!mt-2" *ngIf="section.divide"></mat-divider>
              </ng-container>
            </mat-list>
            <ng-container *ngIf="activeUser.isCustomer()">
              <mat-divider class="!mt-2"></mat-divider>
              <app-tenant-link-side-menu [isOpenSidebar]="isOpenSidebar"></app-tenant-link-side-menu>
            </ng-container>
          </section>
          <section>
            <p *ngIf="isOpenSidebar" class="!mt4 text-lightGray mat-caption text-center">
              Copyrights &copy; {{ currentYear }} Becorps
            </p>
          </section>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="content-wrapper"
                           [ngClass]="{ 'mat-drawer-content-collapse': !sidebar.isOpenSidebar }">
        <!--        TOP MENU COMPONENT-->
        <ng-container>
          <app-top-menu-component></app-top-menu-component>
        </ng-container>

        <mat-divider></mat-divider>
        <!--        MAIN CONTENT-->
        <section class="app-content-wrapper">
          <div *ngIf="
              activeUser.isSuperAdmin() &&
                businessPlan.isPremium() &&
                paymentProviders$ | async as paymentProviders
            ">
            <app-callout *ngIf="paymentProviders && paymentProviders.length === 0" customContent appearance="warn"
                         class="block mb-4">
              <p class="!mb-0">
                It looks like payment methods are not enabled for your account
                yet. Please go
                <span class="cursor-pointer" [routerLink]="appRoutes.businessSettings()"
                      [queryParams]="{ tab: 2 }"><a>here</a></span>
                to enable payment methods that best fit your business
              </p>
            </app-callout>
            <ng-container *ngIf="paymentProviders.length">
              <ng-container *ngFor="let method of paymentProviders">
                <ng-container *ngIf="method.isWireTransfer()">
                  <ng-container *ngrxLet="banks$ as banks">
                    <app-callout *ngIf="banks.length === 0" customContent appearance="warn" class="block mb-4">
                      <p class="!mb-0">
                        Please noticed that you haven't added any bank details.
                        Please click
                        <span class="cursor-pointer"
                              [routerLink]="appRoutes.bankManagement()"><a>here</a></span>&nbsp;to add bank information
                        use
                        for Wire Transfer
                        payment.
                      </p>
                    </app-callout>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <router-outlet></router-outlet>
        </section>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</div>
