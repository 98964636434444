import {Injectable} from "@angular/core";
import {GstReturnType, UploadCompanyGstReturnInput, UploadCompanyGstReturnMutation} from "@generated/graphql";
import {map, Observable} from "rxjs";
import {GstReturn} from "@modules/gst-return/models/gst-return.model";
import {gstReturnFactory} from "@modules/gst-return/factories/gst-return.factory";

@Injectable({
  providedIn: 'root'
})
export class GstReturnApi {
  constructor(
    private readonly uploadCompanyGstReturnMutation: UploadCompanyGstReturnMutation
  ) {
  }

  uploadCompanyGstReturn$(input: UploadCompanyGstReturnInput): Observable<GstReturn> {
    return this.uploadCompanyGstReturnMutation.mutate({input}, {
      context: {useMultipart: true}
    }).pipe(
      map(res => gstReturnFactory.tryCreating(res.data.uploadCompanyGstReturn as GstReturnType))
    )
  }
}
