import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output,} from '@angular/core';
import {OfficerHelper} from '@shared/helpers/officer.helper';
import {CommonHelper} from '@shared/helpers/common.helper';
import {
  OfficerInfoDialogComponent
} from '@shared/component/dialog/officer/officer-info-dialog/officer-info-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {BehaviorSubject, filter, Observable, tap} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {CompanyStatusEnum, ShareTypeType} from '@generated/graphql';
import {ShareTypeService} from '@modules/share-type/services/share-type.service';
import {OfficerResolutionActionEnum} from '@shared/types/officerEnum';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {CountryFlagComponent} from '@shared/component/common/country-flag/country-flag.component';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StatusComponent} from '@shared/component/common/status/status.component';
import {MatButtonModule} from '@angular/material/button';
import {NoRecordComponent} from '@shared/component/common/table/no-record/no-record.component';
import {CompanyShareholder} from '@modules/company/models/company-shareholder.model';
import {
  CompanyIndirectShareholderContainerComponent
} from '@modules/company/components/company-indirect-shareholders-container/company-indirect-shareholder-container.component';
import {LetModule, PushModule} from '@ngrx/component';
import {CompanyIndirectShareholder} from '@modules/company/models/company-indirect-shareholder.model';
import {Corporate} from '@modules/company/models/corporate.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Company} from '@modules/company/models/company.model';
import {CompanyEventService} from '@modules/company/services/company-event.service';

export declare type resolutionAppearance =
  | 'default'
  | 'individual'
  | 'corporate'
  | 'viewShareDetail'
  | string;
export declare type resolutionApproach =
  | 'default'
  | 'KYC'
  | 'shareholderResolution'
  | 'directorResolution'
  | 'shareDistribution'
  | string;

@UntilDestroy()
@Component({
  selector: 'app-officer-resolution',
  standalone: true,
  templateUrl: './officer-resolution.component.html',
  styleUrls: ['./officer-resolution.component.scss'],
  imports: [
    CommonModule,
    CountryFlagComponent,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    StatusComponent,
    MatButtonModule,
    NoRecordComponent,
    CompanyIndirectShareholderContainerComponent,
    PushModule,
    LetModule,
  ],
})
export class OfficerResolutionComponent implements OnInit {
  @Output() addingCompanyIndirectShareholder = new EventEmitter<Corporate>();
  @Output() deletingCompanyIndirectShareholder =
    new EventEmitter<CompanyIndirectShareholder>();
  @Output() updatingCompanyIndirectShareholder =
    new EventEmitter<CompanyIndirectShareholder>();

  private _listOfficers;
  private _isHideActions: boolean = false;
  private _appearance: resolutionAppearance = 'default';
  private _approach: resolutionApproach = 'default';
  private _shareTypes: ShareTypeType[];
  public isShowOwnership: boolean = true;
  public officerHelper: OfficerHelper;
  public commonHelper: CommonHelper;
  public displayedColumns: string[] = [
    'type',
    'name',
    'id',
    'nationality',
    'residence',
    'ordinary',
    'preference',
    'other',
    'ownership',
    'kycStatus',
    'actions',
  ];
  protected officerResolutionAction = OfficerResolutionActionEnum;
  protected readonly CompanyStatusEnum = CompanyStatusEnum;

  _selectedCorporates$ = new BehaviorSubject<Corporate[]>([]);

  get selectedCorporates$() {
    return this._selectedCorporates$.asObservable();
  }

  public get shareTypes(): ShareTypeType[] {
    return this._shareTypes;
  }

  @Output() officerResolutionEvent = new EventEmitter<{
    action: OfficerResolutionActionEnum;
    officerInfo: any;
  }>();

  @Input()
  get listOfficers() {
    return this._listOfficers ?? [];
  }

  set listOfficers(officers) {
    this._listOfficers = officers;
  }

  @Input()
  get appearance(): resolutionAppearance {
    return this._appearance;
  }

  set appearance(appearance: resolutionAppearance) {
    this._appearance = appearance;
  }

  @Input()
  get approach(): resolutionApproach {
    return this._approach;
  }

  set approach(purpose: resolutionApproach) {
    this._approach = purpose;
  }

  @Input()
  get hideAction(): boolean {
    return this._isHideActions;
  }

  set hideAction(value: BooleanInput) {
    this._isHideActions = coerceBooleanProperty(value);
  }

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    private shareTypeService: ShareTypeService,
    private dialog: MatDialog,
    protected readonly cdr: ChangeDetectorRef,
    private readonly companyEventService: CompanyEventService,
  ) {
  }

  ngOnInit(): void {
    this.shareTypeService
      .getAll()
      .subscribe((shareTypes) => (this._shareTypes = shareTypes));
    this.officerHelper = OfficerHelper.getInstance();
    this.commonHelper = CommonHelper.getInstance();
    this.isShowOwnership = this.showOwnership(this.listOfficers);

    this.company$
      .pipe(
        untilDestroyed(this),
        filter((company) => !!company),
        tap((company) => {
          this._selectedCorporates$.next(
            this._selectedCorporates$.value
              .map((v) => company.corporates.find((i) => i.id === v.id))
              .filter((v) => !!v),
          );

          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  public get company$(): Observable<Company> {
    return this.companyEventService.company$;
  }

  onViewOfficerInfo(officerInfo) {
    return this.dialog.open(OfficerInfoDialogComponent, {
      autoFocus: false,
      width: '64rem',
      data: {
        officerInfo: officerInfo,
      },
    });
  }

  public calculateColSpan(): number {
    const columnLength = this.displayedColumns.length;
    let colSpan: number = columnLength;

    if (this.kycApproach()) {
      colSpan = columnLength - (this.appearance === 'individual' ? 3 : 4);
    } else if (this.directorResolutionApproach()) {
      colSpan = columnLength - 5;
    } else if (this.shareholderResolutionApproach()) {
      colSpan = columnLength - (this.viewSharesDetail() ? 1 : 4);
    } else if (this.shareDistributionApproach()) {
      colSpan = columnLength - (this.viewSharesDetail() ? 2 : 0);
    }
    return colSpan;
  }

  public onEmitResolutionAction(
    action: OfficerResolutionActionEnum,
    officerInfo,
  ) {
    this.officerResolutionEvent.emit({
      action: action,
      officerInfo: officerInfo,
    });
  }

  public viewSharesDetail(): boolean {
    return this._appearance === 'viewShareDetail';
  }

  public defaultDisplay() {
    return this._appearance === 'default';
  }

  public individualDisplay() {
    return this._appearance === 'individual';
  }

  public corporateDisplay() {
    return this._appearance === 'corporate';
  }

  public defaultApproach(): boolean {
    return this._approach === 'default';
  }

  public kycApproach(): boolean {
    return this._approach === 'KYC';
  }

  public directorResolutionApproach(): boolean {
    return this._approach === 'directorResolution';
  }

  public shareholderResolutionApproach(): boolean {
    return this._approach === 'shareholderResolution';
  }

  public shareDistributionApproach(): boolean {
    return this._approach === 'shareDistribution';
  }

  private showOwnership(officerData): boolean {
    return (this.isShowOwnership = officerData?.length
      ? !this.officerHelper.isShareholder(officerData[0])
      : false);
  }

  canViewShareholders(index: number, data: CompanyShareholder): boolean {
    return data.canViewShareholders;
  }

  addCompanyIndirectShareholder(item: Corporate) {
    this.addingCompanyIndirectShareholder.emit(item);
  }

  deleteCompanyIndirectShareholder(item: CompanyIndirectShareholder) {
    this.deletingCompanyIndirectShareholder.emit(item);
  }

  updateCompanyIndirectShareholder(event: CompanyIndirectShareholder) {
    this.updatingCompanyIndirectShareholder.emit(event);
  }

  backTo(layer: number) {
    this._selectedCorporates$.next(
      this._selectedCorporates$.value.slice(0, layer + 1),
    );
  }

  select(item: Corporate): void {
    this._selectedCorporates$.next([...this._selectedCorporates$.value, item]);
  }

  exist(): void {
    this._selectedCorporates$.next([]);
  }
}
