import { Inject, Injectable } from '@angular/core';
import { Country } from '@modules/country/models/country.model';
import { Store } from '@ngrx/store';
import { CountryFactory, countryFactory } from '@modules/country/factories/country.factory';
import { CountryType } from "@generated/graphql";
import { EntityService } from "@shared/services/entity.service";
import { COUNTRY_STORE, CountryStore } from "@modules/country/store/country.store";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";

@Injectable({
  providedIn: 'root',
})
export class CountryService extends EntityService<EntityFeatureNameEnum.Country, CountryType, Country> {
  constructor (
    @Inject(COUNTRY_STORE) public readonly countryStore: CountryStore,
    protected readonly store: Store,
  ) {
    super(store, countryStore);
  }

  get factory (): CountryFactory {
    return countryFactory;
  }
}
