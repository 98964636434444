import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from "@ngrx/effects";
import { catchError, EMPTY, map, switchMap, tap } from "rxjs";
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";

// import { setBusinessProfile } from "@modules/account-settings/pages/business-profile/store/business-profile.actions";

@Injectable()
export class BusinessProfileEffects {
  loadBusinessProfile$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.service.getBusinessProfileForPublic()),
      tap(businessProfile => {
        this.service.dispatchAddOrUpdateThenSelectEntityAction(businessProfile)
      }),
      catchError((e: any) => {
        console.error(e);
        return EMPTY;
      }),
    )
  }, {
    dispatch: false
  });

  constructor (
    private action$: Actions,
    private service: BusinessProfileService,
  ) {
    //
  }
}
