import {ModelFactory} from "@shared/factories/model.factory";
import {MissingKycDocumentItemType} from "@generated/graphql";
import {MissingKycDocumentItem} from "@modules/officer/models/form/missing-kyc-document-item.model";
import {companyDirectorFactory} from "@modules/company/factories/company-director.factory";
import {companyShareholderFactory} from "@modules/company/factories/company-shareholder.factory";
import {companyIndirectShareholderFactory} from "@modules/company/factories/company-indirect-shareholder.factory";

export class MissingKycDocumentItemFactory extends ModelFactory<MissingKycDocumentItemType, MissingKycDocumentItem> {
  create(state: MissingKycDocumentItemType): MissingKycDocumentItem {
    if (state.__typename === 'CompanyDirectorType') {
      return companyDirectorFactory.create(state)
    } else if (state.__typename === 'CompanyIndirectShareholderType') {
      return companyIndirectShareholderFactory.create(state)
    }

    return companyShareholderFactory.create(state)
  }
}

export const missingKycDocumentItemFactory = new MissingKycDocumentItemFactory()
