<div class="bank-account-dialog-wrapper"
     appInvalidControlScroll
     *ngIf="item">
  <h1 mat-dialog-title
      class="mb-2 text-darkGray !text-[2.2rem] text-center">
    Update bank information
  </h1>
  <form [formGroup]="form">
    <mat-dialog-content class="!flex flex-col">

      <ng-container *ngIf="form.controls.name as nameControl">
        <mat-form-field appearance="outline">
          <mat-label>Bank name</mat-label>
          <input
            [formControl]="nameControl"
            type="text"
            matInput
            placeholder="Bank name">
          <mat-error *ngIf="nameControl.hasError('required')">
            Bank name is required
          </mat-error>
          <mat-error *ngIf="nameControl.hasError('validationErrors')">
            {{ nameControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.controls.holderName as accountNameControl">
        <mat-form-field appearance="outline">
          <mat-label>Account name</mat-label>
          <input
            [formControl]="accountNameControl"
            type="text"
            matInput
            placeholder="Account name">
          <mat-error *ngIf="accountNameControl.hasError('required')">
            Account name is required
          </mat-error>
          <mat-error *ngIf="accountNameControl.hasError('validationErrors')">
            {{ accountNameControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.controls.account as accountNumberControl">
        <mat-form-field appearance="outline">
          <mat-label>Account number</mat-label>
          <input type="text"
                 matInput
                 [formControl]="accountNumberControl"
                 placeholder="Account number">
          <mat-error *ngIf="accountNumberControl.hasError('required')">
            Account number is required
          </mat-error>
          <mat-error *ngIf="accountNumberControl.hasError('accountNumber')">
            {{ accountNumberControl.errors.accountNumber.message }}
          </mat-error>
          <mat-error *ngIf="accountNumberControl.hasError('validationErrors')">
            {{ accountNumberControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.controls.branch as branchControl">
        <mat-form-field appearance="outline">
          <mat-label>Branch</mat-label>
          <input type="text"
                 matInput
                 formControlName="branch"
                 placeholder="Account number">
          <mat-error *ngIf="form.get('branch')?.hasError('required')">
            Branch is required
          </mat-error>
          <mat-error *ngIf="branchControl.hasError('validationErrors')">
            {{ branchControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.controls.swiftCode as swiftCodeControl">
        <mat-form-field appearance="outline">
          <mat-label>SWIFT code</mat-label>
          <input type="text"
                 matInput
                 [formControl]="swiftCodeControl"
                 placeholder="SWIFT code">
          <mat-error *ngIf="swiftCodeControl.hasError('required')">
            SWIFT code is required
          </mat-error>
          <mat-error *ngIf="swiftCodeControl.hasError('code')">
            {{ swiftCodeControl.errors.code.longMessage }}
          </mat-error>
          <mat-error *ngIf="swiftCodeControl.hasError('validationErrors')">
            {{ swiftCodeControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="form.controls.countryId as supportedCountryIdControl">
        <mat-form-field appearance="outline">
          <mat-label>Available</mat-label>
          <app-multi-country-select-input
            supportedCountry
            [limit]="limit"
            [formControl]="supportedCountryIdControl"></app-multi-country-select-input>
          <mat-error *ngIf="supportedCountryIdControl.hasError('validationErrors')">
            {{ supportedCountryIdControl.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>
      </ng-container>

    </mat-dialog-content>

    <mat-dialog-actions align="center">
      <button
        mat-raised-button
        class="bg-grey-800 text-white w-48"
        (click)="onCloseDialog()">Cancel
      </button>
      <button
        class="w-48"
        [disabled]="(commonService.loading$ | async) || form.invalid || form.pristine"
        appConfirmDialog
        title="Update bank account?"
        confirmMessage="Are you sure you want to update bank account infomation?"
        (ok)="onSubmit()"
        mat-raised-button
        color="accent">Update
      </button>
    </mat-dialog-actions>
  </form>
</div>
