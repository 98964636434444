import { BaseModel } from '@shared/models/base.model';
import { FileTypeType }     from '@generated/graphql';
import {replace, startCase} from "lodash-es";

export class FileType extends BaseModel implements FileTypeType {
  id: string;
  name: string;
  _model: string;
  __typename?: 'FileTypeType';

  constructor(state: FileTypeType) {
    super(state);
  }

  get label () {
    return replace(startCase(this.name.toLowerCase()),'Of','of')
  }

  getModelName(): string {
    return FileType.name;
  }
}
