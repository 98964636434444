import { Directive, ElementRef, HostListener } from '@angular/core';
import { debounceTime, filter, fromEvent, take } from 'rxjs';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appInvalidControlScroll]',
})
export class InvalidControlScrollDirective {
  get firstInvalidControl (): HTMLElement {
    return this._firstInvalidControl;
  }

  set firstInvalidControl (value: HTMLElement) {
    this._firstInvalidControl = value;
  }

  private _firstInvalidControl: HTMLElement;

  constructor (
    private el: ElementRef<HTMLElement>,
  ) {
  }

  public scrollToFirstError () {
    this.firstInvalidControl = this.el.nativeElement.querySelector('.ng-invalid');

    if (this.firstInvalidControl) {
      this.firstInvalidControl.scrollIntoView({
        behavior: "auto"
      })
    }
  }

  public static getTopOffset (element: HTMLElement): number {
    const labelOffset = 50;
    return element.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
