import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {defer, from, switchMap, tap} from 'rxjs';
import {Router} from '@angular/router';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {AuthService} from '@modules/auth/services/auth.service';
import {authActions} from '@modules/auth/store/actions/auth.actions';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthEffects {
  onResetAuth$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.resetAuth.type),
    switchMap(() => defer(() => this.authService.resetAuthFromLocalStorage())),
    tap(() => {
      this.cookieService.deleteAll();
    }),
    switchMap(() => from(this.router.navigate(this.appRoutes.login()))),
  ), {
    dispatch: false,
  });

  constructor(
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private authService: AuthService,
    private cookieService: CookieService,
    private actions$: Actions,
    private router: Router,
  ) {
  }
}
