import {Injectable} from '@angular/core';
import {User} from '@modules/user/models/user.model';
import {combineLatestWith, map, Observable, of, switchMap} from 'rxjs';
import {userFactory} from '@modules/user/factories/user.factory';
import {AuthService} from '@modules/auth/services/auth.service';
import {CountryType} from '@generated/graphql';
import {SupportedCountryService} from "@modules/supported-country/services/supported-country.service";
import {countryFactory} from "@modules/country/factories/country.factory";

@Injectable({
  providedIn: 'root',
})
export class UserPolicy {
  constructor(
    protected readonly authService: AuthService,
    protected readonly supportedCountryService: SupportedCountryService,
  ) {
  }

  canUpdateRole$(): Observable<boolean> {
    return this.authService.activeUser$.pipe(map(activeUser => activeUser && activeUser.isSuperAdmin()));
  }

  canDeleteCountryOfResponsibility$(country: CountryType): Observable<boolean> {
    return this.authService.activeUser$
      .pipe(
        switchMap(activeUser => {
          if (!activeUser) {
            return of(false);
          }

          if (activeUser.isSuperAdmin()) {
            return this.supportedCountryService.isActiveCountriesIncludes$(countryFactory.create(country))
          }

          if (activeUser.isAdmin()) {
            return this.supportedCountryService.isFilterResponsibilitiesIncludes$(activeUser.profile.responsibilities, countryFactory.create(country));
          }

          return of(false);
        }),
      );
  }
}
