import { BaseModel } from "@shared/models/base.model";
import { Country } from "@modules/country/models/country.model";
import { OfficerPersonIdentificationType } from "@modules/officer/models/officer-person-identification-type.model";
import { OfficerIdentificationType } from "@generated/graphql";
import {
  officerPersonIdentificationTypeFactory
} from "@modules/officer/factories/officer-person-identification-type.factory";
import { startCase } from "lodash-es";
import { DateTime } from "luxon";

export class OfficerPersonIdentification extends BaseModel implements OfficerIdentificationType {
  id: string;
  name: string;
  nationality: Country;
  expiryDate?: Date
  identificationNumber: string;

  officerIdentificationType: OfficerPersonIdentificationType;

  constructor (state: OfficerIdentificationType) {
    super(state);

    this.officerIdentificationType = state.officerIdentificationType
      ? officerPersonIdentificationTypeFactory.create(state.officerIdentificationType)
      : null
  }

  get displayIdentification (): string {
    return [
      startCase(this.officerIdentificationType.name.toLowerCase()),
      'number:',
      this.identificationNumber,
      this.expiryDate ? `- Expiry on: ${DateTime.fromJSDate(new Date(this.expiryDate)).toLocaleString(DateTime.DATE_FULL)}` : null
    ].filter(item => !!item).join(' ')
  }

  getModelName (): string {
    return OfficerPersonIdentification.name;
  }
}
