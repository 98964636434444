import {ModelFactory} from "@shared/factories/model.factory";
import {CompanyGstReturnType} from "@generated/graphql";
import {CompanyGstReturn} from "@modules/company/models/company-gst-return.model";

export class CompanyGstReturnFactory extends ModelFactory<CompanyGstReturnType, CompanyGstReturn> {
  create(state: CompanyGstReturnType): CompanyGstReturn {
    return new CompanyGstReturn(state)
  }
}

export const companyGstReturnFactory = new CompanyGstReturnFactory()
