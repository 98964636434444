import {AbstractControl, FormBuilder} from '@angular/forms';
import {BehaviorSubject, catchError, Observable, of, tap} from 'rxjs';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {MatDialogRef} from '@angular/material/dialog';
import {User} from '@modules/user/models/user.model';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {untilDestroyed} from '@ngneat/until-destroy';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {SnackBarTypeEnum} from "@shared/types/commonEnum";
import {AddressType} from "@modules/address/models/address-type.model";
import {AddressTypeApi} from "@modules/address/api/address-type.api";
import {AddressTypeEnum} from '@generated/graphql';

export abstract class AbstractAddressDialogComponent<Control extends AbstractControl, DialogRef, DialogData, Result> {
  AddressTypeEnum = AddressTypeEnum

  control: Control;

  addressTypes$: Observable<AddressType[]> = this.addressTypeApi.get$()

  protected abstract getControl(userId: string): Control;

  protected abstract getQuery$(): Observable<Result>

  abstract get label(): string

  get address$(): Observable<BillingAddress> {
    return this._address$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get selectedAddressType$(): Observable<AddressType> {
    return this._selectedAddressType$.asObservable();
  }

  protected readonly _loading$ = new BehaviorSubject<boolean>(false);

  protected readonly _address$ = new BehaviorSubject<BillingAddress>(null);

  protected readonly _selectedAddressType$ = new BehaviorSubject<AddressType>(null);

  protected constructor(
    protected readonly data: DialogData,
    protected readonly activeUser$: Observable<User>,
    protected readonly dialogRef: MatDialogRef<DialogRef>,
    protected readonly fb: FormBuilder,
    protected readonly billingAddressApi: BillingAddressApi,
    protected readonly snackBarService: SnackBarService,
    protected readonly addressTypeApi: AddressTypeApi
  ) {
  }

  init(): void {
    this.activeUser$.pipe(
      untilDestroyed(this),
      tap(user => {
        if (!user) {
          this.control = null;
        }

        this.control = this.getControl(user.id);
      }),
    ).subscribe();
  }

  closeDialog(result: Result): void {
    this.dialogRef.close(result);
  }

  protected setAddress(address: BillingAddress) {
    this._address$.next(address);
  }

  onSubmit(): void {
    this.snackBarService.dismiss();

    this._loading$.next(true);

    this.getQuery$()
      .pipe(
        tap(result => {
          this._loading$.next(false);

          if (!result) {
            this.snackBarService.pushErrorMessage()
            this.closeDialog(result);
            return;
          }

          this.snackBarService.pushAlert(this.label + ' successfully!', null, SnackBarTypeEnum.success);

          this.closeDialog(result);
        }),
        catchError(({graphQLErrors}) => {
          this._loading$.next(false);

          return of(graphQLErrors);
        }),
      ).subscribe();
  }
}
