import { Injectable } from '@angular/core';
import {
  SupportedCountryType,
  SupportedCountriesQuery,
  SupportedCountryInput,
  UpdateSupportedCountryMutation
} from '@generated/graphql';
import { map, Observable } from 'rxjs';
import { UpdateContract } from "@shared/contracts/update.contract";
import { SupportedCountry } from "@modules/supported-country/models/supported-country.model";
import { supportedCountryFactory } from "@modules/supported-country/factories/supported-country.factory";

@Injectable({
  providedIn: 'root',
})
export class SupportedCountryApi implements UpdateContract<SupportedCountryInput, Observable<SupportedCountry>> {
  constructor (
    protected readonly supportedCountriesQuery: SupportedCountriesQuery,
    protected readonly updateSupportedCountryMutation: UpdateSupportedCountryMutation,
  ) {
  }

  get (): Observable<SupportedCountryType[]> {
    return this.supportedCountriesQuery.watch().valueChanges.pipe(
      map(res => res.data.supportedCountries as SupportedCountryType[]),
    );
  }

  update$ (input: SupportedCountryInput): Observable<SupportedCountry> {
    return this.updateSupportedCountryMutation
      .mutate({input})
      .pipe(
        map(res =>
          res.data.updateSupportedCountry
            ? supportedCountryFactory.create(res.data.updateSupportedCountry as SupportedCountryType)
            : null
        ))
  }
}
