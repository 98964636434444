import { ModelFactory } from '@shared/factories/model.factory';
import {
  CreateServiceSupportedCountryInput,
  ServiceSupportedCountryType,
  UpdateServiceSupportedCountryInput
} from '@generated/graphql';
import { faker } from '@faker-js/faker';
import { ServiceSupportedCountry } from '@modules/service/models/service-supported-country.model';
import { supportedCountryFactory } from '@modules/supported-country/factories/supported-country.factory';
import { serviceFactory } from '@modules/service/factories/service.factory';
import { paymentTypeFactory } from '@modules/payment/factories/payment-type.factory';
import { cloneDeep } from "lodash-es";
import {
  ServiceSupportedCountryForm
} from "@modules/account-settings/pages/business-profile/components/dialog/service-dialog/service-dialog.component";
import { Service } from "@modules/service/models/service.model";
import { SupportedCountry } from "@modules/supported-country/models/supported-country.model";

export class ServiceSupportedCountryFactory extends ModelFactory<ServiceSupportedCountryType, ServiceSupportedCountry> {
  create (state: ServiceSupportedCountryType): ServiceSupportedCountry {
    return new ServiceSupportedCountry(state);
  }

  convertDayToEstimatedTime (day: string | number) {
    return 'P' + day + 'D'
  }

  createCreateInput (
    service: Service,
    supportedCountry: SupportedCountry,
    form: ServiceSupportedCountryForm
  ): CreateServiceSupportedCountryInput {
    const data = cloneDeep(form.value);

    let {
      estimatedTime,
      serviceFee,
      label,
      officialFee,
      officialFeeTaxIncludable,
      serviceFeeTaxIncludable,
      paymentTypeId,
      scopeOfWork,
      description,
      icon
    } = data;

    estimatedTime = this.convertDayToEstimatedTime(estimatedTime)

    return {
      serviceId: service.id,
      supportedCountryId: supportedCountry.id,
      estimatedTime,
      serviceFee,
      label,
      officialFee,
      officialFeeTaxIncludable,
      serviceFeeTaxIncludable,
      paymentTypeId,
      scopeOfWork,
      description,
      icon
    }
  }

  createUpdateInput (item: ServiceSupportedCountry, form: ServiceSupportedCountryForm): UpdateServiceSupportedCountryInput {
    const data = cloneDeep(form.value);
    const address = cloneDeep(form.controls.address.getRawValue());

    let {
      id,
      estimatedTime,
      serviceFee,
      label,
      officialFee,
      officialFeeTaxIncludable,
      serviceFeeTaxIncludable,
      paymentTypeId,
      scopeOfWork,
      description,
      icon
    } = data;

    estimatedTime = this.convertDayToEstimatedTime(estimatedTime)

    const input: UpdateServiceSupportedCountryInput = {
      id,
      label,
      officialFeeTaxIncludable,
      serviceFeeTaxIncludable,
      estimatedTime,
      serviceFee,
      officialFee,
      scopeOfWork,
      description,
      paymentTypeId,
      icon,
      address,
      // updateServiceData: updateServiceData as UpdateServiceInput,
    }

    if (!item.service?.isRegisteredAddress()) {
      delete input.address
    }

    return input
  }

  fake (): ServiceSupportedCountry {
    return this.create({
      id: faker.datatype.uuid(),
      paymentType: paymentTypeFactory.fake(),
      service: serviceFactory.fake(),
      supportedCountry: supportedCountryFactory.fake(),
      supportedCountryId: faker.datatype.uuid(),
      serviceFee: faker.datatype.number(),
      officialFee: faker.datatype.number(),
      serviceFeeTaxIncludable: faker.datatype.boolean(),
      officialFeeTaxIncludable: faker.datatype.boolean(),
      description: faker.lorem.paragraph(),
      scopeOfWork: faker.lorem.paragraph(),
      estimatedTime: 'P' + faker.datatype.number({min: 1, max: 10}) + 'D',
      isActive: faker.datatype.boolean(),
      isArchive: faker.datatype.boolean(),
      label: faker.name.jobType(),
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
      _model: '',
    });
  }
}

export const serviceSupportedCountryFactory = new ServiceSupportedCountryFactory();
