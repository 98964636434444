import { ModelFactory } from '@shared/factories/model.factory';
import { StripeBillingDetailsAddressType } from '@generated/graphql';
import { StripeBillingDetailsAddress } from "@modules/payment/models/stripe-billing-details-address.model";

export class StripeBillingDetailsAddressFactory extends ModelFactory<StripeBillingDetailsAddressType, StripeBillingDetailsAddress> {
  create (state: StripeBillingDetailsAddressType): StripeBillingDetailsAddress {
    return new StripeBillingDetailsAddress(state);
  }

  fake (): StripeBillingDetailsAddress {
    throw new Error('Method not implemented yet!');
  }
}

export const stripeBillingDetailsAddressFactory = new StripeBillingDetailsAddressFactory();
