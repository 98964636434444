<div [formGroup]="formGroup">
  <div class="individual-info-wrapper">
    <mat-form-field appearance="outline" class="w-1/3">
      <mat-label>Salutation</mat-label>
      <mat-select
        disableRipple
        class="text-transform: capitalize"
        formControlName="salutation"
      >
        <mat-option
          class="text-transform: capitalize"
          *ngFor="let item of salutations$ | async"
          [value]="item.value"
          >{{ item.value | salutation }}</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="formGroup.controls.salutation.hasError('validationErrors')"
      >
        {{ formGroup.controls.salutation.getError("validationErrors") }}
      </mat-error>
    </mat-form-field>
    <div class="grid grid-cols-2 gap-2">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="firstName" />
        <mat-error *ngIf="formGroup.get('firstName')?.hasError('required')">
          First name required
        </mat-error>
        <mat-error
          *ngIf="formGroup.controls.firstName.hasError('validationErrors')"
        >
          {{ formGroup.controls.firstName.getError("validationErrors") }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" formControlName="lastName" />
        <mat-error *ngIf="formGroup.get('lastName')?.hasError('required')">
          Last name required
        </mat-error>
        <mat-error
          *ngIf="formGroup.controls.lastName.hasError('validationErrors')"
        >
          {{ formGroup.controls.lastName.getError("validationErrors") }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2" formGroupName="identification">
      <mat-form-field appearance="outline">
        <mat-label>Identification Document</mat-label>
        <mat-select
          formControlName="officerIdentificationTypeId"
          (selectionChange)="onIdentificationTypeChanged($event)"
        >
          <mat-option
            *ngFor="let type of officerIdentificationTypes"
            class="capitalize"
            [value]="type.id"
            >{{ type.name }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            formGroup
              .get('identification')
              .get('officerIdentificationTypeId')
              ?.hasError('required')
          "
        >
          Identification document required
        </mat-error>
        <mat-error
          *ngIf="
            formGroup.controls.identification.controls.officerIdentificationTypeId.hasError(
              'validationErrors'
            )
          "
        >
          {{
            formGroup.controls.identification.controls.officerIdentificationTypeId.getError(
              "validationErrors"
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Identification No</mat-label>
        <input
          matInput
          placeholder="Identification No"
          formControlName="identificationNumber"
        />
        <mat-error
          *ngIf="
            formGroup
              .get('identification')
              .get('identificationNumber')
              ?.hasError('required')
          "
        >
          Identification number required
        </mat-error>
        <mat-error
          *ngIf="
            formGroup.controls.identification.controls.identificationNumber.hasError(
              'validationErrors'
            )
          "
        >
          {{
            formGroup.controls.identification.controls.identificationNumber.getError(
              "validationErrors"
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nationality/Citizenship</mat-label>
        <app-country-select-input
          formControlName="nationalityId"
          [required]="true"
        ></app-country-select-input>
        <mat-error
          *ngIf="
            formGroup
              .get('identification')
              .get('nationalityId')
              ?.hasError('required')
          "
        >
          Nationality required
        </mat-error>
        <mat-error
          *ngIf="
            formGroup.controls.identification.controls.nationalityId.hasError(
              'validationErrors'
            )
          "
        >
          {{
            formGroup.controls.identification.controls.nationalityId.getError(
              "validationErrors"
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Expiry Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="expiryDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error
          *ngIf="
            formGroup
              .get('identification')
              .get('expiryDate')
              ?.hasError('required')
          "
        >
          Expiry date required
        </mat-error>
        <mat-error
          *ngIf="
            formGroup.controls.identification.controls.expiryDate.hasError(
              'validationErrors'
            )
          "
        >
          {{
            formGroup.controls.identification.controls.expiryDate.getError(
              "validationErrors"
            )
          }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-divider class="!my-4 w-1/2 !m-auto"></mat-divider>
  <!--    Contact Information-->
  <section class="contact-information">
    <p class="col-span-2 mat-title !text-base text-darkGray !mb-1">
      Contact Information
    </p>
    <div class="grid grid-cols-2 gap-2">
      <!--        Email-->
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          placeholder="Shareholder's email"
          type="email"
          formControlName="email"
        />
        <mat-error *ngIf="formGroup.get('email')?.hasError('required')">
          Email required
        </mat-error>
        <mat-error *ngIf="formGroup.get('email')?.hasError('email')"
          >Please input valid email format</mat-error
        >
      </mat-form-field>
      <!--        Phone-->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Phone</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['sg', 'hk', 'th', 'fr', 'us', 'uk']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          formControlName="phone"
          [required]="true"
          name="phone"
          #phone
        ></ngx-mat-intl-tel-input>
        <mat-error *ngIf="formGroup.controls.phone.hasError('required')">
          Phone required
        </mat-error>
        <mat-error *ngIf="formGroup.controls.phone.hasError('phone')"
          >Invalid number</mat-error
        >
        <mat-error
          *ngIf="formGroup.controls.phone.hasError('validationErrors')"
        >
          {{ formGroup.controls.phone.getError("validationErrors") }}
        </mat-error>
      </mat-form-field>
      <!--Address-->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Address Line</mat-label>
        <input
          type="text"
          matInput
          placeholder="Address line"
          formControlName="address"
        />
        <mat-error *ngIf="formGroup.get('address')?.hasError('required')">
          Address is required
        </mat-error>
        <mat-error
          *ngIf="formGroup.controls.address.hasError('validationErrors')"
        >
          {{ formGroup.controls.address.getError("validationErrors") }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Apartment , suite , etc.</mat-label>
        <input
          type="text"
          matInput
          formControlName="apartment"
          placeholder="Apartment, suite, unit, building, floor, etc..."
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input type="text" matInput formControlName="city" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>State / Province</mat-label>
        <input type="text" matInput formControlName="state" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Zipcode / Postal code</mat-label>
        <input matInput placeholder="569933" formControlName="zip" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Country/Region</mat-label>
        <app-country-select-input
          formControlName="countryId"
          [required]="true"
        ></app-country-select-input>
        <mat-error *ngIf="formGroup.get('countryId')?.hasError('required')">
          Country is required
        </mat-error>
        <mat-error
          *ngIf="formGroup.controls.countryId.hasError('validationErrors')"
        >
          {{ formGroup.controls.countryId.getError("validationErrors") }}
        </mat-error>
      </mat-form-field>
    </div>
  </section>
</div>
