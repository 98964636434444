import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService } from '@app/modules/job/services/job.service';
import { FileType } from '@generated/graphql';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-files',
  templateUrl: './job-files.component.html',
  styleUrls: ['./job-files.component.scss'],
})
export class JobFilesComponent implements OnInit {
  fileList$: Observable<FileType[]>;

  constructor(
    private readonly jobService: JobService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const jobId = params.get('id');

      this.fileList$ = this.jobService.allJobFiles(jobId);
    });
  }
}
