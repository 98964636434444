<div class="table-container">
  <table
    class="table-custom"
    mat-table
    multiTemplateDataRows
    [dataSource]="shareholders">
    <ng-container *ngIf="type === 'OfficerIndirectCompanyType'">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="!w-20">
          Type
        </th>
        <td mat-cell *matCellDef="let element" class="!w-20">
          <mat-icon [matTooltip]="'Corporate'">apartment</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="line-clamp-3 !mb-0">
            {{ element.officer.name }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Incorporation Country
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <app-country-flag [id]="element.officer.officerProfile.country.id" showCountryName></app-country-flag>
        </td>
      </ng-container>

      <ng-container matColumnDef="percentageOfShares">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Ownership
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          {{ element.ownership | percent:'.0-6' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentsStatus">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          KYC Status
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <ng-template *ngTemplateOutlet="kycStatusTemplate; context: {element}"></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Actions
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <ng-template *ngTemplateOutlet="baseActions; context: {element}"></ng-template>
        </td>
      </ng-container>

      <ng-container *ngIf="type === 'OfficerIndirectCompanyType'" matColumnDef="addIndirectShareholder">
        <td mat-cell *matCellDef="let element" [colSpan]="displayedColumns.length">
          <div
            class="py-2 px-2 ml-[9.2rem] bg-primary-50 inline-flex align-middle text-primary-default w-[calc(100%_-_9.2rem)]">
            <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
            <span>{{ element.officer.name }} hold &#8805; 25% of {{ company.name }} capital. Click
              <span
                (click)="select(element)"
                class="underline cursor-pointer">here</span> to see the details of it shareholders </span>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'OfficerIndirectPersonType'">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="!w-20">
          Type
        </th>
        <td mat-cell *matCellDef="let element" class="!w-20">
          <div class="relative">
            <mat-icon [matTooltip]="'Individual'">person</mat-icon>
            <div *ngIf="element.isUBO">
                    <span
                      class="absolute bg-[#E6F9F0] text-green-default py-1 px-2 rounded-lg
                             -bottom-3 -left-[.6rem]
                             text-[1rem] font-['OpenSans_Semibold'] !font-semibold">UBO</span>
            </div>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="line-clamp-3 !mb-0">
            {{ element.officer.name }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="percentageOfShares">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Ownership
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          {{ element.ownership | percent:'.0-6' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentsStatus">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          KYC Status
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <ng-template *ngTemplateOutlet="kycStatusTemplate; context: {element}"></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="residence">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Residence
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <app-country-flag [id]="element.officer.officerProfile.country.id" showCountryName></app-country-flag>
        </td>
      </ng-container>

      <ng-container matColumnDef="nationality">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Nationality
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <app-country-flag [id]="element.officer.officerProfile.nationality.id" showCountryName></app-country-flag>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="!text-center">
          Actions
        </th>
        <td mat-cell *matCellDef="let element" class="!text-center">
          <div class="flex flex-row w-full justify-center">
            <ng-template *ngTemplateOutlet="baseActions; context: {element}"></ng-template>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'add-indirect':row.canViewShareholders}"></tr>
    <ng-container *ngIf="type === 'OfficerIndirectCompanyType'">
      <tr mat-row class="add-indirect-message" *matRowDef="let row; columns: ['addIndirectShareholder']"
          [ngClass]="{'hidden': !row.canViewShareholders}">
      </tr>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td [colSpan]="3">
        <table-no-record></table-no-record>
      </td>
    </tr>
  </table>
</div>

<ng-template #baseActions let-element="element">
  <button
    *ngIf="element?.canUpdate"
    color="accent"
    mat-icon-button
    [matTooltip]="'Update'"
    (click)="update(element)">
    <mat-icon>edit</mat-icon>
  </button>

  <button
    appConfirmDialog
    (ok)="delete(element)"
    *ngIf="element?.canDelete"
    color="warn"
    mat-icon-button
    [matTooltip]="'Delete'">
    <mat-icon>delete</mat-icon>
  </button>

  <button
    *ngIf="element.isRequiredKyc"
    mat-icon-button
    matTooltip="Check KYC" color="primary"
    (click)="checkKyc(element)">
    <mat-icon class="cursor-pointer">rule</mat-icon>
  </button>
</ng-template>

<ng-template #kycStatusTemplate let-element="element">
  <app-status
    class="inline-flex"
    *ngIf="element.documentStatusLabel && element.isRequiredKyc; else noActionsRequired"
    [statusMessage]="element.documentStatusLabel"
    [statusStyle]="element.documentsStatusStyle"></app-status>
  <ng-template #noActionsRequired>-</ng-template>
</ng-template>
