import {Injectable} from '@angular/core';
import {
  AddressType,
  CompanyRegisteredAddressInput,
  CountryType,
  FileTypeEnum, PaymentStatusEnum,
  ShareTypeEnum,
} from '@generated/graphql';
import {User} from "@modules/user/models/user.model";
import {AppStatusType} from "@shared/component/common/status/status.component";

@Injectable({
  providedIn: 'root',
})
export class CommonHelper {
  private static instance: CommonHelper;

  public static getInstance(): CommonHelper {
    if (!CommonHelper.instance) {
      this.instance = new CommonHelper();
    }
    return CommonHelper.instance;
  }

  public renderAddress(addressInfo: AddressType): string {
    const {apartment, address, city, state, zip, country} = addressInfo;
    return [apartment, address, city, state, country?.name, zip]
      .filter(Boolean)
      .join(', ');
  }

  public renderAddressFromInput(
    addressInfo: CompanyRegisteredAddressInput,
    countries: CountryType[],
  ): string {
    const {apartment, address, city, state, zip} = addressInfo;

    const country = countries.find((c) => c.id === addressInfo.countryId);

    return [apartment, address, city, state, country?.name, zip]
      .filter(Boolean)
      .join(', ');
  }

  public getCancelJobConfirmMessage(user: User): string {
    if (user.isCustomer()) {
      return 'Are you sure you want to cancel this job request? Please note that cancelling this request will notify the expert to stop their work. All progress or updates will be lost.\n\nIf you still wish to cancel, please click Confirm';

    }
    return 'Are you sure you want to close this job request? Please note that closing this request will notify the relevant experts to stop their work. All progress or updates will be lost.\n\nIf you still wish to close, please click Confirm';
  }

  public getMarkJobDoneMessage(): string {
    return 'Once you mark the job as done, an immediate email notification will be sent to the customer to inform them of the job\'s completion.\n\nAre you sure to proceed?';
  }

  public getExpertConfirmJobMessage(): string {
    return 'Please take a moment to examine and approve the customer request. Once you\'ve confirmed, an immediate email notification will be sent to the customer.\n\n Select Confirm to move forward or Cancel to revisit the request details.'
  }

  public getShareTypeHint(shareType): string {
    switch (shareType) {
      case ShareTypeEnum.Ordinary: {
        return 'These are standard shares that entitle the shareholder to voting rights and dividends. Owners usually have one vote per share and receive dividends only after they\'re distributed to preference shareholders.';
      }
      case ShareTypeEnum.Preference: {
        return 'These shares give owners the advantage of receiving dividends before ordinary shareholders. They typically don\'t come with voting rights.';
      }
      case ShareTypeEnum.Other: {
        return 'This category might refer to different types of specific shares such as non-dividend distribution, non-voting shares, etc. The rights and benefits depend on the company\'s articles of association.';
      }
      default: {
        return '';
      }
    }
  }

  public getDocumentHint(fileType: FileTypeEnum): string {
    switch (fileType) {
      case FileTypeEnum.Passport: {
        return 'We require any document allowing identify a person. If you do not have a passport, please attached your national identity card, birth certificate or any documents showing all the following information: (i) full name, including alias, (ii), date of birth and (iii) nationality';
      }
      case FileTypeEnum.ProofOfAddress: {
        return 'We require any document attesting your current residential address. Please note that this document shall have been produced not more than 3 months ago. It can be a phone or utility bill and needs to show your name and address.'
      }
      default: {
        return ''
      }
    }
  }

  renderPaymentStatusColor(status: PaymentStatusEnum): AppStatusType {
    switch (status) {
      case PaymentStatusEnum.AwaitingApproval:
        return "warn";
      case PaymentStatusEnum.AttemptingPayment:
        return "warn";
      case PaymentStatusEnum.ProcessingPayment:
        return "warn";
      case PaymentStatusEnum.Paid:
        return 'success';
      case PaymentStatusEnum.Failed:
        return "error";
      case PaymentStatusEnum.Rejected:
        return "error";
      default:
        return 'default';
    }
  }


  public addHttps(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url;
  }
}
