import { BaseModel } from "@shared/models/base.model";
import { StripeBillingDetailsType } from "@generated/graphql";
import { StripeBillingDetailsAddress } from "@modules/payment/models/stripe-billing-details-address.model";
import { stripeBillingDetailsAddressFactory } from "@modules/payment/factories/stripe-billing-details-address.factory";

export class StripeBillingDetails extends BaseModel implements StripeBillingDetailsType {
  email: string;
  address?: StripeBillingDetailsAddress;
  name?: string;
  phone?: string;

  constructor (state: StripeBillingDetailsType) {
    super(state);

    this.address = state.address ? stripeBillingDetailsAddressFactory.create(state.address) : null
  }

  getModelName (): string {
    return StripeBillingDetails.name;
  }
}
