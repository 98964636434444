import { BaseModel } from '@shared/models/base.model';
import { ServiceEnum, ServiceType } from '@generated/graphql';
import { ServiceCategory } from './service-category.model';
import { serviceCategoryFactory } from "@modules/service/factories/service-category.factory";

export class Service extends BaseModel implements ServiceType {
  id: string;
  name: string;
  label: string;
  serviceCategory: ServiceCategory;

  constructor (state: ServiceType) {
    super(state);

    this.serviceCategory = state.serviceCategory ? serviceCategoryFactory.create(state.serviceCategory) : null
  }

  getModelName (): string {
    return Service.name;
  }

  isRegisteredAddress (): boolean {
    return this.name === ServiceEnum.RegisteredAddress;
  }

  isCustomService (): boolean {
    return this.name === ServiceEnum.CustomService
  }
}
