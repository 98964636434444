<div [formGroup]="formGroup"
     class="corporate-info-wrapper">
  <!--  Corporate Information-->
  <section class="corporate-info-container grid grid-cols-2 gap-2">
    <p class="col-span-2 mat-title !text-base text-darkGray !mb-1">
      Corporate Information
    </p>
    <!--    Corporate Name-->
    <mat-form-field appearance="outline">
      <mat-label>Corporate Name</mat-label>
      <input matInput
             placeholder="Corporate Name"
             formControlName="name"/>
      <mat-error *ngIf="formGroup.get('name')?.hasError('required')">
        Corporate name required
      </mat-error>
    </mat-form-field>
    <!--Country of Incorporation-->
    <mat-form-field appearance="outline">
      <mat-label>Country of Incorporation</mat-label>
      <app-country-select-input formControlName="countryId"
                                required></app-country-select-input>
      <mat-error *ngIf="formGroup.get('countryId')?.hasError('required')">
        Country of incorporation required
      </mat-error>
    </mat-form-field>
    <!--Reg num-->
    <mat-form-field appearance="outline">
      <mat-label>Registration Number</mat-label>
      <input matInput
             placeholder="Registration Number"
             formControlName="registrationNumber"/>
      <mat-error *ngIf="formGroup.get('registrationNumber')?.hasError('required')">
        Registration number required
      </mat-error>
    </mat-form-field>
    <!--Incor Data-->
    <mat-form-field appearance="outline">
      <mat-label>Incorporation Date</mat-label>
      <input matInput
             [matDatepicker]="picker"
             placeholder="Incorporation Date"
             formControlName="registrationDate"/>
      <mat-datepicker-toggle matSuffix
                             [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="formGroup.get('registrationDate')?.hasError('required')">
        Incorporation date required
      </mat-error>
    </mat-form-field>
    <!--    Website-->
    <mat-form-field appearance="outline">
      <mat-label>Website</mat-label>
      <input matInput
             placeholder="https://example.com"
             formControlName="website"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput
             placeholder="example@domain.com"
             formControlName="email"/>
      <mat-error *ngIf="formGroup.get('email')?.hasError('required')">
        Corporate email required
      </mat-error>
      <mat-error *ngIf="formGroup.get('email')?.hasError('email')">
        Please input valid email format
      </mat-error>
    </mat-form-field>
    <!--    Phone-->
    <ng-container formGroupName="address">
      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Phone</mat-label>
        <ngx-mat-intl-tel-input [preferredCountries]="['sg', 'hk', 'th', 'fr', 'us', 'uk']"
                                [enablePlaceholder]="true"
                                [enableSearch]="true"
                                [required]="true"
                                #phone
                                formControlName="phone"></ngx-mat-intl-tel-input>
        <mat-error *ngIf="formGroup.get('phone')?.hasError('required')">
          Corporate phone required
        </mat-error>
        <mat-error *ngIf="formGroup.get('phone')?.hasError('phone')">
          Invalid phone number
        </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-divider class="col-span-2 !my-4 w-1/2 !m-auto"></mat-divider>
    <p class="col-span-2 mat-title !text-base text-darkGray !mb-1">
      Corporate Address
    </p>

    <ng-container formGroupName="address">
      <!--Address-->
      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Address Line</mat-label>
        <input type="text"
               matInput
               placeholder="Address line"
               formControlName="address"/>
        <mat-error *ngIf="formGroup.get('address')?.hasError('required')">
          Address is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Apartment , suite , etc.</mat-label>
        <input type="text"
               matInput
               placeholder="Apartment, suite, unit, building, floor, etc..."
               formControlName="apartment"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input type="text"
               matInput
               formControlName="city"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>State / Province</mat-label>
        <input type="text"
               matInput
               formControlName="state"/>
      </mat-form-field>

      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Zipcode / Postal code</mat-label>
        <input matInput
               placeholder="569933"
               formControlName="zip"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Country/Region</mat-label>
        <app-country-select-input formControlName="countryId"
                                  required></app-country-select-input>
        <mat-error *ngIf="formGroup.get('countryId')?.hasError('required')">
          Country is required
        </mat-error>
      </mat-form-field>
    </ng-container>
  </section>

  <mat-divider class="!my-6"></mat-divider>
  <!--  Corporate Representative-->
  <section class="representative-container">
    <p class="col-span-2 mat-title !text-base text-darkGray !mb-1">
      Corporate Representative
    </p>
    <app-individual-info [formGroup]="individualForm"
                         type="representative"></app-individual-info>
  </section>
</div>
