import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-kyc-reminder-message',
  standalone: true,
  imports: [CommonModule, CalloutComponent, RouterLink],
  templateUrl: './kyc-reminder-message.component.html',
  styleUrls: ['./kyc-reminder-message.component.scss']
})
export class KYCReminderMessageComponent implements OnInit {
  private _companyId: string;

  @Input()
  get companyID(): string {
    return this._companyId
  }

  set companyID(id: string) {
    this._companyId = id;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
