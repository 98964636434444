import { ModelFactory } from "@shared/factories/model.factory";
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import { WireTransferBankType } from "@generated/graphql";

export class WireTransferBankFactory extends ModelFactory<WireTransferBankType, WireTransferBank> {
  create (state: WireTransferBankType): WireTransferBank {
    return new WireTransferBank(state);
  }

  fake (): WireTransferBank {
    throw new Error('Method not implemented')
  }
}

export const wireTransferBankFactory = new WireTransferBankFactory();
