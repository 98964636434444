import { FormArray, FormGroup } from '@angular/forms';
import { ShareholderFormType } from '@app/modules/job/models/form/shareholder-form-type';
import { ShareDistributionFormType } from '@app/modules/share-distribution/models/form/share-distributions-form-type';
import {
  CompanyShareholderInput,
  OfficerCompanyInput,
  OfficerCompanyType,
  OfficerPersonInput,
  OfficerPersonType,
  OfficerType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { OfficerDialogManagement } from './officer-dialog-management.class';

export class OfficerShareholderDialogMangement extends OfficerDialogManagement {
  shareholderControls: FormArray<FormGroup<ShareholderFormType>>;

  setShareholderControls(shareholderControls: FormArray): this {
    this.shareholderControls = shareholderControls;

    return this;
  }

  setTitle(): this {
    if (this.type === 'new') {
      this.title = 'Add Shareholder';
    } else if (this.type === 'edit') {
      this.title = 'Edit Shareholder';
    }

    return this;
  }

  setInformationLabel(): this {
    this.informationLabel = 'Shareholder Information';

    return this;
  }

  getShareDistributionsForm(): FormGroup<ShareDistributionFormType> {
    return this.officerFormManagement.shareDistributionForm;
  }

  prepareEditData(data: CompanyShareholderInput): this {
    let shareholdingDetails = undefined;

    if ('shareholdingDetails' in data) {
      shareholdingDetails = data.shareholdingDetails;
    }

    this.officerFormManagement.setEditData(
      data.officerPerson || data.officerCompany || undefined,
      shareholdingDetails,
    );

    return this;
  }

  setLookupData(officer: OfficerType, type: OfficerTypeEnum): this {
    let officerName = 'officerPerson';
    let officerProfile: OfficerPersonInput | OfficerCompanyInput;

    if (type === OfficerTypeEnum.Corporate) {
      officerName = 'officerCompany';
      const castedOfficer = officer.officerProfile as OfficerCompanyType;

      officerProfile = this.transformOfficerCompany(castedOfficer);
    } else {
      const castedOfficer = officer.officerProfile as OfficerPersonType;

      officerProfile = this.transformOfficerPerson(castedOfficer);
    }

    const transformedOfficer = {
      id: officer.id,
      officerType: type,
      isNominee: false,
      [officerName]: officerProfile,
      refId: officer.id,
    };

    this.setFormType(type)
      .createFormManagement()
      .prepareEditData(transformedOfficer)
      .setUpRefForm();

    return this;
  }

  isOfficerFormValid(): boolean {
    const formValid = this.getOfficerForm().valid;
    const distributionFormValid =
      this.getShareDistributionsForm().getRawValue().shareDistributions
        .length >= 1 && this.getShareDistributionsForm().valid;

    if (this.getOfficerForm().disabled && distributionFormValid) {
      return true;
    }

    return formValid && distributionFormValid;
  }
}
