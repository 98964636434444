<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngIf="businessProfile$ | ngrxPush as businessProfile">
    <ng-container *ngIf="job.detail.gstReturnDetail as data">
      <ul class="!text-sub px-8 py-4 border border-solid rounded-2xl border-[#0000001f]">
        <li>
          <span class="mr-2">GST period&colon;</span>
          <span class="mat-body-2 !text-sub">{{ startCase(data.quarter) + ' - ' + data.year }}</span>
        </li>
        <li>
          <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}}
                             using {{ businessProfile.name }} accounting service&colon;</span>
          <span class="mat-body-2 !text-sub">{{data.isUsingTenantAccountingService ? 'Yes' : 'No'}}</span>
        </li>
        <li>
        <span class="mr-2">
          Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare management accounts document&colon;
        </span>
          <span class="mat-body-2 !text-sub">{{data.needToPrepareDocument ? 'Yes' : 'No'}}</span>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</ng-container>

