import { Inject, Injectable } from '@angular/core';
import { EntityService } from '@shared/services/entity.service';
import { EntityFeatureNameEnum } from '@shared/contracts/entity-store.contract';
import {
  CompanyBasicInfoInput,
  CompanyType,
  ConfirmChangeCompanyStatusJobMutation,
  ExpertChangeCompanyStatusMutation,
  GetChangeCompanyStatusJobRequestQuery,
  JobType,
} from '@generated/graphql';
import { Company } from '@modules/company/models/company.model';
import {
  COMPANY_STORE,
  CompanyStore,
} from '@modules/company/store/company.store';
import { Store } from '@ngrx/store';
import {
  companyFactory,
  CompanyFactory,
} from '@modules/company/factories/company.factory';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';
import { ScreenSpinnerService } from '@shared/component/full-screen-spinner/screen-spinner.service';
import { CompanyApi } from '@modules/company/api/company.api';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityService<
EntityFeatureNameEnum.Company,
CompanyType,
Company
> {
  constructor(
    protected readonly store: Store,
    @Inject(COMPANY_STORE) protected readonly entityStore: CompanyStore,
    protected readonly companyApi: CompanyApi,
    protected readonly screenSpinnerService: ScreenSpinnerService,
    private _expertChangeCompanyStatusMutation: ExpertChangeCompanyStatusMutation,
    private _getChangeCompanyStatusJobRequest: GetChangeCompanyStatusJobRequestQuery,
    private _confirmChangeCompanyStatusMutation: ConfirmChangeCompanyStatusJobMutation,
  ) {
    super(store, entityStore);
  }

  get factory(): CompanyFactory {
    return companyFactory;
  }

  refreshActiveEntity$(
    id: Company['id'],
    options: {
      api: CompanyApi;
      screenSpinnerService?: ScreenSpinnerService;
    } = {
        api: this.companyApi,
        screenSpinnerService: this.screenSpinnerService,
      },
  ): Observable<Company> {
    options.screenSpinnerService?.setLoading(true);

    return options.api.find$(id).pipe(
      tap((company) => {
        this.dispatchUpsertEntityAction(company);

        options.screenSpinnerService?.setLoading(false);
      }),
      catchError((e) => {
        options.screenSpinnerService?.setLoading(false);

        console.error(e);

        return EMPTY;
      }),
    );
  }

  public expertChangeCompanyStatus(
    id: string,
    input: CompanyBasicInfoInput,
  ): Observable<boolean> {
    return this._expertChangeCompanyStatusMutation
      .mutate({
        id,
        input,
      })
      .pipe(map((response) => response.data.expertChangeCompanyStatus));
  }

  public getChangeCompanyStatusJobRequest(id: string): Observable<JobType> {
    return this._getChangeCompanyStatusJobRequest
      .fetch(
        { id },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(
          (response) => response.data.changeCompanyStatusJobRequest as JobType,
        ),
      );
  }

  public confirmChangeCompanyStatusJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmChangeCompanyStatusMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmChangeCompanyStatusJob));
  }
}
