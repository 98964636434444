import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ResetPasswordInput, SendResetPasswordLinkVariables } from '@generated/graphql';
import { ValidationError } from '@utils/validation/app.validation';

export const resettingPasswordActions = createActionGroup({
  source: 'Resetting Password',
  events: {
    'Send link': props<{email: string}>(),
    'Send link success': props<{result: string}>(),
    'Send link error': props<{sendResetPasswordsLinkError: ValidationError<SendResetPasswordLinkVariables>}>(),
    'Reset password': props<{input: ResetPasswordInput}>(),
    'Reset password success': props<{result: string}>(),
    'Reset password error': props<{resetPasswordErrorMessages: string[]}>(),
    'Refresh State': emptyProps(),
  },
});
