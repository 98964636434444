import { ModelFactory } from '@shared/factories/model.factory';
import { BillingAddressType } from '@generated/graphql';
import { BillingAddress } from '@modules/address/models/billing-address.model';
import { faker } from '@faker-js/faker';
import { userFactory } from '@modules/user/factories/user.factory';
import { countryFactory } from '@modules/country/factories/country.factory';
import { OrderBillingAddress } from "@modules/order/models/order-billing-address.model";

export class BillingAddressFactory extends ModelFactory<BillingAddressType, BillingAddress> {
  create (state: BillingAddressType): BillingAddress {
    return new BillingAddress(state);
  }

  createFromOrderBillingAddress (
    address: OrderBillingAddress,
  ): BillingAddress {
    const state = {
      user: undefined,
      ...address,
    }

    return this.create(state)
  }

  fake (): BillingAddress {
    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();

    return this.create({
      id: faker.datatype.uuid(),
      address: faker.address.streetAddress(),
      phone: faker.phone.number(),
      fullName: [firstName, lastName].join(' '),
      isDefault: false,
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
      addressType: null,
      addressTypeId: null,
      firstName,
      lastName,
      userId: faker.datatype.uuid(),
      zip: faker.helpers.arrayElement([null, faker.address.zipCode()]),
      apartment: faker.helpers.arrayElement([null, faker.address.street()]),
      state: faker.helpers.arrayElement([null, faker.address.state()]),
      city: faker.helpers.arrayElement([null, faker.address.city()]),
      user: userFactory.fake(),
      country: faker.helpers.arrayElement([null, countryFactory.fake()]),
    });
  }
}

export const billingAddressFactory = new BillingAddressFactory();
