import {Inject, Injectable} from '@angular/core';
import {EntityService} from "@shared/services/entity.service";
import {EntityFeatureNameEnum} from "@shared/contracts/entity-store.contract";
import {AddressTypeType} from "@generated/graphql";
import {AddressType} from "@modules/address/models/address-type.model";
import {addressTypeFactory, AddressTypeFactory} from "@modules/address/factories/address-type.factory";
import {Store} from "@ngrx/store";
import {ADDRESS_TYPE_STORE, AddressTypeStore} from "@modules/address/store/address-type.store";
import {catchError, EMPTY, Observable, tap} from "rxjs";
import {AddressTypeApi} from "@modules/address/api/address-type.api";

@Injectable({
  providedIn: 'root',
})
export class AddressTypeService extends EntityService<EntityFeatureNameEnum.AddressType, AddressTypeType, AddressType> {
  constructor(
    protected readonly store: Store,
    @Inject(ADDRESS_TYPE_STORE) protected readonly entityStore: AddressTypeStore,
    protected readonly addressTypeApi: AddressTypeApi,
  ) {
    super(store, entityStore);
  }


  get factory(): AddressTypeFactory {
    return addressTypeFactory;
  }

  resolveAddressTypes$(): Observable<AddressType[]> {
    return this.addressTypeApi.get$().pipe(
      tap(data => {
        this.dispatchClearEntitiesAction()

        this.dispatchSetEntities(data)
      }),
      catchError(e => {
        this.dispatchClearEntitiesAction()

        console.error(e)

        return EMPTY
      })
    );
  }
}
