import {ModelFactory} from "@shared/factories/model.factory";
import {CompanyGstReturnItemType} from "@generated/graphql";
import {CompanyGstReturnItem} from "@modules/company/models/company-gst-return-item.model";

export class CompanyGstReturnItemFactory extends ModelFactory<CompanyGstReturnItemType, CompanyGstReturnItem> {
  create(state: CompanyGstReturnItemType): CompanyGstReturnItem {
    return new CompanyGstReturnItem(state)
  }
}

export const companyGstReturnItemFactory = new CompanyGstReturnItemFactory()
