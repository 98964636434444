<ng-container *ngrxLet="activeUser$ as activeUser">
  <div class="shares-allocation-wrapper">
    <section class="table-container table-container-limited-scroll mat-elevation-z1"
      [formGroup]="shareTransferDetailManagement.form">
      <table mat-table class="table-custom" formArrayName="details" [dataSource]="dataSource">
        <!--      ALLOTMENT NUMBER-->
        <ng-container *ngIf="!activeUser.isCustomer()" matColumnDef="allotmentNumber">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Allotment No.
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Allotment No.</mat-label>
              <input matInput placeholder="Allotment No." formControlName="transferNumber" />
            </mat-form-field>
          </td>
        </ng-container>

        <!--      CERTIFICATE NUMBER-->
        <ng-container *ngIf="!activeUser.isCustomer()" matColumnDef="certificateNumber">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Certificate No.
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <ng-container>
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Certificate No.</mat-label>
                <input matInput placeholder="Certificate No." type="text" formControlName="shareCertNumber" />
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      SHARE TYPE-->
        <ng-container matColumnDef="shareType">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Share Type
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Select share type</mat-label>
                <mat-select formControlName="shareTypeId" (selectionChange)="onShareTypeChanged($event, i)">
                  <mat-select-trigger>
                    <span class="capitalize">{{
                      getShareTypeName(
                      shareTransferDetailManagement.form.controls.details
                      .controls[i].controls.shareTypeId.value
                      ).toLowerCase()
                      }}</span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let type of shareTypes" [value]="type.id" class="capitalize">
                    <div class="inline-flex items-center">
                      <span class="mr-2 capitalize">{{
                        type.name.toLowerCase()
                        }}</span>
                      <mat-icon class="text-darkGray text-xbase opacity-80 cursor-pointer" matTooltip="{{
                          commonHelper.getShareTypeHint(type.name)
                        }}">
                        info
                      </mat-icon>
                    </div>
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="element.get('shareType')?.hasError('required')"> -->
                <!--   Share type is required -->
                <!-- </mat-error> -->
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      CURRENCY-->
        <ng-container matColumnDef="currency">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Currency
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Select currency</mat-label>
                <mat-select formControlName="currency" (selectionChange)="onCurrencyChanged($event, i)">
                  <mat-option *ngFor="
                      let currency of shareTransferDetailManagement.getCurrencyForShareType(
                        i
                      )
                    " [value]="currency">
                    {{ currency }}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="element.get('currency')?.hasError('required')"> -->
                <!--   Currency is required -->
                <!-- </mat-error> -->
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      NUMBER OF SHARES-->
        <ng-container matColumnDef="numberOfShares">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Number of Shares
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Number of shares</mat-label>
                <input matInput type="number" step="1" formControlName="numberOfShares"
                  placeholder="Number of Shares" />
                <mat-hint class="text-green-400" *ngIf="
                    shareTransferDetailManagement.details.controls.at(i)
                      .controls.numberOfShares.enabled
                  ">Maximum number of shares:
                  {{
                  shareTransferDetailManagement.getMaxNumberOfShares(i)
                  }}</mat-hint>
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.numberOfShares.hasError('required')
                  ">
                  Number of shares is required
                </mat-error>
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.numberOfShares.hasError('min')
                  ">
                  Shares number must at least 1.
                </mat-error>
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.numberOfShares.hasError('max')
                  ">
                  Shares number must less than
                  {{ shareTransferDetailManagement.getMaxNumberOfShares(i) }}.
                </mat-error>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      PAID AMOUNT-->
        <ng-container matColumnDef="amountPaidAmount">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Consideration Amount
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Consideration Amount</mat-label>
                <input matInput type="number" step="any" min="0" placeholder="Consideration Amount"
                  formControlName="paidAmount" />
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.paidAmount.hasError('min')
                  ">
                  Amount must at least 1.
                </mat-error>
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.paidAmount.hasError('required')
                  ">
                  Consideration number is required
                </mat-error>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      PAID AMOUNT  CURRENCY-->
        <ng-container matColumnDef="amountPaidCurrency" *ngrxLet="fullCurrencies$ as fullCurrencies">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Currency
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Select currency</mat-label>
                <mat-select formControlName="paidAmountCurrency">
                  <mat-option *ngFor="let currency of fullCurrencies" [value]="currency">
                    {{ currency }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="
                    shareTransferDetailManagement.details.controls
                      .at(i)
                      .controls.paidAmountCurrency.hasError('required')
                  ">
                  Currency is required
                </mat-error>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <!--      Actions-->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center w-16"
            [ngClass]="{ hidden: !addNewButton }">
            &nbsp;
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ hidden: !addNewButton }">
            <button mat-icon-button [disabled]="isAllowRemove()" (click)="removeRow(i)" color="warn">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="bg-primary-800"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </section>
  </div>
  <div *ngIf="addNewButton" class="flex flex-row justify-end">
    <button mat-button color="primary" class="text-sub" (click)="addNewRow()">
      <mat-icon>add</mat-icon>
      add new
    </button>
  </div>
</ng-container>
