import { BaseModel } from "@shared/models/base.model";
import { OfficerPersonType } from "@generated/graphql";
import { startCase } from "lodash-es";
import { Address } from "@modules/address/models/address.model";
import { addressFactory } from "@modules/address/factories/address.factory";
import { OfficerPersonIdentification } from "@modules/officer/models/officer-person-identification.model";
import { officerPersonIdentificationFactory } from "@modules/officer/factories/officer-person-identification.factory";

export class OfficerPerson extends BaseModel implements OfficerPersonType {
  id: string;
  address: Address;
  firstName: string;
  identification: OfficerPersonIdentification;
  email: string
  lastName: string;
  salutation: string;
  __typename: 'OfficerPersonType'

  constructor (state: OfficerPersonType) {
    super(state);

    this.address = state.address ? addressFactory.create(state.address) : null

    this.identification = state.identification ? officerPersonIdentificationFactory.create(state.identification) : null
  }

  getModelName (): string {
    return OfficerPerson.name;
  }

  get fullName (): string {
    return [this.firstName, this.lastName].join(' ');
  }

  get fullNameWithSalutation (): string {
    return startCase(this.salutation.toLowerCase()).concat('.', this.fullName)
  }
}
