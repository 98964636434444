import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ACTIVE_USER} from '@app/modules/auth/providers/auth.provider';
import {User} from '@app/modules/user/models/user.model';
import {FileType} from '@generated/graphql';
import {
  CapitalAppearance
} from '@shared/component/job/component/company-incorporation/tables/registered-capital/registered-capital.component';
import {Observable} from 'rxjs';
import {ReplaceDocumentDialogComponent} from './dialogs/replace-document-dialog.component';
import FileService from "@modules/file/services/file.service";

export declare type FileUploadedAppearance = 'default' | 'viewOnly' | string;

@Component({
  selector: 'app-file-uploaded-table',
  templateUrl: './file-uploaded-table.component.html',
  styleUrls: ['./file-uploaded-table.component.scss'],
})
export class FileUploadedTableComponent implements OnInit {
  @Input() fileList: FileType[];

  private _componentAppearance: CapitalAppearance = 'viewOnly';

  @Input()
  get appearance(): FileUploadedAppearance {
    return this._componentAppearance;
  }

  set appearance(value: FileUploadedAppearance) {
    this._componentAppearance = value;
  }

  private _companyDocuments: boolean = false;
  public get companyDocuments(): boolean {
    return this._companyDocuments;
  }

  @Input()
  public set companyDocuments(value: boolean) {
    this._companyDocuments = value;
  }

  tableColumns: string[] = [
    'fileName',
    // 'fileType',
    'uploadedDate',
    'uploadedBy',
    'actions',
  ];

  @Output() onFileDeleted = new EventEmitter();

  @Output() onFileReplaced = new EventEmitter();

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    private _dialog: MatDialog,
    private readonly fileService: FileService
  ) {
  }

  ngOnInit(): void {
  }

  public viewFile(file: FileType): void {
    // window.open(file.url, '_blank');

    this.fileService.viewFile(file.id)
  }

  public downloadFile(file: FileType): void {
    // window.open(file.downloadUrl, '_blank');

    this.fileService.downloadFile(file.id)
  }

  public deleteHandler(id: string) {
    // TODO: Delete file
    this.onFileDeleted.emit(id);
  }

  public openReplaceDialog(oldFile: FileType) {
    const dialog = this._dialog.open(ReplaceDocumentDialogComponent, {
      // width: '350px',
      minWidth: '40%',
      maxWidth: '45%',
      data: {
        oldFile,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.onFileReplaced.emit({
          oldFileId: oldFile.id,
          replaceByFile: result,
        });
      }
    });
  }
}
