import {BaseModel} from "@shared/models/base.model";
import {OfficerIndirectPersonType} from "@generated/graphql";
import {Country} from "@modules/country/models/country.model";
import {countryFactory} from "@modules/country/factories/country.factory";

export class OfficerIndirectPerson extends BaseModel implements OfficerIndirectPersonType {
  id: string;
  firstName: string;
  lastName: string;
  nationality: Country;
  country: Country
  _model: string;
  __typename: 'OfficerIndirectPersonType'

  constructor(state: OfficerIndirectPersonType) {
    super(state);

    this.nationality = countryFactory.tryCreating(state.nationality)

    this.country = countryFactory.tryCreating(state.country)
  }

  getModelName(): string {
    return OfficerIndirectPerson.name;
  }

  get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }
}
