import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore, EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { UserType } from "@generated/graphql";
import { User } from "@modules/user/models/user.model";
import { InjectionToken, Provider } from "@angular/core";
import { Country } from "@modules/country/models/country.model";
import { createSelector } from "@ngrx/store";

export class ExpertStore extends EntityStore<EntityFeatureNameEnum.Expert, UserType, User> {
  get selectState (): SelectState<UserType> {
    return this.feature.selectExpertFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Expert> {
    return 'EXPERT';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Expert> {
    return 'expertFeature';
  }

  selectByCountryOfResponsibility = (id: Country['id']) => createSelector(
    this.selectEntities,
    this.selectIds,
    (data, ids) => ids.map(id => data[id]).filter(user => user.profile.responsibilities.some(res => res.id === id))
  )
}

export const expertStore = new ExpertStore()

export const EXPERT_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.ExpertStore)

export const EXPERT_STORE_CONFIG: Provider = {
  provide: EXPERT_STORE,
  useValue: expertStore,
}
