import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from "@shared/component/common/alerts/snackbar/snackbar.component";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {

  private baseConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
  }

  constructor(private readonly snackBar: MatSnackBar) {
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }

  // THE COMMON MESSAGE USE FOR COMMON CASES
  public pushErrorMessage() {
    this.pushErrorAlert('Oops! We\'ve encountered an unexpected issue.\n Please try again.')
  }

  public pushUpdateSuccessMessage() {
    this.pushSuccessAlert('All changes have been saved successfully.')
  }

  public pushItemDeletedMessage() {
    this.pushAlert('Item deleted successfully.', 'Deleted!')
  }

  // --MESSAGE FOR FILE UPLOAD--
  public pushFileUploadedSuccessMessage() {
    this.pushSuccessAlert('Your file has been uploaded successfully.')
  }

  public pushFileUploadedFailMessage() {
    this.pushErrorAlert('Your file upload unfortunately failed. Please review the error message and try again.', 'Oopps!')
  }

  //--MESSAGE FOR PAYMENT
  public pushSubmitPaymentSuccessMessage() {
    this.pushSuccessAlert('Thank you for submitting your payment! We\'ll process it shortly.', 'Woohoo!')
  }

  public pushSubmitPaymentFailMessage() {
    this.pushErrorAlert('Unfortunately, your payment attempt was unsuccessful. Please check your payment information and try again..', 'Oopps!')
  }

  // -- CORPORATE REQUEST MESSAGE--
  public pushCancelCorporateRequestMessage() {
    this.pushAlert('The request has been canceled')
  }

  public pushCancelCorporateRequestFailedMessage() {
    this.pushErrorAlert('Cancellation unsuccessful. Please try again or contact support.')
  }

  public pushConfirmCorporateSuccessMessage() {
    this.pushSuccessAlert('The customer\'s request is completed!')
  }


  // USE TO PUSH ALERT
  public pushErrorAlert(message: string, title?: string) {
    this.pushAlert(message, title, SnackBarTypeEnum.error);
  }

  public pushWarningAlert(message: string, title?: string) {
    this.pushAlert(message, title, SnackBarTypeEnum.warning);
  }

  public pushSuccessAlert(message: string, title?: string) {
    this.pushAlert(message, title, SnackBarTypeEnum.success);
  }

  public pushAlert(alertMessage: string, alertTitle?: string, alertType?: SnackBarTypeEnum) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: alertTitle,
        message: alertMessage,
        snackBar: this.snackBar,
        snackBarType: alertType ?? SnackBarTypeEnum.default
      },
      panelClass: this.renderAlertStyle(alertType),
      ...this.baseConfig
    })
  }

  private renderAlertStyle(type: SnackBarTypeEnum) {
    switch (type) {
      case SnackBarTypeEnum.default: {
        return ['app-alert', 'alert-default']
      }
      case SnackBarTypeEnum.error: {
        return ['app-alert', 'alert-error']
      }
      case SnackBarTypeEnum.success: {
        return ['app-alert', 'alert-success']
      }
      case SnackBarTypeEnum.warning: {
        return ['app-alert', 'alert-warning']
      }
      default: {
        return ['app-alert', 'alert-default']
      }
    }
  }
}
