import {BaseModel} from "@shared/models/base.model";
import {GstReturnType, QuarterEnum} from "@generated/graphql";
import {User} from "@modules/user/models/user.model";
import {userFactory} from "@modules/user/factories/user.factory";
import {GstReturnable} from "@modules/gst-return/models/gst-returnable.model";
import {gstReturnableFactory} from "@modules/gst-return/factories/gst-returnable.factory";
import {File} from "@modules/file/models/file.model";
import {fileFactory} from "@modules/file/factories/file.factory";

export class GstReturn extends BaseModel implements GstReturnType {
  id: string;
  gstReturnable: GstReturnable;
  createdBy: User;
  effectiveDate: Date;
  needToPrepareDocument: boolean;
  quarter: QuarterEnum;
  prepareManagementAccountDocument: File;
  year: string;
  _model: string;

  constructor(state: GstReturnType) {
    super(state);

    this.gstReturnable = gstReturnableFactory.tryCreating(state.gstReturnable)

    this.createdBy = userFactory.tryCreating(state.createdBy)

    this.effectiveDate = state.effectiveDate ? new Date(state.effectiveDate) : null

    this.prepareManagementAccountDocument = fileFactory.tryCreating(state.prepareManagementAccountDocument)
  }

  getModelName(): string {
    return GstReturn.name;
  }
}
