import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  combineLatestWith,
  debounceTime,
  EMPTY,
  map,
  Observable,
  switchMap,
  tap
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { User } from "@modules/user/models/user.model";
import { CommonService } from "@shared/services/common.service";
import { ACTIVE_USER } from "@modules/auth/providers/auth.provider";
import { PaginationInput, PermissionEnum, RoleEnum, UsersCanBeAssignedToJobInput } from "@generated/graphql";
import { CustomerApi } from "@modules/user/api/customer.api";
import { Validators } from "@config/validators.config";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { COMMON_SERVICE_CONFIG } from "@shared/providers/common-service.provider";
import { Job } from "@modules/job/models/job.model";
import { ExpertApi } from "@modules/user/api/expert.api";
import { PaginationData } from "@shared/types/pagination.type";
import { startWith } from "rxjs/operators";

export interface AssignPeopleDialogComponentData {
  job: Job,
  title: string
  role: RoleEnum
  // assignedUsers: User[]
}

@UntilDestroy()
@Component({
  selector: 'app-assign-people-dialog',
  templateUrl: './assign-people-dialog.component.html',
  styleUrls: ['./assign-people-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [COMMON_SERVICE_CONFIG],
})
export class AssignPeopleDialogComponent implements OnInit {
  static width = '56rem'

  @Output() assignUsers = new EventEmitter<User[]>()

  userControl = new FormControl<User | string>('', [Validators.email]);

  filteredUsers$: Observable<User[]>;

  // usersCanBeSearched$: Observable<User[]>

  disabled$: Observable<boolean>;

  protected _title: string = 'Assign Member';

  protected readonly _users$ = new BehaviorSubject<User[]>([])

  protected readonly _userPagination$: BehaviorSubject<PaginationData<User>> = new BehaviorSubject<PaginationData<User>>(null);

  protected readonly _selectedUsers$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  @ViewChild('input') userInput: ElementRef<HTMLInputElement>;

  get selectedUsers$ (): Observable<User[]> {
    return this._selectedUsers$.asObservable();
  }

  get visibleSelectedUsers$ (): Observable<User[]> {
    return this.selectedUsers$.pipe(
      combineLatestWith(this.activeUser$),
      map(([users, activeUser]) => users.filter(user => user.id !== activeUser.id))
    )
  }

  get userPagination$ () {
    return this._userPagination$
  }

  get users$ (): Observable<User[]> {
    return this._users$.asObservable()
  }

  get title (): string {
    return this._title;
  }

  set title (title: string) {
    this._title = title;
  }

  constructor (
    public readonly commonService: CommonService,
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    @Inject(MAT_DIALOG_DATA) public readonly data: AssignPeopleDialogComponentData,
    protected readonly customerApi: CustomerApi,
    protected readonly expertApi: ExpertApi,
    protected readonly dialogRef: MatDialogRef<AssignPeopleDialogComponent, User[]>,
  ) {
  }

  ngOnInit (): void {
    this.setUp()

    this.disabled$ = this.commonService.loading$.pipe(
      combineLatestWith(this.selectedUsers$),
      map(([loading, selectedUsers]) => {
        return loading || !selectedUsers.length
      })
    )

    this.filteredUsers$ = this.selectedUsers$.pipe(
      combineLatestWith(this.users$),
      map(([selectedUsers, users]) => {

        return users.filter(user => !selectedUsers.find(selectedUser => selectedUser.id === user.id))
      })
    )
  }

  onClose (): void {
    this.dialogRef.close();
  }

  onSelect (event: MatAutocompleteSelectedEvent): void {
    this.addUser(event.option.value)
    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
  }

  removeUser (user: User): void {
    const selectedUsers = [...this._selectedUsers$.value]

    const index = selectedUsers.findIndex(item => item.id === user.id)

    if (index >= 0) {
      selectedUsers.splice(index, 1)

      this.setSelectedUser(selectedUsers)
    }
  }

  canRemove$ (selectedUser: User): Observable<boolean> {
    return this.activeUser$.pipe(
      map(activeUser => {
        if (!activeUser) {
          return false;
        }

        let canRemove = activeUser.id !== selectedUser.id;

        if (selectedUser.isExpert()) {
          canRemove = canRemove && activeUser.hasPermissions(PermissionEnum.AssignExpertToJob)
        } else if (selectedUser.isCustomer()) {
          canRemove = canRemove && activeUser.hasPermissions(PermissionEnum.AllowCustomerToParticipateInJob)
        }

        return canRemove
      })
    )
  }

  protected assign (): void {
    this.assignUsers.emit([...this._selectedUsers$.value])
  }

  protected setUp (): void {
    const {title, role, job} = this.data;

    this.title = title;

    switch (role) {
      case RoleEnum.Expert: {
        this.setSelectedUser(job.experts)

        break;
      }
      case RoleEnum.Customer: {
        this.setSelectedUser(job.customers)

        break;
      }
    }

    this.userControl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(1000),
      startWith(null),
      combineLatestWith(this.activeUser$),
      switchMap(([value, user]) => {
        if (value instanceof User) {
          this.addUser(value)

          return EMPTY
        }

        return this.query({
          jobId: job.id,
          searchText: value,
          pagination: {
            perPage: 5,
            currentPage: 1
          }
        })
      })
    ).subscribe()
  }

  protected setSelectedUser (users: User[]) {
    this._selectedUsers$.next([...users])
  }

  protected setUserPagination (userPagination: PaginationData<User>) {
    this._userPagination$.next(userPagination)
  }

  protected addUser (user: User) {
    this._selectedUsers$.next([...this._selectedUsers$.value, user])
  }

  protected setUsers (users: User[]) {
    this._users$.next([...users])
  }

  protected filter (usersCanBeSearched: User[], value: string): User[] {
    const searchTxt = value.toLowerCase()

    return usersCanBeSearched.filter(user => {
      const searchIn: string[] = [
        user.profile.fullName,
        user.email
      ]

      return searchTxt ? searchIn.some(item => item.toLowerCase().includes(searchTxt)) : true
    });
  }

  protected query (input: UsersCanBeAssignedToJobInput): Observable<PaginationData<User>> {
    let query: Observable<PaginationData<User>>

    switch (this.data.role) {
      case RoleEnum.Customer: {
        query = this.customerApi.getCustomersCanBeAssignedToJob$(input)
        break;
      }
      case RoleEnum.Expert: {
        query = this.expertApi.getExpertsCanBeAssignedToJob$(input)
        break;
      }
    }

    return query.pipe(
      tap(userPagination => {
        this.setUserPagination(userPagination)

        this.setUsers(userPagination.data)
      })
    )
  }
}
