import { Inject, Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { first, Observable } from 'rxjs';
import { BusinessPlan } from "@modules/account-settings/pages/business-profile/models/business-plan.model";
import { BusinessPlanService } from "@modules/account-settings/pages/business-profile/services/business-plan.service";
import { ACTIVE_USER } from "@modules/auth/providers/auth.provider";
import { User } from "@modules/user/models/user.model";

@Injectable({
  providedIn: 'root'
})
export class ActiveBusinessPlanResolver implements Resolve<BusinessPlan> {
  constructor (
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly businessPlanService: BusinessPlanService
  ) {
  }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BusinessPlan> {
    return this.businessPlanService.getByUser$(this.activeUser$.pipe(first())).pipe(first());
  }
}
