import { Component, Inject, OnInit } from '@angular/core';
import {
  ACTIVE_USER,
  IMPERSONATING_USER,
} from '@modules/auth/providers/auth.provider';
import {
  BehaviorSubject,
  combineLatestWith,
  filter,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';
import { User } from '@modules/user/models/user.model';
import { AuthService } from '@modules/auth/services/auth.service';
import {
  APP_SIDEBAR,
  SidebarConfig,
  SideBarItem,
} from '@config/sidebar.config';
import { UserType } from '@generated/graphql';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ServiceDataSyncService } from '@modules/service/services/service-data-sync.service';
import { PaymentProviderService } from '@modules/payment/services/payment-provider.service';
import { BusinessPlanService } from '@modules/account-settings/pages/business-profile/services/business-plan.service';
import { cloneDeep } from 'lodash-es';
import { selectBusinessProfile } from '@modules/account-settings/pages/business-profile/store/business-profile.store';
import { WireTransferBank } from '@modules/wire-transfer-bank/models/wire-transfer-bank.model';
import { WireTransferBankService } from '@modules/wire-transfer-bank/services/wire-transfer-bank.service';
import { BusinessPlan } from '@modules/account-settings/pages/business-profile/models/business-plan.model';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isOpenSidebar = true;
  currentYear = new Date().getFullYear();
  favIcon: HTMLLinkElement = document.querySelector('[rel="icon"]');
  paymentProviders$ = this.paymentProviderService.activeEntities;

  private readonly _sideBar$ = new BehaviorSubject<SidebarConfig>(null);
  public banks$: Observable<WireTransferBank[]>;

  get sidebar(): SidebarConfig {
    return this._sideBar$.value;
  }

  get sidebar$(): Observable<SidebarConfig> {
    return this._sideBar$.asObservable();
  }

  get menuSections$(): Observable<SideBarItem[]> {
    return this.sidebar$.pipe(
      map((sidebar) => this.filterHidden(sidebar.menuSection)),
    );
  }

  public get businessPlan$(): Observable<BusinessPlan> {
    return this.businessPlanService.businessPlan$;
  }

  constructor(
    @Inject(APP_ROUTES) public appRoutes: AppRoutes,
    @Inject(APP_SIDEBAR)
    public readonly defaultSidebar$: Observable<SidebarConfig>,
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    @Inject(IMPERSONATING_USER)
    public readonly impersonatingUser$: Observable<UserType>,
    private readonly paymentProviderService: PaymentProviderService,
    private _titleService: Title,
    private _store: Store,
    private readonly businessPlanService: BusinessPlanService,
    protected readonly wireTransferBankService: WireTransferBankService,
    protected authService: AuthService,
    protected readonly serviceDataSyncService: ServiceDataSyncService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    /**
     * Get and update Service Category store when ActiveUser changed
     */
    this.banks$ = this.wireTransferBankService.entities$;

    this.serviceDataSyncService
      .syncByActiveUser$()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.defaultSidebar$
      .pipe(
        take(1),
        tap((sideBarConfig) => {
          this._sideBar$.next(cloneDeep(sideBarConfig));
        }),
      )
      .subscribe();

    /**
     * Update Sidebar when ActiveUser changed
     */
    this.activeUser$
      .pipe(
        untilDestroyed(this),
        combineLatestWith(this.businessPlanService.visiblePayment$()),
        filter((user) => !!user && !!this.defaultSidebar$),
        tap(([user, visiblePayment]) => {
          const clone = cloneDeep(this.sidebar);

          clone.update(user, visiblePayment);

          this._sideBar$.next(clone);
        }),
      )
      .subscribe(() => {
        this.setupPusher();
      });

    this._store
      .select(selectBusinessProfile)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this._titleService.setTitle(value.headerTitle || 'Becorps');
          this.favIcon.href =
            value.faviconUrl ||
            'assets/images/logo/becorps-white-logo-only.svg';
        },
      });
  }

  private setupPusher() {
    window['Pusher'] = Pusher;

    window['Echo'] = new Echo({
      broadcaster: 'pusher',
      key: environment.pusher.appKey,
      cluster: environment.pusher.cluster,
      forceTLS: true,
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: string, callback: any) => {
            this.http
              .post(
                `${environment.apiUrl.replace('/graphql', '')}/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  withCredentials: true,
                },
              )
              .subscribe((response) => {
                callback(false, response);
              });
          },
        };
      },
    });
  }

  sidebarHandler(isSidebarOpen: boolean): void {
    const clone = cloneDeep(this.sidebar);

    clone.isOpenSidebar = !isSidebarOpen;

    this._sideBar$.next(clone);
  }

  filterHidden(items: SideBarItem[]): SideBarItem[] {
    return [...items.filter((item) => !item.isHidden)];
  }
}
