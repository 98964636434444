import { BaseModel } from "@shared/models/base.model";
import { OfficerDocumentStatusEnum, OfficerDocumentType } from "@generated/graphql";
import { Officer } from "@modules/officer/models/officer.model";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";
import { officerFactory } from "@modules/officer/factories/officer.factory";
import { officerRequiredDocumentFactory } from "@modules/officer/factories/officer-required-document.factory";
import { startCase } from "lodash-es";
import { AppStatusType } from "@shared/component/common/status/status.component";
import { File as FileModel } from "@modules/file/models/file.model";
import { fileFactory } from "@modules/file/factories/file.factory";
import { Company } from "@modules/company/models/company.model";
import { companyFactory } from "@modules/company/factories/company.factory";

export class OfficerDocument extends BaseModel implements OfficerDocumentType {
  id: string;
  officer: Officer;
  company: Company;
  isVerified: boolean;
  canReUpload: boolean;
  isAwaitingReview: boolean
  officerId: string;
  officerRequiredDocument: OfficerRequiredDocument;
  officerRequiredDocumentId: string;
  status: OfficerDocumentStatusEnum;
  file: FileModel
  canUpdate: boolean
  canDelete: boolean
  canManage: boolean
  canReject: boolean
  canApprove: boolean
  _model: string;

  constructor (state: OfficerDocumentType) {
    super(state);

    this.officer = officerFactory.tryCreating(state.officer)

    this.officerRequiredDocument = officerRequiredDocumentFactory.tryCreating(state.officerRequiredDocument)

    this.file = fileFactory.tryCreating(state.file)

    this.company = companyFactory.tryCreating(state.company)
  }

  get statusLabel (): string {
    return startCase(this.status.toLowerCase())
  }

  get statusStyle (): AppStatusType {
    switch (this.status) {
      case OfficerDocumentStatusEnum.AwaitingReview: {
        return 'warn'
      }
      case OfficerDocumentStatusEnum.Verified: {
        return 'success'
      }
      case OfficerDocumentStatusEnum.Rejected: {
        return 'error'
      }
      default:
        return 'default'
    }
  }

  getModelName (): string {
    return OfficerDocument.name;
  }
}
