import { BaseModel } from "@shared/models/base.model";
import { ExtraJobStatusEnum, ExtraJobType } from "@generated/graphql";
import { Job } from "@modules/job/models/job.model";
import { User } from "@modules/user/models/user.model";
import { jobFactory } from "@modules/job/factories/job.factory";
import { userFactory } from "@modules/user/factories/user.factory";
import { Order } from "@modules/order/models/order.model";
import { orderFactory } from "@modules/order/factories/order.factory";
import { AppStatusType } from "@shared/component/common/status/status.component";
import { startCase } from "lodash-es";

export class ExtraJob extends BaseModel implements ExtraJobType {
  static minOutOfPocketExpenses: number = 0;
  static maxOutOfPocketExpenses: number = Math.pow(10, 8) - 1;
  static minServiceFee: number = 0;
  static maxServiceFee: number = Math.pow(10, 8) - 1;
  static minOfficialFee: number = 0;
  static maxOfficialFee: number = Math.pow(10, 8) - 1;

  id: string;
  status: ExtraJobStatusEnum
  currency: string;
  jobId: string;
  matter: string;
  officialFee: number;
  scopeOfWork: string;
  outOfPocketExpenses: number;
  serviceFee: number;
  totalAmount: number;
  subTotalAmount: number;
  vatAmount: number;
  createdById: string;
  order?: Order
  job: Job
  canConfirm: boolean;
  canClose: boolean;
  canDelete: boolean
  canUpdate: boolean
  createdBy: User;
  _model: string;
  protected _isLoading: boolean

  getModelName (): string {
    return ExtraJob.name;
  }

  constructor (state: ExtraJobType) {
    super(state);

    this.job = state.job ? jobFactory.create(state.job) : null;

    this.order = state.order ? orderFactory.create(state.order) : null;

    this.createdBy = state.createdBy ? userFactory.create(state.createdBy) : null
  }

  get customers () {
    return this.job.customers
  }

  get experts () {
    return this.job.experts
  }

  get company () {
    return this.job.company
  }

  get supportedCountry () {
    return this.job.supportedCountry
  }

  set loading (loading: boolean) {
    this._isLoading = loading
  }

  get loading (): boolean {
    return this._isLoading
  }

  getMatterDisplay (): string {
    return this.matter
  }

  renderStatusStyle (): AppStatusType {
    switch (this.status) {
      case ExtraJobStatusEnum.New:
        return 'new';
      case ExtraJobStatusEnum.Pending:
        return 'hold';
      case ExtraJobStatusEnum.Ongoing:
        return 'new-light';
      case ExtraJobStatusEnum.Draft:
        return 'hold';
      default:
        return 'default';
    }
  }

  renderStatusLabel (): string {
    return startCase(this.status.toLowerCase());
  }

  setLoading (loading: boolean): BaseModel {
    this.loading = loading

    return this
  }
}
