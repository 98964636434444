import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { countryStore } from '@modules/country/store/country.store';
import { ShareTypeService } from '@modules/share-type/services/share-type.service';
import {
  CountryType,
  OfficerTypeEnum,
  ShareholdingDetailInput,
  ShareTypeEnum,
  ShareTypeType,
} from '@generated/graphql';
import { Store } from '@ngrx/store';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { AddOfficerDialogComponent } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/add-officer-dialog.component';
import { CompanyIncorporationManagement } from '@app/modules/job/classes/company-incorporation-management.class';

export declare type ShareholderAppearance =
  | 'register'
  | 'viewOnly'
  | 'request'
  | string;

@Component({
  selector: 'app-shareholder-table',
  templateUrl: './shareholder-table.component.html',
  styleUrls: ['./shareholder-table.component.scss'],
})
export class ShareholderTableComponent implements OnInit {
  private _componentAppearance: ShareholderAppearance = 'viewOnly';

  @Input()
  get appearance(): ShareholderAppearance {
    return this._componentAppearance;
  }

  set appearance(value: ShareholderAppearance) {
    this._componentAppearance = value;
  }

  get companyStructureForm() {
    return this.companyIncorporationManagement.companyStructureForm;
  }

  get formStepTwo() {
    return this.companyStructureForm.form;
  }

  get shareholderControls() {
    return this.companyStructureForm.shareholderControls;
  }

  officerType = OfficerTypeEnum;

  shareTypes: ShareTypeType[];
  countries: CountryType[];

  displayedShareholderColumns: string[] = [
    'type',
    'name',
    'id',
    'residence',
    'ordinary',
    'preference',
    'other',
    'ownership',
    'actions',
  ];

  constructor(
    private shareTypeService: ShareTypeService,
    private store: Store,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private companyIncorporationManagement: CompanyIncorporationManagement,
  ) { }

  ngOnInit(): void {
    this.shareTypeService.getAll().subscribe((shareTypes) => {
      this.shareTypes = shareTypes;
    });

    this.store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  getOfficerName(formData): string {
    return formData.officerPerson
      ? `${formData.officerPerson.firstName} ${formData.officerPerson.lastName}`
      : formData.officerCompany.name;
  }

  getOfficerEmail(formData): string {
    return formData.officerCompany
      ? formData.officerCompany.email
      : formData.officerPerson.email;
  }

  getOfficerID(formData): string {
    return formData.officerCompany
      ? formData.officerCompany.registrationNumber
      : formData.officerPerson.identification.identificationNumber;
  }

  getCountryId(formData) {
    const officer = formData.officerPerson || formData.officerCompany;
    return officer.identification
      ? officer.identification.nationalityId
      : officer.countryId;
  }

  editShareholder(formData, index: number) {
    const editOfficerDialog = this.dialog.open(AddOfficerDialogComponent, {
      width: '75%',
      data: {
        officerRole: OfficerRoleEnum.SHAREHOLDER,
        companyCapitals: this.companyStructureForm.companyCaptialControls,
        editData: formData,
        directors: this.formStepTwo.controls.directors,
        shareholderEditIndex: index,
        shareholders: this.shareholderControls,
        exceptIds: this.companyStructureForm.shareholderControls
          .getRawValue()
          .filter((d) => d.refId && d.id !== formData.id)
          .map((d) => d.refId),
      },
    });

    editOfficerDialog
      .afterClosed()
      .subscribe(
        (result: { refId: string; officerForm: AbstractOfficerForm }) => {
          if (result?.officerForm) {
            const currentControl = this.shareholderControls.at(index);

            this.companyStructureForm.companySharehoderForm.updateShareholder(
              index,
              result.officerForm,
              result.refId,
            );

            if (!result.refId) {
              this.companyStructureForm.companyDirectorForm.updateDirectorByRef(
                currentControl,
                result.officerForm,
              );
            }
          }
        },
      );
  }

  deleteShareholder(index: number) {
    const shareholderControl = this.formStepTwo.controls.shareholders.at(index);

    this.companyStructureForm.companyDirectorForm.removeByRef(
      shareholderControl.controls.id.value,
      shareholderControl.controls.officerCompany?.controls.representative
        .controls.id.value,
    );
    this.companyStructureForm.companySharehoderForm.removeAt(index);
  }

  getOrdinaryShares(shareholdingDetails) {
    return shareholdingDetails.filter(
      (item) =>
        item.shareTypeId ===
        this.shareTypes.find(
          (shareType) => shareType.name === ShareTypeEnum.Ordinary,
        ).id,
    );
  }

  getPrefShares(shareholdingDetails) {
    return shareholdingDetails.filter(
      (item) =>
        item.shareTypeId ===
        this.shareTypes.find(
          (shareType) => shareType.name === ShareTypeEnum.Preference,
        ).id,
    );
  }

  getOtherShares(shareholdingDetails) {
    return shareholdingDetails.filter(
      (item) =>
        item.shareTypeId ===
        this.shareTypes.find(
          (shareType) => shareType.name === ShareTypeEnum.Other,
        ).id,
    );
  }

  getOwnership(shareholdingDetails: ShareholdingDetailInput[]) {
    let totalShares = 0;
    let totalCapitalShares = 0;
    shareholdingDetails.forEach((s) => {
      totalShares += s.numberOfShares;
    });
    this.formStepTwo.controls.companyCapitals.value.forEach((c) => {
      totalCapitalShares += c.totalShares;
    });

    if (totalCapitalShares) {
      return (totalShares / totalCapitalShares);
    }

    return 0;
  }

  viewShareholderInfo(shareholderInfo) {
    console.log(shareholderInfo);
  }
}
