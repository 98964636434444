<ng-container *ngIf="activeUser$|async as activeUser">
  <div class="authorized-officer-dialog-wrapper" *ngIf="form" appInvalidControlScroll>
    <h1
      mat-dialog-title
      class="mb-2 text-darkGray !text-[2.2rem] text-center">
      {{ label }}
    </h1>

    <mat-dialog-content>
      <section class="flex flex-col">
        <div class="flex flex-row gap-2" *ngIf="!activeUser.isCustomer()">
          <mat-form-field
            appearance="outline"
            class="w-1/2">
            <mat-label>Effective Date</mat-label>

            <input
              [min]="minDate"
              [max]="maxDate"
              [formControl]="form.controls.dateFrom"
              matInput
              placeholder="Effective date"
              [matDatepicker]="effectiveDateRef">

            <mat-datepicker #effectiveDateRef></mat-datepicker>

            <mat-datepicker-toggle
              matSuffix
              [for]="effectiveDateRef">
            </mat-datepicker-toggle>

            <mat-error *ngIf="form.controls.dateFrom.hasError('validationErrors')">
              {{ form.controls.dateFrom.getError('validationErrors') }}
            </mat-error>

            <mat-error *ngIf="hasMatPickerError(form.controls.dateFrom)">
              Effective date is invalid
            </mat-error>
          </mat-form-field>

          <!--  TODO implement expiration date later        -->
          <!--          <mat-form-field-->
          <!--            appearance="outline"-->
          <!--            class="w-1/2">-->
          <!--            <mat-label>Expiration date</mat-label>-->

          <!--            <mat-datepicker-->
          <!--              #expirationDateRef></mat-datepicker>-->

          <!--            <mat-datepicker-toggle-->
          <!--              matSuffix-->
          <!--              [for]="expirationDateRef">-->
          <!--            </mat-datepicker-toggle>-->

          <!--            <input-->
          <!--              [min]="minDateOfDateTo"-->
          <!--              [max]="maxDate"-->
          <!--              [formControl]="form.controls.dateTo"-->
          <!--              matInput-->
          <!--              placeholder="Expiration date"-->
          <!--              [matDatepicker]="expirationDateRef">-->

          <!--            <mat-error *ngIf="form.controls.dateTo.hasError('validationErrors')">-->
          <!--              {{ form.controls.dateTo.getError('validationErrors') }}-->
          <!--            </mat-error>-->

          <!--            <mat-error *ngIf="hasMatPickerError(form.controls.dateTo)">-->
          <!--              Expiration date is invalid-->
          <!--            </mat-error>-->
          <!--          </mat-form-field>-->
        </div>

        <ng-container [ngSwitch]="officerType">
          <ng-container *ngSwitchCase="OfficerTypeEnum.Secretary">
            <app-individual-info
              *ngIf="companySecretaryForm"
              [countryOfIncorporationOverride]="supportedCountry$|async"
              [formGroup]="officerIndividualForm.officerForm"></app-individual-info>
          </ng-container>

          <ng-container *ngSwitchCase="OfficerTypeEnum.Auditor">
            <form [formGroup]="auditorForm" *ngIf="auditorForm">
              <mat-form-field
                appearance="outline"
                class="w-full"
                *ngrxLet="auditorForm.controls.name as auditorNameControl">
                <input
                  matInput
                  placeholder="Auditor name"
                  [formControl]="auditorNameControl">
                <mat-error *ngIf="auditorNameControl.hasError('required')">
                  Auditor name required
                </mat-error>
              </mat-form-field>
            </form>
          </ng-container>
        </ng-container>
      </section>

    </mat-dialog-content>

    <mat-dialog-actions
      class="!pt-0"
      align="center">
      <button
        appConfirmDialog
        mat-raised-button
        class="bg-grey-800 text-white w-48"
        (ok)="onCloseDialog()">Cancel
      </button>

      <button
        [disabled]="form.invalid || form.pristine || (commonService.loading$|async)"
        appConfirmDialog
        (ok)="onSubmit()"
        mat-raised-button
        color="primary"
        class="w-48">
        {{ action }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-container>
