import { BaseModel } from '@shared/models/base.model';
import {
  AgmInput,
  CompanyDirectorType,
  CompanyRegisteredAddressInput,
  CompanyRegisteredAddressType,
  CompanyShareholderInput,
  CompanyShareholderJobInput,
  CompanyShareholderType,
  CompanyTaxReturnInput,
  CreateCompanyJobInput,
  CustomerChangeCompanyStatusInput,
  DirectorJobInput,
  FileType,
  IncreaseCompanyCapitalInput,
  RemoveDirectorJobInput,
  ServiceEnum,
  TransferSharesInput,
} from '@generated/graphql';
import { JobServicesOrder } from './job.model';
import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';

export class JobObjectDetail extends BaseModel {
  input: CreateCompanyJobInput;
  jobServiceOrder: JobServicesOrder;
  serviceName?: ServiceEnum;
  oldRegisteredAddress?: CompanyRegisteredAddressType;
  registeredAddress?: CompanyRegisteredAddressInput;
  directorInput?: DirectorJobInput;
  oldDirector?: CompanyDirectorType;
  removeDirectorInput?: RemoveDirectorJobInput;
  shareholderInput?: CompanyShareholderJobInput;
  oldShareholder?: CompanyShareholderType;
  transferor?: CompanyShareholderInput;
  transferSharesInput?: TransferSharesInput;
  increaseCompanyCapitalInput?: IncreaseCompanyCapitalInput;
  changeCompanyStatusInput?: CustomerChangeCompanyStatusInput;
  taxReturnInput?: CompanyTaxReturnInput;
  temporaryDocuments?: FileType[];
  agmInput?: AgmInput;

  constructor(state: {
    input: CreateCompanyJobInput;
    jobServiceOrder: JobServicesOrder;
    serviceName?: ServiceEnum;
    oldRegisteredAddress?: CompanyRegisteredAddressType;
    registeredAddress?: CompanyRegisteredAddressInput;
    directorInput?: DirectorJobInput;
    oldDirector?: CompanyDirectorType;
    removeDirectorInput?: RemoveDirectorJobInput;
    shareholderInput?: CompanyShareholderJobInput;
    oldShareholder?: CompanyShareholderType;
    transferor?: CompanyShareholderInput;
    transferSharesInput?: TransferSharesInput;
    increaseCompanyCapitalInput?: IncreaseCompanyCapitalInput;
    changeCompanyStatusInput?: CustomerChangeCompanyStatusInput;
    taxReturnInput?: CompanyTaxReturnInput;
    temporaryDocuments?: FileType[];
    agmInput?: AgmInput;
  }) {
    super(state);

    if (this.jobServiceOrder) {
      this.jobServiceOrder.mainServices =
        state.jobServiceOrder.mainServices.map((s) => ({
          quantity: s.quantity,
          service: new ServiceSupportedCountry(s.service),
        }));

      this.jobServiceOrder.additionalServices =
        state.jobServiceOrder.additionalServices.map((s) => ({
          quantity: s.quantity,
          service: new ServiceSupportedCountry(s.service),
        }));
    }
  }

  getModelName(): string {
    return JobObjectDetail.name;
  }
}
