import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from "@angular/material/card";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BillingAddressService } from "@modules/address/services/billing-address.service";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { Observable } from "rxjs";
import { BillingAddress } from "@modules/address/models/billing-address.model";
import { MatRadioModule } from "@angular/material/radio";
import {
  BillingManagementPageComponent
} from "@modules/account-settings/common/pages/billing-management-page/billing-management-page.component";
import { Validators } from "@config/validators.config";
import { ShareDirectivesModule } from "@shared/share-directives/share-directives.module";

export interface AddressSelectionDialogComponentData {
  selectedAddress: BillingAddress
}

export type AddressSelectionDialogComponentResult = BillingAddress

@Component({
  selector: 'app-address-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    ShareDirectivesModule
  ],
  templateUrl: './address-selection-dialog.component.html',
  styleUrls: ['./address-selection-dialog.component.scss']
})
export class AddressSelectionDialogComponent implements OnInit {
  readonly control = new FormControl<BillingAddress>(null, [
    Validators.required
  ]);

  get billingAddresses$ (): Observable<BillingAddress[]> {
    return this.billingAddressService.getAllAddress$;
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) protected readonly data: AddressSelectionDialogComponentData,
    private readonly dialogRef: MatDialogRef<AddressSelectionDialogComponent, AddressSelectionDialogComponentResult>,
    private readonly billingAddressService: BillingAddressService,
    private readonly dialog: MatDialog,
  ) {
  }

  ngOnInit (): void {
    const {selectedAddress} = this.data

    this.control.setValue(selectedAddress)
  }

  onAddNewAddress () {
    return this.billingAddressService.onAddNewAddress(this.dialog)
  }

  confirm (): void {
    this.dialogRef.close(this.control.value)
  }
}
