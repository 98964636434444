<div class="date-month-picker-wrapper">
  <mat-form-field appearance="outline"
                  class='w-full'>
    <mat-label>
      {{label ?? 'Select Date'}}
    </mat-label>
    <input matInput
           placeholder="{{placeholder ?? 'Select date'}}"
           [matDatepicker]="picker"
           [formControl]="dateMonthPicker">
    <mat-datepicker-toggle matSuffix
                           [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker
                    [calendarHeaderComponent]="dateMonthPickerHeader"></mat-datepicker>
  </mat-form-field>
</div>
