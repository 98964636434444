import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {Job} from '@app/modules/job/models/job.model';
import {Store} from '@ngrx/store';
import {countryStore} from '@app/modules/country/store/country.store';
import {CountryType} from '@generated/graphql';

@Component({
  selector: 'app-company-registered-address-info',
  standalone: true,
  imports: [CommonModule, SectionWpComponent],
  templateUrl: './company-registered-address-info.component.html',
  styleUrls: ['./company-registered-address-info.component.scss'],
})
export class CompanyRegisteredAddressInfoComponent implements OnInit {
  @Input() public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  countries: CountryType[];

  constructor(
    protected readonly commonHelper: CommonHelper,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  renderNewAddress(): string {
    return this.commonHelper.renderAddressFromInput(
      this.jobDetail.registeredAddress,
      this.countries,
    );
  }
}
