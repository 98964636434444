import { ModelFactory } from "@shared/factories/model.factory";
import { BillingAddress } from "@modules/address/models/billing-address.model";
import { BillingDetails } from "@stripe/stripe-js";
import { OrderBillingAddress } from "@modules/order/models/order-billing-address.model";

export class BillingDetailsAdapter extends ModelFactory<BillingAddress | OrderBillingAddress, BillingDetails> {
  create (
    state: BillingAddress | OrderBillingAddress,
    email?: string
  ): BillingDetails {
    return {
      name: state.fullName,
      phone: state.phone,
      email,
      address: {
        country: state.country?.code,
        state: state.state,
        city: state.city,
        postal_code: state.zip,
        line1: state.apartment,
        line2: state.address,
      }
    }
  }

  fake (): BillingDetails {
    throw new Error('Method is not implemented');
  }
}

export const billingDetailsAdapter = new BillingDetailsAdapter()
