import { BaseModel } from '@shared/models/base.model';
import { PaymentProviderNameEnum, PaymentProviderType } from '@generated/graphql';
import { PaymentType } from '@modules/payment/models/payment-type.model';
import { PaymentProviderKey } from '@modules/payment/models/payment-provider-key.model';
import { paymentProviderKeyFactory } from '@modules/payment/factories/payment-provider-key.factory';
import { paymentTypeFactory } from '@modules/payment/factories/payment-type.factory';
import { startCase } from "lodash-es";

export class PaymentProvider extends BaseModel implements PaymentProviderType {
  id: string;
  name: string;
  isActive: boolean;
  paymentProviderKeys: PaymentProviderKey[];
  paymentTypes: PaymentType[];

  constructor (state: PaymentProviderType) {
    super(state);

    this.paymentProviderKeys = state.paymentProviderKeys
      ? state.paymentProviderKeys.map(key => paymentProviderKeyFactory.create(key))
      : [];

    this.paymentTypes = state.paymentTypes
      ? state.paymentTypes.map(type => paymentTypeFactory.create(type))
      : [];
  }

  get label (): string {
    return startCase(this.name.toLowerCase())
  }

  get icon (): string {
    switch (this.name) {
      case PaymentProviderNameEnum.Stripe: {
        return 'credit_card'
      }
      case PaymentProviderNameEnum.WireTransfer: {
        return 'account_balance'
      }
      default:
        return ''
    }
  }

  getModelName (): string {
    return PaymentProvider.name;
  }

  isWireTransfer (): boolean {
    return this.name === PaymentProviderNameEnum.WireTransfer
  }

  isStripe (): boolean {
    return this.name === PaymentProviderNameEnum.Stripe
  }
}


export interface PaymentProviderOption {
  value: PaymentProviderNameEnum
  icon: string
  label: string
}
