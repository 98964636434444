import { Injectable } from '@angular/core';
import {
  AddCustomerMutation, AllCustomersQuery, CustomerInput, CustomersCanBeAssignedToJobQuery,
  CustomersQuery,
  PaginationInput,
  // SearchCustomersQuery,
  UpdateCustomerInput,
  UpdateCustomerMutation, UsersCanBeAssignedToJobInput,
  UserSortKeyInput,
  UserType,
} from '@generated/graphql';
import { map, Observable, of } from 'rxjs';
import { Pagination, PaginationData } from '@shared/types/pagination.type';
import { User } from '@modules/user/models/user.model';
import { userFactory } from '@modules/user/factories/user.factory';
import { paginationDataFactory } from '@shared/factories/pagination.factoy';
import { AddContract } from '@shared/contracts/add.contract';
import { UpdateContract } from '@shared/contracts/update.contract';

@Injectable({
  providedIn: 'root',
})
export class CustomerApi implements AddContract<CustomerInput, Observable<User>>,
  UpdateContract<UpdateCustomerInput, Observable<User>> {
  constructor (
    private readonly customersQuery: CustomersQuery,
    private readonly allCustomersQuery: AllCustomersQuery,
    private readonly addCustomerMutation: AddCustomerMutation,
    private readonly updateCustomerMutation: UpdateCustomerMutation,
    private readonly customersCanBeAssignedToJobQuery: CustomersCanBeAssignedToJobQuery,
    // private readonly searchCustomersQuery: SearchCustomersQuery,
  ) {
  }

  getCustomers$ (pagination: PaginationInput, searchText: string = '', sorts: UserSortKeyInput[] = []): Observable<PaginationData<User>> {
    return this.customersQuery.fetch({
      searchText,
      sorts,
      pagination,
    }, {fetchPolicy: 'no-cache'}).pipe(
      map(response => response.data
        ? paginationDataFactory.create(response.data.customers as Pagination<UserType>, userFactory)
        : null),
    );
  }

  getCustomersCanBeAssignedToJob$ (input: UsersCanBeAssignedToJobInput) {
    return this.customersCanBeAssignedToJobQuery.fetch({input}, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.customersCanBeAssignedToJob
        ? paginationDataFactory.create(res.data.customersCanBeAssignedToJob, userFactory)
        : null
      )
    )
  }

  // searchCustomers$ (searchText: string = ''): Observable<User[]> {
  //   return this.searchCustomersQuery.fetch({
  //     searchText,
  //   }, {fetchPolicy: 'no-cache'}).pipe(
  //     map(response => response.data.searchCustomers ? response.data.searchCustomers.map(user => userFactory.create(user as UserType)) : [])
  //   );
  // }

  all$ (): Observable<User[]> {
    return this.allCustomersQuery.fetch(undefined, {fetchPolicy: 'no-cache'}).pipe(
      map(res => res.data.allCustomers
        ? res.data.allCustomers.map(customer => userFactory.create(customer as UserType))
        : []
      )
    )
  }

  get$ (limit: number = 1000): Observable<User[]> {
    return this.getCustomers$({currentPage: 0, perPage: limit}).pipe(map(res => res.data))
  }

  add$ (input: CustomerInput): Observable<User> {
    return this.addCustomerMutation.mutate({input})
      .pipe(map(response => response.data.addCustomer ? userFactory.create(response.data.addCustomer as UserType) : null));
  }

  update$ (input: UpdateCustomerInput): Observable<User> {
    return this.updateCustomerMutation.mutate({input})
      .pipe(
        map(response => response.data.updateCustomer
          ? userFactory.create(response.data.updateCustomer as UserType)
          : null)
      );
  }
}
