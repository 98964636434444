import { ModelFactory } from "@shared/factories/model.factory";
import { OrderType } from "@generated/graphql";
import { Order } from "@modules/order/models/order.model";

export class OrderFactory extends ModelFactory<OrderType, Order> {
  create (state: OrderType): Order {
    return new Order(state);
  }

  fake (): Order {
    throw new Error('Method is not implemented')
  }
}

export const orderFactory = new OrderFactory()
