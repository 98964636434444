import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { CompanyType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { Company } from "@modules/company/models/company.model";

export class CompanyStore extends EntityStore<EntityFeatureNameEnum.Company, CompanyType, Company> {
  get selectState (): SelectState<CompanyType> {
    return this.feature.selectCompanyFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Company> {
    return 'COMPANY';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Company> {
    return 'companyFeature';
  }
}

export const companyStore = new CompanyStore()
export const COMPANY_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.CompanyStore)
export const COMPANY_STORE_CONFIG: Provider = {
  provide: COMPANY_STORE,
  useValue: companyStore,
}
