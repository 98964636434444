<mat-card class="bank-account-wrapper" *ngIf="value">
  <mat-card-title class="mat-title text-darkGray">{{ value.name }}</mat-card-title>
  <mat-card-content>
    <p class="!mb-1">
      <span
        class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Account name&colon;&nbsp;</span>
      <span class="mat-subheading-2">{{ value.holderName }}</span>
    </p>
    <p class="!mb-1">
      <span
        class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Account number&colon;&nbsp;</span>
      <span class="mat-subheading-2">{{ value.account }}</span>
    </p>
    <p class="!mb-1">
      <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Branch&colon;&nbsp;</span>
      <span class="mat-subheading-2">{{ value.branch }}</span>
    </p>
    <p class="!mb-1">
      <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">SWIFT&colon;&nbsp;</span>
      <span class="mat-subheading-2">{{ value.swiftCode }}</span>
    </p>
    <p class="!mb-0">
      <span
        class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Available&colon;&nbsp;</span>
      <span class="mat-subheading-2">{{ value.country.name }}</span>
    </p>
  </mat-card-content>
  <mat-card-actions align="end" class="!pt-0" *ngIf="visibleActions$">
    <button
      (click)="onUpdate()"
      [disabled]="loading$ | async"
      *ngIf="visibleUpdateAction$ | async"
      mat-button
      color="accent">Edit
    </button>
    <button
      appConfirmDialog
      title="Delete bank account?"
      [confirmMessage]="'This action will delete the selected bank account permanently and can\'t recover them once you delete.\n\n Are you sure you want to permanently delete this bank account?'"
      [disabled]="loading$ | async"
      (ok)="onDelete()"
      *ngIf="visibleDeleteAction$ | async"
      mat-button
      color="warn">Delete
    </button>
  </mat-card-actions>
</mat-card>
