<section
  *ngIf="formStepTwo && shareTypes"
  class="shareholder-table-wrapper table-container mat-elevation-z4 mb-4">
  <table
    mat-table
    class="table-custom"
    [dataSource]="shareholderControls.value">
    <!--    Shareholder type colum-->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="!hidden">Type</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon
          *ngIf="element.officerType === officerType.Individual"
          class="text-darkGray opacity-70"
          [matTooltip]="element.officerType.toLowerCase()"
          matTooltipClass="capitalize">person
        </mat-icon>
        <mat-icon
          *ngIf="element.officerType === officerType.Corporate"
          class="text-darkGray opacity-70"
          [matTooltip]="element.officerType.toLowerCase()"
          matTooltipClass="capitalize">apartment
        </mat-icon>
      </td>
    </ng-container>

    <!--    Shareholder name & email colum-->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="!hidden">Name</th>
      <td mat-cell *matCellDef="let element">
        <p class="!mb-1 line-clamp-2">{{ getOfficerName(element) }}</p>
        <p class="mat-caption !mb-0 text-lightGray truncate"
           matTooltip="{{ element.email }}">
          {{ getOfficerEmail(element) }}
        </p>
      </td>
    </ng-container>

    <!--    Shareholder ID-->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="!hidden">ID</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <p class="!mb-1 line-clamp-2" (click)="viewShareholderInfo(element)">{{ getOfficerID(element) }}</p>
      </td>
    </ng-container>


    <!--    Shareholder residence / country of incorporation colum-->
    <ng-container matColumnDef="residence">
      <th mat-header-cell *matHeaderCellDef class="!hidden">Residence</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <app-country-flag
          showCountryName
          id="{{ getCountryId(element) }}"></app-country-flag>
      </td>
    </ng-container>

    <!--  ordinary shares column-->
    <ng-container matColumnDef="ordinary">
      <th mat-header-cell
          *matHeaderCellDef
          class="!text-white !text-sub !text-center whitespace-nowrap border-solid border-0 border-x">
        Ordinary
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <ng-container
          *ngIf="getOrdinaryShares(element.shareholdingDetails).length;else noOrdinaryShares">
          <ng-container
            *ngFor="let shares of getOrdinaryShares(element.shareholdingDetails)">
            <p class="share-details">
              <span class="mat-subheading-1 !text-sub text-darkGray">
                {{ shares.currency }}</span>&boxv;<span>{{ shares.numberOfShares }}</span>
            </p>
          </ng-container>
        </ng-container>
        <ng-template #noOrdinaryShares> -</ng-template>
      </td>
    </ng-container>

    <!--    preference share colum-->
    <ng-container matColumnDef="preference">
      <th mat-header-cell
          *matHeaderCellDef
          class="!text-white !text-sub !text-center whitespace-nowrap border-solid border-0 border-r">
        Preference
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <ng-container *ngIf="getPrefShares(element.shareholdingDetails).length;else noPrefShares">
          <ng-container *ngFor="let shares of getPrefShares(element.shareholdingDetails)">
            <p class="share-details">
              <span class="mat-subheading-1 !text-sub text-darkGray">
                {{ shares.currency }}</span>&boxv;<span>{{ shares.numberOfShares }}</span>
            </p>
          </ng-container>
        </ng-container>
        <ng-template #noPrefShares> -</ng-template>
      </td>
    </ng-container>

    <!--    other share column-->
    <ng-container matColumnDef="other">
      <th mat-header-cell
          *matHeaderCellDef
          class="!text-white !text-sub !text-center whitespace-nowrap border-solid border-0 border-r">
        Other
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <ng-container
          *ngIf="getOtherShares(element.shareholdingDetails).length;else noOtherShares">
          <ng-container *ngFor="let shares of getOtherShares(element.shareholdingDetails)">
            <p class="share-details">
              <span class="mat-subheading-1 !text-sub text-darkGray">
                {{ shares.currency }}</span>&boxv;<span>{{ shares.numberOfShares }}</span>
            </p>
          </ng-container>
        </ng-container>
        <ng-template #noOtherShares> -</ng-template>
      </td>
    </ng-container>
    <!--    Shareholder type colum-->
    <ng-container matColumnDef="ownership">
      <th mat-header-cell *matHeaderCellDef class="!hidden">Ownership</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        {{ getOwnership(element.shareholdingDetails)|percent: "1.0-4" }}
      </td>
    </ng-container>

    <!--    Actions colum-->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="!hidden">Actions</th>
      <td mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{ hidden: appearance === 'viewOnly' }">
        <div class="inline-flex justify-end" *ngIf="appearance === 'register'">
          <!--          TODO DO LATER-->
          <!--          <button mat-icon-button-->
          <!--                  (click)="viewShareholderInfo(element)">-->
          <!--            <mat-icon class="text-darkGray opacity-70">person_search</mat-icon>-->
          <!--          </button>-->
          <button mat-icon-button (click)="editShareholder(element, i)">
            <mat-icon class="text-darkGray">edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteShareholder(i)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!--    Group type-->
    <ng-container matColumnDef="type-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="!text-center !text-white">
        Type
      </th>
    </ng-container>

    <!--    Group name & email-->
    <ng-container matColumnDef="name-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="!text-white">
        Name
      </th>
    </ng-container>

    <!--    Group ID-->
    <ng-container matColumnDef="id-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="!text-white !text-center">
        ID
      </th>
    </ng-container>


    <!--    Group residence-->
    <ng-container matColumnDef="residence-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="!text-white !text-center">
        Nationality/ <br/>Incorporation Country
      </th>
    </ng-container>

    <!--    Group shareholding-->
    <ng-container matColumnDef="shareholding-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.colspan]="3"
          class="w-2/6 !text-center !text-white">
        Number of Shares
      </th>
    </ng-container>

    <!--    Group ownership-->
    <ng-container matColumnDef="ownership-group">
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="w-24 !text-center !text-white">
        Ownership
      </th>
    </ng-container>

    <!--    Group actions-->
    <ng-container matColumnDef="actions-group" stickyEnd>
      <th mat-header-cell
          *matHeaderCellDef
          [attr.rowspan]="2"
          class="!w-36 !text-center !text-white"
          [ngClass]="{ hidden: appearance === 'viewOnly' }">
        Actions
      </th>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="[
        'type-group',
        'name-group',
        'id-group',
        'residence-group',
        'shareholding-group',
        'ownership-group',
        'actions-group']"
        class="bg-primary-800 group-header"></tr>
    <tr mat-header-row
        *matHeaderRowDef="displayedShareholderColumns"
        class="bg-primary-800"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedShareholderColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td [colSpan]="displayedShareholderColumns.length">
        <table-no-record
          [notFoundMessage]="'You have not declared any shareholder.'"
        ></table-no-record>
      </td>
    </tr>
  </table>
</section>
