import { Injectable } from "@angular/core";
import {
  CloseExtraJobMutation, ConfirmExtraJobMutation,
  DeleteExtraJobMutation,
  ExtraJobInput, ExtraJobQuery,
  ExtraJobType,
  UpdateOrCreateExtraJobMutation
} from "@generated/graphql";
import { map, Observable } from "rxjs";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";
import { extraJobFactory } from "@modules/extra-job/factories/extra-job.factory";
import { FindContract } from "@shared/contracts/find.contract";
import { DeleteContract } from "@shared/contracts/delete.contract";

@Injectable({providedIn: 'root'})
export class ExtraJobApi implements FindContract<ExtraJob>, DeleteContract<string, Observable<boolean>> {
  constructor (
    protected readonly updateOrCreateExtraJob: UpdateOrCreateExtraJobMutation,
    protected readonly deleteExtraJobMutation: DeleteExtraJobMutation,
    protected readonly closeExtraJobMutation: CloseExtraJobMutation,
    protected readonly confirmExtraJobMutation: ConfirmExtraJobMutation,
    protected readonly extraJobQuery: ExtraJobQuery
  ) {
  }

  updateOrCreate$ (input: ExtraJobInput): Observable<ExtraJob> {
    return this.updateOrCreateExtraJob.mutate({input}).pipe(
      map(res => res.data.updateOrCreateExtraJob ? extraJobFactory.create(res.data.updateOrCreateExtraJob as ExtraJobType) : null)
    )
  }

  delete$ (id: string): Observable<boolean> {
    return this.deleteExtraJobMutation.mutate({id}).pipe(map(res => res.data.deleteExtraJob))
  }

  find$ (id: string): Observable<ExtraJob> {
    return this.extraJobQuery.fetch({id}, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.extraJob ? extraJobFactory.create(res.data.extraJob as ExtraJobType) : null)
    );
  }

  confirm$ (id: string): Observable<ExtraJob> {
    return this.confirmExtraJobMutation.mutate({id}).pipe(
      map(res => res.data.confirmExtraJob ? extraJobFactory.create(res.data.confirmExtraJob as ExtraJobType) : null)
    );
  }

  close$ (id: string): Observable<ExtraJob> {
    return this.closeExtraJobMutation.mutate({id}).pipe(
      map(res => res.data.closeExtraJob ? extraJobFactory.create(res.data.closeExtraJob as ExtraJobType) : null)
    );
  }
}
