import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyDirectorService } from '@app/modules/company-director/services/company-director.service';
import { CompanyDirector } from '@app/modules/company/models/company-director.model';
import { JobService } from '@app/modules/job/services/job.service';
import { OfficerIndividualFormType } from '@app/modules/officer/models/form/officer-individual-form-type';
import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';
import {
  CompanyDirectorInput,
  CreateCorporateJobInput,
  DirectorJobInput,
  JobMatterEnum,
  OfficerCompanyInput,
  OfficerDocumentStatusEnum,
  OfficerPersonInput,
  OfficerType,
  OfficerTypeEnum,
  ReplaceDirectorJobInput,
  ServiceEnum,
} from '@generated/graphql';
import { OfficerFormFactory } from '@shared/classes/officer/officer-form-factory';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { startCase } from 'lodash-es';
import { DateTime } from 'luxon';
import { OfficerRequestFormManagement } from './officer-request-form-management';

@Injectable({
  providedIn: 'any',
})
export class DirectorOfficerRequestForm extends OfficerRequestFormManagement {
  public nomineeExists = false;

  public editingDirector: CompanyDirector;

  constructor(
    protected _directorService: CompanyDirectorService,
    protected _officerInputHelper: OfficerInputHelpers,
    protected _jobService: JobService,
  ) {
    super();
  }

  isNominee() {
    return this.typeSelected === OfficerTypeEnum.Nominee;
  }

  public getOfficerTypeOption() {
    let filtered = super.getOfficerTypeOption();

    return filtered;
  }

  public createForm() {
    if (this.typeSelected === OfficerTypeEnum.Nominee) {
      this.formInput = OfficerFormFactory.createForm(
        OfficerTypeEnum.Individual,
      );
      this.formInput.createOfficerForm();
      this.addNominee();

      // this.generateRequireDocuments();

      setTimeout(() => {
        this.formInput.officerForm.disable();
      }, 256);
    } else {
      super.createForm();

      setTimeout(() => {
        this.formInput.officerForm.enable();
      }, 256);
    }
  }

  public addNominee() {
    this.formInput.officerForm.reset();
    this.formInput.officerForm.patchValue({
      firstName: 'Nominee',
      countryId: this.countryOfIncorporation.country.id,
    });
  }

  public getFormValue(): CompanyDirectorInput {
    let keyName = 'officerPerson';

    let officerType = OfficerTypeEnum.Individual.toString();

    let formRawValue = this.formInput.officerForm.getRawValue();

    let officerRawValue = null;

    if (this.isNominee()) {
      const form = this.formInput
        .officerForm as FormGroup<OfficerIndividualFormType>;

      officerRawValue = {
        firstName: form.controls.firstName.getRawValue(),
        countryId: form.controls.countryId.getRawValue(),
      };
    } else if ('identification' in formRawValue) {
      officerRawValue = this._officerInputHelper.transformOfficerInput(
        formRawValue,
      ) as OfficerPersonInput;
    } else if ('representative' in formRawValue) {
      officerRawValue = this._officerInputHelper.transformOfficerInput(
        formRawValue,
      ) as OfficerCompanyInput;

      keyName = 'officerCompany';
      officerType = OfficerTypeEnum.Corporate.toString();
    }

    const input: CompanyDirectorInput = {
      dateFrom: this.effectiveDate.value || new Date(),
      isNominee: this.isNominee(),
      officerType,
      [keyName]: officerRawValue,
    };

    return input;
  }

  public filterAndSortExistOfficers(): this {
    this.existsOfficerManagement.filterAndSortExistOfficersForDirector();

    return this;
  }

  protected setExistsOfficerToForm(officer: OfficerType) {
    if ('identification' in officer.officerProfile) {
      const input =
        this._officerInputHelper.transformOfficerPersonFromTypeToInput(
          officer.officerProfile,
        );

      this.formInput.setEditData(input, undefined);
    } else if ('representative' in officer.officerProfile) {
      const input =
        this._officerInputHelper.transformOfficerCompanyFromTypeToInput(
          officer.officerProfile,
        );

      this.formInput.setEditData(input, undefined);
    }

    if (this.mode === 'Replace' && this.existsOfficerManagement.refId) {
      this.checkNeedDocument(officer);
    }

    this.formInput.officerForm.updateValueAndValidity();

    setTimeout(() => {
      this.formInput.officerForm.disable();
    }, 256);
  }

  public setEditData(officer: CompanyDirector): this {
    this.editingDirector = officer;

    const officerProfile = officer.officer.officerProfile;

    if ('identification' in officerProfile) {
      // INFO: officer person
      this.setOfficerPersonOfficerData(officerProfile);
    } else if ('representative' in officerProfile) {
      // INFO: officer company
      this.setOfficerCompanyOfficerData(officerProfile);
    }

    return this;
  }

  private checkNeedDocument(officer: OfficerType) {
    if (!officer.officerDocuments) {
      this.needDocument = true;
    } else {
      officer.officerDocuments.forEach((d) => {
        if (!(d.status === OfficerDocumentStatusEnum.Verified)) {
          this.needDocument = true;

          return;
        } else {
          this.needDocument = false;
        }
      });
    }
  }

  private createNewDirector() {
    const directorInput: DirectorJobInput = {
      companyId: this.company.id,
      director: {
        id: this.existsOfficerManagement.refId ?? null,
        ...this.getFormValue(),
        dateFrom: DateTime.fromJSDate(this.effectiveDate.value).toISODate(),
      },
    };

    if (!this.isNominee()) {
      directorInput.documents = this.uploadedDocuments;
    }

    if (this.existsOfficerManagement.refId) {
      directorInput.director.id = this.existsOfficerManagement.refId;
      directorInput.director.refId = this.existsOfficerManagement.refId;
    }

    if (!this.activeUser.isCustomer()) {
      // INFO: FOR EXPERT

      this.postStatus$.next('SUBMITTING');

      this._directorService.expertCreateDirector(directorInput).subscribe({
        next: (result) => this.postStatus$.next('SUCCESS'),
        error: (error) => {
          this.postStatus$.next('ERROR');
          return error;
        },
        complete: () => this.postStatus$.next('FINISHED'),
      });
    } else {
      // INFO: FOR CUSTOMER

      let serviceName = ServiceEnum.AddDirector;
      let jobMatter = JobMatterEnum.AddDirector;

      this.postStatus$.next('SUBMITTING');

      if (this.isNominee()) {
        serviceName = ServiceEnum.NomineeDirector;
        jobMatter = JobMatterEnum.AddDirector;

        // this.jobServiceOrder.toggleUserNomineeService('add');
      }
      // else {
      //   this.jobServiceOrder.toggleUserNomineeService('remove');
      // }

      this.jobServiceOrder.addOrUpdateService(serviceName, 'mainServices');

      const jobInput: CreateCorporateJobInput = {
        serviceName,
        jobInput: {
          matter: startCase(jobMatter),
          companyId: this.company.id,
          serviceSupportedCountryId: this.serviceSupportedCountry.id,
        },
        directorInput,
      };

      const jobDetail = {
        ...jobInput,
        jobServiceOrder: this.jobServiceOrder.jobServicesOrder,
      };

      jobInput.jobInput.detail = JSON.stringify(jobDetail);

      this._jobService.createCorporateJob(jobInput).subscribe({
        next: (job) => {
          this.createdJob = job;

          this.postStatus$.next('SUCCESS');
        },
        error: (error) => {
          this.postStatus$.next('ERROR');

          return error;
        },
        complete: () => {
          this.postStatus$.next('FINISHED');
        },
      });
    }
  }

  private updateDirector() {
    const directorInput: DirectorJobInput = {
      director: {
        id: this.editingDirector.id,
        ...this.getFormValue(),
        dateFrom: DateTime.fromJSDate(this.effectiveDate.value).toISODate(),
      },
    };

    directorInput.documents = this.uploadedDocuments;

    if (!this.activeUser.isCustomer()) {
      // INFO: FOR EXPERT
      this.postStatus$.next('SUBMITTING');

      this._directorService.expertUpdateDirector(directorInput).subscribe({
        next: (result) => this.postStatus$.next('SUCCESS'),
        error: (error) => {
          this.postStatus$.next('ERROR');
          return error;
        },
        complete: () => this.postStatus$.next('FINISHED'),
      });
    } else {
      // INFO: FOR CUSTOMER

      const serviceName = ServiceEnum.EditDirector;

      this.postStatus$.next('SUBMITTING');

      this.jobServiceOrder.addOrUpdateService(serviceName, 'mainServices');

      const jobInput: CreateCorporateJobInput = {
        serviceName,
        jobInput: {
          matter: startCase(JobMatterEnum.EditDirector),
          companyId: this.company.id,
          serviceSupportedCountryId: this.serviceSupportedCountry.id,
        },
        directorInput,
      };

      const jobDetail = {
        ...jobInput,
        jobServiceOrder: this.jobServiceOrder.jobServicesOrder,
        oldDirector: this.editingDirector,
      };

      jobInput.jobInput.detail = JSON.stringify(jobDetail);

      this._jobService.createCorporateJob(jobInput).subscribe({
        next: (job) => {
          this.createdJob = job;

          this.postStatus$.next('SUCCESS');
        },
        error: (error) => {
          this.postStatus$.next('ERROR');

          return error;
        },
        complete: () => {
          this.postStatus$.next('FINISHED');
        },
      });
    }
  }

  protected getReplaceDirectorInput() {
    const directorInput: ReplaceDirectorJobInput = {
      companyId: this.company.id,
      director: {
        id: this.existsOfficerManagement.refId ?? null,
        ...this.getFormValue(),
        dateFrom: DateTime.fromJSDate(this.effectiveDate.value).toISODate(),
      },
    };

    directorInput.documents = this.uploadedDocuments;

    return directorInput;
  }

  private replaceDirector() {
    const directorInput: ReplaceDirectorJobInput =
      this.getReplaceDirectorInput();

    if (!this.activeUser.isCustomer()) {
      directorInput.effectiveDate = DateTime.fromJSDate(
        this.effectiveDate.value,
      ).toISODate();

      directorInput.replacedId = this.editingDirector.id;

      // INFO: FOR EXPERT
      this.postStatus$.next('SUBMITTING');

      this._directorService.expertReplaceDirector(directorInput).subscribe({
        next: (result) => this.postStatus$.next('SUCCESS'),
        error: (error) => {
          this.postStatus$.next('ERROR');
          return error;
        },
        complete: () => this.postStatus$.next('FINISHED'),
      });
    } else {
      // INFO: FOR CUSTOMER

      const serviceName = ServiceEnum.ReplaceDirector;

      this.postStatus$.next('SUBMITTING');

      this.jobServiceOrder.addOrUpdateService(serviceName, 'mainServices');

      const jobInput: CreateCorporateJobInput = {
        serviceName,
        jobInput: {
          matter: startCase(JobMatterEnum.ReplaceDirector),
          companyId: this.company.id,
          serviceSupportedCountryId: this.serviceSupportedCountry.id,
        },
        directorInput,
      };

      const jobDetail = {
        ...jobInput,
        jobServiceOrder: this.jobServiceOrder.jobServicesOrder,
        oldDirector: this.editingDirector,
      };

      jobInput.jobInput.detail = JSON.stringify(jobDetail);

      this._jobService.createCorporateJob(jobInput).subscribe({
        next: (job) => {
          this.createdJob = job;

          this.postStatus$.next('SUCCESS');
        },
        error: (error) => {
          this.postStatus$.next('ERROR');

          return error;
        },
        complete: () => {
          this.postStatus$.next('FINISHED');
        },
      });
    }
  }

  public submitForm() {
    if (this.mode === 'New') {
      this.createNewDirector();
    } else if (this.mode === 'Edit') {
      this.updateDirector();
    } else if (this.mode === 'Replace') {
      this.replaceDirector();
    }
  }

  public setServiceSupportedCountry(services: ServiceSupportedCountry[]): this {
    let serviceName = ServiceEnum.AddDirector;

    if (this.mode === 'Edit') {
      serviceName = ServiceEnum.EditDirector;
    } else if (this.mode === 'Replace') {
      serviceName = ServiceEnum.ReplaceDirector;
    }

    this.serviceSupportedCountry = services.find(
      (s) =>
        s.supportedCountry.id === this.countryOfIncorporation.id &&
        s.service.name === serviceName,
    );

    return this;
  }
}
