import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";
import { ScreenSpinnerService } from "@shared/component/full-screen-spinner/screen-spinner.service";

@Component({
  standalone: true,
  selector: 'app-screen-spinner',
  templateUrl: './screen-spinner.component.html',
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  styleUrls: ['./screen-spinner.component.scss']
})
export class ScreenSpinnerComponent implements OnInit {
  loading$ = this.screenSpinnerService.loading$

  constructor (
    private readonly screenSpinnerService: ScreenSpinnerService
  ) {
  }

  ngOnInit (): void {
  }
}
