import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {
  AbstractAddressDialogComponent,
} from '@modules/address/components/dialog/abstract-address-dialog/abstract-address-dialog.component';
import {FormBuilder, FormControl} from '@angular/forms';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {Observable} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {CommonHelper} from "@shared/helpers/common.helper";
import {AddressTypeApi} from "@modules/address/api/address-type.api";

export interface RemoveAddressDialogData {
  address: BillingAddress;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,

    MatDividerModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  selector: 'app-address-removal',
  templateUrl: './address-removal.component.html',
  styleUrls: ['./address-removal.component.scss'],
})
export class AddressRemovalComponent extends AbstractAddressDialogComponent<FormControl<string>, AddressRemovalComponent, RemoveAddressDialogData, boolean> implements OnInit {

  get id(): string {
    return this.data.address.id;
  }

  protected getControl(userId: string): FormControl<string> {
    return this.fb.control(this.id);
  }

  protected getQuery$(): Observable<boolean> {
    return this.billingAddressApi.delete$(this.id);
  }

  get label(): string {
    return 'Delete';
  }

  constructor(
    @Inject(APP_ROUTES) public readonly appRoutes: AppRoutes,
    @Inject(MAT_DIALOG_DATA) protected readonly data: RemoveAddressDialogData,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly dialogRef: MatDialogRef<AddressRemovalComponent>,
    protected readonly fb: FormBuilder,
    protected readonly billingAddressApi: BillingAddressApi,
    protected readonly snackBarService: SnackBarService,
    protected readonly commonHelper: CommonHelper,
    protected readonly addressTypeApi: AddressTypeApi,
  ) {
    super(data, activeUser$, dialogRef, fb, billingAddressApi, snackBarService, addressTypeApi);
  }

  ngOnInit(): void {
    this.setAddress(this.data.address);
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
