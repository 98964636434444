import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseLayoutComponent} from 'src/shared/layout/base-layout/base-layout.component';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink, RouterLinkWithHref, RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {LetModule} from '@ngrx/component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SharedComponentModule} from '@shared/component/component.module';
import {TenantLogoComponent} from '@shared/component/common/tenant-logo/tenant-logo.component';
import {TenantLinkSideMenuComponent} from '@shared/component/tenant-link-side-menu/tenant-link-side-menu.component';
import {EmptyLayoutComponent} from './empty-layout/empty-layout.component';
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {
    TopMenuComponentComponent
} from "@shared/layout/main-layout/components/top-menu-component/top-menu-component.component";

@NgModule({
  declarations: [BaseLayoutComponent, MainLayoutComponent, EmptyLayoutComponent],
  exports: [BaseLayoutComponent, MainLayoutComponent, EmptyLayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatIconModule,
        RouterLinkWithHref,
        RouterLink,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,

        // Import this module to use ngrxLet directive
        LetModule,

        // Import global snackbar
        MatSnackBarModule,
        SharedComponentModule,
        TenantLogoComponent,
        TenantLinkSideMenuComponent,
        CalloutComponent,
        TopMenuComponentComponent,
    ],
})
export class LayoutModule {
}
