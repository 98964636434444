import {
  EnvironmentInjector,
  inject,
  Inject,
  Injectable,
  Injector,
} from '@angular/core';
import { EntityService } from '@shared/services/entity.service';
import { EntityFeatureNameEnum } from '@shared/contracts/entity-store.contract';
import {
  AllJobFilesQuery,
  CancelJobMutation,
  ChangeJobEstimatedTimeMutation,
  ChildrenJobsQuery,
  ConfirmJobMutation,
  CreateCompanyJobInput,
  CreateCompanyJobMutation,
  CreateCorporateJobInput,
  CreateCorporateJobMutation,
  FileType,
  JobInput,
  JobType,
  ServiceEnum,
  UpdateJobInput,
  UpdateJobMutation,
} from '@generated/graphql';
import { Job } from '@modules/job/models/job.model';
import { JobFactory, jobFactory } from '@modules/job/factories/job.factory';
import { Store } from '@ngrx/store';
import { JOB_STORE, JobStore } from '@modules/job/store/job.store';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  first,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { OrderHelper } from '@shared/helpers/order.helpers';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { Company } from '@modules/company/models/company.model';
import { ServiceSupportedCountryService } from '@modules/service/services/service-supported-country.service';

@Injectable({
  providedIn: 'root',
})
export class JobService extends EntityService<
  EntityFeatureNameEnum.Job,
  JobType,
  Job
> {
  constructor(
    protected readonly store: Store,
    @Inject(JOB_STORE) protected readonly entityStore: JobStore,
    private readonly createCompanyJobMutation: CreateCompanyJobMutation,
    private readonly allJobFilesQuery: AllJobFilesQuery,
    private readonly _createCorporateJob: CreateCorporateJobMutation,
    private readonly _cancelJobMutation: CancelJobMutation,
    private readonly _confirmJobMutation: ConfirmJobMutation,
    private readonly _changeJobEstimatedTimeMutation: ChangeJobEstimatedTimeMutation,
    private readonly _updateJobMutation: UpdateJobMutation,
    private readonly _childrenJobsQuery: ChildrenJobsQuery,
    private readonly injector: EnvironmentInjector,
  ) {
    super(store, entityStore);
  }

  get factory(): JobFactory {
    return jobFactory;
  }

  createCompanyJob(data: CreateCompanyJobInput) {
    return this.createCompanyJobMutation.mutate(
      {
        input: data,
      },
      {
        context: {
          useMultipart: true,
        },
      },
    );
  }

  allJobFiles(jobId: string) {
    return this.allJobFilesQuery
      .fetch(
        {
          id: jobId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(map(({ data }) => data.allJobFiles as FileType[]));
  }

  createCorporateJob(input: CreateCorporateJobInput): Observable<JobType> {
    return this._createCorporateJob
      .mutate(
        {
          input,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(map((result) => result.data.createCorporateJob as JobType));
  }

  cancelJob(jobId: string): Observable<boolean> {
    return this._cancelJobMutation
      .mutate({
        jobId,
      })
      .pipe(map((result) => result.data.cancelJob));
  }

  confirmJob(jobId: string): Observable<boolean> {
    return this._confirmJobMutation
      .mutate({
        jobId,
      })
      .pipe(map((result) => result.data.confirmJob));
  }

  changeJobEstimatedTime(
    id: string,
    input: UpdateJobInput,
  ): Observable<boolean> {
    return this._changeJobEstimatedTimeMutation
      .mutate({
        id,
        input,
      })
      .pipe(map((result) => result.data.changeJobEstimatedTime));
  }

  public updateJob(id: string, input: UpdateJobInput): Observable<JobType> {
    return this._updateJobMutation
      .mutate({
        id,
        input,
      })
      .pipe(map((response) => response.data.updateJob as JobType));
  }

  createSimpleJob$(
    company: Company,
    service: ServiceEnum,
    input: Omit<CreateCorporateJobInput, 'jobInput' | 'serviceName'> & {
      jobInput: Pick<JobInput, 'matter' | 'detail'>;
    },
    options?: {
      loading$?: BehaviorSubject<boolean>;
      dialogRef?: MatDialogRef<any>;
    },
  ): Observable<Job> {
    let toolkit: {
      serviceSupportedCountryService: ServiceSupportedCountryService;
      snackBarService: SnackBarService;
      router: Router;
      appRoutes: AppRoutes;
      orderHelper: OrderHelper;
    };

    this.injector.runInContext(() => {
      toolkit = {
        serviceSupportedCountryService: inject(ServiceSupportedCountryService),
        snackBarService: inject(SnackBarService),
        router: inject(Router),
        appRoutes: inject(APP_ROUTES) as AppRoutes,
        orderHelper: inject(OrderHelper),
      };
    });

    const {
      snackBarService,
      appRoutes,
      orderHelper,
      router,
      serviceSupportedCountryService,
    } = toolkit;

    options?.loading$?.next(true);

    if (!company) {
      snackBarService.pushErrorAlert('Request failed!');

      options?.dialogRef?.close();

      return null;
    }

    return serviceSupportedCountryService
      .findByCountryIdAndServiceName$(company?.country?.id, service)
      .pipe(
        first(),
        switchMap((serviceSupportedCountry) => {
          if (!serviceSupportedCountry) {
            snackBarService.pushErrorAlert('Request failed!');

            options?.dialogRef?.close();

            return EMPTY;
          }

          return this.createCorporateJob({
            ...input,
            jobInput: {
              serviceSupportedCountryId: serviceSupportedCountry.id,
              companyId: company.id,
              matter: input.jobInput.matter,
              detail: input.jobInput.detail,
            },
            serviceName: serviceSupportedCountry.service.name as ServiceEnum,
          }).pipe(
            map((job) => jobFactory.tryCreating(job)),
            tap((job) => {
              if (job) {
                options?.dialogRef?.close();

                snackBarService.pushAlert('Request success!');

                orderHelper.setupByJob(jobFactory.create(job));

                return router.navigate(
                  !!job.order?.canMakePayment
                    ? appRoutes.jobCheckout(job.id)
                    : appRoutes.jobDetail(job.id),
                );
              }

              return snackBarService.pushErrorAlert('Request failed!');
            }),
            finalize(() => {
              options?.loading$?.next(false);
            }),
            catchError((e) => {
              console.error(e);
              snackBarService.pushErrorAlert('Request failed!');

              return EMPTY;
            }),
          );
        }),
      );
  }

  childrenJobsQuery(parentId: string): Observable<Job[]> {
    return this._childrenJobsQuery
      .fetch(
        {
          parentId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((result) =>
          result.data.childrenJobs.map((jobType) =>
            jobFactory.create(jobType as JobType),
          ),
        ),
      );
  }
}
