import { PaymentType } from "@generated/graphql";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum, EntityStore, EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { InjectionToken, Provider } from "@angular/core";
import { Payment } from "@modules/payment/models/payment.model";

export class PaymentStore extends EntityStore<EntityFeatureNameEnum.Payment, PaymentType, Payment> {
  getEntry (): EntityEntry<EntityFeatureNameEnum.Payment> {
    return 'PAYMENT';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Payment> {
    return 'paymentFeature';
  }

  get selectState (): SelectState<PaymentType> {
    return this.feature.selectPaymentFeatureState;
  }
}

export const paymentStore = new PaymentStore()

export const PAYMENT_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.PaymentStore)

export const PAYMENT_STORE_CONFIG: Provider = {
  provide: PAYMENT_STORE,
  useValue: paymentStore,
}
