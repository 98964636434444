import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {User} from "@modules/user/models/user.model";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BehaviorSubject} from "rxjs";
import {LetModule} from "@ngrx/component";

@Component({
  standalone: true,
  selector: 'app-user-assigned-chip-list[users]',
  templateUrl: './user-assigned-chip-list.component.html',
  imports: [
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
    LetModule
  ],
  styleUrls: ['./user-assigned-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAssignedChipListComponent implements OnInit, OnChanges {
  protected limitDisplayed: number = 4;
  @Input() users: User[] = []

  get users$() {
    return this._users$.asObservable()
  }

  private _users$ = new BehaviorSubject<User[]>([])

  ngOnChanges(changes: SimpleChanges): void {
    this.setUsers(this.users)
  }

  ngOnInit(): void {
    this.setUsers(this.users)
  }

  setUsers(users: User[]) {
    this._users$.next([...users])
  }

  getLimitUsers(){

  }

}
