import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { verifyEmailActions } from '@modules/auth/store/actions/verify-email.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AuthApi } from '@modules/auth/api/auth.api';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { Router } from '@angular/router';

@Injectable()
export class VerifyEmailEffects {
  onVerifyEmail$ = createEffect(() => this.actions$.pipe(
    ofType(verifyEmailActions.verifyEmail),
    switchMap(({ input }: ReturnType<typeof verifyEmailActions.verifyEmail>) => this.authApi.verifyEmail(input)),
    map(user => verifyEmailActions.verifyEmailSuccess({ user })),
    catchError(e => of(verifyEmailActions.verifyEmailError())),
  ));

  onVerifyEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(verifyEmailActions.verifyEmailSuccess),
    tap(() => {
      // logic here
    }),
  ), {
    dispatch: false,
  });

  onVerifyEmailError$ = createEffect(() => this.actions$.pipe(
    ofType(verifyEmailActions.verifyEmailError),
    tap(() => {
      // logic here
    }),
  ), {
    dispatch: false,
  });

  constructor (
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
    private readonly actions$: Actions,
    private readonly authApi: AuthApi,
    private readonly router: Router,
  ) {
  }
}
