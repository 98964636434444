import { ServiceEnum, ServiceType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { Service } from "@modules/service/models/service.model";
import { createSelector } from "@ngrx/store";

export class ServiceStore extends EntityStore<EntityFeatureNameEnum.Service, ServiceType, Service> {
  get selectState (): SelectState<ServiceType> {
    return this.feature.selectServiceFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Service> {
    return 'SERVICE';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Service> {
    return 'serviceFeature';
  }

  selectEntityByName = (name: ServiceEnum) => createSelector(
    this.selectIds,
    this.selectEntities,
    (ids, entities) => ids.map(id => entities[id]).find(item => item.name === name)
  )
}

export const serviceStore = new ServiceStore()

export const SERVICE_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.ServiceStore)

export const SERVICE_STORE_CONFIG: Provider = {
  provide: SERVICE_STORE,
  useValue: serviceStore,
}
