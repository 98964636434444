import { CredentialsProps } from '@modules/auth/types/auth.type';
import { createAction, props } from '@ngrx/store';
import { LoginInput, UserType } from '@generated/graphql';

export enum LoginActionTypes {
  Login = '[Auth] Login',
  LoginError = '[Auth] Login error',
  LoginSuccess = '[Auth] Login success',
}

export const login = createAction(
  LoginActionTypes.Login,
  props<{input: LoginInput}>(),
);

export const loginSuccess = createAction(
  LoginActionTypes.LoginSuccess,
  props<{
    user: UserType
  }>(),
);

export const loginError = createAction(
  LoginActionTypes.LoginError,
  props<{loginErrorMessages: string[], mustVerifyEmail: boolean}>(),
);
