import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BusinessProfile } from '@app/modules/account-settings/pages/business-profile/models/business-profile.model';
import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  Database,
  getDatabase,
  onValue,
  ref,
  set,
  Unsubscribe,
  update,
} from 'firebase/database';
import { snakeCase } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import {
  ConversationMessage,
  ConversationMessageInput,
  ConversationMessageTypeEnum,
  GroupMessages,
  MessageActionEnum,
} from './message-type';
import { environment } from '@environment/environment';
import { ConversationService } from '@app/modules/job/components/job-detail/conversation/services/conversation.service';
import { File as ServerFile } from '@generated/graphql';
import { ConversationMemberManagement } from './conversation-member-management.class';
import ConversationMetadataManagement from './conversation-metadata-management.class';

type FormType = {
  body?: FormControl<string | null>;
};

@Injectable({
  providedIn: 'any',
})
export default class ConversationManagement {
  private _businessProfile: BusinessProfile;
  public set businessProfile(val: BusinessProfile) {
    this._businessProfile = val;
  }

  private _app: FirebaseApp;
  public get app(): FirebaseApp {
    return this._app;
  }
  public set app(value: FirebaseApp) {
    this._app = value;
  }

  private _db: Database;
  public get db(): Database {
    return this._db;
  }
  public set db(value: Database) {
    this._db = value;
  }
  /**
   * Room Id follow by form mat [room_${jobId}]
   */
  private _roomId: string;
  public get roomId(): string {
    return this._roomId;
  }
  public set roomId(value: string) {
    this._roomId = value;
  }

  /**
   * Messages from firebase
   */
  private _messages: ConversationMessage[] = [];
  public get messages(): ConversationMessage[] {
    return this._messages;
  }
  public set messages(value: ConversationMessage[]) {
    this._messages = value;
  }

  /**
   * Grouped messages by date
   */
  private _groupedMessages: GroupMessages;
  public get groupedMessages(): GroupMessages {
    return this._groupedMessages;
  }
  public set groupedMessages(value: GroupMessages) {
    this._groupedMessages = value;
  }

  private _fb = new FormBuilder();

  private _form: FormGroup<FormType> = this._fb.group<FormType>({
    body: this._fb.control(null, [Validators.required]),
  });
  public get form(): FormGroup<FormType> {
    return this._form;
  }

  private _fetching$ = new BehaviorSubject(true);
  public get fetching$() {
    return this._fetching$;
  }

  private get dbRefPath() {
    return `tenant_${snakeCase(this._businessProfile.name)}_${
      this._businessProfile.id
    }/rooms/${this.roomId}`;
  }

  private _serviceReady = false;
  public get serviceReady() {
    return this._serviceReady;
  }

  private _messageAction: MessageActionEnum;
  public get messageAction(): MessageActionEnum {
    return this._messageAction;
  }
  public set messageAction(value: MessageActionEnum) {
    this._messageAction = value;
  }

  private _editingMessage: ConversationMessage;
  public get editingMessage(): ConversationMessage {
    return this._editingMessage;
  }
  public set editingMessage(value: ConversationMessage) {
    this._editingMessage = value;
  }

  private _files: File[] = [];
  public get files(): File[] {
    return this._files;
  }
  public set files(value: File[]) {
    this._files = value;
  }

  private _conversationService: ConversationService;
  public get conversationService(): ConversationService {
    return this._conversationService;
  }
  public set conversationService(value: ConversationService) {
    this._conversationService = value;
  }

  public memberManagement = new ConversationMemberManagement();

  public metadataManagement = new ConversationMetadataManagement();

  public uploadStatus$ = new BehaviorSubject<
    'idle' | 'uploading' | 'done' | 'error'
  >('idle');

  public appState$ = new BehaviorSubject<
    | 'initializing'
    | 'setup'
    | 'setup_done'
    | 'get_members'
    | 'get_members_done'
    | 'get_messages'
    | 'get_messages_done'
    | 'not_available'
    | 'finished'
  >('initializing');

  private _unsubs: Unsubscribe[] = [];
  public get unsubs(): Unsubscribe[] {
    return this._unsubs;
  }
  public set unsubs(value: Unsubscribe[]) {
    this._unsubs = value;
  }

  public constructor() {}

  public async setup() {
    this.appState$.next('setup');

    this.app = initializeApp(environment.firebaseConfig);
    this.db = getDatabase(this.app);

    this.memberManagement.db = this.db;
    this.memberManagement.dbRefPath = this.dbRefPath;
    this.memberManagement.app = this.app;

    this.metadataManagement.db = this.db;
    this.metadataManagement.dbRefPath = this.dbRefPath;
    this.metadataManagement.app = this.app;

    this.appState$.next('setup_done');

    await this.memberManagement.signInSender();

    await this.memberManagement.joinUsers();

    await this.metadataManagement.getData();

    this._serviceReady = true;
  }

  public getMessages() {
    if (!this._serviceReady) {
      this._fetching$.next(false);

      return;
    }

    try {
      const today = DateTime.now();

      if (!this.groupedMessages) {
        this.groupedMessages = {};
      }

      const messagesRef = ref(this.db, `${this.dbRefPath}/messages`);

      const unsub = onValue(messagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          for (let id in data) {
            const item: ConversationMessageInput = data[id];

            let message: ConversationMessage = {
              id,
              isMe: item.senderId === this.memberManagement.sender.id,
              type: item.type as ConversationMessageTypeEnum,
              body: item.body,
              avatarVisible: true,
              nameVisible: true,
              createdAt: item.createdAt
                ? DateTime.fromMillis(item.createdAt).toJSDate()
                : null,
              updatedAt: item.updatedAt
                ? DateTime.fromMillis(item.updatedAt).toJSDate()
                : null,
              deletedAt: item.deletedAt
                ? DateTime.fromMillis(item.deletedAt).toJSDate()
                : null,
              sender: this.memberManagement.members.find(
                (u) => u.id === item.senderId,
              ),
              file: item.file ?? undefined,
            };

            if (item.replied) {
              message.replied = {
                messageId: item.replied.messageId,
                body: item.replied.body,
                displayName: item.replied.displayName,
                repliedAt: DateTime.fromMillis(
                  item.replied.repliedAt,
                ).toJSDate(),
              };
            }

            const messageDate = DateTime.fromJSDate(message.createdAt);
            let keyLabel = messageDate.toLocaleString();

            if (today.startOf('day').equals(messageDate.startOf('day'))) {
              keyLabel = 'Today';
            }

            if (!this.groupedMessages[keyLabel]) {
              this.groupedMessages[keyLabel] = [message];
            } else {
              const messages = this.groupedMessages[keyLabel];

              const existsIndex = messages.findIndex((m) => m.id === id);

              if (existsIndex === -1) {
                const messagesLength = this.groupedMessages[keyLabel].length;

                const prevMessage = this.groupedMessages[keyLabel].at(
                  messagesLength - 1,
                );

                if (prevMessage.sender.id === message.sender.id) {
                  message.avatarVisible = false;
                  message.nameVisible = false;
                } else {
                  message.avatarVisible = !message.isMe;
                  message.nameVisible = !message.isMe;
                }

                this.groupedMessages[keyLabel].push(message);
              } else {
                // for updated and deleted messages
                message = {
                  ...messages[existsIndex],
                  ...message,
                };

                this.groupedMessages[keyLabel][existsIndex] = message;
              }
            }
          }

          if (this.metadataManagement.data?.lastMessageId) {
            const lastMessage = data[
              this.metadataManagement.data.lastMessageId
            ] as ConversationMessageInput;

            this.memberManagement.updateLastReadMessageIdForSender(
              lastMessage.id,
            );
          }
        }

        // this.groupMessagesByDate();

        setTimeout(() => {
          this._fetching$.next(false);
        }, 512);
      });

      this._unsubs.push(unsub);
    } catch (error) {
      console.error(error);

      this._fetching$.next(false);
    }
  }

  private groupMessagesByDate() {
    const today = DateTime.now();

    this.messages.forEach((message) => {
      if (!this.groupedMessages) {
        this.groupedMessages = {};
      }

      const messageDate = DateTime.fromJSDate(message.createdAt);

      let keyLabel = messageDate.toLocaleString();

      if (today.startOf('day').equals(messageDate.startOf('day'))) {
        keyLabel = 'Today';
      }

      if (!this.groupedMessages[keyLabel]) {
        this.groupedMessages[keyLabel] = [message];
      } else {
        const messagesLength = this.groupedMessages[keyLabel].length;

        const prevMessage = this.groupedMessages[keyLabel].at(
          messagesLength - 1,
        );

        if (prevMessage.sender.id === message.sender.id) {
          prevMessage.avatarVisible = false;
        } else {
          message.avatarVisible = !message.isMe;
        }

        this.groupedMessages[keyLabel].push(message);
      }
    });
  }

  public async sendMessage() {
    if (!this._serviceReady) {
      return;
    }

    await this.memberManagement.joinSender();

    const { body } = this.form.value;

    // new message
    if (!this.editingMessage) {
      const id = (+new Date()).toString();

      const message: ConversationMessageInput = {
        id,
        type: ConversationMessageTypeEnum.Text,
        body: body.trim(),
        senderId: this.memberManagement.sender.id,
        createdAt: +new Date(),
      };

      await set(
        ref(this.db, `${this.dbRefPath}/messages/${message.id}`),
        message,
      );

      await this.metadataManagement.setLastMessageId(id);

      await this.memberManagement.updateLastReadMessageIdForSender(id);
    } else {
      // edit message
      if (this.messageAction === MessageActionEnum.Edit) {
        await this.updateMessage(this.editingMessage, body);
        this.editingMessage = undefined;
      } else if (this.messageAction === MessageActionEnum.Reply) {
        const message: ConversationMessageInput = {
          id: (+new Date()).toString(),
          type: ConversationMessageTypeEnum.Text,
          body: body.trim(),
          replied: {
            messageId: this.editingMessage.id,
            body: this.editingMessage.body,
            displayName: this.editingMessage.sender.displayName,
            repliedAt: +new Date(),
          },
          senderId: this.memberManagement.sender.id,
          createdAt: +new Date(),
        };

        await set(
          ref(this.db, `${this.dbRefPath}/messages/${message.id}`),
          message,
        );

        this.editingMessage = undefined;
      }
    }

    this.form.reset();
  }

  public async updateMessage(
    message: ConversationMessage,
    body: string,
  ): Promise<boolean> {
    try {
      const data = {
        body,
        updatedAt: +new Date(),
      };

      await update(
        ref(this.db, `${this.dbRefPath}/messages/${message.id}`),
        data,
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async deleteMessage(message: ConversationMessage): Promise<boolean> {
    try {
      const data = {
        deletedAt: +new Date(),
      };

      await update(
        ref(this.db, `${this.dbRefPath}/messages/${message.id}`),
        data,
      );

      if (message.type === ConversationMessageTypeEnum.File) {
        this.conversationService.deleteFile(message.file.fileId).subscribe();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async sendUploadmessage(file: ServerFile): Promise<void> {
    const message: ConversationMessageInput = {
      id: (+new Date()).toString(),
      type: ConversationMessageTypeEnum.File,
      body: file.metadata.originalName,
      file: {
        fileId: file.id,
        fileSize: file.size || 0,
      },
      senderId: this.memberManagement.sender.id,
      createdAt: +new Date(),
    };

    await set(
      ref(this.db, `${this.dbRefPath}/messages/${message.id}`),
      message,
    );
  }

  public uploadFiles() {
    if (this.files.length) {
      this.uploadStatus$.next('uploading');

      this._conversationService.uploadFiles(this.roomId, this.files).subscribe({
        next: (response) => {
          response.forEach((file: ServerFile) => {
            this.sendUploadmessage(file);
          });

          this.uploadStatus$.next('done');

          this.files = [];
        },
        error: (error) => {
          console.log(error);
          this.uploadStatus$.next('error');
        },
      });
    }
  }

  public getMembers() {
    const unsub = this.memberManagement.getMembers();

    this.unsubs.push(unsub);
  }

  public unsubsribe() {
    this._unsubs.forEach((u) => u());
  }

  public onDestroy() {
    this.appState$.complete();
    this.uploadStatus$.complete();
    this.memberManagement.members$.complete();
    this.memberManagement.isMissingExperts$.complete();
  }
}
