<ng-container *ngrxLet="activeUser$ as activeUser">
    <div class="increase-capital-info-wrapper">
      <!--KYC REMINDER-->
      <ng-container *ngIf="activeUser.isCustomer()">
        <app-kyc-reminder-message [companyID]="job.company.id"></app-kyc-reminder-message>
      </ng-container>
      <!--NEW CAPITAL-->
      <section *ngIf="job">
        <p class="mat-title !text-base !mb-4 p-2 text-primary-900 bg-grey-50">
          New Capital Details&colon;
        </p>
        <app-company-capital-input-review [companyCapitalInputs]="companyCapitals"></app-company-capital-input-review>
      </section>
      <mat-divider class="!mx-auto w-1/2 !my-6"></mat-divider>

      <!--CHANGE DETAIL-->
      <section *ngIf="job && (company$ | async) as company">
        <p class="mat-title !text-base !mb-4 p-2 text-primary-900 bg-grey-50">
          Change in paid-up capital&colon;
        </p>

        <app-capital-input-summarize [company]="company" [newCapitals]="companyCapitals"></app-capital-input-summarize>
      </section>
      <mat-divider class="!mx-auto w-1/2 !my-6"></mat-divider>

      <!--ISSUE SHARES-->
      <section *ngIf="job">
        <p class="mat-title !text-base !mb-4 p-2 text-primary-900 bg-grey-50">
          New shares distribution&colon;
        </p>

        <app-callout *ngIf="!(allocatedValid$ | async)" appearance="error" customContent class="block !mb-4">
          <p class="mat-title !text-sub !mb-1">
            The Company shares must allocated 100% to shareholders
          </p>
        </app-callout>

        <app-share-holder-list-view-table hideAction [listOfficers]="shareholderListView$"
                                          [totalCapitalShares]="totalNewCapitalShares$ | async"
                                          [showEdit]="showEditDetails"
                                          (officerResolutionEvent)="officerResolutionEventHanlder($event)"></app-share-holder-list-view-table>
      </section>
    </div>
</ng-container>
