import {
  CompanyDirectorType,
  OfficerDocumentStatusEnum,
} from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';
import { Company } from '@modules/company/models/company.model';
import { Officer } from '@modules/officer/models/officer.model';
import { companyFactory } from '@modules/company/factories/company.factory';
import { officerFactory } from '@modules/officer/factories/officer.factory';
import { startCase } from 'lodash-es';
import { AppStatusType } from '@shared/component/common/status/status.component';

export class CompanyDirector extends BaseModel implements CompanyDirectorType {
  id: string;
  canDeleteDocument: boolean;
  canUploadDocument: boolean;
  company: Company;
  companyId: string;
  documentsStatus: OfficerDocumentStatusEnum;
  isNominee: boolean;
  officer: Officer;
  dateFrom?: Date;
  dateTo?: Date;
  dateOfAppoinment?: Date;
  dateOfCessation?: Date;
  dateOfResignation?: Date;

  __typename: 'CompanyDirectorType';

  constructor(state: CompanyDirectorType) {
    super(state);

    this.company = state.company ? companyFactory.create(state.company) : null;

    this.officer = state.officer ? officerFactory.create(state.officer) : null;
  }

  get title(): string {
    return 'Director';
  }

  get name(): string {
    return this.isNominee ? 'Nominee' : this.officer.name;
  }

  get documentStatusLabel(): string {
    return startCase(this.documentsStatus.toLowerCase());
  }

  get renderOfficerRole(): string {
    return 'Director';
  }

  get documentsStatusStyle(): AppStatusType {
    switch (this.documentsStatus) {
      case OfficerDocumentStatusEnum.AwaitingReview: {
        return 'warn';
      }
      case OfficerDocumentStatusEnum.Verified: {
        return 'success';
      }
      case OfficerDocumentStatusEnum.Rejected: {
        return 'error';
      }
      default:
        return 'default';
    }
  }

  getModelName(): string {
    return CompanyDirector.name;
  }
}
