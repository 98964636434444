<ng-container>
  <ng-container *ngIf="job.detailObject['oldRegisteredAddress']">
    <p class="mat-title !text-sub !mb-2 p-2 text-primary-900 bg-grey-50">
      Address to be changed
    </p>
    <p class="text-sub px-2">
      {{
        commonHelper.renderAddress(
          job.detailObject["oldRegisteredAddress"].address
        )
      }}
    </p>
  </ng-container>
  <p class="mat-title !text-sub !mb-2 p-2 text-primary-900 bg-grey-50">
    New Address
  </p>
  <p class="text-sub px-2">
    {{ renderNewAddress() }}
  </p>
</ng-container>
