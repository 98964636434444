import { ModelFactory } from '@shared/factories/model.factory';
import { JobType } from '@generated/graphql';
import { Job } from '@modules/job/models/job.model';

export class JobFactory extends ModelFactory<JobType, Job> {
  create (state: JobType): Job {
    return new Job(state);
  }

  fake (): Job {
    throw new Error('Method not implemented');
  }
}

export const jobFactory = new JobFactory();
