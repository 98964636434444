import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  CompanyDirectorInput,
  CompanyShareholderInput,
  CountryType,
  OfficerIdentificationTypeType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { CommonHelper } from '@shared/helpers/common.helper';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { Observable } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import { CommonModule } from '@angular/common';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { OfficerService } from '@app/modules/officer/services/officer.service';
import { Store } from '@ngrx/store';
import { countryStore } from '@app/modules/country/store/country.store';

@Component({
  selector: 'app-officer-input-review-information',
  standalone: true,
  templateUrl: './officer-input-review-information.component.html',
  imports: [CommonModule],
})
export class OfficerInputReviewInformationComponent implements OnInit {
  @Input() public officerInput: CompanyDirectorInput | CompanyShareholderInput;

  @Input() public formType: OfficerTypeEnum;

  @Input() public officerType: string;

  public identificationTypes: OfficerIdentificationTypeType[];

  public countries: CountryType[];

  get isDirectorInput() {
    return (
      'dateOfAppointment' in this.officerInput ||
      'dateOfCessation' in this.officerInput
    );
  }

  get directorInput() {
    return this.isDirectorInput
      ? (this.officerInput as CompanyDirectorInput)
      : null;
  }

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    public officerHelper: OfficerHelper,
    public officerInputHelper: OfficerInputHelpers,
    public commonHelper: CommonHelper,
    private _officerService: OfficerService,
    private _store: Store,
  ) {}

  ngOnInit(): void {
    this._store
      .select(countryStore.selectAllEntity)
      .subscribe((countries) => (this.countries = countries));

    this._officerService.getIdentificationType().subscribe((types) => {
      this.identificationTypes = types;
    });
  }
}
