import {Injectable} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import {Observable} from 'rxjs';
import {AddressType} from "@modules/address/models/address-type.model";
import {AddressTypeService} from "@modules/address/services/address-type.service";

@Injectable({
  providedIn: 'root',
})
export class AddressTypesResolver implements Resolve<AddressType[]> {
  constructor(
    private readonly addressTypeService: AddressTypeService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AddressType[]> {
    return this.addressTypeService.resolveAddressTypes$();
  }
}
