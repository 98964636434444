import { ModelFactory } from '@shared/factories/model.factory';
import { RoleEnum, RoleType } from '@generated/graphql';
import { Role } from '@modules/role/models/role.model';
import { faker } from '@faker-js/faker';

export class RoleFactory extends ModelFactory<RoleType, Role> {
  create (state: RoleType): Role {
    return new Role(state);
  }

  fake (): Role {
    return this.create({
      id: faker.datatype.uuid(),
      name: faker.helpers.arrayElement([RoleEnum.Customer, RoleEnum.Expert]),
      permissions: [],
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
    });
  }
}

export const roleFactory = new RoleFactory();
