import { ModelFactory } from "@shared/factories/model.factory";
import { OfficerIdentificationTypeType } from "@generated/graphql";
import { OfficerPersonIdentificationType } from "@modules/officer/models/officer-person-identification-type.model";

export class OfficerPersonIdentificationTypeFactory extends ModelFactory<OfficerIdentificationTypeType, OfficerPersonIdentificationType> {
  create (state: OfficerIdentificationTypeType): OfficerPersonIdentificationType {
    return new OfficerPersonIdentificationType(state);
  }

  fake (): OfficerPersonIdentificationType {
    throw new Error('Method is not implemented');
  }
}

export const officerPersonIdentificationTypeFactory = new OfficerPersonIdentificationTypeFactory()
