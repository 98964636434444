<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngIf="job.detail.gstRegistration as data">
    <ng-container [ngSwitch]="data.value">
      <p class="text-sub text-darkGray" *ngSwitchCase="GstRegistrationOptionsEnum.RegisterGst">
        The company required to register for GST
      </p>
      <p class="text-sub text-darkGray" *ngSwitchCase="GstRegistrationOptionsEnum.RegisterVoluntarily">
        The company is not required to register for GST but {{!activeUser.isCustomer()?'customer':''}} wish to register voluntarily
      </p>
    </ng-container>
  </ng-container>
</ng-container>
