import {Component, OnInit} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {OrderSummary} from '@shared/component/payments/components/order-summary/order-summary.type';
import {OrderSummaryService} from '@shared/component/payments/components/order-summary/order-summary.service';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {BusinessPlanService} from '@app/modules/account-settings/pages/business-profile/services/business-plan.service';
import {LetModule} from '@ngrx/component';
import {CurrencyService} from "@modules/payment/services/currency.service";

@Component({
  selector: 'app-order-summary',
  standalone: true,
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  imports: [CommonModule, MatDividerModule, LetModule],
})
export class OrderSummaryComponent implements OnInit {
  currencyExchangeRate: number;
  private orderSummary: OrderSummary;

  constructor(
    private readonly orderSummaryService: OrderSummaryService,
    private readonly _businessPlanService: BusinessPlanService,
    private readonly currencyService: CurrencyService
  ) {
  }

  get orderSummary$(): Observable<OrderSummary> {
    return this.orderSummaryService.orderSummary$;
  }

  public get businessPlan$() {
    return this._businessPlanService.businessPlan$;
  }

  ngOnInit(): void {
    this.orderSummary$.pipe(
      tap(orderSummary => {
          this.currencyService.getCurrencyExchangeRates(orderSummary.currency).pipe(
            tap(currencyExchangeRate => {
              this.currencyExchangeRate = currencyExchangeRate;
            })
          ).subscribe();
        }
      )
    ).subscribe();
  }
}
