<div class="dialog-wrapper">
  <h1 mat-dialog-title
      class="mb-0 text-darkGray !text-[2.2rem]">
    <span>{{ label }}</span>
  </h1>
  <section class="address-details">
    <form [formGroup]="control"
          *ngIf="control">
      <mat-form-field appearance="outline">
        <mat-label>Type of address</mat-label>
        <mat-select class="capitalize" [formControl]="control.controls.addressTypeId">
          <mat-option
            [value]="addressType.id"
            class="capitalize"
            *ngFor="let addressType of (addressTypes$ | async)">{{ addressType.name.toLocaleLowerCase() }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="grid grid-cols-2 gap-2" *ngIf="selectedAddressType$ | async as selectedAddressType">
        <ng-container [ngSwitch]="selectedAddressType.name">
          <ng-container *ngSwitchCase="AddressTypeEnum.Individual">
            <mat-form-field appearance="outline">
              <mat-label>First name</mat-label>
              <input type="text"
                     matInput
                     placeholder="First name"
                     formControlName="firstName">
              <mat-error *ngIf="control.get('firstName')?.hasError('required')">
                First name is required
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Last name</mat-label>
              <input type="text"
                     matInput
                     placeholder="Last name"
                     formControlName="lastName">
              <mat-error *ngIf="control.get('lastName')?.hasError('required')">
                Last name is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="AddressTypeEnum.Corporate">
            <mat-form-field appearance="outline" class="col-span-2">
              <mat-label>Name</mat-label>
              <input type="text"
                     matInput
                     placeholder="Name"
                     formControlName="name">
              <mat-error *ngIf="control.get('name')?.hasError('required')">
                Name is required
              </mat-error>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </div>
      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Address Line</mat-label>
        <input type="text"
               matInput
               placeholder="Address line"
               formControlName="address">
        <mat-error *ngIf="control.get('address')?.hasError('required')">
          Address is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Apartment , suite , etc.</mat-label>
        <input type="text"
               matInput
               placeholder="Apartment, suite, unit, building, floor, etc..."
               formControlName="apartment">
      </mat-form-field>

      <div class="grid grid-cols-2 gap-2">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input type="text"
                 matInput
                 formControlName="city">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>State / Province</mat-label>
          <input type="text"
                 matInput
                 formControlName="state">
        </mat-form-field>

        <mat-form-field appearance="outline"
                        class="w-full">
          <mat-label>Zipcode / Postal code</mat-label>
          <input matInput
                 formControlName="zip"
                 placeholder="569933"/>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Country/Region</mat-label>
          <app-country-select-input required
                                    formControlName="countryId"></app-country-select-input>
          <mat-error *ngIf="control.get('countryId')?.hasError('required')">
            Country is required
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline"
                      class="w-full">
        <mat-label>Phone</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['sg', 'hk', 'th', 'fr', 'us', 'uk']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          [formControlName]="'phone'"
        ></ngx-mat-intl-tel-input>
        <mat-error
          *ngIf="control.get('phone')?.hasError('required')">
          Phone is required
        </mat-error>
        <mat-error *ngIf="control.get('phone')?.hasError('phone')">Invalid number</mat-error>
      </mat-form-field>

      <section mat-dialog-actions
               align="end">
        <button mat-button
                (click)="closeDialog(null)">Cancel
        </button>
        <button
          [disabled]="control.pristine || control.invalid || (loading$ | async)"
          (click)="onSubmit()"
          mat-raised-button
          color="primary">Update
        </button>
      </section>
    </form>
  </section>
</div>
