import {Injectable} from "@angular/core";
import {
  CreatePaymentProviderAccountLinkMutation,
  CreatePaymentProviderAccountLoginLinkMutation,
  CreatePaymentProviderAccountMutation,
  GetPaymentProviderAccountQuery,
  PaymentProviderNameEnum
} from "@generated/graphql";
import {first, map} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderAccountService {
  constructor(
    private readonly createPaymentProviderAccountMutation: CreatePaymentProviderAccountMutation,
    private readonly getPaymentProviderAccountQuery: GetPaymentProviderAccountQuery,
    private readonly createPaymentProviderAccountLinkMutation: CreatePaymentProviderAccountLinkMutation,
    private readonly createPaymentProviderAccountLoginLinkMutation: CreatePaymentProviderAccountLoginLinkMutation,
  ) {

  }

  getPaymentProviderAccount(provider: PaymentProviderNameEnum) {
    return this.getPaymentProviderAccountQuery.fetch({provider}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.getPaymentProviderAccount),
    );
  }

  createPaymentProviderAccount(provider: PaymentProviderNameEnum) {
    return this.createPaymentProviderAccountMutation.mutate({provider}).pipe(
      map(res => res.data.createPaymentProviderAccount),
      first(),
    );
  }

  createPaymentProviderAccountLink(provider: PaymentProviderNameEnum) {
    return this.createPaymentProviderAccountLinkMutation.mutate({provider}).pipe(
      map(res => res.data.createPaymentProviderAccountLink),
      first(),
    );
  }

  createLoginLink(provider: PaymentProviderNameEnum) {
    return this.createPaymentProviderAccountLoginLinkMutation.mutate({provider}).pipe(
      map(res => res.data.createPaymentProviderAccountLoginLink),
      first(),
    )
  }
}
