import { BaseModel } from "@shared/models/base.model";
import { OrderDetailType } from "@generated/graphql";
import { OrderBillingAddress } from "@modules/order/models/order-billing-address.model";
import { orderBillingAddressFactory } from "@modules/order/factories/order-billing-address.factory";
import { StripeBillingDetails } from "@modules/payment/models/stripe-billing-details.model";
import { stripeBillingDetailsFactory } from "@modules/payment/factories/stripe-billing-details.factory";

export class OrderDetail extends BaseModel implements OrderDetailType {
  address?: OrderBillingAddress
  billingDetails?: StripeBillingDetails

  constructor (state: OrderDetailType) {
    super(state);

    this.address = state.address ? orderBillingAddressFactory.create(state.address) : null

    this.billingDetails = state.billingDetails ? stripeBillingDetailsFactory.create(state.billingDetails) : null
  }

  getModelName (): string {
    return OrderDetail.name;
  }
}
