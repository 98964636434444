import { BaseModel } from "@shared/models/base.model";
import { BusinessPlanNameEnum, BusinessPlanType } from "@generated/graphql";
import { BusinessPlanReminder } from "@modules/account-settings/pages/business-profile/models/business-plan-reminder.model";
import {
  businessPlanReminderFactory
} from "@modules/account-settings/pages/business-profile/factories/business-plan-reminder.factory";

export class BusinessPlan extends BaseModel implements BusinessPlanType {
  id: string;
  currencyCode: string;
  name: BusinessPlanNameEnum;
  price: number;
  isTrial?: boolean;
  packageId?: number;
  reminderCount?: number;
  reminders?: BusinessPlanReminder[];
  nextPaymentDate?: Date;
  startTrialDate?: Date;
  deletedAt?: Date;
  expiredDate?: Date;


  constructor (state: BusinessPlanType) {
    super(state);

    this.reminders = state.reminders ? state.reminders.map(reminder => businessPlanReminderFactory.create(reminder)) : []
  }

  getModelName (): string {
    return BusinessPlan.name;
  }

  isPremium (): boolean {
    return this.name ? this.name === BusinessPlanNameEnum.Premium : false
  }
}
