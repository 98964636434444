import { Inject, Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { RoleEnum } from '@generated/graphql';
import { User } from '@modules/user/models/user.model';
import { CommonActivatedRouteSnapshot } from '@shared/types/route.type';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor (
    private readonly authService: AuthService,
    private readonly router: Router,
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
  ) {
  }

  canActivate (
    route: CommonActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(route);
  }

  private check (route: CommonActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService
      .activeUser$
      .pipe(map(activeUser => {
        const roles = route.data.roles;

        return roles && roles.length ? this.hasRole(activeUser, roles) : (activeUser ? true : this.router.createUrlTree(this.appRoutes.login()));
      }));
  }


  private hasRole (activeUser: User, routeRequiredRoles: RoleEnum[]): UrlTree | boolean {
    if (activeUser && activeUser.roles) {
      return routeRequiredRoles.some(routeRequiredRole => activeUser.roles.some(userRole => userRole.name === routeRequiredRole)) || this.router.createUrlTree(this.appRoutes.login());
    }

    return this.router.createUrlTree(this.appRoutes.login());
  }
}
