import {Injectable} from '@angular/core';
import {Job, OrderDetail} from '@app/modules/job/models/job.model';
import {SupportedCountry} from '@app/modules/supported-country/models/supported-country.model';
import {Order} from "@modules/order/models/order.model";

/**
 * this one is only use to pay for job.
 */
@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  private static instance: OrderHelper;

  private constructor() {
    //
  }

  private _order: Order;

  get order(): Order {
    return this._order;
  }

  get services(): OrderDetail[] {
    if (!this.mainServices || this.mainServices.length === 0) {
      return [];
    }

    return this.mainServices.concat(this.additionalServices);
  }

  private _mainServices: OrderDetail[];

  get mainServices(): OrderDetail[] {
    return this._mainServices;
  }

  private _additionalServices: OrderDetail[];

  get additionalServices(): OrderDetail[] {
    return this._additionalServices;
  }

  private _countryOfIncorporation: SupportedCountry;

  get countryOfIncorporation(): SupportedCountry {
    return this._countryOfIncorporation;
  }

  public static getInstance(): OrderHelper {
    if (!OrderHelper.instance) {
      OrderHelper.instance = new OrderHelper();
    }

    return OrderHelper.instance;
  }

  getMainCurrency(): string {
    return this.countryOfIncorporation.currency;
  }

  setMainServices(services: OrderDetail[]): OrderHelper {
    this._mainServices = services;

    return this;
  }

  setAdditionalServices(services: OrderDetail[]): OrderHelper {
    this._additionalServices = services;

    return this;
  }

  setCountryOfIncorporation(country: SupportedCountry): OrderHelper {
    this._countryOfIncorporation = country;

    return this;
  }

  calculateSubtotal(): number {
    let subTotal = 0;

    this.services.forEach((item) => {
      if (!item.service.paymentType.isContactToClient) {
        subTotal += item.quantity * item.service.serviceFee;
      }
    });

    return subTotal;
  }

  calculateOfficialFee(): number {
    let officialFee = 0;

    this.services.forEach((item) => {
      if (!item.service.paymentType.isContactToClient) {
        officialFee += item.quantity * item.service.officialFee;
      }
    });

    return officialFee;
  }

  calculateTax(): number {
    if (this._countryOfIncorporation.includeTax) {
      return (
        this.calculateSubtotal() * (this._countryOfIncorporation.taxRate / 100)
      );
    }

    return 0;
  }

  calculateTotalFee(): number {
    return (
      this.calculateSubtotal() +
      this.calculateTax() +
      this.calculateOfficialFee()
    );
  }

  setupByJob(job: Job): void {
    this.setCountryOfIncorporation(job.supportedCountry)
      .setMainServices(job.detailObject.jobServiceOrder?.mainServices || [])
      .setAdditionalServices(job.detailObject.jobServiceOrder?.additionalServices || [])
      .setOrder(job.order);
  }

  setOrder(order: Order): OrderHelper {
    this._order = order;

    return this;
  }
}
