import {BaseModel} from '@shared/models/base.model';
import {PaymentMethodType} from '@generated/graphql';
import {User} from "@modules/user/models/user.model";
import {PaymentProvider} from "@modules/payment/models/payment-provider.model";
import {userFactory} from "@modules/user/factories/user.factory";
import {paymentProviderFactory} from "@modules/payment/factories/payment-provider.factory";

export class PaymentMethod extends BaseModel implements PaymentMethodType {
  id: string;
  user: User;
  paymentProvider: PaymentProvider;
  providerPaymentMethodId: string;
  cardBrand?: string;
  cardLastFour?: string;
  cardExpiryMonth?: string;
  cardExpiryYear?: string;
  fingerprint?: string;
  isDefault: boolean;

  constructor(state: PaymentMethodType) {
    super(state);

    this.user = state.user ? userFactory.create(state.user) : null;
    this.paymentProvider = state.paymentProvider ? paymentProviderFactory.create(state.paymentProvider) : null
  }

  getModelName(): string {
    return PaymentMethod.name;
  }
}
