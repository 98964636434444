import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfficerService } from '@app/modules/officer/services/officer.service';
import {
  OfficerCompanyType,
  OfficerFiltersInput,
  OfficerPersonType,
  OfficerType,
  OfficerTypeEnum,
  SupportedCountryType,
} from '@generated/graphql';
import { isNil, omitBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import {
  LookupCorporateFormType,
  LookupIndividualFormType,
} from './lookup-form-type';

export class LookupOfficerDialogManagement {
  private _fb = new FormBuilder();

  private _type: OfficerTypeEnum;
  get type(): OfficerTypeEnum {
    return this._type;
  }
  set type(value: OfficerTypeEnum) {
    this._type = value;
  }

  private _form:
    | FormGroup<LookupIndividualFormType>
    | FormGroup<LookupCorporateFormType>;
  get form() {
    return this._form;
  }

  get individualForm() {
    return this.form as FormGroup<LookupIndividualFormType>;
  }

  get corporateForm() {
    return this.form as FormGroup<LookupCorporateFormType>;
  }

  officerService: OfficerService;

  officers: OfficerType[] = [];

  get officerPeople() {
    if (
      this.officers.length === 0 ||
      !('firstName' in this.officers[0].officerProfile)
    )
      return [];

    return this.officers.map((o) => o.officerProfile as OfficerPersonType);
  }

  get officerCompanies() {
    if (
      this.officers.length === 0 ||
      !('registrationNumber' in this.officers[0].officerProfile)
    )
      return [];

    return this.officers.map((o) => o.officerProfile as OfficerCompanyType);
  }

  fetching$ = new BehaviorSubject(false);

  countryOfIncorporation: SupportedCountryType;

  public exceptOfficerIds: string[] = [];

  constructor() {
    this._type = OfficerTypeEnum.Individual;

    this.createForm();
  }

  changeType(newType: OfficerTypeEnum) {
    this._type = newType;

    this.createForm();
  }

  createForm() {
    if (this.isCorporate()) {
      this.createCorporateForm();
    } else {
      this.createIndividualForm();
    }
  }

  createIndividualForm() {
    this._form = this._fb.group<LookupIndividualFormType>({
      firstName: this._fb.control(null, [Validators.required]),
      lastName: this._fb.control(null, [Validators.required]),
      identificationTypeId: this._fb.control(null, [Validators.required]),
      identificationNumber: this._fb.control(null, [Validators.required]),
    });
  }

  createCorporateForm() {
    this._form = this._fb.group<LookupCorporateFormType>({
      name: this._fb.control(null, [Validators.required]),
      registrationNumber: this._fb.control(null, [Validators.required]),
    });
  }

  isIndividual(): boolean {
    return this._type === OfficerTypeEnum.Individual;
  }

  isCorporate(): boolean {
    return this._type === OfficerTypeEnum.Corporate;
  }

  startLookup() {
    this.officers = [];
    this.fetching$.next(true);

    let filters = this._form.value as unknown as OfficerFiltersInput;
    filters.type = this.type;

    if (this.exceptOfficerIds.length > 0) {
      filters.exceptOfficerIds = this.exceptOfficerIds;
    }

    filters = omitBy(filters, isNil);

    this.officerService.lookupOfficersForExpert(filters).subscribe({
      next: (result) => {
        this.officers = result;
        this.fetching$.next(false);
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.fetching$.next(false);
      },
    });
  }

  retreiveOfficer(index: number) {
    return this.officers.find((o, idx) => idx === index);
  }

  // INFO: keep this function for later if needed

  // filterResult(data: OfficerType[]): OfficerType[] {
  //   let result: OfficerType[] = [];
  //
  //   if (this.countryOfIncorporation.country.code.toUpperCase() === 'SG') {
  //     result = data.filter((t) => {
  //       if ('firstName' in t.officerProfile) {
  //         return (
  //           t.officerProfile.identification.officerIdentificationType.name.toUpperCase() !==
  //           'ID'
  //         );
  //       } else {
  //         return (
  //           (
  //             t.officerProfile.representative
  //               .officerProfile as OfficerPersonType
  //           ).identification.officerIdentificationType.name.toUpperCase() !==
  //           'ID'
  //         );
  //       }
  //     });
  //   } else {
  //     result = data.filter((t) => {
  //       if ('firstName' in t.officerProfile) {
  //         return OTHER_IDENTIFICATION_TYPE_FILTER.includes(
  //           t.officerProfile.identification.officerIdentificationType.name.toUpperCase(),
  //         );
  //       } else {
  //         return OTHER_IDENTIFICATION_TYPE_FILTER.includes(
  //           (
  //             t.officerProfile.representative
  //               .officerProfile as OfficerPersonType
  //           ).identification.officerIdentificationType.name.toUpperCase(),
  //         );
  //       }
  //     });
  //   }
  //
  //   return result;
  // }
}
