import {Component, Input, OnInit, ViewChild}       from '@angular/core';
import {MatTable}                                  from '@angular/material/table';
import {ShareTypeService}                          from '@modules/share-type/services/share-type.service';
import {CountryType, ShareTypeEnum, ShareTypeType} from '@generated/graphql';
import {Store}                                     from '@ngrx/store';
import {countryStore}                              from '@modules/country/store/country.store';
import {MatSelectChange}                           from '@angular/material/select';
import {CompanyIncorporationManagement}            from '@app/modules/job/classes/company-incorporation-management.class';
import {selectOperationCountry}                    from '@app/modules/job/store/create-job.selectors';
import {UntilDestroy, untilDestroyed}              from '@ngneat/until-destroy';
import {tap}                                       from 'rxjs';
import {CommonHelper}                              from "@shared/helpers/common.helper";

export interface CompanyCapitalInfo {
    id: string;
    capitalAmount: number;
    numberOfShares: number;
    currency: string;
    shareType: string;
}

export interface ShareTypeInfo {
    id: string;
    value: ShareTypeEnum;
    name: string;
}

export declare type CapitalAppearance = 'register' | 'viewOnly' | string;

@UntilDestroy()
@Component({
               selector: 'app-registered-capital',
               templateUrl: './registered-capital.component.html',
               styleUrls: ['./registered-capital.component.scss'],
           })
export class RegisteredCapitalComponent implements OnInit {
    private _componentAppearance: CapitalAppearance = 'viewOnly';

    @Input()
    get appearance(): CapitalAppearance {
        return this._componentAppearance;
    }

    set appearance(value: CapitalAppearance) {
        this._componentAppearance = value;
    }

    @ViewChild(MatTable) table: MatTable<CompanyCapitalInfo>;

    get companyStructureForm() {
        return this.companyIncorporationManagement.companyStructureForm.form;
    }

    get companyCapitalForm() {
        return this.companyIncorporationManagement.companyStructureForm
            .companyCapitalForm;
    }

    get companyCapitalControls() {
        return this.companyIncorporationManagement.companyStructureForm.form
            .controls.companyCapitals;
    }

    shareTypes: ShareTypeType[];

    countries: CountryType[];

    currencies: string[];

    constructor(
        private shareTypeService: ShareTypeService,
        private store: Store,
        public companyIncorporationManagement: CompanyIncorporationManagement,
        protected readonly commonHelper: CommonHelper
    ) {
    }

    ngOnInit(): void {
        if (this.appearance === 'register') {
            this.companyCapitalForm.shareTypeDisabled.createItem(0);
        }

        this.shareTypeService.getAll().subscribe((shareTypes) => {
            this.shareTypes = shareTypes;
            this.store
                .select(countryStore.selectReserveCurrencies)
                .subscribe((currencies) => {
                    this.currencies = currencies;

                    this.companyCapitalForm.init();
                });
        });

        this.store
            .select(selectOperationCountry)
            .pipe(
                untilDestroyed(this),
                tap((country) => {
                    this.companyCapitalForm.init();
                }),
            )
            .subscribe();
    }

    addNewCapital() {
        this.companyCapitalForm.addNewCapital();
    }

    deleteCapital(i: number) {
        this.companyCapitalForm.deleteCapital(i);
    }

    onCurrencyChanged(source: MatSelectChange, index: number) {
        this.companyCapitalForm.shareTypeDisabled.currencyChanged(
            source.value,
            index,
        );
        const shareTypeControl = this.companyCapitalControls
                                     .at(index)
                                     .get('shareTypeId');
        shareTypeControl.enable();
        shareTypeControl.setValue(null);
    }

    onShareTypeChanged(source: MatSelectChange, index: number) {
        this.companyCapitalForm.shareTypeDisabled.shareTypeIdChanged(
            source.value,
            index,
        );
    }

    isShareTypeDisabled(value: string, index: number): boolean {
        return this.companyCapitalForm.shareTypeDisabled.isDisabled(value, index);
    }

    getShareTypeName(id: string): string {
        const shareType = this.shareTypes.find((t) => t.id === id);

        if (!shareType) {
            return '';
        }

        return shareType.name;
    }
}
