import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShareDirectivesModule } from '@shared/share-directives/share-directives.module';
import { MatButtonModule } from '@angular/material/button';
import { DynamicComponentDirective } from '@shared/share-directives/directives/dynamic-component.directive';
import { CompanyCapitalInput, TransfereeInput } from '@generated/graphql';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TransferSharesInfoComponent } from '@app/modules/job/components/job-detail/main-request/components/transfer-shares-info/transfer-shares-info.component';
import { SharesAllocationComponent } from '@shared/component/company/common/shares-allocation/shares-allocation.component';
import { ShareTransferDetailByCapitalManagement } from '@shared/classes/shareholder/share-transfer-detail-by-capital-input-management.class';
import { ShareTypeService } from '@app/modules/share-type/services/share-type.service';

interface DialogData {
  index: number;
  shareholderInputs: TransfereeInput[];
  capitalInputs: CompanyCapitalInput[];
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-edit-transfer-shares-for-increase-capital-dialog',
  templateUrl:
    './edit-transfer-shares-for-increase-capital-dialog.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    ShareDirectivesModule,
    DynamicComponentDirective,
    TransferSharesInfoComponent,
    SharesAllocationComponent,
  ],
})
export class EditTransferSharesForIncreaseCapitalDialogComponent
  implements OnInit, OnDestroy {
  submitting$ = new BehaviorSubject(false);

  public shareTransferDetailManagement =
    new ShareTransferDetailByCapitalManagement();

  constructor(
    public dialogRef: MatDialogRef<EditTransferSharesForIncreaseCapitalDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: DialogData,
    private _shareTypeService: ShareTypeService,
  ) { }

  ngOnInit(): void {
    this._shareTypeService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((shareTypes) => {
        const newShareTypeIds = this.data.capitalInputs.map(
          (c) => c.shareTypeId,
        );

        const filteredShareTypes = shareTypes.filter((s) =>
          newShareTypeIds.includes(s.id),
        );

        this.shareTransferDetailManagement.userType = 'expert';

        this.shareTransferDetailManagement.exceptIndex = this.data.index;

        this.shareTransferDetailManagement.createForm();

        this.shareTransferDetailManagement.shareTypes = filteredShareTypes;

        this.shareTransferDetailManagement.shareTypes$.next(filteredShareTypes);

        this.shareTransferDetailManagement.capitalsInput =
          this.data.capitalInputs;

        this.shareTransferDetailManagement.shareholderInputs =
          this.data.shareholderInputs;

        this.shareTransferDetailManagement.setDataFromInput(
          this.data.shareholderInputs[this.data.index].sharesTransferDetails,
        );
      });
  }

  onSubmit(): void {
    this.dialogRef.close(
      this.shareTransferDetailManagement.form.controls.details.getRawValue(),
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.submitting$.complete();
  }
}
