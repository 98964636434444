import { ModelFactory } from '@shared/factories/model.factory';
import { PaymentTypeEnum, PaymentTypeType } from '@generated/graphql';
import { faker } from '@faker-js/faker';
import { PaymentType } from "@modules/payment/models/payment-type.model";

export class PaymentTypeFactory extends ModelFactory<PaymentTypeType, PaymentType> {
  create (state: PaymentTypeType): PaymentType {
    return new PaymentType(state);
  }

  fake (): PaymentType {
    return this.create({
      id: faker.datatype.uuid(),
      name: faker.helpers.arrayElement([
        PaymentTypeEnum.PerApplication,
        PaymentTypeEnum.ContactToClient,
        PaymentTypeEnum.SubscriptionQuarterly
      ]),
      createdAt: new Date(faker.date.past()),
      updatedAt: new Date(faker.date.future()),
    });
  }
}

export const paymentTypeFactory = new PaymentTypeFactory();
