import { Injectable } from '@angular/core';
import {
  ServiceCategoriesQuery, ServiceCategoryType,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';
import { ServiceCategory } from "@modules/service/models/service-category.model";
import { serviceCategoryFactory } from "@modules/service/factories/service-category.factory";

@Injectable({
  providedIn: 'root',
})
export class ServiceCategoryApi {
  constructor (
    protected readonly serviceCategoriesQuery: ServiceCategoriesQuery
  ) {
  }

  get (): Observable<ServiceCategory[]> {
    return this.serviceCategoriesQuery.watch(undefined, {fetchPolicy: 'no-cache'}).valueChanges.pipe(
      map(res => res.data.serviceCategories
        ? res.data.serviceCategories.map(service => serviceCategoryFactory.create(service as ServiceCategoryType))
        : []
      ),
    );
  }
}
