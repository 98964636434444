<ng-container *ngrxLet="activeUser$ as activeUser">
  <section class="top-menu-wrapper">
    <div class="menu-container w-full justify-end">
      <section class="master-search-container">
        <!--          TODO Implement master search later-->
      </section>
      <section class="group-actions">
        <div class="action-item">
          <!--          TODO BS-491-->
          <app-notifications></app-notifications>
        </div>
        <div class="action-item profile-actions relative mx-2">
          <button mat-icon-button [matMenuTriggerFor]="profileActions">
            <app-avatar
              [width]="32"
              [height]="32"
              [src]="activeUser.profile.avatarUrl || null"
            ></app-avatar>
          </button>
          <div
            class="displayed-role absolute left-1/2 px-2 -bottom-1 whitespace-nowrap capitalize"
            *ngIf="activeUser.roles.length && !this.activeUser.isCustomer()"
          >
            {{ activeUser.roles[0].getRoleTypeDisplay() ?? "-" }}
          </div>
          <mat-menu #profileActions="matMenu" xPosition="before">
            <div
              class="menu-user-info !min-w-[20rem] !max-w-[24rem] !cursor-default"
            >
              <div class="px-4 pb-2">
                <p class="!text-sub font-light !italic !mb-1">
                  {{
                    (impersonatingUser$ | async)
                      ? "Impersonated as"
                      : "Signed in as"
                  }}
                </p>
                <p
                  class="mat-body-strong font-bold text-ellipsis overflow-hidden !m-0"
                >
                  {{ activeUser.profile.fullName }}
                </p>
                <span class="mat-caption text-ellipsis overflow-hidden">{{
                  activeUser.email
                }}</span>
              </div>
              <mat-divider></mat-divider>
            </div>
            <a mat-menu-item [routerLink]="appRoutes.profileSettings()">
              <mat-icon>manage_accounts</mat-icon>
              <span>Account</span>
            </a>
            <a
              *ngIf="activeUser.isSuperAdmin() || activeUser.isAdmin()"
              mat-menu-item
              [routerLink]="appRoutes.businessSettings()"
            >
              <mat-icon>corporate_fare</mat-icon>
              <span>Business Settings</span>
            </a>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>Log Out</span>
            </button>
          </mat-menu>
        </div>
        <div
          class="menu-item-wrapper stop-impersonate"
          *ngIf="impersonatingUser$ | async"
        >
          <button mat-flat-button color="accent" (click)="stopImpersonate()">
            stop impersonate
          </button>
        </div>
      </section>
    </div>
  </section>
</ng-container>
