import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from "luxon";

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform (value: string): string {
    try {
      return Duration.fromISO(value).toHuman()
    } catch (e) {
      return 'Invalid duration'
    }
  }
}
