import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, combineLatestWith, defer, from, map, of, switchMap } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import {
  defaultAuthState,
} from '@modules/auth/store/reducers/auth.reducer';
import { authActions } from '@modules/auth/store/actions/auth.actions';
import { cloneDeep } from 'lodash-es';
import { AuthApi } from '@modules/auth/api/auth.api';

@Injectable()
export class AuthInitializerEffects {
  $initAuthUserFromDB = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => defer(() => this.authService.getAuthFromLocalStorage())),
    map(tempAuth => {
      const auth = tempAuth || cloneDeep(defaultAuthState);

      return authActions.setAuth({ auth });
    }),
    catchError(e => {
      console.error(e);

      return of(authActions.setAuth({ auth: cloneDeep(defaultAuthState) }));
    }),
  ));

  constructor (
    private authService: AuthService,
    private authApi: AuthApi,
    private actions$: Actions,
  ) {
  }
}
