import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentProviderAccountService} from "@modules/payment/services/payment-provider-account.service";
import {filter, forkJoin, iif, Observable, switchMap, tap} from "rxjs";
import {PaymentProviderAccountStatusEnum, PaymentProviderNameEnum} from "@generated/graphql";
import {loadStripe, SetupIntent, Stripe, StripeElements} from "@stripe/stripe-js";
import {environment} from "@environment/environment";
import {StripeService} from "@modules/payment/services/stripe.service";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {User} from "@modules/user/models/user.model";
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PaymentService} from "@shared/component/payments/services/payment.service";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PaymentProviderAccount} from "@modules/payment/models/payment-provider-account.model";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {SnackBarService} from "@shared/services/snack-bar.service";

@Component({
  selector: 'app-stripe-setup-form',
  standalone: true,
  imports: [CommonModule, CalloutComponent, FormsModule, ReactiveFormsModule, MatButtonModule, MatCheckboxModule, MatProgressSpinnerModule, MatIconModule],
  templateUrl: './stripe-setup-form.component.html',
  styleUrls: ['./stripe-setup-form.component.scss']
})
export class StripeSetupFormComponent implements OnInit {
  canStartAcceptingPayment$: Observable<boolean>;
  loading = true;
  @Output() setupSuccess?: EventEmitter<SetupIntent> = new EventEmitter<SetupIntent>();
  private stripe: Stripe;
  private elements: StripeElements;
  private user: User;

  constructor(
    @Inject(ACTIVE_USER) private readonly activeUser$: Observable<User>,
    private readonly paymentProviderAccountService: PaymentProviderAccountService,
    private readonly stripeService: StripeService,
    private readonly paymentService: PaymentService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.activeUser$.subscribe(user => this.user = user);
    this.canStartAcceptingPayment$ = this.checkCanStartAcceptingPayment();
    this.canStartAcceptingPayment$.pipe(
      tap(_ => this.setLoading(true)),
      filter(v => Boolean(v)),
      switchMap(_ => this.initialize()),
      tap(_ => this.setLoading(false)),
    ).subscribe();
  }

  async submit() {
    const res = await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (res.error) {
      this.snackBarService.pushErrorAlert(res.error.message);
    } else {
      this.setupSuccess.emit(res.setupIntent);
    }
  }

  private checkCanStartAcceptingPayment() {
    return this.paymentService.checkCanStartAcceptingPayment(PaymentProviderNameEnum.Stripe);
  }

  private initialize() {
    return forkJoin([
      this.setupStripeClient(),
      this.getSetupIntentClientSecret(),
    ]).pipe(
      tap(([stripe, clientSecret]) => {
        this.stripe = stripe;
        this.elements = stripe.elements({clientSecret, loader: 'auto'});
        const linkAuthenticationElement = this.elements.create("linkAuthentication", {defaultValues: {email: this.user.email}});
        const paymentElement = this.elements.create('payment', {
          defaultValues: {
            billingDetails: {
              address: {
                country: this.user.getDefaultBillingAddress()?.country?.code,
              }
            },
          },
        });

        linkAuthenticationElement.mount("#link-authentication-element");
        paymentElement.mount("#payment-element");
      }));
  }

  private setupStripeClient(): Observable<Stripe> {
    return iif(() => !this.user.isSuperAdmin(),
      this.paymentProviderAccountService.getPaymentProviderAccount(PaymentProviderNameEnum.Stripe).pipe(
        filter(stripeAccount => stripeAccount?.status === PaymentProviderAccountStatusEnum.Active),
        switchMap<PaymentProviderAccount, Promise<Stripe>>(stripeAccount => loadStripe(environment.stripe.publishableKey, {stripeAccount: stripeAccount.providerAccountId})),
      ),
      loadStripe(environment.stripe.publishableKey)
    );
  }

  private getSetupIntentClientSecret(): Observable<string> {
    return this.stripeService.getSetupIntentClientSecret().pipe();
  }

  private setLoading(flag: boolean) {
    this.loading = flag;
    this.cdr.markForCheck();
  }
}
