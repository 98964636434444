import {BaseModel} from '@shared/models/base.model';
import {AddressType, PaymentTypeShortEnum, ServiceEnum, ServiceSupportedCountryType} from '@generated/graphql';
import {Service} from '@modules/service/models/service.model';
import {SupportedCountry} from '@app/modules/supported-country/models/supported-country.model';
import {PaymentType} from '@app/modules/payment/models/payment-type.model';
import {serviceFactory} from '@modules/service/factories/service.factory';
import {paymentTypeFactory} from '@modules/payment/factories/payment-type.factory';
import {supportedCountryFactory} from '@modules/supported-country/factories/supported-country.factory';
import {File} from '@modules/file/models/file.model';
import {fileFactory} from '@modules/file/factories/file.factory';
import {Duration} from "luxon";

export class ServiceSupportedCountry extends BaseModel implements ServiceSupportedCountryType {
  static readonly defaultEstimatedTime: string = 'P1D'
  static readonly maxEstimatedTime: number = Number.MAX_SAFE_INTEGER
  static readonly defaultPaymentTypeShort: PaymentTypeShortEnum = PaymentTypeShortEnum.ContactToClient
  static readonly defaultServiceFeeTaxIncludable: boolean = true
  static readonly defaultOfficialFeeTaxIncludable: boolean = false
  static readonly minFee: number = 0
  static readonly maxFee: number = Math.pow(10, 8) - 1

  id: string;
  service: Service;
  supportedCountry: SupportedCountry;
  supportedCountryId: string;
  paymentType: PaymentType;
  serviceFee: number;
  officialFee: number;
  serviceFeeTaxIncludable: boolean;
  officialFeeTaxIncludable: boolean;
  description?: string;
  scopeOfWork?: string;
  estimatedTime: string;
  address?: AddressType
  isActive: boolean;
  isArchive: boolean;
  label: string
  icon?: File;

  _model: string;

  constructor(state: ServiceSupportedCountryType) {
    super(state);

    this.service = state.service ? serviceFactory.create(state.service) : null;

    this.supportedCountry = state.supportedCountry ? supportedCountryFactory.create(state.supportedCountry) : null;

    this.paymentType = state.paymentType ? paymentTypeFactory.create(state.paymentType) : null;

    this.icon = state.icon ? fileFactory.create(state.icon) : null;
  }

  get displayIcon() {
    if (this.icon) {
      return this.icon.url
    }

    return this.tempIcon
  }

  get tempIcon() {
    const prefix = 'assets/images/icons/'

    const icon = () => {
      switch (this.service.name) {
        case ServiceEnum.CompanyRegistration: {
          return 'company-registration.svg'
        }
        case ServiceEnum.TransferCompany: {
          return 'transfer-company.svg'
        }
        case ServiceEnum.CorporateSecretary: {
          return 'secretary.svg'
        }
        case ServiceEnum.RegisteredAddress: {
          return 'registered-address.svg'
        }
        case ServiceEnum.NomineeDirector: {
          return 'nominee.svg'
        }
        case ServiceEnum.Payroll: {
          return 'payroll.svg'
        }
        case ServiceEnum.Accounting: {
          return 'accounting.svg'
        }
        case ServiceEnum.BankAccountOpening: {
          return 'open-bank-account.svg'
        }
        case ServiceEnum.EmploymentPass: {
          return 'employment-pass.svg'
        }
        case ServiceEnum.DependantPassApplication: {
          return 'dependant-pass.svg'
        }
        case ServiceEnum.ChangeRegisteredAddress: {
          return 'change-company-address.svg'
        }
        case ServiceEnum.ChangeFyeDate: {
          return 'change-fye-date.svg'
        }
        case ServiceEnum.Agm: {
          return 'AGM.svg'
        }
        case ServiceEnum.AddDirector: {
          return 'add-officer.svg'
        }
        case ServiceEnum.EditDirector: {
          return 'edit-officer-info.svg'
        }
        case ServiceEnum.ReplaceDirector: {
          return 'replace-officer.svg'
        }
        case ServiceEnum.RemoveDirector: {
          return 'remove-officer.svg'
        }
        case ServiceEnum.IncreaseCapital: {
          return 'increase-capital.svg'
        }
        case ServiceEnum.IndividualTransferShare: {
          return 'transfer-shares.svg'
        }
        case ServiceEnum.CorporateTransferShare: {
          return 'transfer-shares.svg'
        }
        case ServiceEnum.IssuesCorporateShares: {
          return 'issue-shares.svg'
        }
        case ServiceEnum.IssuesIndividualShares: {
          return 'issue-shares.svg'
        }
        case ServiceEnum.EditShareholder: {
          return 'edit-officer-info.svg'
        }
        case ServiceEnum.GstRegistration: {
          return 'gst-registration.svg'
        }
        case ServiceEnum.GstReturn: {
          return 'gst-return.svg'
        }
        case ServiceEnum.TaxReturn: {
          return 'tax-return.svg'
        }
        case ServiceEnum.PrepareManagementAccount: {
          return 'management-account.svg'
        }
        case ServiceEnum.PrepareFinancialStatement: {
          return 'financial-statement.svg'
        }
        case ServiceEnum.PrepareAuditedFinancialStatements: {
          return 'financial-statement.svg'
        }
        case ServiceEnum.PrepareXblrReport: {
          return 'report.svg';
        }
        case ServiceEnum.DormantApplication: {
          return 'dormant-application.svg'
        }
        default: {
          return 'becorps-logo-only.svg'
        }
      }
    }

    return prefix + icon()
  }

  get displayLabel(): string {
    switch (this.service.name) {
      case ServiceEnum.IndividualTransferShare: {
        return 'Individual Transfer Shares'
      }
      case ServiceEnum.CorporateTransferShare: {
        return 'Corporate Transfer Shares'
      }
      case ServiceEnum.GstRegistration: {
        return 'GST Registration'
      }
      case ServiceEnum.GstReturn: {
        return 'GST Return'
      }
      case ServiceEnum.ChangeFyeDate: {
        return 'Change FYE Date'
      }
      case ServiceEnum.Agm: {
        return 'AGM'
      }
      case ServiceEnum.PrepareXblrReport: {
        return 'Prepare XBLR Report'
      }
      default: {
        return this.label
      }
    }
  }

  getModelName(): string {
    return ServiceSupportedCountry.name;
  }

  isBelongsToCustomService(): boolean {
    return this.service?.isCustomService()
  }

  getEstimatedTime(): number {
    let estimatedTime: string;

    try {
      estimatedTime = Duration.fromISO(this.estimatedTime).toHuman() ? this.estimatedTime : ServiceSupportedCountry.defaultEstimatedTime;
    } catch (e) {
      estimatedTime = ServiceSupportedCountry.defaultEstimatedTime;
    }

    return Duration.fromISO(estimatedTime).days
  }
}
