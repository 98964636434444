import { ServiceEnum, ServiceType, WireTransferBankType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";

export class WireTransferBankStore extends EntityStore<EntityFeatureNameEnum.WireTransferBank, WireTransferBankType, WireTransferBank> {
  get selectState (): SelectState<WireTransferBankType> {
    return this.feature.selectWireTransferBankFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.WireTransferBank> {
    return 'WIRE_TRANSFER_BANK';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.WireTransferBank> {
    return 'wireTransferBankFeature';
  }
}

export const wireTransferBankStore = new WireTransferBankStore()

export const WIRE_TRANSFER_BANK_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.WireTransferBankStore)

export const WIRE_TRANSFER_BANK_STORE_CONFIG: Provider = {
  provide: WIRE_TRANSFER_BANK_STORE,
  useValue: wireTransferBankStore,
}
