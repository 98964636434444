import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule
  ],
  selector: 'app-empty-billing-address',
  templateUrl: './empty-billing-address.component.html',
  styleUrls: ['./empty-billing-address.component.scss']
})
export class EmptyBillingAddressComponent implements OnInit {
  @Output() add = new EventEmitter<void>()

  constructor () {
  }

  ngOnInit (): void {
  }

  onAdd () {
    this.add.emit()
  }
}
