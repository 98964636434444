import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";

export declare type AppStatusType =
  'default'
  | 'primary'
  | 'new'
  | 'new-light'
  | 'warn'
  | 'success'
  | 'error'
  | 'cancelled'
  | 'hold'
  | 'action'
  | 'action-light'
  | string
export declare type AppStatusFontWeight = 'regular' | 'medium' | 'semibold' | 'bold' | string

@Component({
  selector: 'app-status',
  standalone: true,
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  imports: [CommonModule]
})

export class StatusComponent implements OnInit {
  private _style: AppStatusType = 'default';
  private _fontWeight: AppStatusFontWeight = 'medium';

  private _status: string = '';

  @Input()
  get statusMessage(): string {
    return this._status;
  }

  set statusMessage(statusMessage: string) {
    this._status = statusMessage;
  }

  @Input()
  get statusStyle(): AppStatusType {
    return this._style;
  }

  set statusStyle(value: AppStatusType) {
    this._style = value;
  }

  @Input()
  get fontWeight(): AppStatusFontWeight {
    return this._fontWeight;
  }

  set fontWeight(value: AppStatusFontWeight) {
    this._fontWeight = value;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  public renderFontFamily() {
    switch (this.fontWeight) {
      case 'regular': {
        return `!font-['OpenSans'] !font-normal`;
      }
      case 'medium': {
        return `font-['OpenSans_Medium'] !font-medium`;

      }
      case 'semibold': {
        return `font-['OpenSans_Semibold'] !font-semibold`;
      }
      case 'bold': {
        return `font-['OpenSans_Bold'] !font-bold`;
      }
      default: {
        return `font-['OpenSans_Medium'] !font-medium`;
      }
    }
  }
}
