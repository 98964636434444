import { Injectable } from '@angular/core';
import {
  PaymentQuery, PaymentType, PaymentWithOnlyReceiptQuery,
} from '@generated/graphql';
import { FindContract } from "@shared/contracts/find.contract";
import { Payment } from "@modules/payment/models/payment.model";
import { map, Observable } from "rxjs";
import { paymentFactory } from "@modules/payment/factories/payment.factory";

@Injectable({
  providedIn: 'root',
})
export class PaymentApi implements FindContract<Payment> {
  constructor (
    private readonly paymentQuery: PaymentQuery,
    private readonly paymentWithOnlyReceiptQuery: PaymentWithOnlyReceiptQuery,
  ) {
  }

  find$ (id: string): Observable<Payment> {
    return this.paymentQuery.fetch({id}, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.payment ? paymentFactory.create(res.data.payment as PaymentType) : null)
    )
  }

  getPaymentWithOnlyReceipt$ (id: string): Observable<Payment> {
    return this.paymentWithOnlyReceiptQuery.fetch({id}, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.payment ? paymentFactory.create(res.data.payment as PaymentType) : null)
    )
  }
}
