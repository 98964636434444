import { Provider } from "@angular/core";
import { SnackBarService } from "@shared/services/snack-bar.service";
import { ErrorService } from "@shared/services/error.service";
import { CommonService } from "@shared/services/common.service";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";

export const COMMON_SERVICE_CONFIG: Provider = {
  provide: CommonService,
  useFactory (
    snackBarService: SnackBarService,
    errorService: ErrorService,
    matDialog: MatDialog,
    fb: FormBuilder
  ): CommonService {
    return new CommonService(snackBarService, errorService, matDialog, fb)
  },
  deps: [SnackBarService, ErrorService, MatDialog, FormBuilder]
}
