import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EntityService } from "@shared/services/entity.service";
import { ServiceEnum, ServiceType } from "@generated/graphql";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { Service } from "@modules/service/models/service.model";
import { ServiceFactory, serviceFactory } from "@modules/service/factories/service.factory";
import { SERVICE_STORE, ServiceStore } from "@modules/service/store/service.store";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ServiceService extends EntityService<EntityFeatureNameEnum.Service, ServiceType, Service> {
  get factory (): ServiceFactory {
    return serviceFactory
  }

  constructor (
    protected readonly store: Store,
    @Inject(SERVICE_STORE) protected readonly entityStore: ServiceStore
  ) {
    super(store, entityStore)
  }

  findByName$ (name: ServiceEnum): Observable<Service> {
    return this.store.pipe(
      select(this.entityStore.selectEntityByName(name)),
      map(item => item ? this.factory.create(item) : null)
    )
  }
}
