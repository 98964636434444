import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {Corporate} from "@modules/company/models/corporate.model";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  CompanyIndirectShareholderTableComponent
} from "@modules/company/components/company-indirect-shareholder-table/company-indirect-shareholder-table.component";
import {CompanyIndirectShareholder} from "@modules/company/models/company-indirect-shareholder.model";
import {NoRecordComponent} from "@shared/component/common/table/no-record/no-record.component";
import {MatTabsModule} from "@angular/material/tabs";
import {ShareDirectivesModule} from "@shared/share-directives/share-directives.module";
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {CompanyShareholder} from "@modules/company/models/company-shareholder.model";
import {PushModule} from "@ngrx/component";
import {Company} from "@modules/company/models/company.model";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    CompanyIndirectShareholderTableComponent,
    NoRecordComponent,
    MatTabsModule,
    ShareDirectivesModule,
    CalloutComponent,
    PushModule,
  ],
  selector: 'app-company-indirect-shareholder-container[selectedCorporates][company]',
  templateUrl: './company-indirect-shareholder-container.component.html',
  styleUrls: ['./company-indirect-shareholder-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyIndirectShareholderContainerComponent implements OnInit {
  static limitDeep: number = 7;

  @Output() backTo = new EventEmitter<number>()
  @Output() exist = new EventEmitter<void>()
  @Output() adding = new EventEmitter<Corporate>()
  @Output() selected = new EventEmitter<CompanyIndirectShareholder>()
  @Output() deleting = new EventEmitter<CompanyIndirectShareholder>()
  @Output() updating = new EventEmitter<CompanyIndirectShareholder>()
  @Output() checkingKyc = new EventEmitter<CompanyIndirectShareholder>()
  @Input() company: Company
  @Input() selectedCorporates: Corporate[] = []
  selectedItem: Corporate;

  get limitDeepMessage(): string {
    return `Currently we only allow shareholders to be listed up to the ${CompanyIndirectShareholderContainerComponent.limitDeep + 1}th layer`
  };

  get isLimitDeep(): boolean {
    return this.selectedCorporates.length === CompanyIndirectShareholderContainerComponent.limitDeep
  }

  get lastCorporate(): Corporate {
    return this.selectedCorporates[this.selectedCorporates.length - 1]
  }

  get corporateShareholders(): CompanyIndirectShareholder[] {
    return this.lastCorporate?.corporateShareholders
  }

  get individualShareholders(): CompanyIndirectShareholder[] {
    return this.lastCorporate?.individualShareholders
  }

  get noNeedToListShareholdersMessage(): string {
    return `"${this.lastCorporate?.name}" holds under ${CompanyShareholder.UBO * 100}% percentage of shares based on "${this.company?.name}" so "${this.lastCorporate?.name}" does not need to list the information of its shareholders`
  }

  ngOnInit(): void {
  }

  onExist(): void {
    this.exist.emit()
  }

  add(item: Corporate) {
    this.adding.emit(item)
  }

  select(item: CompanyIndirectShareholder) {
    this.selected.emit(item)

    this.selectedItem = item
  }

  backToLayer(layer: number): void {
    this.backTo.emit(layer)
  }

  delete(item: CompanyIndirectShareholder) {
    this.deleting.emit(item)
  }

  update(item: CompanyIndirectShareholder) {
    this.updating.emit(item)
  }

  checkKyc(item: CompanyIndirectShareholder) {
    this.checkingKyc.emit(item)
  }
}
