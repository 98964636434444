import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SupportedCountry } from '@modules/supported-country/models/supported-country.model';
import {
  SupportedCountryFactory,
  supportedCountryFactory
} from '@modules/supported-country/factories/supported-country.factory';
import { Country } from '@modules/country/models/country.model';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { User } from '@modules/user/models/user.model';
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { SupportedCountryType } from "@generated/graphql";
import {
  SUPPORTED_COUNTRY_STORE,
  SupportedCountryStore
} from "@modules/supported-country/store/supported-country.store";

@Injectable({
  providedIn: 'root',
})
export class SupportedCountryService extends EntityService<EntityFeatureNameEnum.SupportedCountry, SupportedCountryType, SupportedCountry> {
  readonly activeEntities$: Observable<SupportedCountry[]> = this.store.pipe(
    select(this.entityStore.selectAllActive),
    map(countries => countries ? countries.map(country => this.factory.create(country)) : []),
  );

  readonly countries$: Observable<Country[]> = this.entities$.pipe(
    map(items => items.map(({country}) => country)),
  );

  get factory (): SupportedCountryFactory {
    return supportedCountryFactory;
  }

  get activeCountries$ (): Observable<Country[]> {
    return this.activeEntities$.pipe(
      map(supportedCountries => supportedCountries.map(({country}) => country))
    )
  }

  get visibleResponsibilities$ (): Observable<Country[]> {
    return this.activeUser$
      .pipe(
        switchMap(activeUser => {
          if (!activeUser) {
            return of([]);
          }

          if (activeUser.isSuperAdmin()) {
            return this.activeCountries$;
          }

          if (activeUser.isAdmin()) {
            return this.filterResponsibilities$(activeUser.profile.responsibilities)
          }

          return of([]);
        }),
      );
  }

  constructor (
    @Inject(SUPPORTED_COUNTRY_STORE) public readonly entityStore: SupportedCountryStore,
    protected readonly store: Store,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
  ) {
    super(store, entityStore)
  }

  findByCountryId$ (id: Country['id'], shouldActivated: boolean = false): Observable<SupportedCountry> {
    const entities$ = !shouldActivated ? this.entities$ : this.activeEntities$

    return entities$.pipe(map(items => items.find(item => item.country.id === id)))
  }

  filterResponsibilities$ (responsibilities: Country[]): Observable<Country[]> {
    return this.activeCountries$.pipe(
      map(countries => responsibilities.filter(responsibility => countries.map(country => country.id).includes(responsibility.id)))
    )
  }

  isFilterResponsibilitiesIncludes$ (responsibilities: Country[], country: Country): Observable<boolean> {
    return this.filterResponsibilities$(responsibilities).pipe(map(countries => countries.map(country => country.id).includes(country.id)))
  }

  isActiveCountriesIncludes$ (country: Country): Observable<boolean> {
    return this.activeCountries$.pipe(map(countries => countries.map(country => country.id).includes(country.id)));
  }

  findActive$ (id: string): Observable<SupportedCountry> {
    return this.find$(id).pipe(map(country => country && country.isActive ? country : null))
  }
}
