<div role="group"
     class="multi-country-select-input-container"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <mat-chip-list #countryChipList
                 aria-label="Country selection"
                 [disabled]="disabled"
                 multiple>
    <mat-chip *ngFor="let country of selectedCountries"
              (removed)="removeCountry(country)"
              [disabled]="!(canDelete$(country) | async)">
      <span [class]="['fi', 'fi-' + country.code.toLowerCase()]"></span>&nbsp;{{country.name}}
      <button matChipRemove
              *ngIf="(canDelete$(country) | async)"
              [attr.aria-label]="'remove'">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input aria-label="Country"
           #countryInput
           [matChipInputFor]="countryChipList"
           [formControl]="countryControl"
           [matAutocomplete]="autoMultipleCountry"
           autocomplete="nope"
           [matAutocompleteDisabled]="isReachedLimit"/>
  </mat-chip-list>
  <mat-autocomplete #autoMultipleCountry="matAutocomplete"
                    (optionSelected)="onCountrySelected($event.option.value)">
    <mat-option *ngFor="let country of filteredCountries$ | async"
                [value]="country.id">
      <span [class]="['fi', 'fi-' + country.code.toLowerCase()]"></span>&nbsp;{{country.name}}
    </mat-option>
  </mat-autocomplete>
</div>
