import {BaseModel} from '@shared/models/base.model';
import {PaymentCycleEnum, PaymentTypeEnum, PaymentTypeShortEnum, PaymentTypeType} from '@generated/graphql';
import {startCase} from 'lodash-es';
import {createSelectOptionsFromEnum, SelectOption} from '@shared/helpers/convention.helpers';

export type PaymentTypeName = PaymentTypeShortEnum | PaymentTypeEnum

export class PaymentType extends BaseModel implements PaymentTypeType {
  id: string;
  name: PaymentTypeEnum;

  constructor(state: PaymentTypeType) {
    super(state);
  }

  static get paymentTypeNameSelectOptions(): SelectOption<PaymentTypeName>[] {
    return [
      PaymentTypeEnum.ContactToClient,
      PaymentTypeEnum.PerApplication,
      PaymentTypeEnum.OneOff,
      PaymentTypeShortEnum.Subscription
    ].map(item => {
      return {
        label: startCase(item.toLowerCase()),
        value: item
      };
    });
  }

  static get paymentCycleSelectOptions(): SelectOption<PaymentCycleEnum>[] {
    return createSelectOptionsFromEnum(PaymentCycleEnum);
  }

  get shortName(): PaymentTypeName {
    if (this.isSubscription) {
      return PaymentTypeShortEnum.Subscription;
    }

    return this.name;
  }

  get shortNameLabel(): string {
    return startCase(this.shortName.toLowerCase());
  }

  get isSubscription(): boolean {
    return PaymentType.isSubscription(this.name);
  }

  get isOneOffCharge(): boolean {
    return this.name === PaymentTypeEnum.OneOff;
  }

  get isChargePerApplication(): boolean {
    return this.name === PaymentTypeEnum.PerApplication;
  }

  get isContactToClient(): boolean {
    return this.name === PaymentTypeEnum.ContactToClient;
  }

  get cycle(): PaymentCycleEnum {
    if (this.isSubscription) {
      return Object.values(PaymentCycleEnum).find(item => this.name.includes(item));
    }

    return null;
  }

  get cycleLabel(): string {
    return this.cycle ? startCase(this.cycle.toLowerCase()) : '';
  }

  static getNameByTypeNameAndCycle(selection: PaymentTypeName, cycle: PaymentCycleEnum = null): PaymentType['name'] {
    const name = [selection, cycle]
      .filter(item => !!item)
      .join('_')
      .toUpperCase() as PaymentType['name'];

    return Object.values(PaymentTypeEnum).includes(name) ? name : null;
  }

  static isSubscription(name: PaymentTypeName): boolean {
    return name.includes(PaymentTypeShortEnum.Subscription);
  }

  static isContactToClient(name: PaymentTypeName): boolean {
    return name === PaymentTypeEnum.ContactToClient;
  }

  getModelName(): string {
    return PaymentType.name;
  }
}
