import {BaseModel} from '@shared/models/base.model';
import {ServiceCategoryName, ServiceCategoryType} from '@generated/graphql';
import {ServiceSupportedCountry} from '@modules/service/models/service-supported-country.model';
import {serviceCategoryFactory} from '@modules/service/factories/service-category.factory';
import {serviceSupportedCountryFactory} from '@modules/service/factories/service-supported-country.factory';
import {startCase} from 'lodash-es';
import {Service} from '@modules/service/models/service.model';
import {serviceFactory} from '@modules/service/factories/service.factory';

export class ServiceCategory extends BaseModel implements ServiceCategoryType {
  id: string;
  name: ServiceCategoryName;
  parent: ServiceCategory;
  serviceSupportedCountries: ServiceSupportedCountry[];
  services: Service[];

  constructor(state: ServiceCategoryType) {
    super(state);

    this.parent = state.parent ? serviceCategoryFactory.create(state.parent) : null;

    this.serviceSupportedCountries = state.serviceSupportedCountries
      ? state.serviceSupportedCountries.map(service => serviceSupportedCountryFactory.create(service))
      : [];

    this.services = state.services ? state.services.map(item => serviceFactory.create(item)) : [];
  }

  getModelName(): string {
    return ServiceCategory.name;
  }

  getNameLabel(): string {
    switch (this.name) {
      case ServiceCategoryName.AdditionalService: {
        return 'Additional Services'
      }
      case ServiceCategoryName.CapitalAndShare: {
        return 'Capital And Shares'
      }
      default: {
        return this.name ? startCase(this.name.toLowerCase()) : this.name;
      }
    }
  }

  isAdditionalService() {
    return this.name === ServiceCategoryName.AdditionalService;
  }

  isIncorporationService() {
    return this.name === ServiceCategoryName.Incorporation;
  }
}
