import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {Job} from '@app/modules/job/models/job.model';
import {Store} from '@ngrx/store';
import {countryStore} from '@app/modules/country/store/country.store';
import {CompanyDirectorInput, CompanyShareholderInput, CountryType, OfficerTypeEnum,} from '@generated/graphql';
import {
  OfficerInputReviewInformationComponent
} from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import {OfficerInputHelpers} from '@shared/classes/officer/officer-input.helper';
import {CompanyShareholder} from '@app/modules/company/models/company-shareholder.model';
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";
import {LetModule} from "@ngrx/component";
import {
  KYCReminderMessageComponent
} from "@shared/component/message/kyc-reminder-message/kyc-reminder-message.component";

@Component({
  selector: 'app-update-shareholder-info',
  standalone: true,
  templateUrl: './update-shareholder-info.component.html',
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
    CalloutComponent,
    LetModule,
    KYCReminderMessageComponent,
  ],
})
export class UpdateShareholderInfoComponent implements OnInit {
  @Input() public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  countries: CountryType[];

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    protected readonly commonHelper: CommonHelper,
    private _store: Store,
    private _officerInputHelper: OfficerInputHelpers,
  ) {
  }

  ngOnInit(): void {
    this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public getOfficerType(): OfficerTypeEnum {
    return this.jobDetail.shareholderInput.shareholder
      .officerType as OfficerTypeEnum;
  }

  public getCurrentOfficerInputValue(): CompanyShareholderInput {
    return this._officerInputHelper.transformShareholderModelToInput(
      new CompanyShareholder(this.jobDetail.oldShareholder),
    );
  }

  public getNewOfficerInputValue(): CompanyDirectorInput {
    return this.jobDetail.shareholderInput.shareholder;
  }
}
