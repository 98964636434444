import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { defer, from, switchMap, tap } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { authUserActions } from '@modules/auth/store/actions/auth-user.actions';

@Injectable()
export class AuthUserEffects {
  onSetAuthUser$ = createEffect(() => this.actions$.pipe(
      ofType(authUserActions.setAuthUser.type),
      switchMap(() => this.authService.auth$),
      switchMap(auth => defer(() => this.authService.setAuthToLocalStorage(auth))),
    ),
    {
      dispatch: false,
    },
  );

  constructor (
    private authService: AuthService,
    private actions$: Actions,
  ) {
  }
}
