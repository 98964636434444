<div
  class="children-jobs-table-wrapper"
  *ngrxLet="$childrenJobs | async as childrenJobs"
>
  <table mat-table [dataSource]="childrenJobs" class="table-custom w-full">
    <ng-container matColumnDef="matter">
      <th mat-header-cell *matHeaderCellDef class="!text-white w-1/3">
        Matter
      </th>
      <td mat-cell *matCellDef="let element">
        <a link [routerLink]="['/job', element.id]">
          {{ element.service.label }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="assignee">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="!text-white w-1/4 !text-center"
      >
        Experts
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <div class="text-center line-clamp-2">
          <span
            *ngIf="!element.experts.length; else showExpert"
            class="text-center block"
            >-</span
          >
          <ng-template #showExpert>
            <ul *ngFor="let expert of element.experts">
              <li>{{ expert.profile.fullName }}</li>
            </ul>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="!text-white w-1/4 !text-center"
      >
        Status
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <app-status
          class="!text-sm"
          fontWeight="semibold"
          [statusMessage]="element.renderStatusLabel()"
          [statusStyle]="element.renderStatusStyle()"
        ></app-status>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="bg-primary-800"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="row-hover !cursor-default"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td [colSpan]="3">
        <table-no-record
          [notFoundMessage]="'No children jobs'"
        ></table-no-record>
      </td>
    </tr>
  </table>
</div>
