import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableModule} from "@angular/material/table";
import {CommonModule} from "@angular/common";
import {NoRecordComponent} from "@shared/component/common/table/no-record/no-record.component";
import {CompanyIndirectShareholder} from "@modules/company/models/company-indirect-shareholder.model";
import {MatIconModule} from "@angular/material/icon";
import {OfficerIndirectCompanyType, OfficerIndirectPersonType} from "@generated/graphql";
import {CountryFlagComponent} from "@shared/component/common/country-flag/country-flag.component";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {StatusComponent} from "@shared/component/common/status/status.component";
import {ShareDirectivesModule} from "@shared/share-directives/share-directives.module";
import {Company} from "@modules/company/models/company.model";

@Component({
  standalone: true,
  selector: 'app-company-indirect-shareholder-table[shareholders][type][company]',
  templateUrl: './company-indirect-shareholder-table.component.html',
  imports: [
    CommonModule,
    MatTableModule,
    NoRecordComponent,
    MatIconModule,
    CountryFlagComponent,
    MatButtonModule,
    MatTooltipModule,
    StatusComponent,
    ShareDirectivesModule
  ],
  styleUrls: ['./company-indirect-shareholder-table.component.scss']
})
export class CompanyIndirectShareholderTableComponent implements OnInit {
  @Output() selected = new EventEmitter<CompanyIndirectShareholder>()
  @Output() deleting = new EventEmitter<CompanyIndirectShareholder>()
  @Output() checkingKyc = new EventEmitter<CompanyIndirectShareholder>()
  @Output() updating = new EventEmitter<CompanyIndirectShareholder>()
  @Input() shareholders: CompanyIndirectShareholder[]
  @Input() type: OfficerIndirectPersonType['__typename'] | OfficerIndirectCompanyType['__typename']
  @Input() company: Company

  get displayedColumns(): string[] {
    if (this.type === 'OfficerIndirectCompanyType') {
      return ['type','name', 'country', 'percentageOfShares', 'documentsStatus', 'actions']
    }

    return ['type', 'name', 'nationality', 'residence', 'percentageOfShares', 'documentsStatus', 'actions']
  }


  constructor() {
  }

  ngOnInit(): void {
  }

  select(item: CompanyIndirectShareholder) {
    this.selected.emit(item)
  }

  delete(item: CompanyIndirectShareholder) {
    this.deleting.emit(item)
  }

  update(item: CompanyIndirectShareholder) {
    this.updating.emit(item)
  }

  checkKyc(item: CompanyIndirectShareholder) {
    this.checkingKyc.emit(item)
  }
}
