import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { LookupOfficerDialogComponent } from '@shared/component/job/component/company-incorporation/dialog/lookup-officer-dialog/lookup-officer-dialog.component';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import {
  CompanyDirectorInput,
  CompanyShareholderInput,
  CreateCompanyInput,
  IncorporateTypeEnum,
  OfficerCompanyType,
  OfficerType,
  OfficerTypeEnum,
  SupportedCountryType,
} from '@generated/graphql';
import { SelectOption } from '@shared/helpers/convention.helpers';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectOperationCountry } from '@modules/job/store/create-job.selectors';
import { ShareholderFormType } from '@modules/job/models/form/shareholder-form-type';
import { DirectorFormType } from '@app/modules/job/models/form/director-form-type';
import { OfficerDialogManagement } from './classes/officer-dialog-management.class';
import { OfficerDialogManagementFactory } from './classes/officer-dialog-management-factory.class';
import { OfficerShareholderDialogMangement } from './classes/officer-shareholder-dialog-management.class';
import { ExistsOfficerType } from './classes/exists-officer.type';
import { OfficerService } from '@app/modules/officer/services/officer.service';

type DialogData = {
  incorporateType?: IncorporateTypeEnum;
  officerRole: OfficerRoleEnum;
  companyCapitals: FormArray;
  editData?:
  | CreateCompanyInput['shareholders'][0]
  | CreateCompanyInput['directors'][0];
  shareholders?: FormArray<FormGroup<ShareholderFormType>>;
  directors?: FormArray<FormGroup<DirectorFormType>>;
  shareholderEditIndex: number;
  exceptIds: string[];
};

@Component({
  selector: 'app-add-officer-dialog',
  templateUrl: './add-officer-dialog.component.html',
  styleUrls: ['./add-officer-dialog.component.scss'],
})
export class AddOfficerDialogComponent implements OnInit, OnDestroy {
  readonly officerType = OfficerTypeEnum;
  readonly companyCapitals: FormArray;

  private _officerRole: OfficerRoleEnum = OfficerRoleEnum.SHAREHOLDER;
  selectedType = OfficerTypeEnum.Individual;
  officerTypeOption: SelectOption[] = [
    {
      label: OfficerTypeEnum.Individual.toString().toLowerCase(),
      value: OfficerTypeEnum.Individual,
    },
    {
      label: OfficerTypeEnum.Corporate.toString().toLowerCase(),
      value: OfficerTypeEnum.Corporate,
    },
  ];
  useDetailOf = 'NEW';
  useDetailOfOption: SelectOption[] = [];
  countryOfIncorporation: SupportedCountryType;
  selectedExistsOfficerId: string;

  officerDialogManagement: OfficerDialogManagement;

  refId: string;

  customerExistOfficers: OfficerType[] = [];

  fetchingCustomerOfficers$ = new BehaviorSubject(true);

  get hasDateOfAppointment(): boolean {
    return (
      this.data.incorporateType === IncorporateTypeEnum.Transfer &&
      this._officerRole === OfficerRoleEnum.DIRECTOR
    );
  }

  extraForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: DialogRef<AddOfficerDialogComponent>,
    private dialog: MatDialog,
    private store: Store,
    private officerService: OfficerService,
  ) {
    this.companyCapitals = data.companyCapitals;
    this._officerRole = this.data.officerRole;
  }

  ngOnInit(): void {
    this.renderUseDetailOption(this._officerRole);

    this.store
      .select(selectOperationCountry)
      .pipe(
        tap((countryOfIncorporation) => {
          this.countryOfIncorporation = countryOfIncorporation;

          this.officerDialogManagement =
            OfficerDialogManagementFactory.getInstance(
              this._officerRole,
              this.data.editData ? 'edit' : 'new',
            );

          this.officerDialogManagement.countryOfIncorporation =
            this.countryOfIncorporation;

          this.setUpEditData();
        }),
      )
      .subscribe();
  }

  private createExtraForm() {
    if (this.hasDateOfAppointment) {
      this.extraForm = this._fb.group({
        dateOfAppointment: this._fb.control<Date>(null, Validators.required),
      });

      const { editData } = this.data;

      if (editData && (editData as CompanyDirectorInput).dateOfAppointment) {
        this.extraForm.controls.dateOfAppointment.setValue(
          (editData as CompanyDirectorInput).dateOfAppointment,
        );
      }
    }
  }

  private setUpEditData() {
    this.activeUser$.subscribe((user) => {
      if (user.isCustomer()) {
        this.fetchingCustomerOfficers$.next(true);

        this.officerService
          .allOfficersByCustomer(this.countryOfIncorporation.country.code)
          .subscribe((officers) => {
            this.customerExistOfficers = officers;

            this.officerDialogManagement.setExistOfficers(
              this.customerExistOfficers,
            );

            this.fetchingCustomerOfficers$.next(false);
          });
      }

      this.officerDialogManagement.setCreatingOfficers(
        this.data.directors || this.data.shareholders || undefined,
      );
    });

    if (this.data.editData) {
      const officer = this.data.editData;
      this.selectedType = officer.officerType as OfficerTypeEnum;
      this.officerDialogManagement
        .setFormType(this.selectedType)
        .createFormManagement()
        .prepareEditData(officer);

      if (officer.refId) {
        this.refId = officer.refId;

        this.useDetailOf = 'USE_EXISTING';

        this.officerDialogManagement.setUpRefForm();
      }
    } else {
      this.officerDialogManagement.createFormManagement();
    }

    if (
      this.data.shareholders &&
      this.officerDialogManagement instanceof OfficerShareholderDialogMangement
    ) {
      this.officerDialogManagement.setShareholderControls(
        this.data.shareholders,
      );
    }

    this.createExtraForm();
  }

  getShareDistributionsForm() {
    return (
      this.officerDialogManagement as OfficerShareholderDialogMangement
    ).getShareDistributionsForm();
  }

  getShareholderControls() {
    return (this.officerDialogManagement as OfficerShareholderDialogMangement)
      .shareholderControls;
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onSelectionChange(event) {
    this.selectedType = event.value;
    this.officerDialogManagement
      .setFormType(this.selectedType)
      .createFormManagement();
  }

  onSelectUseDetailChange(event) {
    this.useDetailOf = event.value;

    if (this.useDetailOf === 'NEW') {
      setTimeout(() => {
        this.officerDialogManagement.getOfficerForm().enable();
        this.officerDialogManagement.getOfficerForm().reset();
      }, 256);

      this.refId = undefined;
    }
  }

  renderUseDetailOption(officerRole: OfficerRoleEnum): SelectOption[] {
    this.useDetailOfOption = [
      {
        label: `New ${officerRole.toString().toLowerCase()}`,
        value: 'NEW',
      },
      {
        label: 'Existing Shareholders / Directors',
        value: 'USE_EXISTING',
      },
    ];

    return this.useDetailOfOption;
  }

  onOpenLookupOfficer() {
    const lookupOfficerDialog = this.dialog.open(LookupOfficerDialogComponent, {
      width: '50%',
      data: {
        countryOfIncorporation: this.countryOfIncorporation,
      },
    });

    lookupOfficerDialog
      .afterClosed()
      .subscribe((result: { officer: OfficerType; type: OfficerTypeEnum }) => {
        if (result) {
          const { officer, type } = result;

          this.selectedType = type;

          this.officerDialogManagement.setLookupData(officer, type);

          this.refId = officer.id;
        }
      });
  }

  showCorporate(officerTypeOption: SelectOption): boolean {
    const excepts = ['SG'];

    return !(
      officerTypeOption.value === OfficerTypeEnum.Corporate &&
      this.data.officerRole === OfficerRoleEnum.DIRECTOR &&
      excepts.includes(this.countryOfIncorporation.country.code.toUpperCase())
    );
  }

  existsOfficerSelectedHandler(existsOfficer: ExistsOfficerType): void {
    let officer: CompanyShareholderInput | CompanyDirectorInput;

    if (!existsOfficer.id.includes('temporary-')) {
      let representative = this.customerExistOfficers.find(
        (o) =>
          'representative' in o.officerProfile &&
          o.officerProfile.representative.id === existsOfficer.id,
      );

      let lookupOfficer: OfficerType;

      if (representative) {
        lookupOfficer = (representative.officerProfile as OfficerCompanyType)
          .representative;
      } else {
        lookupOfficer = this.customerExistOfficers.find(
          (o) => o.id === existsOfficer.id,
        );
      }

      if (lookupOfficer) {
        this.selectedType = existsOfficer.type;

        this.officerDialogManagement.setLookupData(
          lookupOfficer,
          this.selectedType,
        );

        this.refId = lookupOfficer.id;

        return;
      } else {
        officer = this.data.shareholders.value.find((s) => {
          if (s.id === existsOfficer.id) {
            return true;
          } else if (
            s.officerType === OfficerTypeEnum.Corporate &&
            s.officerCompany.representative.id === existsOfficer.id
          ) {
            return true;
          }

          return false;
        }) as CompanyShareholderInput;
      }
    } else {
      if (this.officerDialogManagement.isDirector()) {
        officer = this.data.shareholders.value.find((s) => {
          if (s.id === existsOfficer.id) {
            return true;
          } else if (
            s.officerType === OfficerTypeEnum.Corporate &&
            s.officerCompany.representative.id === existsOfficer.id
          ) {
            return true;
          }

          return false;
        }) as CompanyShareholderInput;

        if (
          officer &&
          officer.officerType === OfficerTypeEnum.Corporate &&
          existsOfficer.type === OfficerTypeEnum.Individual
        ) {
          officer = {
            id: existsOfficer.id,
            officerType: officer.officerType,
            isNominee: false,
            dateFrom: new Date(),
            officerPerson: officer.officerCompany.representative,
            refId: officer.officerCompany.representative.id,
          };
        }
      } else if (this.officerDialogManagement.isShareholder()) {
        officer = this.data.directors.value.find((s) => {
          if (s.id === existsOfficer.id) {
            return true;
          } else if (
            s.officerType === OfficerTypeEnum.Corporate &&
            s.officerCompany.representative.id === existsOfficer.id
          ) {
            return true;
          }

          return false;
        }) as CompanyDirectorInput;

        if (
          officer &&
          officer.officerType === OfficerTypeEnum.Corporate &&
          existsOfficer.type === OfficerTypeEnum.Individual
        ) {
          officer = {
            id: existsOfficer.id,
            officerType: officer.officerType,
            isNominee: false,
            officerPerson: officer.officerCompany.representative,
            refId: officer.officerCompany.representative.id,
          };
        }
      }
    }

    if (officer) {
      this.selectedType = existsOfficer.type;

      this.officerDialogManagement
        .setFormType(this.selectedType)
        .createFormManagement()
        .prepareEditData(officer)
        .setUpRefForm();

      this.refId = existsOfficer.id;
    }
  }

  getReturnResult() {
    return {
      officerForm: this.officerDialogManagement.officerFormManagement,
      refId: this.refId,
      extraData: this.extraForm?.getRawValue(),
    };
  }

  ngOnDestroy(): void {
    this.fetchingCustomerOfficers$.complete();
  }
}
