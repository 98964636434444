import { Inject, Injectable } from "@angular/core";
import { ServiceCategory } from "@modules/service/models/service-category.model";
import { Store } from "@ngrx/store";
import { ServiceCategoryFactory, serviceCategoryFactory } from "@modules/service/factories/service-category.factory";
import { ServiceCategoryType } from "@generated/graphql";
import { EntityService } from "@shared/services/entity.service";
import { SERVICE_CATEGORY_STORE, ServiceCategoryStore } from "@modules/service/store/service-category.store";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService extends EntityService<EntityFeatureNameEnum.ServiceCategory, ServiceCategoryType, ServiceCategory> {
  get factory (): ServiceCategoryFactory {
    return serviceCategoryFactory
  }

  constructor (
    protected readonly store: Store,
    @Inject(SERVICE_CATEGORY_STORE) protected readonly entityStore: ServiceCategoryStore
  ) {
    super(store, entityStore)
  }
}
