<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="businessProfile$ as businessProfile">
    <div class="gst-return-dialog-wrapper">
      <h1 mat-dialog-title class="mb-0 !text-[2.2rem] text-darkGray text-center">GST Return</h1>
      <mat-dialog-content>
        <ng-container [formGroup]="form">
          <p class="mat-subheading-1 !text-sub !mb-1 text-darkGray">
            Is customer need support to prepare their management accounts?
          </p>
          <mat-radio-group
            class="group-selection inline-flex pl-2"
            [formControl]="form.controls.gstReturnDetail.controls.needToPrepareDocument">
            <mat-radio-button
              *ngFor="let option of options"
              [value]="option.value"
              class="select-option mr-4 !text-sub capitalize">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>

          <!--DISPLAY ATTACHMENT FILE UPLOADED BY CUSTOMER-->
          <div
            *ngIf="!formValue.gstReturnDetail.isUsingTenantAccountingService && formValue.gstReturnDetail.needToPrepareDocument"
            class="mt-4">
            <app-dropzone-upload
              class="block !w-full"
              (filesChanged)="requestedFileChanged($event)">
            </app-dropzone-upload>
          </div>

          <section>
            <mat-divider class="!w-1/2 !my-6 !mx-auto"></mat-divider>
            <mat-form-field appearance="outline">
              <mat-label>Effective date</mat-label>
              <input matInput [matDatepicker]="picker" [formControl]="form.controls.effectiveDate"
                     placeholder="DD/MM/YYYY"/>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="form.controls.effectiveDate.hasError('required')">
                Effective date is required
              </mat-error>
            </mat-form-field>

            <ng-container>
              <p class="mat-body-2 text-base !mb-2">
                Upload the GST Return
              </p>
              <app-dropzone-upload class="block !w-full" (filesChanged)="filesChanged($event)"></app-dropzone-upload>
            </ng-container>
          </section>
        </ng-container>

      </mat-dialog-content>
      <mat-dialog-actions align="center">
        <button
          mat-raised-button
          mat-dialog-close
          class="w-48 bg-grey-800 text-white">Cancel
        </button>
        <button
          [disabled]="form.invalid"
          (click)="submit()"
          mat-raised-button
          color="primary"
          class="w-48">
          Submit
        </button>
      </mat-dialog-actions>
    </div>
  </ng-container>
</ng-container>

