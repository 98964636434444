import { Injectable } from "@angular/core";
import { OfficerRequiredDocumentsQuery, OfficerRequiredDocumentType } from "@generated/graphql";
import { map, Observable } from "rxjs";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";
import { officerRequiredDocumentFactory } from "@modules/officer/factories/officer-required-document.factory";

@Injectable({
  providedIn: 'root'
})
export class OfficerRequiredDocumentApi {
  constructor (
    private readonly officerRequiredDocumentsQuery: OfficerRequiredDocumentsQuery
  ) {
  }

  get$ (): Observable<OfficerRequiredDocument[]> {
    return this.officerRequiredDocumentsQuery
      .fetch(undefined, {fetchPolicy: 'no-cache'})
      .pipe(map(res => officerRequiredDocumentFactory.createEntities(res.data.officerRequiredDocuments as OfficerRequiredDocumentType[])))
  }
}
