<div class="checkout-page-wrapper">
  <p class="mat-title text-darkGray !mb-4 !text-xl">Checkout</p>
  <!--  ORDER SUMMARY-->
  <section class="oder-summary-container mb-4">
    <mat-card>
      <mat-card-title class="text-darkGray !mb-4 !inline-flex">
        <mat-icon class="mr-2 opacity-70">receipt_long</mat-icon>
        <p class="mat-title !text-base !mb-0">Order Summary</p>
      </mat-card-title>
      <mat-card-content class="px-8">
        <app-order-summary></app-order-summary>
      </mat-card-content>
    </mat-card>
  </section>
  <!--  BILLING ADDRESS-->
  <section class="billing-address-container mb-4"
           *ngIf="activeUser$|async as activeUser">
    <mat-card>
      <mat-card-title class="text-darkGray !mb-4 !inline-flex">
        <mat-icon class="mr-2 opacity-70">location_on</mat-icon>
        <p class="mat-title !text-base !mb-0">Billing address</p>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngrxLet="this.selectedBillingAddress$ as selectedBillingAddress">
          <ng-container *ngIf="!selectedBillingAddress; else hasAddress">
            <app-empty-billing-address (add)="onAddNewAddress()"></app-empty-billing-address>
          </ng-container>

          <ng-template #hasAddress>
            <app-address-card
              [config]="addressCardComponentConfig$|async"
              *ngIf="selectedBillingAddress$|async as selectedBillingAddress"
              (changeAddress)="changeSelectedAddress($event)"
              [address]="selectedBillingAddress">
            </app-address-card>
          </ng-template>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </section>
  <!--  PAYMENT METHODS-->
  <section class="payment-method-container">
    <mat-card>
      <mat-card-title class="text-darkGray !mb-4 !inline-flex">
        <mat-icon class="mr-2 opacity-70">monetization_on</mat-icon>
        <p class="mat-title !text-base !mb-0">Payment Methods</p>
      </mat-card-title>
      <mat-card-content class="px-8">
        <app-payment-methods [country$]="country$"></app-payment-methods>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-footer class="px-8 py-4">
        <p class="!mb-0 !text-sub">
          <span class="mat-title !text-sub text-darkGray !mb-0">Need help?&nbsp;</span>
          <span>We will be happy to assist you with any questions regarding our
            payment system at&nbsp;</span>
          <span
            *ngIf="businessProfile$|async as businessProfile">
            <a [href]="'mailto:' + businessProfile?.email">
              {{businessProfile?.email}}
            </a>
          </span>
        </p>
      </mat-card-footer>
    </mat-card>
  </section>
</div>
