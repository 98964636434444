import {Inject, Injectable} from '@angular/core';
import {
  CheckCanStartAcceptingPaymentQuery,
  PaymentProviderNameEnum,
  PaymentsForCompanyQuery,
  PaymentType,
  UploadProofOfPaymentInput,
  UploadProofOfPaymentMutation,
} from '@generated/graphql';
import {catchError, EMPTY, first, map, Observable, tap} from 'rxjs';
import {EntityService} from '@shared/services/entity.service';
import {EntityFeatureNameEnum} from '@shared/contracts/entity-store.contract';
import {Payment} from '@modules/payment/models/payment.model';
import {
  PAYMENT_STORE,
  PaymentStore,
} from '@modules/payment/store/payment.store';
import {Store} from '@ngrx/store';
import {PaymentApi} from '@modules/payment/api/payment.api';
import {
  paymentFactory,
  PaymentFactory,
} from '@modules/payment/factories/payment.factory';
import {SnackBarService} from "@shared/services/snack-bar.service";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends EntityService<
  EntityFeatureNameEnum.Payment,
  PaymentType,
  Payment
> {
  constructor(
    @Inject(PAYMENT_STORE) protected readonly entityStore: PaymentStore,
    protected readonly store: Store,
    private readonly paymentApi: PaymentApi,
    private readonly uploadProofOfPaymentMutation: UploadProofOfPaymentMutation,
    private readonly _checkCanStartAcceptingPayment: CheckCanStartAcceptingPaymentQuery,
    private readonly _paymentsForCompanyQuery: PaymentsForCompanyQuery,
    private readonly snackBarService: SnackBarService,
    private readonly screenSpinnerService: ScreenSpinnerService,
  ) {
    super(store, entityStore);
  }

  get factory(): PaymentFactory {
    return paymentFactory;
  }

  openPaymentReceipt(payment: Payment): void {
    const errorMsg = "Can't get Payment Receipt. Please try again or contact us to support!";

    this.screenSpinnerService.loading()

    this.paymentApi.getPaymentWithOnlyReceipt$(payment.id).pipe(
      first(),
      tap((paymentWithOnlyReceipt) => {
        this.screenSpinnerService.stopLoading()

        if (paymentWithOnlyReceipt) {
          window.open(paymentWithOnlyReceipt.receiptUrl, '_blank');

          return;
        }

        this.snackBarService.pushErrorAlert(errorMsg);
      }),
      catchError((e) => {
        this.snackBarService.pushErrorAlert(errorMsg);

        this.screenSpinnerService.stopLoading()

        return EMPTY;
      }),
    ).subscribe();
  }

  uploadProofOfPayment(data: UploadProofOfPaymentInput) {
    return this.uploadProofOfPaymentMutation
      .mutate(
        {
          input: {
            ...data,
          },
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(map((result) => result.data.uploadProofOfPayment));
  }

  checkCanStartAcceptingPayment(provider: PaymentProviderNameEnum) {
    return this._checkCanStartAcceptingPayment
      .fetch({provider}, {fetchPolicy: 'network-only'})
      .pipe(map((res) => res.data.checkCanStartAcceptingPayment));
  }

  public getPaymentsForCompany(companyId: string): Observable<PaymentType[]> {
    return this._paymentsForCompanyQuery
      .fetch(
        {
          companyId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((response) => response.data.paymentsForCompany as PaymentType[]),
      );
  }
}
