import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Company } from '@app/modules/company/models/company.model';
import { JobOrderServiceManagement } from '@app/modules/job/classes/job-order-service-management.class';
import { OfficerCorporateFormType } from '@app/modules/officer/models/form/officer-corporate-form-type';
import { OfficerIndividualFormType } from '@app/modules/officer/models/form/officer-individual-form-type';
import { OfficerCompany } from '@app/modules/officer/models/officer-company.model';
import { OfficerPerson } from '@app/modules/officer/models/officer-person.model';
import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { User } from '@app/modules/user/models/user.model';
import {
  FileTypeEnum,
  JobType,
  OfficerType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { ExistsOfficerManagement } from '@shared/classes/officer/exists-officer-management.class';
import { OfficerFormFactory } from '@shared/classes/officer/officer-form-factory';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import {
  DropzoneAction,
  DropzoneFileChangedEvent,
} from '@shared/component/common/dropzone-upload/dropzone-upload.component';
import { ExistsOfficerType } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/classes/exists-officer.type';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { BehaviorSubject } from 'rxjs';

export type OfficerDocument = {
  type: FileTypeEnum;
  file: File;
};

export type PostStatus =
  | 'IDLE'
  | 'SUBMITTING'
  | 'SUCCESS'
  | 'FINISHED'
  | 'ERROR';

export type ActionMode = 'New' | 'Edit' | 'Replace';

export abstract class OfficerRequestFormManagement {
  public mode: ActionMode = 'New';

  public role: OfficerRoleEnum;

  public effectiveDate = new FormControl<Date>(new Date(), Validators.required);

  private _typeSelected: OfficerTypeEnum = OfficerTypeEnum.Individual;
  get typeSelected() {
    return this._typeSelected;
  }
  set typeSelected(val) {
    this._typeSelected = val;
  }

  private _activeUser: User;
  get activeUser() {
    return this._activeUser;
  }
  set activeUser(val) {
    this._activeUser = val;
  }

  private _company: Company;
  get company() {
    return this._company;
  }
  set company(val) {
    this._company = val;
  }

  protected readonly officerTypeOption: OfficerTypeEnum[] = [
    OfficerTypeEnum.Individual,
    OfficerTypeEnum.Corporate,
    OfficerTypeEnum.Nominee,
  ];

  public serviceSupportedCountry: ServiceSupportedCountry;

  public jobServiceOrder = new JobOrderServiceManagement();

  public postStatus$ = new BehaviorSubject<PostStatus>('IDLE');

  public formInput: AbstractOfficerForm;

  public requireDocuments = [];

  public uploadedDocuments: OfficerDocument[] = [];

  public needDocument = false;

  public createdJob: JobType;

  public officerHelper = new OfficerHelper();

  public officerInputHelper = new OfficerInputHelpers();

  public existsOfficerManagement = new ExistsOfficerManagement();

  private _countryOfIncorporation: SupportedCountry;
  get countryOfIncorporation() {
    return this._countryOfIncorporation;
  }
  set countryOfIncorporation(val) {
    this._countryOfIncorporation = val;
    this.existsOfficerManagement.countryOfIncorporation = val;
  }

  public constructor() {
    //
  }

  public getOfficerTypeOption() {
    let filtered = this.officerTypeOption;

    if (this.serviceSupportedCountry.supportedCountry.country.code === 'SG') {
      filtered = this.officerTypeOption.filter(
        (o) => !(o === OfficerTypeEnum.Corporate),
      );
    }

    return filtered;
  }

  public createForm() {
    this.formInput = null;

    this.formInput = OfficerFormFactory.createForm(this.typeSelected);

    this.formInput.createOfficerForm();

    // this.generateRequireDocuments();
  }

  protected generateRequireDocuments() {
    switch (this.typeSelected) {
      case OfficerTypeEnum.Corporate:
        this.requireDocuments = [
          FileTypeEnum.CertificateOfIncorporation,
          FileTypeEnum.CertificateOfIncumbency,
          FileTypeEnum.ArticlesOfAssociation,
        ];

        this.needDocument = true;

        break;

      case OfficerTypeEnum.Individual:
        this.requireDocuments = [
          FileTypeEnum.Passport,
          FileTypeEnum.ProofOfAddress,
        ];

        this.needDocument = true;

        break;

      default:
        this.needDocument = false;
        this.requireDocuments = [];
    }
  }

  public fileChanged(event: DropzoneFileChangedEvent, type: FileTypeEnum) {
    if (event.type === DropzoneAction.Added) {
      this.uploadedDocuments.push({
        type,
        file: event.files[0],
      });
    } else {
      const index = this.uploadedDocuments.findIndex(
        (f) => f.file === event.files[0],
      );

      if (index > -1) {
        this.uploadedDocuments.splice(index, 1);
      }
    }
  }

  public isFormValid(): boolean {
    if (
      this.needDocument &&
      (this.uploadedDocuments.length === 0 ||
        this.uploadedDocuments.length !== this.requireDocuments.length)
    ) {
      return false;
    }

    if (!this.activeUser.isCustomer() && this.effectiveDate.invalid) {
      return false;
    }

    return !this.formInput.officerForm.invalid;
  }

  public individualOfficerForm() {
    return (
      (this.formInput.officerForm as FormGroup<OfficerIndividualFormType>) ||
      null
    );
  }

  public corporateOfficerForm() {
    return (
      (this.formInput.officerForm as FormGroup<OfficerCorporateFormType>) ||
      null
    );
  }

  public filterAndSortExistOfficers(): this {
    this.existsOfficerManagement.filterAndSortExistOfficers();

    return this;
  }

  public resetRequestForm(): void {
    this.formInput.createOfficerForm();

    this.existsOfficerManagement.reset();

    setTimeout(() => {
      this.formInput.officerForm.enable();
    }, 128);
  }

  protected setOfficerPersonOfficerData(officer: OfficerPerson): void {
    (
      this.formInput.officerForm as FormGroup<OfficerIndividualFormType>
    ).patchValue(
      this.officerInputHelper.transformOfficerPersonFromTypeToInput(officer),
    );
  }

  protected setOfficerCompanyOfficerData(officer: OfficerCompany): void {
    (
      this.formInput.officerForm as FormGroup<OfficerCorporateFormType>
    ).patchValue(
      this.officerInputHelper.transformOfficerCompanyModelToInput(officer),
    );
  }

  public existsOfficerSelectedHandler(existsOfficer: ExistsOfficerType): this {
    this.createForm();

    this.existsOfficerManagement.refId = existsOfficer.id;

    const officer =
      this.existsOfficerManagement.findSelectedOfficer(existsOfficer);

    this.setExistsOfficerToForm(officer);

    return this;
  }

  public lookUpOfficerSelectedHandler(officer: OfficerType): this {
    this.createForm();

    this.existsOfficerManagement.refId = officer.id;

    this.setExistsOfficerToForm(officer);

    return this;
  }

  /**
   * Abstract function
   */

  public abstract setEditData(data: any): this;

  public abstract setServiceSupportedCountry(
    services: ServiceSupportedCountry[],
  ): this;

  // public abstract existsOfficerSelectedHandler(
  //   existsOfficer: ExistsOfficerType,
  // ): this;
  //
  // public abstract lookUpOfficerSelectedHandler(officer: OfficerType): this;

  protected abstract setExistsOfficerToForm(officer: OfficerType): void;

  public abstract getFormValue(): any;

  public abstract submitForm(): any;
}
