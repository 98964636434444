import {ModelFactory} from "@shared/factories/model.factory";
import {OfficerIndirectPersonType} from "@generated/graphql";
import {OfficerIndirectPerson} from "@modules/officer/models/officer-indirect-person.model";

export class OfficerIndirectPersonFactory extends ModelFactory<OfficerIndirectPersonType, OfficerIndirectPerson> {
  create(state: OfficerIndirectPersonType): OfficerIndirectPerson {
    return new OfficerIndirectPerson(state);
  }
}

export const officerIndirectPersonFactory = new OfficerIndirectPersonFactory()
