import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { UserType } from '@generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class ActiveUserResolver implements Resolve<UserType> {

  constructor (
    private readonly authService: AuthService,
  ) {
  }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserType> {
    return this.authService.reloadActiveUser$();
  }
}
