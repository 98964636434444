import {Injectable} from '@angular/core';
import {
  CompanyAuditorInput,
  CompanySecretaryInput,
  CompanySecretaryType, ServiceEnum,
  UpdateCompanyAuditorInput,
  UpdateCompanySecretaryInput,
} from '@generated/graphql';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter, finalize, first,
  Observable,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import {CompanySecretary} from '@modules/company/models/company-secretary.model';
import {Company} from '@modules/company/models/company.model';
import {cloneDeep} from 'lodash-es';
import {GraphQLErrors} from '@apollo/client/errors';
import {companySecretaryFactory} from '@modules/company/factories/company-secretary.factory';
import {DateTime} from 'luxon';
import {CompanyAuditor} from '@modules/company/models/company-auditor.model';
import {companyAuditorFactory} from '@modules/company/factories/company-auditor.factory';
import {CompanySecretaryApi} from '@modules/company/api/company-secretary.api';
import {CompanyAuditorApi} from '@modules/company/api/company-auditor.api';
import {ActivatedRoute, Router} from "@angular/router";

export enum CompanyDialogEnum {
  FYE = 'FYE',
  AGM = 'AGM',
  FormC = 'FORM_C',
  ECI = 'ECI',
  ProfitsTaxReturn = 'PROFITS_TAX_RETURN',
  GSTReturn = 'GST_RETURN',
  GSTRegistration = 'GST_REGISTRATION',
  ChangeRegisteredAddress = 'CHANGE_REGISTERED_ADDRESS',
  AddDirector = 'ADD_DIRECTOR',
  EditDirector = 'EDIT_DIRECTOR',
  ReplaceDirector = 'REPLACE_DIRECTOR',
  RemoveDirector = 'REMOVE_DIRECTOR',
  IncreaseCapital = 'INCREASE_CAPITAL',
  EditShareholder = 'EDIT_SHAREHOLDER',
  CorporateTransferShare = 'CORPORATE_TRANSFER_SHARE',
}

@Injectable({
  providedIn: 'root',
})
export class CompanyEventService {
  readonly openDialog$ = new Subject<CompanyDialogEnum>();

  readonly onCompanySecretaryCreate = new Subject<{
    input: CompanySecretaryInput;
  }>();

  readonly onCompanySecretaryCreatedSuccess = new Subject<CompanySecretary>();

  readonly onCompanySecretaryCreatedError = new Subject<GraphQLErrors | null>();

  readonly onCompanySecretaryUpdate = new Subject<{
    input: UpdateCompanySecretaryInput;
  }>();

  readonly onCompanySecretaryUpdatedSuccess = new Subject<CompanySecretary>();

  readonly onCompanySecretaryUpdatedError = new Subject<GraphQLErrors | null>();

  readonly onCompanySecretaryDelete = new Subject<CompanySecretary['id']>();

  readonly onCompanySecretaryDeletedSuccess = new Subject<boolean>();

  readonly onCompanySecretaryDeletedError = new Subject<GraphQLErrors | null>();

  readonly onCompanyAuditorCreate = new Subject<{
    input: CompanyAuditorInput;
  }>();

  readonly onCompanyAuditorCreatedSuccess = new Subject<CompanyAuditor>();

  readonly onCompanyAuditorCreatedError = new Subject<GraphQLErrors | null>();

  readonly onCompanyAuditorUpdate = new Subject<{
    input: UpdateCompanyAuditorInput;
  }>();

  readonly onCompanyAuditorUpdatedSuccess = new Subject<CompanyAuditor>();

  readonly onCompanyAuditorUpdatedError = new Subject<GraphQLErrors | null>();

  readonly onCompanyAuditorDelete = new Subject<CompanyAuditor['id']>();

  readonly onCompanyAuditorDeletedSuccess = new Subject<boolean>();

  readonly onCompanyAuditorDeletedError = new Subject<GraphQLErrors | null>();

  private readonly _companySecretaries$: BehaviorSubject<CompanySecretary[]> =
    new BehaviorSubject<CompanySecretary[]>([]);

  private readonly _companyAuditors$: BehaviorSubject<CompanyAuditor[]> =
    new BehaviorSubject<CompanyAuditor[]>([]);

  private readonly _company$: BehaviorSubject<Company> =
    new BehaviorSubject<Company>(null);

  constructor(
    private readonly companySecretaryApi: CompanySecretaryApi,
    private readonly companyAuditorApi: CompanyAuditorApi,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
  }

  get company(): Company {
    return this._company$.value;
  }

  get company$(): Observable<Company> {
    return this._company$.asObservable();
  }

  get companySecretaries(): CompanySecretary[] {
    return this._companySecretaries$.value;
  }

  get companySecretaries$(): Observable<CompanySecretary[]> {
    return this._companySecretaries$.asObservable();
  }

  get companyAuditors(): CompanyAuditor[] {
    return this._companyAuditors$.value;
  }

  get companyAuditors$(): Observable<CompanyAuditor[]> {
    return this._companyAuditors$.asObservable();
  }

  setCompany(company: Company): void {
    this._company$.next(cloneDeep(company));
  }

  addCompanySecretary(secretary: CompanySecretary): void {
    this._companySecretaries$.next([
      secretary,
      ...this._companySecretaries$.value,
    ]);
  }

  setCompanySecretaries(secretaries: CompanySecretary[]): void {
    this._companySecretaries$.next([...secretaries]);
  }

  updateCompanySecretary(update: CompanySecretaryType): void {
    const companySecretaries = cloneDeep(this.companySecretaries);

    const index = companySecretaries.findIndex(
      (companySecretary) => companySecretary.id === update.id,
    );

    if (index >= 0) {
      companySecretaries[index] = companySecretaryFactory.create(update);

      this.setCompanySecretaries(companySecretaries);
    }
  }

  deleteCompanySecretary(id: CompanySecretary['id']): void {
    const companySecretaries = cloneDeep(this.companySecretaries);

    const index = companySecretaries.findIndex(
      (companySecretary) => companySecretary.id === id,
    );

    if (index >= 0) {
      companySecretaries.splice(index, 1);

      this.setCompanySecretaries(companySecretaries);
    }
  }

  setCompanyAuditors(auditors: CompanyAuditor[]): void {
    this._companyAuditors$.next([...auditors]);
  }

  addCompanyAuditor(auditor: CompanyAuditor): void {
    this._companyAuditors$.next([auditor, ...this._companyAuditors$.value]);
  }

  updateCompanyAuditor(update: CompanyAuditor): void {
    const companyAuditors = cloneDeep(this.companyAuditors);

    const index = companyAuditors.findIndex(
      (companyAuditor) => companyAuditor.id === update.id,
    );

    if (index >= 0) {
      companyAuditors[index] = companyAuditorFactory.create(update);

      this.setCompanyAuditors(companyAuditors);
    }
  }

  deleteCompanyAuditor(id: CompanyAuditor['id']): void {
    const companyAuditors = cloneDeep(this.companyAuditors);

    const index = companyAuditors.findIndex(
      (companyAuditor) => companyAuditor.id === id,
    );

    if (index >= 0) {
      companyAuditors.splice(index, 1);

      this.setCompanyAuditors(companyAuditors);
    }
  }

  companySecretaryCreateEvent$(): Observable<CompanySecretary> {
    return this.onCompanySecretaryCreate.pipe(
      switchMap(({input}) => {
        const processedInput = this.processCompanySecretaryInput(input);

        return this.companySecretaryApi.create$(processedInput).pipe(
          tap((companySecretary) => {
            if (companySecretary) {
              this.addCompanySecretary(companySecretary);

              this.onCompanySecretaryCreatedSuccess.next(companySecretary);

              return;
            }

            this.onCompanySecretaryCreatedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanySecretaryCreatedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  companySecretaryUpdateEvent$(): Observable<CompanySecretary> {
    return this.onCompanySecretaryUpdate.pipe(
      switchMap(({input}) => {
        const processedInput = this.processUpdateCompanySecretaryInput(input);

        return this.companySecretaryApi.update$(processedInput).pipe(
          tap((companySecretary) => {
            if (companySecretary) {
              this.updateCompanySecretary(companySecretary);

              this.onCompanySecretaryUpdatedSuccess.next(companySecretary);

              return;
            }

            this.onCompanySecretaryUpdatedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanySecretaryUpdatedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  companySecretaryDeleteEvent$(): Observable<boolean> {
    return this.onCompanySecretaryDelete.pipe(
      switchMap((id) => {
        return this.companySecretaryApi.delete$(id).pipe(
          tap((isDeleted) => {
            if (isDeleted) {
              this.deleteCompanySecretary(id);

              this.onCompanySecretaryDeletedSuccess.next(isDeleted);

              return;
            }

            this.onCompanySecretaryDeletedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanySecretaryDeletedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  companyAuditorCreateEvent$(): Observable<CompanyAuditor> {
    return this.onCompanyAuditorCreate.pipe(
      switchMap(({input}) => {
        return this.companyAuditorApi.create$(input).pipe(
          tap((companyAuditor) => {
            if (companyAuditor) {
              this.addCompanyAuditor(companyAuditor);

              this.onCompanyAuditorCreatedSuccess.next(companyAuditor);

              return;
            }

            this.onCompanyAuditorCreatedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanyAuditorCreatedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  companyAuditorUpdateEvent$(): Observable<CompanyAuditor> {
    return this.onCompanyAuditorUpdate.pipe(
      switchMap(({input}) => {
        return this.companyAuditorApi.update$(input).pipe(
          tap((companyAuditor) => {
            if (companyAuditor) {
              this.updateCompanyAuditor(companyAuditor);

              this.onCompanyAuditorUpdatedSuccess.next(companyAuditor);

              return;
            }

            this.onCompanyAuditorUpdatedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanyAuditorUpdatedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  companyAuditorDeleteEvent$(): Observable<boolean> {
    return this.onCompanyAuditorDelete.pipe(
      switchMap((id) => {
        return this.companyAuditorApi.delete$(id).pipe(
          tap((isDeleted) => {
            if (isDeleted) {
              this.deleteCompanyAuditor(id);

              this.onCompanyAuditorDeletedSuccess.next(isDeleted);

              return;
            }

            this.onCompanyAuditorDeletedError.next(null);
          }),
          catchError(({graphQLErrors}) => {
            this.onCompanyAuditorDeletedError.next(
              graphQLErrors as GraphQLErrors,
            );

            return EMPTY;
          }),
        );
      }),
    );
  }

  onOpenDialog$(type: CompanyDialogEnum | CompanyDialogEnum[], config: {
    shouldResetQueryParams?: boolean
  } = {
    shouldResetQueryParams: true
  }): Observable<CompanyDialogEnum> {
    return this.openDialog$.pipe(
      filter((value) => Array.isArray(type) ? type.includes(value) : value === type),
      first(),
      finalize(() => {
        this.openDialog$.next(null)

        if (config?.shouldResetQueryParams) {
          this.router.navigate([], {relativeTo: this.route});
        }
      })
    );
  }

  protected processCompanySecretaryInput(
    input: CompanySecretaryInput,
  ): CompanySecretaryInput {
    const cloneInput = cloneDeep(input);

    let expiryDate =
      cloneInput.officer?.identification?.expiryDate ?? undefined;

    if (expiryDate) {
      cloneInput.officer.identification.expiryDate = DateTime.fromJSDate(
        expiryDate as Date,
      ).toISODate();
    }

    return cloneInput;
  }

  protected processUpdateCompanySecretaryInput(
    input: UpdateCompanySecretaryInput,
  ): UpdateCompanySecretaryInput {
    const processedInput = this.processCompanySecretaryInput(input);

    return {
      ...input,
      ...processedInput,
    };
  }
}
