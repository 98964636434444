<section *ngIf="companyStructureForm"
         class="company-capital-wrapper table-container table-container-limited-scroll mat-elevation-z4 mb-4"
         [formGroup]="companyStructureForm">
    <table formArrayName="companyCapitals"
           class="mat-table cdk-table table-custom w-full">
        <tr class="mat-header-row bg-primary-800">
            <th class="mat-header-cell !text-white">Capital Amount</th>
            <th class="mat-header-cell !text-center !text-white">Number of Shares</th>
            <th class="mat-header-cell !text-center !text-white">Currency</th>
            <th class="mat-header-cell !text-center !text-white">Share type</th>
            <th class="mat-header-cell w-16"
                [ngClass]="{ hidden: appearance === 'viewOnly' }">
                &nbsp;
            </th>
        </tr>
        <ng-container *ngFor="let control of companyCapitalControls.controls; let i = index">
            <tr [formGroupName]="i"
                class="mat-row">
                <td class="mat-cell">
                    <mat-form-field appearance="outline"
                                    class="w-full"
                                    *ngIf="appearance === 'register'">
                        <input matInput
                               type="number"
                               step="any"
                               min="0"
                               [defaultValue]="0"
                               formControlName="totalAmount"/>
                        <mat-error *ngIf="control.get('totalAmount')?.hasError('required')">
                            Total amount required
                        </mat-error>
                        <mat-error *ngIf="control.get('totalAmount')?.hasError('min')">
                            Amount must at least 1.
                        </mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="appearance === 'viewOnly'">
                        {{ control.controls.totalAmount.value | number}}
                    </ng-container>
                </td>
                <td class="mat-cell text-center">
                    <mat-form-field appearance="outline"
                                    class="w-full"
                                    *ngIf="appearance === 'register'">
                        <input matInput
                               type="number"
                               min="0"
                               step="1"
                               [defaultValue]="0"
                               formControlName="totalShares"/>
                        <mat-error *ngIf="control.get('totalShares')?.hasError('required')">
                            Total shares required
                        </mat-error>
                        <mat-error *ngIf="control.get('totalShares')?.hasError('min')">
                            Shares number must at least 1.
                        </mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="appearance === 'viewOnly'">
                        {{ control.controls.totalShares.value }}
                    </ng-container>
                </td>
                <td class="mat-cell text-center">
                    <mat-form-field appearance="outline"
                                    class="w-full"
                                    *ngIf="appearance === 'register'">
                        <mat-select class="mat-select-no-label !text-transform: capitalize"
                                    disableRipple
                                    formControlName="currency"
                                    (selectionChange)="onCurrencyChanged($event, i)">
                            <mat-option *ngFor="let currency of currencies"
                                        [value]="currency"
                                        class="!text-transform: capitalize">
                                {{ currency }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="control.get('currency')?.hasError('required')">
                            Currency is required
                        </mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="appearance === 'viewOnly'">
                        {{ control.controls.currency.value }}
                    </ng-container>
                </td>
                <td class="mat-cell text-center">
                    <mat-form-field appearance="outline"
                                    class="w-full"
                                    *ngIf="appearance === 'register'">
                        <mat-select class="mat-select-no-label !text-transform: capitalize"
                                    disableRipple
                                    formControlName="shareTypeId"
                                    (selectionChange)="onShareTypeChanged($event, i)">
                            <mat-select-trigger>
                                <span class="capitalize">
                                    {{control.controls.shareTypeId.value ? getShareTypeName(control.controls.shareTypeId.value).toLocaleLowerCase() : ''}}
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let type of shareTypes"
                                        [value]="type.id"
                                        class="!capitalize"
                                        [disabled]="isShareTypeDisabled(type.id, i)">
                                <div class="inline-flex items-center">
                                    <span class="capitalize mr-2">
                                      {{ type.name.toLowerCase() }}
                                    </span>
                                    <mat-icon class="text-darkGray text-xbase opacity-60 cursor-pointer"
                                              matTooltip="{{commonHelper.getShareTypeHint(type.name)}}">
                                        info
                                    </mat-icon>
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="control.get('shareTypeId')?.hasError('required')">
                            Share type is required
                        </mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="appearance === 'viewOnly' && shareTypes">
            <span class="!capitalize">{{
                getShareTypeName(control.controls.shareTypeId.value).toLowerCase()
                }}</span>
                    </ng-container>
                </td>
                <td class="mat-cell w-16"
                    [ngClass]="{ hidden: appearance === 'viewOnly' }">
                    <button *ngIf="companyCapitalControls.controls.length > 1"
                            mat-icon-button
                            color="warn"
                            (click)="deleteCapital(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </tr>
        </ng-container>
    </table>
</section>
<div class="m-2 inline-flex justify-end w-full"
     *ngIf="appearance !== 'viewOnly'">
    <button mat-raised-button
            color="accent"
            (click)="addNewCapital()">
        <mat-icon>add</mat-icon>
        Add New
    </button>
</div>
