import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
  ConversationMessage,
  ConversationMessageTypeEnum,
  MessageActionEnum,
} from '@shared/classes/conversation/message-type';
import {
  TextMessageComponent
} from '@modules/job/components/job-detail/conversation/components/text-message/text-message.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ShareDirectivesModule} from '@shared/share-directives/share-directives.module';
import {
  FileMessageComponent
} from '@modules/job/components/job-detail/conversation/components/file-message/file-message.component';

@Component({
  selector: 'app-message-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    TextMessageComponent,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    NgOptimizedImage,
    ShareDirectivesModule,
    FileMessageComponent,
  ],
  templateUrl: './message-wrapper.component.html',
  styleUrls: ['./message-wrapper.component.scss'],
})
export class MessageWrapperComponent implements OnInit {
  private _message: ConversationMessage;
  protected readonly MessageActionEnum = MessageActionEnum;
  protected readonly MessageType = ConversationMessageTypeEnum;

  @Output() gotoMessage = new EventEmitter();
  @Output() deleteMessage = new EventEmitter();
  @Output() messageSelected = new EventEmitter<{
    action: MessageActionEnum;
    message: ConversationMessage;
  }>();

  public get message(): ConversationMessage {
    return this._message;
  }

  public get isTextMessage(): boolean {
    return this.message.type === ConversationMessageTypeEnum.Text;
  }

  public get isFileMessage(): boolean {
    return this.message.type === ConversationMessageTypeEnum.File;
  }

  public get isLinkMessage(): boolean {
    return this.message.type === ConversationMessageTypeEnum.Link;
  }

  @Input()
  public set message(value: ConversationMessage) {
    this._message = value;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  public onActionOnMessage(
    action: MessageActionEnum,
    message: ConversationMessage,
  ) {
    this.messageSelected.emit({action, message});
  }
}
