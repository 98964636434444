<ng-container *ngrxLet="businessProfile$ as businessProfile">
    <ng-container *ngIf="paymentMethodsData$ | async">
        <div class="payment-methods-wrapper">
            <!--  select payment method-->
            <section class="payment-method-container">
                <ng-container *ngIf="paymentProviderOptions$ | ngrxPush as paymentProviderOptions">
                  <ng-container *ngIf="paymentProviderOptions.length">
                    <p class="mat-subheading-1 text-darkGray">Select the payment methods that work best for you</p>
                    <mat-radio-group
                      [formControl]="paymentProviderControl"
                      aria-label="Select a payment method"
                      class="group-selection mb-4 inline-flex">
                        <ng-container *ngFor="let item of paymentProviderOptions">
                            <mat-radio-button [value]="item.value"
                                              class="select-option w-full">
                                <p class="!mb-0 inline-flex option-label justify-between w-full">
                                    <span>{{ item.label }}</span>
                                    <mat-icon class="text-darkGray opacity-70">{{item.icon}}</mat-icon>
                                </p>
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                  </ng-container>

                  <app-callout message="We apologize for the inconvenience as our payment gateway is currently under maintenance. Please check back in a little while. We appreciate your patience and understanding. Thank you!"
                               appearance="warn"
                               *ngIf="!paymentProviderOptions.length"></app-callout>
                </ng-container>
            </section>

            <mat-divider class="!my-6 w-1/2 !m-auto"></mat-divider>

            <section class="payment-detail-container">
                <ng-container [ngSwitch]="paymentProviderControl.value">
                    <!--WIRE TRANSFER CONFIG-->
                    <ng-container *ngSwitchCase="PaymentProviderNameEnum.WireTransfer">
                        <section *ngIf="(banks$ | async).length; else noWTBankAccountsProvided"
                                 class="wire-transfer-container">
                            <div class="bank-info-container mb-6">
                                <div class="!mb-6 inline-flex items-baseline">
                                    <app-status
                                      [ngClass]="'!text-sub !mr-2'"
                                      statusMessage="1"
                                      statusStyle="new"></app-status>
                                    <p class="mat-subheading-1 !mb-0">
                                        Please select a bank account that is suitable for your wire transfer payment.
                                    </p>
                                </div>
                                <div class="list-bank-account w-full max-h-[28rem] border border-solid border-[#0000001f] px-8 py-4 overflow-x-hidden overflow-y-auto">
                                    <div *ngFor="let bank of banks$ | async"
                                         class="bank-info">
                                        <p class="mat-title text-darkGray !text-xbase !mb-1">
                                            {{ bank.name }}
                                        </p>
                                        <p class="!mb-1">
                                            <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Account name&colon;&nbsp;</span>
                                            <span class="mat-subheading-2">{{bank.holderName}}</span>
                                        </p>
                                        <p class="!mb-1">
                                            <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Accountnumber&colon;&nbsp;</span>
                                            <span class="mat-subheading-2">{{ bank.account }}</span>
                                        </p>
                                        <p class="!mb-1">
                                            <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">Branch&colon;&nbsp;</span>
                                            <span class="mat-subheading-2">{{ bank.branch }}</span>
                                        </p>
                                        <p class="!mb-1">
                                            <span class="font-['OpenSans_Semibold'] font-semibold text-darkGray inline-block w-40">SWIFT&colon;&nbsp;</span>
                                            <span class="mat-subheading-2">{{ bank.swiftCode }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="upload-receipt-container mb-6 w-full flex flex-col items-end">
                                <div class="!mb-6 inline-flex items-baseline w-full justify-start">
                                    <app-status
                                      [ngClass]="'!text-sub mr-2'"
                                      statusMessage="2"
                                      statusStyle="new"></app-status>
                                    <p class="mat-subheading-1 !mb-0">
                                        Upload a copy of the wire transfer receipt
                                    </p>
                                </div>
                                <div class="w-full mb-3">
                                    <app-dropzone-upload
                                      (filesChanged)="wireTransferProofChanged($event)"
                                      accept="application/pdf, image/*"></app-dropzone-upload>
                                </div>
                                <button
                                  (click)="submitPayment()"
                                  [disabled]="disabledSubmitWireTransferPayment$ | async"
                                  class="w-fit !mb-4"
                                  color="primary"
                                  mat-raised-button>
                                    <mat-icon>upload</mat-icon>
                                    Submit payment
                                </button>
                                <app-callout
                                  [ngClass]="'w-full !mb-0'"
                                  appearance="warn"
                                  message="Once your payment is confirmed, we will send a confirmation to your email address.">
                                </app-callout>
                            </div>
                        </section>
                    </ng-container>

                    <!--STRIPE CONFIG-->
                    <ng-container *ngSwitchCase="PaymentProviderNameEnum.Stripe">
                        <div class="w-full inline-flex justify-center">
                            <app-stripe-payment-form></app-stripe-payment-form>
                        </div>
                    </ng-container>
                </ng-container>
            </section>
        </div>

        <ng-template #noWTBankAccountsProvided>
            <app-callout appearance="warn"
                         customContent>
              <p class="!text-sub !mb-0">
                We regret to inform you that bank information for Wire Transfer payments is currently unavailable. We appreciate your understanding and ask for your patience. In the meantime, please contact our support team for assistance
                <span *ngIf="businessProfile">via&nbsp;<a [href]="'mailto:' + businessProfile.email">{{businessProfile.email}}</a></span>
                or check back later. Thank you.
              </p>
            </app-callout>
        </ng-template>
    </ng-container>
</ng-container>
