<div class="dialog-wrapper extra-job-dialog-wrapper" *ngIf="form">
  <h1 mat-dialog-title
      class="mb-2 !text-[2.2rem] text-darkGray !text-center">
    Create Extra Job
  </h1>
  <mat-dialog-content class="min-h-fit">
    <form class="flex flex-col w-full" [formGroup]="form" appInvalidControlScroll>
      <mat-form-field appearance="outline">
        <mat-label>Job matter</mat-label>
        <input
          [formControl]="form.controls.matter"
          matInput
          placeholder="Job matter">
        <mat-error *ngIf="form.controls.matter.hasError('validationErrors')">
          {{ form.controls.matter.getError('validationErrors') }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Scope of work</mat-label>
        <textarea
          [formControl]="form.controls.scopeOfWork"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="4"
          contenteditable="true"
          placeholder="Scope of work"></textarea>
        <mat-error *ngIf="form.controls.scopeOfWork.hasError('validationErrors')">
          {{ form.controls.scopeOfWork.getError('validationErrors') }}
        </mat-error>
      </mat-form-field>

      <ng-container *ngIf="visiblePayment$|async">
        <mat-divider class="!w-1/2 !my-6 !m-auto"></mat-divider>

        <p class="mat-title !text-xbase text-darkGray !mb-1">
          Job Fees
        </p>

        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>Currency</mat-label>
          <mat-select [formControl]="form.controls.currency">
            <mat-option [value]="currency" *ngFor="let currency of job.supportedCountry.supportedCurrencies">
              {{currency}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.currency.hasError('validationErrors')">
            {{ form.controls.currency.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>

        <div class="grid grid-cols-2 gap-2">
          <mat-form-field appearance="outline">
            <mat-label>Service Fees</mat-label>
            <input
              [formControl]="form.controls.serviceFee"
              matInput
              placeholder="0.0"
              type="number"
              min="0"
              step="any">
            <span matSuffix class="font-bold text-lightGray">{{ form.value.currency }}</span>

            <mat-error *ngIf="form.controls.serviceFee.hasError('required')">
              Official fee is required
            </mat-error>

            <mat-error *ngIf="form.controls.serviceFee.hasError('min')">
              Official fee should greater than or equal to {{ form.controls.serviceFee.errors.min.min }}
            </mat-error>

            <mat-error *ngIf="form.controls.serviceFee.hasError('max')">
              Official fee should less than or equal to {{ form.controls.serviceFee.errors.max.max }}
            </mat-error>

            <mat-error *ngIf="form.controls.serviceFee.hasError('validationErrors')">
              {{ form.controls.serviceFee.getError('validationErrors') }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Official fees</mat-label>
            <input
              [formControl]="form.controls.officialFee"
              matInput
              placeholder="0.0"
              type="number"
              step="any">
            <span matSuffix class="font-bold text-lightGray">{{ form.value.currency }}</span>

            <mat-error *ngIf="form.controls.officialFee.hasError('required')">
              Official fee is required
            </mat-error>

            <mat-error *ngIf="form.controls.officialFee.hasError('min')">
              Official fee should greater than or equal to {{ form.controls.officialFee.errors.min.min }}
            </mat-error>

            <mat-error *ngIf="form.controls.officialFee.hasError('max')">
              Official fee should less than or equal to {{ form.controls.officialFee.errors.max.max }}
            </mat-error>

            <mat-error *ngIf="form.controls.officialFee.hasError('validationErrors')">
              {{ form.controls.officialFee.getError('validationErrors') }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Out of pocket expenses</mat-label>
            <input
              [formControl]="form.controls.outOfPocketExpenses"
              matInput
              placeholder="0.0"
              type="number"
              step="any">
            <span matSuffix class="font-bold text-lightGray">{{ form.value.currency }}</span>

            <mat-error *ngIf="form.controls.outOfPocketExpenses.hasError('required')">
              Out of pocket expenses is required
            </mat-error>

            <mat-error *ngIf="form.controls.outOfPocketExpenses.hasError('min')">
              Out of pocket expenses should greater than or equal
              to {{ form.controls.outOfPocketExpenses.errors.min.min }}
            </mat-error>

            <mat-error *ngIf="form.controls.outOfPocketExpenses.hasError('max')">
              Out of pocket expenses should less than or equal to {{ form.controls.outOfPocketExpenses.errors.max.max }}
            </mat-error>

            <mat-error *ngIf="form.controls.outOfPocketExpenses.hasError('validationErrors')">
              {{ form.controls.outOfPocketExpenses.getError('validationErrors') }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="!pt-1" align="center">
    <button
      appConfirmDialog
      (ok)="onCloseDialog()"
      [title]="'Wait a sec!'"
      [confirmMessage]="'Cancelling will erase all the information you\'ve entered so far. Are you absolutely sure you want to proceed?'"
      mat-raised-button
      class="bg-grey-800 text-white w-48">Cancel
    </button>

    <button
      appConfirmDialog
      (ok)="onSubmit()"
      [disabled]="form.invalid || form.pristine || (commonService.loading$ | async)"
      mat-raised-button
      class="w-48"
      color="primary">{{ actionLabel }}
    </button>
  </mat-dialog-actions>
</div>
