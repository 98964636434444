import { Inject, Injectable } from '@angular/core';
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { PaymentProviderNameEnum, PaymentProviderType } from "@generated/graphql";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { PaymentProvider, PaymentProviderOption } from "@modules/payment/models/payment-provider.model";
import { PAYMENT_PROVIDER_STORE, PaymentProviderStore, } from "@modules/payment/store/payment-provider.store";
import { PaymentProviderFactory, paymentProviderFactory } from "@modules/payment/factories/payment-provider.factory";

@Injectable({
  providedIn: 'root'
})
export class PaymentProviderService extends EntityService<EntityFeatureNameEnum.PaymentProvider, PaymentProviderType, PaymentProvider> {
  get activeEntities (): Observable<PaymentProvider[]> {
    return this.entities$.pipe(map(items => items.filter(item => item.isActive)))
  }

  findByName$ (name: PaymentProviderNameEnum): Observable<PaymentProvider> {
    return this.store.pipe(
      select(this.paymentProviderStore.selectByName(name)),
      map(item => item ? this.factory.create(item) : null)
    )
  }

  constructor (
    protected readonly store: Store,
    @Inject(PAYMENT_PROVIDER_STORE) protected readonly paymentProviderStore: PaymentProviderStore
  ) {
    super(store, paymentProviderStore)
  }

  get factory (): PaymentProviderFactory {
    return paymentProviderFactory;
  }

  get paymentProviderOptions$ (): Observable<PaymentProviderOption[]> {
    return this.activeEntities.pipe(
      map(items => items.map(item => ({
        icon: item.icon,
        value: item.name as PaymentProviderNameEnum,
        label: item.label
      })))
    )
  }
}
