import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash-es';

@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  transform (value: string): unknown {
    return value ? startCase(value.toLowerCase()) : '';
  }
}
