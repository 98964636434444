<div class="address-card-wrapper" *ngIf="addressCard$|async as addressCard">
  <mat-card class="mat-elevation-z0 border-0 !py-0">
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row items-center w-full gap-4 mb-2 billing-container">
          <p class="mat-title !text-base text-darkGray !mb-0 break-words billing-name">{{addressCard.fullName}}</p>
          &verbar;
          <p class="mat-title !text-base !mb-0 text-darkGray billing-phone">
            {{addressCard.phone}}
          </p>
        </div>
        <p class="!mb-1 w-[80%] break-words address-info">
          {{addressCard.addressToShow}}
        </p>
      </div>
    </mat-card-content>
    <mat-card-actions
      align="end"
      class="!py-0">
      <button
        *ngIf="config.showEditAction"
        mat-button
        color="accent"
        (click)="onChangeBillingAddress()">Edit
      </button>
    </mat-card-actions>
  </mat-card>
</div>
