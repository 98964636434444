import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage}                                          from "@angular/common";
import {MatCardModule}                                                           from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ShareDirectivesModule} from "@shared/share-directives/share-directives.module";
import {of} from "rxjs";
import {PaymentMethod} from "@modules/payment/models/payment-method.model";

@Component({
  selector: 'credit-card',
  standalone: true,
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
             imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, ShareDirectivesModule, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardComponent implements OnInit {
  @Input() visibleActions$ = of(false);
  @Input() visibleDeleteAction$ = of(false);
  @Input() visibleUpdateAction$ = of(false);
  @Input() visibleSetDefaultAction$ = of(false);
  @Input() loading$ = of(false);
  @Input() paymentMethod: PaymentMethod;
  @Output() update = new EventEmitter<PaymentMethod>();
  @Output() delete = new EventEmitter<PaymentMethod>();
  @Output() setDefault = new EventEmitter<PaymentMethod>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onUpdate() {
    this.update.emit(this.paymentMethod);
  }

  onDelete() {
    this.delete.emit(this.paymentMethod);
  }

  onSetDefault() {
    this.setDefault.emit(this.paymentMethod);
  }
}
