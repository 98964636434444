import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {PhoneValidator} from '@utils/validation/app.validation';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {filter, map, Observable, switchMap, tap} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {AddressTypeEnum, UpdateBillingAddressInput} from '@generated/graphql';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {
  AbstractAddressDialogComponent,
} from '@modules/address/components/dialog/abstract-address-dialog/abstract-address-dialog.component';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import {MatInputModule} from '@angular/material/input';
import {cloneDeep} from 'lodash-es';
import {MatButtonModule} from "@angular/material/button";
import {
  CountrySelectInputComponent
} from "@shared/component/common/country-select-input/country-select-input.component";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {BillingAddressForm} from "@modules/address/components/dialog/address-dialog/address-dialog.component";
import {AddressTypeApi} from "@modules/address/api/address-type.api";
import {AddressType} from "@modules/address/models/address-type.model";
import {MatSelectModule} from "@angular/material/select";
import {LetModule} from "@ngrx/component";

export interface UpdateAddressDialogData {
  address: BillingAddress;
}

export interface UpdateBillingAddressForm extends BillingAddressForm {
  id: FormControl<string>
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatIntlTelInputComponent,
    MatDialogModule,
    CountrySelectInputComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  selector: 'app-update-address-dialog',
  templateUrl: './update-address-dialog.component.html',
  styleUrls: ['./update-address-dialog.component.scss'],
})
export class UpdateAddressDialogComponent extends AbstractAddressDialogComponent<FormGroup<UpdateBillingAddressForm>, UpdateAddressDialogComponent, UpdateAddressDialogData, BillingAddress> implements OnInit {
  get label(): string {
    return 'Update Billing Address';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: UpdateAddressDialogData,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly dialogRef: MatDialogRef<UpdateAddressDialogComponent>,
    protected readonly fb: FormBuilder,
    protected readonly billingAddressApi: BillingAddressApi,
    protected readonly snackBarService: SnackBarService,
    protected readonly addressTypeApi: AddressTypeApi,
  ) {
    super(data, activeUser$, dialogRef, fb, billingAddressApi, snackBarService, addressTypeApi);
  }

  static initializeControl(fb: FormBuilder, address: BillingAddress): FormGroup<UpdateBillingAddressForm> {
    try {
      const form = fb.group({
        id: fb.control(address.id, [Validators.required]),
        name: fb.control('', [Validators.required]),
        firstName: fb.control('', [Validators.required]),
        lastName: fb.control('', [Validators.required]),
        addressTypeId: fb.control('', [Validators.required]),
        address: fb.control('', [Validators.required]),
        phone: fb.control('', [Validators.required, PhoneValidator]),
        isDefault: fb.control(false, [Validators.required]),
        apartment: fb.control(null),
        city: fb.control(null),
        state: fb.control(null),
        zip: fb.control(null),

        countryId: fb.control(address?.country?.id ?? null, Validators.required),

        userId: fb.control(address.userId, [
          Validators.required,
        ]),
      });

      this.onAddressTypeChanged(form, address.addressType)

      return form
    } catch (e) {
      console.error('Cant initialize control! Please check dialog data again!');

      return null;
    }
  }

  ngOnInit(): void {
    super.init();

    this.setAddress(this.data.address);

    this.address$.pipe(
      untilDestroyed(this),
      filter(address => !!address && !!this.control),
      tap(address => {
        this.control.patchValue(cloneDeep(address));

        this._selectedAddressType$.next(address.addressType)
      }),
    ).subscribe();

    this.control.controls.addressTypeId.valueChanges.pipe(
      untilDestroyed(this),
      switchMap(id => this.addressTypes$.pipe(
        map(types => types.find(type => type.id === id))
      )),
      tap(type => {
        this._selectedAddressType$.next(type)

        UpdateAddressDialogComponent.onAddressTypeChanged(this.control, type)
      })
    ).subscribe()
  }

  getControl(userId: string): FormGroup {
    return UpdateAddressDialogComponent.initializeControl(this.fb, this.data.address);
  }

  getQuery$(): Observable<BillingAddress> {
    return this.billingAddressApi.update$(this.control.value as UpdateBillingAddressInput);
  }

  static onAddressTypeChanged(control: FormGroup<UpdateBillingAddressForm>, type: AddressType) {
    control.patchValue({firstName: '', lastName: '', name: ''})

    const controls = control.controls

    switch (type.name) {
      case AddressTypeEnum.Individual: {
        controls.firstName.addValidators(Validators.required)
        controls.lastName.addValidators(Validators.required)
        controls.name.removeValidators(Validators.required)

        break;
      }

      case AddressTypeEnum.Corporate: {
        controls.firstName.removeValidators(Validators.required)
        controls.lastName.removeValidators(Validators.required)
        controls.name.addValidators(Validators.required)

        break;
      }
    }

    controls.firstName.updateValueAndValidity()
    controls.lastName.updateValueAndValidity()
    controls.name.updateValueAndValidity()
  }
}
