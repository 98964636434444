<ng-container *ngIf="shareTransferDetailManagement && shareTransferDetailManagement.form">
  <h1 mat-dialog-title class="mb-2 !text-[2.2rem] text-darkGray text-center">
    <span>Shares Transfer Details</span>
  </h1>

  <mat-dialog-content class="min-h-[10rem]">
    <section class="shares-distribution-container">
      <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
        Shares Transfer Details
      </p>
      <app-shares-allocation *ngIf="shareTransferDetailManagement.form"
        [shareTransferDetailManagement]="shareTransferDetailManagement" addNewButton="false"></app-shares-allocation>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="bg-grey-800 text-white mr-4 w-20" (click)="onClose()">
      Cancel
    </button>

    <button mat-raised-button color="accent" [disabled]="
        shareTransferDetailManagement.form.invalid || (submitting$ | async)
      " (click)="onSubmit()">
      Save
    </button>
  </mat-dialog-actions>
</ng-container>
