import { ModelFactory } from "@shared/factories/model.factory";
import { CompanyShareholderType } from "@generated/graphql";
import { CompanyShareholder } from "@modules/company/models/company-shareholder.model";

export class CompanyShareholderFactory extends ModelFactory<CompanyShareholderType, CompanyShareholder> {
  create (state: CompanyShareholderType): CompanyShareholder {
    return new CompanyShareholder(state);
  }

  fake (): CompanyShareholder {
    throw new Error('This method is not implemented')
  }
}

export const companyShareholderFactory = new CompanyShareholderFactory()
