import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatestWith, iif, map, Observable, of, switchMap, tap } from "rxjs";
import { BusinessPlan } from "@modules/account-settings/pages/business-profile/models/business-plan.model";
import { BusinessPlanApi } from "@modules/account-settings/pages/business-profile/api/business-plan.api";
import { User } from "@modules/user/models/user.model";
import { BusinessProfilePermissionEnum } from "@generated/graphql";
import { PaymentType, PaymentTypeName } from "@modules/payment/models/payment-type.model";
import { ACTIVE_USER } from "@modules/auth/providers/auth.provider";

@Injectable({
  providedIn: 'root'
})
export class BusinessPlanService {
  get businessPlan$ (): Observable<BusinessPlan> {
    return this._businessPlan$.asObservable()
  }

  get isPremiumPlan$ (): Observable<boolean> {
    return this.businessPlan$.pipe(
      map(plan => plan && plan.isPremium())
    )
  }

  set businessPlan (value: BusinessPlan) {
    this._businessPlan$.next(value)
  }

  private readonly _businessPlan$ = new BehaviorSubject<BusinessPlan>(null);

  constructor (
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly businessPlanApi: BusinessPlanApi
  ) {
  }


  getByUser$ (user$: Observable<User>): Observable<BusinessPlan> {
    return user$.pipe(
      switchMap(user => iif(
          () => user && user.hasPermissions(BusinessProfilePermissionEnum.ViewBusinessPlan),
          this.businessPlanApi.first(),
          of(null)
        )
      ),
      tap(plan => {
        this.businessPlan = plan
      }),
    )
  }

  visiblePayment$ (): Observable<boolean> {
    return this.businessPlan$.pipe(
      switchMap(plan => {
        if (!plan) {
          return this.getByUser$(this.activeUser$).pipe(map(value => value && value.isPremium()))
        }

        return of(plan && plan.isPremium())
      })
    )
  }

  visiblePaymentForUser$ (user$: Observable<User>): Observable<boolean> {
    return this.visiblePayment$().pipe(
      combineLatestWith(user$),
      map(([visiblePayment, user]) => user && !user.isExpert() && visiblePayment)
    )
  }

  visiblePrice (paymentType: PaymentTypeName, visiblePayment: boolean = false): boolean {
    return !PaymentType.isContactToClient(paymentType as PaymentTypeName) && visiblePayment;
  }
}
