import {ModelFactory} from "@shared/factories/model.factory";
import {OfficerProfileType} from "@generated/graphql";
import {officerPersonFactory} from "@modules/officer/factories/officer-person.factory";
import {officerCompanyFactory} from "@modules/officer/factories/officer-company.factory";
import {officerIndirectCompanyFactory} from "@modules/officer/factories/officer-indirect-company.factory";
import {officerIndirectPersonFactory} from "@modules/officer/factories/officer-indirect-person.factory";
import {OfficerProfile} from "@modules/officer/models/officer-profile.model";

export class OfficerProfileFactory extends ModelFactory<OfficerProfileType, OfficerProfile> {
  create(state: OfficerProfileType): OfficerProfile {
    switch (state.__typename) {
      case 'OfficerPersonType': {
        return officerPersonFactory.tryCreating(state);
      }
      case 'OfficerCompanyType': {
        return officerCompanyFactory.tryCreating(state)
      }
      case 'OfficerIndirectCompanyType': {
        return officerIndirectCompanyFactory.tryCreating(state)
      }
      case 'OfficerIndirectPersonType': {
        return officerIndirectPersonFactory.tryCreating(state)
      }
    }
  }
}

export const officerProfileFactory = new OfficerProfileFactory()
