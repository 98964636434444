import { ModelFactory } from '@shared/factories/model.factory';
import { CompanyType } from '@generated/graphql';
import { Company } from '@modules/company/models/company.model';

export class CompanyFactory extends ModelFactory<CompanyType, Company> {
  create (state: CompanyType): Company {
    return new Company(state);
  }

  fake (): Company {
    throw new Error('Method not implemented');
  }
}

export const companyFactory = new CompanyFactory();
