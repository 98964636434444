import {BaseModel} from "@shared/models/base.model";
import {AddressTypeType, OrderBillingAddressType} from "@generated/graphql";
import {Country} from "@modules/country/models/country.model";
import {countryFactory} from "@modules/country/factories/country.factory";
import {addressTypeFactory} from "@modules/address/factories/address-type.factory";

export class OrderBillingAddress extends BaseModel implements OrderBillingAddressType {
  id: string;
  address: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  apartment?: string;
  city?: string;
  countryId?: string;
  addressType: AddressTypeType
  fullName: string;
  isDefault: boolean;
  phone: string;
  state?: string;
  userId: string;
  addressTypeId: string;
  zip?: string;
  country: Country

  constructor(state: OrderBillingAddressType) {
    super(state);

    this.country = state.country ? countryFactory.create(state.country) : null

    this.addressType = addressTypeFactory.tryCreating(state.addressType)
  }

  getModelName(): string {
    return OrderBillingAddress.name;
  }

  get addressToShow(): string {
    return [
      this.apartment,
      this.address,
      this.state,
      this.city,
      this.country?.name,
      this.zip
    ].filter(v => !!v).join(', ')
  }
}
