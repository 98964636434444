import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService } from '@app/modules/job/services/job.service';
import { JobType } from '@generated/graphql';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-children-jobs-table',
  templateUrl: './children-jobs-table.component.html',
})
export class ChildrenJobsTableComponent implements OnInit {
  $childrenJobs: Observable<JobType[]> = of([]);
  displayedColumns: string[] = ['matter', 'assignee', 'status'];

  constructor(
    private readonly jobService: JobService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.jobService.activeEntity$.subscribe((job) => {
      const jobId = job.id;

      this.$childrenJobs = this.jobService.childrenJobsQuery(jobId);
    });
  }
}
