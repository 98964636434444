import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { SupportedCountryApi } from '@modules/supported-country/api/supported-country.api';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { SupportedCountryService } from "@modules/supported-country/services/supported-country.service";

@Injectable()
export class SupportedCountryInitializerEffects {
  initSupportedCountries$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => this.supportedCountryApi.get()),
    map(entities => {
      return this.supportedCountryService.entityStore.setEntitiesAction({entities})
    }),
    catchError(e => {
      console.error(e);

      return of(this.supportedCountryService.entityStore.clearEntitiesAction());
    }),
  ));

  constructor (
    protected readonly actions$: Actions,
    protected readonly supportedCountryApi: SupportedCountryApi,
    protected readonly supportedCountryService: SupportedCountryService
  ) {
  }
}
