<ng-container *ngrxLet="activeUser$ as activeUser">
  <div class="file-uploaded-table-wrapper">
    <div class="table-container table-container-limited-scroll mat-elevation-z1">
      <table mat-table class="table-custom" [dataSource]="fileList">
        <!--    File column-->
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef class="!text-white w-1/3">
            File
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="!mb-1 line-clamp-2">
              <a (click)="viewFile(element)" class="cursor-pointer" target="_blank">
                {{ element.metadata.originalName }}
              </a>
            </p>
            <p class="mat-caption !mb-0 text-lightGray truncate">
              {{ element.size | filesize }}
            </p>
          </td>
        </ng-container>

        <!--    Uploaded Date-->
        <ng-container matColumnDef="uploadedDate">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Uploaded Date
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="!mb-1 line-clamp-1 !text-center">
              {{ element.createdAt | date }}
            </p>
          </td>
        </ng-container>

        <!--    Uploaded By-->

        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
            Uploaded By
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="!mb-1 line-clamp-2 !text-center">
              {{
                element.createdBy.profile.firstName +
                " " +
                element.createdBy.profile.lastName
              }}
            </p>
            <p class="mat-caption !mb-0 text-lightGray truncate !text-center">
              {{ element.createdBy.email }}
            </p>
          </td>
        </ng-container>

        <!--    Action-->

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="w-44">&ngsp;</th>
          <td mat-cell *matCellDef="let element">
            <div class="inline-flex justify-end w-full">
              <button mat-icon-button class="text-darkGray opacity-70" (click)="viewFile(element)"
                      [matTooltip]="'Open file'">
                <mat-icon>pageview</mat-icon>
              </button>
              <button *ngIf="activeUser.isCorporateExpert() && companyDocuments"
                      mat-icon-button
                      class="text-darkGray opacity-70"
                      matTooltip="Amend file"
                      (click)="openReplaceDialog(element)">
                <mat-icon>sync</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="downloadFile(element)" matTooltip="Download">
                <mat-icon>file_download</mat-icon>
              </button>
              <button *ngIf="
                  appearance !== 'viewOnly' ||
                  (activeUser.isCorporateExpert() && companyDocuments)"
                      mat-icon-button
                      color="warn"
                      (ok)="deleteHandler(element.id)"
                      appConfirmDialog
                      title="Hold on!"
                      confirmMessage="Are you sure you want to permanently delete this file? This action cannot be undone."
                      matTooltip="Delete">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns" class="bg-primary-800"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns" class="row-hover"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td [colSpan]="tableColumns.length">
            <table-no-record [notFoundMessage]="'No File Uploaded'"></table-no-record>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>
