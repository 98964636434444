<ng-container *ngrxLet="activeUser$ as activeUser">
  <div class="review-incorporation-request-wrapper text-sub !mx-4">
    <ul class="text-sub px-2">
      <li class="list-disc">
        <span class="mr-3">Quantity&colon;</span>
        <span class="mat-body-2 !text-sub capitalize">{{
          jobDetail.jobServiceOrder.mainServices[0].quantity
        }}</span>
      </li>
    </ul>
  </div>
</ng-container>
