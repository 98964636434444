import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { first, Observable, tap } from 'rxjs';
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";
import { OfficerRequiredDocumentService } from "@modules/officer/services/officer-required-document.service";
import { OfficerRequiredDocumentApi } from "@modules/officer/api/officer-required-document.api";

@Injectable({
  providedIn: 'root'
})
export class OfficerRequiredDocumentsResolver implements Resolve<OfficerRequiredDocument[]> {
  constructor (
    protected readonly officerRequiredDocumentService: OfficerRequiredDocumentService,
    protected readonly officerRequiredDocumentApi: OfficerRequiredDocumentApi
  ) {
  }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OfficerRequiredDocument[]> {
    return this.officerRequiredDocumentApi.get$().pipe(
      first(),
      tap(value => this.officerRequiredDocumentService.dispatchSetEntities(value))
    )
  }
}
