import { ModelFactory } from '@shared/factories/model.factory';
import { ServiceType } from '@generated/graphql';
import { faker } from '@faker-js/faker';
import { Service } from '@modules/service/models/service.model';
import { serviceCategoryFactory } from '@modules/service/factories/service-category.factory';

export class ServiceFactory extends ModelFactory<ServiceType, Service> {
  create (state: ServiceType): Service {
    return new Service(state);
  }

  fake (): Service {
    return this.create({
      id: faker.datatype.uuid(),
      name: faker.lorem.word(),
      label: faker.name.jobTitle(),
      serviceCategory: serviceCategoryFactory.fake(),
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
    });
  }
}

export const serviceFactory = new ServiceFactory();
