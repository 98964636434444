import {UserType} from '@generated/graphql';
import {Role} from '@app/modules/role/models/role.model';
import {Profile} from '@app/modules/profile/models/profile.model';
import {BaseModel} from '@shared/models/base.model';
import {profileFactory} from '@modules/profile/factories/profile.factory';
import {roleFactory} from '@modules/role/factories/role.factory';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {billingAddressFactory} from '@modules/address/factories/billing-address.factory';
import {AppPermissionEnum} from "@modules/permission/models/permission.model";
import {AppStatusType} from "@shared/component/common/status/status.component";

export class User extends BaseModel implements UserType {
  id: string;
  email: string;
  profile: Profile;
  roles: Role[];
  lastTimePasswordChanged?: Date;
  isActive: boolean
  billingAddresses: BillingAddress[];
  canDeactivate: boolean;
  canReactivate: boolean;
  canUpdate: boolean
  canImpersonate: boolean
  emailVerifiedAt: Date;
  password?: string;
  canCreateShortUrl: boolean;

  constructor(state: UserType) {
    super(state);

    this.profile = state.profile ? profileFactory.create(state.profile) : null;

    this.roles = state.roles ? state.roles.map(role => roleFactory.create(role)) : [];

    this.billingAddresses = state.billingAddresses ? state.billingAddresses.map(address => billingAddressFactory.create(address)) : [];
  }

  get renderStatusLabel(): string {
    if (!this.emailVerifiedAt) {
      return 'In-active'
    }

    return this.isActive ? 'Active' : 'Deactivated';
  }

  get renderStatusStyle(): AppStatusType {
    if (!this.emailVerifiedAt) {
      return 'warn'
    }

    return this.isActive ? 'success' : 'cancelled';
  }

  getModelName(): string {
    return User.name;
  }

  isCorporateExpert(): boolean {
    return this.isSuperAdmin() || this.isAdmin() || this.isExpert()
  }

  isAdmin(): boolean {
    return this.roles.some(role => role.isAdmin());
  }

  isSuperAdmin(): boolean {
    return this.roles.some(role => role.isSuperAdmin());
  }

  isExpert(): boolean {
    return this.roles.some(role => role.isExpert());
  }

  isCustomer(): boolean {
    return this.roles.some(role => role.isCustomer());
  }

  hasPermissions(permissions: AppPermissionEnum | AppPermissionEnum[]): boolean {
    /**
     * TODO update when user is only assigned permissions
     */

    if (this.roles) {
      return this.roles.some(role => role.hasPermissions(permissions))
    }

    return false
  }

  getDefaultBillingAddress(): BillingAddress {
    return this.billingAddresses.find(address => address.isDefault) || this.billingAddresses[0];
  }

  getFullName(): string {
    return [this.profile.firstName, this.profile.lastName].join(' ')
  }
}
