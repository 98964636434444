import { ModelFactory } from "@shared/factories/model.factory";
import { CompanyAuditorType } from "@generated/graphql";
import { CompanyAuditor } from "@modules/company/models/company-auditor.model";

export class CompanyAuditorFactory extends ModelFactory<CompanyAuditorType, CompanyAuditor> {
  create (state: CompanyAuditorType): CompanyAuditor {
    return new CompanyAuditor(state);
  }

  fake (): CompanyAuditor {
    throw new Error('This method is not implemented')
  }
}

export const companyAuditorFactory = new CompanyAuditorFactory()
