import {Pipe, PipeTransform} from '@angular/core';
import {File} from "@modules/file/models/file.model";

@Pipe({
  name: 'fileExplorerIcon'
})
export class FileExplorerIconPipe implements PipeTransform {
  transform(value: File): unknown {
    const defaultIcon = 'attach_file'

    const {originalMimeType} = value.metadata

    if (value.isDirectory) {
      return 'folder'
    }

    return File.mimeTypesWithIcon.find(item => item.types.includes(originalMimeType))?.icon ?? defaultIcon
  }
}
