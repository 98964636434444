import { ModelFactory } from "@shared/factories/model.factory";
import { FileType } from "@generated/graphql";
import { File } from "@modules/file/models/file.model";

export class FileFactory extends ModelFactory<FileType, File> {
  create (state: FileType): File {
    return new File(state);
  }

  fake (): File {
    throw new Error('Method not implemented');
  }
}

export const fileFactory = new FileFactory()
