import { Injectable } from '@angular/core';
import { AddContract } from '@shared/contracts/add.contract';
import {
  AddMyBillingAddressMutation,
  BillingAddressInput,
  BillingAddressType,
  DeleteMyBillingAddressMutation,
  UpdateMyBillingAddressMutation,
  UpdateBillingAddressInput, MyBillingAddressesQuery,
} from '@generated/graphql';
import { BillingAddress } from '@modules/address/models/billing-address.model';
import { map, Observable } from 'rxjs';
import { billingAddressFactory } from '@modules/address/factories/billing-address.factory';
import { UpdateContract } from '@shared/contracts/update.contract';
import { DeleteContract } from '@shared/contracts/delete.contract';

@Injectable({
  providedIn: 'root',
})
export class BillingAddressApi implements AddContract<BillingAddressInput, Observable<BillingAddress>>,
  UpdateContract<UpdateBillingAddressInput, Observable<BillingAddress>>,
  DeleteContract<string, Observable<boolean>> {
  constructor (
    protected readonly myBillingAddressesQuery: MyBillingAddressesQuery,
    protected readonly addMyBillingAddressMutation: AddMyBillingAddressMutation,
    protected readonly updateMyBillingAddressMutation: UpdateMyBillingAddressMutation,
    protected readonly deleteMyBillingAddressMutation: DeleteMyBillingAddressMutation,
  ) {
  }

  add$ (input: BillingAddressInput): Observable<BillingAddress> {
    return this.addMyBillingAddressMutation.mutate({ input })
      .pipe(
        map(response => response.data.addMyBillingAddress ? billingAddressFactory.create(response.data.addMyBillingAddress as BillingAddressType) : null),
      );
  }

  update$ (input: UpdateBillingAddressInput): Observable<BillingAddress> {
    return this.updateMyBillingAddressMutation.mutate({ input })
      .pipe(
        map(response => response.data.updateMyBillingAddress ? billingAddressFactory.create(response.data.updateMyBillingAddress as BillingAddressType) : null),
      );
  }

  delete$ (id: string): Observable<boolean> {
    return this.deleteMyBillingAddressMutation.mutate({ id })
      .pipe(
        map(response => response.data.deleteMyBillingAddress),
      );
  }

  getMyBillingAddresses (): Observable<BillingAddress[]> {
    return this.myBillingAddressesQuery.watch(null, {
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map(res => res.data.myBillingAddresses ? res.data.myBillingAddresses.map(address => billingAddressFactory.create(address as BillingAddressType)) : []),
    );
  }
}
