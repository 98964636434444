import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ExtraJob}                                                             from "@modules/extra-job/models/extra-job.model";
import {
    BusinessPlanService
}                                                                             from "@modules/account-settings/pages/business-profile/services/business-plan.service";
import {
    addressCardComponentConfig, AddressCardComponentConfig, AddressCardInput
}                                                                             from "@shared/component/common/address-card/address-card.component";
import {BehaviorSubject}                                                      from "rxjs";
import {CommonHelper}                                                         from "@shared/helpers/common.helper";

@Component({
               selector: 'app-extra-job[extraJob]',
               templateUrl: './extra-job.component.html',
               styleUrls: ['./extra-job.component.scss'],
               changeDetection: ChangeDetectionStrategy.OnPush,
               encapsulation: ViewEncapsulation.None,
           })
export class ExtraJobComponent implements OnInit {
    @Input() extraJob: ExtraJob;

    addressCardComponentConfig: AddressCardComponentConfig = {
        ...addressCardComponentConfig,
        showEditAction: false
    };

    private _address$ = new BehaviorSubject<AddressCardInput>(null);

    get address$() {
        return this._address$.asObservable();
    }

    get visiblePayment$() {
        return this.businessPlanService.visiblePayment$();
    }

    constructor(
        protected readonly commonHelper: CommonHelper,
        private readonly businessPlanService: BusinessPlanService
    ) {
    }

    ngOnInit(): void {
        const orderDetail = this.extraJob?.order?.orderDetail;

        this._address$.next(orderDetail?.billingDetails || orderDetail?.address);
    }
}
