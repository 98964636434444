import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyCapitalFormType } from '@app/modules/company/models/form/company-capital-form-type';
import { CompanyCapitalInput } from '@generated/graphql';
import { ShareTypeDisabledManagement } from '@shared/component/job/component/company-incorporation/tables/registered-capital/share-type-disabled.class';

export class CompanyCapitalForm {
  private fb: FormBuilder = new FormBuilder();

  private _form = this.fb.array<FormGroup<CompanyCapitalFormType>>(
    [],
    Validators.required,
  );
  get form() {
    return this._form;
  }

  shareTypeDisabled: ShareTypeDisabledManagement =
    new ShareTypeDisabledManagement();

  constructor() {}

  private createCapitalItem() {
    return this.fb.group<CompanyCapitalFormType>({
      totalAmount: this.fb.control(0, [Validators.required, Validators.min(1)]),
      totalShares: this.fb.control(0, [Validators.required, Validators.min(1)]),
      currency: this.fb.control('', Validators.required),
      shareTypeId: this.fb.control({ value: '', disabled: true }, [
        Validators.required,
      ]),
    });
  }

  addNewCapital() {
    this._form.push(this.createCapitalItem());
    this.shareTypeDisabled.createItem(this._form.length - 1);
  }

  deleteCapital(i: number) {
    this._form.removeAt(i);
    this.shareTypeDisabled.removeAt(i);
  }

  init() {
    if (this._form.controls.length === 0) {
      this.addNewCapital();
    }
  }

  setDataFromInput(input: CompanyCapitalInput[]) {
    this._form.clear();
    input.forEach((data) => {
      this._form.push(
        this.fb.group<CompanyCapitalFormType>({
          totalAmount: this.fb.control(data.totalAmount, [
            Validators.required,
            Validators.min(1),
          ]),
          totalShares: this.fb.control(data.totalShares, [
            Validators.required,
            Validators.min(1),
          ]),
          currency: this.fb.control(data.currency, Validators.required),
          shareTypeId: this.fb.control(
            { value: data.shareTypeId, disabled: true },
            [Validators.required],
          ),
        }),
      );
    });
  }
}
