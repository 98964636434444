import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  public get alertTitle(): string {
    return this.data.title ?? this.getDefaultTitle();
  }

  public get alertMessage(): string {
    return this.data.message ?? '';
  }

  public closeSnackbar() {
    this.data.snackBar.dismiss();
  }

  public get alertIcon(): string {
    switch (this.data.snackBarType) {
      case SnackBarTypeEnum.default: {
        return 'info'
      }
      case SnackBarTypeEnum.error: {
        return 'report';
      }
      case SnackBarTypeEnum.success: {
        return 'check_circle'
      }
      case SnackBarTypeEnum.warning: {
        return 'warning'
      }
      default: {
        return 'info'
      }
    }
  }

  public getDefaultTitle(): string {
    switch (this.data.snackBarType) {
      case SnackBarTypeEnum.default: {
        return 'Info'
      }
      case SnackBarTypeEnum.error: {
        return 'Error';
      }
      case SnackBarTypeEnum.success: {
        return 'Success'
      }
      case SnackBarTypeEnum.warning: {
        return 'Warning'
      }
      default: {
        return 'Info'
      }
    }
  }
}
