<mat-list *ngIf="profile$ | async" class="side-menu-inner">
  <div mat-subheader>Contact Us</div>
  <a *ngFor="let externalLink of (profile$ | async)?.externalLinks" mat-list-item
    class="menu-item-wrapper cursor-pointer !text-darkGray" [href]="externalLink.content" target="_blank">

    <mat-icon *ngIf="externalLink.type === type.Call" mat-list-icon [matTooltipDisabled]="isOpenSidebar"
      [matTooltip]="externalLink.label" matTooltipPosition="right">duo
    </mat-icon>
    <mat-icon *ngIf="externalLink.type === type.Chat" mat-list-icon [matTooltipDisabled]="isOpenSidebar"
      [matTooltip]="externalLink.label" matTooltipPosition="right">question_answer
    </mat-icon>
    <mat-icon *ngIf="externalLink.type === type.Other" mat-list-icon [matTooltipDisabled]="isOpenSidebar"
      [matTooltip]="externalLink.label" matTooltipPosition="right">public
    </mat-icon>

    <div mat-line>{{ externalLink.label }}</div>
  </a>
</mat-list>
