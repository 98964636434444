<ng-container>
  <section class="mb-4">
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Director will be replaced
    </p>
    <app-officer-input-review-information
      [officerType]="'CompanyDirectorType'"
      [formType]="getOfficerType()"
      [officerInput]="getCurrentOfficerInputValue()"
    ></app-officer-input-review-information>
  </section>
  <section>
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Replaced by
    </p>
    <app-officer-input-review-information
      [officerType]="'CompanyDirectorType'"
      [formType]="getOfficerType()"
      [officerInput]="getNewOfficerInputValue()"
    ></app-officer-input-review-information>
  </section>
</ng-container>
