import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import {
  OfficerIdentificationTypeType,
  OfficerTypeEnum,
  SupportedCountryType,
} from '@generated/graphql';
import { SelectOption } from '@shared/helpers/convention.helpers';
import { LookupOfficerDialogManagement } from './classes/lookup-officer-dialog-management.class';
import { MatRadioChange } from '@angular/material/radio';
import { OfficerService } from '@app/modules/officer/services/officer.service';
import { map, Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filterIdentificationTypesByCountryCode } from '@shared/helpers/identification.helpers';

type LookupOfficersDialogData = {
  countryOfIncorporation: SupportedCountryType;
  hideCorporate: boolean;
  exceptOfficerIds?: string[];
};

@Component({
  selector: 'app-lookup-officer-dialog',
  templateUrl: './lookup-officer-dialog.component.html',
  styleUrls: ['./lookup-officer-dialog.component.scss'],
})
export class LookupOfficerDialogComponent implements OnInit {
  readonly officerType = OfficerTypeEnum;

  lookupOfficerDialogManagement = new LookupOfficerDialogManagement();

  officerTypeOption: SelectOption[] = [
    {
      label: OfficerTypeEnum.Individual.toString().toLowerCase(),
      value: OfficerTypeEnum.Individual,
    },
    {
      label: OfficerTypeEnum.Corporate.toString().toLowerCase(),
      value: OfficerTypeEnum.Corporate,
    },
  ];

  officerIdentificationTypes$: Observable<OfficerIdentificationTypeType[]>;

  officerPeopleDisplayedColumns = [
    'name',
    'nationality',
    'identificationType',
    'identificationNumber',
    'actions',
  ];

  officerCompanyDisplayedColumns = [
    'name',
    'registrationNumber',
    'country',
    'actions',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LookupOfficersDialogData,
    private officerService: OfficerService,
    public dialogRef: DialogRef<LookupOfficerDialogComponent>,
  ) {
    if (this.data.hideCorporate) {
      this.officerTypeOption = this.officerTypeOption.filter(
        (o) => o.value !== OfficerTypeEnum.Corporate,
      );
    }

    if (this.data.exceptOfficerIds) {
      this.lookupOfficerDialogManagement.exceptOfficerIds =
        this.data.exceptOfficerIds;
    }
  }

  ngOnInit(): void {
    this.officerIdentificationTypes$ = this.officerService
      .getIdentificationType()
      .pipe(
        map((result) => {
          return filterIdentificationTypesByCountryCode(
            result,
            this.data.countryOfIncorporation.country.code,
          );
        }),
      );

    this.lookupOfficerDialogManagement.countryOfIncorporation =
      this.data.countryOfIncorporation;

    this.lookupOfficerDialogManagement.officerService = this.officerService;

    this.lookupOfficerDialogManagement.createForm();
  }

  onSelectionChange(event: MatRadioChange) {
    this.lookupOfficerDialogManagement.changeType(event.value);
  }

  lookupHandler() {
    this.lookupOfficerDialogManagement.startLookup();
  }

  retreiveHandler(index: number) {
    return {
      officer: this.lookupOfficerDialogManagement.retreiveOfficer(index),
      type: this.lookupOfficerDialogManagement.type,
    };
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
