import { ModelFactory } from '@shared/factories/model.factory';
import { CountryType } from '@generated/graphql';
import { Country } from '@modules/country/models/country.model';
import { faker } from '@faker-js/faker';

export class CountryFactory extends ModelFactory<CountryType, Country> {
  create (state: CountryType): Country {
    return new Country(state);
  }

  fake (): Country {
    return this.create({
      id: faker.datatype.uuid(),
      name: faker.address.country(),
      code: faker.address.countryCode(),
    });
  }
}

export const countryFactory = new CountryFactory();
