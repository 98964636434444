import { UserType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState,
} from "@shared/contracts/entity-store.contract";
import { User } from "@modules/user/models/user.model";

export class CustomerStore extends EntityStore<EntityFeatureNameEnum.Customer, UserType, User> {
  get selectState (): SelectState<UserType> {
    return this.feature.selectCustomerFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Customer> {
    return 'CUSTOMER';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Customer> {
    return 'customerFeature';
  }
}

export const customerStore = new CustomerStore()
export const CUSTOMER_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.CustomerStore)
export const CUSTOMER_STORE_CONFIG: Provider = {
  provide: CUSTOMER_STORE,
  useValue: customerStore,
}
