import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthState } from '@modules/auth/store/reducers/auth.reducer';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    'Set auth': props<{auth: AuthState}>(),
    'Reset auth': emptyProps(),
  },
});
