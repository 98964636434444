import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyCapitalInput, ShareTypeType } from '@generated/graphql';
import { Company } from '@app/modules/company/models/company.model';
import { ShareTypeService } from '@app/modules/share-type/services/share-type.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

type CapitalData = {
  currency: string;
  shareTypes: {
    shareType: string;
    details: {
      description: string;
      beforeCapitalIncrease: number;
      afterCapitalIncrease: number;
    }[];
  }[];
};

@UntilDestroy()
@Component({
  selector: 'app-capital-input-summarize',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './capital-input-summarize.component.html',
  styleUrls: ['./capital-input-summarize.component.scss'],
})
export class CapitalInputSummarizeComponent implements OnInit {
  @Input() company: Company;

  private _newCapitals: CompanyCapitalInput[];
  @Input() set newCapitals(val: CompanyCapitalInput[]) {
    this._newCapitals = val;

    this.setupChangedCapitalData();
  }
  public get newCapitals() {
    return this._newCapitals;
  }

  public displayedColumns: string[] = [
    'shareType',
    'details',
    'before',
    'after',
  ];

  public capitalData: CapitalData[] = [];
  public capitalListData$ = new BehaviorSubject<CapitalData[]>([]);

  public shareTypes: ShareTypeType[];

  constructor(private _shareTypeService: ShareTypeService) { }

  ngOnInit(): void { }

  private initializeCapitalData() {
    this.capitalData = [];

    const companyCapitals = this.company.companyCapitals;

    const initializeCurrencies = uniq(companyCapitals.map((c) => c.currency));

    initializeCurrencies.forEach((currency) => {
      const newItem: CapitalData = {
        currency,
        shareTypes: [],
      };

      companyCapitals.forEach((capital) => {
        if (currency === capital.currency) {
          newItem.shareTypes.push({
            shareType: capital.shareType.name,
            details: [
              {
                description: 'Amount of paid up capital',
                beforeCapitalIncrease: capital.totalAmount,
                afterCapitalIncrease: 0,
              },
              {
                description: 'Number of shares',
                beforeCapitalIncrease: capital.totalShares,
                afterCapitalIncrease: 0,
              },
            ],
          });
        }
      });

      this.capitalData.push(newItem);
    });
  }

  private setupChangedCapitalData() {
    this._shareTypeService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((shareTypes) => {
        this.shareTypes = shareTypes;

        this.initializeCapitalData();

        this.newCapitals.forEach((newCapital) => {
          if (newCapital.currency && newCapital.shareTypeId) {
            const newShareTypeName = this.getShareTypeName(
              newCapital.shareTypeId,
            );

            const ignoreSharetypes: string[] = [];

            const data = this.capitalData.find(
              (cd) => cd.currency === newCapital.currency,
            );

            if (data) {
              if (data.currency === newCapital.currency) {
                let needNewShareType = false;

                data.shareTypes.forEach((shareType) => {
                  if (shareType.shareType === newShareTypeName) {
                    shareType.details[0].afterCapitalIncrease =
                      shareType.details[0].beforeCapitalIncrease +
                      newCapital.totalAmount;

                    shareType.details[1].afterCapitalIncrease =
                      shareType.details[1].beforeCapitalIncrease +
                      newCapital.totalShares;

                    needNewShareType = false;

                    ignoreSharetypes.push(newShareTypeName);

                    return;
                  } else {
                    needNewShareType = true;
                  }
                });

                if (
                  needNewShareType &&
                  !ignoreSharetypes.includes(newShareTypeName)
                ) {
                  data.shareTypes.push({
                    shareType: newShareTypeName,
                    details: [
                      {
                        description: 'Amount of paid up capital',
                        beforeCapitalIncrease: 0,
                        afterCapitalIncrease: newCapital.totalAmount,
                      },
                      {
                        description: 'Number of shares',
                        beforeCapitalIncrease: 0,
                        afterCapitalIncrease: newCapital.totalShares,
                      },
                    ],
                  });
                }
              }
            } else {
              // new currency
              this.capitalData.push({
                currency: newCapital.currency,
                shareTypes: [
                  {
                    shareType: newShareTypeName,
                    details: [
                      {
                        description: 'Amount of paid up capital',
                        beforeCapitalIncrease: 0,
                        afterCapitalIncrease: newCapital.totalAmount,
                      },
                      {
                        description: 'Number of shares',
                        beforeCapitalIncrease: 0,
                        afterCapitalIncrease: newCapital.totalShares,
                      },
                    ],
                  },
                ],
              });
            }
          }
        });

        this.capitalData.forEach((cd) => {
          cd.shareTypes = cd.shareTypes.filter((st) => {
            return (
              st.details.filter((d) => {
                if (d.afterCapitalIncrease !== 0) {
                  return true;
                }
                return false;
              }).length > 0
            );
          });
        });

        this.capitalListData$.next(
          this.capitalData.filter((cd) => cd.shareTypes.length > 0),
        );
      });
  }

  private getShareTypeName(id: string): string {
    return this.shareTypes.find((st) => st.id === id).name;
  }
}
