import { InjectionToken, Provider } from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';
import { Observable } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import { UserType } from '@generated/graphql';

export const ACTIVE_USER = new InjectionToken<string>('ACTIVE_USER');

export const ACTIVE_USER_CONFIG: Provider = {
  provide: ACTIVE_USER,
  useFactory (authService: AuthService): Observable<User> {
    return authService.activeUser$;
  },
  deps: [AuthService],
};

export const IMPERSONATING_USER = new InjectionToken<string>('IMPERSONATING_USER');

export const IMPERSONATING_USER_CONFIG: Provider = {
  provide: IMPERSONATING_USER,
  useFactory (authService: AuthService): Observable<UserType> {
    return authService.impersonatingUser$;
  },
  deps: [AuthService],
};
