import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { login } from '@modules/auth/store/actions/login.actions';
import { select, Store } from '@ngrx/store';
import { LocalStorageService } from '@shared/services/local-storage.service';
import {
  selectAuthState,
  selectImpersonatingUser,
  selectIsRegisterSuccess,
  selectIsResendVerificationEmailSuccess,
  selectIsResetPasswordError,
  selectIsResetPasswordSuccess,
  selectIsSendResetPasswordsLinkError,
  selectIsSendResetPasswordsLinkSuccess,
  selectIsVerifyEmailError,
  selectIsVerifyEmailSuccess,
  selectLoginErrorMessages,
  selectLoginLoading,
  selectMustVerifyEmail,
  selectRegisterError,
  selectRegisterLoading,
  selectResendVerificationEmailLoading,
  selectResetPasswordErrorMessages,
  selectResetPasswordLoading,
  selectSendResetPasswordsLinkError,
  selectSendResetPasswordsLinkLoading,
  selectUser,
  selectVerifyEmailLoading,
} from '@modules/auth/store/features/auth.feature';
import {
  authFeatureName,
  AuthState,
} from '@modules/auth/store/reducers/auth.reducer';
import { userFactory } from '@modules/user/factories/user.factory';
import { User } from '@modules/user/models/user.model';
import {
  LoginInput,
  MeQuery,
  RegisterInput,
  RegisterVariables,
  ResetPasswordInput,
  SendResetPasswordLinkVariables,
  UserType,
  VerifyEmailInput,
} from '@generated/graphql';
import { logout } from '@modules/auth/store/actions/logout.actions';
import { registerActions } from '@modules/auth/store/actions/register.actions';
import { resendEmailVerificationActions } from '@modules/auth/store/actions/resend-email-verification.actions';
import { verifyEmailActions } from '@modules/auth/store/actions/verify-email.actions';
import { resettingPasswordActions } from '@modules/auth/store/actions/resetting-password.actions';
import { ValidationError } from '@utils/validation/app.validation';
import { impersonateActions } from '@modules/auth/store/actions/impersonate.actions';
import { authActions } from '@modules/auth/store/actions/auth.actions';
import { authUserActions } from '@modules/auth/store/actions/auth-user.actions';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  returnUrl: string;

  readonly auth$: Observable<AuthState> = this.store.pipe(
    select(selectAuthState),
  );
  readonly authUser$: Observable<UserType> = this.store.pipe(
    select(selectUser),
  );
  readonly impersonatingUser$: Observable<UserType> = this.store.pipe(
    select(selectImpersonatingUser),
  );
  readonly loginErrorMessages$: Observable<string[]> = this.store.pipe(
    select(selectLoginErrorMessages),
  );
  readonly registerError$: Observable<ValidationError<RegisterVariables>> =
    this.store.pipe(select(selectRegisterError));
  readonly mustVerifyEmail$: Observable<boolean> = this.store.pipe(
    select(selectMustVerifyEmail),
  );
  readonly loginLoading$: Observable<boolean> = this.store.pipe(
    select(selectLoginLoading),
  );
  readonly resendVerificationEmailLoading$: Observable<boolean> =
    this.store.pipe(select(selectResendVerificationEmailLoading));
  readonly registerLoading$: Observable<boolean> = this.store.pipe(
    select(selectRegisterLoading),
  );
  readonly isRegisterSuccess$: Observable<boolean> = this.store.pipe(
    select(selectIsRegisterSuccess),
  );
  readonly isResendVerificationEmailSuccess$: Observable<boolean> =
    this.store.pipe(select(selectIsResendVerificationEmailSuccess));
  readonly verifyEmailLoading$: Observable<boolean> = this.store.pipe(
    select(selectVerifyEmailLoading),
  );
  readonly isVerifyEmailSuccess$: Observable<boolean> = this.store.pipe(
    select(selectIsVerifyEmailSuccess),
  );
  readonly isVerifyEmailError$: Observable<boolean> = this.store.pipe(
    select(selectIsVerifyEmailError),
  );
  readonly sendResetPasswordsLinkLoading$: Observable<boolean> =
    this.store.pipe(select(selectSendResetPasswordsLinkLoading));
  readonly isSendResetPasswordsLinkSuccess$: Observable<boolean> =
    this.store.pipe(select(selectIsSendResetPasswordsLinkSuccess));
  readonly isSendResetPasswordsLinkError$: Observable<boolean> =
    this.store.pipe(select(selectIsSendResetPasswordsLinkError));
  readonly sendResetPasswordsLinkError$: Observable<ValidationError<SendResetPasswordLinkVariables>> = this.store.pipe(select(selectSendResetPasswordsLinkError));
  readonly resetPasswordLoading$: Observable<boolean> = this.store.pipe(
    select(selectResetPasswordLoading),
  );
  readonly isResetPasswordSuccess$: Observable<boolean> = this.store.pipe(
    select(selectIsResetPasswordSuccess),
  );
  readonly isResetPasswordError$: Observable<boolean> = this.store.pipe(
    select(selectIsResetPasswordError),
  );
  readonly resetPasswordErrorMessages$: Observable<string[]> = this.store.pipe(
    select(selectResetPasswordErrorMessages),
  );

  get activeUser$ (): Observable<User> {
    return this.impersonatingUser$.pipe(
      switchMap(impersonateUser =>
        impersonateUser ? of(impersonateUser) : this.authUser$,
      ),
      map(user => user ? userFactory.create(user) : null),
    );
  }

  constructor (
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
    private readonly _fetchMe: MeQuery,
  ) {
  }

  impersonate (id: string): void {
    this.store.dispatch(impersonateActions.impersonateUser({id}));
  }

  reloadActiveUser$ (): Observable<UserType> {
    return this.fetchMe()
      .pipe(
        filter(user => !!user),
        switchMap(user => this.setActiveUser$(user)),
      );
  }

  setActiveUser$ (user: UserType): Observable<UserType> {
    /**
     * Carefully, It will make recursive if using without take(1)
     */
    return this.impersonatingUser$.pipe(take(1)).pipe(
      switchMap(impersonatingUser => {
        if (impersonatingUser) {
          this.store.dispatch(impersonateActions.setImpersonateUser({user}));
        } else {
          this.store.dispatch(authUserActions.setAuthUser({user}));
        }

        return this.activeUser$;
      }),
    );
  }

  updateActiveUser$ (value: Partial<UserType>): Observable<UserType> {
    return this.activeUser$.pipe(
      take(1),
      filter(user => !!user),
      switchMap(user => {
        const updatedUser = {...cloneDeep(user), ...value} as UserType;

        return this.setActiveUser$(updatedUser);
      }),
    );
  }

  updateActiveUser (value: Partial<UserType>): void {
    try {
      this.updateActiveUser$(value).subscribe();
    } catch (e) {
      console.error(e);
    }
  }

  stopImpersonate (): void {
    this.store.dispatch(impersonateActions.stopImpersonate());
  }

  login (input: LoginInput): void {
    this.store.dispatch(login({input}));
  }

  register (input: RegisterInput): void {
    this.store.dispatch(registerActions.register({input}));
  }

  refreshRegisterState (): void {
    this.store.dispatch(registerActions.refreshState());
  }

  logout (): void {
    this.store.dispatch(logout());
  }

  resetAuth (): void {
    this.store.dispatch(authActions.resetAuth());
  }

  verifyEmail (input: VerifyEmailInput): void {
    this.store.dispatch(verifyEmailActions.verifyEmail({input}));
  }

  resendEmailVerification (email: string): void {
    this.store.dispatch(
      resendEmailVerificationActions.resendVerificationEmail({
        emailOrId: email,
      }),
    );
  }

  sendResetPasswordsLink (email: string): void {
    this.store.dispatch(resettingPasswordActions.sendLink({email}));
  }

  resetPassword (input: ResetPasswordInput): void {
    this.store.dispatch(resettingPasswordActions.resetPassword({input}));
  }

  refreshResettingPasswordState (): void {
    this.store.dispatch(resettingPasswordActions.refreshState());
  }

  async getAuthFromLocalStorage (): Promise<AuthState> {
    return await this.localStorageService.getData<AuthState>(authFeatureName);
  }

  async setAuthToLocalStorage (auth: AuthState): Promise<AuthService> {
    await this.localStorageService.saveData(authFeatureName, auth);

    return this;
  }

  async resetAuthFromLocalStorage (): Promise<AuthService> {
    await this.localStorageService.removeData(authFeatureName);

    return this;
  }

  fetchMe (): Observable<UserType> {
    return this._fetchMe
      .fetch(null, {
        fetchPolicy: 'no-cache',
      }).pipe(map(({data}) => data.me as UserType));
  }
}
