import { OfficerIdentificationTypeType } from '@generated/graphql';

export const OTHER_IDENTIFICATION_TYPE_FILTER = ['PASSPORT', 'ID'];

export const filterIdentificationTypesByCountryCode = (
  data: OfficerIdentificationTypeType[],
  countryCode: string,
): OfficerIdentificationTypeType[] => {
  let result: OfficerIdentificationTypeType[] = [];

  if (countryCode.toUpperCase() === 'SG') {
    result = data.filter((t) => t.name.toUpperCase() !== 'ID');
  } else {
    result = data.filter((t) =>
      OTHER_IDENTIFICATION_TYPE_FILTER.includes(t.name.toUpperCase()),
    );
  }

  return result;
};

export const checkIdentificationTypeValidByCountry = (
  typeName: string,
  countryCode: string,
): boolean => {
  if (countryCode.toUpperCase() === 'SG') {
    return typeName !== 'ID';
  }

  return OTHER_IDENTIFICATION_TYPE_FILTER.includes(typeName.toUpperCase());
};
