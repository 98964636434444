export enum ConversationMessageTypeEnum {
  Text = 'TEXT',
  File = 'FILE',
  Link = 'LINK',
}

export enum MessageActionEnum {
  Reply = 'REPLY',
  Edit = 'EDIT',
  Download = 'DOWNLOAD',
}

export interface ConversationMetadata {
  lastMessageId?: string;
}

export interface ConversationMember {
  id?: string;
  displayName?: string;
  avatarUrl?: string;
  roles?: string[];
  lastReadMessageId?: string;
  unreadCount?: number;
}

export interface ReplyMessage {
  replied?: {
    messageId?: string;
    body?: string;
    displayName?: string;
    repliedAt?: Date;
  };
}

export interface ReplyMessageInput {
  replied?: {
    messageId?: string;
    body?: string;
    displayName?: string;
    repliedAt?: number;
  };
}

export interface FileMessage {
  file?: {
    fileSize?: number;
    fileId?: string;
  };
}

export interface ConversationMessage extends ReplyMessage, FileMessage {
  id?: string;
  isMe?: boolean;
  type?: ConversationMessageTypeEnum;
  body?: string;
  sender?: ConversationMember;
  avatarVisible?: boolean;
  nameVisible?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface ConversationMessageInput
  extends ReplyMessageInput,
  FileMessage {
  id?: string;
  type?: ConversationMessageTypeEnum;
  body?: string;
  senderId?: string;
  createdAt?: number;
  updatedAt?: number;
  deletedAt?: number;
}

export interface GroupMessages {
  [key: string]: ConversationMessage[];
}
