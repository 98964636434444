import { FormArray, FormGroup, Validators } from '@angular/forms';
import { CompanyCapitalFormType } from '@app/modules/company/models/form/company-capital-form-type';
import { DirectorFormType } from '@app/modules/job/models/form/director-form-type';
import { ShareholderFormType } from '@app/modules/job/models/form/shareholder-form-type';
import { CompanyShareholderInput, OfficerTypeEnum } from '@generated/graphql';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { OfficerCorporateForm } from '@shared/classes/officer/officer-corporate-form.class';
import { OfficerIndividualForm } from '@shared/classes/officer/officer-individual-form.class';
import { CompanyOfficerForm } from './company-officer-form.class';

export class CompanyShareholderForm extends CompanyOfficerForm {
  protected _form: FormArray<FormGroup<ShareholderFormType>>;

  valid = false;

  allocateValid = false;

  constructor(
    private _companyCapitals: FormArray<FormGroup<CompanyCapitalFormType>>,
  ) {
    super();
    this._form = this.fb.array<FormGroup<ShareholderFormType>>(
      [],
      Validators.required,
    );
  }

  addShareholder(officerForm: AbstractOfficerForm, refId: string) {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    officerForm.officerForm.enable();

    let id = `temporary-${Date.now()}`;

    if (refId && !refId.includes('temporary-')) {
      id = refId;
    }

    this._form.push(
      this.fb.group<ShareholderFormType>({
        id: this.fb.control(id),
        officerType: this.fb.control(officerType),
        isNominee: this.fb.control(false),
        [officerName]: officerForm.officerForm,
        shareholdingDetails: officerForm.shareDistributionForm.controls
          .shareDistributions as FormArray,
        refId: this.fb.control(refId),
      }),
    );
  }

  updateShareholder(
    index: number,
    officerForm: AbstractOfficerForm,
    refId: string,
  ) {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    const currentControl = this._form.at(index);

    if (currentControl.controls.officerType.value === officerType) {
      currentControl.patchValue({
        officerType: officerType,
        isNominee: false,
        [officerName]: officerForm.officerForm.getRawValue(),
        refId: refId,
      });

      const shareholdingDetailControl =
        currentControl.controls.shareholdingDetails;

      shareholdingDetailControl.clear();

      officerForm.shareDistributionForm.controls.shareDistributions.controls.forEach(
        (s) => shareholdingDetailControl.push(s),
      );
    } else {
      const newInput = this.fb.group<ShareholderFormType>({
        id: this.fb.control(currentControl.controls.id.value),
        officerType: this.fb.control(officerType),
        isNominee: this.fb.control(false),
        [officerName]: officerForm.officerForm.getRawValue(),
        refId: this.fb.control(refId),
        shareholdingDetails: currentControl.controls.shareholdingDetails,
      });

      this._form.setControl(index, newInput);
    }
  }

  updateShareholderByRef(
    directorControl: FormGroup<DirectorFormType>,
    officerForm: AbstractOfficerForm,
  ) {
    let officerType = OfficerTypeEnum.Individual;
    let officerName = 'officerPerson';

    if (officerForm instanceof OfficerCorporateForm) {
      officerType = OfficerTypeEnum.Corporate;
      officerName = 'officerCompany';
    }

    this._form.controls.forEach((c, idx) => {
      if (
        c.controls.refId &&
        c.controls.refId.value === directorControl.controls.id.value
      ) {
        if (directorControl.controls.officerType.value === officerType) {
          c.patchValue({
            [officerName]: officerForm.officerForm.getRawValue(),
          });
        } else {
          const newInput = this.fb.group({
            id: c.controls.id.value,
            officerType: [officerType],
            isNominee: [false],
            [officerName]: officerForm.officerForm.getRawValue(),
            refId: c.controls.refId.value,
            shareholdingDetails: c.controls.shareholdingDetails,
          }) as unknown as FormGroup<ShareholderFormType>;

          this._form.setControl(idx, newInput);
        }
      } else if (
        c.controls.refId &&
        directorControl.controls.officerType.value ===
          OfficerTypeEnum.Corporate &&
        c.controls.refId.value ===
          directorControl.controls.officerCompany.controls.representative
            .controls.id.value
      ) {
        c.patchValue({
          officerPerson:
            officerForm.officerForm.controls.representative.getRawValue(),
        });
      }
    });
  }

  checkValid() {
    if (this._form.length === 0) {
      this.valid = false;
    } else {
      this.valid = this._form.valid;
    }

    this.checkAllocateValid();
  }

  /**
   * Check if all shareholders have allocated = 100% or will not pass
   */
  checkAllocateValid() {
    if (this.form.length === 0) {
      this.allocateValid = false;
    } else {
      let totalShares = 0;
      let totalCapitalShares = 0;

      this._companyCapitals.value.forEach((c) => {
        totalCapitalShares += c.totalShares;
      });

      if (totalCapitalShares) {
        this.form.value.forEach((s) => {
          s.shareholdingDetails.forEach((s) => {
            totalShares += s.numberOfShares;
          });
        });

        const allocated = (totalShares / totalCapitalShares) * 100;

        this.allocateValid = !(allocated !== 100);
      } else {
        this.allocateValid = false;
      }
    }
  }

  setDataFromInput(input: CompanyShareholderInput[]) {
    input.forEach((data) => {
      let officerType = OfficerTypeEnum.Individual;
      let officerName = 'officerPerson';
      let officerForm: OfficerIndividualForm | OfficerCorporateForm;

      if (data.officerCompany) {
        officerType = OfficerTypeEnum.Corporate;
        officerName = 'officerCompany';

        officerForm = new OfficerCorporateForm();
        officerForm.createOfficerForm();
        officerForm.createShareDistributionsForm();
        officerForm.setEditData(data.officerCompany, data.shareholdingDetails);
      } else {
        officerForm = new OfficerIndividualForm();
        officerForm.createOfficerForm();
        officerForm.createShareDistributionsForm();
        officerForm.setEditData(data.officerPerson, data.shareholdingDetails);
      }

      this._form.push(
        this.fb.group<ShareholderFormType>({
          officerType: this.fb.control(officerType),
          isNominee: this.fb.control(false),
          [officerName]: officerForm.officerForm,
          shareholdingDetails: officerForm.shareDistributionForm.controls
            .shareDistributions as FormArray,
        }),
      );
    });
  }
}
