import {Injectable} from '@angular/core';
import {CurrencyExchangeQuery} from "@generated/graphql";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    private readonly currencyExchangeQuery: CurrencyExchangeQuery
  ) {
  }

  getCurrencyExchangeRates(fromCurrency: string, toCurrency?: string, amount?: number): Observable<number> {
    return this.currencyExchangeQuery.fetch({fromCurrency, toCurrency, amount}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.currencyExchange)
    )
  }
}
