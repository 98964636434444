import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { BusinessProfileType } from "@generated/graphql";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectBusinessProfile } from "@modules/account-settings/pages/business-profile/store/business-profile.store";

@Component({
  standalone: true,
  selector: ' app-tenant-logo',
  imports: [
    CommonModule,
  ],
  templateUrl: './tenant-logo.component.html',
})
export class TenantLogoComponent implements OnInit {
  @Input() styleClasses: string = '';
  profile$: Observable<BusinessProfileType>;

  constructor(private _store: Store) { }

  ngOnInit(): void {
    this.profile$ = this._store.select(selectBusinessProfile)
  }

  // getStyleClasses(): string {
  //   if (this.styleClasses) return `${this.styleClasses} object-center object-contain`;
  //
  //   return 'w-32 h-32 object-contain object-center';
  // }
}
