import { Injectable } from '@angular/core';
import { Address } from '@app/modules/address/models/address.model';
import { CompanyDirector } from '@app/modules/company/models/company-director.model';
import { CompanyShareholder } from '@app/modules/company/models/company-shareholder.model';
import { OfficerCompany } from '@app/modules/officer/models/officer-company.model';
import { OfficerPerson } from '@app/modules/officer/models/officer-person.model';
import {
  AddressInput,
  CompanyDirectorInput,
  CompanyShareholderInput,
  CompanyShareholderType,
  CountryType,
  OfficerCompanyInput,
  OfficerCompanyType,
  OfficerIdentificationTypeType,
  OfficerPersonInput,
  OfficerPersonType,
  OfficerTypeEnum,
  SalutationEnum,
  TransfereeWithDocumentsInput,
} from '@generated/graphql';
import { ShareHolderListViewTableType } from '@shared/component/shareholder/classes/share-holder-list-view-table-type';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class OfficerInputHelpers {
  public getOfficerName(
    officerProfile: OfficerPersonInput | OfficerCompanyInput,
  ): string {
    let officerName = '';

    if ('identification' in officerProfile) {
      officerName = `${officerProfile.firstName} ${
        officerProfile.lastName || ''
      }`.trim();
    } else if ('representative' in officerProfile) {
      officerName = officerProfile.name;
    } else if ('firstName' in officerProfile) {
      // Nominee
      return officerProfile.firstName;
    }

    return officerName;
  }

  public getOfficerEmail(
    officerProfile: OfficerPersonInput | OfficerCompanyInput,
  ): string {
    return officerProfile.email;
  }

  isIndividual(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): boolean {
    return !!officerInput.officerPerson;
  }

  isNominee(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): boolean {
    return officerInput.isNominee;
  }

  isCorporate(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): boolean {
    return !!officerInput.officerCompany;
  }

  getIdentificationTypeName(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
    types: OfficerIdentificationTypeType[],
  ): string {
    let typeName = '';

    if (this.isIndividual(officerInput)) {
      const result = types.find(
        (t) =>
          t.id ===
          officerInput.officerPerson.identification.officerIdentificationTypeId,
      );

      if (result) {
        typeName = result.name;
      }
    } else if (this.isCorporate(officerInput)) {
      const result = types.find(
        (t) =>
          t.id ===
          officerInput.officerCompany.representative.identification
            .officerIdentificationTypeId,
      );

      if (result) {
        typeName = result.name;
      }
    }

    return typeName;
  }

  getIdentificationNumber(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): string {
    if (this.isIndividual(officerInput)) {
      return officerInput.officerPerson.identification.identificationNumber;
    } else if (this.isCorporate(officerInput)) {
      return officerInput.officerCompany.representative.identification
        .identificationNumber;
    }

    return 'N/A';
  }

  getIdentificationExpiryDate(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): Date {
    if (this.isIndividual(officerInput)) {
      return officerInput.officerPerson.identification.expiryDate || null;
    } else if (this.isCorporate(officerInput)) {
      return (
        officerInput.officerCompany.representative.identification.expiryDate ||
        null
      );
    }

    return null;
  }

  getEmail(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): string {
    if (this.isIndividual(officerInput)) {
      return officerInput.officerPerson.email || '-';
    } else if (this.isCorporate(officerInput)) {
      return officerInput.officerCompany.email || '-';
    }

    return '-';
  }

  getRegistrationNumber(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): string {
    if (this.isCorporate(officerInput)) {
      return officerInput.officerCompany.registrationNumber || '-';
    }

    return '-';
  }

  getRegistrationDate(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): Date {
    if (this.isCorporate(officerInput)) {
      return officerInput.officerCompany.registrationDate || null;
    }

    return null;
  }

  getPhone(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
  ): string {
    if (this.isIndividual(officerInput)) {
      return officerInput.officerPerson.phone || '-';
    } else if (this.isCorporate(officerInput)) {
      return officerInput.officerCompany.representative.phone || '-';
    }

    return '-';
  }

  getAddress(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
    countries: CountryType[],
  ): string {
    if (this.isIndividual(officerInput)) {
      const country = countries.find(
        (c) => c.id === officerInput.officerPerson.countryId,
      );

      return [
        officerInput.officerPerson.apartment,
        officerInput.officerPerson.address,
        officerInput.officerPerson.city,
        officerInput.officerPerson.state,
        officerInput.officerPerson.zip,
        country.name,
      ]
        .filter(Boolean)
        .join(', ');
    } else if (this.isCorporate(officerInput)) {
      const country = countries.find(
        (c) => c.id === officerInput.officerCompany.representative.countryId,
      );

      const { apartment, address, city, state, zip } =
        officerInput.officerCompany.address;

      return [apartment, address, city, state, zip, country.name]
        .filter(Boolean)
        .join(', ');
    }

    return '-';
  }

  getCountryName(
    officerInput: CompanyShareholderInput | CompanyDirectorInput,
    countries: CountryType[],
  ): string {
    let country: CountryType;

    if (this.isIndividual(officerInput)) {
      country = countries.find(
        (c) => c.id === officerInput.officerPerson.countryId,
      );
    } else if (this.isCorporate(officerInput)) {
      country = countries.find(
        (c) => c.id === officerInput.officerCompany.representative.countryId,
      );
    }

    return country.name;
  }

  transformOfficerInput(input: OfficerPersonInput | OfficerCompanyInput) {
    if ('identification' in input) {
      const transformedInput = cloneDeep(input);

      transformedInput.identification.expiryDate =
        DateTime.fromJSDate(input.identification.expiryDate).toISODate() ||
        input.identification.expiryDate;

      return transformedInput;
    } else if ('representative' in input) {
      const transformedInput = cloneDeep(input);

      transformedInput.registrationDate =
        DateTime.fromJSDate(input.registrationDate).toISODate() ??
        input.registrationDate;

      transformedInput.representative.identification.expiryDate =
        DateTime.fromJSDate(
          input.representative.identification.expiryDate,
        ).toISODate() ?? input.representative.identification.expiryDate;

      return transformedInput;
    }

    return input;
  }

  transformOfficerPersonFromTypeToInput(officerProfile: OfficerPersonType) {
    const officerProfileInput: OfficerPersonInput = {
      address: officerProfile.address.address,
      apartment: officerProfile.address.apartment,
      city: officerProfile.address.city,
      countryId: officerProfile.address.country.id,
      phone: officerProfile.address.phone,
      state: officerProfile.address.state,
      zip: officerProfile.address.zip,
      email: officerProfile.email,
      firstName: officerProfile.firstName,
      lastName: officerProfile.lastName,
      salutation: officerProfile.salutation as SalutationEnum,
      identification: {
        expiryDate: officerProfile.identification.expiryDate
          ? DateTime.fromISO(officerProfile.identification.expiryDate).toFormat(
              'yyyy-MM-dd',
            )
          : null,
        identificationNumber:
          officerProfile.identification.identificationNumber,
        nationalityId: officerProfile.identification.nationality.id,
        officerIdentificationTypeId:
          officerProfile.identification.officerIdentificationType.id,
      },
    };

    return officerProfileInput;
  }

  transformOfficerCompanyFromTypeToInput(officerProfile: OfficerCompanyType) {
    const officerProfileInput: OfficerCompanyInput = {
      address: {
        address: officerProfile.address.address,
        apartment: officerProfile.address.apartment,
        city: officerProfile.address.city,
        countryId: officerProfile.address.country.id,
        phone: officerProfile.address.phone,
        state: officerProfile.address.state,
        zip: officerProfile.address.zip,
      },
      countryId: officerProfile.country.id,
      email: officerProfile.email,
      name: officerProfile.name,
      registrationDate: DateTime.fromISO(
        officerProfile.registrationDate,
      ).toFormat('yyyy-MM-dd'),
      registrationNumber: officerProfile.registrationNumber,
      website: officerProfile.website,
      representative: {
        id: officerProfile.representative.id,
        ...this.transformOfficerPersonFromTypeToInput(
          officerProfile.representative.officerProfile as OfficerPersonType,
        ),
      },
    };

    return officerProfileInput;
  }

  public transformOfficerPersonModelToInput(
    officer: OfficerPerson,
  ): OfficerPersonInput {
    const input: OfficerPersonInput = {
      salutation: officer.salutation as SalutationEnum,
      firstName: officer.firstName,
      lastName: officer.lastName,
      countryId: officer.identification.nationality.id,
      email: officer.email,
      phone: officer.address.phone,
      address: officer.address.address,
      apartment: officer.address.apartment,
      zip: officer.address.zip,
      city: officer.address.city,
      state: officer.address.state,
      identification: {
        officerIdentificationTypeId:
          officer.identification.officerIdentificationType.id,
        nationalityId: officer.identification.nationality.id,
        identificationNumber: officer.identification.identificationNumber,
        expiryDate: officer.identification.expiryDate,
      },
    };

    return input;
  }

  public transformOfficerCompanyModelToInput(
    officer: OfficerCompany,
  ): OfficerCompanyInput {
    const repInput = this.transformOfficerPersonModelToInput(
      officer.representative.officerProfile as OfficerPerson,
    );

    const input: OfficerCompanyInput = {
      name: officer.name,
      registrationNumber: officer.registrationNumber,
      registrationDate: officer.registrationDate,
      website: officer.website,
      email: officer.email,
      address: this.transformAddressModelToInput(officer.address),
      countryId: officer.country.id,
      representative: repInput,
    };

    return input;
  }

  public transformAddressModelToInput(address: Address): AddressInput {
    const input: AddressInput = {
      phone: address.phone,
      address: address.address,
      apartment: address.apartment,
      zip: address.zip,
      city: address.city,
      state: address.state,
      countryId: address.country.id,
    };

    return input;
  }

  public transformDirectorModelToInput(
    director: CompanyDirector,
  ): CompanyDirectorInput {
    if (director.isNominee) {
      const nominee = director.officer.officerProfile as OfficerPerson;

      const input: CompanyDirectorInput = {
        dateFrom: director.dateFrom,
        isNominee: true,
        officerType: OfficerTypeEnum.Individual,
        officerPerson: {
          firstName: nominee.firstName,
          countryId: null,
        },
      };

      return input;
    }

    let keyName = 'officerPerson';

    let officerType = OfficerTypeEnum.Individual.toString();

    let officerInput = null;

    if (director.officer.officerType === OfficerTypeEnum.Individual) {
      officerInput = this.transformOfficerPersonModelToInput(
        director.officer.officerProfile as OfficerPerson,
      );
    } else if (director.officer.officerType === OfficerTypeEnum.Corporate) {
      officerInput = this.transformOfficerCompanyModelToInput(
        director.officer.officerProfile as OfficerCompany,
      );

      keyName = 'officerCompany';
      officerType = OfficerTypeEnum.Corporate.toString();
    }

    const input: CompanyDirectorInput = {
      dateOfCessation: director.dateOfCessation,
      dateFrom: director.dateFrom,
      isNominee: false,
      officerType,
      [keyName]: officerInput,
    };

    return input;
  }

  public transformShareholderModelToInput(
    shareholder: CompanyShareholder,
    withShareholding = false,
  ): CompanyShareholderInput {
    let keyName = 'officerPerson';

    let officerType = OfficerTypeEnum.Individual.toString();

    let officerInput = null;

    if (shareholder.officer.officerType === OfficerTypeEnum.Individual) {
      officerInput = this.transformOfficerPersonModelToInput(
        shareholder.officer.officerProfile as OfficerPerson,
      );
    } else if (shareholder.officer.officerType === OfficerTypeEnum.Corporate) {
      officerInput = this.transformOfficerCompanyModelToInput(
        shareholder.officer.officerProfile as OfficerCompany,
      );

      keyName = 'officerCompany';
      officerType = OfficerTypeEnum.Corporate.toString();
    }

    const input: CompanyShareholderInput = {
      isNominee: false,
      officerType,
      [keyName]: officerInput,
    };

    if (withShareholding) {
      // TODO: add shareholding details if needed later
    }

    return input;
  }

  public transformNewShareholderToListViewItem(
    shareholderInput: TransfereeWithDocumentsInput,
  ) {
    const viewItem: ShareHolderListViewTableType = {
      officerType: shareholderInput.officerType as OfficerTypeEnum,
      officer: {},
      sharesTransferDetails: shareholderInput.sharesTransferDetails,
    };

    if (shareholderInput.officerPerson) {
      const officer = shareholderInput.officerPerson;

      viewItem.officer.officerPerson = {
        countryId: officer.countryId,
        firstName: officer.firstName,
        lastName: officer.lastName,
        identification: {
          officerIdentificationTypeId:
            officer.identification.officerIdentificationTypeId,
          identificationNumber: officer.identification.identificationNumber,
          nationalityId: officer.identification.nationalityId,
        },
      };
    } else {
      const officer = shareholderInput.officerCompany;

      viewItem.officer.officerCompany = {
        name: officer.name,
        countryId: officer.countryId,
        registrationNumber: officer.registrationNumber,
        representative: {
          countryId: officer.representative.countryId,
          identification: {
            officerIdentificationTypeId:
              officer.representative.identification.officerIdentificationTypeId,
            identificationNumber:
              officer.representative.identification.identificationNumber,
            nationalityId: officer.representative.identification.nationalityId,
          },
        },
      };
    }

    return viewItem;
  }
}
