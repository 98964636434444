import { BaseModel } from "@shared/models/base.model";
import { StripeBillingDetailsAddressType } from "@generated/graphql";

export class StripeBillingDetailsAddress extends BaseModel implements StripeBillingDetailsAddressType {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;

  constructor (state: StripeBillingDetailsAddressType) {
    super(state);
  }

  getModelName (): string {
    return StripeBillingDetailsAddress.name;
  }

  get addressToShow (): string {
    return [
      this.line1,
      this.line2,
      this.city,
      this.state,
      this.country,
      this.postal_code
    ].filter(v => !!v).join(', ')
  }
}
