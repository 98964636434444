import {AbstractOfficerForm} from '@shared/classes/officer/abstract-officer-form.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OfficerAuditorFormType} from '@modules/officer/models/form/officer-auditor-form-type';

export class OfficerAuditorForm extends AbstractOfficerForm {
  officerForm: FormGroup<OfficerAuditorFormType>;

  constructor() {
    super();
  }

  createOfficerForm() {
    this.officerForm = this.fb.group(<OfficerAuditorFormType>({
      auditorName: new FormControl<string | null>('', Validators.required),
    }));
  }

  setEditData() {
    //TODO Implement Edit
  }
}
