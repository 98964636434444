import {ModelFactory} from "@shared/factories/model.factory";
import {OfficerIndirectCompanyType} from "@generated/graphql";
import {OfficerIndirectCompany} from "@modules/officer/models/officer-indirect-company.model";

export class OfficerIndirectCompanyFactory extends ModelFactory<OfficerIndirectCompanyType, OfficerIndirectCompany> {
  create(state: OfficerIndirectCompanyType): OfficerIndirectCompany {
    return new OfficerIndirectCompany(state);
  }
}

export const officerIndirectCompanyFactory = new OfficerIndirectCompanyFactory()
