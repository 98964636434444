import { EntityServiceContract } from "@shared/contracts/entity-service.contract";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { ModelFactory } from "@shared/factories/model.factory";
import { EntityMap, EntityMapOne, Predicate, Update } from "@ngrx/entity";
import { EntityFeatureNameEnum, EntityStore } from "@shared/contracts/entity-store.contract";

export abstract class EntityService<
  EntityFeature extends EntityFeatureNameEnum,
  BE extends Record<string, any>,
  FE extends BE,
  Factory extends ModelFactory<BE, FE> = ModelFactory<BE, FE>
> implements EntityServiceContract<BE, FE> {
  readonly entities$: Observable<FE[]> = this.store.select(this.entityStore.selectAllEntity).pipe(
    map(entities => entities ? entities.map(entity => this.factory.create(entity)) : []),
  );

  readonly activeEntity$: Observable<FE> = this.store.select(this.entityStore.selectSelectedEntity).pipe(
    map(entity => entity ? this.factory.create(entity) : null)
  )

  abstract get factory (): Factory

  protected constructor (
    protected readonly store: Store,
    protected readonly entityStore: EntityStore<EntityFeature, BE, FE>
  ) {
  }

  find$ (id: string): Observable<FE> {
    return this.store.select(
      this.entityStore.selectEntity(id)
    ).pipe(
      map(item => item ? this.factory.create(item) : null)
    );
  }

  getByIds$ (ids: string[], keepDuplicate: boolean = true): Observable<FE[]> {
    const searchIds = !keepDuplicate ? [...new Set(ids)] : ids

    return this.store.select(this.entityStore.selectEntitiesByIds(searchIds)).pipe(
      map(items => items ? items.map(item => this.factory.create(item)) : [])
    );
  }

  dispatchSetEntities (entities: BE[]): void {
    this.store.dispatch(this.entityStore.setEntitiesAction({entities}))
  }

  dispatchLoadEntitiesAction (entities: BE[]): void {
    this.store.dispatch(this.entityStore.loadEntitiesAction({entities}))
  }

  dispatchAddEntityAction (entity: BE): void {
    this.store.dispatch(this.entityStore.addEntityAction({entity}))
  }

  dispatchSetEntityAction (entity: BE): void {
    this.store.dispatch(this.entityStore.setEntityAction({entity}))
  }

  dispatchUpsertEntityAction (entity: BE): void {
    this.store.dispatch(this.entityStore.upsertEntityAction({entity}))
  }

  dispatchAddEntitiesAction (entities: BE[]): void {
    this.store.dispatch(this.entityStore.addEntitiesAction({entities}))
  }

  dispatchUpsertEntitiesAction (entities: BE[]): void {
    this.store.dispatch(this.entityStore.upsertEntitiesAction({entities}))
  }

  dispatchUpdateEntityAction (update: Update<BE>): void {
    this.store.dispatch(this.entityStore.updateEntityAction({update}))
  }

  dispatchUpdateEntitiesAction (updates: Update<BE>[]): void {
    this.store.dispatch(this.entityStore.updateEntitiesAction({updates}))
  }

  dispatchMapEntityAction (entityMap: EntityMapOne<BE>): void {
    this.store.dispatch(this.entityStore.mapEntityAction({entityMap}))
  }

  dispatchMapEntitiesAction (entityMap: EntityMap<BE>): void {
    this.store.dispatch(this.entityStore.mapEntitiesAction({entityMap}))
  }

  dispatchDeleteEntityAction (id: string): void {
    this.store.dispatch(this.entityStore.deleteEntityAction({id}))
  }

  dispatchDeleteEntitiesAction (ids: string[]): void {
    this.store.dispatch(this.entityStore.deleteEntitiesAction({ids}))
  }

  dispatchDeleteEntitiesByPredicateAction (predicate: Predicate<BE>): void {
    this.store.dispatch(this.entityStore.deleteEntitiesByPredicateAction({predicate}))
  }

  dispatchClearEntitiesAction (): void {
    this.store.dispatch(this.entityStore.clearEntitiesAction())
  }

  dispatchSelectEntityAction (selectedEntityId: string): void {
    this.store.dispatch(this.entityStore.selectEntityAction({selectedEntityId}))
  }

  dispatchAddOrUpdateEntityAction (entity: BE): void {
    this.store.dispatch(this.entityStore.addOrUpdateEntityAction({entity}))
  }

  dispatchAddOrUpdateThenSelectEntityAction (entity: BE): void {
    this.dispatchAddOrUpdateEntityAction(entity)
    this.dispatchSelectEntityAction(entity.id)
  }
}
