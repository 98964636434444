import { Component, Input, OnInit } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  imports: [
    CommonModule,
    RouterLink,
    MatCardModule,
    MatButtonModule,
    MatIconModule
  ],
  styleUrls: ['./content-wrapper.component.scss']
})
export class ContentWrapperComponent implements OnInit {
  private _isShowBackButton: boolean = false;


  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() backTo?: any;
  @Input() queryParam?: any;

  @Input()
  get showBackButton (): boolean {
    return this._isShowBackButton;
  }

  set showBackButton (value: BooleanInput) {
    this._isShowBackButton = coerceBooleanProperty(value);
  }

  constructor () {
  }

  ngOnInit (): void {
  }

}
