import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";
import {LetModule} from "@ngrx/component";
import {CalloutComponent} from "@shared/component/message/callout/callout.component";

@Component({
  selector: 'app-special-request-info',
  standalone: true,
  imports: [CommonModule, LetModule, CalloutComponent],
  templateUrl: './special-request-info.component.html',
  styleUrls: ['./special-request-info.component.scss']
})
export class SpecialRequestInfoComponent implements OnInit {

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
  ) {
  }

  ngOnInit(): void {
  }
}
