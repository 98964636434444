<div class="billing-address-wrapper"
     *ngIf="address">
  <mat-card>
    <mat-card-title class="!flex flex-row items-center">
      <p *ngIf="config.showTitle"
         class="mat-title !text-base !mb-0">Billing address</p>

      <ng-container class="flex items-start w-full">
        <span class="mat-title !text-base text-darkGray !mb-0 !mr-2 break-all">{{ address.fullName }}</span>
        <ng-container *ngIf="config.showDefaultBadged">
          <span *ngIf="address.isDefault"
                class="ml-2 inline-flex items-center text-green-default text-sub">
            <mat-icon>verified</mat-icon>
            <span>&nbsp;default</span>
          </span>
        </ng-container>
      </ng-container>
    </mat-card-title>

    <mat-card-content>
      <div class="flex flex-col">
        <div class="inline-flex gap-2 items-start mb-1">
          <mat-icon class="flex-shrink-0 text-darkGray opacity-70 mr-2">place</mat-icon>
          <p class="!mb-1 w-[80%] break-words text-sub">
            {{ commonHelper.renderAddress(address) }}
          </p>
        </div>
        <div class="inline-flex gap-2 items-start">
          <mat-icon class="flex-shrink-0 text-darkGray opacity-70 mr-2">phone</mat-icon>
          <p class="!mb-0 text-darkGray w-full text-sub">{{ address.phone }}</p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      *ngIf="config.showActions"
      align="end"
      class="!pt-0">
      <ng-container *ngIf="config.showEditAction">
        <button mat-button
                type="button"
                color="primary"
                (click)="onUpdate(address)">Edit
        </button>
      </ng-container>

      <ng-container *ngIf="config.showManageAction">
        <button (click)="onManage()"
                mat-button
                type="button"
                color="primary">{{ config.manageLabel }}
        </button>
      </ng-container>

      <ng-container *ngIf="config.showDeleteAction">
        <button (click)="onRemove(address)"
                mat-button
                type="button"
                color="warn">Delete
        </button>
      </ng-container>

      <button *ngIf="!address.isDefault"
              (click)="setAsDefault(address)"
              [disabled]="loading$ | async"
              mat-button
              type="button"
              color="accent">Set as default
      </button>
    </mat-card-actions>
  </mat-card>
</div>
