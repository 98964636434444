import { PermissionEnum, PermissionType, RoleEnum, RoleType } from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';
import { startCase } from 'lodash-es';
import { AppPermissionEnum, Permission } from "@modules/permission/models/permission.model";
import { permissionFactory } from "@modules/permission/factories/permission.factory";

export class Role extends BaseModel implements RoleType {
  id: string;
  name: string;
  permissions: Permission[];

  constructor (state: RoleType) {
    super(state);

    this.permissions = state.permissions ? state.permissions.map(permission => permissionFactory.create(permission)) : []
  }


  getModelName (): string {
    return Role.name;
  }

  getRoleTypeDisplay (): string {
    return this.name ? startCase(this.name.toLowerCase()) : '';
  }

  isCustomer (): boolean {
    return this.name === RoleEnum.Customer;
  }

  isExpert (): boolean {
    return this.name === RoleEnum.Expert;
  }

  isAdmin (): boolean {
    return this.name === RoleEnum.Admin;
  }

  isSuperAdmin (): boolean {
    return this.name === RoleEnum.SuperAdmin;
  }

  hasPermissions (permissions: AppPermissionEnum | AppPermissionEnum[]): boolean {
    if (typeof permissions === 'string') {
      return this.permissions.some(permission => permission.name === permissions)
    }

    if (Array.isArray(permissions)) {
      return permissions.every(permission => this.permissions.some(item => item.name === permission))
    }

    return false
  }
}
