import { ModelFactory } from "@shared/factories/model.factory";
import { MissingKycDocumentType } from "@generated/graphql";
import { MissingKycDocument } from "@modules/officer/models/missing-kyc-document.model";

export class MissingKycDocumentFactory extends ModelFactory<MissingKycDocumentType, MissingKycDocument> {
  create (state: MissingKycDocumentType): MissingKycDocument {
    return new MissingKycDocument(state);
  }
}

export const missingKycDocumentFactory = new MissingKycDocumentFactory()
