import {Injectable} from '@angular/core';
import {
  CountryType,
  OfficerCompanyType,
  OfficerIdentificationType,
  OfficerPersonType,
  OfficerProfileType, OfficerType,
  ShareholdingDetailType,
  ShareTypeEnum
} from '@generated/graphql';
import {startCase} from "lodash-es";
import {AppStatusType} from "@shared/component/common/status/status.component";
import {CompanyShareholder} from "@modules/company/models/company-shareholder.model";

export enum KycCollectionStatusEnum {
  MissingDocument = 'MISSING_DOCUMENT',
  AwaitingReview = 'AWAITING_REVIEW',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Failed = 'FAILED',
  Verified = 'VERIFIED',
}

@Injectable({
  providedIn: 'root',
})
export class OfficerHelper {
  private static instance: OfficerHelper;

  public static getInstance() {
    if (!OfficerHelper.instance) {
      return this.instance = new OfficerHelper();
    }
    return OfficerHelper.instance;
  }

  public isIndividual(typeName: string): boolean {
    return typeName === 'OfficerPersonType';
  }

  public isCorporate(typeName: string): boolean {
    return typeName === 'OfficerCompanyType';
  }

  public isDirector(typeName: string) {
    return typeName === 'CompanyDirectorType';
  }

  // public isShareholder(typeName: string) {
  //   return typeName === 'CompanyShareholderType';
  // }
  public isShareholder(officer: OfficerType) {
    return officer instanceof CompanyShareholder;
  }

  public isSecretary(typeName: string) {
    return typeName === 'CompanySecretaryType';
  }

  public isAuditor(typeName: string) {
    return typeName === 'CompanyAuditorType';
  }

  public renderOfficerRole(typeName: string): string {
    switch (typeName) {
      case 'CompanyDirectorType': {
        return 'Director';
      }
      case 'CompanyShareholderType': {
        return 'Shareholder';
      }
      case 'CompanySecretaryType': {
        return 'Secretary';
      }
      case 'CompanyAuditorType': {
        return 'Auditor';
      }
      default: {
        return '-';
      }
    }
  }

  public getOfficerName(officerProfile: OfficerProfileType): string {
    let officerName = '';
    if (this.isIndividual(officerProfile.__typename)) {
      const individualInfo = officerProfile as OfficerPersonType;
      officerName = individualInfo.firstName + ' ' + individualInfo.lastName;
    }
    if (this.isCorporate(officerProfile.__typename)) {
      const corporateInfo = officerProfile as OfficerCompanyType;
      officerName = corporateInfo.name;
    }
    return officerName;
  }

  public getOfficerEmail(officerProfile: OfficerProfileType): string {
    if (officerProfile.__typename === 'OfficerCompanyType' || officerProfile.__typename === 'OfficerPersonType') {
      return officerProfile.email;
    }

    return ''
  }

  public transformOfficerProfile(officerProfile: OfficerProfileType): OfficerProfileType {
    let profile: OfficerProfileType;
    if (this.isIndividual(officerProfile.__typename)) {
      profile = officerProfile as OfficerPersonType;
    }
    if (this.isCorporate(officerProfile.__typename)) {
      profile = officerProfile as OfficerCompanyType;
    }
    return profile;
  }

  public getCountryOfIncorporation(officerProfile: OfficerProfileType): CountryType {
    let country: CountryType;
    if (this.isCorporate(officerProfile.__typename)) {
      country = (officerProfile as OfficerCompanyType).country;
    }
    return country;
  }

  public getIdentificationInfo(officerProfile): OfficerIdentificationType {
    let identificationInfo: OfficerIdentificationType;
    if (this.isIndividual(officerProfile.__typename)) {
      const individualInfo = officerProfile as OfficerPersonType;
      identificationInfo = individualInfo.identification;
    }
    return identificationInfo;
  }

  public getRegistrationNumber(officerProfile): string {
    let registrationNumber: string = '';
    if (this.isCorporate(officerProfile.__typename)) {
      const corporateInfo = officerProfile as OfficerCompanyType;
      registrationNumber = corporateInfo.registrationNumber;
    }
    return registrationNumber;
  }

  public renderKycCollectionStatusLabel(status: KycCollectionStatusEnum): string {
    return status ? startCase(status.toLowerCase()) : null;
  }

  public renderKycCollectionStatus(status: KycCollectionStatusEnum): AppStatusType {
    switch (status) {
      case KycCollectionStatusEnum.MissingDocument:
        return 'hold';
      case KycCollectionStatusEnum.AwaitingReview:
        return 'warn';
      case KycCollectionStatusEnum.Failed:
        return 'error';
      case KycCollectionStatusEnum.Rejected:
        return 'error';
      case KycCollectionStatusEnum.Approved:
        return 'success';
      case KycCollectionStatusEnum.Verified:
        return 'success';
      default:
        return 'default';
    }
  }

  public getOrdinaryShares(shareholdingDetails: ShareholdingDetailType[]): ShareholdingDetailType[] {
    return shareholdingDetails.length ? shareholdingDetails.filter((item) => (item.shareType.name === ShareTypeEnum.Ordinary && item.numberOfShares > 0)) : [];
  }

  public getPreferenceShares(shareholdingDetails: ShareholdingDetailType[]): ShareholdingDetailType[] {
    return shareholdingDetails.length ? shareholdingDetails.filter((item) => (item.shareType.name === ShareTypeEnum.Preference && item.numberOfShares > 0)) : [];
  }

  public getOtherShares(shareholdingDetails: ShareholdingDetailType[]): ShareholdingDetailType[] {
    return shareholdingDetails.length ? shareholdingDetails.filter((item) => (item.shareType.name === ShareTypeEnum.Other && item.numberOfShares > 0)) : [];
  }
}
