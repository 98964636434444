<div class="content-wrapper w-full">
  <mat-card class="h-fit !p-8 !mat-elevation-z2">
    <section class="title-group-container !inline-flex !mb-6">
      <button *ngIf="showBackButton"
              mat-icon-button
              class="!mr-3 text-darkGray"
              [routerLink]="backTo"
              [queryParams]="queryParam">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div>
        <p class="mat-title !text-xl text-darkGray !mb-1">{{title}}</p>
        <p *ngIf="subtitle"
           class="text-lightGray">{{subtitle}}</p>
      </div>
    </section>
    <mat-card-content>
      <ng-content></ng-content>
    </mat-card-content>
  </mat-card>
</div>
