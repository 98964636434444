import { ModelFactory } from "@shared/factories/model.factory";
import { CompanyDirectorType } from "@generated/graphql";
import { CompanyDirector } from "@modules/company/models/company-director.model";

export class CompanyDirectorFactory extends ModelFactory<CompanyDirectorType, CompanyDirector> {
  create (state: CompanyDirectorType): CompanyDirector {
    return new CompanyDirector(state);
  }
}

export const companyDirectorFactory = new CompanyDirectorFactory()
