import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import {
  CompanyCapitalInput,
  CompanyDirectorInput,
  CompanyShareholderInput,
  CreateCompanyInput,
  CreateCompanyJobInput,
  IncorporateTypeEnum,
  JobMatterEnum,
  OfficerPersonIdentificationInput,
  OfficerPersonInput,
  ServiceEnum,
} from '@generated/graphql';
import { startCase } from 'lodash-es';
import { DateTime } from 'luxon';
import { CompanyDetailForm } from './company-detail-form.class';
import { CompanyStructureForm } from './company-structure-form.class';
import { JobOrderServiceManagement } from './job-order-service-management.class';

@Injectable({
  providedIn: 'root',
})
export class CompanyIncorporationManagement {
  private _fb = new FormBuilder();

  private _type: IncorporateTypeEnum;
  set type(type: IncorporateTypeEnum) {
    this._type = type;
  }
  get type(): IncorporateTypeEnum {
    return this._type;
  }

  private _countryOfIncorporation: SupportedCountry;
  set countryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;
  }
  get countryOfIncorporation(): SupportedCountry {
    return this._countryOfIncorporation;
  }

  private _submitData: Partial<CreateCompanyJobInput> = {
    jobInput: {},
    companyInput: {
      registeredAddress: {},
      companyCapitals: [],
      shareholders: [],
      directors: [],
    },
  };

  orderServiceManagement: JobOrderServiceManagement;

  companyDetailForm: CompanyDetailForm;

  companyStructureForm: CompanyStructureForm;

  constructor() {
    this.orderServiceManagement = new JobOrderServiceManagement();
  }

  createForm(): this {
    // Because it is singleton using, check if page change but still have old form type
    // to make sure it in Register company but form is Transfer, and so on...
    if (
      !this.companyDetailForm ||
      (this.type === IncorporateTypeEnum.Register &&
        'numberOfEmployees' in this.companyDetailForm.form.value) ||
      (this.type === IncorporateTypeEnum.Transfer &&
        !('numberOfEmployees' in this.companyDetailForm.form.value))
    ) {
      this.companyDetailForm = new CompanyDetailForm(
        this._countryOfIncorporation,
        this.orderServiceManagement,
      );

      if (this.type === IncorporateTypeEnum.Register) {
        this.companyDetailForm.createRegisterForm();
      } else if (this.type === IncorporateTypeEnum.Transfer) {
        this.companyDetailForm.createTransferForm();
      }
    }

    this.companyStructureForm = new CompanyStructureForm(
      this._fb,
      this._countryOfIncorporation,
      this.orderServiceManagement,
    );

    this.companyStructureForm.createForm();

    return this;
  }

  addInitialService(): this {
    this.orderServiceManagement.resetAdditionalServices();

    if (this.isRegisterCompany()) {
      this.orderServiceManagement.addOrUpdateService(
        ServiceEnum.CompanyRegistration,
        'mainServices',
      );
    } else if (this.isTransferCompany()) {
      this.orderServiceManagement.addOrUpdateService(
        ServiceEnum.TransferCompany,
        'mainServices',
      );
    }

    this.orderServiceManagement.addOrUpdateService(
      ServiceEnum.CorporateSecretary,
      'mainServices',
    );

    if (this.companyDetailForm.registeredAddressForm.useTenantAddress) {
      this.orderServiceManagement.toggleRegisteredAddressService('add');
    }

    return this;
  }

  updateCountryOfIncorporation(country: SupportedCountry): this {
    this.countryOfIncorporation = country;

    if (this.companyDetailForm) {
      this.companyDetailForm.updateCountryOfIncorporation(country);
      this.companyDetailForm.registeredAddressForm.countryOfIncorporation =
        country;
    }

    if (this.companyStructureForm) {
      this.companyStructureForm.countryOfIncorporation = country;
    }

    return this;
  }

  updateServiceSupportedCountryId(): this {
    this.orderServiceManagement.incorporationServices.forEach((i) => {
      if (
        i.service.name === ServiceEnum.CompanyRegistration &&
        this.type === IncorporateTypeEnum.Register
      ) {
        this._submitData.jobInput.serviceSupportedCountryId = i.id;
      } else if (
        i.service.name === ServiceEnum.TransferCompany &&
        this.type === IncorporateTypeEnum.Transfer
      ) {
        this._submitData.jobInput.serviceSupportedCountryId = i.id;
      }
    });

    return this;
  }

  isRegisterCompany(): boolean {
    return this.type === IncorporateTypeEnum.Register;
  }

  isTransferCompany(): boolean {
    return this.type === IncorporateTypeEnum.Transfer;
  }

  getInputData() {
    this.prepairSubmitData();

    return this._submitData;
  }

  prepairSubmitData(): this {
    this.updateServiceSupportedCountryId();

    if (this.type === IncorporateTypeEnum.Register) {
      this._submitData.jobInput.matter = startCase(
        JobMatterEnum.RegisterCompany,
      );
    } else if (this.type === IncorporateTypeEnum.Transfer) {
      this._submitData.jobInput.matter = startCase(
        JobMatterEnum.TransferCompany,
      );
    }

    this.transformInputData();

    return this;
  }

  transformInputData(): void {
    this._submitData.companyInput = {
      ...this.companyDetailForm.form.getRawValue(),
      shareholders: this.getShareholderTransformData(),
      directors: this.getDirectorsTransformData(),
      companyCapitals: this.companyStructureForm.companyCaptialControls
        .value as CompanyCapitalInput[],
    } as CreateCompanyInput;

    if ('registrationDate' in this.companyDetailForm.form.controls) {
      this._submitData.companyInput.registrationDate = DateTime.fromJSDate(
        this.companyDetailForm.form.controls.registrationDate.value,
      ).toISODate();
    }

    this._submitData.companyInput.fyeDate = DateTime.fromJSDate(
      this.companyDetailForm.form.controls.fyeDate.value,
    ).toISODate();

    this._submitData.companyInput.registeredAddress.fromDate =
      DateTime.fromJSDate(
        this.companyDetailForm.form.controls.registeredAddress.controls.fromDate
          .value,
      ).toISODate();

    this._submitData.jobInput.detail = JSON.stringify({
      input: this._submitData,
      jobServiceOrder: this.orderServiceManagement.jobServicesOrder,
    });
  }

  getShareholderTransformData(): any[] {
    const data = [
      ...this.companyStructureForm.form.controls.shareholders.getRawValue(),
    ];

    return data.map((shareholder) => {
      const transformedShareholder = Object.assign(
        {},
        shareholder,
      ) as unknown as CompanyShareholderInput;

      if (transformedShareholder.officerPerson) {
        const ident = transformedShareholder.officerPerson
          .identification as OfficerPersonIdentificationInput;

        transformedShareholder.officerPerson.identification.expiryDate =
          DateTime.fromJSDate(ident.expiryDate).toISODate();
      } else if (transformedShareholder.officerCompany) {
        const rep = transformedShareholder.officerCompany
          .representative as OfficerPersonInput;

        transformedShareholder.officerCompany.registrationDate =
          DateTime.fromJSDate(
            transformedShareholder.officerCompany.registrationDate,
          ).toISODate();

        transformedShareholder.officerCompany.representative.identification.expiryDate =
          DateTime.fromJSDate(rep.identification.expiryDate).toISODate();
      }

      return transformedShareholder;
    });
  }

  getDirectorsTransformData(): any[] {
    const data = [
      ...this.companyStructureForm.form.controls.directors.getRawValue(),
    ];

    return data.map((director) => {
      const transformedDirector = Object.assign(
        {},
        director,
      ) as unknown as CompanyDirectorInput;

      transformedDirector.dateFrom = DateTime.fromJSDate(
        director.dateFrom,
      ).toISODate();

      if (transformedDirector.dateOfAppointment) {
        transformedDirector.dateOfAppointment = DateTime.fromJSDate(
          director.dateOfAppointment,
        ).toISODate();
      }

      if (!director.isNominee) {
        if (director.officerPerson) {
          const rep = director.officerPerson
            .identification as OfficerPersonIdentificationInput;

          transformedDirector.officerPerson.identification.expiryDate =
            DateTime.fromJSDate(rep.expiryDate).toISODate();
        } else if (director.officerCompany) {
          const rep = transformedDirector.officerCompany.representative
            .identification as OfficerPersonIdentificationInput;

          transformedDirector.officerCompany.representative.identification.expiryDate =
            DateTime.fromJSDate(rep.expiryDate).toISODate();
          transformedDirector.officerCompany.registrationDate =
            DateTime.fromJSDate(
              transformedDirector.officerCompany.registrationDate,
            ).toISODate();
        }
      }

      return transformedDirector;
    });
  }

  setDataFromJobInput(input: CreateCompanyJobInput) {
    this.companyStructureForm.companyCapitalForm.setDataFromInput(
      input.companyInput.companyCapitals,
    );

    this.companyStructureForm.companySharehoderForm.setDataFromInput(
      input.companyInput.shareholders,
    );

    this.companyStructureForm.companyDirectorForm.setDataFromInput(
      input.companyInput.directors,
    );
  }
}
