import { Injectable } from "@angular/core";
import {
  ExpertsCanBeAssignedToJobQuery,
  ExpertsQuery,
  UsersCanBeAssignedToJobInput,
  UserType
} from "@generated/graphql";
import { map, Observable } from "rxjs";
import { User } from "@modules/user/models/user.model";
import { userFactory } from "@modules/user/factories/user.factory";
import { paginationDataFactory } from "@shared/factories/pagination.factoy";

@Injectable({
  providedIn: 'root'
})
export class ExpertApi {
  constructor (
    protected readonly expertsQuery: ExpertsQuery,
    protected readonly expertsCanBeAssignedToJobQuery: ExpertsCanBeAssignedToJobQuery,
  ) {
  }

  get$ (): Observable<User[]> {
    return this.expertsQuery.fetch(undefined, {fetchPolicy: 'no-cache'}).pipe(
      map(res => res.data.experts
        ? res.data.experts.map(expert => userFactory.create(expert as UserType))
        : []
      )
    )
  }

  getExpertsCanBeAssignedToJob$ (input: UsersCanBeAssignedToJobInput) {
    return this.expertsCanBeAssignedToJobQuery.fetch({input}, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.expertsCanBeAssignedToJob
        ? paginationDataFactory.create(res.data.expertsCanBeAssignedToJob, userFactory)
        : null
      )
    )
  }
}
