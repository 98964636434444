import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import * as moment from 'moment/moment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { JobService } from '@app/modules/job/services/job.service';
import { UpdateJobInput } from '@generated/graphql';
import { Job } from '@app/modules/job/models/job.model';
import { BehaviorSubject } from 'rxjs';

type EditEstimatedDateDialogData = {
  job: Job;
  estimatedTime: number;
};

@Component({
  selector: 'app-edit-estimated-date',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './edit-estimated-date.component.html',
  styleUrls: ['./edit-estimated-date.component.scss'],
})
export class EditEstimatedDateComponent implements OnInit, OnDestroy {
  public estimatedForm: FormGroup;
  private _currentEstimatedTime: number;
  private _jobCreatedDate: Date;

  public submitting$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditEstimatedDateDialogData,
    protected readonly fb: FormBuilder,
    protected readonly dialogRef: MatDialogRef<EditEstimatedDateComponent>,
    private _jobService: JobService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    this._jobCreatedDate = this.data.job.createdAt;
    this._currentEstimatedTime = this.data.estimatedTime;

    this.estimatedForm
      .get('estimatedTime')
      .patchValue(this._currentEstimatedTime);
  }

  public calculateDeadline() {
    return moment(this._jobCreatedDate)
      .add(this.estimatedForm.get('estimatedTime').value, 'days')
      .toDate();
  }

  private initForm() {
    this.estimatedForm = this.fb.group({
      estimatedTime: new FormControl('', [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  public onSubmit(): void {
    this.submitting$.next(true);

    const input: UpdateJobInput = {
      estimated_time: `P${this.estimatedForm.controls['estimatedTime'].value}D`,
    };

    this._jobService.changeJobEstimatedTime(this.data.job.id, input).subscribe({
      next: (result) => {
        this.submitting$.next(false);

        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);

        this.submitting$.next(false);

        return error;
      },
    });
  }

  public ngOnDestroy(): void {
    this.submitting$.complete();
  }
}
