import { Injectable } from '@angular/core';
import {
  InAppNotificationLogsQuery,
  InAppNotificationLogType,
  MarkReadAllInAppNotificationLogMutation,
  ReadInAppNotificationLogMutation,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class InAppNotificationLogService {
  public constructor(
    private _inAppNotificationLogsQuery: InAppNotificationLogsQuery,
    private _readInAppNotificationLogMutation: ReadInAppNotificationLogMutation,
    private _markReadAllInAppNotificationLogMutation: MarkReadAllInAppNotificationLogMutation,
  ) {}

  public getInAppNotificationLogs(): Observable<InAppNotificationLogType[]> {
    return this._inAppNotificationLogsQuery
      .fetch(
        {},
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map(
          (response) =>
            response.data.inAppNotificationLogs as InAppNotificationLogType[],
        ),
      );
  }

  public readInAppNotification(logId: string): Observable<boolean> {
    return this._readInAppNotificationLogMutation
      .mutate({
        logId,
      })
      .pipe(map((response) => response.data.readInAppNotificationLog));
  }

  public markReadAllInAppNotificationLog(): Observable<boolean> {
    return this._markReadAllInAppNotificationLogMutation
      .mutate()
      .pipe(map((response) => response.data.markReadAllInAppNotificationLog));
  }
}
