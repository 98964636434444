import * as localforage from 'localforage';
import { InjectionToken, Provider } from '@angular/core';
import { environment } from '@environment/environment';

export const APP_DB = new InjectionToken('APP_DB');

localforage.config({
  storeName: environment.appDBName,
});

export const APP_DB_CONFIG: Provider = {
  provide: APP_DB,
  useValue: localforage,
};
