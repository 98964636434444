<div class="flex flex-row w-full justify-between">
  <h1 mat-dialog-title
      class="!mb-0 !text-[2.2rem] text-darkGray">{{ title }}</h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="text-darkGray text-opacity-80">close</mat-icon>
  </button>
</div>
<mat-divider class="!my-3"></mat-divider>
<mat-dialog-content>
  <p class="!text-sub !text-darkGray whitespace-pre-line">
    {{ confirmMessage }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button
          class="bg-grey-800 text-white w-48"
          [mat-dialog-close]="ConfirmDialogAction.Cancel">Cancel
  </button>
  <button type="button"
          [mat-dialog-close]="ConfirmDialogAction.Confirm"
          mat-raised-button
          class="w-48"
          color="primary">Confirm
  </button>
</mat-dialog-actions>
