import { BaseModel } from "@shared/models/base.model";
import { AddressType } from "@generated/graphql";
import { Country } from "@modules/country/models/country.model";
import { countryFactory } from "@modules/country/factories/country.factory";

export class Address extends BaseModel implements AddressType {
  id: string;
  address: string;
  apartment?: string;
  city?: string;
  country?: Country;
  phone?: string;
  state?: string;
  zip?: string;

  constructor (state: AddressType) {
    super(state);

    this.country = state.country ? countryFactory.create(state.country) : null
  }

  get fullAddress (): string {
    return [
      this.apartment,
      this.address,
      this.city,
      this.state,
      this.zip,
    ].filter(item => !!item).join(', ')
  }

  getModelName (): string {
    return Address.name;
  }
}
