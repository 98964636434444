import {BaseModel} from "@shared/models/base.model";
import {AddressTypeEnum, AddressTypeType} from "@generated/graphql";

export class AddressType extends BaseModel implements AddressTypeType {
  id: string;
  name: AddressTypeEnum;
  __typename: "AddressTypeType";

  constructor(state: AddressTypeType) {
    super(state);
  }

  getModelName(): string {
    return AddressType.name;
  }

  isIndividual(): boolean {
    return this.name === AddressTypeEnum.Individual
  }

  isCorporate(): boolean {
    return this.name === AddressTypeEnum.Corporate
  }
}
