import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, defer, map, of, switchMap, tap,} from 'rxjs';
import {AuthService} from '@modules/auth/services/auth.service';
import {impersonateActions} from '@modules/auth/store/actions/impersonate.actions';
import {AuthApi} from '@modules/auth/api/auth.api';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {Router} from '@angular/router';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

@Injectable()
export class ImpersonateEffects {
  onImpersonate$ = createEffect(() => this.actions$.pipe(
      ofType(impersonateActions.impersonateUser.type),
      tap(() => this.fullScreenSpinnerService.setLoading(true)),
      tap(() => this.router.navigate(this.appRoutes.dashboard())),
      switchMap(({id}: ReturnType<typeof impersonateActions.impersonateUser>) => this.authApi.impersonate(id)),
      map(user => {
        return user ? impersonateActions.impersonateUserSuccess({user}) : impersonateActions.impersonateUserError(null);
      }),
      catchError((e) => {
        return of(impersonateActions.impersonateUserError(e));
      }),
    ),
  );

  onImpersonateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.impersonateUserSuccess.type),
    map(({user}: ReturnType<typeof impersonateActions.impersonateUserSuccess>) => impersonateActions.setImpersonateUser({user})),
    tap(() => this.fullScreenSpinnerService.setLoading(false)),
    tap(() => {
      this.snackBarService.pushAlert('Impersonation successful!', 'Success', SnackBarTypeEnum.success)
    }),
  ));

  onImpersonateError$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.impersonateUserError.type),
    tap(() => this.fullScreenSpinnerService.setLoading(false)),
    tap((e: ReturnType<typeof impersonateActions.impersonateUserError>) => {
      this.snackBarService.pushAlert('Impersonation failed. Please try again or contact support.', 'Error', SnackBarTypeEnum.error)
    }),
  ), {
    dispatch: false,
  });


  onStopImpersonate$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.stopImpersonate.type),
    tap(() => this.fullScreenSpinnerService.setLoading(true)),
    tap(() => this.router.navigate(this.appRoutes.dashboard())),
    switchMap(() => this.authApi.stopImpersonate()),
    tap(() => {
      this.snackBarService.pushAlert('Impersonation mode exited.')
    }),
    map(result => impersonateActions.stopImpersonateSuccess()),
    catchError((e) => of(impersonateActions.stopImpersonateError())),
  ));

  onStopImpersonateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.stopImpersonateSuccess.type),
    tap(() => this.fullScreenSpinnerService.setLoading(false)),
    tap(() => {
      this.snackBarService.pushAlert('Impersonation mode exited.')

    }),
    map((e: ReturnType<typeof impersonateActions.stopImpersonateSuccess>) => {
      return impersonateActions.setImpersonateUser({user: null});
    }),
  ));

  onStopImpersonateError$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.stopImpersonateError.type),
    tap(() => this.fullScreenSpinnerService.setLoading(false)),
    map((e: ReturnType<typeof impersonateActions.stopImpersonateSuccess>) => {
      return impersonateActions.setImpersonateUser({user: null});
    }),
  ));

  onSetImpersonateUser$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateActions.setImpersonateUser.type),
    tap(({user}) => {
      // TODO write new logic here when set impersonate user
    }),
    switchMap(() => this.authService.auth$),
    switchMap(auth => defer(() => this.authService.setAuthToLocalStorage(auth))),
  ), {
    dispatch: false,
  });

  constructor(
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
    private authApi: AuthApi,
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private snackBarService: SnackBarService,
    private readonly fullScreenSpinnerService: ScreenSpinnerService
  ) {
  }
}
