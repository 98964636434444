import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {Job} from '@app/modules/job/models/job.model';
import {Store} from '@ngrx/store';
import {countryStore} from '@app/modules/country/store/country.store';
import {CountryType, OfficerTypeEnum} from '@generated/graphql';
import {
  OfficerInputReviewInformationComponent
} from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import {OfficerInputHelpers} from '@shared/classes/officer/officer-input.helper';
import {
  TransferSharesConfirmationComponent
} from '@shared/component/company/corporate-request/transfer-share/components/transfer-shares-confimration/transfer-shares-confirmation.component';
import {
  SharesTransferDetailReviewComponent
} from '@shared/component/shareholder/components/shares-transfer-detail-review/shares-transfer-detail-review.component';
import {CalloutComponent} from "@shared/component/message/callout/callout.component";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";
import {LetModule} from "@ngrx/component";
import {
  KYCReminderMessageComponent
} from "@shared/component/message/kyc-reminder-message/kyc-reminder-message.component";

@Component({
  selector: 'app-transfer-shares-info',
  standalone: true,
  templateUrl: './transfer-shares-info.component.html',
  styleUrls: ['./transfer-shares-info.component.scss'],
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
    TransferSharesConfirmationComponent,
    SharesTransferDetailReviewComponent,
    CalloutComponent,
    LetModule,
    KYCReminderMessageComponent,
  ],
})
export class TransferSharesInfoComponent implements OnInit {
  @Input() public job: Job;

  @Input() hideSharesTransferDetail = false;

  get jobDetail() {
    return this.job.detailObject;
  }

  countries: CountryType[];

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    protected readonly commonHelper: CommonHelper,
    private _store: Store,
    private _officerInputHelper: OfficerInputHelpers,
  ) {
  }

  ngOnInit(): void {
    this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public getTransferor() {
    return this.jobDetail.transferor;
  }

  public getTransferorType() {
    return this.jobDetail.transferor.officerType as OfficerTypeEnum;
  }

  public getTransferee() {
    return this.jobDetail.transferSharesInput.transfereeInput;
  }

  public getTransfereeType() {
    return this.jobDetail.transferSharesInput.transfereeInput
      .officerType as OfficerTypeEnum;
  }

  public getSharesTransferDetails() {
    return this.jobDetail.transferSharesInput.transfereeInput
      .sharesTransferDetails;
  }
}
