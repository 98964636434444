<div
  class="register-company-capital-wrapper table-container table-container-limited-scroll !max-h-[28rem] mat-elevation-z1">
  <ng-container *ngIf="shareTypes">
    <table mat-table class="table-custom" [dataSource]="dataSource">
      <!--    AMOUNT COLUMN-->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="!text-white">
          Capital Amount
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.totalAmount }}
        </td>
      </ng-container>

      <!--    NUMBER OF SHARES COLUMN-->
      <ng-container matColumnDef="numberOfShares">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Number of Shares
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.totalShares |number }}
        </td>
      </ng-container>

      <!--    CURRENCY COLUMN-->
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">Currency</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          {{ element.currency }}
        </td>
      </ng-container>

      <!--    SHARE TYPE COLUMN-->
      <ng-container matColumnDef="shareType">
        <th mat-header-cell *matHeaderCellDef class="!text-white !text-center">
          Share Type
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!capitalize text-center">
          {{ getShareTypeName(element.shareTypeId).toLowerCase() }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="companyCapitalColumns; sticky: true" class="bg-primary-800"></tr>
      <tr mat-row *matRowDef="let row; columns: companyCapitalColumns"></tr>
    </table>
  </ng-container>
</div>
