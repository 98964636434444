import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RegisterInput, UserType } from '@generated/graphql';
import { AuthState } from '@modules/auth/store/reducers/auth.reducer';

export const registerActions = createActionGroup({
  source: 'Register',
  events: {
    'Register': props<{input: RegisterInput}>(),
    'Register Success': props<{user: UserType}>(),
    'Register Error': props<{registerError: AuthState['registerError']}>(),
    'Refresh State': emptyProps(),
  },
});
