import { Pipe, PipeTransform } from '@angular/core';
import { upperFirst } from 'lodash-es';

@Pipe({
  name: 'salutation',
})
export class SalutationPipe implements PipeTransform {
  transform (value: string): unknown {
    return upperFirst(value?.toLowerCase()?.concat('.') || '');
  }
}
