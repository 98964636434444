import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { createReducer, on } from '@ngrx/store';
import { CreateJobActions } from './create-job.actions';

export type CreateJobStoreState = {
  countryOfIncorporation: SupportedCountry;
};

export const initialState: CreateJobStoreState = {
  countryOfIncorporation: undefined,
};

export const createJobReducer = createReducer<CreateJobStoreState>(
  initialState,
  on(
    CreateJobActions.setOperationCountry,
    (state, { countryOfIncorporation }): CreateJobStoreState => ({
      ...state,
      countryOfIncorporation: countryOfIncorporation,
    })
  )
);
