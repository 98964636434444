<div class="lookup-officer-wrapper">
    <h1 mat-dialog-title
        class="mb-2 text-darkGray !text-[2.2rem]">
        <span>Lookup Information</span>
    </h1>
    <mat-dialog-content>
        <section class="officer-type-container">
            <div class="flex flex-col mb-4">
                <p class="mat-title !text-xbase text-darkGray !mb-2">Type</p>
                <mat-radio-group aria-label="Select an option"
                                 class="group-selection mb-4 inline-flex"
                                 [(ngModel)]="lookupOfficerDialogManagement.type"
                                 [ngModelOptions]="{ standalone: true }">
                    <mat-radio-button class="select-option mr-4 !transform:capitalize"
                                      *ngFor="let option of officerTypeOption"
                                      [value]="option.value"
                                      (change)="onSelectionChange($event)">
                        <p class="mat-subheading-1 text-darkGray !mb-0 text-transform: capitalize">
                            {{ option.label }}
                        </p>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </section>
        <section class="flex flex-col items-end search-input-container">
            <div class="grid grid-cols-2 gap-2 w-full"
                 *ngIf="lookupOfficerDialogManagement.isIndividual()"
                 [formGroup]="lookupOfficerDialogManagement.individualForm">
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput
                           placeholder="First name"
                           formControlName="firstName"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput
                           placeholder="Last name"
                           formControlName="lastName"/>
                </mat-form-field>
                <mat-form-field *ngIf="officerIdentificationTypes$ | async as identificationTypes"
                                appearance="outline">
                    <mat-label>Identification Type</mat-label>
                    <mat-select formControlName="identificationTypeId">
                        <mat-option *ngFor="let type of identificationTypes"
                                    [value]="type.id">{{ type.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Identification No</mat-label>
                    <input matInput
                           placeholder="Identification number"
                           formControlName="identificationNumber"/>
                </mat-form-field>
            </div>

            <div class="grid grid-cols-2 gap-2 w-full"
                 *ngIf="lookupOfficerDialogManagement.isCorporate()"
                 [formGroup]="lookupOfficerDialogManagement.corporateForm">
                <mat-form-field appearance="outline">
                    <mat-label>Corporate Name</mat-label>
                    <input matInput
                           placeholder="Corporate name"
                           formControlName="name"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Registration No</mat-label>
                    <input matInput
                           placeholder="Registration number"
                           formControlName="registrationNumber"/>
                </mat-form-field>
            </div>
            <button mat-raised-button
                    color="primary"
                    [disabled]="lookupOfficerDialogManagement.form.invalid"
                    (click)="lookupHandler()">
                <mat-icon>search</mat-icon>
                Search
            </button>
        </section>
        <section class="search-result-container w-full mt-2">
            <div *ngIf="lookupOfficerDialogManagement.fetching$ | async"
                 class="flex justify-center py-2">
                <mat-spinner [diameter]="30"></mat-spinner>
            </div>
            <mat-divider class="!my-2 !w-3/4 !m-auto"></mat-divider>
            <p class="mat-title !text-xbase text-darkGray !mb-2">Search results:</p>
            <app-callout customContent>
                <div class="flex flex-col items-end table-container">
                    <!-- INDIVIDUALS TABLE -->
                    <ng-container *ngIf="lookupOfficerDialogManagement.isIndividual()">
                        <table mat-table
                               [dataSource]="lookupOfficerDialogManagement.officerPeople"
                               class="table-custom"
                               width="100%">
                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell
                                    *matHeaderCellDef>Name
                                </th>
                                <td mat-cell
                                    *matCellDef="let element">
                                    {{ element.firstName + " " + element.lastName }}
                                </td>
                            </ng-container>

                            <!-- Nationality Column -->
                            <ng-container matColumnDef="nationality">
                                <th mat-header-cell
                                    *matHeaderCellDef>Nationality
                                </th>
                                <td mat-cell
                                    class="text-center"
                                    *matCellDef="let element">
                                    {{element.identification.nationality.name}}
                                </td>
                            </ng-container>

                            <!-- Identification Type Column -->
                            <ng-container matColumnDef="identificationType">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    Identification Document
                                </th>
                                <td mat-cell
                                    *matCellDef="let element"
                                    class="text-center">
                                    {{ element.identification.officerIdentificationType.name }}
                                </td>
                            </ng-container>

                            <!-- Identification Number Column -->
                            <ng-container matColumnDef="identificationNumber">
                                <th mat-header-cell
                                    *matHeaderCellDef>Identification No
                                </th>
                                <td mat-cell
                                    *matCellDef="let element"
                                    class="text-center">
                                    {{ element.identification.identificationNumber }}
                                </td>
                            </ng-container>

                            <!-- Actions Column -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell
                                    *matHeaderCellDef
                                    width="10%"></th>
                                <td mat-cell
                                    class="text-center"
                                    *matCellDef="let element; let index = index">
                                    <button mat-icon-button
                                            color="primary"
                                            [mat-dialog-close]="retreiveHandler(index)">
                                        <mat-icon>call_received</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row
                                *matHeaderRowDef="officerPeopleDisplayedColumns"></tr>
                            <tr mat-row
                                *matRowDef="let row; columns: officerPeopleDisplayedColumns"></tr>
                            <tr class="mat-row"
                                *matNoDataRow>
                                <td [colSpan]="officerPeopleDisplayedColumns.length">
                                    <table-no-record [notFoundMessage]="'No data'"></table-no-record>
                                </td>
                            </tr>
                        </table>
                    </ng-container>

                    <!-- CORPORATES TABLE -->
                    <ng-container *ngIf="lookupOfficerDialogManagement.isCorporate()">
                        <table mat-table
                               [dataSource]="lookupOfficerDialogManagement.officerCompanies"
                               class="table-custom"
                               width="100%">
                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell
                                    *matHeaderCellDef>Name
                                </th>
                                <td mat-cell
                                    *matCellDef="let element">
                                    {{ element.name }}
                                </td>
                            </ng-container>

                            <!-- Registration Number Column -->
                            <ng-container matColumnDef="registrationNumber">
                                <th mat-header-cell
                                    *matHeaderCellDef>Registration No
                                </th>
                                <td mat-cell
                                    *matCellDef="let element">
                                    {{ element.registrationNumber }}
                                </td>
                            </ng-container>

                            <!-- Country Column -->
                            <ng-container matColumnDef="country">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    Country of Incorporation
                                </th>
                                <td mat-cell
                                    *matCellDef="let element"
                                    class="text-center">
                                    {{ element.country.name }}
                                </td>
                            </ng-container>

                            <!-- Actions Column -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell
                                    *matHeaderCellDef
                                    width="10%"></th>
                                <td mat-cell
                                    class="text-center"
                                    *matCellDef="let element; let index = index">
                                    <button mat-icon-button
                                            color="primary"
                                            [mat-dialog-close]="retreiveHandler(index)">
                                        <mat-icon>call_received</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row
                                *matHeaderRowDef="officerCompanyDisplayedColumns"></tr>
                            <tr mat-row
                                *matRowDef="let row; columns: officerCompanyDisplayedColumns"></tr>
                            <tr class="mat-row"
                                *matNoDataRow>
                                <td [colSpan]="officerCompanyDisplayedColumns.length">
                                    <table-no-record [notFoundMessage]="'No data'"></table-no-record>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                </div>
            </app-callout>
        </section>
    </mat-dialog-content>
</div>
