import { FormArray } from '@angular/forms';
import {
  CompanyDirectorInput,
  CompanyShareholderInput,
  OfficerCompanyInput,
  OfficerCompanyType,
  OfficerPersonInput,
  OfficerPersonType,
  OfficerType,
  OfficerTypeEnum,
  SalutationEnum,
  SupportedCountryType,
} from '@generated/graphql';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { OfficerFormFactory } from '@shared/classes/officer/officer-form-factory';
import { OfficerInputHelper } from '@shared/helpers/officer-input.helper';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { sortBy, uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { ExistsOfficerType } from './exists-officer.type';

export abstract class OfficerDialogManagement {
  type: string | 'new' | 'edit';

  officerRole: OfficerRoleEnum;

  title: string;

  informationLabel: string;

  formType: OfficerTypeEnum;

  officerFormManagement: AbstractOfficerForm;

  existOfficers: ExistsOfficerType[] = [];

  _countryOfIncorporation: SupportedCountryType;
  get countryOfIncorporation() {
    return this._countryOfIncorporation;
  }
  set countryOfIncorporation(country: SupportedCountryType) {
    this._countryOfIncorporation = country;
  }

  officerHelper = new OfficerHelper();

  constructor(officerRole: OfficerRoleEnum, type: string) {
    this.officerRole = officerRole;

    this.type = type;
    this.setTitle();
    this.setInformationLabel();
    this.setFormType(OfficerTypeEnum.Individual);
  }

  resetOfficerForm(): this {
    this.officerFormManagement.officerForm = undefined;

    return this;
  }

  isDirector(): boolean {
    return this.officerRole === OfficerRoleEnum.DIRECTOR;
  }

  isShareholder(): boolean {
    return this.officerRole === OfficerRoleEnum.SHAREHOLDER;
  }

  setFormType(formType: OfficerTypeEnum): this {
    this.formType = formType;

    return this;
  }

  createFormManagement(): this {
    let shareDistributionForm = undefined;

    if (this.officerFormManagement?.shareDistributionForm) {
      shareDistributionForm = this.officerFormManagement.shareDistributionForm;
    }

    this.officerFormManagement = OfficerFormFactory.createForm(this.formType);
    this.officerFormManagement.createOfficerForm();

    if (!shareDistributionForm) {
      this.officerFormManagement.createShareDistributionsForm();
    } else {
      this.officerFormManagement.shareDistributionForm = shareDistributionForm;
    }

    return this;
  }

  getOfficerForm() {
    return this.officerFormManagement.officerForm;
  }

  setUpRefForm(): this {
    setTimeout(() => {
      this.getOfficerForm().disable();
    }, 256);

    return this;
  }

  /**
   * Set officers from current job
   */
  setCreatingOfficers(officers: FormArray): this {
    if (officers && officers.length) {
      const normalOfficers = officers
        .getRawValue()
        .filter((o) => !o.refId || !o.refId.includes('temporary-'))
        .map((o) => ({
          id: o.id,
          name: OfficerInputHelper.getOfficerName(
            o.officerCompany || o.officerPerson,
          ),
          email: OfficerInputHelper.getOfficerEmail(
            o.officerPerson || o.officerCompany,
          ),
          type: o.officerType as OfficerTypeEnum,
          refId: o.refId,
        }));

      const representative = officers
        .getRawValue()
        .filter((o) => o.officerType === OfficerTypeEnum.Corporate)
        .map((o) => ({
          id: o.officerCompany.representative.id,
          name: OfficerInputHelper.getOfficerName(
            o.officerCompany.representative,
          ),
          email: OfficerInputHelper.getOfficerEmail(
            o.officerCompany.representative,
          ),
          type: OfficerTypeEnum.Individual,
          refId: o.officerCompany.representative.id,
        }));

      this.existOfficers = [
        ...this.existOfficers,
        ...normalOfficers,
        ...representative,
      ];

      this.filterAndSortExistOfficers();
    }

    return this;
  }

  /**
   * Set officers from current customer's company
   */
  setExistOfficers(officers: OfficerType[]): this {
    if (officers.length) {
      const normalOfficers = officers.map((o) => ({
        id: o.id,
        name: this.officerHelper.getOfficerName(o.officerProfile),
        email: this.officerHelper.getOfficerEmail(o.officerProfile),
        type:
          o.officerProfile.__typename === 'OfficerCompanyType'
            ? OfficerTypeEnum.Corporate
            : OfficerTypeEnum.Individual,
        refId: o.id,
      }));

      const representative = officers
        .filter((o) => o.officerProfile.__typename === 'OfficerCompanyType')
        .map((o) => {
          const representative = (o.officerProfile as OfficerCompanyType)
            .representative;
          return {
            id: representative.id,
            name: this.officerHelper.getOfficerName(
              representative.officerProfile,
            ),
            email: this.officerHelper.getOfficerEmail(
              representative.officerProfile,
            ),
            type: OfficerTypeEnum.Individual,
            refId: representative.id,
          };
        });

      this.existOfficers = [
        ...this.existOfficers,
        ...normalOfficers,
        ...representative,
      ];

      this.filterAndSortExistOfficers();
    }

    return this;
  }

  filterAndSortExistOfficers(): this {
    if (
      this.countryOfIncorporation.country.code.toUpperCase() == 'SG' &&
      this.officerRole === OfficerRoleEnum.DIRECTOR
    ) {
      this.existOfficers = this.existOfficers.filter(
        (e) => e.type !== OfficerTypeEnum.Corporate,
      );
    }

    this.existOfficers = sortBy(
      uniqBy(this.existOfficers, (o) => o.id),
      (o) => o.name,
    );

    return this;
  }

  transformOfficerPerson(officerProfile: OfficerPersonType) {
    const officerProfileInput: OfficerPersonInput = {
      address: officerProfile.address.address,
      apartment: officerProfile.address.apartment,
      city: officerProfile.address.city,
      countryId: officerProfile.address.country.id,
      phone: officerProfile.address.phone,
      state: officerProfile.address.state,
      zip: officerProfile.address.zip,
      email: officerProfile.email,
      firstName: officerProfile.firstName,
      lastName: officerProfile.lastName,
      salutation: officerProfile.salutation as SalutationEnum,
      identification: {
        expiryDate: DateTime.fromISO(
          officerProfile.identification.expiryDate,
        ).toJSDate(),
        identificationNumber:
          officerProfile.identification.identificationNumber,
        nationalityId: officerProfile.identification.nationality.id,
        officerIdentificationTypeId:
          officerProfile.identification.officerIdentificationType.id,
      },
    };

    return officerProfileInput;
  }

  transformOfficerCompany(officerProfile: OfficerCompanyType) {
    const officerProfileInput: OfficerCompanyInput = {
      address: {
        address: officerProfile.address.address,
        apartment: officerProfile.address.apartment,
        city: officerProfile.address.city,
        countryId: officerProfile.address.country.id,
        phone: officerProfile.address.phone,
        state: officerProfile.address.state,
        zip: officerProfile.address.zip,
      },
      countryId: officerProfile.country.id,
      email: officerProfile.email,
      name: officerProfile.name,
      registrationDate: DateTime.fromISO(
        officerProfile.registrationDate,
      ).toJSDate(),
      registrationNumber: officerProfile.registrationNumber,
      website: officerProfile.website,
      representative: {
        id: officerProfile.representative.id,
        ...this.transformOfficerPerson(
          officerProfile.representative.officerProfile as OfficerPersonType,
        ),
      },
    };

    return officerProfileInput;
  }

  abstract setLookupData(officer: OfficerType, type: OfficerTypeEnum): this;

  abstract isOfficerFormValid(): boolean;

  abstract setTitle(): this;

  abstract setInformationLabel(): this;

  abstract prepareEditData(
    data: CompanyDirectorInput | CompanyShareholderInput,
  ): this;
}
