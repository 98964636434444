import {isValidPhoneNumber} from 'libphonenumber-js';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {NestedKeyOf} from '@shared/types/common.type';
import {InvalidControlScrollDirective} from '@shared/share-directives/directives/invalid-control-scroll.directive';
import {interval, take, tap} from 'rxjs';
import {cloneDeep} from 'lodash-es';
import {SnackBarService} from "@shared/services/snack-bar.service";
import {DateTime} from "luxon";

export type ValidationErrorRecord<T extends string> = Record<keyof T, string[]>

export type Prefix<T extends object> = NestedKeyOf<T> | string | 'input' | null

export interface HandleErrorValidationContract {
  hasError(key: string): boolean;

  getMessages(key: string): string[];
}

export class ValidationError<T extends Record<string, any>> implements HandleErrorValidationContract {
  public scrollEl: InvalidControlScrollDirective;

  public isScroll: boolean = true;

  protected shouldDisplayErrorBySnackBar: boolean = false

  protected snackBarService: SnackBarService

  protected prefix: Prefix<T> = 'input'

  constructor(
    public error: ValidationErrorRecord<NestedKeyOf<T>>,
  ) {
    if (!this.error) {
      throw new Error('Errors passed are not valid');
    }
  }

  getMessages(key: NestedKeyOf<T>): string[] {
    if (!this.error) {
      return [];
    }

    return Object.entries(this.error)
      .filter(([k, value]) => k === key)
      .map(([k, value]) => value)
      .flat(1);
  }

  setPrefix(prefix: Prefix<T> = 'input'): ValidationError<T> {
    this.prefix = prefix

    return this
  }

  getAllMessages(): string[] {
    if (!this.error) {
      return [];
    }

    return Object.entries(this.error)
      .map(([k, value]) => value)
      .flat(1);
  }

  hasError(key: NestedKeyOf<T>): boolean {
    if (!this.error) {
      return false;
    }

    return Object.keys(this.error).includes(key);
  }

  showErrors(control: AbstractControl, prefix: ValidationError<T>['prefix'] = 'input'): void {
    if (!this.error) {
      return;
    }

    this.setPrefix(prefix)

    this.getFilterErrors().forEach(([key, value]) => {
      let searchKey: string = key;

      if (this.prefix) {
        searchKey = key.split('.').filter(key => !this.prefix.split('.').includes(key)).join('.');
      }

      const exactlyErrorControl = control.get(searchKey)

      exactlyErrorControl?.setErrors({
        validationErrors: value.join(' '),
      });
    });

    this.handleDisplayErrorBySnackBar()

    this.handleScroll();
  }

  getFilterErrors() {
    return Object.entries(this.error).filter(([key]) => this.prefix ? key.includes(this.prefix) : true)
  }

  setShouldDisplayErrorBySnackBar(shouldDisplayErrorBySnackBar: boolean) {
    this.shouldDisplayErrorBySnackBar = shouldDisplayErrorBySnackBar

    return this
  }

  setSnackBarService(snackBarService: SnackBarService): ValidationError<T> {
    this.snackBarService = snackBarService

    return this
  }

  useSnackBarServiceToDisplayError(snackBarService: SnackBarService): ValidationError<T> {
    return this.setSnackBarService(snackBarService).setShouldDisplayErrorBySnackBar(true)
  }

  setIsScroll(isScroll: boolean = true): ValidationError<T> {
    this.isScroll = isScroll;

    return this;
  }

  setScrollEl(scrollEl: InvalidControlScrollDirective): ValidationError<T> {
    this.scrollEl = cloneDeep(scrollEl);

    return this;
  }

  protected handleDisplayErrorBySnackBar(): void {
    if (this.shouldDisplayErrorBySnackBar && this.snackBarService instanceof SnackBarService) {
      // TODO implement later
    }
  }

  protected handleScroll() {
    if (this.isScroll && this.scrollEl) {
      interval(100)
        .pipe(
          take(5),
          tap(() => {
            this.scrollEl.scrollToFirstError();
          })
        ).subscribe();
    }
  }
}

export class ValidationErrorFactory {
  create<T extends Record<string, any>>(error: ValidationErrorRecord<NestedKeyOf<T>>) {
    return new ValidationError<T>(error);
  }
}

export const validationErrorFactory = new ValidationErrorFactory();

export function FieldMatchValidator<T extends Record<string, any> = {}>(key: {
  firstField: NestedKeyOf<T>,
  secondField: NestedKeyOf<T>
  firstFieldLabel: string
  secondFieldLabel: string
}, match: boolean = true): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const {firstField, secondField} = key;
    let {firstFieldLabel, secondFieldLabel} = key;

    const firstFieldValue = control.value[firstField];
    const secondFieldValue = control.value[secondField];
    const errorKey = match ? 'mismatch' : 'match';

    let error = {
      [errorKey]: match ? `${secondFieldLabel} not matching` : `${secondFieldLabel} should not same as ${firstFieldLabel}`,
    };

    if (!secondFieldValue || !firstFieldValue || (match && secondFieldValue === firstFieldValue) || (!match && secondFieldValue !== firstFieldValue)) {
      error = null;
    } else {
      control.get(secondField)?.setErrors(error);
    }

    return error;
  };
}

export function PhoneValidator(control: AbstractControl): ValidationErrors | null {
  const phone = control.value;

  if (!phone) {
    return null;
  }

  return isValidPhoneNumber(phone)
    ? null
    : {
      phone: {
        message: 'Phone is invalid',
      },
    };
}

export const GstRegistrationDateValidator = (companyRegistrationDate: Date) => (control: AbstractControl): ValidationErrors | null  => {
  const value = control.value;

  if (!value) {
    return null;
  }

  const currentYear = new Date(Date.now())

  // const companyRegistrationDateString = DateTime.fromJSDate(companyRegistrationDate).toISODate()

  const companyIncorporatedYear = companyRegistrationDate.getFullYear();

  if (value.getFullYear() < companyRegistrationDate.getFullYear()) {
    return {
      date: {
        message: `The company was incorporated in ${companyIncorporatedYear}. Please enter a year that is ${companyIncorporatedYear} or later`
      }
    }
  } else if (value.getFullYear() > currentYear.getFullYear()) {
    return {
      date: {
        message: `You cannot enter a year in the future. Please enter a year that is ${currentYear.getFullYear()} or earlier.`
      }
    }
  } else {
    return null;
  }
}

export function MailValidator(control: AbstractControl): ValidationErrors {
  const mail = control.value as string;

  if (!mail) {
    return null;
  }

  return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(mail)
    ? null
    : {
      email: {
        message: 'Mail is invalid',
      },
    };
}
