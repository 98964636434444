import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgxDropzoneChangeEvent, NgxDropzoneModule } from 'ngx-dropzone';
import { CommonModule } from '@angular/common';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { formatBytes } from '@shared/helpers/file.helpers';
import { SharePipesModule } from '@shared/share-pipes/share-pipes.module';

export enum DropzoneAction {
  Added = 'ADDED',
  Removed = 'REMOVED',
}

export interface DropzoneFileChangedEvent {
  files: File[];
  type: DropzoneAction;
}

@Component({
  standalone: true,
  selector: 'app-dropzone-upload',
  templateUrl: './dropzone-upload.component.html',
  styleUrls: ['./dropzone-upload.component.scss'],
  imports: [CommonModule, NgxDropzoneModule, SharePipesModule],
})
export class DropzoneUploadComponent implements OnInit, OnDestroy {
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() imagePreview = false;
  @Input() label = 'Click or drag and drop to upload';
  @Input() id = '';
  @Input() accept = '*';
  @Input() maxFileSize = 5242880; // 5MB

  @Output() filesChanged: EventEmitter<DropzoneFileChangedEvent> =
    new EventEmitter();

  files: File[] = [];

  errors: string[] = [];

  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }

  onSelect(event: NgxDropzoneChangeEvent): void {
    this.errors = [];

    if (event.rejectedFiles.length) {
      this.setErrors(event.rejectedFiles);

      return;
    }

    if (this.multiple) {
      this.files.push(...event.addedFiles);
    } else {
      this.files = event.addedFiles;
    }

    this.filesChanged.emit({
      type: DropzoneAction.Added,
      files: this.files,
    });
  }

  onRemove(event: any): void {
    this.files.splice(this.files.indexOf(event), 1);
    this.filesChanged.emit({
      type: DropzoneAction.Removed,
      files: this.files,
    });
  }

  reset(): void {
    this.files = [];
  }

  setErrors(rejectedFiles: RejectedFile[]) {
    rejectedFiles.forEach((file) => {
      if (file.reason === 'size') {
        this.errors.push(
          `${file.name} too large (${formatBytes(
            file.size,
          )}). Please choose file less than ${formatBytes(this.maxFileSize)}`,
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.files = [];
  }
}
