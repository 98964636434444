import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SupportedCountryService } from "@modules/supported-country/services/supported-country.service";
import { CommonService } from "@shared/services/common.service";
import { MatDialogRef } from "@angular/material/dialog";
import { Maybe, WireTransferBankInput } from "@generated/graphql";
import { catchError, EMPTY, Observable, tap } from "rxjs";
import { Validators } from "@config/validators.config";
import { InvalidControlScrollDirective } from "@shared/share-directives/directives/invalid-control-scroll.directive";


export interface WireTransferBankAccountControls {
  name: FormControl<string>,
  holderName: FormControl<string>
  account: FormControl<string>
  branch: FormControl<string>
  swiftCode: FormControl<string>
  countryId: FormControl<string[]>
}

@Component({
  template: '',
})
export abstract class BankAccountDialogComponent<
  Input extends WireTransferBankInput,
  Controls extends WireTransferBankAccountControls,
  Result = Maybe<unknown>
> implements OnInit {
  static readonly width = '40%'

  form = this.commonService.fb.group<Controls>(this.getControls());

  // visibleResponsibilities$ = this.supportedCountryService.visibleResponsibilities$;

  private _scrollEl: InvalidControlScrollDirective;

  protected constructor (
    public readonly matDialogRef: MatDialogRef<BankAccountDialogComponent<Input, Controls>, Result>,
    public readonly commonService: CommonService,
    protected readonly supportedCountryService: SupportedCountryService,
  ) {
  }

  @ViewChild(InvalidControlScrollDirective)
  set scrollEl (content: InvalidControlScrollDirective) {
    this._scrollEl = content;
  }

  get scrollEl (): InvalidControlScrollDirective {
    return this._scrollEl;
  }

  ngOnInit (): void {
  }

  onCloseDialog (): void {
    this.matDialogRef.close();
  }

  getDefaultControls (): WireTransferBankAccountControls {
    const fb = this.commonService.fb

    return {
      name: fb.control('', [Validators.required]),
      holderName: fb.control('', [Validators.required]),
      account: fb.control('', [Validators.required, Validators.bankAccountNumber()]),
      branch: fb.control('', [Validators.required]),
      swiftCode: fb.control('', [Validators.required, Validators.swiftOrBic]),
      countryId: fb.control(null, [Validators.required])
    } as Controls
  }

  getControls (): Controls {
    return {
      ...this.getDefaultControls()
    } as Controls
  }

  abstract query (input: Input): Observable<Result>

  abstract getInput (): Input;

  onSubmit () {
    this.commonService.setLoading(true)

    this.commonService.snackBarService.dismiss()

    this.query(this.getInput()).pipe(
      tap(result => {
        this.commonService.setLoading(false)

        this.matDialogRef.close(result)
      }),
      catchError(({graphQLErrors}) => {
        this.commonService
          .errorService
          .getValidationErrorObjectList(graphQLErrors)
          .setScrollEl(this.scrollEl)
          .showErrors(this.form, this.getPrefix());

        this.commonService.setLoading(false)

        this.commonService
          .snackBarService
          .pushErrorMessage()

        return EMPTY
      })
    ).subscribe()
  }

  getPrefix (): string {
    return 'input';
  }
}
