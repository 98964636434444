import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { OrderType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { Order } from "@modules/order/models/order.model";

export class OrderStore extends EntityStore<EntityFeatureNameEnum.Order, OrderType, Order> {
  get selectState (): SelectState<OrderType> {
    return this.feature.selectOrderFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.Order> {
    return 'ORDER';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.Order> {
    return 'orderFeature';
  }
}

export const orderStore = new OrderStore()

export const ORDER_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.OrderStore)

export const ORDER_STORE_CONFIG: Provider = {
  provide: ORDER_STORE,
  useValue: orderStore,
}
