<div
  *ngIf="orderSummary$ | async as orderSummary"
  class="order-summary-wrapper"
>
  <ng-container *ngrxLet="businessPlan$ as businessPlan">
    <ng-container *ngIf="orderSummary.items.length">
      <section class="inline-flex w-full">
        <div class="flex-grow basis-2/5 w-2/5">
          <span class="mat-title !text-sub !mb-0 text-darkGray">Item</span>
        </div>
        <div
          *ngIf="businessPlan.isPremium()"
          class="flex-grow basis-1/5 w-1/5 text-center"
        >
          <span class="mat-title !text-sub !mb-0 text-darkGray"
          >Rate ({{ orderSummary.currency }})</span
          >
        </div>
        <div class="flex-grow basis-1/5 w-1/5 text-center">
          <span class="mat-title !text-sub !mb-0 text-darkGray">Quantity</span>
        </div>
        <div
          *ngIf="businessPlan.isPremium()"
          class="flex-grow basis-1/5 w-1/5 text-center"
        >
          <span class="mat-title !text-sub !mb-0 text-darkGray"
          >Amount ({{ orderSummary.currency }})</span
          >
        </div>
      </section>

      <mat-divider class="!my-4"></mat-divider>
      <section class="services-container">
        <div
          *ngFor="let item of orderSummary.items"
          class="service-item inline-flex w-full mb-2"
        >
          <div class="flex-grow basis-2/5 w-2/5">
            <p class="text-sub !mb-0">
              <span>{{ item.name }}</span>
              <span
                *ngIf="
                  businessPlan.isPremium() && item.paymentType?.isSubscription
                "
                class="italic text-darkGray opacity-80"
              >&nbsp;(billed
                {{ item.paymentType.cycleLabel.toLocaleLowerCase() }})</span
              >
            </p>
          </div>
          <div
            *ngIf="businessPlan.isPremium()"
            class="flex-grow basis-1/5 w-1/5 text-center"
          >
            <span
              *ngIf="item.paymentType?.isContactToClient; else showServiceFee"
              class="!text-sub !mb-0"
            >-</span
            >
            <ng-template #showServiceFee>
              <span class="!text-sub !mb-0">{{ item.rate | number }}</span>
            </ng-template>
          </div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0">{{ item.quantity }}</span>
          </div>
          <div
            *ngIf="businessPlan.isPremium()"
            class="flex-grow basis-1/5 w-1/5 text-center"
          >
            <span
              *ngIf="item.paymentType?.isContactToClient"
              class="!text-sub !mb-0"
            >You'll be contacted</span
            >
            <span
              *ngIf="!item.paymentType?.isContactToClient"
              class="!text-sub !mb-0 break-words"
            >
              {{ item.amount | number }}
            </span>
          </div>
        </div>
      </section>
    </ng-container>

    <section
      *ngIf="businessPlan.isPremium()"
      class="inline-flex justify-end w-full"
    >
      <div class="flex flex-col w-2/5">
        <mat-divider class="!my-2"></mat-divider>

        <!--      SUBTOTAL-->
        <div class="inline-flex mb-1 w-full">
          <p class="w-1/2 !mb-0 text-darkGray mat-title !text-sub text-center">
            Subtotal&colon;
          </p>
          <p class="w-1/2 !mb-0 text-center !text-sub">
            {{
              orderSummary.subTotalAmount
                | currency
                : orderSummary.currency
                  : "symbol"
                  : (orderSummary.subTotalAmount % 1 === 0 ? "1.0-0" : "1.2-2")
            }}
          </p>
        </div>
        <!--      TAX-->
        <div
          *ngIf="orderSummary.supportedCountry.includeTax"
          class="inline-flex w-full mb-1"
        >
          <p class="w-1/2 !mb-0 text-darkGray mat-title !text-sub text-center">
            <span class="mat-title !mb-0 !text-sub text-darkGray">
              GST/VAT {{ orderSummary.supportedCountry.taxRate }}&percnt;&colon;
            </span>
          </p>
          <p class="w-1/2 !mb-0 text-center !text-sub">
            {{
              orderSummary.taxAmount && orderSummary.taxAmount !== 0
              ? (orderSummary.taxAmount
                | currency
                : orderSummary.currency
                  : "symbol"
                  : (orderSummary.taxAmount % 1 === 0 ? "1.0-0" : "1.2-2"))
              : "N/A"
            }}
          </p>
        </div>
        <!--      OFFICIAL FEE-->
        <div class="inline-flex w-full">
          <p class="w-1/2 !mb-0 text-darkGray mat-title !text-sub text-center">
            Official fees&colon;
          </p>
          <p class="w-1/2 !mb-0 text-center !text-sub">
            {{
              orderSummary.officialFee && orderSummary.officialFee !== 0
              ? (orderSummary.officialFee
                | currency
                : orderSummary.currency
                  : "symbol"
                  : (orderSummary.officialFee % 1 === 0 ? "1.0-0" : "1.2-2"))
              : "N/A"
            }}
          </p>
        </div>
        <!--      TOTAL-->
        <mat-divider class="!my-2"></mat-divider>
        <div class="inline-flex mb-2 w-full">
          <p class="w-1/2 !mb-0 text-darkGray mat-title !text-base text-center">
            Total&colon;
          </p>
          <p class="w-1/2 !mb-0 text-center mat-title !text-base">
            {{
              orderSummary.totalAmount
                | currency
                : orderSummary.currency
                  : "symbol"
                  : (orderSummary.totalAmount % 1 === 0 ? "1.0-0" : "1.2-2")
            }}
          </p>
        </div>
        <div class="inline-flex mb-2 w-full" *ngIf="orderSummary.currency!=='USD'">
          <p class="w-1/2 !mb-0 text-darkGray mat-title !text-base text-center"></p>
          <p class="w-1/2 !mb-0 text-center mat-title !text-sub !font-medium">
            (Equal to
            {{
              orderSummary.data.order?.payment?.amount || orderSummary.totalAmount * currencyExchangeRate
                | currency
                : "USD"
                  : "code"
                  : (orderSummary.totalAmount % 1 === 0 ? "1.0-0" : "1.2-2")
            }})
          </p>
        </div>
      </div>
    </section>
  </ng-container>
</div>
