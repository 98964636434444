import { SupportedCountry } from '@modules/supported-country/models/supported-country.model';
import { OrderHelper } from '@shared/helpers/order.helpers';
import { ExtraJob } from '@modules/extra-job/models/extra-job.model';
import { PaymentType } from '@modules/payment/models/payment-type.model';
import { OrderDetail } from '@app/modules/job/models/job.model';
import { ServiceEnum } from '@generated/graphql';

export type OrderData = OrderHelper | ExtraJob;

export interface OrderSummaryItem {
  name: string;
  rate: number;
  quantity: number;
  amount: number;
  paymentType?: PaymentType;
}

export class OrderSummary {
  constructor(
    public data: OrderData,
    public currency: string,
    public supportedCountry: SupportedCountry,
    public officialFee: number,
    public subTotalAmount: number,
    public taxAmount: number,
    public totalAmount: number,
  ) { }

  get items(): OrderSummaryItem[] {
    if (this.data instanceof OrderHelper) {
      return this.data.services.map((item) => {
        return {
          name: this.getServiceName(item),
          paymentType: item.service.paymentType,
          quantity: item.quantity,
          rate: item.service.serviceFee,
          amount: item.service.serviceFee * item.quantity,
        };
      });
    }

    if (this.data instanceof ExtraJob) {
      return [
        {
          name: 'Service Fee',
          rate: this.data.serviceFee,
          amount: this.data.serviceFee,
          quantity: 1,
        },
        {
          name: 'Out of Pocket Expenses',
          rate: this.data.outOfPocketExpenses,
          amount: this.data.outOfPocketExpenses,
          quantity: 1,
        },
      ];
    }

    return [];
  }

  private getServiceName(item: OrderDetail) {
    if (item.service) {
      if (item.service.service.name === ServiceEnum.PrepareManagementAccount) {
        return 'Deposit for accounting service';
      }

      if (item.service.service.name === ServiceEnum.PrepareFinancialStatement) {
        return 'Deposit to draft Financial Statements';
      }

      if (
        item.service.service.name ===
        ServiceEnum.PrepareAuditedFinancialStatements
      ) {
        return 'Deposit to draft Audited Financial Statements';
      }
    }

    return item.service.label;
  }
}
