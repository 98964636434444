import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { BusinessProfileService } from '@app/modules/account-settings/pages/business-profile/services/business-profile.service';
import FileService from '@app/modules/file/services/file.service';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { ConversationInfoComponent } from '@modules/job/components/job-detail/conversation/components/conversation-info/conversation-info.component';
import { MessageWrapperComponent } from '@modules/job/components/job-detail/conversation/components/message-wrapper/message-wrapper.component';
import { Job } from '@modules/job/models/job.model';
import { User } from '@modules/user/models/user.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import ConversationManagement from '@shared/classes/conversation/conversation-management';
import {
  ConversationMessage,
  ConversationMessageTypeEnum,
  MessageActionEnum,
} from '@shared/classes/conversation/message-type';
import {
  DropzoneAction,
  DropzoneFileChangedEvent,
  DropzoneUploadComponent,
} from '@shared/component/common/dropzone-upload/dropzone-upload.component';
import { SharedComponentModule } from '@shared/component/component.module';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { ShareDirectivesModule } from '@shared/share-directives/share-directives.module';
import { cloneDeep, keys, uniq } from 'lodash-es';
import {
  NgxDropzoneChangeEvent,
  NgxDropzoneComponent,
  NgxDropzoneModule,
} from 'ngx-dropzone';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TextMessageComponent } from './components/text-message/text-message.component';
import { ConversationService } from './services/conversation.service';

@UntilDestroy()
@Component({
  selector: 'app-conversation',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    SharedComponentModule,
    NgOptimizedImage,
    MatTooltipModule,
    ConversationInfoComponent,
    LetModule,
    TextMessageComponent,
    MatProgressSpinnerModule,
    MessageWrapperComponent,
    DropzoneUploadComponent,
    ShareDirectivesModule,
    NgxDropzoneModule,
    MatProgressBarModule,
  ],
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit, OnDestroy {
  @ViewChild('messagesContainer') messagesContainer: ElementRef<HTMLDivElement>;

  private _job: Job;
  @Input()
  get job(): Job {
    return this._job;
  }

  set job(value: Job) {
    this._job = value;
  }

  private _job$: Observable<Job>;
  public get job$(): Observable<Job> {
    return this._job$;
  }
  @Input()
  public set job$(value: Observable<Job>) {
    this._job$ = value;
  }

  public keys = keys;

  private _conversationManagement: ConversationManagement;
  public get conversationManagement(): ConversationManagement {
    return this._conversationManagement;
  }
  public set conversationManagement(value: ConversationManagement) {
    this._conversationManagement = value;
  }

  protected readonly MessageActionEnum = MessageActionEnum;

  private readonly _messageSelected$ = new BehaviorSubject<any>(null);
  get messageSelected$() {
    return this._messageSelected$.asObservable();
  }

  public showUpload = false;

  public notifying$ = new BehaviorSubject(false);

  private jobSubscription = new Subscription();

  @ViewChild('dropzone') dropzone: NgxDropzoneComponent;

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    private _businessProfileService: BusinessProfileService,
    private _conversationService: ConversationService,
    private _fileService: FileService,
    private _snackbarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      this.conversationManagement = new ConversationManagement();
      this.conversationManagement.conversationService =
        this._conversationService;

      // DEBUG ONLY
      // this._conversationManagement.appState$.subscribe((val) =>
      //   console.log('Service state: %s', val),
      // );

      this.startChatService();

      this.jobSubscription = this.job$.subscribe((job) => {
        if (
          job.participants.length &&
          this.conversationManagement &&
          this.conversationManagement.serviceReady
        ) {
          const members = cloneDeep(job.participants);
          this.conversationManagement.memberManagement.users = members;

          this.conversationManagement.memberManagement.joinUsers();
        }
      });
    });
  }

  private startChatService() {
    this.activeUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this._businessProfileService
        .getBusinessProfile()
        .pipe(untilDestroyed(this))
        .subscribe(async (profile) => {
          this.conversationManagement.businessProfile = profile;
          this.conversationManagement.roomId = `job_${this.job.id}`;

          const members = cloneDeep(this.job.participants);

          if (user.isAdmin() || user.isSuperAdmin()) {
            members.push(user);
          }

          this.conversationManagement.memberManagement.users = members;
          this.conversationManagement.memberManagement.sender = user;

          this.conversationManagement.setup().then(() => {
            this.conversationManagement.appState$.next('get_members');
            this.conversationManagement.getMembers();

            this.conversationManagement.appState$.next('get_messages');
            this.conversationManagement.getMessages();

            this.conversationManagement.fetching$.subscribe((val) => {
              if (val === false) {
                if (
                  keys(this.conversationManagement.groupedMessages).length > 0
                ) {
                  this.scrollToBottom();
                }

                this.conversationManagement.appState$.next('finished');
              }
            });
          });
        });
    });
  }

  public async submitHandler() {
    // trigger upload
    if (this.showUpload && this.conversationManagement.files.length > 0) {
      this.uploadHandler();
    }

    const { body } = this.conversationManagement.form.value;

    if (!body || body.trim() === '') {
      this.conversationManagement.form.reset();

      return;
    }

    await this.conversationManagement.sendMessage();

    this._messageSelected$.next(null);

    this.scrollToBottom();

    if (this.showUpload) {
      this.hideUpload();
    }
  }

  public onCancelAction() {
    this._messageSelected$.next(null);
  }

  public onActionOnMessageSelected(event: {
    action: MessageActionEnum;
    message: ConversationMessage;
  }) {
    if (event.action === MessageActionEnum.Download) {
      this.downloadFile(event.message.file.fileId);
    } else {
      this._messageSelected$.next(event);
      this.conversationManagement.messageAction = event.action;
      this.conversationManagement.editingMessage = event.message;
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    }, 128);
  }

  public async deleteMessageHandler(message: ConversationMessage) {
    await this._conversationManagement.deleteMessage(message);

    if (message.type === ConversationMessageTypeEnum.File) {
      this._conversationService
        .getFiles(this.conversationManagement.roomId)
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }

  public gotoMessageHandler(id: string) {
    document
      .querySelector(`#messageId_${id}`)
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  public onFileChange(event: DropzoneFileChangedEvent) {
    if (event.type === DropzoneAction.Added) {
      this.conversationManagement.files = event.files;
    } else {
      this.conversationManagement.files = event.files;
    }
  }

  private hideUpload(): void {
    this.showUpload = false;
    this.conversationManagement.files = [];
  }

  public onSelect(event: NgxDropzoneChangeEvent) {
    if (event.addedFiles.length > 0) {
      this.conversationManagement.files.push(...event.addedFiles);
    } else {
      this.hideUpload();
    }
  }

  public onRemove(event: File) {
    this.conversationManagement.files.splice(
      this.conversationManagement.files.indexOf(event),
      1,
    );

    if (this.conversationManagement.files.length === 0) {
      this.hideUpload();
    }
  }

  public toggleUpload() {
    if (!this.showUpload) {
      this.showUpload = true;
      this.conversationManagement.files = [];
    }

    setTimeout(() => {
      this.dropzone.showFileSelector();
    }, 128);
  }

  public uploadHandler() {
    this.conversationManagement.uploadFiles();

    this.conversationManagement.uploadStatus$.subscribe((val) => {
      if (val === 'done') {
        this.hideUpload();

        this._conversationService
          .getFiles(this.conversationManagement.roomId)
          .pipe(untilDestroyed(this))
          .subscribe();
      }
    });
  }

  public downloadFile(fileId: string) {
    this._fileService.getFileUrl(fileId, 'DOWNLOAD').subscribe((result) => {
      window.open(result, '_blank');
    });
  }

  public manualSendNotification(): void {
    this.notifying$.next(true);

    const receiverIds = uniq([
      ...this.conversationManagement.memberManagement.members.map((u) => u.id),
      ...this.conversationManagement.memberManagement.users.map((u) => u.id),
    ]).filter(
      (id) => id !== this.conversationManagement.memberManagement.sender.id,
    );

    this._conversationService
      .manualSendNotification(
        this.job.id,
        this.conversationManagement.memberManagement.sender.id,
        receiverIds,
      )
      .subscribe({
        next: () => {
          this.notifying$.next(false);
          this._snackbarService.pushAlert('Notify was sent');
        },
        error: (error) => {
          console.log(error);
          this.notifying$.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.conversationManagement.unsubsribe();
    this.conversationManagement = null;
    this.notifying$.complete();

    this.jobSubscription.unsubscribe();
  }

  unsorted() {
    return 0;
  }
}
