<div class="dialog-wrapper"
     *ngIf="address$ | async as address">
  <h1 mat-dialog-title
      class="mb-0 !text-[2.2rem] text-darkGray">
    <span *ngIf="address.isDefault">Removal Failed</span>
    <span *ngIf="!address.isDefault">Confirm Removal</span>
  </h1>
  <mat-dialog-content>
    <p class="!mb-1 mat-title !text-base text-darkGray">{{address.fullName}}</p>
    <p class="!mb-1 w-[80%] break-words">{{commonHelper.renderAddress(address)}}</p>
    <mat-divider *ngIf="address.isDefault"
                 class="!my-2"></mat-divider>
    <!--   Remove fail note-->
    <div *ngIf="address.isDefault; else deleteNote"
         class="inline-flex items-start">
      <mat-icon color="warn"
                class="!overflow-visible mr-2">error
      </mat-icon>
      <p class="!mb-0 mat-subheading-1 !text-sub">
        <span class="text-red-700">This address is used as your residential address for digital purchases. To delete this address, first</span>
        <span class="text-primary-default">
        set a different residential address for your digital purchases
      </span>
      </p>
    </div>
    <ng-template #deleteNote>
      <p class="mat-caption !mt-4">
        <span class="mat-title text-darkGray !text-sm">Please note:&nbsp;</span>
        <span class="text-darkGray opacity-70">Removing this address will not affect any pending invoices being shipped to this address. To ensure uninterrupted fulfillment of future invoices, please update periodical subscriptions using this address.</span>
      </p>
      <mat-divider></mat-divider>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="address.isDefault ; else confirmSection"
                      align="end">
    <button mat-raised-button
            color="primary"
            (click)="onCloseDialog()">
      Cancel
    </button>

  </mat-dialog-actions>
  <ng-template #confirmSection>
    <mat-dialog-actions align="end">
      <button
        mat-button
        class="bg-grey-800 text-white"
        (click)="onCloseDialog()">
        No
      </button>
      <button
        [disabled]="!id"
        (click)="onSubmit()"
        mat-raised-button
        color="primary">
        Yes
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
