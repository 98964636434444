import { ServiceSupportedCountryType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { ServiceSupportedCountry } from "@modules/service/models/service-supported-country.model";
import { ServiceCategory } from "@modules/service/models/service-category.model";
import { createSelector } from "@ngrx/store";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";

export class ServiceSupportedCountryStore extends EntityStore<EntityFeatureNameEnum.ServiceSupportedCountry, ServiceSupportedCountryType, ServiceSupportedCountry> {
  get selectState (): SelectState<ServiceSupportedCountryType> {
    return this.feature.selectServiceSupportedCountryFeatureState;
  }

  readonly selectByServiceCategoryId = (id: ServiceCategory['id']) => createSelector(
    this.selectIds,
    this.selectEntities,
    (ids, entities) => this.sortByCreatedAt(ids.map(id => entities[id]).filter(item => item.service?.serviceCategory?.id === id))
  );

  getEntry (): EntityEntry<EntityFeatureNameEnum.ServiceSupportedCountry> {
    return 'SERVICE_SUPPORTED_COUNTRY';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.ServiceSupportedCountry> {
    return 'serviceSupportedCountryFeature';
  }

  sortByCreatedAt (items: ServiceSupportedCountryType[]): ServiceSupportedCountryType[] {
    return items.sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return 0
      }

      return a.createdAt < b.createdAt ? 1 : -1
    })
  }
}

export const serviceSupportedCountryStore = new ServiceSupportedCountryStore()
export const SERVICE_SUPPORTED_COUNTRY_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.ServiceSupportedCountryStore)
export const SERVICE_SUPPORTED_COUNTRY_STORE_CONFIG: Provider = {
  provide: SERVICE_SUPPORTED_COUNTRY_STORE,
  useValue: serviceSupportedCountryStore,
}
