import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';
import { ServiceEnum } from '@generated/graphql';
import { JobServicesOrder, OrderDetail } from '../models/job.model';

export class OrderServiceManagement {
  private _jobServiceOrder: JobServicesOrder = {
    mainServices: [],
    additionalServices: [],
  };
  get jobServicesOrder(): JobServicesOrder {
    return this._jobServiceOrder;
  }

  private _incorporationServices: ServiceSupportedCountry[];
  set incorporationServices(services: ServiceSupportedCountry[]) {
    this._incorporationServices = services;
  }
  get incorporationServices(): ServiceSupportedCountry[] {
    return this._incorporationServices;
  }

  constructor() { }

  addOrUpdateService(
    serviceName: ServiceEnum,
    type: 'mainServices' | 'additionalServices',
    quantity = 1,
  ) {
    const existsServiceIndex = this.jobServicesOrder[type].findIndex(
      (js) => js.service.service.name === serviceName,
    );

    const service = this.incorporationServices.find(
      (s) => s.service.name === serviceName,
    );

    if (service) {
      if (existsServiceIndex > -1) {
        this.jobServicesOrder[type][existsServiceIndex] = {
          quantity,
          service,
        };
      } else {
        this.jobServicesOrder[type].push({
          quantity,
          service,
        });
      }
    }
  }

  removeService(
    serviceName: ServiceEnum,
    type: 'mainServices' | 'additionalServices',
  ) {
    this.jobServicesOrder[type] = this.jobServicesOrder[type].filter(
      (js) => js.service.service.name !== serviceName,
    );
  }

  removeMainServiceByIndex(index: number) {
    this._jobServiceOrder.mainServices.splice(index, 1);
  }

  removeAdditionalServiceByIndex(index: number) {
    this._jobServiceOrder.additionalServices.splice(index, 1);
  }

  resetMainServices(): this {
    this._jobServiceOrder.mainServices = [];

    return this;
  }

  resetAdditionalServices(): this {
    this._jobServiceOrder.additionalServices = [];

    return this;
  }

  resetServices(): this {
    this.resetMainServices().resetAdditionalServices();

    return this;
  }

  getAllServices(): OrderDetail[] {
    return [
      ...this._jobServiceOrder.mainServices,
      ...this._jobServiceOrder.additionalServices,
    ];
  }

  // CUSTOM SERVICE
  addOrUpdateCustomService(
    serviceSupportedCountry: ServiceSupportedCountry,
    quantity = 1,
  ) {
    const type = 'additionalServices';

    const existsServiceIndex = this.jobServicesOrder[type].findIndex(
      (js) => js.service.id === serviceSupportedCountry.id,
    );

    const service = this.incorporationServices.find(
      (s) => s.id === serviceSupportedCountry.id,
    );

    if (service) {
      if (existsServiceIndex > -1) {
        this.jobServicesOrder[type][existsServiceIndex] = {
          quantity,
          service,
        };
      } else {
        this.jobServicesOrder[type].push({
          quantity,
          service,
        });
      }
    }
  }

  removeCustomService(serviceSupportedCountry: ServiceSupportedCountry) {
    const type = 'additionalServices';

    this.jobServicesOrder[type] = this.jobServicesOrder[type].filter(
      (js) => js.service.id !== serviceSupportedCountry.id,
    );
  }
}
