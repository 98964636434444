import {BaseModel}                                from '@shared/models/base.model';
import {FileType, PaymentStatusEnum, PaymentType} from '@generated/graphql';
import {Country}                                  from '@modules/country/models/country.model';
import {Payable}                                  from '@modules/payment/models/payable.model';
import {PaymentDetail}                            from '@modules/payment/models/payment-detail.model';
import {paymentDetailFactory}                     from '@modules/payment/factories/payment-detail.factory';
import {countryFactory}                           from '@modules/country/factories/country.factory';
import {AppStatusType}                            from "@shared/component/common/status/status.component";
import {startCase}                                from "lodash-es";
import {PaymentProvider}                          from "@modules/payment/models/payment-provider.model";
import {PaymentType as PaymentTypeModel}          from "@modules/payment/models/payment-type.model";
import {User}                                     from "@modules/user/models/user.model";
import {userFactory}                              from "@modules/user/factories/user.factory";
import {paymentProviderFactory}                   from "@modules/payment/factories/payment-provider.factory";
import {orderFactory}                             from "@modules/order/factories/order.factory";
import {CallOutStyle}                             from "@shared/component/message/callout/callout.component";


export class Payment extends BaseModel implements PaymentType {
    id: string;
    amount: number;
    currency: string;
    detail: PaymentDetail;
    payable: Payable;
    payableCountry: Country;
    payableType: string;
    status: PaymentStatusEnum;
    paymentProvider: PaymentProvider;
    paymentType: PaymentTypeModel;
    user: User;
    proofOfPayment: FileType;
    receiptUrl: string;
    canGetReceiptUrl: boolean;
    isAttemptingPayment: boolean;
    isProcessingPayment: boolean;
    isAwaitingApproval: boolean;
    isRejected: boolean;
    isPaid: boolean;
    showProofOfPayment: boolean;
    _model: string;
    protected _isLoading: boolean;

    constructor(state: PaymentType) {
        super(state);

        this.detail = state.detail ? paymentDetailFactory.create(state.detail) : null;

        this.payable = state.payable ? orderFactory.create(state.payable) : null;

        this.payableCountry = state.payableCountry ? countryFactory.create(state.payableCountry) : null;

        this.user = state.user ? userFactory.create(state.user) : null;

        this.paymentProvider = state.paymentProvider ? paymentProviderFactory.create(state.paymentProvider) : null;
    }

    set loading(loading: boolean) {
        this._isLoading = loading;
    }

    get loading(): boolean {
        return this._isLoading;
    }

    setLoading(loading: boolean): BaseModel {
        this.loading = loading;

        return this;
    }

    getModelName(): string {
        return Payment.name;
    }

    public renderStatusStyle(): AppStatusType {
        switch (this.status) {
            case PaymentStatusEnum.AwaitingApproval:
                return "warn";
            case PaymentStatusEnum.AttemptingPayment:
                return "warn";
            case PaymentStatusEnum.ProcessingPayment:
                return "warn";
            case PaymentStatusEnum.Paid:
                return 'success';
            case PaymentStatusEnum.Failed:
                return "error";
            case PaymentStatusEnum.Rejected:
                return "error";
            default:
                return 'default';
        }
    }

    public renderStatusLabel(): string {
        return startCase(this.status.toLowerCase());
    }

    public renderPaymentMessage(activeUser: User): string {
        if (activeUser.isExpert()) {
            return this.renderMessageForExpert();
        }
        if (activeUser.isCustomer()) {
            return this.renderMessageForCustomer();
        }
        if (activeUser.isAdmin() || activeUser.isSuperAdmin()) {
            return this.renderMessageForAdmin();
        }
        return null;

    }

    public renderPaymentHintMessageStyle(): CallOutStyle {
        switch (this.status) {
            case PaymentStatusEnum.AwaitingApproval:
                return "warn";
            case PaymentStatusEnum.AttemptingPayment:
                return "warn";
            case PaymentStatusEnum.ProcessingPayment:
                return "action-light";
            case PaymentStatusEnum.Failed:
                return "error";
            case PaymentStatusEnum.Rejected:
                return "error";
            default:
                return 'default';
        }
    }

    private renderMessageForCustomer(): string {
        switch (this.status) {
            case PaymentStatusEnum.AwaitingApproval:
                return 'Your payment is pending approval, our experts will begin working on your job once approved.';
            case PaymentStatusEnum.AttemptingPayment:
                return 'Payment in process through Stripe. Almost there, just a few more moments!';
            case PaymentStatusEnum.ProcessingPayment:
                return 'Payment in process through Stripe. Almost there, just a few more moments!';
            case PaymentStatusEnum.Failed:
                return 'Payment failed. Please check your payment information and try again.';
            case PaymentStatusEnum.Rejected:
                return 'Payment rejected. Please ensure the payment details are accurate and try again.';
            default:
                return '';
        }
    }

    private renderMessageForExpert() {
        switch (this.status) {
            case PaymentStatusEnum.AwaitingApproval:
                return 'Customer payment has been received and awaiting review by the admin.';
            case PaymentStatusEnum.AttemptingPayment:
                return 'Customer payment is being processed through Stripe. Please wait for the transaction to complete';
            case PaymentStatusEnum.ProcessingPayment:
                return 'Customer payment is being processed through Stripe. Please wait for the transaction to complete';
            case PaymentStatusEnum.Failed:
                return 'Customer payment unsuccessful. Kindly request them to retry the payment';
            case PaymentStatusEnum.Rejected:
                return 'Customer payment has been rejected by admin. Please reach out to the customer for further assistance and resolution.';
            default:
                return '';
        }
    }

    private renderMessageForAdmin() {
        switch (this.status) {
            case PaymentStatusEnum.AwaitingApproval:
                return 'Proof of payment has been submitted by the customer. The expert will start working upon approval.';
            case PaymentStatusEnum.AttemptingPayment:
                return 'The payment is being processed through Stripe. Please wait for the transaction to complete.';
            case PaymentStatusEnum.ProcessingPayment:
                return 'The payment is being processed through Stripe. Please wait for the transaction to complete.';
            case PaymentStatusEnum.Failed:
                return 'Customer payment unsuccessful. Kindly request them to retry the payment';
            case PaymentStatusEnum.Rejected:
                return 'Customer payment has been rejected. Please reach out to the customer for further assistance and resolution.';
            default:
                return '';
        }
    }
}
