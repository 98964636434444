import { OfficerCompanyInput, OfficerPersonInput } from '@generated/graphql';

export class OfficerInputHelper {
  static getOfficerName(
    officerProfile: OfficerPersonInput | OfficerCompanyInput,
  ): string {
    if ('firstName' in officerProfile) {
      return `${officerProfile.firstName} ${officerProfile.lastName}`;
    }

    if ('name' in officerProfile) {
      return officerProfile.name;
    }

    return '';
  }

  static getOfficerEmail(
    officerProfile: OfficerPersonInput | OfficerCompanyInput,
  ): string {
    return officerProfile.email || '';
  }
}
