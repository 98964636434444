import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {BehaviorSubject, combineLatestWith, debounceTime, first, map, Observable, of, tap} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {MatDialog} from '@angular/material/dialog';
import {
  ExtraJobDialogComponent,
  ExtraJobDialogData
} from '@shared/component/job/dialog/extra-job-dialog/extra-job-dialog.component';
import {ExtraJob} from '@modules/extra-job/models/extra-job.model';
import {Job} from '@modules/job/models/job.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ExtraJobInput, PermissionEnum} from '@generated/graphql';
import {BusinessPlanService} from '@modules/account-settings/pages/business-profile/services/business-plan.service';
import {cloneDeep} from 'lodash-es';
import {FormControl} from '@angular/forms';
import {startWith} from 'rxjs/operators';
import {
  ExtraJobDetailDialogComponent,
  ExtraJobDetailDialogData
} from '@modules/job/components/job-detail/extra-job-detail-dialog/extra-job-detail-dialog.component';
import {APP_ROUTES, AppRoutes} from "@config/app-routes.config";
import {PaymentService} from "@shared/component/payments/services/payment.service";
import {Payment} from "@modules/payment/models/payment.model";
import {CommonService} from "@shared/services/common.service";
import {CommonHelper} from "@shared/helpers/common.helper";

export type ExtraJobsUpdateOrCreateEvent = [ExtraJobDialogComponent, ExtraJobInput]

export type ExtraJobsColumn = (keyof ExtraJob | 'actions')
export type ExtraJobsColumns = ExtraJobsColumn[]

@UntilDestroy()
@Component({
  selector: 'app-extra-jobs[job][extraJobs]',
  templateUrl: './extra-jobs.component.html',
  styleUrls: ['./extra-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraJobsComponent implements OnInit, OnChanges {
  @Input() job: Job;
  @Input() extraJobs: ExtraJob[] = [];
  @Output() updateOrCreate = new EventEmitter<ExtraJobsUpdateOrCreateEvent>();
  @Output() delete = new EventEmitter<ExtraJob>();
  @Output() confirmJob = new EventEmitter<ExtraJob>();
  @Output() closeJob = new EventEmitter<ExtraJob>();

  filteredJobs$: Observable<ExtraJob[]>;

  readonly searchControl = new FormControl<string>('');

  private _tableColumns$ = new BehaviorSubject<ExtraJobsColumns>([])

  private readonly defaultColumns: ExtraJobsColumns = [
    'matter',
    'status',
    'order',
    'totalAmount',
    'actions'
  ];

  private readonly limitColumns: ExtraJobsColumns = [
    'matter',
    'scopeOfWork',
    'status',
    'actions'
  ];

  private _extraJobs$ = new BehaviorSubject<ExtraJob[]>([]);

  get extraJobs$(): Observable<ExtraJob[]> {
    return this._extraJobs$.asObservable();
  }

  get tableColumns$(): Observable<ExtraJobsColumns> {
    return this._tableColumns$.asObservable()
  }

  constructor(
    public readonly commonService: CommonService,
    public readonly commonHelper: CommonHelper,
    @Inject(APP_ROUTES) public readonly appRoutes: AppRoutes,
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    private readonly cdr: ChangeDetectorRef,
    private readonly businessPlanService: BusinessPlanService,
    private readonly paymentService: PaymentService,
    private readonly dialog: MatDialog,
  ) {
  }

  ngOnChanges(): void {
    this.setExtraJobs(this.extraJobs);
    // this.cdr.detectChanges()
  }

  ngOnInit(): void {
    this.setExtraJobs(this.extraJobs);

    this.filteredJobs$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      combineLatestWith(this.extraJobs$),
      map(([searchTxt, jobs]) => {
        if (searchTxt) {
          return jobs.filter(job => job.matter.toLowerCase().includes(searchTxt.toLowerCase()));
        }

        return jobs;
      })
    );

    this.businessPlanService.visiblePayment$().pipe(
      untilDestroyed(this),
      tap(visiblePayment => {
        if (visiblePayment) {
          this._tableColumns$.next([...this.defaultColumns]);
        } else {
          this._tableColumns$.next([...this.limitColumns]);
        }

        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  openDialog(extraJob?: ExtraJob) {
    const dialog = this.dialog.open<
      ExtraJobDialogComponent,
      ExtraJobDialogData,
      ExtraJob
    >(ExtraJobDialogComponent, {
      width: ExtraJobDialogComponent.width,
      autoFocus: false,
      data: {
        job: this.job,
        extraJob: cloneDeep(extraJob)
      }
    });

    const {componentInstance} = dialog;
    const {createOrUpdate} = componentInstance;

    createOrUpdate
      .pipe(
        untilDestroyed(componentInstance),
        tap(input => {
          if (input.id) {
            extraJob.loading = true;
          }

          this.updateOrCreate.emit([componentInstance, input]);
        })
      ).subscribe();
  }

  setExtraJobs(extraJobs: ExtraJob[]): void {
    this._extraJobs$.next([...extraJobs]);
  }

  canMakePayment$(item: ExtraJob): Observable<boolean> {
    return of(!!item.order?.canMakePayment)
  }

  canUpdate$(item: ExtraJob): Observable<boolean> {
    return of(item.canUpdate);
  }

  canDelete$(item: ExtraJob): Observable<boolean> {
    return of(item.canDelete);
  }

  canCreateExtraJob$(): Observable<boolean> {
    return this.activeUser$.pipe(map(user => user && user.hasPermissions(PermissionEnum.CreateExtraJob)));
  }

  onDelete(item: ExtraJob) {
    item.loading = true;

    this.delete.emit(item);
  }

  onConfirm(item: ExtraJob) {
    item.loading = true;

    this.confirmJob.emit(item);
  }

  onClose(item: ExtraJob) {
    item.loading = true;

    this.closeJob.emit(item);
  }

  viewExtraDetail(item: ExtraJob) {
    this.dialog.open<
      ExtraJobDetailDialogComponent,
      ExtraJobDetailDialogData
    >(ExtraJobDetailDialogComponent, {
      autoFocus: false,
      width: ExtraJobDetailDialogComponent.width,
      data: {
        extraJob: item
      }
    });
  }

  openPaymentReceipt(payment: Payment): void {
    this.paymentService.openPaymentReceipt(payment)
  }
}
