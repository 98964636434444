import { Injectable } from '@angular/core';
import {
  CreateWireTransferBankMutation,
  WireTransferBanksQuery,
  WireTransferBankInput,
  WireTransferBankType, UpdateWireTransferBankInput, UpdateWireTransferBankMutation, DeleteWireTransferBankMutation
} from "@generated/graphql";
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import { AddContract } from "@shared/contracts/add.contract";
import { map, Observable } from "rxjs";
import { wireTransferBankFactory } from "@modules/wire-transfer-bank/factories/wire-transfer-bank.factory";
import { UpdateContract } from "@shared/contracts/update.contract";
import { DeleteContract } from "@shared/contracts/delete.contract";

@Injectable({
  providedIn: 'root'
})
export class WireTransferBankApi implements DeleteContract<string, Observable<boolean>>,
  UpdateContract<UpdateWireTransferBankInput, Observable<WireTransferBank>>,
  AddContract<WireTransferBankInput, Observable<WireTransferBank>> {
  constructor (
    protected readonly createWireTransferBankMutation: CreateWireTransferBankMutation,
    protected readonly updateWireTransferBankMutation: UpdateWireTransferBankMutation,
    protected readonly deleteWireTransferBankMutation: DeleteWireTransferBankMutation,
    protected readonly wireTransferBanksQuery: WireTransferBanksQuery
  ) {
  }

  delete$ (id: string): Observable<boolean> {
    return this.deleteWireTransferBankMutation.mutate({id}).pipe(
      map(res => res.data.deleteWireTransferBank)
    )
  }

  add$ (input: WireTransferBankInput): Observable<WireTransferBank> {
    return this.createWireTransferBankMutation.mutate({input}).pipe(
      map(res => res.data.createWireTransferBank
        ? wireTransferBankFactory.create(res.data.createWireTransferBank as WireTransferBankType) : null
      )
    );
  }

  update$ (input: UpdateWireTransferBankInput): Observable<WireTransferBank> {
    return this.updateWireTransferBankMutation.mutate({input}).pipe(
      map(res => res.data.updateWireTransferBank
        ? wireTransferBankFactory.create(res.data.updateWireTransferBank as WireTransferBankType) : null
      )
    );
  }

  get (): Observable<WireTransferBank[]> {
    return this.wireTransferBanksQuery.watch(undefined, {fetchPolicy: 'no-cache'})
      .valueChanges
      .pipe(
        map(value => value.data.wireTransferBanks
          ? value.data.wireTransferBanks.map(item => wireTransferBankFactory.create(item as WireTransferBankType))
          : []
        )
      )
  }
}
