<div class="assign-people-dialog-wrapper">
  <h1
    mat-dialog-title
    class="mb-2 !text-[2.2rem] text-darkGray text-center">
    <span>{{ title }}</span>
  </h1>

  <mat-dialog-content class="min-h-[10rem]">
    <mat-form-field
      appearance="outline"
      class="w-full">
      <mat-chip-list class="w-full" #usersList>
        <mat-chip
          *ngFor="let user of visibleSelectedUsers$|async">
          <img src="assets/images/icons/profile.svg"
               alt="user avatar"
               matChipAvatar>
          {{ user.profile.fullName }}
          <button
            *ngIf="canRemove$(user) | async"
            matChipRemove
            appConfirmDialog
            [confirmMessage]="'Do you really want to remove this person?'"
            (ok)="removeUser(user)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input matInput
               #input
               [formControl]="userControl"
               [autofocus]="false"
               [matAutocomplete]="usersListAutocomplete"
               [matChipInputFor]="usersList"
               placeholder="Enter email of person">
      </mat-chip-list>
    </mat-form-field>

    <mat-autocomplete
      #usersListAutocomplete="matAutocomplete"
      class="pt-2"
      (optionSelected)="onSelect($event)">
      <ng-container *ngIf="filteredUsers$ | async as filteredUsers">
        <ng-container *ngIf="filteredUsers.length>0;else noUser">
          <mat-option disabled *ngIf="filteredUsers.length">
            {{ userControl.value ? 'Found' : 'Total' }} {{ filteredUsers.length }} user(s)
          </mat-option>

          <mat-option
            *ngFor="let user of filteredUsers"
            [ngStyle]="{'line-height':1}"
            [value]="user">
            <div class="flex flex-row items-center">
              <app-avatar
                type="circle"
                [width]=32
                [height]=32></app-avatar>
              <div class="flex flex-col ml-4">
                <p class="text-darkGray !text-sub !mb-0">{{ user.profile.fullName }}</p>
                <p class="mat-caption text-lightGray !mb-0">{{ user.email }}</p>
              </div>
            </div>
          </mat-option>
        </ng-container>

        <ng-template #noUser>
          <mat-option disabled class="!text-center">
            No options found
          </mat-option>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  </mat-dialog-content>


  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      class="bg-grey-800 text-white w-32"
      (click)="onClose()">Cancel
    </button>

    <button
      appConfirmDialog
      (ok)="assign()"
      [confirmMessage]="'Are you sure to add selected person into this job?'"
      [disabled]="disabled$ | async"
      mat-raised-button
      class="w-32"
      color="accent">Submit
    </button>
  </mat-dialog-actions>
</div>
