import { BaseModel } from '@shared/models/base.model';
import {AddressTypeType, BillingAddressType} from '@generated/graphql';
import { Country } from '@modules/country/models/country.model';
import { countryFactory } from '@modules/country/factories/country.factory';
import { User } from '@modules/user/models/user.model';
import {AddressType} from "@modules/address/models/address-type.model";
import {addressTypeFactory} from "@modules/address/factories/address-type.factory";

export class BillingAddress extends BaseModel implements BillingAddressType {
  address: string;
  firstName: string;
  id: string;
  isDefault: boolean;
  lastName: string;
  phone: string;
  fullName: string;
  userId: string;
  user: User
  addressType: AddressType
  addressTypeId: string

  zip?: string;
  apartment?: string;
  state?: string;
  city?: string;
  country?: Country;

  constructor (state: BillingAddressType) {
    super(state);

    this.country = state.country ? countryFactory.create(state.country) : null;

    this.addressType = addressTypeFactory.tryCreating(state.addressType)
  }

  getModelName (): string {
    return BillingAddress.name;
  }

  get addressToShow (): string {
    return [
      this.apartment,
      this.address,
      this.state,
      this.city,
      this.country?.name,
      this.zip
    ].filter(v => !!v).join(', ')
  }
}
