import { Injectable } from '@angular/core';
import {
  AllOfficersByCustomerQuery,
  LookupOfficersForExpertQuery,
  OfficerFiltersInput,
  OfficerIdentificationTypesQuery,
  OfficerIdentificationTypeType,
  OfficerType,
} from '@generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfficerService {
  constructor(
    private officerIdentificationTypes: OfficerIdentificationTypesQuery,
    private lookupOfficersForExpertQuery: LookupOfficersForExpertQuery,
    private allOfficersByCustomerQuery: AllOfficersByCustomerQuery,
  ) { }

  getIdentificationType() {
    return this.officerIdentificationTypes
      .watch()
      .valueChanges.pipe(
        map(
          (res) =>
            res.data
              .officerIdentificationTypes as OfficerIdentificationTypeType[],
        ),
      );
  }

  lookupOfficersForExpert(filters: OfficerFiltersInput) {
    return this.lookupOfficersForExpertQuery
      .watch(
        {
          filters,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.pipe(
        map((res) => res.data.allOfficersForExpert as OfficerType[]),
      );
  }

  allOfficersByCustomer(country: string) {
    return this.allOfficersByCustomerQuery
      .watch(
        {
          country,
        },
        {
          fetchPolicy: 'cache-and-network',
        },
      )
      .valueChanges.pipe(
        map(({ data }) => data.allOfficersByCustomer as OfficerType[]),
      );
  }
}
