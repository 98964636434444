import {
  CompanyDirectorInput,
  OfficerCompanyInput,
  OfficerCompanyType,
  OfficerPersonInput,
  OfficerPersonType,
  OfficerType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { OfficerDialogManagement } from './officer-dialog-management.class';

export class OfficerDirectorDialogMangement extends OfficerDialogManagement {
  setTitle(): this {
    if (this.type === 'new') {
      this.title = 'Add Director';
    } else if (this.type === 'edit') {
      this.title = 'Edit Director';
    }

    return this;
  }

  setInformationLabel(): this {
    this.informationLabel = 'Director Information';

    return this;
  }

  prepareEditData(data: CompanyDirectorInput): this {
    this.officerFormManagement.setEditData(
      data.officerPerson || data.officerCompany || undefined,
      undefined,
    );

    return this;
  }

  setLookupData(officer: OfficerType, type: OfficerTypeEnum): this {
    let officerName = 'officerPerson';
    let officerProfile: OfficerPersonInput | OfficerCompanyInput;

    if (type === OfficerTypeEnum.Corporate) {
      officerName = 'officerCompany';
      const castedOfficer = officer.officerProfile as OfficerCompanyType;

      officerProfile = this.transformOfficerCompany(castedOfficer);
    } else {
      const castedOfficer = officer.officerProfile as OfficerPersonType;

      officerProfile = this.transformOfficerPerson(castedOfficer);
    }

    const transformedOfficer = {
      id: officer.id,
      officerType: type,
      isNominee: false,
      dateFrom: new Date(),
      [officerName]: officerProfile,
      refId: officer.id,
    };

    this.setFormType(type)
      .createFormManagement()
      .prepareEditData(transformedOfficer)
      .setUpRefForm();

    return this;
  }

  isOfficerFormValid(): boolean {
    if (!this.officerFormManagement) {
      return false;
    }

    return this.getOfficerForm().disabled ? true : this.getOfficerForm().valid;
  }
}
