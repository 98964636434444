import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, filter, first, map, Observable, tap} from 'rxjs';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {EntityService} from "@shared/services/entity.service";
import {EntityFeatureNameEnum} from "@shared/contracts/entity-store.contract";
import {BillingAddressType} from "@generated/graphql";
import {BILLING_ADDRESS_STORE, BillingAddressStore} from "@modules/address/store/billing-address.store";
import {Store} from "@ngrx/store";
import {billingAddressFactory, BillingAddressFactory} from "@modules/address/factories/billing-address.factory";
import {Update} from "@ngrx/entity";
import {cloneDeep} from "lodash-es";
import {AddressCardInput} from "@shared/component/common/address-card/address-card.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  AddressDialogComponent,
  AddressDialogData
} from "@modules/address/components/dialog/address-dialog/address-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class BillingAddressService extends EntityService<EntityFeatureNameEnum.BillingAddress, BillingAddressType, BillingAddress> {
  private readonly _selectedBillingAddress$ = new BehaviorSubject<AddressCardInput>(null)

  get selectedBillingAddress$() {
    return this._selectedBillingAddress$.asObservable()
  }

  get myDefaultBillingAddress$(): Observable<BillingAddress> {
    return this.activeEntity$;
  }

  get allWithoutActiveEntity$(): Observable<BillingAddress[]> {
    return this.store.select(this.entityStore.selectAllWithoutActiveEntity);
  }

  get getAllAddress$(): Observable<BillingAddress[]> {
    return this.entities$;
  }

  get factory(): BillingAddressFactory {
    return billingAddressFactory;
  }

  constructor(
    protected readonly store: Store,
    @Inject(BILLING_ADDRESS_STORE) protected readonly entityStore: BillingAddressStore,
    protected readonly billingAddressApi: BillingAddressApi,
  ) {
    super(store, entityStore);
  }

  setSelectedBillingAddress(address: AddressCardInput) {
    this._selectedBillingAddress$.next(cloneDeep(address))
  }

  resolveMyBillingAddresses$(): Observable<BillingAddress[]> {
    return this.refreshMyBillingAddresses$()
      .pipe(first());
  }

  refreshMyBillingAddresses$(): Observable<BillingAddress[]> {
    return this.billingAddressApi.getMyBillingAddresses()
      .pipe(
        map(billingAddresses => {
          this.dispatchClearEntitiesAction();

          this.dispatchSetEntities(billingAddresses);

          const activeAddress = billingAddresses.find(item => item.isDefault);

          if (activeAddress) {
            this.dispatchSelectEntityAction(activeAddress.id);
          }

          return billingAddresses;
        }),
      );
  }

  toggleIsDefaultRemainingRecords(address: BillingAddress): void {
    this.entities$.pipe(
      filter(() => address.isDefault),
      first(),
      tap(entities => {
        this.dispatchSelectEntityAction(address.id);

        const newEntities: Update<BillingAddress>[] = entities
          .filter(entity => entity.id !== address.id)
          .map(entity => ({
            id: entity.id,
            changes: {isDefault: false}
          }));

        this.dispatchUpdateEntitiesAction(newEntities);
      })
    ).subscribe();
  }

  onAddNewAddress = (dialog: MatDialog): MatDialogRef<AddressDialogComponent, BillingAddress> => {

    const {address$, addressDialog} = this.onAddNewAddress$(dialog);

    address$.pipe(first()).subscribe()

    return addressDialog
  }

  onAddNewAddress$ = (dialog: MatDialog): {
    address$: Observable<BillingAddress>
    addressDialog: MatDialogRef<AddressDialogComponent, BillingAddress>
  } => {

    const addressDialog = dialog.open<
      AddressDialogComponent,
      AddressDialogData,
      BillingAddress
    >(AddressDialogComponent, {
      width: AddressDialogComponent.width,
      data: {}
    });

    return {
      addressDialog,
      address$: addressDialog.afterClosed()
        .pipe(
          map(address => {
            if (address) {
              this.dispatchUpsertEntityAction(address)
              this.toggleIsDefaultRemainingRecords(address)
            }

            return address
          }),
        )
    }
  }
}
