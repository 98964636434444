<div class="officer-info-wrapper">
  <h1 mat-dialog-title
      class="mb-2 text-darkGray !text-[2.2rem] !text-center">
    Officer Information
  </h1>
  <mat-dialog-content>
    <app-officer-information [officerInfo]="officerInfo"></app-officer-information>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button
            class="bg-grey-800 text-white"
            (click)="onCloseDialog()">Close
    </button>
  </mat-dialog-actions>
</div>

