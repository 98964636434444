import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldControl } from "@angular/material/form-field";
import { CountryType } from '@generated/graphql';
import { map, Observable, Subject, Subscription, tap } from "rxjs";
import { startWith } from "rxjs/operators";
import { COUNTRY_STORE, CountryStore } from "@modules/country/store/country.store";
import { Store } from "@ngrx/store";

@Component({
  selector: 'app-country-select-input',
  standalone: true,
  templateUrl: './country-select-input.component.html',
  styleUrls: ['./country-select-input.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule],
  providers: [
    {provide: MatFormFieldControl, useExisting: CountrySelectInputComponent},
  ],
  host: {
    '[class.country-select-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class CountrySelectInputComponent
  implements OnInit,
    OnDestroy,
    MatFormFieldControl<string>,
    ControlValueAccessor {
  countries: CountryType[];
  countryControl = new FormControl<string | CountryType>('');
  selectedCountry?: CountryType;
  filteredCountries$: Observable<CountryType[]>;
  subs = new Subscription();

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'country-select-input';
  id = `country-select-input-${CountrySelectInputComponent.nextId++}`;
  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  get empty () {
    return !this.countryControl.value;
  }

  get shouldLabelFloat () {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder (): string {
    return this._placeholder;
  }

  set placeholder (value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required (): boolean {
    return this._required;
  }

  set required (value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled (): boolean {
    return this._disabled;
  }

  set disabled (value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled
      ? this.countryControl.disable()
      : this.countryControl.enable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value (): string | null {
    return this.selectedCountry.id || null;
  }

  set value (countryId: string) {
    if (this.countries) {
      const foundCountry = this.countries.find((c) => c.id === countryId);
      this.selectedCountry = foundCountry;
      this.countryControl.setValue(foundCountry);
    }
    this.stateChanges.next();
  }

  get errorState (): boolean {
    return this.required && !this.selectedCountry && this.touched;
  }

  constructor (
    @Inject(COUNTRY_STORE) protected readonly countryStore: CountryStore,
    private _store: Store,
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit (): void {
    this._setupCountries();
  }

  private _setupCountries (): void {
    this.subs = this._store
      .select(this.countryStore.selectAllEntity)
      .pipe(
        tap((countries) => {
          this.countries = countries;
        })
      )
      .subscribe(() => {
        this._initialValue();
        this._setupFilteredCountries();
      });
  }

  private _initialValue (): void {
    if (this.ngControl && this.ngControl.value) {
      const initialId = this.ngControl.value;
      const foundCountry = this.countries.find((c) => c.id === initialId);

      if (foundCountry) {
        this.selectedCountry = foundCountry;
        this.countryControl.setValue(foundCountry);
        this.value = foundCountry.id;
      }
    }
  }

  private _setupFilteredCountries (): void {
    this.filteredCountries$ = this.countryControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.countries.slice();
      })
    );
  }

  private _filter (name: string): CountryType[] {
    const filterValue = name.toLowerCase();
    return this.countries.filter((country) =>
      country.name.toLowerCase()
        .includes(filterValue)
    );
  }

  setDescribedByIds (ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.country-select-input-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick () {
    //
  }

  writeValue (countryId: string): void {
    this.value = countryId;
  }

  registerOnChange (fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched (fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState (isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocusIn (event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut (event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();

      if (this.countryControl.value === '') {
        this.resetControl();
        this.onChange(null);
      }

      this.stateChanges.next();
    }
  }

  countryDisplayWith (country: CountryType): string {
    return country && country.name ? country.name : '';
  }

  onCountrySelected (country: CountryType): void {
    this.selectedCountry = country;
    this.onChange(country.id);
  }

  resetControl (): void {
    this.selectedCountry = undefined;
    this.countryControl.setValue(null);
    this.value = null;
  }

  ngOnDestroy (): void {
    this.stateChanges.complete();
    this.subs.unsubscribe();
  }
}
