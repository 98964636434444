import { ModelFactory } from "@shared/factories/model.factory";
import { OrderDetailType } from "@generated/graphql";
import { OrderDetail } from "@modules/order/models/order-detail.model";

export class OrderDetailFactory extends ModelFactory<OrderDetailType, OrderDetail> {
  create (state: OrderDetailType): OrderDetail {
    return new OrderDetail(state);
  }

  fake (): OrderDetail {
    throw new Error('Method is not implemented')
  }
}

export const orderDetailFactory = new OrderDetailFactory()
