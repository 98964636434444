import { BaseModel } from "@shared/models/base.model";
import { WireTransferBankType } from "@generated/graphql";
import { Country } from "@modules/country/models/country.model";
import { countryFactory } from "@modules/country/factories/country.factory";

export class WireTransferBank extends BaseModel implements WireTransferBankType {
  id: string;
  account: string;
  branch: string;
  holderName: string;
  name: string;
  countryId: string;
  swiftCode: string;
  country: Country;

  constructor (state: WireTransferBankType) {
    super(state);

    this.country = state.country ? countryFactory.create(state.country) : null
  }

  getModelName (): string {
    return WireTransferBank.name;
  }
}
