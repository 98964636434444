import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OfficerCorporateFormType } from '@app/modules/officer/models/form/officer-corporate-form-type';
import {
  OfficerIndividualFormType,
  OfficerIndividualIdentificationFormType,
} from '@app/modules/officer/models/form/officer-individual-form-type';
import {
  OfficerCompanyInput,
  SalutationEnum,
  ShareholdingDetailInput,
} from '@generated/graphql';
import { AbstractOfficerForm } from './abstract-officer-form.class';
import { AddressFormType } from '../address/address-form.class';

export class OfficerCorporateForm extends AbstractOfficerForm {
  officerForm: FormGroup<OfficerCorporateFormType>;

  constructor() {
    super();
  }

  createOfficerForm(): void {
    this.officerForm = this.fb.group<OfficerCorporateFormType>({
      name: new FormControl<string | null>('', Validators.required),
      registrationNumber: new FormControl<string | null>(
        '',
        Validators.required,
      ),
      registrationDate: new FormControl<Date | null>(null, Validators.required),
      countryId: new FormControl<string | null>('', Validators.required),
      website: new FormControl<string | null>(''),
      email: new FormControl<string | null>('', [
        Validators.required,
        Validators.email,
      ]),
      address: this.fb.group<AddressFormType>({
        address: new FormControl<string | null>('', [Validators.required]),
        apartment: new FormControl<string | null>(''),
        phone: new FormControl<string | null>(''),
        zip: new FormControl<string | null>(''),
        city: new FormControl<string | null>(''),
        state: new FormControl<string | null>(''),
        countryId: new FormControl<string | null>(''),
      }),
      representative: this.fb.group<OfficerIndividualFormType>({
        id: new FormControl<string | null>(`temporary-${new Date().getTime()}`),
        salutation: new FormControl<string | null>(
          SalutationEnum.Mr,
          Validators.required,
        ),
        firstName: new FormControl<string | null>('', Validators.required),
        lastName: new FormControl<string | null>('', Validators.required),
        countryId: new FormControl<string | null>('', Validators.required),
        email: new FormControl<string | null>('', Validators.required),
        phone: new FormControl<string | null>('', Validators.required),
        address: new FormControl<string | null>('', Validators.required),
        apartment: new FormControl<string | null>(''),
        zip: new FormControl<string | null>(''),
        city: new FormControl<string | null>(''),
        state: new FormControl<string | null>(''),
        identification: this.fb.group<OfficerIndividualIdentificationFormType>({
          officerIdentificationTypeId: new FormControl<string | null>(
            '',
            Validators.required,
          ),
          nationalityId: new FormControl<string | null>(
            '',
            Validators.required,
          ),
          identificationNumber: new FormControl<string | null>(
            '',
            Validators.required,
          ),
          expiryDate: new FormControl<Date | null>(null),
        }),
      }),
    });
  }

  setEditData(
    formData: OfficerCompanyInput,
    shareholding: ShareholdingDetailInput[] = [],
  ): void {
    if (!formData) {
      return;
    }

    this.officerForm.patchValue({
      name: formData.name,
      registrationNumber: formData.registrationNumber,
      registrationDate: formData.registrationDate,
      website: formData.website,
      email: formData.email,
      address: formData.address,
      countryId: formData.countryId,
      representative: {
        id: formData.representative.id,
        salutation: formData.representative.salutation,
        firstName: formData.representative.firstName,
        lastName: formData.representative.lastName,
        countryId: formData.representative.countryId,
        email: formData.representative.email,
        phone: formData.representative.phone,
        address: formData.representative.address,
        apartment: formData.representative.apartment,
        zip: formData.representative.zip,
        city: formData.representative.city,
        state: formData.representative.state,
        identification: {
          officerIdentificationTypeId:
            formData.representative.identification.officerIdentificationTypeId,
          nationalityId: formData.representative.identification.nationalityId,
          identificationNumber:
            formData.representative.identification.identificationNumber,
          expiryDate: formData.representative.identification.expiryDate,
        },
      },
    });

    if (shareholding.length) {
      this.setShareDistribution(shareholding);
    }
  }
}
