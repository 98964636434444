<div role="group"
     class="country-select-input-container"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input type="text"
         aria-label="Country"
         #countryInput
         [placeholder]="placeholder"
         matInput
         [formControl]="countryControl"
         [matAutocomplete]="autoCountry"
         autocomplete="nope"/>
  <mat-autocomplete #autoCountry="matAutocomplete"
                    [displayWith]="countryDisplayWith"
                    (optionSelected)="onCountrySelected($event.option.value)"
                    autoSelectActiveOption>
    <mat-option *ngFor="let country of filteredCountries$ | async"
                [value]="country">
      <span [class]="['fi', 'fi-' + country.code.toLowerCase()]"></span>&nbsp;{{
      country.name
      }}
    </mat-option>
  </mat-autocomplete>
</div>
