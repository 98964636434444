import {Component, Inject, Input, OnInit} from '@angular/core';
import {Job} from "@modules/job/models/job.model";
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";
import {startCase} from 'lodash-es';
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";

@Component({
  selector: 'app-gst-return-info',
  templateUrl: './gst-return-info.component.html',
  styleUrls: ['./gst-return-info.component.scss']
})
export class GstReturnInfoComponent implements OnInit {
  @Input() job: Job

  businessProfile$ = this.businessProfileService.activeEntity$

  startCase = startCase

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    public readonly businessProfileService: BusinessProfileService
  ) {
  }

  ngOnInit(): void {
  }
}
