<div class="auth-card-wp">
  <mat-card class="mat-card">
    <section>
      <app-tenant-logo></app-tenant-logo>
      <mat-card-title-group>
        <mat-card-title *ngIf="title" class="!text-3xl">
          {{title}}
        </mat-card-title>
        <mat-card-subtitle class="mat-card-subtitle" *ngIf="subtitle">
          {{subtitle}}
        </mat-card-subtitle>
      </mat-card-title-group>
    </section>

    <section>
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </section>
  </mat-card>
</div>
