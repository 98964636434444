import { Company } from '@app/modules/company/models/company.model';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import {
  OfficerCompanyType,
  OfficerType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { ExistsOfficerType } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/classes/exists-officer.type';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { sortBy, uniqBy } from 'lodash-es';

export class ExistsOfficerManagement {
  public existType: 'Director' | 'Shareholder';

  public existOfficerList: ExistsOfficerType[] = [];

  public officers: OfficerType[] = [];

  public exceptIds: string[] = [];

  public officerHelper = new OfficerHelper();

  public countryOfIncorporation: SupportedCountry;

  public refId: string;

  constructor() {
    //
  }

  public setDataFromServer(officers: OfficerType[]): this {
    if (officers.length) {
      this.officers = [...this.officers, ...officers];

      const normalOfficers = officers.map((o) => ({
        id: o.id,
        name: this.officerHelper.getOfficerName(o.officerProfile),
        email: this.officerHelper.getOfficerEmail(o.officerProfile),
        type:
          o.officerProfile.__typename === 'OfficerCompanyType'
            ? OfficerTypeEnum.Corporate
            : OfficerTypeEnum.Individual,
        refId: o.id,
      }));

      const companyOfficers = officers.filter(
        (o) => o.officerProfile.__typename === 'OfficerCompanyType',
      );

      const representatives = companyOfficers.map((o) => {
        const representative = (o.officerProfile as OfficerCompanyType)
          .representative;

        return {
          id: representative.id,
          name: this.officerHelper.getOfficerName(
            representative.officerProfile,
          ),
          email: this.officerHelper.getOfficerEmail(
            representative.officerProfile,
          ),
          type: OfficerTypeEnum.Individual,
          refId: representative.id,
        };
      });

      this.existOfficerList = [
        ...this.existOfficerList,
        ...normalOfficers,
        ...representatives,
      ];

      this.filterAndSortExistOfficers();
    }

    return this;
  }

  public setExceptOfficerIds(company: Company): this {
    if (this.existType === 'Director') {
      this.exceptIds = [...company.directors.map((s) => s.officer.id)];
    } else if (this.existType === 'Shareholder') {
      this.exceptIds = [...company.shareholders.map((d) => d.officer.id)];
    }

    return this;
  }

  public findSelectedOfficer(existsOfficer: ExistsOfficerType) {
    let foundOfficer = this.officers.find((o) => o.id === existsOfficer.id);

    if (!foundOfficer) {
      const companyOfficers = this.officers.filter(
        (o) => o.officerProfile.__typename === 'OfficerCompanyType',
      );

      const foundOfficerCompany = companyOfficers.find(
        (o) =>
          (o.officerProfile as OfficerCompanyType).representative.id ===
          existsOfficer.id,
      );

      foundOfficer = (foundOfficerCompany.officerProfile as OfficerCompanyType)
        .representative;
    }

    return foundOfficer;
  }

  public filterAndSortExistOfficersForDirector(): this {
    if (this.countryOfIncorporation.country.code.toUpperCase() == 'SG') {
      this.existOfficerList = this.existOfficerList.filter(
        (e) => e.type !== OfficerTypeEnum.Corporate,
      );
    }

    return this.filterAndSortExistOfficers();
  }

  public filterAndSortExistOfficers(): this {
    this.existOfficerList = sortBy(
      uniqBy(this.existOfficerList, (o) => o.id),
      (o) => o.name,
    );

    return this;
  }

  public reset() {
    this.refId = undefined;
  }
}
