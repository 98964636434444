import { Injectable } from '@angular/core';
import {
  CompanyDirectorType,
  ConfirmCreateDirectorJobMutation,
  ConfirmRemoveDirectorJobMutation,
  ConfirmReplaceDirectorJobMutation,
  ConfirmUpdateDirectorJobMutation,
  DirectorJobInput,
  DirectorsQuery,
  ExpertCreateDirectorMutation,
  ExpertRemoveDirectorMutation,
  ExpertReplaceDirectorMutation,
  ExpertUpdateDirectorMutation,
  FilterCompanyDirectorInput,
  GetDirectorJobRequestsQuery,
  JobType,
  RemoveDirectorJobInput,
  ReplaceDirectorJobInput,
} from '@generated/graphql';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyDirectorService {
  public jobRequest$ = new BehaviorSubject<JobType[]>([]);

  public constructor(
    private _confirmCreateDirectorJobMutation: ConfirmCreateDirectorJobMutation,
    private _confirmUpdateDirectorJobMutation: ConfirmUpdateDirectorJobMutation,
    private _expertCreateDirectorMutation: ExpertCreateDirectorMutation,
    private _expertUpdateDirectorMutation: ExpertUpdateDirectorMutation,
    private _getDirectorJobRequestsQuery: GetDirectorJobRequestsQuery,
    private _expertRemoveDirectorMutation: ExpertRemoveDirectorMutation,
    private _confirmRemoveDirectorJobMutation: ConfirmRemoveDirectorJobMutation,
    private _expertReplaceDirectorMutation: ExpertReplaceDirectorMutation,
    private _confirmReplaceDirectorJobMutation: ConfirmReplaceDirectorJobMutation,
    private _getDirectorsQuery: DirectorsQuery,
  ) {}

  public expertCreateDirector(
    input: DirectorJobInput,
  ): Observable<CompanyDirectorType> {
    return this._expertCreateDirectorMutation
      .mutate(
        {
          input,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map(
          (response) =>
            response.data.expertCreateDirector as CompanyDirectorType,
        ),
      );
  }

  public getDirectorJobRequests(companyId: string): Observable<JobType[]> {
    return this._getDirectorJobRequestsQuery
      .fetch({ companyId }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((response) => response.data.directorJobRequests as JobType[]),
        tap((jobRequests) => {
          this.jobRequest$.next(jobRequests);
        }),
      );
  }

  public confirmCreateDirectorJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmCreateDirectorJobMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmCreateDirectorJob));
  }

  public confirmUpdateDirectorJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmUpdateDirectorJobMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmUpdateDirectorJob));
  }

  public expertUpdateDirector(
    input: DirectorJobInput,
  ): Observable<CompanyDirectorType> {
    return this._expertUpdateDirectorMutation
      .mutate(
        {
          input,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map(
          (response) =>
            response.data.expertUpdateDirector as CompanyDirectorType,
        ),
      );
  }

  public expertRemoveDirector(
    input: RemoveDirectorJobInput,
  ): Observable<boolean> {
    return this._expertRemoveDirectorMutation
      .mutate({
        input,
      })
      .pipe(map((response) => response.data.expertRemoveDirector));
  }

  public confirmRemoveDirectorJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmRemoveDirectorJobMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmRemoveDirectorJob));
  }

  public expertReplaceDirector(
    input: ReplaceDirectorJobInput,
  ): Observable<CompanyDirectorType> {
    return this._expertReplaceDirectorMutation
      .mutate(
        {
          input,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map(
          (response) =>
            response.data.expertReplaceDirector as CompanyDirectorType,
        ),
      );
  }

  public confirmReplaceDirectorJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmReplaceDirectorJobMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmReplaceDirectorJob));
  }

  public getDirectors(
    filters: FilterCompanyDirectorInput,
  ): Observable<CompanyDirectorType[]> {
    return this._getDirectorsQuery
      .fetch(
        {
          filters,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((response) => response.data.directors as CompanyDirectorType[]),
      );
  }
}
