import { ModelFactory } from "@shared/factories/model.factory";
import { FileType } from "@modules/file/models/file-type.model";
import { FileTypeType } from "@generated/graphql";

export class FileTypeFactory extends ModelFactory<FileTypeType, FileType> {
  create (state: FileTypeType): FileType {
    return new FileType(state);
  }

  fake (): FileType {
    throw new Error('Method not implemented');
  }
}

export const fileTypeFactory = new FileTypeFactory()
