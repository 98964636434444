<ng-container *ngIf="activeUser$ | async as activeUser">
  <ng-container *ngrxLet="businessPlan$ as businessPlan">
    <div class="officer-resolution-request-wrapper">
      <h1
        mat-dialog-title
        class="mb-0 !text-[2.2rem] text-center text-darkGray"
      >
        {{ isEditMode() ? "Edit" : "Add New" }}&nbsp;
        <span *ngIf="isDirector()">Director</span>
        <span *ngIf="isShareholder()">Shareholder</span>
      </h1>
      <div
        class="officer-container !-mx-6"
        *ngIf="officerRequestFormManagement"
      >
        <mat-stepper #stepper linear>
          <mat-step
            label="Officer Details"
            [stepControl]="officerRequestFormManagement.formInput.officerForm"
          >
            <mat-dialog-content>
              <div *ngIf="!activeUser.isCustomer()">
                <mat-form-field appearance="outline" class="w-1/3">
                  <mat-label>Effective date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="effectiveDatePicker"
                    [formControl]="officerRequestFormManagement.effectiveDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="effectiveDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #effectiveDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-divider class="!my-2 !mx-auto !w-1/2"></mat-divider>
              </div>
              <!--USE DETAIL OF-->
              <ng-container *ngIf="!isEditMode()">
                <div
                  class="flex flex-col"
                  [ngClass]="{ 'mb-4': showExistingOfficer() }"
                >
                  <p class="mat-title !text-base !mb-2 text-darkGray">
                    Choose a
                    <span *ngIf="isDirector()">director</span>
                    <span *ngIf="isShareholder()">shareholder</span>
                  </p>
                  <mat-radio-group
                    aria-label="Select an option"
                    class="group-selection mb-4 inline-flex"
                    [(ngModel)]="useDetailOfSelected"
                    (change)="useDetailChanged($event)"
                  >
                    <mat-radio-button
                      class="select-option mr-4 !transform:capitalize"
                      *ngFor="let option of useDetailOfOption"
                      [value]="option.value"
                    >
                      <p
                        class="mat-subheading-1 text-darkGray !mb-0 text-transform: capitalize"
                      >
                        {{ option.label }}
                      </p>
                    </mat-radio-button>
                  </mat-radio-group>

                  <ng-container *ngIf="showExistingOfficer()">
                    <p class="mat-title !text-base text-darkGray !mb-2">
                      Use Detail of
                    </p>
                    <!-- <mat-form-field appearance="outline" class="w-1/3"> -->
                    <!--   <input type="text" matInput placeholder="existing" /> -->
                    <!-- </mat-form-field> -->
                    <app-exists-officers-select
                      [countryOfIncorporation]="supportedCountry"
                      [existOfficers]="
                        officerRequestFormManagement.existsOfficerManagement
                          .existOfficerList
                      "
                      [refId]="
                        officerRequestFormManagement.existsOfficerManagement
                          .refId
                      "
                      [exceptIds]="
                        officerRequestFormManagement.existsOfficerManagement
                          .exceptIds || []
                      "
                      (existsOfficerSelectedEvent)="
                        existsOfficerSelectedHandler($event)
                      "
                    ></app-exists-officers-select>

                    <mat-divider class="!w-1/2 !mx-auto"></mat-divider>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="activeUser.isExpert() && !isEditMode()">
                <div class="text-center mb-2">
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="onOpenLookupOfficer()"
                  >
                    Lookup officers
                  </button>
                </div>
              </ng-container>

              <!--Officer Type-->
              <div
                class="flex flex-col mb-4"
                [formGroup]="officerRequestFormManagement.formInput.officerForm"
              >
                <mat-form-field appearance="outline" class="w-1/3">
                  <mat-label>Type</mat-label>
                  <mat-select
                    class="capitalize"
                    [(value)]="typeSelected"
                    [disabled]="disableOfficerTypeSelect()"
                    (selectionChange)="onSelectType($event)"
                  >
                    <mat-option
                      *ngFor="
                        let type of officerRequestFormManagement.getOfficerTypeOption()
                      "
                      [value]="type"
                      [disabled]="isTypeDisabled(type)"
                      class="capitalize"
                    >
                      {{ type.toLowerCase() }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!--Officer INPUT FORM-->
                <app-individual-info
                  *ngIf="
                    typeSelected === OfficerTypeEnum.Individual ||
                    typeSelected === OfficerTypeEnum.Nominee
                  "
                  [formGroup]="
                    officerRequestFormManagement.formInput.officerForm
                  "
                  [countryOfIncorporationOverride]="supportedCountry$ | async"
                  #officerPersonForm
                ></app-individual-info>
                <app-corporate-info
                  *ngIf="typeSelected === OfficerTypeEnum.Corporate"
                  [formGroup]="
                    officerRequestFormManagement.formInput.officerForm
                  "
                  #officerCompanyForm
                ></app-corporate-info>

                <!--Officer KYC DOCUMENTS-->
                <!-- <ng-container *ngIf="typeSelected !== OfficerTypeEnum.Nominee"> -->
                <!--   <mat-divider class="!my-2 !mx-auto !w-1/2"></mat-divider> -->
                <!---->
                <!--   <p class="mat-title !text-base !mb-3 text-darkGray"> -->
                <!--     Require documents -->
                <!--   </p> -->
                <!--   <ng-container *ngIf=" -->
                <!--       activeUser.isCustomer() && isEditMode() && isDirector() -->
                <!--     "> -->
                <!--     <app-callout class="block mb-3" *ngIf="companyProfile.country.code === 'SG'" -->
                <!--       message="It is mandatory to update the ACRA for any change of the director’s place of residence or passport. Please note that you have to notify the ACRA of any change of a director’s particulars within 14 days to avoid any penalty." -->
                <!--       appearance="warn"> -->
                <!--     </app-callout> -->
                <!---->
                <!--     <app-callout class="block mb-3" *ngIf="companyProfile.country.code === 'HK'" -->
                <!--       message="It is mandatory to update the Companies Registry (CR) for any change in particulars of directors. Please note that you have to notify the CR within 15 days after the date of change to avoid any penalty" -->
                <!--       appearance="warn"> -->
                <!--     </app-callout> -->
                <!--   </ng-container> -->
                <!--   <div class="grid gap-4" [ngClass]="{ -->
                <!--       'grid-cols-2': -->
                <!--         typeSelected === OfficerTypeEnum.Individual, -->
                <!--       'grid-cols-3': typeSelected === OfficerTypeEnum.Corporate -->
                <!--     }"> -->
                <!--     <div *ngFor=" -->
                <!--         let document of officerRequestFormManagement.requireDocuments -->
                <!--       " class="flex flex-col"> -->
                <!--       <p class="!mb-1 inline-flex items-center"> -->
                <!--         <span class="mat-subheading-1 text-sub !mb-0 !mr-2">{{ -->
                <!--           startCase(document.toLowerCase()) -->
                <!--           }}</span> -->
                <!--         <mat-icon *ngIf="typeSelected === OfficerTypeEnum.Individual" -->
                <!--           class="text-darkGray opacity-80 !text-base" matTooltip="{{ -->
                <!--             commonHelper.getDocumentHint(document) -->
                <!--           }}">info -->
                <!--         </mat-icon> -->
                <!--       </p> -->
                <!--       <app-dropzone-upload (filesChanged)=" -->
                <!--           officerRequestFormManagement.fileChanged( -->
                <!--             $event, -->
                <!--             document -->
                <!--           ) -->
                <!--         " accept="application/pdf,image/*" -->
                <!--         label="Upload {{ startCase(document.toLowerCase()) }}"></app-dropzone-upload> -->
                <!--     </div> -->
                <!--   </div> -->
                <!-- </ng-container> -->
              </div>
            </mat-dialog-content>
            <mat-dialog-actions>
              <div class="w-full flex flex-row justify-between">
                <section>
                  <button
                    mat-raised-button
                    class="w-52 bg-grey-800 text-white"
                    (click)="onCloseDialog()"
                  >
                    Cancel
                  </button>
                </section>
                <section>
                  <button
                    mat-button
                    color="accent"
                    class="w-52 mr-3"
                    (click)="resetForm()"
                  >
                    Reset form
                  </button>
                  <button
                    mat-raised-button
                    class="w-52"
                    color="primary"
                    (click)="onReviewRequest()"
                    [disabled]="!officerRequestFormManagement.isFormValid()"
                    matStepperNext
                  >
                    Next
                  </button>
                </section>
              </div>
            </mat-dialog-actions>
          </mat-step>

          <mat-step label="Review request">
            <mat-dialog-content *ngIf="stepper.selectedIndex === 1">
              <app-callout
                *ngIf="activeUser.isCustomer() && !isEditMode()"
                message="All the information will be reviewed by a corporate expert and double checked with you the new officer registration."
                appearance="warn"
                class="block mb-4"
              ></app-callout>

              <app-callout
                *ngIf="activeUser.isCustomer() && isEditMode()"
                message="All the information will be reviewed by a corporate expert and double checked with you on changing officer information."
                appearance="warn"
                class="block mb-4"
              ></app-callout>

              <!--TODO USE IT FOR ADD NEW OFFICER  - DISPLAY NEW OFFICER INFORMATION-->
              <ng-container *ngIf="!isEditMode()">
                <ng-container *ngIf="isNominee()">
                  <p
                    class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50"
                  >
                    New
                    {{
                      isDirector()
                        ? "director"
                        : isShareholder()
                          ? "shareholder"
                          : ""
                    }}
                    information
                  </p>
                  <p>Nominee {{ isDirector() ? "director" : "" }}</p>
                </ng-container>
                <ng-container *ngIf="!isNominee()">
                  <p
                    class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50"
                  >
                    New
                    {{
                      isDirector()
                        ? "director"
                        : isShareholder()
                          ? "shareholder"
                          : ""
                    }}
                    information
                  </p>
                  <app-officer-input-review-information
                    *ngIf="officerRequestFormManagement.isFormValid()"
                    [officerType]="getFormType()"
                    [formType]="officerRequestFormManagement.typeSelected"
                    [officerInput]="officerRequestFormManagement.getFormValue()"
                  ></app-officer-input-review-information>
                </ng-container>
              </ng-container>

              <!--TODO USE IT FOR EDIT OFFICER INFORMATION  - DISPLAY OLD & NEW INFORMATION-->
              <ng-container *ngIf="isEditMode() && !isNominee()">
                <section class="mb-4">
                  <p
                    class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50"
                  >
                    Current
                    {{
                      isDirector()
                        ? "director"
                        : isShareholder()
                          ? "shareholder"
                          : ""
                    }}
                    information
                  </p>
                  <app-officer-input-review-information
                    *ngIf="officerRequestFormManagement.isFormValid()"
                    [officerType]="getFormType()"
                    [formType]="officerRequestFormManagement.typeSelected"
                    [officerInput]="getOldOfficerInputValue()"
                  ></app-officer-input-review-information>
                </section>
                <section>
                  <p
                    class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50"
                  >
                    New
                    {{
                      isDirector()
                        ? "director"
                        : isShareholder()
                          ? "shareholder"
                          : ""
                    }}
                    information
                  </p>
                  <app-officer-input-review-information
                    *ngIf="officerRequestFormManagement.isFormValid()"
                    [officerType]="getFormType()"
                    [formType]="officerRequestFormManagement.typeSelected"
                    [officerInput]="officerRequestFormManagement.getFormValue()"
                  ></app-officer-input-review-information>
                </section>
              </ng-container>
            </mat-dialog-content>
            <mat-dialog-actions
              align="center"
              *ngIf="stepper.selectedIndex === 1"
            >
              <button
                mat-raised-button
                class="w-52 bg-grey-800 text-white"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                class="w-52"
                color="primary"
                (click)="onSubmitRequest()"
                [disabled]="
                  (officerRequestFormManagement.postStatus$ | async) ===
                  'SUBMITTING'
                "
              >
                <span *ngIf="!activeUser.isCustomer()">Submit</span>
                <span
                  *ngIf="businessPlan.isPremium() && activeUser.isCustomer()"
                >
                  Place order
                </span>
                <span
                  *ngIf="!businessPlan.isPremium() && activeUser.isCustomer()"
                >
                  Submit Request
                </span>
              </button>
            </mat-dialog-actions>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </ng-container>
</ng-container>
