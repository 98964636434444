import { Inject, Injectable } from '@angular/core';
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { PermissionEnum, UserType } from "@generated/graphql";
import { User } from "@modules/user/models/user.model";
import { EXPERT_STORE, ExpertStore } from "@modules/user/store/expert/expert.store";
import { select, Store } from "@ngrx/store";
import { UserFactory, userFactory } from "@modules/user/factories/user.factory";
import { catchError, EMPTY, first, iif, map, Observable, of, switchMap, tap } from "rxjs";
import { Country } from "@modules/country/models/country.model";
import { ExpertApi } from "@modules/user/api/expert.api";
import { ACTIVE_USER } from "@modules/auth/providers/auth.provider";

@Injectable({
  providedIn: 'root'
})
export class ExpertService extends EntityService<EntityFeatureNameEnum.Expert, UserType, User> {
  constructor (
    protected readonly store: Store,
    @Inject(EXPERT_STORE) protected readonly entityStore: ExpertStore,
    private readonly expertApi: ExpertApi,
    @Inject(ACTIVE_USER) private readonly activeUser$: Observable<User>
  ) {
    super(store, entityStore)
  }

  get factory (): UserFactory {
    return userFactory;
  }

  getByCountryOfResponsibility$ (id: Country['id']): Observable<User[]> {
    return this.store.pipe(
      select(this.entityStore.selectByCountryOfResponsibility(id)),
      map(users => users.map(user => userFactory.create(user)))
    )
  }

  getAndDispatchEntities (): Observable<User[]> {
    return this.activeUser$.pipe(
      switchMap(user => iif(() =>
          user && user.hasPermissions(PermissionEnum.GetExperts),
        this.expertApi.get$(),
        of([])
      )),
      tap(users => {
        this.dispatchClearEntitiesAction()
        this.dispatchSetEntities(users)
      }),
      catchError(e => {
        console.error(e)

        return EMPTY
      })
    )
  }
}
