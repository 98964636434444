import { BaseModel } from '@shared/models/base.model';
import {
  OrderDetailType,
  OrderStatusEnum,
  OrderType,
} from '@generated/graphql';
import { Job } from '@modules/job/models/job.model';
import { User } from '@modules/user/models/user.model';
import { userFactory } from '@modules/user/factories/user.factory';
import { Payment } from '@modules/payment/models/payment.model';
import { paymentFactory } from '@modules/payment/factories/payment.factory';
import { AppStatusType } from '@shared/component/common/status/status.component';
import { ExtraJob } from '@modules/extra-job/models/extra-job.model';
import { orderableFactory } from '@modules/order/factories/orderable.factory';
import { OrderDetail } from '@modules/order/models/order-detail.model';
import { orderDetailFactory } from '@modules/order/factories/order-detail.factory';

export type Orderable = Job | ExtraJob;

export class Order extends BaseModel implements OrderType {
  id: string;
  orderable: Orderable;
  canMakePayment: boolean;
  totalAmount: number;
  currency: string;
  user: User;
  payment: Payment;
  orderDetail: OrderDetail;
  status: OrderStatusEnum;
  canUpdateOrderBillingAddress: boolean;

  constructor(state: OrderType) {
    super(state);

    this.orderable = state.orderable
      ? orderableFactory.create(state.orderable)
      : null;

    this.user = state.user ? userFactory.create(state.user) : null;

    this.payment = state.payment ? paymentFactory.create(state.payment) : null;

    this.orderDetail = state.orderDetail
      ? orderDetailFactory.create(state.orderDetail)
      : null;
  }

  getModelName(): string {
    return Order.name;
  }

  get paymentStatusAndStyleIfUserDidNotMakePayment(): {
    status: string;
    statusStyle: AppStatusType;
  } {
    return {
      status: 'Awaiting Payment',
      statusStyle: 'warn',
    };
  }
}
