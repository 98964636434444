export abstract class ModelFactory<B extends Record<string, any>, F extends Record<string, any>> {
  abstract create (state: B): F

  fake? (): F

  createEntities (states: B[]): F[] {
    return states ? states.map(state => this.tryCreating(state)) : []
  }

  tryCreating (state: B | null): F | null {
    return state ? this.create(state) : null
  }
}
