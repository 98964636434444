import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  BankAccountDialogComponent,
  WireTransferBankAccountControls
} from "@shared/component/payments/dialogs/bank-account-dialog/bank-account-dialog.component";
import {CommonService} from "@shared/services/common.service";
import {SupportedCountryService} from "@modules/supported-country/services/supported-country.service";
import {COMMON_SERVICE_CONFIG} from "@shared/providers/common-service.provider";
import {MatDialogRef} from "@angular/material/dialog";
import {WireTransferBank} from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import {WireTransferBankApi} from "@modules/wire-transfer-bank/api/wire-transfer-bank.api";
import {WireTransferBankInput} from "@generated/graphql";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Observable, tap} from "rxjs";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

@UntilDestroy()
@Component({
  providers: [COMMON_SERVICE_CONFIG],
  selector: 'app-add-bank-account-dialog',
  templateUrl: './add-bank-account-dialog.component.html',
  styleUrls: ['./add-bank-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBankAccountDialogComponent extends BankAccountDialogComponent<WireTransferBankInput, WireTransferBankAccountControls, WireTransferBank> implements OnInit {
  input: WireTransferBankInput

  constructor(
    public readonly matDialogRef: MatDialogRef<AddBankAccountDialogComponent, WireTransferBank>,
    public readonly commonService: CommonService,
    protected readonly supportedCountryService: SupportedCountryService,
    protected readonly wireTransferBankApi: WireTransferBankApi
  ) {
    super(matDialogRef, commonService, supportedCountryService)
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  getInput(): WireTransferBankInput {
    const input = this.form.value

    return {
      ...input,
      countryId: input.countryId?.[0] ?? null
    } as WireTransferBankInput;
  }

  query(input: WireTransferBankInput): Observable<WireTransferBank> {
    return this.wireTransferBankApi
      .add$(input)
      .pipe(
        tap(result => {
          if (result) {
            this.commonService.snackBarService.pushAlert('Wire transfer bank information has been added successfully.', null, SnackBarTypeEnum.success)
            return;
          }
          this.commonService.snackBarService.pushErrorMessage()
        }),
      );
  }
}
