<div class="extra-job-detail-wrapper text-darkGray">
  <h1 mat-dialog-title
      class="mb-2 text-darkGray !text-[2.2rem]">{{ extraJob.matter }}</h1>

  <mat-divider class="!my-4 !w-1/2 !mx-auto"></mat-divider>
  <!--    Basic detail-->
  <ng-container>
    <p class="!mb-2 mat-title text-darkGray !text-base">Job information</p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Job matter&colon;&nbsp;</span>
      <span class="mat-subheading-1 !text-sub !mb-0">{{ extraJob.matter }}</span>
    </p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Created by&colon;&nbsp;</span>
      <span class="mat-subheading-1 !text-sub !mb-0">{{ extraJob.createdBy.profile.fullName }}</span>
    </p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Created at&colon;&nbsp;</span>
      <span class="mat-subheading-1 !text-sub !mb-0">{{ extraJob.createdAt |date }}</span></p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Scope of work&colon;&nbsp;</span>
      <span class="mat-subheading-1 !text-sub !mb-0 block break-words mt-1">{{ extraJob.scopeOfWork }}</span>
    </p>
  </ng-container>


  <ng-container *ngIf="visiblePayment$|async">
    <mat-divider class="!my-4 !w-1/2 !mx-auto"></mat-divider>
    <p class="!mb-2 mat-title text-darkGray !text-base">Job fees&colon;</p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Service fee&colon;&nbsp;</span>
      <span
        class="mat-subheading-1 !text-sub !mb-0">{{ extraJob.serviceFee | currency:extraJob.currency:'symbol' : (extraJob.serviceFee % 1 === 0 ? "1.0-0" : "1.2-2")}}</span>
    </p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Official fee&colon;&nbsp;</span>
      <span
        class="mat-subheading-1 !text-sub !mb-0">{{ extraJob.officialFee | currency:extraJob.currency:'symbol':(extraJob.officialFee % 1 === 0 ? "1.0-0" : "1.2-2")}}</span>
    </p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Out of pocket expenses&colon;&nbsp;</span>
      <span
        class="mat-subheading-1 !text-sub !mb-0"> {{ extraJob.outOfPocketExpenses | currency:extraJob.currency:'symbol':(extraJob.outOfPocketExpenses % 1 === 0 ? "1.0-0" : "1.2-2") }}</span>
    </p>
    <p class="!mb-1">
      <span class="text-sub opacity-80 !mr-2">Tax&colon;&nbsp;</span>
      <span
        class="mat-subheading-1 !text-sub !mb-0"> {{ extraJob.vatAmount | currency:extraJob.currency:'symbol':(extraJob.vatAmount % 1 === 0 ? "1.0-0" : "1.2-2") }}</span>
    </p>
    <p class="!mb-1">
      <span class="mat-body-2 !text-sub !mr-2">Total&colon;</span>
      <span
        class="mat-subheading-1 !text-sub !mb-0"> {{ extraJob.totalAmount | currency:extraJob.currency:'symbol':(extraJob.totalAmount % 1 === 0 ? "1.0-0" : "1.2-2") }}</span>
    </p>

    <div class="mt-4"
         *ngIf="extraJob.order.payment">
      <mat-divider class="!my-4 !w-1/2 !mx-auto"></mat-divider>
      <p class="!mb-2 mat-title text-darkGray !text-base">Payment details&colon;</p>
      <div class="inline-flex items-center mb-4">
        <span class="text-sub opacity-80 !mr-2">Payment Status:</span>
        <app-status
          class="block !text-sm"
          [statusMessage]="extraJob.order.payment.renderStatusLabel()"
          [statusStyle]="extraJob.order.payment.renderStatusStyle()">
        </app-status>
      </div>
    </div>

    <ng-container *ngIf="address$|async as billingAddress">
      <div class="billing-address">
        <p class="text-sub opacity-80 !mb-2">Billing address&colon;</p>
        <app-address-card class="!text-sub"
                          [address]="billingAddress"
                          [config]="addressCardComponentConfig"></app-address-card>
      </div>
    </ng-container>
  </ng-container>
</div>
