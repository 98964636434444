import { Injectable } from '@angular/core';
import {
  PaymentProvidersQuery,
  UpdatePaymentProvidersStatusMutation,
  UpdatePaymentProviderStatusInput,
  UpdatePaymentProviderStatusMutation,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';
import { paymentProviderFactory } from "@modules/payment/factories/payment-provider.factory";
import { PaymentProvider } from "@modules/payment/models/payment-provider.model";

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderApi {
  constructor (
    protected readonly paymentProvidersQuery: PaymentProvidersQuery,
    protected readonly updatePaymentProviderStatusMutation: UpdatePaymentProviderStatusMutation,
    protected readonly updatePaymentProvidersStatusMutation: UpdatePaymentProvidersStatusMutation,
  ) {
  }

  get (): Observable<PaymentProvider[]> {
    return this.paymentProvidersQuery.watch(null, {
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map(res => res.data.paymentProviders ? res.data.paymentProviders.map(type => paymentProviderFactory.create(type as PaymentProvider)) : []),
    );
  }

  updateStatus (input: UpdatePaymentProviderStatusInput): Observable<PaymentProvider> {
    return this.updatePaymentProviderStatusMutation.mutate({input}).pipe(
      map(res => res.data.updatePaymentProviderStatus ? paymentProviderFactory.create(res.data.updatePaymentProviderStatus as PaymentProvider) : null),
    );
  }

  updateManyStatus (input: UpdatePaymentProviderStatusInput[]): Observable<PaymentProvider[]> {
    return this.updatePaymentProvidersStatusMutation.mutate({input}).pipe(
      map(res => res.data.updatePaymentProvidersStatus ? res.data.updatePaymentProvidersStatus.map(item => paymentProviderFactory.create(item as PaymentProvider)) : []),
    );
  }
}
