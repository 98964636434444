<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="company$ as company">
    <div class="officer-resolutions-wrapper">
      <div class="table-container table-container-limited-scroll mat-elevation-z1">
        <table *ngIf="
            !(selectedCorporates$ | async).length;
            else companyIndirectShareholderContainer
          " mat-table class="table-custom" [multiTemplateDataRows]="viewSharesDetail() || kycApproach()"
          [ngClass]="{ 'multi-header': viewSharesDetail() }" [dataSource]="listOfficers">
          <!--Officer Type-->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="!text-white w-20" [ngClass]="{ hidden: viewSharesDetail() }">
              Type
            </th>
            <td mat-cell *matCellDef="let element" class="w-20">
              <div class="relative">
                <mat-icon class="text-darkGray opacity-70" matTooltip="{{
                    element.isNominee
                      ? 'Nominee'
                      : element.officer.isIndividual
                        ? 'Individual'
                        : 'Corporate'
                  }}" matTooltipClass="capitalize">
                  {{
                  element.isNominee
                  ? "assignment_ind"
                  : element.officer.isIndividual
                  ? "person"
                  : "apartment"
                  }}
                </mat-icon>
                <div *ngIf="approach === 'KYC' && element.isUBO">
                  <span
                    class="absolute bg-[#E6F9F0] text-green-default py-1 px-2 rounded-lg -bottom-3 -left-[.6rem] text-[1rem] font-['OpenSans_Semibold'] !font-semibold">UBO</span>
                </div>
              </div>
            </td>
          </ng-container>

          <!--Officer Name-->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="!text-white" [ngClass]="{ hidden: viewSharesDetail() }">
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="w-[90%]">
                <p class="line-clamp-3" [ngClass]="{
                    '!mb-1': !element.isNominee,
                    '!mb-0': element.isNominee
                  }">
                  {{ element.officer.name }}
                </p>
                <p *ngIf="!element.isNominee" class="mat-caption line-clamp-2 text-lightGray !mb-0">
                  {{ element.officer.officerEmail }}
                </p>
              </div>
            </td>
          </ng-container>

          <!--OFFICER ID-->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center"
              [ngClass]="{ hidden: viewSharesDetail() }">
              ID
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <p class="line-clamp-2 !mb-0">
                {{ element.officer.officerIDNumber ?? "-" }}
              </p>
            </td>
          </ng-container>

          <!--Officer Nationality / Country of Incorporation-->
          <ng-container matColumnDef="nationality">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center"
              [ngClass]="{ hidden: viewSharesDetail() }">
              {{
              individualDisplay()
              ? "Nationality"
              : corporateDisplay()
              ? "Incorporation Country"
              : "Nationality/Incorporation Country"
              }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <p class="!mb-0" *ngIf="element.isNominee">-</p>
              <p class="!mb-0" *ngIf="!element.isNominee">
                {{ element.officer.officerNationalCountry?.name }}
              </p>
            </td>
          </ng-container>

          <!--Officer Residence-->
          <ng-container matColumnDef="residence">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center"
              [ngClass]="{ hidden: viewSharesDetail() || corporateDisplay() }">
              Residence Country
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{ hidden: corporateDisplay() }">
              <p class="!mb-0">
                {{ element.officer.officerResidenceCountry?.name }}
              </p>
            </td>
          </ng-container>

          <!--Ordinary Shares-->
          <ng-container matColumnDef="ordinary" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-l border-r border-t"
              [ngClass]="{ hidden: !viewSharesDetail() }">
              Ordinary
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{ hidden: !viewSharesDetail() }">
              <ng-container *ngIf="
                  element.shareholdingDetails &&
                    element.shareholdingDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    officerHelper.getOrdinaryShares(element.shareholdingDetails)
                      .length;
                    else noOrdinaryShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of officerHelper.getOrdinaryShares(
                        element.shareholdingDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">{{
                        shareInfo.currency
                        }}</span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noOrdinaryShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Preference Shares-->
          <ng-container matColumnDef="preference">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-r border-t"
              [ngClass]="{ hidden: !viewSharesDetail() }">
              Preference
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{ hidden: !viewSharesDetail() }">
              <ng-container *ngIf="
                  element.shareholdingDetails &&
                    element.shareholdingDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    officerHelper.getPreferenceShares(
                      element.shareholdingDetails
                    ).length;
                    else noPreferenceShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of officerHelper.getPreferenceShares(
                        element.shareholdingDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">{{
                        shareInfo.currency
                        }}</span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noPreferenceShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Other Shares-->
          <ng-container matColumnDef="other">
            <th mat-header-cell *matHeaderCellDef
              class="!text-white !text-center border-solid border-0 border-r border-t"
              [ngClass]="{ hidden: !viewSharesDetail() }">
              Other
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{ hidden: !viewSharesDetail() }">
              <ng-container *ngIf="
                  element.shareholdingDetails &&
                    element.shareholdingDetails.length;
                  else noShares
                ">
                <ng-container *ngIf="
                    officerHelper.getOtherShares(element.shareholdingDetails)
                      .length;
                    else noOtherShare
                  ">
                  <ng-container *ngFor="
                      let shareInfo of officerHelper.getOtherShares(
                        element.shareholdingDetails
                      )
                    ">
                    <p class="share-details !mb-1">
                      <span class="mat-subheading-1 !text-sub text-darkGray">
                        {{ shareInfo.currency }}
                      </span>
                      &boxv;
                      <span>{{
                        shareInfo.numberOfShares | number: "1.0-0"
                        }}</span>
                    </p>
                  </ng-container>
                </ng-container>
                <ng-template #noOtherShare>
                  <span>&dash;</span>
                </ng-template>
              </ng-container>
              <ng-template #noShares>
                <span>&dash;</span>
              </ng-template>
            </td>
          </ng-container>

          <!--Officer Ownership-->
          <ng-container matColumnDef="ownership">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center"
              [ngClass]="{ hidden: isShowOwnership || viewSharesDetail() }">
              Ownership
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{
                hidden: isShowOwnership || shareDistributionApproach()
              }">
              {{ element.ownership | percent: "1.0-4" }}
            </td>
          </ng-container>

          <!--Officer KYC Status-->
          <ng-container matColumnDef="kycStatus">
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-center"
              [ngClass]="{ hidden: !kycApproach() }">
              KYC Status
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{ hidden: !kycApproach() }">
              <app-status *ngIf="element.documentStatusLabel" [statusMessage]="element.documentStatusLabel"
                [statusStyle]="element.documentsStatusStyle"></app-status>
            </td>
          </ng-container>

          <!--Action buttons-->
          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="!text-white !text-end"
              [ngClass]="{ hidden: viewSharesDetail() || hideAction }">
              Actions
            </th>
            <td mat-cell *matCellDef="let element" class="text-end" [ngClass]="{ hidden: hideAction }">
              <ng-container *ngIf="
                  company.status !== CompanyStatusEnum.Archived;
                  else viewInfoOnly
                ">
                <button mat-icon-button *ngIf="!(activeUser.isCustomer() && element.isNominee)" class="text-end"
                  (click)="onViewOfficerInfo(element)">
                  <mat-icon matTooltip="View Info" class="text-darkGray opacity-70">person_search
                  </mat-icon>
                </button>
                <!--SUBMIT KYC DOCUMENT-->
                <ng-container *ngIf="kycApproach()">
                  <ng-container *ngIf="element.isNominee; else nonNominee">
                    <button mat-icon-button color="primary" matTooltip="Check KYC" *ngIf="!activeUser.isCustomer()"
                      (click)="
                        onEmitResolutionAction(
                          officerResolutionAction.UploadKycDocument,
                          element
                        )
                      ">
                      <mat-icon class="cursor-pointer">rule</mat-icon>
                    </button>
                  </ng-container>
                  <ng-template #nonNominee>
                    <button mat-icon-button matTooltip="Check KYC" color="primary" (click)="
                        onEmitResolutionAction(
                          officerResolutionAction.UploadKycDocument,
                          element
                        )
                      ">
                      <mat-icon class="cursor-pointer">rule</mat-icon>
                    </button>
                  </ng-template>
                </ng-container>
                <!--DIRECTOR RESOLUTION-->
                <ng-container *ngIf="directorResolutionApproach()">
                  <button *ngIf="!element.isNominee || !activeUser.isCustomer()" [disabled]="
                      activeUser.isCustomer() &&
                      company.status === CompanyStatusEnum.InProcess
                    " mat-icon-button matTooltip="Edit Info" (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.EditInfo,
                        element
                      )
                    ">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button [disabled]="
                      activeUser.isCustomer() &&
                      company.status === CompanyStatusEnum.InProcess
                    " matTooltip="Replace Director" mat-icon-button (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.Replace,
                        element
                      )
                    ">
                    <mat-icon>sync</mat-icon>
                  </button>
                  <button [disabled]="
                      activeUser.isCustomer() &&
                      company.status === CompanyStatusEnum.InProcess
                    " mat-icon-button matTooltip="Remove Director" color="warn" (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.Remove,
                        element
                      )
                    ">
                    <mat-icon>person_remove</mat-icon>
                  </button>
                </ng-container>

                <!--SHAREHOLDER RESOLUTION-->
                <ng-container *ngIf="shareholderResolutionApproach()">
                  <button [disabled]="
                      activeUser.isCustomer() &&
                      company.status === CompanyStatusEnum.InProcess
                    " mat-icon-button (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.EditInfo,
                        element
                      )
                    " matTooltip="Edit Info">
                    <mat-icon>edit</mat-icon>
                  </button>
                </ng-container>
                <!--SHARE DISTRIBUTION BUTTON-->
                <ng-container *ngIf="shareDistributionApproach()">
                  <button mat-icon-button matTooltip="Distribute shares" color="accent" (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.DistributeShare,
                        element
                      )
                    ">
                    <mat-icon>price_change</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Delete" color="warn" (click)="
                      onEmitResolutionAction(
                        officerResolutionAction.Remove,
                        element
                      )
                    ">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </ng-container>
              </ng-container>
              <!--//ONLY VIEW OFFICER INFO IF COMPANY STATUS IS ARCHIVE, NO ACTION CAN PERFORM-->
              <ng-template #viewInfoOnly>
                <button *ngIf="!element.isNominee && activeUser.isCustomer()" mat-icon-button class="text-end"
                  (click)="onViewOfficerInfo(element)">
                  <mat-icon matTooltip="View Info" class="text-darkGray opacity-70">person_search
                  </mat-icon>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <!--      CAN ADD INDIRECT SHAREHOLDER-->
          <ng-container *ngIf="kycApproach() && corporateDisplay()" matColumnDef="addIndirectShareholder">
            <td mat-cell [attr.colspan]="displayedColumns.length - 4" *matCellDef="let element">
              <div
                class="py-2 px-2 ml-[9.2rem] bg-primary-50 inline-flex align-middle text-primary-default w-[calc(100%_-_9.2rem)]">
                <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
                <span>
                  {{ element.officer.name }} hold &#8805; 25% of company
                  capital. Click
                  <span (click)="select(element)" class="underline cursor-pointer">here</span>
                  to add the details of it shareholders
                </span>
              </div>
            </td>
          </ng-container>

          <!--VIEW SHAREHOLDING DETAIL-->
          <ng-container *ngIf="viewSharesDetail()">
            <!--GROUP TYPE-->
            <ng-container matColumnDef="type-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!w-20 !text-center !text-white">
                Type
              </th>
            </ng-container>

            <!--GROUP  NAME-->
            <ng-container matColumnDef="name-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white">
                Name
              </th>
            </ng-container>

            <!--GROUP  ID-->
            <ng-container matColumnDef="id-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white !text-center">
                ID
              </th>
            </ng-container>

            <!--   GROUP  NATIONALITY/ COUNTRY OF INCORPORATION-->
            <ng-container matColumnDef="country-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white !text-center">
                Nationality/ <br />Incorporation Country
              </th>
            </ng-container>

            <!--GROUP COUNTRY OF RESIDENCE-->
            <ng-container matColumnDef="residence-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="!text-white !text-center">
                Residence Country
              </th>
            </ng-container>

            <!--GROUP NUMBER OF SHARES-->
            <ng-container matColumnDef="shareholding-group">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="!text-center !text-white">
                Number of Shares
              </th>
            </ng-container>

            <!--GROUP OWNERSHIP-->
            <ng-container matColumnDef="ownership-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" [ngClass]="{
                  hidden: !viewSharesDetail() || shareDistributionApproach()
                }" class="!text-center !text-white">
                Ownership
              </th>
            </ng-container>

            <!--GROUP KYC STATUS-->
            <ng-container matColumnDef="kyc-status-group">
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" [ngClass]="{ hidden: !kycApproach() }"
                class="!text-center !text-white">
                KYC Status
              </th>
            </ng-container>

            <!--GROUP ACTIONS-->
            <ng-container matColumnDef="actions-group" stickyEnd>
              <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="w-fit !text-end !text-white"
                [ngClass]="{ hidden: hideAction }">
                Actions
              </th>
            </ng-container>
          </ng-container>

          <!--HEADER-FOOTER-ROW SECTION-->
          <ng-container *ngIf="viewSharesDetail()">
            <tr mat-header-row *matHeaderRowDef="
                [
                  'type-group',
                  'name-group',
                  'id-group',
                  'country-group',
                  'residence-group',
                  'shareholding-group',
                  'ownership-group',
                  'kyc-status-group',
                  'actions-group'
                ];
                sticky: true
              " class="bg-primary-800 group-header"></tr>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row class="bg-primary-800"></tr>
          <tr *matRowDef="let row; columns: displayedColumns" class="row-hover !cursor-default" [ngClass]="{
              'add-indirect':
                kycApproach() && corporateDisplay() && row.canCreateShareholder
            }" mat-row></tr>
          <ng-container *ngIf="kycApproach() && corporateDisplay()">
            <tr mat-row class="add-indirect-message" *matRowDef="
                let row;
                columns: ['addIndirectShareholder'];
                when: canViewShareholders
              " [ngClass]="{ hidden: !canViewShareholders(undefined, row) }"></tr>
          </ng-container>
          <tr *matNoDataRow class="mat-row">
            <td [colSpan]="
                hideAction ? calculateColSpan() - 1 : calculateColSpan()
              ">
              <table-no-record></table-no-record>
            </td>
          </tr>
        </table>

        <ng-template #companyIndirectShareholderContainer>
          <app-company-indirect-shareholder-container *ngIf="company$ | ngrxPush as company" (checkingKyc)="
              onEmitResolutionAction(
                officerResolutionAction.UploadKycDocument,
                $event
              )
            " (backTo)="backTo($event)" (selected)="select($event)" (adding)="addCompanyIndirectShareholder($event)"
            (updating)="updateCompanyIndirectShareholder($event)" (deleting)="deleteCompanyIndirectShareholder($event)"
            [company]="company" (exist)="exist()"
            [selectedCorporates]="selectedCorporates$ | async"></app-company-indirect-shareholder-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
