import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {Job} from '@app/modules/job/models/job.model';
import {Store} from '@ngrx/store';
import {
  OfficerInputReviewInformationComponent
} from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import {CalloutComponent} from '@shared/component/message/callout/callout.component';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {Observable} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {LetModule} from '@ngrx/component';
import {CompanyTaxReturnInput} from '@generated/graphql';
import {
  BusinessProfileService
} from '@app/modules/account-settings/pages/business-profile/services/business-profile.service';
import {BusinessProfile} from '@app/modules/account-settings/pages/business-profile/models/business-profile.model';
import {startCase} from 'lodash-es';

@Component({
  selector: 'app-tax-return-review-info',
  standalone: true,
  templateUrl: './tax-return-review-info.component.html',
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
    CalloutComponent,
    LetModule,
  ],
})
export class TaxReturnReviewInfoComponent implements OnInit {
  @Input() public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  public get isSingapore(): boolean {
    return this.job.company.country.code === 'SG';
  }

  public get isHongKong(): boolean {
    return this.job.company.country.code === 'HK';
  }

  public get taxReturnInput() {
    return this.jobDetail.taxReturnInput as CompanyTaxReturnInput;
  }

  public get businessProfile$(): Observable<BusinessProfile> {
    return this.businessProfileService.activeEntity$;
  }

  public startCase = startCase;

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    protected readonly commonHelper: CommonHelper,
    private readonly businessProfileService: BusinessProfileService,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    // this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
    //   this.countries = countries;
    // });
  }
}
