import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { ExtraJobType } from "@generated/graphql";
import { InjectionToken, Provider } from "@angular/core";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";

export class ExtraJobStore extends EntityStore<EntityFeatureNameEnum.ExtraJob, ExtraJobType, ExtraJob> {
  getEntry (): EntityEntry<EntityFeatureNameEnum.ExtraJob> {
    return 'EXTRA_JOB';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.ExtraJob> {
    return 'extraJobFeature';
  }

  get selectState (): SelectState<ExtraJobType> {
    return this.feature.selectExtraJobFeatureState;
  }
}

export const extraJobStore = new ExtraJobStore()

export const EXTRA_JOB_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.ExtraJobStore)

export const EXTRA_JOB_STORE_CONFIG: Provider = {
  provide: EXTRA_JOB_STORE,
  useValue: extraJobStore,
}
