import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserType } from '@generated/graphql';

export const resendEmailVerificationActions = createActionGroup({
  source: 'Email Verification',
  events: {
    'Resend Verification Email': props<{emailOrId: string}>(),
    'Resend Verification Email Success': emptyProps(),
    'Resend Verification Email Error': emptyProps(),
  },
});
