import { BaseModel } from '@shared/models/base.model';
import { PaymentProviderKeyType } from '@generated/graphql';
import { PaymentProvider } from '@modules/payment/models/payment-provider.model';
import { SupportedCountry } from '@modules/supported-country/models/supported-country.model';
import { paymentProviderFactory } from '@modules/payment/factories/payment-provider.factory';

export class PaymentProviderKey extends BaseModel implements PaymentProviderKeyType {
  id: string;
  clientKey: string;
  secretKey: string;
  paymentProvider: PaymentProvider;
  supportedCountry: SupportedCountry;

  constructor (state: PaymentProviderKeyType) {
    super(state);
    this.paymentProvider = state.paymentProvider
      ? paymentProviderFactory.create(state.paymentProvider)
      : null;
  }

  getModelName (): string {
    return PaymentProviderKey.name;
  }
}
