import {ModelFactory} from "@shared/factories/model.factory";
import {CorporateType} from "@generated/graphql";
import {Corporate} from "@modules/company/models/corporate.model";
import {companyShareholderFactory} from "@modules/company/factories/company-shareholder.factory";
import {companyIndirectShareholderFactory} from "@modules/company/factories/company-indirect-shareholder.factory";

export class CorporateFactory extends ModelFactory<CorporateType, Corporate> {
  create(state: CorporateType): Corporate {
    if (state.__typename === 'CompanyShareholderType') {
      return companyShareholderFactory.tryCreating(state)
    }

    return companyIndirectShareholderFactory.tryCreating(state)
  }
}

export const corporateFactory = new CorporateFactory()
