import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";
import { OrderData, OrderSummary } from "@shared/component/payments/components/order-summary/order-summary.type";
import { OrderSummaryFactory } from "@shared/component/payments/components/order-summary/order-summary.factory";
import { OrderHelper } from "@shared/helpers/order.helpers";
import { cloneDeep } from "lodash-es";

@Injectable()
export class OrderSummaryService {
  get data$ (): Observable<OrderData> {
    return this._data$.asObservable()
  }

  get orderSummary$ (): Observable<OrderSummary> {
    return this.data$.pipe(
      map(data => {
        return this.factory.create(data)
      })
    )
  }

  get factory (): OrderSummaryFactory {
    return this.orderSummaryFactory
  }

  private readonly _data$ = new BehaviorSubject<OrderData>(this.orderHelper)

  constructor (
    private readonly orderSummaryFactory: OrderSummaryFactory,
    private readonly orderHelper: OrderHelper,
  ) {
  }


  setData (data: OrderData): void {
    this._data$.next(cloneDeep(data))
  }
}
