import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Job} from "@modules/job/models/job.model";
import {GstRegistrationOptionsEnum} from "@generated/graphql";
import {ACTIVE_USER} from "@modules/auth/providers/auth.provider";
import {Observable} from "rxjs";
import {User} from "@modules/user/models/user.model";
import {LetModule} from "@ngrx/component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LetModule,
  ],
  selector: 'app-gst-registration-info',
  templateUrl: './gst-registration-info.component.html',
  styleUrls: ['./gst-registration-info.component.scss']
})
export class GstRegistrationInfoComponent implements OnInit {
  @Input() public job: Job;

  GstRegistrationOptionsEnum = GstRegistrationOptionsEnum

  constructor(
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
  ) {
  }

  ngOnInit(): void {
  }
}
