import { Injectable } from "@angular/core";
import {
  CompanyAuditorInput,
  CompanyAuditorType,
  CreateCompanyAuditorMutation,
  UpdateCompanyAuditorMutation,
  DeleteCompanyAuditorMutation,
  UpdateCompanyAuditorInput
} from "@generated/graphql";
import { CreateContract } from "@shared/contracts/create.contract";
import { map, Observable } from "rxjs";
import { UpdateContract } from "@shared/contracts/update.contract";
import { DeleteContract } from "@shared/contracts/delete.contract";
import { CompanyAuditor } from "@modules/company/models/company-auditor.model";
import { companyAuditorFactory } from "@modules/company/factories/company-auditor.factory";

@Injectable({
  providedIn: 'root'
})
export class CompanyAuditorApi implements CreateContract<CompanyAuditorInput, CompanyAuditor>,
  UpdateContract<UpdateCompanyAuditorInput, Observable<CompanyAuditor>>,
  DeleteContract<string, Observable<boolean>> {

  constructor (
    private readonly companyAuditorMutation: CreateCompanyAuditorMutation,
    private readonly updateCompanyAuditorMutation: UpdateCompanyAuditorMutation,
    private readonly deleteCompanyAuditorMutation: DeleteCompanyAuditorMutation
  ) {
  }

  delete$ (id: string): Observable<boolean> {
    return this.deleteCompanyAuditorMutation.mutate({id}).pipe(
      map(res => res.data.deleteCompanyAuditor)
    )
  }

  create$ (input: CompanyAuditorInput): Observable<CompanyAuditor> {
    return this.companyAuditorMutation.mutate({input}).pipe(
      map(res => res.data.createCompanyAuditor
        ? companyAuditorFactory.create(res.data.createCompanyAuditor as CompanyAuditorType)
        : null
      )
    )
  }

  update$ (input: UpdateCompanyAuditorInput): Observable<CompanyAuditor> {
    return this.updateCompanyAuditorMutation.mutate({input}).pipe(
      map(res => res.data.updateCompanyAuditor
        ? companyAuditorFactory.create(res.data.updateCompanyAuditor as CompanyAuditorType)
        : null
      )
    )
  }
}
