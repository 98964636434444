import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import FileService from '@app/modules/file/services/file.service';
import { FileType } from '@generated/graphql';
import {
  DropzoneAction,
  DropzoneFileChangedEvent,
  DropzoneUploadComponent,
} from '@shared/component/common/dropzone-upload/dropzone-upload.component';
import { generalAcceptFileTypes } from '@shared/types/file.type';
import { BehaviorSubject } from 'rxjs';

interface DialogData {
  oldFile: FileType;
}

@Component({
  standalone: true,
  selector: 'replace-document-dialog',
  templateUrl: 'replace-document-dialog.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    DropzoneUploadComponent,
    MatProgressBarModule,
    MatDialogModule,
  ],
})
export class ReplaceDocumentDialogComponent implements OnDestroy {
  public readonly accept = generalAcceptFileTypes;

  public newDocument: File = null;

  public uploading$ = new BehaviorSubject(false);

  constructor(
    public dialogRef: MatDialogRef<ReplaceDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _fileService: FileService,
  ) { }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  public documentFileChangedHandler({ files, type }: DropzoneFileChangedEvent) {
    if (type === DropzoneAction.Added) {
      this.newDocument = files[0];
    } else {
      this.newDocument = null;
    }
  }

  public submit() {
    this.uploading$.next(true);

    this._fileService
      .replaceCompanyDocument(this.data.oldFile.id, this.newDocument)
      .subscribe((replacedDocument) => {
        this.uploading$.next(false);

        if (replacedDocument) {
          this.dialogRef.close(replacedDocument);
        }
      });
  }

  ngOnDestroy(): void {
    this.uploading$.complete();
  }
}
