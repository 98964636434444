import {ModelFactory} from "@shared/factories/model.factory";
import {CompanyIndirectShareholderType} from "@generated/graphql";
import {CompanyIndirectShareholder} from "@modules/company/models/company-indirect-shareholder.model";

export class CompanyIndirectShareholderFactory extends ModelFactory<CompanyIndirectShareholderType, CompanyIndirectShareholder> {
  create(state: CompanyIndirectShareholderType): CompanyIndirectShareholder {
    return new CompanyIndirectShareholder(state);
  }
}

export const companyIndirectShareholderFactory = new CompanyIndirectShareholderFactory()
