import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Job } from "@modules/job/models/job.model";
import { FormControl, FormGroup } from "@angular/forms";
import { CommonService } from "@shared/services/common.service";
import { Validators } from "@config/validators.config";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";
import { SupportedCountry } from "@modules/supported-country/models/supported-country.model";
import { ExtraJobInput } from "@generated/graphql";
import { UntilDestroy } from "@ngneat/until-destroy";
import { InvalidControlScrollDirective } from "@shared/share-directives/directives/invalid-control-scroll.directive";
import { COMMON_SERVICE_CONFIG } from "@shared/providers/common-service.provider";
import { BusinessPlanService } from "@modules/account-settings/pages/business-profile/services/business-plan.service";
import { Observable } from "rxjs";

export interface ExtraJobDialogData {
  job: Job
  extraJob?: ExtraJob
}

export type ExtraJobForm = FormGroup<{
  id: FormControl<string | number>
  matter: FormControl<string>
  currency: FormControl<string>
  scopeOfWork: FormControl<string>
  jobId: FormControl<string>
  serviceFee: FormControl<number>
  officialFee: FormControl<number>
  outOfPocketExpenses: FormControl<number>
}>

@UntilDestroy()
@Component({
  selector: 'app-extra-job-dialog',
  templateUrl: './extra-job-dialog.component.html',
  styleUrls: ['./extra-job-dialog.component.scss'],
  providers: [COMMON_SERVICE_CONFIG],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraJobDialogComponent implements OnInit {
  static width = '50%';

  @Output() createOrUpdate = new EventEmitter<ExtraJobInput>()
  form: ExtraJobForm
  private _scrollEl: InvalidControlScrollDirective;

  @ViewChild(InvalidControlScrollDirective)
  set scrollEl (content: InvalidControlScrollDirective) {
    this._scrollEl = content;
  }

  get scrollEl (): InvalidControlScrollDirective {
    return this._scrollEl;
  }

  get actionLabel (): string {
    return this.extraJob ? 'Update job' : 'Create job';
  }

  get job (): Job {
    return this.data?.job
  }

  get extraJob (): ExtraJob {
    return this.data?.extraJob
  }

  get visiblePayment$ (): Observable<boolean> {
    return this.businessPlanService.visiblePayment$()
  }

  constructor (
    public readonly commonService: CommonService,
    private readonly dialogRef: MatDialogRef<ExtraJobDialogComponent>,
    private readonly businessPlanService: BusinessPlanService,
    @Inject(MAT_DIALOG_DATA) private readonly data?: ExtraJobDialogData,
  ) {
    if (!this.data?.job) {
      throw new Error('Something wrong when initialize ' + ExtraJobDialogComponent.name)
    }
  }

  ngOnInit (): void {
    const {fb} = this.commonService

    this.form = fb.group({
      id: fb.control({value: null, disabled: !this.extraJob}),
      matter: fb.control(null, [Validators.required]),
      currency: fb.control(this.job.supportedCountry.currency ??
        SupportedCountry.defaultCurrency, [
        Validators.required
      ]),
      scopeOfWork: fb.control(null, [
        Validators.required
      ]),
      jobId: fb.control(this.job.id, [
        Validators.required
      ]),
      serviceFee: fb.control(ExtraJob.minServiceFee, [
        Validators.required,
        Validators.min(ExtraJob.minServiceFee),
        Validators.max(ExtraJob.maxServiceFee)
      ]),
      officialFee: fb.control(ExtraJob.minOfficialFee, [
        Validators.required,
        Validators.min(ExtraJob.minOfficialFee),
        Validators.max(ExtraJob.maxOfficialFee)
      ]),
      outOfPocketExpenses: fb.control(ExtraJob.minOutOfPocketExpenses, [
        Validators.required,
        Validators.min(ExtraJob.minOutOfPocketExpenses),
        Validators.max(ExtraJob.maxOutOfPocketExpenses)
      ]),
    })

    this.whenHasExtraJob()
  }

  whenHasExtraJob () {
    if (this.extraJob) {
      this.form.controls.id.addValidators([Validators.required])

      this.form.patchValue(this.extraJob)
    } else {
      this.form.controls.id.removeValidators([Validators.required])
    }

    this.form.updateValueAndValidity()
  }

  onCloseDialog (): void {
    this.dialogRef.close();
  }

  onSubmit (): void {
    this.createOrUpdate.next(this.form.value as ExtraJobInput)
  }
}
