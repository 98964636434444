import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'auth-card-wp',
  templateUrl: './auth-card.component.html',
  styleUrls: ['./auth-card.component.scss']
})
export class AuthCardComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
