import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentActions from './document.actions';

export interface DocumentState {
  refresh: number;
}

export const initalState: DocumentState = {
  refresh: +new Date(),
};

export const documentReducer = createReducer(
  initalState,
  on(DocumentActions.refreshDocuments, (state) => ({ refresh: +new Date() })),
);

export const documentFeatureKey = 'document';
