import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SupportedCountry } from '@generated/graphql';

export abstract class CompanyOfficerForm {
  protected fb: FormBuilder = new FormBuilder();

  protected _form: FormArray<FormGroup<any>>;
  get form() {
    return this._form;
  }

  protected _countryOfIncorporation: SupportedCountry;

  valid = false;

  constructor() {
    //
  }

  setCountryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;
  }

  removeAt(index: number) {
    this._form.removeAt(index);
  }

  removeByRef(refId: string, representativeRefId: string = undefined) {
    if (!refId.includes('temporary-')) {
      return;
    }

    const index = this._form.controls.findIndex(
      (c) => c.controls.refId && c.controls.refId.value === refId,
    );

    if (index > -1) {
      this._form.removeAt(index);
    } else {
      if (representativeRefId) {
        const repIndex = this._form.controls.findIndex(
          (c) =>
            c.controls.refId && c.controls.refId.value === representativeRefId,
        );

        if (repIndex > -1) {
          this._form.removeAt(repIndex);
        }
      }
    }
  }

  abstract checkValid(): void;
}
