import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserType } from '@generated/graphql';

export const impersonateActions = createActionGroup({
  source: 'Auth',
  events: {
    'Set impersonate user': props<{user: UserType}>(),

    'Impersonate user': props<{id: string}>(),
    'Impersonate user success': props<{user: UserType}>(),
    'Impersonate user error': props<any>(),

    'Stop impersonate': emptyProps(),
    'Stop impersonate success': emptyProps(),
    'Stop impersonate error': emptyProps(),
  },
});
