<ng-container *ngIf="activeUser$ | async as activeUser">
  <div class="job-detail-wrapper flex flex-col" *ngIf="job$ | async as job">
    <!--BASIC INFO-->
    <section class="basic-info-container">
      <mat-card
        class="h-fit !p-8 !pb-0 !shadow-none !border-b-0 !rounded-b-none"
      >
        <div class="flex flex-row w-full mb-4 items-baseline">
          <a [routerLink]="appRoutes.jobs()" mat-icon-button class="!mr-4">
            <mat-icon>arrow_back</mat-icon>
          </a>
          <section class="w-full">
            <div class="mb-4">
              <p class="mat-title text-darkGray !mb-0">
                <span>{{ job.getMatterDisplay() }}&nbsp;&dash;&nbsp;</span>
                <span *ngIf="job.company.name" class="text-primary-default">{{
                  job.company.name
                }}</span>
              </p>
              <p class="!mb-0 text-lightGray !text-sub">
                <span>Created on&nbsp;</span>
                <span>{{ job.createdAt | date: "d MMM, YYYY" }} by&nbsp;</span>
                <span class="font-['OpenSans_Medium'] text-darkGray">{{
                  job.createdBy.profile.fullName
                }}</span>
              </p>
            </div>
          </section>
        </div>

        <div class="flex flex-row w-full gap-3 h-fit pb-4">
          <section class="overview-container grow w-full">
            <mat-card class="h-full !shadow-none">
              <mat-card-title class="mat-title !text-base"
                >Details
              </mat-card-title>

              <mat-card-content>
                <div class="flex flex-row gap-4 mb-4">
                  <section class="overview-detail w-3/5">
                    <div class="flex flex-row items-center mb-4">
                      <p
                        class="!mb-0 inline-flex text-darkGray opacity-70 !mr-2 w-[16rem] max-w-xs"
                      >
                        <mat-icon class="!text-base text-center mr-2"
                          >auto_mode
                        </mat-icon>
                        <span>Status&colon;</span>
                      </p>
                      <app-status
                        [statusMessage]="job.status.toLocaleLowerCase()"
                        [statusStyle]="job.renderStatusStyle()"
                        [ngClass]="'!text-sm capitalize'"
                      ></app-status>
                      <!--                      <button mat-icon-button class="ml-2 text-grey-800">-->
                      <!--                        <mat-icon class="!text-xbase">edit</mat-icon>-->
                      <!--                      </button>-->
                    </div>
                    <div class="flex flex-row items-center mb-4">
                      <p
                        class="!mb-0 inline-flex text-darkGray opacity-70 !mr-2 w-[16rem] max-w-xs"
                      >
                        <mat-icon class="!text-base text-center mr-2"
                          >schedule
                        </mat-icon>
                        <span>Estimated Time&colon;</span>
                      </p>
                      <p class="mat-subheading-1 !mb-0 !text-sub text-darkGray">
                        <!--                                                5 business day(s)-->
                        {{ getEstimatedTime(job) }}&nbsp;business day(s)
                      </p>
                      <button
                        mat-icon-button
                        class="text-grey-800"
                        *ngIf="
                          !activeUser.isCustomer() &&
                          job.status !== JobStatusEnum.Closed &&
                          job.status !== JobStatusEnum.Done &&
                          job.status !== JobStatusEnum.Draft
                        "
                        (click)="onEditEstimatedDate(job)"
                      >
                        <mat-icon class="!text-xbase">edit</mat-icon>
                      </button>
                    </div>
                    <div class="flex flex-row items-center mb-4">
                      <p
                        class="!mb-0 inline-flex text-darkGray opacity-70 !mr-2 w-[16rem] max-w-xs"
                      >
                        <mat-icon class="!text-base text-center mr-2"
                          >date_range
                        </mat-icon>
                        <span>Deadline&colon;</span>
                      </p>
                      <p class="mat-subheading-1 !mb-0 !text-sub text-darkGray">
                        {{ getJobDeadline(job) | date: "d MMM, YYYY" }}
                      </p>
                    </div>
                    <div
                      class="flex flex-row items-center"
                      *ngIf="visiblePayment$ | async"
                    >
                      <p
                        class="!mb-0 inline-flex text-darkGray opacity-70 !mr-2 w-[16rem] max-w-xs"
                      >
                        <mat-icon class="!text-base text-center mr-2"
                          >request_quote
                        </mat-icon>
                        <span>Payment Status&colon;</span>
                      </p>
                      <app-status
                        [statusMessage]="getPaymentStatusLabel(job)"
                        [statusStyle]="getPaymentStatusStyle(job)"
                        [ngClass]="'text-sm'"
                      ></app-status>
                    </div>
                  </section>
                  <mat-divider
                    vertical
                    *ngIf="job.status !== jobStatus.Draft"
                  ></mat-divider>

                  <section
                    class="assign-container w-2/5"
                    *ngIf="job.status !== jobStatus.Draft"
                  >
                    <section class="mb-4">
                      <div class="flex flex-row justify-between items-center">
                        <p class="!mb-0 inline-flex text-darkGray opacity-70">
                          <mat-icon class="!text-base text-center mr-2"
                            >assignment_ind
                          </mat-icon>
                          <span
                            matBadgePosition="after"
                            [matBadge]="(assignedExperts$ | async).length"
                            matBadgeSize="small"
                            >Expert(s)</span
                          >
                        </p>
                        <ng-container *ngIf="canAssignExpert$ | async">
                          <ng-container
                            *ngrxLet="assignedExperts$ as assignedExperts"
                          >
                            <mat-icon
                              *ngIf="!assignedExperts.length"
                              color="accent"
                              matRipple
                              class="cursor-pointer px-1 rounded bg-primary-50 !w-fit"
                              (click)="onOpenExpertDialog(job)"
                            >
                              add
                            </mat-icon>
                            <button
                              mat-button
                              *ngIf="assignedExperts.length"
                              disableRipple
                              (click)="onOpenExpertDialog(job)"
                              class="!mb-0 cursor-pointer text-secondary-400 hover:mat-subheading-1 hover:text-primary-default hover:underline text-sub"
                            >
                              Edit
                            </button>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="pl-8 mt-2">
                        <ng-container
                          *ngrxLet="assignedExperts$ | async as assignedExperts"
                        >
                          <div *ngIf="assignedExperts.length; else noExpert">
                            <app-user-assigned-chip-list
                              [users]="assignedExperts"
                            ></app-user-assigned-chip-list>
                          </div>
                          <ng-template #noExpert>
                            <div
                              class="!mb-0 text-lightGray text-sub"
                              *ngIf="canAssignExpert$ | async"
                            >
                              <span>None&nbsp;&dash;&nbsp;</span>
                              <span
                                class="hover:underline hover:text-primary-default hover:cursor-pointer"
                                (click)="onOpenExpertDialog(job)"
                                >Assign Expert</span
                              >
                            </div>
                          </ng-template>
                        </ng-container>
                      </div>
                    </section>

                    <section class="mb-4">
                      <div class="flex flex-row justify-between items-center">
                        <p class="!mb-0 inline-flex text-darkGray opacity-70">
                          <mat-icon class="!text-base text-center mr-2"
                            >people_alt
                          </mat-icon>
                          <span
                            matBadgePosition="after"
                            [matBadge]="(assignedCustomers$ | async).length"
                            matBadgeSize="small"
                            >Customer(s)</span
                          >
                        </p>
                        <ng-container *ngIf="canAddCustomers$ | async">
                          <ng-container
                            *ngrxLet="assignedCustomers$ as assignedCustomers"
                          >
                            <mat-icon
                              *ngIf="!assignedCustomers.length"
                              color="accent"
                              matRipple
                              class="cursor-pointer px-1 rounded bg-primary-50 !w-fit"
                              (click)="onOpenCustomerDialog(job)"
                            >
                              add
                            </mat-icon>
                            <button
                              mat-button
                              *ngIf="assignedCustomers.length"
                              disableRipple
                              (click)="onOpenCustomerDialog(job)"
                              class="!mb-0 cursor-pointer text-secondary-400 hover:mat-subheading-1 hover:text-primary-default hover:underline text-sub"
                            >
                              Edit
                            </button>
                          </ng-container>
                        </ng-container>
                      </div>

                      <div class="pl-8 mt-2">
                        <ng-container
                          *ngrxLet="
                            assignedCustomers$ | async as assignedCustomers
                          "
                        >
                          <div
                            *ngIf="assignedCustomers.length; else noCustomer"
                          >
                            <app-user-assigned-chip-list
                              [users]="assignedCustomers"
                            ></app-user-assigned-chip-list>
                          </div>
                          <ng-template #noCustomer>
                            <div
                              class="!mb-0 text-lightGray text-sub"
                              *ngIf="canAddCustomers$ | async"
                            >
                              <span>None&nbsp;&dash;&nbsp;</span>
                              <span
                                class="hover:underline hover:text-primary-default hover:cursor-pointer"
                                (click)="onOpenCustomerDialog(job)"
                                >Add Customer</span
                              >
                            </div>
                          </ng-template>
                        </ng-container>
                      </div>
                    </section>
                  </section>
                </div>
                <ng-container
                  *ngIf="
                    (visiblePayment$ | async) && (showMakePayment$ | async)
                  "
                >
                  <app-callout
                    [message]="
                      job.order?.payment &&
                      job.order?.payment?.status !==
                        PaymentStatusEnum.AttemptingPayment
                        ? job.order?.payment?.renderPaymentMessage(activeUser)
                        : renderUnpaidMessages(activeUser)
                    "
                    [appearance]="
                      job.order?.payment
                        ? job.order?.payment?.renderPaymentHintMessageStyle()
                        : 'warn'
                    "
                  >
                  </app-callout>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </section>

          <section class="actions-container min-w-[34rem] max-w-[38rem] h-auto">
            <mat-card class="h-full !shadow-none">
              <mat-card-title class="mat-title !text-base"
                >Quick Actions
              </mat-card-title>

              <mat-card-content class="!flex !flex-col">
                <a
                  [routerLink]="'/companies/' + job.company.id"
                  mat-raised-button
                  class="!mb-3"
                  color="primary"
                  matTooltip="View Company Profile"
                >
                  <mat-icon matPrefix>business</mat-icon>
                  Company Profile
                </a>

                <a
                  *ngIf="job.parentId"
                  [routerLink]="'/job/' + job.parentId"
                  mat-raised-button
                  class="!mb-3 bg-[#6a59ff] text-white"
                  matTooltip="View Parent Request"
                >
                  <mat-icon matPrefix>work</mat-icon>
                  Parent Request
                </a>

                <button
                  *ngIf="!activeUser.isCustomer() && (showMarkDone$ | async)"
                  mat-raised-button
                  class="!mb-3 bg-[#03A66D] text-white"
                  appConfirmDialog
                  [confirmMessage]="commonHelper.getMarkJobDoneMessage()"
                  matTooltip="Mark Job as Done"
                  (ok)="markJobDone(job)"
                >
                  <mat-icon matPrefix>task_alt</mat-icon>
                  Mark Job Done
                </button>

                <a
                  *ngIf="(showMakePayment$ | async) && job.parentId === null"
                  [disabled]="!job.order?.canMakePayment"
                  [routerLink]="appRoutes.jobCheckout(job.id)"
                  mat-raised-button
                  class="!mb-3 bg-orange-default text-white"
                >
                  <mat-icon matPrefix>request_quote</mat-icon>
                  Make payment
                </a>

                <button
                  *ngIf="
                    job.order?.payment?.canGetReceiptUrl &&
                    job.parentId === null
                  "
                  [disabled]="job.order?.payment?.loading"
                  (click)="openPaymentReceipt(job.order?.payment)"
                  mat-raised-button
                  class="!mb-3 bg-orange-default text-white"
                  matTooltip="View Proof of Payment"
                >
                  <mat-icon matPrefix>request_quote</mat-icon>
                  View Proof of Payment
                </button>

                <button
                  mat-raised-button
                  class="bg-[#5e6a72cc] text-white"
                  *ngIf="canCloseJob(job)"
                  appConfirmDialog
                  title="Close job request?"
                  [confirmMessage]="
                    commonHelper.getCancelJobConfirmMessage(activeUser)
                  "
                  (ok)="cancelJobConfirmed(job)"
                >
                  <mat-icon matSuffix>work_off</mat-icon>
                  <span *ngIf="activeUser.isCustomer()">Cancel Request</span>
                  <span *ngIf="!activeUser.isCustomer()">Close Job</span>
                </button>
              </mat-card-content>
            </mat-card>
          </section>
        </div>
      </mat-card>
    </section>

    <!--JOB DETAIL-->
    <section class="job-detail-container">
      <mat-tab-group
        class="border border-x custom-tab"
        [selectedIndex]="selectedIndex$ | async"
      >
        <!--CONVERSATION-->
        <mat-tab label="Conversation">
          <app-conversation [job]="job" [job$]="job$"></app-conversation>
        </mat-tab>
        <!--MAIN REQUEST-->
        <mat-tab *ngIf="!job.parentId" label="Main Request">
          <app-job-main-request [job]="job"></app-job-main-request>
        </mat-tab>
        <!--SCOPE OF WORK & FEE-->
        <mat-tab
          [label]="
            (visiblePayment$ | async) ? 'Scope of Work & Fees' : 'Scope of Work'
          "
        >
          <app-job-scope-and-fee></app-job-scope-and-fee>
        </mat-tab>
        <!--JOB FILES-->
        <mat-tab label="Job Files">
          <app-job-files></app-job-files>
        </mat-tab>
        <!--EXTRA JOBS-->
        <mat-tab
          label="Extra Jobs"
          *ngIf="
            job.status !== JobStatusEnum.Draft && (visibleExtraJobs$ | async)
          "
        >
          <app-extra-jobs
            (delete)="onDeleteExtraJob($event)"
            (closeJob)="onCloseExtraJob($event)"
            (confirmJob)="onConfirmExtraJob($event)"
            (updateOrCreate)="onUpdateOrCreateExtraJob($event)"
            [job]="job"
            [extraJobs]="extraJobs$ | async"
          ></app-extra-jobs>
        </mat-tab>
      </mat-tab-group>
    </section>
  </div>
</ng-container>
