import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from "@angular/common";
import {OfficerInformationComponent} from "@shared/component/officer/officer-information/officer-information.component";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-officer-info-dialog',
  standalone: true,
  templateUrl: './officer-info-dialog.component.html',
  styleUrls: ['./officer-info-dialog.component.scss'],
  imports: [CommonModule, MatDialogModule, OfficerInformationComponent, MatButtonModule]
})
export class OfficerInfoDialogComponent implements OnInit {
  private _officerInfo;

  get officerInfo() {
    return this._officerInfo;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    protected readonly dialogRef: MatDialogRef<OfficerInfoDialogComponent>) {
  }

  ngOnInit(): void {
    this._officerInfo = this.data.officerInfo;
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
