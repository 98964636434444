import { Injectable } from "@angular/core";
import { ModelFactory } from "@shared/factories/model.factory";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";
import { OrderData, OrderSummary } from "@shared/component/payments/components/order-summary/order-summary.type";

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryFactory extends ModelFactory<OrderData, OrderSummary> {
  create (state: OrderData): OrderSummary {
    if (state instanceof ExtraJob) {
      return new OrderSummary(
        state,
        state.currency,
        state.job.supportedCountry,
        state.officialFee,
        state.subTotalAmount,
        state.vatAmount,
        state.totalAmount,
      )
    }

    return new OrderSummary(
      state,
      state.getMainCurrency(),
      state.countryOfIncorporation,
      state.calculateOfficialFee(),
      state.calculateSubtotal(),
      state.calculateTax(),
      state.calculateTotalFee()
    )
  }

  fake (): OrderSummary {
    throw new Error('This method is not implemented!');
  }
}
