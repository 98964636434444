import {ModelFactory} from "@shared/factories/model.factory";
import {GstReturnableType} from "@generated/graphql";
import {GstReturnable} from "@modules/gst-return/models/gst-returnable.model";
import {companyFactory} from "@modules/company/factories/company.factory";

export class GstReturnableFactory extends ModelFactory<GstReturnableType, GstReturnable> {
  create(state: GstReturnableType): GstReturnable {
    return companyFactory.tryCreating(state);
  }
}

export const gstReturnableFactory = new GstReturnableFactory()
