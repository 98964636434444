import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AssignPeopleDialogComponent,
  AssignPeopleDialogComponentData,
} from '@shared/component/job/dialog/assign-people-dialog/assign-people-dialog.component';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  delay,
  EMPTY,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { User } from '@modules/user/models/user.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JobService } from '@modules/job/services/job.service';
import { Job } from '@modules/job/models/job.model';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import {
  JobStatusEnum,
  OrderStatusEnum,
  PaymentProviderAccountStatusEnum,
  PaymentProviderNameEnum,
  PaymentStatusEnum,
  PermissionEnum,
  RoleEnum,
  UpdateJobInput,
} from '@generated/graphql';
import { JobApi } from '@modules/job/api/job.api';
import { CommonService } from '@shared/services/common.service';
import { ExtraJob } from '@modules/extra-job/models/extra-job.model';
import { ExtraJobApi } from '@modules/extra-job/api/extra-job.api';
import { ExtraJobsUpdateOrCreateEvent } from '@modules/job/components/job-detail/extra-jobs/extra-jobs.component';
import { COMMON_SERVICE_CONFIG } from '@shared/providers/common-service.provider';
import { OrderHelper } from '@shared/helpers/order.helpers';
import { BusinessPlanService } from '@app/modules/account-settings/pages/business-profile/services/business-plan.service';
import { PaymentService } from '@shared/component/payments/services/payment.service';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeQueryParams } from '@modules/payment/services/stripe.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '@environment/environment';
import { PaymentProviderAccountService } from '@modules/payment/services/payment-provider-account.service';
import { cloneDeep } from 'lodash-es';
import { Payment } from '@modules/payment/models/payment.model';
import moment from 'moment';
import { EditEstimatedDateComponent } from '@modules/job/components/dialog/edit-estimated-date/edit-estimated-date.component';
import { CommonHelper } from '@shared/helpers/common.helper';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Duration } from 'luxon';
import { SnackBarTypeEnum } from '@shared/types/commonEnum';
import { ExpertService } from '@modules/user/services/expert.service';

export enum JobDetailTab {
  ExtraJob = 4,
}

@UntilDestroy()
@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
  providers: [COMMON_SERVICE_CONFIG],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDetailPageComponent implements OnInit {
  private _extraJobs$ = new BehaviorSubject<ExtraJob[]>([]);
  private _stripeParams$ = new BehaviorSubject<StripeQueryParams>(null);

  public job$: Observable<Job>;
  public expertsCanAssigned$: Observable<User[]>;
  public canAssignExpert$: Observable<boolean>;
  public canAddCustomers$: Observable<boolean>;
  public visibleExtraJobs$ = this.businessPlanService.isPremiumPlan$;

  public estimatedTime: number;

  protected _assignedCustomers$ = new BehaviorSubject<User[]>([]);
  protected _assignedExperts$ = new BehaviorSubject<User[]>([]);

  protected _customers$ = new BehaviorSubject<User[]>([]);
  protected _experts$ = new BehaviorSubject<User[]>([]);
  protected _selectedIndex$ = new BehaviorSubject<number>(0);
  protected readonly PaymentStatusEnum = PaymentStatusEnum;

  get visiblePayment$() {
    return this.businessPlanService.visiblePayment$();
  }

  get extraJobs$() {
    return this._extraJobs$.asObservable();
  }

  get assignedCustomers() {
    return this._assignedCustomers$.value;
  }

  get assignedExperts() {
    return this._assignedExperts$.value;
  }

  get customers() {
    return this._customers$.value;
  }

  get experts() {
    return this._experts$.value;
  }

  get assignedCustomers$(): Observable<User[]> {
    return this._assignedCustomers$.asObservable();
  }

  get assignedExperts$(): Observable<User[]> {
    return this._assignedExperts$.asObservable();
  }

  get showMakePayment$(): Observable<boolean> {
    return this.job$.pipe(
      map((job) => {
        if (!job.order) {
          return false;
        }

        if (job.order?.status === OrderStatusEnum.Paid) {
          return false;
        }

        if (
          job.status === JobStatusEnum.Closed ||
          job.status === JobStatusEnum.Draft
        ) {
          return false;
        }

        return (
          job?.order &&
          (!!job.order?.canMakePayment ||
            job.order?.payment?.isProcessingPayment)
        );
      }),
    );
  }

  get showMarkDone$(): Observable<boolean> {
    return this.job$.pipe(
      map((job) => {
        return job.status === JobStatusEnum.Ongoing;
      }),
    );
  }

  get stripeParams$(): Observable<StripeQueryParams> {
    return this._stripeParams$.asObservable();
  }

  get selectedIndex$(): Observable<number> {
    return this._selectedIndex$.asObservable();
  }

  jobId: string;

  jobStatus = JobStatusEnum;

  protected readonly JobStatusEnum = JobStatusEnum;

  constructor(
    @Inject(APP_ROUTES) public readonly appRoutes: AppRoutes,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly jobService: JobService,
    protected readonly jobApi: JobApi,
    protected readonly expertService: ExpertService,
    protected readonly commonService: CommonService,
    protected readonly cdr: ChangeDetectorRef,
    private readonly extraJobApi: ExtraJobApi,
    private readonly businessPlanService: BusinessPlanService,
    private readonly paymentService: PaymentService,
    private readonly orderHelper: OrderHelper,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly paymentProviderAccountService: PaymentProviderAccountService,
    private readonly router: Router,
    public commonHelper: CommonHelper,
    private readonly _snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.job$ = this.jobService.activeEntity$;

    this.expertsCanAssigned$ = this.job$.pipe(
      switchMap((job) =>
        this.expertService.getByCountryOfResponsibility$(
          job.supportedCountry.country.id,
        ),
      ),
    );

    this.job$
      .pipe(
        untilDestroyed(this),
        tap((job) => {
          if (job) {
            this.jobId = job.id;

            this.setAssignedCustomers(job.customers);
            this.setAssignedExperts(job.experts);
            this.setExtraJobs(job.extraJobs);
            this.orderHelper.setCountryOfIncorporation(job.supportedCountry);

            return;
          }

          this.setAssignedCustomers([]);
          this.setAssignedExperts([]);
          this.setExtraJobs([]);
        }),
      )
      .subscribe();

    this.activeUser$
      .pipe(
        untilDestroyed(this),
        tap((user) => {
          this.canAssignExpert$ = of(
            user && user.hasPermissions(PermissionEnum.AssignExpertToJob),
          );
          this.canAddCustomers$ = of(
            user &&
              user.hasPermissions(
                PermissionEnum.AllowCustomerToParticipateInJob,
              ),
          );
        }),
      )
      .subscribe();

    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        filter((params: StripeQueryParams) => !!params.payment_intent),
        delay(5000),
        tap((params) => {
          const {
            payment_intent,
            payment_intent_client_secret,
            redirect_status,
          } = params;

          this._stripeParams$.next({
            payment_intent,
            payment_intent_client_secret,
            redirect_status,
          });
        }),
      )
      .subscribe();

    this.stripeParams$
      .pipe(
        untilDestroyed(this),
        filter((value) => !!value),
        combineLatestWith(
          this.paymentProviderAccountService
            .getPaymentProviderAccount(PaymentProviderNameEnum.Stripe)
            .pipe(
              filter(
                (stripeAccount) =>
                  stripeAccount?.status ===
                  PaymentProviderAccountStatusEnum.Active,
              ),
            ),
        ),
        switchMap(([_, stripeAccount]) =>
          loadStripe(environment.stripe.publishableKey, {
            stripeAccount: stripeAccount.providerAccountId,
          }),
        ),
        switchMap((stripe) =>
          stripe.retrievePaymentIntent(
            this._stripeParams$.value.payment_intent_client_secret,
          ),
        ),
        filter((data) => data.paymentIntent.status === 'succeeded'),
        tap(() => {
          const currentUrl = this.router.url.split('?')[0]; // Get the current route path without query
          // parameters
          const cloneQueryParams = cloneDeep(this.route.snapshot.queryParams);
          Object.keys(this._stripeParams$.value).forEach(
            (key) => (cloneQueryParams[key] = null),
          );
          const queryParams = cloneQueryParams;
          this.router.navigate([currentUrl], { queryParams, replaceUrl: true });
        }),
        catchError((e) => {
          console.error(e);
          return EMPTY;
        }),
      )
      .subscribe();

    this.route.queryParams
      .pipe(
        filter((params) => !!params.tab),
        tap((params) => {
          this._selectedIndex$.next(params.tab);
        }),
      )
      .subscribe();
  }

  public setAssignedCustomers(customers: User[]): void {
    this._assignedCustomers$.next([...customers]);
  }

  public setAssignedExperts(experts: User[]): void {
    this._assignedExperts$.next([...experts]);
  }

  public setExtraJobs(extraJobs: ExtraJob[]): void {
    this._extraJobs$.next([...extraJobs]);
  }

  public onOpenExpertDialog(job: Job): void {
    const dialog = this.openExtraJobDialog(
      'Assign Expert',
      job,
      RoleEnum.Expert,
    );

    dialog.componentInstance.assignUsers
      .pipe(
        combineLatestWith(this.job$.pipe(first())),
        switchMap(([value, job]) => {
          this.commonService.snackBarService.dismiss();

          return this.syncQuery$(
            dialog,
            this.jobApi.syncExpertsToJob$({
              id: job.id,
              userIds: value.map((item) => item.id),
            }),
          );
        }),
      )
      .subscribe();
  }

  public onOpenCustomerDialog(job: Job): void {
    const dialog = this.openExtraJobDialog(
      'Add Customer',
      job,
      RoleEnum.Customer,
    );

    dialog.componentInstance.assignUsers
      .pipe(
        combineLatestWith(this.job$.pipe(first())),
        switchMap(([value, job]) => {
          this.commonService.snackBarService.dismiss();

          return this.syncQuery$(
            dialog,
            this.jobApi.syncCustomersToJob$({
              id: job.id,
              userIds: value.map((item) => item.id),
            }),
            'Add Success!',
          );
        }),
      )
      .subscribe();
  }

  protected syncQuery$(
    dialog: MatDialogRef<AssignPeopleDialogComponent, User[]>,
    query$: Observable<Job>,
    successText: string = 'Assign Success!',
  ) {
    return query$.pipe(
      tap((job) => {
        dialog.close();

        this.jobService.dispatchUpsertEntityAction(job);

        this.commonService.snackBarService.pushSuccessAlert(successText);

        this.cdr.markForCheck();
      }),
      catchError((e) => {
        this.commonService.snackBarService.pushErrorMessage();

        return EMPTY;
      }),
    );
  }

  public onDeleteExtraJob(item: ExtraJob): void {
    this.extraJobApi
      .delete$(item.id)
      .pipe(
        tap((result) => {
          if (result) {
            this.deleteExtraJob(item.id);

            this.commonService.snackBarService.pushAlert(
              'Extra job has been deleted',
            );

            return;
          }

          this.commonService.snackBarService.pushErrorMessage();
        }),
        catchError((e) => {
          console.error(e);

          item.loading = false;

          return EMPTY;
        }),
      )
      .subscribe();
  }

  onCloseExtraJob(item: ExtraJob) {
    this.extraJobApi
      .close$(item.id)
      .pipe(
        tap((result) => {
          if (result) {
            this.updateExtraJob(result);

            this.commonService.snackBarService.pushAlert(
              'The extra job is now closed.',
            );

            return;
          }

          this.commonService.snackBarService.pushErrorMessage();
        }),
        catchError((e) => {
          console.error(e);

          item.loading = false;

          return EMPTY;
        }),
      )
      .subscribe();
  }

  onConfirmExtraJob(item: ExtraJob) {
    this.extraJobApi
      .confirm$(item.id)
      .pipe(
        tap((result) => {
          if (result) {
            this.updateExtraJob(result);

            this.commonService.snackBarService.pushAlert(
              'Extra job has been completed',
              'Well done',
              SnackBarTypeEnum.success,
            );

            return;
          }

          this.commonService.snackBarService.pushErrorMessage();
        }),
        catchError((e) => {
          console.error(e);
          item.loading = false;

          return EMPTY;
        }),
      )
      .subscribe();
  }

  public onUpdateOrCreateExtraJob($event: ExtraJobsUpdateOrCreateEvent): void {
    const [componentInstance, input] = $event;
    const { commonService } = componentInstance;
    const { snackBarService, errorService } = commonService;

    snackBarService.dismiss();

    commonService.setLoading(true);

    this.extraJobApi
      .updateOrCreate$(input)
      .pipe(
        tap((result) => {
          commonService.setLoading(false);

          if (result) {
            if (input.id) {
              snackBarService.pushUpdateSuccessMessage();

              this.updateExtraJob(result);
            } else {
              snackBarService.pushAlert(
                'Extra job created successfully!',
                null,
                SnackBarTypeEnum.success,
              );

              this.setExtraJobs([result, ...this._extraJobs$.value]);
            }

            componentInstance.onCloseDialog();

            return;
          }

          snackBarService.pushErrorMessage();

          componentInstance.onCloseDialog();
        }),
        catchError(({ graphQLErrors }) => {
          commonService.setLoading(false);

          errorService
            .getValidationErrorObjectList(graphQLErrors)
            .setScrollEl(componentInstance.scrollEl)
            .showErrors(componentInstance.form);

          return EMPTY;
        }),
      )
      .subscribe();
  }

  public openPaymentReceipt(payment: Payment): void {
    this.paymentService.openPaymentReceipt(payment)
  }

  protected openExtraJobDialog(
    title: string,
    job: Job,
    role: RoleEnum,
  ): MatDialogRef<AssignPeopleDialogComponent, User[]> {
    return this.dialog.open<
      AssignPeopleDialogComponent,
      AssignPeopleDialogComponentData,
      User[]
    >(AssignPeopleDialogComponent, {
      width: AssignPeopleDialogComponent.width,
      autoFocus: false,
      data: {
        title,
        job,
        role,
      },
    });
  }

  public renderUnpaidMessages(activeUser: User): string {
    if (activeUser.isSuperAdmin() || activeUser.isAdmin()) {
      return 'Payment awaited. Once payment is received, you can assign expert to working on the request';
    }
    if (activeUser.isExpert()) {
      return 'Payment awaited. Once payment is received, you can begin working on the request';
    }
    if (activeUser.isCustomer()) {
      return 'Payment awaited. Once payment is received, our experts will promptly begin working on your request';
    }
    return null;
  }

  public getEstimatedTime(job: Job): number {
    if (job.estimatedTime) {
      return (this.estimatedTime = Duration.fromISO(job.estimatedTime).as(
        'days',
      ));
    }

    const { service, detailObject } = job;
    return (this.estimatedTime = detailObject.jobServiceOrder.mainServices
      .find((item) => item.service.service.id === service.id)
      .service.getEstimatedTime());
  }

  public getJobDeadline(job: Job) {
    const { createdAt } = job;
    return moment(createdAt).add(this.estimatedTime, 'days').toDate();
  }

  public onEditEstimatedDate(job: Job) {
    const dialog = this.dialog.open(EditEstimatedDateComponent, {
      autoFocus: false,
      width: '40vw',
      data: {
        job,
        estimatedTime: this.estimatedTime,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this._snackBarService.pushUpdateSuccessMessage();

        this.refetchJob();
      }
    });
  }

  public canCloseJob(job: Job): boolean {
    return (
      job.status !== JobStatusEnum.Closed &&
      job.status !== JobStatusEnum.Draft &&
      job.status !== JobStatusEnum.Done
    );
  }

  private updateExtraJob(result: ExtraJob): void {
    const extraJobs = this._extraJobs$.value;

    let extraJob = extraJobs.find((job) => job.id === result.id);

    if (extraJob) {
      Object.assign(extraJob, result);

      extraJob.loading = false;
    }

    this.setExtraJobs(extraJobs);
  }

  private deleteExtraJob(id: ExtraJob['id']): void {
    const extraJobs = this._extraJobs$.value;

    let index = extraJobs.findIndex((job) => job.id === id);

    if (index >= 0) {
      extraJobs.splice(index, 1);
    }

    this.setExtraJobs(extraJobs);
  }

  cancelJobConfirmed(job: Job): void {
    this.jobService.cancelJob(job.id).subscribe((result) => {
      if (result) {
        this.refetchJob();
      }
    });
  }

  private refetchJob() {
    this.jobApi
      .find$(this.jobId)
      .pipe(
        untilDestroyed(this),
        first(),
        tap((value) => {
          this.jobService.dispatchAddOrUpdateThenSelectEntityAction(value);
        }),
      )
      .subscribe();
  }

  getPaymentStatusLabel(job: Job): string {
    if (job.parentId) {
      return this.generatePaymentStatusLabel(job.parent);
    }

    return this.generatePaymentStatusLabel(job);
  }

  private generatePaymentStatusLabel(job: Job): string {
    if (!job.order) {
      return 'Unavailable';
    }

    if (job.order?.status === OrderStatusEnum.Paid) {
      return 'Paid';
    }

    if (job.status === JobStatusEnum.Closed && !job.order?.payment) {
      return 'Payment Cancelled';
    }
    if (job.status === JobStatusEnum.Draft) {
      return 'Unavailable';
    }

    return job.order?.payment &&
      job.order?.payment?.status !== PaymentStatusEnum.AttemptingPayment
      ? job.order?.payment?.renderStatusLabel()
      : job.order?.paymentStatusAndStyleIfUserDidNotMakePayment.status;
  }

  getPaymentStatusStyle(job: Job): string {
    if (job.parent) {
      return this.generatePaymentStatusStyle(job.parent);
    }

    return this.generatePaymentStatusStyle(job);
  }

  private generatePaymentStatusStyle(job: Job): string {
    if (job.order?.status === OrderStatusEnum.Paid) {
      return 'success';
    }

    if (
      job.status === JobStatusEnum.Closed ||
      job.status === JobStatusEnum.Draft
    ) {
      return 'default';
    }

    return job.order?.payment
      ? job.order?.payment?.renderStatusStyle()
      : job.order?.paymentStatusAndStyleIfUserDidNotMakePayment.statusStyle;
  }

  markJobDone(job: Job): void {
    const input: UpdateJobInput = {
      status: JobStatusEnum.Done,
    };

    this.jobService.updateJob(job.id, input).subscribe((result) => {
      if (result) {
        this.refetchJob();
      }
    });
  }
}
