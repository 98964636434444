<ngx-dropzone class="dropzone-upload__container"
              (change)="onSelect($event)"
              [multiple]="multiple"
              [accept]="accept"
              [maxFileSize]="maxFileSize">
  <ngx-dropzone-label class="dropzone-upload__label">
    {{ label }}<br/>
    <span class="text-sm">(Max size: {{ maxFileSize | filesize }})</span>
  </ngx-dropzone-label>

  <ng-container *ngIf="!imagePreview">
    <ngx-dropzone-preview *ngFor="let f of files"
                          [removable]="true"
                          (removed)="onRemove(f)">
      <ngx-dropzone-label class="!text-sub line-clamp-3">
        {{ f.name }}
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ng-container>

  <ng-container *ngIf="imagePreview">
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                class="dropzone-upload__preview-image"
                                *ngFor="let f of files"
                                [file]="f"
                                [removable]="true"
                                (removed)="onRemove(f)">
      <ngx-dropzone-label class="dropzone-upload__preview-image__label line-clamp-2">{{ f.name }}
      </ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </ng-container>
</ngx-dropzone>
<div *ngIf="errors.length"
     class="my-1">
  <ul class="list-none list-inside"
      *ngFor="let error of errors">
    <li class="text-sm text-red-500 pl-2">{{ error }}</li>
  </ul>
</div>
