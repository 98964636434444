import { AbstractControl, ValidationErrors } from '@angular/forms';

export const uniqueCompanyNameValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const name = control.get('name').value;
  const secondNameControl = control.get('secondName');
  const thirdNameControl = control.get('thirdName');
  let error = null;

  if (name) {
    const secondName = secondNameControl.value || '';
    const thirdName = thirdNameControl.value || '';

    if (secondName && name.trim() === secondName.trim()) {
      error = { conflictName: true };
      secondNameControl.setErrors(error);
    }

    if (thirdName && name.trim() === thirdName.trim()) {
      error = { conflictName: true };
      thirdNameControl.setErrors(error);
    }

    if (secondName && thirdName && secondName.trim() === thirdName.trim()) {
      error = { conflictName: true };

      secondNameControl.setErrors({ conflictWithThirdName: true });
      thirdNameControl.setErrors({ conflictWithSecondName: true });
    } else {
      if (secondNameControl.hasError('conflictWithThirdName')) {
        secondNameControl.setErrors(null);
      }
      if (thirdNameControl.hasError('conflictWithSecondName')) {
        thirdNameControl.setErrors(null);
      }
    }
  }

  return error;
};
