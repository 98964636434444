import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CompanyShareholder } from '@app/modules/company/models/company-shareholder.model';
import {
  CountryType,
  SharesTransferDetailInput,
  ShareTypeType,
} from '@generated/graphql';
import { uniq, uniqBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { ShareTransferDetailFormType } from './share-transfer-detail.form';

type FormType = {
  details: FormArray<FormGroup<ShareTransferDetailFormType>>;
};

export class ShareTransferDetailManagement {
  public userType: 'customer' | 'expert' = 'customer';

  public fb = new FormBuilder();

  public form: FormGroup<FormType>;

  public shareTypes: ShareTypeType[];

  public shareTypes$ = new BehaviorSubject<ShareTypeType[]>([]);

  public currencies: string[];

  public maximumNumberOfShares: number;

  private _shareholder: CompanyShareholder;
  public get shareholder() {
    return this._shareholder;
  }
  public set shareholder(val) {
    this._shareholder = val;

    this.afterSetShareholder();
  }

  private _countries: CountryType[];
  public set countries(val: CountryType[]) {
    this._countries = val;

    this.currencies = this._countries.map((c) => c.currency);
  }

  public get details() {
    return this.form.controls.details;
  }

  public constructor() { }

  public createForm(): this {
    this.form = null;

    this.form = this.fb.group<FormType>({
      details: this.fb.array<FormGroup<ShareTransferDetailFormType>>([]),
    });

    this.addFormItem();

    return this;
  }

  public addFormItem(): this {
    if (this.userType === 'expert') {
      this.form.controls.details.push(
        this.fb.group<ShareTransferDetailFormType>({
          transferNumber: new FormControl(null, [Validators.required]),
          shareCertNumber: new FormControl(null, [Validators.required]),
          ...this.createRequiredFormField(),
        }),
      );
    } else {
      this.form.controls.details.push(
        this.fb.group<ShareTransferDetailFormType>({
          ...this.createRequiredFormField(),
        }),
      );
    }

    return this;
  }

  private createRequiredFormField() {
    return {
      shareTypeId: new FormControl(null, [Validators.required]),
      currency: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      numberOfShares: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.min(1),
      ]),
      paidAmount: new FormControl(null, [
        Validators.required,
        Validators.min(1),
      ]),
      paidAmountCurrency: new FormControl(null, [Validators.required]),
    };
  }

  public removeFormItem(index: number): this {
    this.form.controls.details.removeAt(index);
    return this;
  }

  private afterSetShareholder() {
    const shareTypes = uniqBy(
      this._shareholder.shareholdingDetails.map((sd) => sd.shareType),
      'id',
    );

    this.shareTypes = shareTypes ?? undefined;

    this.shareTypes$.next(this.shareTypes);

    const currencies = uniq(
      this._shareholder.shareholdingDetails.map((sd) => sd.currency),
    );

    this.currencies = currencies ?? undefined;
  }

  public shareTypeChangeHandler(index: number) {
    const rowControl = this.form.controls.details.at(index);

    rowControl.controls.currency.enable();
  }

  public currencyChangeHandler(index: number) {
    const rowControl = this.form.controls.details.at(index);

    rowControl.controls.numberOfShares.enable();

    rowControl.controls.numberOfShares.addValidators(
      Validators.max(this.getMaxNumberOfShares(index)),
    );
  }

  public getCurrencyForShareType(index: number): string[] {
    const rowControl = this.form.controls.details.at(index);

    if (this.shareholder) {
      const existsCurrencies = this.form.controls.details.controls
        .filter(
          (c, i) =>
            c.controls.shareTypeId.value ===
            rowControl.controls.shareTypeId.value && i !== index,
        )
        .map((c) => c.controls.currency.value);

      const currencies = this.shareholder.shareholdingDetails
        .filter(
          (d) =>
            d.shareType.id === rowControl.controls.shareTypeId.value &&
            !existsCurrencies.includes(d.currency),
        )
        .map((d) => d.currency);

      return currencies;
    }

    return [];
  }

  public getMaxNumberOfShares(index: number): number {
    const rowControl = this.form.controls.details.at(index);

    const shareTypeId = rowControl.controls.shareTypeId.getRawValue();

    const currency = rowControl.controls.currency.getRawValue();

    let total = 0;

    if (rowControl.controls.currency.enabled && this.shareholder) {
      const shareholdingDetails = this.shareholder.shareholdingDetails.filter(
        (d) => d.shareType.id === shareTypeId,
      );

      shareholdingDetails.forEach((sd) => {
        if (sd.currency === currency) {
          total += sd.numberOfShares;
        }
      });
    }

    return total;
  }

  public setDataFromInput(details: SharesTransferDetailInput[]) {
    this.form.controls.details.clear();

    details.forEach((d, i) => {
      this.addFormItem();
      const control = this.form.controls.details.at(i);

      control.patchValue({
        transferNumber: d.transferNumber,
        shareCertNumber: d.shareCertNumber,
        shareTypeId: d.shareTypeId,
        numberOfShares: d.numberOfShares,
        currency: d.currency,
        paidAmount: d.paidAmount,
        paidAmountCurrency: d.paidAmountCurrency,
      });

      control.controls.currency.enable();
      control.controls.numberOfShares.enable();

      control.controls.numberOfShares.addValidators(
        Validators.max(this.getMaxNumberOfShares(i)),
      );

      control.controls.numberOfShares.updateValueAndValidity();
    });
  }
}
