import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisteredAddressFormType } from '@app/modules/job/models/form/company-detail-form-type';
import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { PaymentTypeEnum, ServiceEnum } from '@generated/graphql';
import { JobOrderServiceManagement } from './job-order-service-management.class';

export class RegisteredAddressForm {
  private _fb: FormBuilder = new FormBuilder();

  private _form: FormGroup<RegisteredAddressFormType>;
  get form(): FormGroup<RegisteredAddressFormType> {
    return this._form;
  }

  private _countryOfIncorporation: SupportedCountry;
  set countryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;
  }

  private _useTenantAddress = false;
  get useTenantAddress() {
    return this._useTenantAddress;
  }

  constructor(
    countryOfIncorporation: SupportedCountry,
    private _orderServiceManagement: JobOrderServiceManagement,
  ) {
    this._countryOfIncorporation = countryOfIncorporation;
  }

  createForm() {
    this._form = this._fb.group<RegisteredAddressFormType>({
      address: this._fb.control('', [Validators.required]),
      apartment: this._fb.control(''),
      city: this._fb.control(''),
      state: this._fb.control(''),
      zip: this._fb.control(''),
      fromDate: this._fb.control(new Date()),
      countryId: this._fb.control(
        { value: this._countryOfIncorporation.country.id, disabled: true },
        [Validators.required],
      ),
      rentalAgreementDocument: this._fb.control(null),
    });
  }

  fillTenantAddress(data: ServiceSupportedCountry): void {
    this.form.patchValue({
      address: data.address.address,
      apartment: data.address.apartment,
      city: data.address.city,
      state: data.address.state,
      zip: data.address.zip,
      rentalAgreementDocument: null,
    });

    this.form.disable();
  }

  setCountryId(countryId: string): void {
    this.form.controls.countryId.setValue(countryId);
  }

  setRentalAgreementFile(file: File | null): this {
    this.form.controls.rentalAgreementDocument.setValue(file);

    return this;
  }

  useTenantAddressChanged(val: boolean): this {
    this._useTenantAddress = val;

    if (this._useTenantAddress) {
      const info =
        this._orderServiceManagement.incorporationServices.find(
          (s) => s.service.name === ServiceEnum.RegisteredAddress,
        ) || null;

      if (info) {
        this.fillTenantAddress(info);

        this._orderServiceManagement.toggleRegisteredAddressService('add');
      }
    } else {
      this.resetForm();

      this._orderServiceManagement.toggleRegisteredAddressService('remove');
    }

    return this;
  }

  resetForm() {
    this.form.enable();
    this.form.reset({
      fromDate: new Date(),
      countryId: this._countryOfIncorporation.country.id,
    });
    this.form.controls.countryId.disable();
  }

  getServiceFee(): number {
    const service = this._orderServiceManagement.incorporationServices.find(
      (i) => i.service.name === ServiceEnum.RegisteredAddress,
    );
    return service ? service.serviceFee : 0;
  }

  isContactPayment(): boolean {
    const service = this._orderServiceManagement.incorporationServices.find(
      (i) => i.service.name === ServiceEnum.RegisteredAddress,
    );

    return service.paymentType.name === PaymentTypeEnum.ContactToClient;
  }

  isHaveRegisteredAddressInfo(): boolean {
    const service = this._orderServiceManagement.incorporationServices.find(
      (i) => i.service.name === ServiceEnum.RegisteredAddress,
    );
    return !!service?.address;
  }
}
