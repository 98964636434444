import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { createActionGroup, props } from '@ngrx/store';

export const CreateJobActions = createActionGroup({
  source: 'Create Job',
  events: {
    'Set Operation Country': props<{
      countryOfIncorporation: SupportedCountry;
    }>(),
  },
});
