import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '@shared/helpers/file.helpers';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number, decimals = 2) {
    return formatBytes(bytes, decimals);
  }
}
