import { Component, OnInit } from '@angular/core';
import { OrderSummaryService } from '@shared/component/payments/components/order-summary/order-summary.service';
import { Observable, tap } from 'rxjs';
import { OrderSummary } from '@shared/component/payments/components/order-summary/order-summary.type';
import { JobService } from '@modules/job/services/job.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderHelper } from '@shared/helpers/order.helpers';
import { Job } from '@app/modules/job/models/job.model';
import { CommonHelper } from '@shared/helpers/common.helper';
import { BusinessPlanService } from '@app/modules/account-settings/pages/business-profile/services/business-plan.service';

@UntilDestroy()
@Component({
  selector: 'app-job-scope-and-fee',
  templateUrl: './scope-and-fee.component.html',
  styleUrls: ['./scope-and-fee.component.scss'],
})
export class ScopeAndFeeComponent implements OnInit {
  job$: Observable<Job>;

  get orderSummary$(): Observable<OrderSummary> {
    return this.orderSummaryService.orderSummary$;
  }

  public get businessPlan$() {
    return this._businessPlanService.businessPlan$;
  }

  constructor(
    private readonly _businessPlanService: BusinessPlanService,
    private readonly jobService: JobService,
    private readonly orderHelper: OrderHelper,
    private readonly orderSummaryService: OrderSummaryService,
    protected readonly commonHelper: CommonHelper,
  ) { }

  ngOnInit() {
    this.job$ = this.jobService.activeEntity$;

    this.job$
      .pipe(
        untilDestroyed(this),
        tap((job) => {
          this.orderHelper.setupByJob(job);
          this.orderSummaryService.setData(this.orderHelper);
        }),
      )
      .subscribe();
  }
}
