import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { CountryApi } from '@modules/country/api/country.api';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { CountryService } from "@modules/country/services/country.service";

@Injectable()
export class CountryInitializerEffects {
  initCountries$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => this.countryApi.get()),
    map(entities => {
      return this.countryService.countryStore.setEntitiesAction({entities});
    }),
    catchError(e => {
      console.error(e);

      return of(this.countryService.countryStore.clearEntitiesAction());
    }),
  ));

  constructor (
    private actions$: Actions,
    private countryApi: CountryApi,
    protected readonly countryService: CountryService
  ) {
  }
}
