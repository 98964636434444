import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {login, loginError, loginSuccess} from '@modules/auth/store/actions/login.actions';
import {catchError, combineLatestWith, first, map, of, switchMap, tap} from 'rxjs';
import {AuthApi} from '@modules/auth/api/auth.api';
import {Router, UrlTree} from '@angular/router';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {AuthService} from '@modules/auth/services/auth.service';
import {authUserActions} from '@modules/auth/store/actions/auth-user.actions';
import {ErrorService} from '@shared/services/error.service';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {LoginVariables} from '@generated/graphql';
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";
import {isTrustedExternalUrl} from "@utils/url";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";

@Injectable()
export class LoginEffects {
  onLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login.type),
      switchMap(({input}: ReturnType<typeof login>) =>
        this.authApi.login(input)
          .pipe(
            map(user => loginSuccess({user})),
            combineLatestWith(this.businessProfileService.activeEntity$.pipe(first())),
            map(([_, business]) => {
              this.snackBarService.pushAlert(`Welcome to ${business ? business.name : ''}!`)

              return _
            }),
            catchError(({graphQLErrors}) => {
              const mustVerifyEmailErrorKey = 'mustVerifyEmail';

              const loginErrorMessages = this.errorService.getValidationErrorMessages<LoginVariables & {
                [mustVerifyEmailErrorKey]: string[]
              }>(graphQLErrors, [mustVerifyEmailErrorKey]);

              const mustVerifyEmail = this.errorService.hasValidationErrorKey<LoginVariables & {
                [mustVerifyEmailErrorKey]: string[]
              }>(graphQLErrors, mustVerifyEmailErrorKey);

              return of(loginError({loginErrorMessages, mustVerifyEmail}));
            }),
          ),
      ))
  });

  onLoginSuccess$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loginSuccess.type),
        tap(async (action: ReturnType<typeof loginSuccess>) => {
          if (action.user) {
            const dashboardUrlTree= this.router.createUrlTree(this.appRoutes.dashboard())

            let url: string | UrlTree = dashboardUrlTree

            if (this.authService.returnUrl) {
              url = this.authService.returnUrl;

              this.authService.returnUrl = null;
            }

            if (typeof url === 'string' && isTrustedExternalUrl(url)) {
              this.screenSpinnerService.loading()

              return window.location.replace(url)
            } else if (url instanceof UrlTree) {
              return await this.router.navigateByUrl(url);
            } else {
              return await this.router.navigateByUrl(dashboardUrlTree);
            }
          }
        }),
        map(({user}: ReturnType<typeof loginSuccess>) => authUserActions.setAuthUser({user: user})),
      )
    },
  );

  onLoginError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginError.type),
      tap((err: ReturnType<typeof loginError>) => {

      }),
    )
  }, {
    dispatch: false,
  });

  constructor(
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private businessProfileService: BusinessProfileService,
    private snackBarService: SnackBarService,
    private errorService: ErrorService,
    private actions$: Actions,
    private authApi: AuthApi,
    private router: Router,
    private authService: AuthService,
    private screenSpinnerService: ScreenSpinnerService
  ) {
  }
}


