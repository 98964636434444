import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { Observable, of } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";

@Component({
  selector: 'app-bank-account[value]',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountComponent implements OnInit {
  @Input() value: WireTransferBank

  @Input() visibleActions$: Observable<boolean> = of(false)

  @Input() visibleDeleteAction$: Observable<boolean> = of(false)

  @Input() visibleUpdateAction$: Observable<boolean> = of(false)

  @Input() loading$: Observable<boolean> = of(false)

  @Output() update = new EventEmitter<WireTransferBank>()

  @Output() delete = new EventEmitter<WireTransferBank>()

  constructor (
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>) {
  }

  ngOnInit (): void {
  }

  onDelete (): void {
    this.delete.emit(this.value)
  }

  onUpdate (): void {
    this.update.emit(this.value)
  }
}
