import { BaseModel } from '@shared/models/base.model';
import { OfficerCompanyType } from '@generated/graphql';
import { Country } from '@modules/country/models/country.model';
import { Officer } from '@modules/officer/models/officer.model';
import { Address } from '@modules/address/models/address.model';
import { addressFactory } from '@modules/address/factories/address.factory';

export class OfficerCompany extends BaseModel implements OfficerCompanyType {
  country: Country;
  address?: Address;
  id: string;
  name: string;
  email?: string;
  registrationNumber: string;
  registrationDate: Date;
  representative: Officer;
  website?: string;
  __typename: 'OfficerCompanyType';

  constructor(state: OfficerCompanyType) {
    super(state);

    this.address = addressFactory.tryCreating(state.address);
  }

  getModelName(): string {
    return OfficerCompany.name;
  }
}
