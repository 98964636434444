import { ModelFactory } from '@shared/factories/model.factory';
import { Pagination, PaginationData } from '@shared/types/pagination.type';


export class PaginationDataFactory {
  create<BE, FE> (state: Pagination<BE>, factory: ModelFactory<BE, FE>): PaginationData<FE> {
    const data = state?.data?.length ? state?.data.map(item => factory.create(item)) : [];

    return new PaginationData<FE>({
      ...state,
      data,
    });
  }
}

export const paginationDataFactory = new PaginationDataFactory();
