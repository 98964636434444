import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedComponentModule } from '@shared/component/component.module';
import { MatIconModule } from '@angular/material/icon';
import { ACTIVE_USER } from '@modules/auth/providers/auth.provider';
import { Observable, of, tap } from 'rxjs';
import { User } from '@modules/user/models/user.model';
import { Store } from '@ngrx/store';
import { LetModule } from '@ngrx/component';
import { countryStore } from '@modules/country/store/country.store';
import { ShareTypeService } from '@modules/share-type/services/share-type.service';
import { ShareTypeType } from '@generated/graphql';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonHelper } from '@shared/helpers/common.helper';
import { ShareTransferDetailManagement } from '@shared/classes/shareholder/share-transfer-detail-management.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelectChange } from '@angular/material/select';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@UntilDestroy()
@Component({
  selector: 'app-shares-allocation',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    SharedComponentModule,
    MatIconModule,
    LetModule,
    MatTooltipModule,
  ],
  templateUrl: './shares-allocation.component.html',
  styleUrls: ['./shares-allocation.component.scss'],
})
export class SharesAllocationComponent implements OnInit {
  @Input() public shareTransferDetailManagement: ShareTransferDetailManagement;

  public listCurrencies$: Observable<string[]>;

  public fullCurrencies$: Observable<string[]>;

  public shareTypes$: Observable<ShareTypeType[]>;

  public shareTypes: ShareTypeType[];

  protected displayedColumns: string[] = [
    'allotmentNumber',
    'certificateNumber',
    'shareType',
    'currency',
    'numberOfShares',
    'amountPaidAmount',
    'amountPaidCurrency',
    'actions',
  ];

  public dataSource: MatTableDataSource<any>;

  private _addNewButton: boolean = true;
  @Input()
  public set addNewButton(val: BooleanInput) {
    this._addNewButton = coerceBooleanProperty(val);
  }
  public get addNewButton() {
    return this._addNewButton;
  }

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    private store: Store,
    private shareTypeService: ShareTypeService,
    protected readonly commonHelper: CommonHelper,
  ) { }

  ngOnInit(): void {
    this.initData();

    this.activeUser$.subscribe((user) => {
      const defaultDisplayedColumns: string[] = [
        'shareType',
        'currency',
        'numberOfShares',
        'amountPaidAmount',
        'amountPaidCurrency',
        'actions',
      ];

      if (user.isCustomer()) {
        this.displayedColumns = defaultDisplayedColumns;
      } else {
        this.displayedColumns = [
          ...['allotmentNumber', 'certificateNumber'],
          ...defaultDisplayedColumns,
        ];
      }
    });
  }

  public removeRow(rowIndex: number) {
    this.shareTransferDetailManagement.removeFormItem(rowIndex);

    this.setTableDataSource();
  }

  public isAllowRemove() {
    return (
      this.shareTransferDetailManagement.form.controls.details.length === 1
    );
  }

  public addNewRow() {
    this.shareTransferDetailManagement.addFormItem();

    this.setTableDataSource();
  }

  public onShareTypeChanged(event: MatSelectChange, index: number) {
    this.shareTransferDetailManagement.shareTypeChangeHandler(index);
  }

  public onCurrencyChanged(event: MatSelectChange, index: number) {
    this.shareTransferDetailManagement.currencyChangeHandler(index);
  }

  public getShareTypeName(id: string): string {
    const shareType = this.shareTypes.find((t) => t.id === id);

    if (!shareType) {
      return '';
    }

    return shareType.name;
  }

  private initData() {
    this.listCurrencies$ = this.store
      .select(countryStore.selectReserveCurrencies)
      .pipe(
        untilDestroyed(this),
        tap((currency) => [...new Set(currency)]),
      );

    this.fullCurrencies$ = this.listCurrencies$;

    if (this.shareTransferDetailManagement.currencies) {
      this.listCurrencies$ = of(this.shareTransferDetailManagement.currencies);
    }

    this.shareTransferDetailManagement.shareTypes$.subscribe(
      (types) => (this.shareTypes = types),
    );

    // this.shareTypeService
    //   .getAll()
    //   .pipe(untilDestroyed(this))
    //   .subscribe((shareTypes) => {
    //     this.shareTypes = shareTypes;
    //   });

    this.setTableDataSource();
  }

  private setTableDataSource(): void {
    this.dataSource = new MatTableDataSource(
      this.shareTransferDetailManagement.form.controls.details.controls,
    );
  }
}
