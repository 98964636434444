import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  Operation,
} from '@apollo/client/core';
import { environment } from '@environment/environment';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ErrorService } from '@shared/services/error.service';

export const APOLLO_CONFIG = {
  provide: APOLLO_OPTIONS,
  useFactory: (
    httpBatchLink: HttpBatchLink,
    errorService: ErrorService
  ): ApolloClientOptions<any> => {
    const httpLink = httpBatchLink.create({
      uri: environment.apiUrl,
      includeExtensions: true,
      withCredentials: true,
    });

    const uploadLink = createUploadLink({
      uri: environment.apiUrl,
      includeExtensions: true,
      credentials: 'include',
    });

    const errorLink = onError(
      ({ networkError, graphQLErrors, operation, forward }) => {
        if (graphQLErrors) {
          errorService.handleGraphqlErrors(graphQLErrors);
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }
      }
    );

    const link = ApolloLink.from([
      errorLink.concat(ApolloLink.split(hasFile, uploadLink, httpLink)),
    ]);

    return {
      cache: new InMemoryCache(),
      link,
    };
  },
  deps: [HttpBatchLink, ErrorService],
};

const hasFile = (operation: Operation): boolean => {
  const { useMultipart } = operation.getContext();

  return useMultipart || false;
};
