import {NgModule} from "@angular/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule, NativeDateAdapter,
} from "@angular/material/core";
import {DateTime} from "luxon";

const locale = 'en-SG'

export const APP_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    const dateParts = typeof value === 'string' ? value.split('/') : null;

    if (dateParts && dateParts.length === 3) {
      const day = +dateParts[0];
      const month = +dateParts[1] - 1;
      const year = +dateParts[2];
      return new Date(year, month, day);
    }

    return super.parse(value);
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy')
    }

    return super.format(date, displayFormat);
  }
}

@NgModule({
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: locale},
    {provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: APP_MAT_DATE_FORMATS},
  ]
})
export class MatDatepickerConfig {
}
