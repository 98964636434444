import { Injectable } from '@angular/core';
import { CompanyShareholderService } from '@app/modules/company-shareholder/services/company-shareholder.service';
import { CompanyShareholder } from '@app/modules/company/models/company-shareholder.model';
import { JobService } from '@app/modules/job/services/job.service';
import { ServiceSupportedCountry } from '@app/modules/service/models/service-supported-country.model';
import {
  CompanyShareholderInput,
  CompanyShareholderJobInput,
  CreateCorporateJobInput,
  JobMatterEnum,
  OfficerCompanyInput,
  OfficerDocumentStatusEnum,
  OfficerPersonInput,
  OfficerType,
  OfficerTypeEnum,
  ServiceEnum,
} from '@generated/graphql';
import { OfficerFormFactory } from '@shared/classes/officer/officer-form-factory';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { startCase } from 'lodash-es';
import { DateTime } from 'luxon';
import { OfficerRequestFormManagement } from './officer-request-form-management';

@Injectable({
  providedIn: 'any',
})
export class ShareholderOfficerRequestForm extends OfficerRequestFormManagement {
  public editingShareholder: CompanyShareholder;

  constructor(
    private _shareholderService: CompanyShareholderService,
    private _officerInputHelper: OfficerInputHelpers,
    private _jobService: JobService,
  ) {
    super();
  }

  public createForm() {
    if (this.typeSelected === OfficerTypeEnum.Nominee) {
      this.formInput = OfficerFormFactory.createForm(
        OfficerTypeEnum.Individual,
      );

      this.formInput.createOfficerForm();

      // this.generateRequireDocuments();

      setTimeout(() => {
        this.formInput.officerForm.disable();
      }, 256);
    } else {
      super.createForm();

      setTimeout(() => {
        this.formInput.officerForm.enable();
      }, 256);
    }
  }

  public getFormValue(): CompanyShareholderInput {
    let keyName = 'officerPerson';

    let officerType = OfficerTypeEnum.Individual.toString();

    let formRawValue = this.formInput.officerForm.getRawValue();

    let officerRawValue = null;

    if ('identification' in formRawValue) {
      officerRawValue = this._officerInputHelper.transformOfficerInput(
        formRawValue,
      ) as OfficerPersonInput;
    } else if ('representative' in formRawValue) {
      officerRawValue = this._officerInputHelper.transformOfficerInput(
        formRawValue,
      ) as OfficerCompanyInput;

      keyName = 'officerCompany';
      officerType = OfficerTypeEnum.Corporate.toString();
    }

    const input: CompanyShareholderInput = {
      isNominee: false,
      officerType,
      [keyName]: officerRawValue,
    };

    return input;
  }

  public filterAndSortExistOfficers(): this {
    this.existsOfficerManagement.filterAndSortExistOfficers();

    return this;
  }

  protected setExistsOfficerToForm(officer: OfficerType) {
    if ('identification' in officer.officerProfile) {
      const input =
        this._officerInputHelper.transformOfficerPersonFromTypeToInput(
          officer.officerProfile,
        );

      this.formInput.setEditData(input, undefined);
    } else if ('representative' in officer.officerProfile) {
      const input =
        this._officerInputHelper.transformOfficerCompanyFromTypeToInput(
          officer.officerProfile,
        );

      this.formInput.setEditData(input, undefined);
    }

    if (this.mode === 'Replace' && this.existsOfficerManagement.refId) {
      this.checkNeedDocument(officer);
    }
  }

  public setEditData(officer: CompanyShareholder): this {
    this.editingShareholder = officer;

    const officerProfile = officer.officer.officerProfile;

    if ('identification' in officerProfile) {
      // INFO: officer person
      this.setOfficerPersonOfficerData(officerProfile);
    } else if ('representative' in officerProfile) {
      // INFO: officer company
      this.setOfficerCompanyOfficerData(officerProfile);
    }

    return this;
  }

  private checkNeedDocument(officer: OfficerType) {
    officer.officerDocuments.forEach((d) => {
      if (!(d.status === OfficerDocumentStatusEnum.Verified)) {
        this.needDocument = true;

        return;
      } else {
        this.needDocument = false;
      }
    });
  }

  private updateShareholder() {
    const shareholderInput: CompanyShareholderJobInput = {
      shareholder: {
        id: this.editingShareholder.id,
        ...this.getFormValue(),
        effectiveDate: DateTime.fromJSDate(
          this.effectiveDate.value,
        ).toISODate(),
      },
    };

    shareholderInput.documents = this.uploadedDocuments;

    if (!this.activeUser.isCustomer()) {
      // INFO: FOR EXPERT
      this.postStatus$.next('SUBMITTING');

      this._shareholderService
        .expertUpdateShareholder(shareholderInput)
        .subscribe({
          next: (result) => this.postStatus$.next('SUCCESS'),
          error: (error) => {
            this.postStatus$.next('ERROR');
            return error;
          },
          complete: () => this.postStatus$.next('FINISHED'),
        });
    } else {
      // INFO: FOR CUSTOMER

      const serviceName = ServiceEnum.EditShareholder;

      this.postStatus$.next('SUBMITTING');

      this.jobServiceOrder.addOrUpdateService(serviceName, 'mainServices');

      const jobInput: CreateCorporateJobInput = {
        serviceName,
        jobInput: {
          matter: startCase(JobMatterEnum.EditShareholder),
          companyId: this.company.id,
          serviceSupportedCountryId: this.serviceSupportedCountry.id,
        },
        shareholderInput,
      };

      const jobDetail = {
        ...jobInput,
        jobServiceOrder: this.jobServiceOrder.jobServicesOrder,
        oldShareholder: this.editingShareholder,
      };

      jobInput.jobInput.detail = JSON.stringify(jobDetail);

      this._jobService.createCorporateJob(jobInput).subscribe({
        next: (job) => {
          this.createdJob = job;

          this.postStatus$.next('SUCCESS');
        },
        error: (error) => {
          this.postStatus$.next('ERROR');

          return error;
        },
        complete: () => {
          this.postStatus$.next('FINISHED');
        },
      });
    }
  }

  public submitForm() {
    if (this.mode === 'Edit') {
      this.updateShareholder();
    }
  }

  public setServiceSupportedCountry(services: ServiceSupportedCountry[]): this {
    let serviceName = ServiceEnum.EditShareholder;

    // if (this.mode === 'Edit') {
    //   serviceName = ServiceEnum.EditShareholder;
    // }

    this.serviceSupportedCountry = services.find(
      (s) =>
        s.supportedCountry.id === this.countryOfIncorporation.id &&
        s.service.name === serviceName,
    );

    return this;
  }
}
