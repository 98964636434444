export enum OfficerRoleEnum {
  SHAREHOLDER = 'SHAREHOLDER',
  DIRECTOR = 'DIRECTOR',
  SECRETARY = 'SECRETARY',
  AUDITOR = 'AUDITOR'
}

export enum OfficerResolutionActionEnum {
  EditInfo = 'EDIT_INFO',
  Replace = 'REPLACE',
  Remove = 'REMOVE',
  UploadKycDocument = 'UPLOAD_KYC_DOCUMENT',
  DistributeShare = 'DISTRIBUTE_SHARE'
}
