import {NgModule} from '@angular/core';
import {AuthCardComponent} from '@shared/component/auth-card/auth-card.component';
import {AvatarComponent} from '@shared/component/common/avatar/avatar.component';
import {
  MultiCountrySelectInputComponent
} from '@shared/component/common/multi-country-select-input/multi-country-select-input.component';
import {CreditCardComponent} from '@shared/component/payments/components/credit-card/credit-card.component';
import {ContentWrapperComponent} from '@shared/component/common/content-wrapper/content-wrapper.component';
import {
  ShareholderTableComponent
} from '@shared/component/job/component/company-incorporation/tables/shareholder-table/shareholder-table.component';
import {
  DirectorsTableComponent
} from '@shared/component/job/component/company-incorporation/tables/directors-table/directors-table.component';
import {IndividualInfoComponent} from '@shared/component/company/common/individual-info/individual-info.component';
import {CorporateInfoComponent} from '@shared/component/company/common/corporate-info/corporate-info.component';
import {
  SharesDistributionTableComponent
} from '@shared/component/job/component/company-incorporation/tables/shares-distribution-table/shares-distribution-table.component';
import {
  RegisteredCapitalComponent
} from '@shared/component/job/component/company-incorporation/tables/registered-capital/registered-capital.component';
import {
  FileUploadedTableComponent
} from '@shared/component/common/table/file-uploaded/file-uploaded-table/file-uploaded-table.component';
import {
  LookupOfficerDialogComponent
} from '@shared/component/job/component/company-incorporation/dialog/lookup-officer-dialog/lookup-officer-dialog.component';
import {
  AddOfficerDialogComponent
} from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/add-officer-dialog.component';
import {
  AssignPeopleDialogComponent
} from '@shared/component/job/dialog/assign-people-dialog/assign-people-dialog.component';
import {CheckoutPageComponent} from '@shared/component/payments/pages/checkout-page/checkout-page.component';
import {PaymentMethodsComponent} from '@shared/component/payments/components/payment-methods/payment-methods.component';
import {BankAccountComponent} from '@shared/component/payments/components/bank-account/bank-account.component';
import {
  AddBankAccountDialogComponent
} from '@shared/component/payments/dialogs/add-bank-account-dialog/add-bank-account-dialog.component';
import {
  UpdateBankAccountDialogComponent
} from '@shared/component/payments/dialogs/update-bank-account-dialog/update-bank-account-dialog.component';
import {ExtraJobDialogComponent} from '@shared/component/job/dialog/extra-job-dialog/extra-job-dialog.component';
import {
  DateMonthPickerComponent,
  DateMonthPickerHeader,
} from '@shared/component/date-time-picker/date-month-picker/date-month-picker.component';
import {
  AuthorizedOfficerDialogComponent
} from '@shared/component/job/component/company-incorporation/dialog/authorized-officer-dialog/authorized-officer-dialog.component';
import {
  OfficerResolutionRequestDialogComponent
} from '@shared/component/company/corporate-request/officer-resolution/officer-resolution-reuqest-dialog/officer-resolution-request-dialog.component';
import {
  CountrySelectInputComponent
} from '@shared/component/common/country-select-input/country-select-input.component';
import {OrderSummaryComponent} from '@shared/component/payments/components/order-summary/order-summary.component';
import {OfficerInformationComponent} from '@shared/component/officer/officer-information/officer-information.component';
import {BillingAddressComponent} from '@modules/address/components/billing-address/billing-address.component';
import {
  EmptyBillingAddressComponent
} from '@modules/account-settings/common/components/empty-billing-address/empty-billing-address.component';
import {
  OfficerResolutionComponent
} from '@shared/component/officer/table/officer-resolution/officer-resolution.component';
import {NoRecordComponent} from '@shared/component/common/table/no-record/no-record.component';
import {MatStepperModule} from '@angular/material/stepper';
import {
  RegisterCompanyCapitalComponent
} from '@shared/component/company/common/register-capital/register-company-capital.component';
import {
  ExistsOfficersSelectComponent
} from '@shared/component/officer/exists-officers-select/exists-officers-select.component';
import {StatusComponent} from '@shared/component/common/status/status.component';
import {CalloutComponent} from '@shared/component/message/callout/callout.component';
import {
  StripePaymentFormComponent
} from '@modules/payment/components/payment-forms/stripe-payment-form/stripe-payment-form.component';
import {LetModule, PushModule} from '@ngrx/component';
import {ShareDirectivesModule} from '@shared/share-directives/share-directives.module';
import {DropzoneUploadComponent} from '@shared/component/common/dropzone-upload/dropzone-upload.component';
import {RouterLink, RouterLinkWithHref} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {SharePipesModule} from '@shared/share-pipes/share-pipes.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CountryFlagComponent} from '@shared/component/common/country-flag/country-flag.component';
import {MatTableModule} from '@angular/material/table';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import {TenantLogoComponent} from '@shared/component/common/tenant-logo/tenant-logo.component';
import {AddressCardComponent} from '@shared/component/common/address-card/address-card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  OfficerInputReviewInformationComponent
} from './officer/officer-input-review-information/officer-input-review-information.component';
import {
  GstReturnSubmitDialogComponent
} from "@shared/component/job/dialog/gst-return-submit-dialog/gst-return-submit-dialog.component";
import {MatDatepickerConfig} from "@config/mat-datepicker.config";

@NgModule({
  declarations: [
    AuthCardComponent,
    AvatarComponent,
    ShareholderTableComponent,
    DirectorsTableComponent,
    IndividualInfoComponent,
    CorporateInfoComponent,
    SharesDistributionTableComponent,
    RegisteredCapitalComponent,
    FileUploadedTableComponent,
    LookupOfficerDialogComponent,
    AddOfficerDialogComponent,
    AssignPeopleDialogComponent,
    CheckoutPageComponent,
    PaymentMethodsComponent,
    BankAccountComponent,
    AddBankAccountDialogComponent,
    UpdateBankAccountDialogComponent,
    ExtraJobDialogComponent,
    DateMonthPickerComponent,
    DateMonthPickerHeader,
    AuthorizedOfficerDialogComponent,
    OfficerResolutionRequestDialogComponent,
    GstReturnSubmitDialogComponent,
  ],
  exports: [
    AuthCardComponent,
    AvatarComponent,
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatAutocompleteModule,
    ShareholderTableComponent,
    DirectorsTableComponent,
    RegisteredCapitalComponent,
    FileUploadedTableComponent,
    CheckoutPageComponent,
    BankAccountComponent,
    DateMonthPickerComponent,
    IndividualInfoComponent,
    CountrySelectInputComponent,
    CorporateInfoComponent,
    MultiCountrySelectInputComponent,
    CreditCardComponent,
    ContentWrapperComponent,
  ],
  bootstrap: [DateMonthPickerComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    NgxMatIntlTelInputComponent,
    RouterLinkWithHref,
    TenantLogoComponent,
    MatTableModule,
    CountryFlagComponent,
    MatTooltipModule,
    SharePipesModule,
    MatRadioModule,
    MatDatepickerConfig,
    RouterLink,
    DropzoneUploadComponent,
    ShareDirectivesModule,
    LetModule,
    StripePaymentFormComponent,
    CalloutComponent,
    StatusComponent,
    ExistsOfficersSelectComponent,
    RegisterCompanyCapitalComponent,
    MatStepperModule,
    NoRecordComponent,
    OfficerResolutionComponent,
    EmptyBillingAddressComponent,
    CountrySelectInputComponent,
    BillingAddressComponent,
    ContentWrapperComponent,
    OfficerInformationComponent,
    MultiCountrySelectInputComponent,
    OrderSummaryComponent,
    CreditCardComponent,
    AddressCardComponent,
    PushModule,
    MatProgressSpinnerModule,
    OfficerInputReviewInformationComponent,
  ],
})
export class SharedComponentModule {
}
