import { ModelFactory } from '@shared/factories/model.factory';
import { ServiceCategoryName, ServiceCategoryType } from '@generated/graphql';
import { faker } from '@faker-js/faker';
import { ServiceCategory } from '@modules/service/models/service-category.model';

export class ServiceCategoryFactory extends ModelFactory<ServiceCategoryType, ServiceCategory> {
  create (state: ServiceCategoryType): ServiceCategory {
    return new ServiceCategory(state);
  }

  fake (): ServiceCategory {
    return this.create({
      id: faker.datatype.uuid(),
      name: faker.helpers.arrayElement([ServiceCategoryName.AdditionalService, ServiceCategoryName.Incorporation]),
      // TODO carefully it is recursive itself.
      // parent: serviceCategoryFactory.fake(),
      parent: null,
      serviceSupportedCountries: [],
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
    });
  }
}

export const serviceCategoryFactory = new ServiceCategoryFactory();
