<mat-form-field *ngIf="existOfficers" appearance="outline" class="w-1/3">
  <mat-label>Select&ngsp;
    <span *ngIf="isShareholder">shareholder</span>
    <span *ngIf="isDirector">director</span>
  </mat-label>
  <input type="text" placeholder="Pick one" matInput required [formControl]="officerFilterInput"
    [matAutocomplete]="auto" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayExistsOfficerName"
    (optionSelected)="onExistsOfficerSelected($event)">
    <ng-container *ngIf="filteredOptions$ | async as options">
      <ng-container *ngIf="options.length > 0; else noOptions">
        <mat-option *ngFor="let option of options; let i = index" [value]="option.id" class="!py-1 !h-fit">
          <div class="inline-flex items-center">
            <mat-icon *ngIf="isIndividual(option)" class="text-darkGray opacity-70" [matTooltip]="'Individual'"
              matTooltipClass="capitalize">person
            </mat-icon>
            <mat-icon *ngIf="isCorporate(option)" class="text-darkGray opacity-70" [matTooltip]="'Corporate'"
              matTooltipClass="capitalize">apartment
            </mat-icon>
            <div>
              <p class="!mb-1 !text-sub truncate">
                {{ option.name }}
              </p>
              <p class="!mb-0 mat-caption text-lightGray truncate">
                {{ option.email }}
              </p>
            </div>
          </div>
        </mat-option>
      </ng-container>
      <ng-template #noOptions>
        <mat-option disabled class="!text-center">
          No options found
        </mat-option>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
  <mat-error *ngIf="officerFilterInput.invalid && officerFilterInput.errors?.required">
    Please pick a valid option
  </mat-error>
  <!--        TODO help to check error show if no option found-->
  <mat-error *ngIf="
      (filteredOptions$ | async).length === 0 &&
      !officerFilterInput.errors?.required
    ">
    No options found. Please refine your search.
  </mat-error>
</mat-form-field>
