<span class="block status-wrapper px-2 py-1 rounded-3xl w-fit m-auto {{renderFontFamily()}}"
      [ngClass]="{
    'status-default': statusStyle === 'default',
    'status-primary': statusStyle === 'primary',
    'status-new': statusStyle === 'new',
    'status-new-light': statusStyle === 'new-light',
    'status-success': statusStyle === 'success',
    'status-warn': statusStyle === 'warn',
    'status-error': statusStyle === 'error',
    'status-hold': statusStyle === 'hold',
    'status-cancelled': statusStyle === 'cancelled',
    'status-action': statusStyle === 'action',
    'status-action-light': statusStyle === 'action-light'}">
    {{ statusMessage }}
</span>
