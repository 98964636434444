import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  createSelectOptionsFromEnum,
  SelectOption,
} from '@shared/helpers/convention.helpers';
import { SalutationEnum } from '@generated/graphql';
import { FormGroup } from '@angular/forms';
import { OfficerCorporateFormType } from '@app/modules/officer/models/form/officer-corporate-form-type';
import { OfficerIndividualFormType } from '@app/modules/officer/models/form/officer-individual-form-type';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { IndividualInfoComponent } from '../individual-info/individual-info.component';

@Component({
  selector: 'app-corporate-info',
  templateUrl: './corporate-info.component.html',
  styleUrls: ['./corporate-info.component.scss'],
})
export class CorporateInfoComponent implements OnInit {
  @Input() formGroup: FormGroup<OfficerCorporateFormType>;

  @ViewChildren(NgxMatIntlTelInputComponent)
  phoneInputs: QueryList<NgxMatIntlTelInputComponent>;

  @ViewChild(IndividualInfoComponent)
  representativeForm: IndividualInfoComponent;

  salutations$: Observable<SelectOption[]> = of(
    createSelectOptionsFromEnum(SalutationEnum),
  );

  get individualForm(): FormGroup<OfficerIndividualFormType> {
    return this.formGroup.get('representative') as FormGroup;
  }

  constructor() { }

  ngOnInit(): void { }

  public resetForm(): void {
    this.phoneInputs.forEach((i) => i.reset());

    this.representativeForm.resetForm();
  }
}
