import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef, Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {CountryType} from '@generated/graphql';
import {Store} from '@ngrx/store';
import {combineLatestWith, map, Observable, of, startWith, Subject, Subscription, tap} from 'rxjs';
import {UserPolicy} from '@modules/user/policies/user.policy';
import {COUNTRY_STORE, CountryStore} from "@modules/country/store/country.store";
import {
  SUPPORTED_COUNTRY_STORE,
  SupportedCountryStore
} from "@modules/supported-country/store/supported-country.store";
import {CommonModule} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@Component({
  selector: 'app-multi-country-select-input',
  standalone: true,
  templateUrl: './multi-country-select-input.component.html',
  styleUrls: ['./multi-country-select-input.component.scss'],
  imports: [CommonModule, MatChipsModule, MatIconModule, MatAutocompleteModule, ReactiveFormsModule],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiCountrySelectInputComponent,
    },
  ],
  host: {
    '[class.multi-country-select-input-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class MultiCountrySelectInputComponent
  implements OnInit,
             OnDestroy,
             ControlValueAccessor,
             MatFormFieldControl<string[]> {
  countries: CountryType[];

  @Input() canPerformAction: boolean = true;
  @Input() visibleCountries: CountryType[];
  selectedCountries: CountryType[] = [];
  filteredCountries$: Observable<CountryType[]>;
  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
  subs = new Subscription();
  countryControl = new FormControl();
  isReachedLimit = false;

  searchText: string = '';

  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'multi-country-select-input';
  id = `multi-country-select-input-${MultiCountrySelectInputComponent.nextId++}`;
  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  get empty() {
    return this.selectedCountries.length === 0;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled
    ? this.countryControl.disable()
    : this.countryControl.enable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value(): string[] | null {
    if (this.selectedCountries.length) {
      return this.selectedCountries.map((c) => c.id);
    }

    return [];
  }

  set value(ids: string[]) {
    if (this.countries && ids) {
      this.selectedCountries = this.countries.filter((c) => ids.includes(c.id));
    }
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.required && this.selectedCountries.length === 0;
  }

  @Input()
  get limit(): number {
    return this._limit;
  }

  set limit(val: number) {
    this._limit = val;
  }

  private _limit: number = 0;

  @Input()
  get supportedCountry(): boolean {
    return this._supportedCountry;
  }

  set supportedCountry(value: BooleanInput) {
    this._supportedCountry = coerceBooleanProperty(value);
  }

  private _supportedCountry: boolean = false;

  constructor(
    @Inject(COUNTRY_STORE) protected readonly countryStore: CountryStore,
    @Inject(SUPPORTED_COUNTRY_STORE) protected readonly supportedCountryStore: SupportedCountryStore,
    private _store: Store,
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef<HTMLElement>,
    private readonly userPolicy: UserPolicy,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.multi-country-select-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    this.countryInput.nativeElement.focus();
  }

  writeValue(ids: string[] | null): void {
    this.value = ids;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _setupCountries(): void {
    if (!this.supportedCountry) {
      this.subs = this._store
        .select(this.countryStore.selectAllEntity)
        .pipe(
          tap((countries) => {
            this.countries = countries;
            this._initialValues();
          }),
          tap(() => {
            this._setupFilteredCountries();
          }),
        )
        .subscribe();
    }
    else {
      this.subs = this._store
        .select(this.supportedCountryStore.selectAllEntity)
        .pipe(
          tap((supportedCountries) => {
            this.countries = supportedCountries.map((sp) => sp.country);
            this._initialValues();
          }),
          tap(() => {
            this._setupFilteredCountries();
          }),
        )
        .subscribe();
    }
  }

  private _initialValues(): void {
    if (this.ngControl && this.ngControl.value) {
      const initialIds = this.ngControl.value;
      this.selectedCountries = this.countries.filter((c) =>
        initialIds.includes(c.id),
      );
    }
    this.checkReachedLimit();
  }

  private _setupFilteredCountries(): void {
    this.filteredCountries$ = this.countryControl.valueChanges.pipe(
      startWith(null),
      map((value: string) => {
        this.searchText = value;

        return this._filter();
      }),
    );
  }

  ngOnInit(): void {
    this._setupCountries();
  }

  private _filter(): CountryType[] {
    let countries = [...this.countries];

    if (this.visibleCountries) {
      countries = countries.filter(item => this.visibleCountries.some(country => country.id === item.id));
    }

    const selectedIds = this.selectedCountries.map((c) => c.id);

    if (!this.searchText && !selectedIds.length) {
      return countries.slice();
    }
    else if (!this.searchText && selectedIds.length) {
      return countries.filter((c) => !selectedIds.includes(c.id));
    }

    const filterValue = this.searchText.toLowerCase();

    if (this.selectedCountries.length) {
      return countries.filter(
        (c) =>
          !selectedIds.includes(c.id) &&
          c.name.toLowerCase()
            .includes(filterValue),
      );
    }

    return countries.filter((country) =>
      country.name.toLowerCase()
        .includes(filterValue),
    );
  }

  onCountrySelected(value: string): void {
    const country = this.countries.find((c) => c.id === value);
    this.selectedCountries.push(country);
    this.countryInput.nativeElement.value = '';
    this.countryControl.setValue(null);
    this.onChange(this.value);
    this.checkReachedLimit();
  }

  removeCountry(country: CountryType): void {
    const index = this.selectedCountries.findIndex((c) => c.id === country.id);

    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.onChange(this.value);
      this.checkReachedLimit();
    }
  }

  checkReachedLimit(): void {
    const reached =
      this.limit > 0 && this.selectedCountries.length === this.limit;

    if (reached || !this._filter().length) {
      this.isReachedLimit = true;
      this.countryControl.disable();
    }
    else {
      this.isReachedLimit = false;
      this.countryControl.enable();
    }
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.subs.unsubscribe();
  }

  canDelete$(country: CountryType): Observable<boolean> {
    return of(this.canPerformAction)
      .pipe(
        combineLatestWith(this.userPolicy.canDeleteCountryOfResponsibility$(country)),
        map(([canPerformAction, canDeleteCountryOfResponsibility$]) => {
          return canPerformAction && canDeleteCountryOfResponsibility$
        })
      );
  }
}
