export class ShareTypeDisabled {
  currency?: string;
  shareTypeId?: string;

  setShareTypeId(id?: string) {
    this.shareTypeId = id;
  }

  setCurrency(currency: string) {
    this.currency = currency;
  }
}

export class ShareTypeDisabledManagement {
  private shareTypeDisabled: ShareTypeDisabled[] = [];

  createItem(index: number) {
    this.shareTypeDisabled.push(new ShareTypeDisabled());
  }

  findItemByIndex(index: number) {
    return this.shareTypeDisabled[index];
  }

  currencyChanged(currency: string, index: number) {
    const item = this.findItemByIndex(index);
    item.currency = currency;
  }

  shareTypeIdChanged(shareTypeId: string, index: number) {
    const item = this.findItemByIndex(index);
    item.shareTypeId = shareTypeId;
  }

  isDisabled(shareTypeId: string, index: number) {
    const item = this.findItemByIndex(index);
    const found = this.shareTypeDisabled.filter(
      (s, i) =>
        index !== i &&
        s.shareTypeId === shareTypeId &&
        s.currency === item.currency
    );

    return !!found.length;
  }

  removeAt(index: number) {
    this.shareTypeDisabled.splice(index, 1);
  }
}
