import { Validators } from '@angular/forms';
import {
  CompanyCapitalInput,
  SharesTransferDetailInput,
  TransfereeInput,
} from '@generated/graphql';
import { uniq } from 'lodash-es';
import { ShareTransferDetailManagement } from './share-transfer-detail-management.class';

export class ShareTransferDetailByCapitalManagement extends ShareTransferDetailManagement {
  private _capitalsInput: CompanyCapitalInput[];
  public set capitalsInput(val) {
    this._capitalsInput = val;

    this.afterSetCapitals();
  }
  public get capitalsInput() {
    return this._capitalsInput;
  }

  public shareholderInputs: TransfereeInput[] = [];

  public exceptIndex: number;

  public constructor() {
    super();
  }

  private afterSetCapitals() {
    this.capitalsInput.forEach;
  }

  public getCurrencyForShareType(index: number): string[] {
    const rowControl = this.form.controls.details.at(index);

    return uniq(
      this.capitalsInput
        .filter((c) => c.shareTypeId === rowControl.controls.shareTypeId.value)
        .map((c) => c.currency),
    );
  }

  public getMaxNumberOfShares(index: number): number {
    const rowControl = this.form.controls.details.at(index);

    const shareTypeId = rowControl.controls.shareTypeId.getRawValue();

    const currency = rowControl.controls.currency.getRawValue();

    const totalSharesFromCapital = this.capitalsInput
      .filter((c) => c.shareTypeId === shareTypeId && c.currency === currency)
      .reduce((sum, c) => (sum += c.totalShares), 0);

    let total = 0;

    if (rowControl.controls.currency.enabled) {
      const otherShareholders = this.shareholderInputs.filter(
        (s, i) => i !== this.exceptIndex,
      );

      otherShareholders.forEach((s) => {
        total += s.sharesTransferDetails
          .filter(
            (std) =>
              std.currency === currency && std.shareTypeId === shareTypeId,
          )
          .reduce((sum, std) => (sum += std.numberOfShares), 0);
      });
    }

    return totalSharesFromCapital - total;
  }

  public setDataFromInput(details: SharesTransferDetailInput[]) {
    this.form.controls.details.clear();

    details.forEach((d, i) => {
      this.addFormItem();
      const control = this.form.controls.details.at(i);

      control.patchValue({
        transferNumber: d.transferNumber,
        shareCertNumber: d.shareCertNumber,
        shareTypeId: d.shareTypeId,
        numberOfShares: d.numberOfShares,
        currency: d.currency,
        paidAmount: d.paidAmount,
        paidAmountCurrency: d.paidAmountCurrency,
      });

      control.controls.shareTypeId.disable();

      control.controls.numberOfShares.enable();

      control.controls.numberOfShares.addValidators(
        Validators.max(this.getMaxNumberOfShares(i)),
      );

      control.controls.numberOfShares.updateValueAndValidity();
    });
  }
}
