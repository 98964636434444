import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { OfficerDialogManagement } from './officer-dialog-management.class';
import { OfficerDirectorDialogMangement } from './officer-director-dialog-management.class';
import { OfficerShareholderDialogMangement } from './officer-shareholder-dialog-management.class';

export class OfficerDialogManagementFactory {
  static getInstance(
    officerRole: OfficerRoleEnum,
    type: 'edit' | 'new',
  ): OfficerDialogManagement {
    if (officerRole === OfficerRoleEnum.DIRECTOR) {
      return new OfficerDirectorDialogMangement(officerRole, type);
    } else if (officerRole === OfficerRoleEnum.SHAREHOLDER) {
      return new OfficerShareholderDialogMangement(officerRole, type);
    } else {
      throw new Error('Wrong officer role!');
    }
  }
}
