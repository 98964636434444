import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CompanyCapitalFormType } from '@app/modules/company/models/form/company-capital-form-type';
import { DirectorFormType } from '@app/modules/job/models/form/director-form-type';
import { FormStepTwoFormType } from '@app/modules/job/models/form/form-step-two-form-type';
import { ShareholderFormType } from '@app/modules/job/models/form/shareholder-form-type';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { PaymentTypeEnum, ServiceEnum } from '@generated/graphql';
import { CompanyCapitalForm } from './company-capital-form.class';
import { CompanyDirectorForm } from './company-director-form.class';
import { CompanyShareholderForm } from './company-shareholder-form.class';
import { JobOrderServiceManagement } from './job-order-service-management.class';

export class CompanyStructureForm {
  private _form: FormGroup<FormStepTwoFormType>;
  get form(): FormGroup<FormStepTwoFormType> {
    return this._form;
  }

  set countryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;
  }

  companyCapitalForm: CompanyCapitalForm;

  companyDirectorForm: CompanyDirectorForm;

  companySharehoderForm: CompanyShareholderForm;

  get companyCaptialControls(): FormArray<FormGroup<CompanyCapitalFormType>> {
    return this.companyCapitalForm.form;
  }

  get shareholderControls(): FormArray<FormGroup<ShareholderFormType>> {
    return this.companySharehoderForm.form;
  }

  get directorControls(): FormArray<FormGroup<DirectorFormType>> {
    return this.companyDirectorForm.form;
  }

  private _countryOfIncorporation: SupportedCountry;

  constructor(
    private _fb: FormBuilder,
    countryOfIncorporation: SupportedCountry,
    private _orderServiceManagement: JobOrderServiceManagement,
  ) {
    this._countryOfIncorporation = countryOfIncorporation;

    this.companyCapitalForm = new CompanyCapitalForm();

    this.createDirectorForm();

    this.createShareholderForm();
  }

  private createDirectorForm() {
    this.companyDirectorForm = new CompanyDirectorForm(
      this._orderServiceManagement,
    );

    this.companyDirectorForm.setCountryOfIncorporation(
      this._countryOfIncorporation,
    );
  }

  private createShareholderForm() {
    this.companySharehoderForm = new CompanyShareholderForm(
      this.companyCapitalForm.form,
    );
  }

  createForm(): this {
    this._form = this._fb.group<FormStepTwoFormType>({
      companyCapitals: this.companyCapitalForm.form,
      shareholders: this.companySharehoderForm.form,
      directors: this.companyDirectorForm.form,
    });

    this.setupForm();

    return this;
  }

  private setupForm() {
    this.companyCapitalForm.form.valueChanges.subscribe(() => {
      this.companySharehoderForm.form.updateValueAndValidity();
    });

    this.companySharehoderForm.form.valueChanges.subscribe(() => {
      this.companySharehoderForm.checkValid();
    });

    this.companyDirectorForm.form.valueChanges.subscribe(() => {
      this.companyDirectorForm.checkValid();
    });
  }

  getNomineeServiceFee(): number {
    const service = this._orderServiceManagement.incorporationServices.find(
      (s) => s.service.name === ServiceEnum.NomineeDirector,
    );

    return service ? service.serviceFee : 0;
  }

  isNomineeServicePaymentTypeContact(): boolean {
    const service = this._orderServiceManagement.incorporationServices.find(
      (s) => s.service.name === ServiceEnum.NomineeDirector,
    );

    return service.paymentType.name === PaymentTypeEnum.ContactToClient;
  }

  setCountryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;

    this.companyDirectorForm.setCountryOfIncorporation(
      this._countryOfIncorporation,
    );
  }
}
