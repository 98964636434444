import { ModelFactory } from "@shared/factories/model.factory";
import { OfficerRequiredDocumentType } from "@generated/graphql";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";

export class OfficerRequiredDocumentFactory extends ModelFactory<OfficerRequiredDocumentType, OfficerRequiredDocument> {
  create (state: OfficerRequiredDocumentType): OfficerRequiredDocument {
    return new OfficerRequiredDocument(state);
  }

  fake (): OfficerRequiredDocument {
    throw new Error('This method is not implemented')
  }
}

export const officerRequiredDocumentFactory = new OfficerRequiredDocumentFactory()
