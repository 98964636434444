<ng-container *ngrxLet="activeUser$ as activeUser">
  <section class="mb-4">
    <!--KYC REMINDER-->
    <ng-container *ngIf="activeUser.isCustomer()">
      <app-kyc-reminder-message [companyID]="job.company.id"></app-kyc-reminder-message>
    </ng-container>
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Transferor Information
    </p>
    <app-officer-input-review-information class="block !px-2" [officerType]="'CompanyShareholderType'"
                                          [formType]="getTransferorType()"
                                          [officerInput]="getTransferor()"></app-officer-input-review-information>
  </section>

  <section class="mb-4">
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Transferee Information
    </p>
    <app-officer-input-review-information [officerType]="'CompanyShareholderType'" class="block !px-2"
                                          [formType]="getTransfereeType()"
                                          [officerInput]="getTransferee()"></app-officer-input-review-information>
  </section>

  <section *ngIf="!hideSharesTransferDetail">
    <p class="mat-title !text-base !mb-2 p-2 text-primary-900 bg-grey-50">
      Shares Transfer Details
    </p>
    <section class="transfer-detail-wrapper">
      <app-shares-transfer-detail-review
        [sharesTransferDetails]="getSharesTransferDetails()"></app-shares-transfer-detail-review>
    </section>
  </section>

</ng-container>
