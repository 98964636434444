import {Pipe, PipeTransform} from '@angular/core';
import {basename} from "@shared/helpers/file.helpers";

@Pipe({
  name: 'basename'
})
export class BasenamePipe implements PipeTransform {
  transform(value: string): unknown {
    return basename(value);
  }
}
