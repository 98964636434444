import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, delay, map, of, switchMap, tap} from 'rxjs';
import {AuthApi} from '@modules/auth/api/auth.api';
import {APP_ROUTES, AppRoutes} from '@config/app-routes.config';
import {AuthService} from '@modules/auth/services/auth.service';
import {ErrorService} from '@shared/services/error.service';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {resendEmailVerificationActions} from '@modules/auth/store/actions/resend-email-verification.actions';

@Injectable()
export class ResendEmailVerificationEffects {
  onResendVerificationEmail$ = createEffect(() => this.actions$.pipe(
    ofType(resendEmailVerificationActions.resendVerificationEmail.type),
    delay(2000),
    switchMap(({emailOrId}: ReturnType<typeof resendEmailVerificationActions.resendVerificationEmail>) => {
      return this.authApi.resendEmailVerification(emailOrId);
    }),
    tap(() => this.snackBarService.pushAlert('We\'ve sent a verification email to your inbox.Please check your inbox and complete the verification process.')),
    map(isSend => resendEmailVerificationActions.resendVerificationEmailSuccess()),
    catchError(e => of(resendEmailVerificationActions.resendVerificationEmailError())),
  ));

  onResendVerificationEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(resendEmailVerificationActions.resendVerificationEmailSuccess.type),
    tap(() => {

    }),
  ), {
    dispatch: false,
  });

  onResendVerificationEmailError$ = createEffect(() => this.actions$.pipe(
    ofType(resendEmailVerificationActions.resendVerificationEmailError.type),
    tap(() => {

    }),
  ), {
    dispatch: false,
  });

  constructor(
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private errorService: ErrorService,
    private actions$: Actions,
    // private store: Store<AuthState>,
    private authApi: AuthApi,
    // private router: Router,
  ) {
  }
}


