import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CompanyCapitalFormType } from '@modules/company/models/form/company-capital-form-type';
import {
  ShareholderFormType,
  ShareholdingDetailsFormType,
} from '@modules/job/models/form/shareholder-form-type';
import { ShareDistributionFormType } from '@modules/share-distribution/models/form/share-distributions-form-type';
import { ShareTypeService } from '@modules/share-type/services/share-type.service';
import {
  CompanyShareholderInput,
  CountryType,
  ShareTypeType,
} from '@generated/graphql';
import { ShareDistributionList } from 'src/shared/component/job/component/company-incorporation/tables/shares-distribution-table/share-distribution-list.class';
import {CommonHelper} from "@shared/helpers/common.helper";

@Component({
  selector: 'app-shares-distribution-table',
  templateUrl: './shares-distribution-table.component.html',
  styleUrls: ['./shares-distribution-table.component.scss'],
})
export class SharesDistributionTableComponent implements OnInit {
  @Input() shareholderEditIndex: number;
  @Input() formGroup: FormGroup<ShareDistributionFormType>;
  @Input() companyCapitals: FormArray<FormGroup<CompanyCapitalFormType>>;
  @Input() shareholderControls: FormArray<FormGroup<ShareholderFormType>>;

  shareTypes: ShareTypeType[] = [];
  countries: CountryType[];
  currencies: string[] = [];
  shareDistributionsList: ShareDistributionList = new ShareDistributionList();

  get shareDistributions() {
    return this.formGroup.controls.shareDistributions;
  }

  get companyCapitalsData() {
    return this.companyCapitals.value;
  }

  showMaximumValidatorNumber: boolean[] = [];

  constructor(
    public readonly commonHelper:CommonHelper,
    private shareTypeService: ShareTypeService,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.shareTypeService.getAll().subscribe((shareTypes) => {
      const filteredShareTypeIds = [
        ...new Set<string>(this.companyCapitalsData.map((c) => c.shareTypeId)),
      ];
      this.shareTypes = shareTypes.filter((s) =>
        filteredShareTypeIds.includes(s.id),
      );
    });

    this.shareDistributionsList.setCompanyCapitals(this.companyCapitalsData);

    this.shareDistributions.value.forEach((sd, idx) => {
      this.shareDistributionsList.addNewItem(sd);
      this.shareDistributionsList.changeCurrency(sd.currency, idx);
    });
  }

  addShareDistribution() {
    const newShareDistribution = this.createShareDistribution();
    this.shareDistributions.push(newShareDistribution);
    this.shareDistributionsList.addNewItem(newShareDistribution.value);

    this.showMaximumValidatorNumber.push(false);
  }

  removeShareDistribution(index: number) {
    this.shareDistributions.removeAt(index);
    this.shareDistributionsList.removeItem(index);

    this.showMaximumValidatorNumber.splice(index, 1);
  }

  createShareDistribution() {
    return this.fb.group<ShareholdingDetailsFormType>({
      shareTypeId: this.fb.control('', Validators.required),
      currency: this.fb.control(
        { value: '', disabled: true },
        Validators.required,
      ),
      numberOfShares: this.fb.control(0, [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  onCurrencyChanged(e: MatSelectChange, index: number) {
    const currency = e.value;
    this.shareDistributionsList.changeCurrency(currency, index);

    this.setMaximunNumberOfShareValidator(index);
  }

  onShareTypeChanged(e: MatSelectChange, index: number) {
    const shareTypeId = e.value;
    const currencyControl = this.shareDistributions.at(index).get('currency');

    this.shareDistributionsList.changeShareType(shareTypeId, index);

    currencyControl.enable();
  }

  setMaximunNumberOfShareValidator(index: number): void {
    const sharesNumberControl = this.shareDistributions
      .at(index)
      .get('numberOfShares');

    sharesNumberControl.clearValidators();
    sharesNumberControl.addValidators([
      Validators.max(this.getMaximumNumberOfShareAt(index)),
      Validators.min(1),
      Validators.required,
    ]);
    sharesNumberControl.updateValueAndValidity();

    this.showMaximumValidatorNumber[index] = true;
  }

  getMaximumNumberOfShareAt(index: number): number {
    let max = 0,
      total = 0;
    const shareTypeId = this.shareDistributionsList.items.at(index).shareTypeId;
    const currency = this.shareDistributionsList.items.at(index).currency;
    const capital = this.companyCapitals.value.find(
      (c) => c.shareTypeId === shareTypeId && c.currency === currency,
    );

    if (this.shareholderControls) {
      (this.shareholderControls.value as unknown as CompanyShareholderInput[])
        .filter((s, index) => this.shareholderEditIndex !== index)
        .forEach((shareholder) => {
          shareholder.shareholdingDetails.forEach((d) => {
            if (d.currency === currency && d.shareTypeId === shareTypeId) {
              total += d.numberOfShares;
            }
          });
        });
    }

    max = capital.totalShares - total;

    return max;
  }
  getShareTypeName(id: string): string {
    const shareType = this.shareTypes.find((t) => t.id === id);

    if (!shareType) {
      return '';
    }

    return shareType.name;
  }
}
