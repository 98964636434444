import {Injectable} from '@angular/core';
import {GraphQLErrors} from '@apollo/client/errors';
import {cloneDeep} from 'lodash-es';
import {ValidationError, validationErrorFactory, ValidationErrorRecord} from '@utils/validation/app.validation';
import {NestedKeyOf} from '@shared/types/common.type';
import {Store} from '@ngrx/store';
import {authActions} from '@modules/auth/store/actions/auth.actions';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {GraphQLError} from 'graphql/error';
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

export enum ErrorKey {
  Authentication = 'authentication',
  Authorization = 'authorization'
}

// import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private readonly store: Store,
    private readonly snackBarService: SnackBarService,
  ) {
  }

  handleGraphqlErrors = (graphQLErrors: GraphQLErrors): void => {
    graphQLErrors.map((graphqlError) => {
        const {message, locations, path} = graphqlError;

        const hasAuthenticationError = this.hasErrorCategory(graphqlError, ErrorKey.Authentication);

        const hasAuthorizationError = this.hasErrorCategory(graphqlError, ErrorKey.Authorization);

        if (hasAuthenticationError) {
          this.handleAuthenticationError();
        }

        if (hasAuthorizationError) {
          this.handleAuthorizationError();
        }

        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );

        return graphqlError;
      },
    );
  };

  getValidationErrorMessages<T extends Record<any, any>>(graphQLErrors: GraphQLErrors, exceptKeys: NestedKeyOf<T>[] = []): string[] {
    const messages: string[] = [];

    const value = this.getValidationErrorObjectList(graphQLErrors, exceptKeys)
      .getAllMessages();

    if (value?.length) {
      messages.push(...value);
    }

    return messages;
  }

  getValidationErrorObjectList<T extends Record<string, any>>(graphQLErrors: GraphQLErrors, exceptKeys: NestedKeyOf<T>[] = []): ValidationError<T> {
    const value = graphQLErrors
      .filter(error => error.extensions?.validation)
      .map(error => error.extensions?.validation as ValidationError<T>['error'])
      .reduce((prev, item) => {
        const result = cloneDeep(item);

        exceptKeys.forEach((exceptKey: string) => {
          if (Object.keys(result).includes(exceptKey)) {
            delete result[exceptKey];
          }
        });

        Object.entries(result).forEach(([key, value]) => {
          prev[key] = prev[key] && prev[key].length ? prev[key].concat(...value) : value;
        });

        return prev;
      }, {}) as ValidationErrorRecord<NestedKeyOf<T>>;

    return validationErrorFactory.create<T>(value);
  }

  hasValidationErrorKey<T extends Record<string, any>>(graphQLErrors: GraphQLErrors, key: string): boolean {
    return this.getValidationErrorObjectList(graphQLErrors).hasError(key);
  }

  handleAuthenticationError(): void {
    this.store.dispatch(authActions.resetAuth());

    // this.snackBarService.warn('Authentication error!');
  }

  handleAuthorizationError() {
    this.snackBarService.pushAlert('Missing permission! You need the necessary rights to do this.', '', SnackBarTypeEnum.warning);
  }

  hasErrorCategory(graphQLError: GraphQLError, key: ErrorKey): boolean {
    const {extensions} = graphQLError;

    const category = extensions?.category as string;

    return category === key;
  }

  getDebugMessage(graphQLErrors: GraphQLError[], separator: string = '\n'): string {
    return graphQLErrors?.map((error: any) => error?.debugMessage).join(separator);
  }
}
