<h1 mat-dialog-title class="mb-2 text-darkGray !text-[2.2rem] text-center">
  Replace document
</h1>
<div mat-dialog-content>
  <p class="text-sub">
    Replace:
    <a href="#!">{{ data.oldFile.metadata.originalName }}</a>
    by &colon;
  </p>

  <div class="mb-4">
    <app-dropzone-upload [accept]="accept" (filesChanged)="documentFileChangedHandler($event)"></app-dropzone-upload>

    <div class="my-2">
      <mat-progress-bar *ngIf="uploading$ | async" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button class="bg-grey-800 text-white w-48" (click)="onCancelClick()">
    Cancel
  </button>
  <button mat-raised-button color="primary" class="w-48" cdkFocusInitial
          [disabled]="!newDocument || (uploading$ | async)"
          (click)="submit()">
    Replace
  </button>
</div>
