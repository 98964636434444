import { Inject, Injectable } from '@angular/core';
import { APP_DB } from '@config/app-db.config';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor (
    @Inject(APP_DB) readonly storage: LocalForage,
  ) {
  }

  async saveData<T> (key: string, value: T): Promise<void> {
    try {
      await this.storage.setItem<T>(key, value);
    } catch (e) {
      console.error(e);
    }
  }

  async getData<T> (key: string): Promise<T> {
    try {
      return await this.storage.getItem(key);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async removeData (key: string) {
    try {
      await this.storage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }

  async clearData () {
    try {
      return await this.storage.clear();
    } catch (e) {
      console.error(e);
    }
  }
}
