import { ServiceCategoryType } from "@generated/graphql";
import { ServiceCategory } from "@modules/service/models/service-category.model";
import { InjectionToken, Provider } from "@angular/core";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum, EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";

export class ServiceCategoryStore extends EntityStore<EntityFeatureNameEnum.ServiceCategory, ServiceCategoryType, ServiceCategory> {
  get selectState (): SelectState<ServiceCategoryType> {
    return this.feature.selectServiceCategoryFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.ServiceCategory> {
    return 'SERVICE_CATEGORY';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.ServiceCategory> {
    return 'serviceCategoryFeature';
  }
}

export const serviceCategoryStore = new ServiceCategoryStore()
export const SERVICE_CATEGORY_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.ServiceCategoryStore)
export const SERVICE_CATEGORY_STORE_CONFIG: Provider = {
  provide: SERVICE_CATEGORY_STORE,
  useValue: serviceCategoryStore,
}
