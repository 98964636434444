import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, delay, map, of, switchMap, tap } from 'rxjs';
import { AuthApi } from '@modules/auth/api/auth.api';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthState } from '@modules/auth/store/reducers/auth.reducer';
import {
  registerActions,
} from '@modules/auth/store/actions/register.actions';
import { ErrorService } from '@shared/services/error.service';
import { RegisterVariables } from '@generated/graphql';

@Injectable()
export class RegisterEffects {
  onRegister$ = createEffect(() => this.actions$.pipe(
    ofType(registerActions.register.type),
    switchMap(({ input }: ReturnType<typeof registerActions.register>) =>
      this.authApi.register(input)
        .pipe(
          debounceTime(2000),
          map(user => registerActions.registerSuccess({ user })),
          catchError(({ graphQLErrors }) => {
            const registerError = this.errorService.getValidationErrorObjectList<RegisterVariables>(graphQLErrors);

            return of(registerActions.registerError({ registerError }));
          }),
        ),
    )));

  onRegisterSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(registerActions.registerSuccess.type),
      tap(async (action: ReturnType<typeof registerActions.registerSuccess>) => {
        // if (action.user) {
        //   await this.router.navigate(this.appRoutes.login());
        // }
      }),
      // map(({ user }: ReturnType<typeof registerSuccess>) => authUserActions.setAuthUser({ user: user })),
    ),
    {
      dispatch: false,
    });

  onRegisterError$ = createEffect(() => this.actions$.pipe(
    ofType(registerActions.registerError.type),
    tap((action: ReturnType<typeof registerActions.registerError>) => {

    }),
  ), {
    dispatch: false,
  });

  constructor (
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private authService: AuthService,
    private errorService: ErrorService,
    private actions$: Actions,
    private store: Store,
    private authApi: AuthApi,
    private router: Router,
  ) {
  }
}


