import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import { WireTransferBankApi } from "@modules/wire-transfer-bank/api/wire-transfer-bank.api";
import { WireTransferBankService } from "@modules/wire-transfer-bank/services/wire-transfer-bank.service";

@Injectable({
  providedIn: 'root'
})
export class WireTransferBanksResolver implements Resolve<WireTransferBank[]> {
  constructor (
    protected readonly wireTransferBankApi: WireTransferBankApi,
    protected readonly wireTransferBankService: WireTransferBankService,
  ) {
  }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WireTransferBank[]> {
    return this.wireTransferBankApi.get()
      .pipe(
        take(1),
        tap(value => {
          this.wireTransferBankService.dispatchClearEntitiesAction()

          this.wireTransferBankService.dispatchSetEntities(value)
        })
      );
  }
}
