import { Injectable } from '@angular/core';
import {
  CompanyShareholderJobInput,
  CompanyShareholderType,
  ConfirmTransferSharesJobMutation,
  ConfirmUpdateShareholderJobMutation,
  ExpertMakingTransferSharesMutation,
  ExpertUpdateShareholderMutation,
  GetShareholderJobRequestsQuery,
  JobType,
  SharesTransferDetailInput,
  TransferSharesInput,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyShareholderService {
  public constructor(
    private _getShareholderJobRequestsQuery: GetShareholderJobRequestsQuery,
    private _confirmUpdateShareholderJobMutation: ConfirmUpdateShareholderJobMutation,
    private _expertUpdateShareholderMutation: ExpertUpdateShareholderMutation,
    private _expertMakingTransferSharesMutation: ExpertMakingTransferSharesMutation,
    private _cconfirmTransferSharesJobMutatioin: ConfirmTransferSharesJobMutation,
  ) {
    //
  }

  public getShareholderJobRequests(companyId: string): Observable<JobType[]> {
    return this._getShareholderJobRequestsQuery
      .fetch({ companyId }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((response) => response.data.shareholderJobRequests as JobType[]),
      );
  }

  public confirmUpdateShareholderJob(
    jobId: string,
    effectiveDate: string,
  ): Observable<boolean> {
    return this._confirmUpdateShareholderJobMutation
      .mutate({
        jobId,
        effectiveDate,
      })
      .pipe(map((response) => response.data.confirmUpdateShareholderJob));
  }

  public expertUpdateShareholder(
    input: CompanyShareholderJobInput,
  ): Observable<CompanyShareholderType> {
    return this._expertUpdateShareholderMutation
      .mutate(
        {
          input,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map(
          (response) =>
            response.data.expertUpdateShareholder as CompanyShareholderType,
        ),
      );
  }

  public expertMakingTransferShares(
    input: TransferSharesInput,
  ): Observable<CompanyShareholderType> {
    return this._expertMakingTransferSharesMutation
      .mutate({
        input,
      })
      .pipe(
        map(
          (response) =>
            response.data.expertMakingTransferShares as CompanyShareholderType,
        ),
      );
  }

  public confirmTransferSharesJob(
    jobId: string,
    effectiveDate: string,
    sharesTransferDetails: SharesTransferDetailInput[],
  ): Observable<boolean> {
    return this._cconfirmTransferSharesJobMutatioin
      .mutate({
        jobId,
        effectiveDate,
        sharesTransferDetails,
      })
      .pipe(map((response) => response.data.confirmTransferSharesJob));
  }
}
