import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {CountryType} from '@generated/graphql';
import {Job} from '@app/modules/job/models/job.model';
import {
  CompanyCapitalInputReviewComponent
} from '@shared/component/company/common/comapny-capital-input-review/company-capital-input-review.component';
import {
  CapitalInputSummarizeComponent
} from '@shared/component/company/corporate-request/increase-capital-request-dialog/components/capital-input-summarize/capital-input-summarize.component';
import {
  ShareHolderListViewTableComponent
} from '@shared/component/shareholder/components/share-holder-list-view-table/share-holder-list-view-table.component';
import {OfficerInputHelpers} from '@shared/classes/officer/officer-input.helper';
import {BehaviorSubject, Observable} from 'rxjs';
import {CompanyApi} from '@app/modules/company/api/company.api';
import {Company} from '@app/modules/company/models/company.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CalloutComponent} from '@shared/component/message/callout/callout.component';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {User} from '@modules/user/models/user.model';
import {LetModule} from '@ngrx/component';
import {OfficerResolutionActionEnum} from '@shared/types/officerEnum';
import {MatDialog} from '@angular/material/dialog';
import {
  EditTransferSharesForIncreaseCapitalDialogComponent
} from '@shared/component/job/dialog/corporate-job-confirm-dialog/components/edit-transfer-shares-for-increase-capital-dialog/edit-transfer-shares-for-increase-capital-dialog.component';
import {cloneDeep} from 'lodash-es';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  KYCReminderMessageComponent
} from "@shared/component/message/kyc-reminder-message/kyc-reminder-message.component";

@UntilDestroy()
@Component({
  selector: 'app-increase-capital-info',
  standalone: true,
  templateUrl: './increase-capital-info.component.html',
  styleUrls: ['./increase-capital-info.component.scss'],
  imports: [
    CommonModule,
    MatDividerModule,
    CompanyCapitalInputReviewComponent,
    CapitalInputSummarizeComponent,
    ShareHolderListViewTableComponent,
    CalloutComponent,
    LetModule,
    KYCReminderMessageComponent,
  ],
})
export class IncreaseCapitalInfoComponent implements OnInit {
  private _job: Job;
  @Input()
  public set job(job) {
    this._job = job;
  }

  public get job() {
    return this._job;
  }

  @Input()
  company$: Observable<Company>;

  private _showEditDetails: boolean = false;
  @Input()
  public set showEditDetails(val: BooleanInput) {
    this._showEditDetails = coerceBooleanProperty(val);
  }

  public get showEditDetails() {
    return this._showEditDetails;
  }

  get jobDetail() {
    return this.job.detailObject;
  }

  public countries: CountryType[];

  public shareholderListView$ = new BehaviorSubject([]);

  public get companyCapitals() {
    return this.jobDetail.increaseCompanyCapitalInput.companyCapitals;
  }

  public totalNewCapitalShares$ = new BehaviorSubject(0);

  public allocatedValid$ = new BehaviorSubject(true);

  public shareDetailsValid$ = new BehaviorSubject(false);

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    private _companyApi: CompanyApi,
    public officerInputHelper: OfficerInputHelpers,
    private _dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (!this.company$) {
      this.company$ = this._companyApi
        .find$(this.job.company.id)
        .pipe(untilDestroyed(this));
    }

    this.setUpForSharesTransferDetails();
  }

  private checkInputNeededInfo() {
    let invalidCount = 0;

    this.jobDetail.increaseCompanyCapitalInput.shareholders.forEach((s) => {
      invalidCount += s.sharesTransferDetails.filter(
        (std) => !std.transferNumber || !std.shareCertNumber,
      ).length;
    });

    this.shareDetailsValid$.next(invalidCount === 0);
  }

  private setUpForSharesTransferDetails() {
    this.shareholderListView$.next(
      this.jobDetail.increaseCompanyCapitalInput.shareholders.map(
        (shareholder) =>
          this.officerInputHelper.transformNewShareholderToListViewItem(
            shareholder,
          ),
      ),
    );

    const totalShares =
      this.jobDetail.increaseCompanyCapitalInput.companyCapitals.reduce(
        (sum, c) => (sum += c.totalShares),
        0,
      );

    const shareholderTotalShares =
      this.jobDetail.increaseCompanyCapitalInput.shareholders.reduce(
        (parentSum, shareholder) =>
          (parentSum += shareholder.sharesTransferDetails.reduce(
            (childSum, std) => (childSum += std.numberOfShares),
            0,
          )),
        0,
      );

    this.allocatedValid$.next(totalShares === shareholderTotalShares);

    this.totalNewCapitalShares$.next(totalShares);

    this.checkInputNeededInfo();
  }

  public officerResolutionEventHanlder({
                                         action,
                                         index,
                                       }: {
    action: OfficerResolutionActionEnum;
    index: number;
  }) {
    if (action === OfficerResolutionActionEnum.EditInfo) {
      const editDialog = this._dialog.open(
        EditTransferSharesForIncreaseCapitalDialogComponent,
        {
          autoFocus: false,
          data: {
            index,
            capitalInputs:
            this.jobDetail.increaseCompanyCapitalInput.companyCapitals,
            shareholderInputs:
            this.jobDetail.increaseCompanyCapitalInput.shareholders,
          },
        },
      );

      editDialog
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result) {
            const tmpJob = cloneDeep(this.job);

            tmpJob.detailObject.increaseCompanyCapitalInput.shareholders[
              index
              ].sharesTransferDetails = result;

            this.job = tmpJob;

            this.setUpForSharesTransferDetails();
          }
        });
    }
  }
}
