import { ModelFactory } from "@shared/factories/model.factory";
import { OrderableType } from "@generated/graphql";
import { Orderable } from "@modules/order/models/order.model";
import { jobFactory } from "@modules/job/factories/job.factory";
import { extraJobFactory } from "@modules/extra-job/factories/extra-job.factory";

export class OrderableFactory extends ModelFactory<OrderableType, Orderable> {
  create (state: OrderableType): Orderable {
    if (state.__typename === 'ExtraJobType') {
      return extraJobFactory.create(state);
    }

    return jobFactory.create(state)
  }

  fake (): Orderable {
    throw new Error('Method is not implemented')
  }
}

export const orderableFactory = new OrderableFactory()
