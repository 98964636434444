import {ModelFactory} from "@shared/factories/model.factory";
import {PermissionType} from "@generated/graphql";
import {Permission} from "@modules/permission/models/permission.model";

export class PermissionFactory extends ModelFactory<PermissionType, Permission> {
  create(state: PermissionType): Permission {
    return new Permission(state);
  }

  fake(): Permission {
    throw new Error('Method not implemented!')
  }
}

export const permissionFactory = new PermissionFactory();
