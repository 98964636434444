import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";

export enum ConfirmDialogAction {
  Confirm = 'CONFIRM',
  Cancel = 'CANCEL'
}

export interface ConfirmDialogComponentData {
  confirmMessage?: string
  title?: string
}

@Component({
  standalone: true,
  providers: [
    CommonModule,
    MatDialogModule
  ],
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatListModule
  ],
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  ConfirmDialogAction = ConfirmDialogAction

  get title (): string {
    return this.data.title ?? 'Confirmation'
  }

  get confirmMessage (): string {
    return this.data.confirmMessage ?? 'Are you sure you want to perform this action?'
  };

  constructor (
    @Inject(MAT_DIALOG_DATA) protected readonly data: ConfirmDialogComponentData
  ) {
  }

  ngOnInit (): void {
  }
}
