import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appOutOfFocus]'
})
export class OutOfFocusDirective {
  @Output() outOfFocus: EventEmitter<void> = new EventEmitter();

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:dblclick', ['$event.target'])
  onDoubleClick(targetElement: HTMLElement): void {
    const isClickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!isClickedInside) {
      this.outOfFocus.emit();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keys = ['Escape', 'Esc']

    if (keys.includes(event.key)) {
      this.outOfFocus.emit();
    }
  }
}
