import { BaseModel } from "@shared/models/base.model";
import { OfficerRequiredDocumentType, OfficerTypeEnum } from "@generated/graphql";
import { Country } from "@modules/country/models/country.model";
import { FileType } from "@modules/file/models/file-type.model";
import { countryFactory } from "@modules/country/factories/country.factory";
import { fileTypeFactory } from "@modules/file/factories/file-type.factory";

export class OfficerRequiredDocument extends BaseModel implements OfficerRequiredDocumentType {
  id: string;
  country: Country;
  fileType: FileType;
  officerType: OfficerTypeEnum;

  constructor (state: OfficerRequiredDocumentType) {
    super(state);

    this.country = state.country ? countryFactory.create(state.country) : null;

    this.fileType = state.fileType ? fileTypeFactory.create(state.fileType) : null
  }

  getModelName (): string {
    return OfficerRequiredDocument.name;
  }
}
