<div *ngIf="formStepTwo" class="directors-table-wrapper table-container mat-elevation-z4 mb-4">
  <table mat-table class="table-custom" [dataSource]="directorControls.value">
    <!--    Type column-->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="!text-white w-20">Type</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.officerType === officerType.Individual" class="text-darkGray opacity-70"
          [matTooltip]="element.officerType.toLowerCase()" matTooltipClass="capitalize">person
        </mat-icon>
        <mat-icon *ngIf="element.officerType === officerType.Corporate" class="text-darkGray opacity-70"
          [matTooltip]="element.officerType.toLowerCase()" matTooltipClass="capitalize">apartment
        </mat-icon>
      </td>
    </ng-container>

    <!--    Name column-->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="!text-white w-1/3">Name</th>
      <td mat-cell *matCellDef="let element">
        <p class="!mb-1 line-clamp-2">{{ getOfficerName(element) }}</p>
        <p class="mat-caption !mb-0 text-lightGray truncate" matTooltip="{{ element.email }}">
          {{ getOfficerEmail(element) }}
        </p>
      </td>
    </ng-container>

    <!--    Nationality column-->
    <ng-container matColumnDef="nationality">
      <th mat-header-cell *matHeaderCellDef class="!text-white !text-center w-1/3">
        Nationality/<br />Country of Incorporation
      </th>
      <td mat-cell *matCellDef="let element" class="!text-center">
        <ng-container *ngIf="element.isNominee"><span>&dash;</span></ng-container>
        <app-country-flag *ngIf="!element.isNominee" showCountryName
          id="{{ getCountryId(element) }}"></app-country-flag>
      </td>
    </ng-container>

    <!--    Residence column-->
    <ng-container matColumnDef="residence">
      <th mat-header-cell *matHeaderCellDef class="!text-white !text-center w-1/3">
        Residence
      </th>
      <td mat-cell *matCellDef="let element" class="!text-center">
        <ng-container>
          <app-country-flag showCountryName id="{{ getResidenceCountryId(element) }}"></app-country-flag>
        </ng-container>
      </td>
    </ng-container>

    <!--    Actions column-->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="!text-white w-36" [ngClass]="{ hidden: appearance === 'viewOnly' }">
        &nbsp;
      </th>
      <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ hidden: appearance === 'viewOnly' }">
        <div class="inline-flex justify-end w-full" *ngIf="appearance === 'register'">
          <!--          TODO IMPLEMENT LATER-->
          <!--          <button mat-icon-button>-->
          <!--            <mat-icon *ngIf="!element.isNominee"-->
          <!--                      class="text-darkGray opacity-70">pageview-->
          <!--            </mat-icon>-->
          <!--          </button>-->
          <button *ngIf="!element.isNominee" mat-icon-button (click)="editDirector(element, i)">
            <mat-icon class="text-darkGray">edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteDirector(i)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedDirectorColumns" class="bg-primary-800"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDirectorColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td [colSpan]="displayedDirectorColumns.length">
        <table-no-record [notFoundMessage]="'You have not appointed any director.'"></table-no-record>
      </td>
    </tr>
  </table>
</div>
