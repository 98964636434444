import { CompanyCapitalInput, ShareTypeType } from '@generated/graphql';

export interface ShareDistributionCurrency {
  currency?: string;
  disabled?: boolean;
}

export interface ShareDistributionItem {
  shareTypeId?: string;
  currencies?: ShareDistributionCurrency[];
  currency?: string;
  numberOfShares?: number;
}

export class ShareDistributionList {
  companyCapitals: CompanyCapitalInput[] = [];
  items: ShareDistributionItem[] = [];

  constructor() {}

  setCompanyCapitals(data: CompanyCapitalInput[]): void {
    this.companyCapitals = data;
  }

  addNewItem(data: Partial<ShareDistributionItem>): void {
    this.items.push(data);
  }

  removeItem(index: number): void {
    this.items.splice(index, 1);
    this.refreshCurrencies();
  }

  changeShareType(shareTypeId: string, index: number): void {
    this.items[index].shareTypeId = shareTypeId;
    this.setCurrencies(index);
  }

  changeCurrency(currency: string, index: number): void {
    this.items[index].currency = currency;
    this.refreshCurrencies();
  }

  private refreshCurrencies() {
    this.items.forEach((i, idx) => {
      this.setCurrencies(idx);
    });
  }

  private setCurrencies(index: number): void {
    const shareTypeId = this.items[index].shareTypeId;
    const originCurrencies = [
      ...new Set(
        this.companyCapitals
          .filter((c) => c.shareTypeId === shareTypeId)
          .map((c) => c.currency)
      ),
    ];

    let existsCurrencies: string[] = [];

    this.items.forEach((i, idx) => {
      if (i.shareTypeId === shareTypeId && idx !== index) {
        existsCurrencies.push(i.currency);
      }
    });

    this.items[index].currencies = originCurrencies.map((c) => ({
      currency: c,
      disabled: existsCurrencies.includes(c),
    }));
  }
}
