import {BaseModel} from "@shared/models/base.model";
import {CompanyIndirectShareholderType, OfficerDocumentStatusEnum} from "@generated/graphql";
import {Officer} from "@modules/officer/models/officer.model";
import {Corporate} from "@modules/company/models/corporate.model";
import {corporateFactory} from "@modules/company/factories/corporate.factory";
import {officerFactory} from "@modules/officer/factories/officer.factory";
import {companyIndirectShareholderFactory} from "@modules/company/factories/company-indirect-shareholder.factory";
import {startCase} from "lodash-es";
import {AppStatusType} from "@shared/component/common/status/status.component";

export class CompanyIndirectShareholder extends BaseModel implements CompanyIndirectShareholderType {
  id: string;
  officer: Officer;
  company: Corporate;
  companyId: string;
  companyType: string;
  percentageOfShares: number;
  isUBO: boolean
  documentsStatus: OfficerDocumentStatusEnum
  canCreateShareholder: boolean
  remainingPercentageOfShares: number
  remainingPercentageOfSharesForUpdating: number
  canUploadDocument: boolean
  canDeleteDocument: boolean
  canDelete: boolean
  canUpdate: boolean
  shareholders: CompanyIndirectShareholder[]
  canViewShareholders: boolean
  isRequiredKyc: boolean
  _model: string;

  constructor(state: CompanyIndirectShareholderType) {
    super(state);

    this.officer = officerFactory.tryCreating(state.officer)

    this.company = corporateFactory.tryCreating(state.company)

    this.shareholders = companyIndirectShareholderFactory.createEntities(state.shareholders)
  }

  get title (): string {
    return 'Shareholder'
  }

  get renderOfficerRole(): string {
    return this.title
  }

  get isNominee(): boolean {
    return false;
  }

  get corporateShareholders(): CompanyIndirectShareholder[] {
    return this.shareholders.filter(item => item.officer.officerProfile.__typename === 'OfficerIndirectCompanyType')
  }

  get individualShareholders(): CompanyIndirectShareholder[] {
    return this.shareholders.filter(item => item.officer.officerProfile.__typename === 'OfficerIndirectPersonType')
  }

  get ownership(): number {
    return this.percentageOfShares;
  }

  get name(): string {
    return this.officer.name;
  }

  get documentStatusLabel(): string {
    return startCase(this.documentsStatus.toLowerCase());
  }

  get documentsStatusStyle(): AppStatusType {
    switch (this.documentsStatus) {
      case OfficerDocumentStatusEnum.AwaitingReview: {
        return 'warn';
      }
      case OfficerDocumentStatusEnum.Verified: {
        return 'success';
      }
      case OfficerDocumentStatusEnum.Rejected: {
        return 'error';
      }
      default:
        return 'default';
    }
  }

  getModelName(): string {
    return CompanyIndirectShareholder.name;
  }
}
