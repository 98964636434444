import { PaymentTypeEnum, PaymentTypeType } from "@generated/graphql";
import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum, EntityStore, EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { PaymentType } from "@modules/payment/models/payment-type.model";
import { InjectionToken, Provider } from "@angular/core";
import { createSelector } from "@ngrx/store";

export class PaymentTypeStore extends EntityStore<EntityFeatureNameEnum.PaymentType, PaymentTypeType, PaymentType> {
  readonly selectByName = (name: PaymentTypeEnum) => createSelector(
    this.selectIds,
    this.selectEntities,
    (ids, entities) => ids.map((id) => entities[id]).find(item => item.name === name)
  );

  get selectState (): SelectState<PaymentTypeType> {
    return this.feature.selectPaymentTypeFeatureState;
  }

  getEntry (): EntityEntry<EntityFeatureNameEnum.PaymentType> {
    return 'PAYMENT_TYPE';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.PaymentType> {
    return 'paymentTypeFeature';
  }
}

export const paymentTypeStore = new PaymentTypeStore()

export const PAYMENT_TYPE_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.PaymentTypeStore)
export const PAYMENT_TYPE_STORE_CONFIG: Provider = {
  provide: PAYMENT_TYPE_STORE,
  useValue: paymentTypeStore,
}
