import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { PaymentProvider } from '@modules/payment/models/payment-provider.model';
import { PaymentProviderService } from '@modules/payment/services/payment-provider.service';
import { PaymentProviderApi } from '@modules/payment/api/payment-provider.api';

@Injectable({
  providedIn: 'root'
})
export class PaymentProviderResolver implements Resolve<PaymentProvider[]> {
  constructor (
    protected readonly paymentProviderService: PaymentProviderService,
    protected readonly paymentProviderApi: PaymentProviderApi
  ) {
  }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentProvider[]> {
    return this.paymentProviderApi.get().pipe(
      take(1),
      tap(paymentProviders => {
        this.paymentProviderService.dispatchClearEntitiesAction()

        this.paymentProviderService.dispatchSetEntities(paymentProviders);

        return paymentProviders;
      }));
  }
}
