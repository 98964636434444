import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SectionWpComponent
} from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import {CommonHelper} from '@shared/helpers/common.helper';
import {
  OfficerInputReviewInformationComponent
} from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import {CalloutComponent} from '@shared/component/message/callout/callout.component';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {Observable} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {LetModule} from '@ngrx/component';
import {AgmInput} from '@generated/graphql';
import {
  BusinessProfileService
} from '@app/modules/account-settings/pages/business-profile/services/business-profile.service';
import {BusinessProfile} from '@app/modules/account-settings/pages/business-profile/models/business-profile.model';
import {Company} from '@app/modules/company/models/company.model';

@Component({
  selector: 'app-hold-agm-review-info',
  standalone: true,
  templateUrl: './hold-agm-review-info.component.html',
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
    CalloutComponent,
    LetModule,
  ],
})
export class HoldAgmReviewInfoComponent implements OnInit {
  private _holdAgmInput: AgmInput;
  public get holdAgmInput() {
    return this._holdAgmInput;
  }

  @Input()
  public set holdAgmInput(input) {
    this._holdAgmInput = input;
  }

  private _companyInfo: Company;
  public get companyInfo() {
    return this._companyInfo;
  }

  @Input()
  public set companyInfo(company) {
    this._companyInfo = company;
  }

  public get businessProfile$(): Observable<BusinessProfile> {
    return this.businessProfileService.activeEntity$;
  }

  constructor(
    @Inject(ACTIVE_USER) readonly activeUser$: Observable<User>,
    protected readonly commonHelper: CommonHelper,
    private readonly businessProfileService: BusinessProfileService,
  ) {
  }

  ngOnInit(): void {
  }
}
