import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { BusinessPlanService } from "@modules/account-settings/pages/business-profile/services/business-plan.service";
import { APP_ROUTES, AppRoutes } from "@config/app-routes.config";

@Injectable({
  providedIn: 'root'
})
export class PaymentGuard implements CanActivate, CanActivateChild {
  constructor (
    @Inject(APP_ROUTES) private readonly appRoutes: AppRoutes,
    private readonly businessPlanService: BusinessPlanService,
    private readonly router: Router,
  ) {
  }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): ReturnType<CanActivate['canActivate']> {
    return this.check$()
  }

  canActivateChild (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<CanActivateChild['canActivateChild']> {
    return this.check$()
  }

  private check$ (): ReturnType<CanActivate['canActivate']> {
    return this.businessPlanService
      .visiblePayment$()
      .pipe(
        map(visiblePayment => {
          return visiblePayment ?? this.router.createUrlTree(this.appRoutes.dashboard())
        })
      );
  }
}
