<ng-container *ngIf="customContent;else messageOnly">
    <section class="rounded-lg p-4 w-full callout-wrapper"
             [ngClass]="{'callout-default': appearance === 'default',
                        'callout-primary': appearance === 'primary',
                        'callout-new': appearance === 'new',
                        'callout-new-light': appearance === 'new-light',
                        'callout-success': appearance === 'success',
                        'callout-warn': appearance === 'warn',
                        'callout-error': appearance === 'error',
                        'callout-hold': appearance === 'hold',
                        'callout-cancelled': appearance === 'cancelled',
                        'callout-action': appearance === 'action',
                        'callout-action-light': appearance === 'action-light'}">
        <ng-content></ng-content>
    </section>

</ng-container>

<ng-template #messageOnly>
    <section class="rounded-lg p-4 w-full callout-wrapper"
             *ngIf="message"
             [ngClass]="{'callout-default': appearance === 'default',
                        'callout-primary': appearance === 'primary',
                        'callout-new': appearance === 'new',
                        'callout-new-light': appearance === 'new-light',
                        'callout-success': appearance === 'success',
                        'callout-warn': appearance === 'warn',
                        'callout-error': appearance === 'error',
                        'callout-hold': appearance === 'hold',
                        'callout-cancelled': appearance === 'cancelled',
                        'callout-action': appearance === 'action',
                        'callout-action-light': appearance === 'action-light'}">
        <p class="!text-sub !mb-0">{{message}}</p>
    </section>
</ng-template>
