import { Comparer, EntityState, IdSelector } from "@ngrx/entity/src/models";
import {
  ActionCreator,
  ActionReducer,
  createAction,
  createFeature,
  createReducer,
  createSelector,
  DefaultProjectorFn, FeatureConfig,
  MemoizedSelector,
  on,
  props
} from "@ngrx/store";
import {
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityMap,
  EntityMapOne,
  Predicate,
  Update
} from "@ngrx/entity";
import { TypedAction } from "@ngrx/store/src/models";
import { CamelCase, CamelToSnakeCase } from "@shared/types/common.type";
import { startCase } from "lodash-es";
import { ReducerTypes } from "@ngrx/store/src/reducer_creator";

export interface EntityStateExtra<T> extends EntityState<T> {
  ids: string[],
  selectedEntityId: string | null
}

export enum EntityStoreEnum {
  CountryStore = 'COUNTRY_STORE',
  ServiceCategoryStore = 'SERVICE_CATEGORY_STORE',
  SupportedCountryStore = 'SUPPORTED_COUNTRY_STORE',
  ServiceSupportedCountryStore = 'SERVICE_SUPPORTED_COUNTRY_STORE',
  PaymentTypeStore = 'PAYMENT_TYPE_STORE',
  PaymentProviderStore = 'PAYMENT_PROVIDER_STORE',
  PaymentStore = 'PAYMENT_STORE',
  ServiceStore = 'SERVICE_STORE',
  PaymentUserStore = 'PAYMENT_USER_STORE',
  WireTransferBankStore = 'WIRE_TRANSFER_BANK_STORE',
  CustomerStore = 'CUSTOMER_STORE',
  ExpertStore = 'EXPERT_STORE',
  JobStore = 'JOB_STORE',
  CompanyStore = 'COMPANY_STORE',
  OrderStore = 'ORDER_STORE',
  BusinessProfileStore = 'BUSINESS_PROFILE_STORE',
  ExtraJobStore = 'EXTRA_JOB_STORE',
  BillingAddressStore = 'BILLING_ADDRESS_STORE',
  OfficerRequiredDocumentStore = 'OFFICER_REQUIRED_DOCUMENT_STORE',
  AddressTypeStore = 'ADDRESS_TYPE_STORE'
}

export enum EntityFeatureNameEnum {
  Country = 'country',
  PaymentType = 'paymentType',
  ServiceCategory = 'serviceCategory',
  SupportedCountry = 'supportedCountry',
  ServiceSupportedCountry = 'serviceSupportedCountry',
  PaymentProvider = 'paymentProvider',
  Service = 'service',
  WireTransferBank = 'wireTransferBank',
  PaymentUser = 'paymentUser',
  Customer = 'customer',
  Expert = 'expert',
  Job = 'job',
  Company = 'company',
  Order = 'order',
  BusinessProfile = 'businessProfile',
  ExtraJob = 'extraJob',
  Payment = 'payment',
  BillingAddress = 'billingAddress',
  OfficerRequiredDocument = 'officerRequiredDocument',
  AddressType = 'addressType'
}

export type EntityEntry<T extends string> = Uppercase<CamelToSnakeCase<T>>

export type EntityFeatureName<T extends string> = `${CamelCase<Lowercase<CamelToSnakeCase<T>>>}Feature`

export type EntityAction<T> = ActionCreator<string, (props: { entity: T }) => ({ entity: T } & TypedAction<string>)>

export type DeleteEntityAction<T> = ActionCreator<string, (props: { id: string }) => ({
  id: string
} & TypedAction<string>)>

export type EntityMapAction<T> = ActionCreator<string, (props: { entityMap: EntityMapOne<T> }) => ({
  entityMap: EntityMapOne<T>
} & TypedAction<string>)>

export type UpdateEntityAction<T> = ActionCreator<string, (props: { update: Update<T> }) => ({
  update: Update<T>
} & TypedAction<string>)>

export type EntitiesAction<T> = ActionCreator<string, (props: { entities: T[] }) => ({
  entities: T[]
} & TypedAction<string>)>

export type EntitiesMapAction<T> = ActionCreator<string, (props: { entityMap: EntityMap<T> }) => ({
  entityMap: EntityMap<T>
} & TypedAction<string>)>

export type DeleteEntitiesAction<T> = ActionCreator<string, (props: { ids: string[] }) => ({
  ids: string[]
} & TypedAction<string>)>

export type UpdateEntitiesAction<T> = ActionCreator<string, (props: { updates: Update<T>[] }) => ({
  updates: Update<T>[]
} & TypedAction<string>)>

export type DeleteEntitiesByPredicateAction<T> = ActionCreator<string, (props: { predicate: Predicate<T> }) => ({
  predicate: Predicate<T>
} & TypedAction<string>)>

export type ClearEntitiesAction<T> = ActionCreator<string, () => TypedAction<string>>

export type SelectEntityId<T> = MemoizedSelector<EntityStateExtra<T>, string, DefaultProjectorFn<string>>

export type SelectAllEntity<T> = MemoizedSelector<EntityStateExtra<T>, T[], DefaultProjectorFn<T[]>>

export type SelectEntities<T> = MemoizedSelector<EntityStateExtra<T>, Dictionary<T>, DefaultProjectorFn<Dictionary<T>>>

export type SelectEntity<T> = (id: string) => MemoizedSelector<EntityStateExtra<T>, T, DefaultProjectorFn<T>>

export type SelectSelectedEntity<T> = MemoizedSelector<EntityStateExtra<T>, T, DefaultProjectorFn<T>>

export type SelectEntitiesByIds<T> = (ids: string[]) => MemoizedSelector<EntityStateExtra<T>, T[], DefaultProjectorFn<T[]>>

export type SelectIds<T> = MemoizedSelector<EntityStateExtra<T>, string[], DefaultProjectorFn<string[]>>

export type SelectState<T> = MemoizedSelector<EntityStateExtra<T>, EntityStateExtra<T>, DefaultProjectorFn<EntityStateExtra<T>>>

export type EntityReducer<T> = ReducerTypes<EntityStateExtra<T>, ActionCreator[]>

export interface EntityStoreContract<F extends string, BE, FE> {
  sortComparer?: false | Comparer<BE>;

  feature: FeatureConfig<EntityFeatureName<F>, EntityStateExtra<BE>>

  selectId?: IdSelector<BE>;

  loadEntitiesAction: EntitiesAction<BE>

  setEntitiesAction: EntitiesAction<BE>

  addOrUpdateEntityAction: EntityAction<BE>

  addEntityAction: EntityAction<BE>

  setEntityAction: EntityAction<BE>

  upsertEntityAction: EntityAction<BE>

  addEntitiesAction: EntitiesAction<BE>

  upsertEntitiesAction: EntitiesAction<BE>

  updateEntityAction: UpdateEntityAction<BE>

  updateEntitiesAction: UpdateEntitiesAction<BE>

  mapEntityAction: EntityMapAction<BE>

  mapEntitiesAction: EntitiesMapAction<BE>

  deleteEntityAction: DeleteEntityAction<BE>

  deleteEntitiesAction: DeleteEntitiesAction<BE>

  deleteEntitiesByPredicateAction: DeleteEntitiesByPredicateAction<BE>

  clearEntitiesAction: ClearEntitiesAction<BE>

  actionReducer: ActionReducer<EntityStateExtra<BE>>

  reducers: EntityReducer<BE>[]

  onUpdateEntityAction: EntityReducer<BE>

  onSetEntityAction: EntityReducer<BE>

  onAddEntityAction: EntityReducer<BE>

  onSetEntitiesAction: EntityReducer<BE>

  onUpsertEntityAction: EntityReducer<BE>

  onAddEntitiesAction: EntityReducer<BE>

  onUpsertEntitiesAction: EntityReducer<BE>

  onUpdateEntitiesAction: EntityReducer<BE>

  onAddOrUpdateEntityAction: EntityReducer<BE>

  onMapEntityAction: EntityReducer<BE>

  onMapEntitiesAction: EntityReducer<BE>

  onDeleteEntityAction: EntityReducer<BE>

  onDeleteEntitiesAction: EntityReducer<BE>

  onDeleteEntitiesByPredicateAction: EntityReducer<BE>

  onLoadEntitiesAction: EntityReducer<BE>

  onClearEntitiesAction: EntityReducer<BE>

  adapter: EntityAdapter<BE>

  initialState: EntityStateExtra<BE>

  selectIds: SelectIds<BE>

  selectEntities: SelectEntities<BE>

  selectAllEntity: SelectAllEntity<BE>

  selectEntity: SelectEntity<BE>

  selectSelectedEntity: SelectSelectedEntity<BE>

  selectEntitiesByIds: SelectEntitiesByIds<BE>

  get selectedEntityId (): SelectEntityId<BE>

  get selectState (): SelectState<BE>

  getEntry (): EntityEntry<F>

  getFeatureName (): EntityFeatureName<F>
}

export enum EntityActionLabelEnum {
  LoadEntities = 'LOAD_ENTITIES',
  SetEntities = 'SET_ENTITIES',
  AddEntity = 'ADD_ENTITY',
  AddOrUpdateEntity = 'ADD_OR_UPDATE_ENTITY',
  SetEntity = 'SET_ENTITY',
  UpsertEntity = 'UPSERT_ENTITY',
  AddEntities = 'ADD_ENTITIES',
  UpsertEntities = 'UPSERT_ENTITIES',
  UpdateEntity = 'UPDATE_ENTITY',
  UpdateEntities = 'UPDATE_ENTITIES',
  MapEntity = 'MAP_ENTITY',
  MapEntities = 'MAP_ENTITIES',
  SelectEntity = 'SELECT_ENTITY',
  DeleteEntity = 'DELETE_ENTITY',
  DeleteEntities = 'DELETE_ENTITIES',
  DeleteEntitiesByPredicate = 'DELETE_ENTITIES_BY_PREDICATE',
  ClearEntities = 'CLEAR_ENTITIES',
}

export abstract class EntityStore<EntityFeature extends EntityFeatureNameEnum, BE extends Record<string, any>, FE extends BE> implements EntityStoreContract<EntityFeature, BE, FE> {
  selectId?: IdSelector<BE>;

  sortComparer?: false | Comparer<BE>;

  adapter = createEntityAdapter<BE>({
    selectId: this.selectId,
    sortComparer: this.sortComparer,
  });

  initialState: EntityStateExtra<BE> = this.adapter.getInitialState({
    ids: [],
    entities: [],
    selectedEntityId: null
  });

  getActionType = (text: string): string => `[${this.getEntry()}] - ${startCase(text.toLowerCase())}`;

  selectEntityAction = createAction(this.getActionType(EntityActionLabelEnum.SelectEntity), props<{
    selectedEntityId: string
  }>());

  loadEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.LoadEntities), props<{
    entities: BE[]
  }>());

  setEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.SetEntities), props<{
    entities: BE[]
  }>());

  addOrUpdateEntityAction = createAction(this.getActionType(EntityActionLabelEnum.AddOrUpdateEntity), props<{ entity: BE }>())

  addEntityAction = createAction(this.getActionType(EntityActionLabelEnum.AddEntity), props<{ entity: BE }>());

  setEntityAction = createAction(this.getActionType(EntityActionLabelEnum.SetEntity), props<{ entity: BE }>());

  upsertEntityAction = createAction(this.getActionType(EntityActionLabelEnum.UpsertEntity), props<{
    entity: BE
  }>());

  addEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.AddEntities), props<{
    entities: BE[]
  }>());

  upsertEntitiesAction = createAction(
    this.getActionType(EntityActionLabelEnum.UpsertEntities), props<{
      entities: BE[]
    }>()
  );

  updateEntityAction = createAction(
    this.getActionType(EntityActionLabelEnum.UpdateEntity),
    props<{
      update: Update<BE>
    }>()
  );

  updateEntitiesAction = createAction(
    this.getActionType(EntityActionLabelEnum.UpdateEntities),
    props<{
      updates: Update<BE>[]
    }>()
  );

  mapEntityAction = createAction(this.getActionType(EntityActionLabelEnum.MapEntity), props<{
    entityMap: EntityMapOne<BE>
  }>());

  mapEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.MapEntities), props<{
    entityMap: EntityMap<BE>
  }>());

  deleteEntityAction = createAction(this.getActionType(EntityActionLabelEnum.DeleteEntity), props<{
    id: string
  }>());

  deleteEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.DeleteEntities), props<{
    ids: string[]
  }>());

  deleteEntitiesByPredicateAction = createAction(
    this.getActionType(EntityActionLabelEnum.DeleteEntitiesByPredicate), props<{
      predicate: Predicate<BE>
    }>()
  );

  clearEntitiesAction = createAction(this.getActionType(EntityActionLabelEnum.ClearEntities));

  onUpdateEntityAction = on(this.updateEntityAction, (state: EntityStateExtra<BE>, {update}) => {
    return this.adapter.updateOne(update, state);
  });

  onAddEntityAction = on(this.addEntityAction, (state: EntityStateExtra<BE>, {entity}) => {
    return this.adapter.addOne(entity, state);
  });

  onSetEntityAction = on(this.setEntityAction, (state: EntityStateExtra<BE>, {entity}) => {
    return this.adapter.setOne(entity, state);
  });

  onSetEntitiesAction = on(this.setEntitiesAction, (state: EntityStateExtra<BE>, {entities}) => {
    return this.adapter.setMany(entities, state);
  });

  onUpsertEntityAction = on(this.upsertEntityAction, (state: EntityStateExtra<BE>, {entity}) => {
    return this.adapter.upsertOne(entity, state);
  });

  onAddEntitiesAction = on(this.addEntitiesAction, (state: EntityStateExtra<BE>, {entities}) => {
    return this.adapter.addMany(entities, state);
  });

  onUpsertEntitiesAction = on(this.upsertEntitiesAction, (state: EntityStateExtra<BE>, {entities}) => {
    return this.adapter.upsertMany(entities, state);
  });

  onUpdateEntitiesAction = on(this.updateEntitiesAction, (state: EntityStateExtra<BE>, {updates}) => {
    return this.adapter.updateMany(updates, state);
  });

  onAddOrUpdateEntityAction = on(this.addOrUpdateEntityAction, (state: EntityStateExtra<BE>, {entity}) => {
    const exists = state.entities[entity.id]

    return !exists ? this.adapter.addOne(entity, state) : this.adapter.updateOne({
      id: entity.id,
      changes: entity
    }, state)
  });

  onMapEntityAction = on(this.mapEntityAction, (state: EntityStateExtra<BE>, {entityMap}) => {
    return this.adapter.mapOne(entityMap, state);
  });

  onMapEntitiesAction = on(this.mapEntitiesAction, (state: EntityStateExtra<BE>, {entityMap}) => {
    return this.adapter.map(entityMap, state);
  });

  onDeleteEntityAction = on(this.deleteEntityAction, (state: EntityStateExtra<BE>, {id}) => {
    return this.adapter.removeOne(id, state);
  });

  onDeleteEntitiesAction = on(this.deleteEntitiesAction, (state: EntityStateExtra<BE>, {ids}) => {
    return this.adapter.removeMany(ids, state);
  });

  onDeleteEntitiesByPredicateAction = on(this.deleteEntitiesByPredicateAction, (state: EntityStateExtra<BE>, {predicate}) => {
    return this.adapter.removeMany(predicate, state);
  });

  onLoadEntitiesAction = on(this.loadEntitiesAction, (state: EntityStateExtra<BE>, {entities}) => {
    return this.adapter.setAll(entities, state);
  });

  onClearEntitiesAction = on(this.clearEntitiesAction, (state: EntityStateExtra<BE>) => {
    return this.adapter.removeAll({...state, selectedEntityId: null} as EntityStateExtra<BE>);
  });

  onSelectEntityAction = on(this.selectEntityAction, (state: EntityStateExtra<BE>, {selectedEntityId}) => {
    return {...state, selectedEntityId};
  });

  reducers: ReducerTypes<EntityStateExtra<BE>, ActionCreator[]>[] = [
    this.onAddEntityAction,
    this.onSetEntityAction,
    this.onSetEntitiesAction,
    this.onUpsertEntityAction,
    this.onAddEntitiesAction,
    this.onAddOrUpdateEntityAction,
    this.onUpsertEntitiesAction,
    this.onUpdateEntityAction,
    this.onUpdateEntitiesAction,
    this.onMapEntityAction,
    this.onMapEntitiesAction,
    this.onDeleteEntityAction,
    this.onDeleteEntitiesAction,
    this.onDeleteEntitiesByPredicateAction,
    this.onLoadEntitiesAction,
    this.onClearEntitiesAction,
    this.onSelectEntityAction,
  ];

  actionReducer = createReducer<EntityStateExtra<BE>>(
    this.initialState,
    ...this.reducers
  );

  feature = createFeature({
    name: this.getFeatureName(),
    reducer: this.actionReducer,
  });

  selectAllEntity = createSelector(
    this.selectIds,
    this.selectEntities,
    (ids: string[], entities: Dictionary<BE>) => ids.map((id) => entities[id]),
  );

  selectEntity = (id: string) => createSelector(
    this.selectEntities,
    (entities: Dictionary<BE>) => entities[id],
  );

  selectSelectedEntity = createSelector(
    this.selectEntities,
    this.selectedEntityId,
    (entities, id) => entities[id],
  );

  selectEntitiesByIds = (ids: string[]) => createSelector(
    this.selectEntities,
    (entities: Dictionary<BE>) => ids.map(id => entities[id]) as BE[],
  );

  get selectEntities () {
    return this.feature.selectEntities
  }

  get selectIds () {
    return this.feature.selectIds
  }

  get selectedEntityId () {
    return this.feature.selectSelectedEntityId
  }

  abstract get selectState (): SelectState<BE>;

  abstract getEntry (): EntityEntry<EntityFeature>

  abstract getFeatureName (): EntityFeatureName<EntityFeature>
}
