import { ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '@environment/environment';

export function storeDebugMiddleware (reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {

    if (!environment.production && environment.appStore?.debug) {
      console.log('state', state);
      console.log('action', action);

      return reducer(state, action);
    }
  };
}
