import { ModelFactory } from "@shared/factories/model.factory";
import { BusinessPlanType } from "@generated/graphql";
import { BusinessPlan } from "@modules/account-settings/pages/business-profile/models/business-plan.model";

export class BusinessPlanFactory extends ModelFactory<BusinessPlanType, BusinessPlan> {
  create (state: BusinessPlanType): BusinessPlan {
    return new BusinessPlan(state);
  }

  fake (): BusinessPlan {
    throw new Error('Method not implemented')
  }
}

export const businessPlanFactory = new BusinessPlanFactory()
