import { FirebaseApp } from '@firebase/app';
import { Database, ref, update } from '@firebase/database';
import { onValue } from 'firebase/database';
import { ConversationMetadata } from './message-type';

export default class ConversationMetadataManagement {
  private _roomId: string;
  public get roomId(): string {
    return this._roomId;
  }
  public set roomId(value: string) {
    this._roomId = value;
  }

  private _db: Database;
  public get db(): Database {
    return this._db;
  }
  public set db(value: Database) {
    this._db = value;
  }

  private _dbRefPath: string;
  public get dbRefPath(): string {
    return this._dbRefPath;
  }
  public set dbRefPath(value: string) {
    this._dbRefPath = value;
  }

  private _app: FirebaseApp;
  public get app(): FirebaseApp {
    return this._app;
  }
  public set app(value: FirebaseApp) {
    this._app = value;
  }

  private _data: ConversationMetadata;
  public get data(): ConversationMetadata {
    return this._data;
  }
  public set data(value: ConversationMetadata) {
    this._data = value;
  }

  public constructor() { }

  public async setLastMessageId(id: string) {
    const inputObj = {};

    inputObj[`${this.dbRefPath}/metadata/lastMessageId`] = id;

    await update(ref(this.db), inputObj);
  }

  public async getData() {
    return onValue(
      ref(this.db, `${this.dbRefPath}/metadata`),
      async (snapshot) => {
        if (snapshot.exists()) {
          this.data = snapshot.val();
        }
      },
    );
  }
}
