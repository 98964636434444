import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  AuthorizationQuery, AuthorizationResult,
  ImpersonateMutation, LeaveImpersonationMutation,
  LoginInput,
  LoginMutation,
  LogoutMutation,
  RegisterInput,
  RegisterMutation,
  ResendEmailVerificationMutation, ResetPasswordInput, ResetPasswordMutation,
  SendResetPasswordLinkMutation, UpdateUserPasswordInput, UpdateUserPasswordMutation,
  UserType,
  VerifyEmailInput,
  VerifyEmailMutation,
} from '@generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  constructor (
    protected readonly loginMutation: LoginMutation,
    protected readonly registerMutation: RegisterMutation,
    protected readonly resendEmailVerificationMutation: ResendEmailVerificationMutation,
    protected readonly verifyEmailMutation: VerifyEmailMutation,
    protected readonly sendResetPasswordLinkMutation: SendResetPasswordLinkMutation,
    protected readonly resetPasswordMutation: ResetPasswordMutation,
    protected readonly logoutMutation: LogoutMutation,
    protected readonly impersonateMutation: ImpersonateMutation,
    protected readonly leaveImpersonationMutation: LeaveImpersonationMutation,
    protected readonly authorizationQuery: AuthorizationQuery,
    protected readonly updateUserPasswordMutation: UpdateUserPasswordMutation,
  ) {
  }

  login (input: LoginInput): Observable<UserType> {
    const { email, password } = input;

    if (!email || !password) {
      throw new Error('Something wrong! Cant login!');
    }

    return this.loginMutation.mutate({ input }).pipe(map(res => res.data.login as UserType));
  }

  authorization (): Observable<AuthorizationResult> {
    return this.authorizationQuery.fetch(undefined, {
      fetchPolicy: 'no-cache',
    }).pipe(map(response => response.data));
  }

  register (input: RegisterInput): Observable<UserType> {
    return this.registerMutation
      .mutate({ input })
      .pipe(map(res => res.data.register as UserType));
  }

  resendEmailVerification (emailOrId: string): Observable<boolean> {
    return this.resendEmailVerificationMutation.mutate({ emailOrId }).pipe(
      map(res => res.data.resendEmailVerification),
    );
  }

  verifyEmail (input: VerifyEmailInput): Observable<UserType> {
    return this.verifyEmailMutation.mutate({ input }).pipe(
      map(res => res.data.verifyEmail as UserType),
    );
  }

  sendResetPasswordsLink (email: string): Observable<string> {
    return this.sendResetPasswordLinkMutation.mutate({ email }).pipe(
      map(res => res.data.sendResetPasswordLink as string),
    );
  }

  resetPassword (input: ResetPasswordInput): Observable<string> {
    return this.resetPasswordMutation.mutate({ input }).pipe(
      map(res => res.data.resetPassword as string),
    );
  }

  logout (): Observable<boolean> {
    return this.logoutMutation.mutate().pipe(
      map(res => res.data.logout as boolean),
    );
  }

  impersonate (id: string): Observable<UserType> {
    return this.impersonateMutation.mutate({ id }, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.impersonate as UserType),
    );
  }

  stopImpersonate (): Observable<boolean> {
    return this.leaveImpersonationMutation.mutate().pipe(
      map(res => res.data.leaveImpersonation as boolean),
    );
  }

  updateUserPassword (input: UpdateUserPasswordInput): Observable<UserType> {
    return this.updateUserPasswordMutation.mutate({ input }).pipe(map(res => res.data.updateUserPassword as UserType));
  }
}
