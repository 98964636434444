import { AppRoute, AppRoutes, appRoutes } from '@config/app-routes.config';
import { InjectionToken, Provider } from '@angular/core';
import { User } from '@modules/user/models/user.model';
import { cloneDeep } from 'lodash-es';
import { of } from 'rxjs';

export interface SideBarItem {
  divide?: boolean;
  name?: string;
  icon?: string;
  navigateTo?: AppRoute;
  items?: SideBarItem[];
  isHidden?: boolean;
}

export interface SidebarConfigContract {
  isOpenSidebar: boolean;
  visiblePayment?: boolean;

  dashboard: SideBarItem;
  payment: SideBarItem;
  job: SideBarItem;
  companies: SideBarItem;
  team: SideBarItem;
  customers: SideBarItem;
  registerCompany: SideBarItem;
  transferCompany: SideBarItem;
  overview: SideBarItem;
  incorporate: SideBarItem;

  menuSection: SideBarItem[];

  update(activeUser: User, visiblePayment?: boolean): SidebarConfigContract;
}

export class SidebarConfig implements SidebarConfigContract {
  private _dashboard: SideBarItem = {
    isHidden: false,
    icon: 'space_dashboard',
    name: 'Dashboard',
    navigateTo: this.appRoutes.dashboard,
  };

  private _payment: SideBarItem = {
    isHidden: false,
    icon: 'payment',
    name: 'Payments',
    navigateTo: this.appRoutes.payments,
  };

  private _job: SideBarItem = {
    isHidden: false,
    icon: 'work',
    name: 'Job Request',
    navigateTo: this.appRoutes.jobs,
  };
  private _companies: SideBarItem = {
    isHidden: false,
    icon: 'business',
    name: 'Companies',
    navigateTo: this.appRoutes.companies,
  };
  private _team: SideBarItem = {
    isHidden: false,
    icon: 'supervisor_account',
    name: 'Team',
    navigateTo: this.appRoutes.team,
  };

  private _customers: SideBarItem = {
    isHidden: false,
    icon: 'groups',
    name: 'Customers',
    navigateTo: this.appRoutes.customers,
  };

  private _registerCompany: SideBarItem = {
    isHidden: false,
    icon: 'domain_add',
    name: 'Register Company',
    navigateTo: this.appRoutes.registerCompany,
  };

  private _transferCompany: SideBarItem = {
    isHidden: false,
    icon: 'business',
    name: 'Transfer Company',
    navigateTo: this.appRoutes.transferCompany,
  };

  private _menuSection: SideBarItem[] = [];
  private _incorporate: SideBarItem = {
    name: 'Incorporate',
    isHidden: false,
    items: [],
  };
  private _overview: SideBarItem = {
    name: 'Overview',
    isHidden: false,
    items: [],
  };
  private _visiblePayment: boolean = false;
  private _isOpenSidebar: boolean = true;

  get isOpenSidebar(): boolean {
    return this._isOpenSidebar;
  }

  set isOpenSidebar(value: boolean) {
    this._isOpenSidebar = value;
  }

  get visiblePayment(): boolean {
    return this._visiblePayment;
  }

  set visiblePayment(value: boolean) {
    this._visiblePayment = value;
  }

  get payment(): SideBarItem {
    return this._payment;
  }

  set payment(value: SideBarItem) {
    this._payment = value;
  }

  get dashboard(): SideBarItem {
    return this._dashboard;
  }

  set dashboard(value: SideBarItem) {
    this._dashboard = value;
  }

  get job(): SideBarItem {
    return this._job;
  }

  set job(value: SideBarItem) {
    this._job = value;
  }

  get companies(): SideBarItem {
    return this._companies;
  }

  set companies(value: SideBarItem) {
    this._companies = value;
  }

  get team(): SideBarItem {
    return this._team;
  }

  set team(value: SideBarItem) {
    this._team = value;
  }

  get customers(): SideBarItem {
    return this._customers;
  }

  set customers(value: SideBarItem) {
    this._customers = value;
  }

  get transferCompany(): SideBarItem {
    return this._transferCompany;
  }

  set transferCompany(value: SideBarItem) {
    this._transferCompany = value;
  }

  get registerCompany(): SideBarItem {
    return this._registerCompany;
  }

  set registerCompany(value: SideBarItem) {
    this._registerCompany = value;
  }

  constructor(protected readonly appRoutes: AppRoutes) {
    this.setUp();
  }

  get overview(): SideBarItem {
    return this._overview;
  }

  set overview(item: SideBarItem) {
    this._overview = item;
  }

  get incorporate(): SideBarItem {
    return this._incorporate;
  }

  set incorporate(item: SideBarItem) {
    this._incorporate = item;
  }

  get menuSection(): SideBarItem[] {
    return this._menuSection;
  }

  set menuSection(items: SideBarItem[]) {
    this._menuSection = items;
  }

  setUp(): void {
    this.incorporate = cloneDeep({
      ...this.incorporate,
      items: [this.registerCompany, this.transferCompany],
    });

    this.overview = cloneDeep({
      ...this.overview,
      items: [
        this.dashboard,
        this.payment,
        this.job,
        this.companies,
        this.team,
        this.customers,
      ],
    });

    this.menuSection = cloneDeep([this.overview, this.incorporate]);
  }

  update(activeUser: User, visiblePayment: boolean = false): SidebarConfig {
    this._visiblePayment = visiblePayment;

    /**
     * Write logic when user hasn't logged in here
     */
    if (!activeUser) {
      /**
       * Hide all items if user hasn't logged in
       */
      this.menuSection = [
        ...this.menuSection.map((section) => ({
          ...section,
          isHidden: true,
        })),
      ];

      return this;
    }

    /**
     * Write logic when user has logged in here.
     */

    //HIDDEN ITEMS
    this.team = {
      ...this.team,
      isHidden: activeUser.isExpert() || activeUser.isCustomer(),
    };

    this.customers = {
      ...this.customers,
      isHidden: activeUser.isCustomer(),
    };

    this.payment = {
      ...this.payment,
      isHidden: activeUser.isExpert() || !this._visiblePayment,
    };

    this.incorporate = {
      ...this.incorporate,
      // isHidden: activeUser.isSuperAdmin() || activeUser.isAdmin()
    };

    // MODIFY LABEL
    this.job = {
      ...this.job,
      name: activeUser.isCustomer() ? 'My Request' : 'Job Request',
    };

    this.payment = {
      ...this.payment,
      name: activeUser.isCustomer() ? 'My Payments' : 'Payments',
    };

    this.companies = {
      ...this.companies,
      name: activeUser.isCustomer() ? 'My Companies' : 'Companies',
    };

    //DIVIDER
    this.overview = {
      ...this.overview,
      divide: activeUser.isExpert() || activeUser.isCustomer(),
    };

    this.setUp();

    return this;
  }
}

export const sideBarConfig = new SidebarConfig(appRoutes);

export const APP_SIDEBAR = new InjectionToken('APP_SIDEBAR');

export const APP_SIDEBAR_CONFIG: Provider = {
  provide: APP_SIDEBAR,
  useValue: of(sideBarConfig),
};
