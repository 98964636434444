import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShareholdingDetailsFormType } from '@app/modules/job/models/form/shareholder-form-type';
import { ShareDistributionFormType } from '@app/modules/share-distribution/models/form/share-distributions-form-type';
import {
  OfficerCompanyInput,
  OfficerPersonInput,
  ShareholdingDetailInput,
} from '@generated/graphql';

export abstract class AbstractOfficerForm {
  fb: FormBuilder;
  officerForm: FormGroup;

  shareDistributionForm: FormGroup<ShareDistributionFormType>;

  constructor() {
    this.fb = new FormBuilder();
  }

  createShareDistributionsForm(): void {
    this.shareDistributionForm = this.fb.group<ShareDistributionFormType>({
      shareDistributions: new FormArray<FormGroup<ShareholdingDetailsFormType>>(
        [],
      ),
    });
  }

  setShareDistribution(shareholding: ShareholdingDetailInput[]): void {
    const arrayControls = this.shareDistributionForm.controls
      .shareDistributions as FormArray;
    arrayControls.setValue([]);

    shareholding.forEach((s) =>
      arrayControls.push(
        this.fb.group({
          shareTypeId: s.shareTypeId,
          currency: s.currency,
          numberOfShares: s.numberOfShares,
        }),
      ),
    );
  }

  abstract createOfficerForm(): void;

  abstract setEditData(
    formData: OfficerCompanyInput | OfficerPersonInput,
    shareholding: ShareholdingDetailInput[],
  ): void;
}
