import { Component, Input, OnInit } from '@angular/core';
import { CountryService } from '@modules/country/services/country.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Observable } from "rxjs";
import { Country } from "@modules/country/models/country.model";

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-country-flag[id]',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent implements OnInit {
  @Input() id: string;
  private _suffixIcon: boolean = false;
  private _showCountryName: boolean = false;
  country$: Observable<Country>

  @Input()
  get showCountryName (): boolean {
    return this._showCountryName;
  }

  set showCountryName (value: BooleanInput) {
    this._showCountryName = coerceBooleanProperty(value);
  }

  @Input()
  get suffixIcon (): boolean {
    return this._suffixIcon;
  }

  set suffixIcon (value: BooleanInput) {
    this._suffixIcon = coerceBooleanProperty(value);
  }

  constructor (
    private readonly countryService: CountryService,
  ) {
  }

  ngOnInit (): void {
    this.country$ = this.countryService.find$(this.id)
  }
}
