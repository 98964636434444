import { ModelFactory } from "@shared/factories/model.factory";
import { Officer } from "@modules/officer/models/officer.model";
import { OfficerType } from "@generated/graphql";

export class OfficerFactory extends ModelFactory<OfficerType, Officer> {
  create (state: OfficerType): Officer {
    return new Officer(state);
  }

  fake (): Officer {
    throw new Error('This method is not implemented')
  }
}

export const officerFactory = new OfficerFactory()
