import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { cloneDeep } from "lodash-es";
import { PaymentMethodsData } from "@shared/component/payments/components/payment-methods/payment-methods.type";

@Injectable()
export class PaymentMethodsService {
  private readonly _data$ = new BehaviorSubject<PaymentMethodsData>(null)

  get data (): PaymentMethodsData {
    return this._data$.value
  }

  get data$ (): Observable<PaymentMethodsData> {
    return this._data$.asObservable()
  }

  constructor () {
  }

  setData (data: PaymentMethodsData) {
    this._data$.next(cloneDeep(data))
  }
}
