<mat-dialog-content class="min-h-fit">
  <app-extra-job [extraJob]="extraJob"></app-extra-job>
</mat-dialog-content>

<mat-dialog-actions class="!pt-1" align="end">
  <button
    (click)="close()"
    mat-raised-button
    class="bg-grey-800 text-white">Close
  </button>
</mat-dialog-actions>
