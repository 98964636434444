import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  BankAccountDialogComponent,
  WireTransferBankAccountControls
} from "@shared/component/payments/dialogs/bank-account-dialog/bank-account-dialog.component";
import { UpdateWireTransferBankInput } from "@generated/graphql";
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { COMMON_SERVICE_CONFIG } from "@shared/providers/common-service.provider";
import { CommonService } from "@shared/services/common.service";
import { SupportedCountryService } from "@modules/supported-country/services/supported-country.service";
import { WireTransferBankApi } from "@modules/wire-transfer-bank/api/wire-transfer-bank.api";
import { Observable, tap } from "rxjs";
import { Validators } from "@config/validators.config";
import { FormControl, FormGroup } from "@angular/forms";
import { cloneDeep } from "lodash-es";

export interface UpdateWireTransferBankAccountControls extends WireTransferBankAccountControls {
  id: FormControl<string>
}

export interface UpdateBankAccountDialogData {
  item: WireTransferBank
}


@Component({
  providers: [COMMON_SERVICE_CONFIG],
  selector: 'app-update-bank-account-dialog',
  templateUrl: './update-bank-account-dialog.component.html',
  styleUrls: ['./update-bank-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UpdateBankAccountDialogComponent extends BankAccountDialogComponent<
  UpdateWireTransferBankInput,
  UpdateWireTransferBankAccountControls,
  WireTransferBank
> implements OnInit {
  limit: number = 1;

  private _item: WireTransferBank

  get item (): WireTransferBank {
    return this._item;
  }

  set item (value: WireTransferBank) {
    this._item = value;
  }

  constructor (
    public readonly matDialogRef: MatDialogRef<UpdateBankAccountDialogComponent, WireTransferBank>,
    public readonly commonService: CommonService,
    protected readonly supportedCountryService: SupportedCountryService,
    protected readonly wireTransferBankApi: WireTransferBankApi,
    @Inject(MAT_DIALOG_DATA) protected readonly data: UpdateBankAccountDialogData,
  ) {
    super(matDialogRef, commonService, supportedCountryService)
  }

  ngOnInit (): void {
    super.ngOnInit()

    this.item = cloneDeep(this.data.item)

    if (this.form instanceof FormGroup) {
      this.form.patchValue({
        ...this.item,
        countryId: [this.item.countryId]
      })
    }
  }

  getControls (): UpdateWireTransferBankAccountControls {
    return {
      ...super.getControls(),
      id: this.commonService.fb.control('', [Validators.required])
    }
  }

  getInput (): UpdateWireTransferBankInput {
    const input = this.form.value

    return {
      ...input,
      countryId: input.countryId?.[0] ?? null,
    } as UpdateWireTransferBankInput;
  }

  query (input: UpdateWireTransferBankInput): Observable<WireTransferBank> {
    return this.wireTransferBankApi.update$(this.getInput()).pipe(
      tap(result => {
        if (result) {
          this.commonService
            .snackBarService
            .pushUpdateSuccessMessage()

          return;
        }

        this.commonService
          .snackBarService
          .pushErrorMessage()
      }),
    );
  }
}
