<div class="capital-input-summarize-wrapper">
  <div *ngIf="capitalListData$ | async as capitalListData" class="table-container">
    <table *ngFor="let data of capitalListData" class="mat-table cdk-table table-custom mb-6 mat-elevation-z1">
      <!--HEADER SECTION-->
      <thead>
      <tr class="mat-header-row bg-primary-800">
        <th class="mat-header-cell !text-white" [colSpan]="displayedColumns.length">
          Currency&colon;&nbsp;
          <span>{{ data.currency }}</span>
        </th>
      </tr>
      <tr class="mat-header-row bg-primary-800">
        <th class="mat-header-cell !text-white cdk-header-row">Share type</th>
        <th class="mat-header-cell !text-center !text-white cdk-header-row">
          Details
        </th>
        <th class="mat-header-cell !text-center !text-white cdk-header-row">
          Before
        </th>
        <th class="mat-header-cell !text-center !text-white cdk-header-row">
          After
        </th>
      </tr>
      </thead>
      <!--BODY SECTION-->
      <tbody>
      <ng-container *ngFor="let share of data.shareTypes">
        <tr class="mat-row cdk-row">
          <td rowspan="2" class="mat-cell">
              <span class="mat-subheading-1 font-medium capitalize !text-sub">{{
                share.shareType.toLowerCase()
                }}</span>
          </td>
          <td class="mat-cell cdk-cell">
            {{ share.details[0].description }}
          </td>
          <td class="mat-cell cdk-cell text-center">
            {{
            share.details[0].beforeCapitalIncrease
              ? (share.details[0].beforeCapitalIncrease | number)
              : "-"
            }}
          </td>
          <td class="mat-cell cdk-cell text-center">
            {{
            share.details[0].afterCapitalIncrease
              ? (share.details[0].afterCapitalIncrease | number)
              : "Not changed"
            }}
          </td>
        </tr>
        <tr class="mat-row cdk-row">
          <td class="mat-cell cdk-cell !pl-[1rem]">
            {{ share.details[1].description }}
          </td>
          <td class="mat-cell cdk-cell text-center">
            {{
            share.details[1].beforeCapitalIncrease
              ? (share.details[1].beforeCapitalIncrease |number)
              : "-"
            }}
          </td>
          <td class="mat-cell cdk-cell text-center">
            {{
            share.details[1].afterCapitalIncrease
              ? (share.details[1].afterCapitalIncrease |number)
              : "Not changed"
            }}
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
