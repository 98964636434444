import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CompanyCapitalInput, ShareTypeType } from '@generated/graphql';
import { ShareTypeService } from '@modules/share-type/services/share-type.service';
import { CommonHelper } from '@shared/helpers/common.helper';

@Component({
  standalone: true,
  selector: 'app-company-capital-input-review',
  templateUrl: './company-capital-input-review.component.html',
  imports: [CommonModule, MatTableModule, MatIconModule],
})
export class CompanyCapitalInputReviewComponent implements OnInit {
  public dataSource: MatTableDataSource<any>;

  private _companyCapitalInputs: CompanyCapitalInput[];
  @Input() set companyCapitalInputs(capitals: CompanyCapitalInput[]) {
    this._companyCapitalInputs = capitals;

    this.dataSource = new MatTableDataSource(this._companyCapitalInputs);
  }

  public shareTypes: ShareTypeType[];

  public companyCapitalColumns: string[] = [
    'amount',
    'numberOfShares',
    'currency',
    'shareType',
  ];

  constructor(
    private shareTypeService: ShareTypeService,
    public readonly commonHelper: CommonHelper,
  ) { }

  ngOnInit(): void {
    this.shareTypeService
      .getAll()
      .subscribe((shareTypes) => (this.shareTypes = shareTypes));
  }

  public getShareTypeName(id: string): string {
    const shareType = this.shareTypes.find((t) => t.id === id);

    if (!shareType) {
      return '';
    }

    return shareType.name;
  }
}
