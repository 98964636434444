import { BusinessProfileType } from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';
import { BusinessExternalLink } from 'src/app/modules/account-settings/pages/business-profile/models/business-external-link.model';

export class BusinessProfile
  extends BaseModel
  implements BusinessProfileType {
  email?: string;
  favicon?: string;
  faviconUrl?: string;
  headerTitle?: string;
  id: string;
  logo?: string;
  logoUrl?: string;
  name?: string;
  phone?: string;
  website?: string;
  externalLinks?: BusinessExternalLink[];

  constructor(state: BusinessProfileType) {
    super(state);
  }

  getModelName(): string {
    return BusinessProfile.name;
  }
}
