import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {Observable, tap} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  CompanyAuditorInput,
  CompanySecretaryInput,
  OfficerTypeEnum,
  UpdateCompanyAuditorInput,
  UpdateCompanySecretaryInput
} from '@generated/graphql';
import {officerFormMapper} from '@shared/classes/officer/officer-form-factory';
import {cloneDeep, startCase} from "lodash-es";
import {CommonService} from "@shared/services/common.service";
import {COMMON_SERVICE_CONFIG} from "@shared/providers/common-service.provider";
import {Company} from "@modules/company/models/company.model";
import {SupportedCountryService} from "@modules/supported-country/services/supported-country.service";
import {SupportedCountry} from "@modules/supported-country/models/supported-country.model";
import {CompanyEventService} from "@modules/company/services/company-event.service";
import {OfficerIndividualForm} from "@shared/classes/officer/officer-individual-form.class";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {OfficerIndividualFormType} from "@modules/officer/models/form/officer-individual-form-type";
import {hasMatDatePickerError, Validators} from "@config/validators.config";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {InvalidControlScrollDirective} from "@shared/share-directives/directives/invalid-control-scroll.directive";
import {CompanySecretary} from "@modules/company/models/company-secretary.model";
import {OfficerPerson} from "@modules/officer/models/officer-person.model";
import {formatDateTime, maxDate, minDate} from "@shared/helpers/date-time.helpers";
import {CompanyAuditor} from "@modules/company/models/company-auditor.model";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

export type AuthorizedOfficerDialogData = {
  officerType: OfficerTypeEnum.Auditor | OfficerTypeEnum.Secretary,
  company: Company
  item?: CompanySecretary | CompanyAuditor
}

export type CompanySecretaryForm = FormGroup<{
  companyId: FormControl<string>
  dateFrom: FormControl<Date>
  dateTo: FormControl<Date>
  officer: FormGroup<OfficerIndividualFormType>
}>

export type AuditorForm = FormGroup<{
  companyId: FormControl<string>
  dateFrom: FormControl<Date>
  dateTo: FormControl<Date>
  name: FormControl<string>
}>

@UntilDestroy()
@Component({
  providers: [COMMON_SERVICE_CONFIG],
  selector: 'app-authorized-officer-dialog',
  templateUrl: './authorized-officer-dialog.component.html',
  styleUrls: ['./authorized-officer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedOfficerDialogComponent implements OnInit {
  static width = '40%';

  @ViewChild(InvalidControlScrollDirective) scrollEl: InvalidControlScrollDirective;

  officerIndividualForm: OfficerIndividualForm;

  minDate = minDate

  maxDate = maxDate

  readonly OfficerTypeEnum = OfficerTypeEnum

  private _form: CompanySecretaryForm | AuditorForm

  private _companySecretaryForm: CompanySecretaryForm

  private _auditorForm: AuditorForm

  get minDateOfDateTo(): Date {
    if (this.form) {
      const minDate = cloneDeep(this.form.value.dateFrom)

      minDate.setDate(minDate.getDate() + 1)

      return minDate
    }

    return null;
  }

  get form(): CompanySecretaryForm | AuditorForm {
    return this._form;
  }

  set form(value: CompanySecretaryForm | AuditorForm) {
    this._form = value;
  }

  get companySecretaryForm(): CompanySecretaryForm {
    return this._companySecretaryForm;
  }

  set companySecretaryForm(value: CompanySecretaryForm) {
    this.form = value

    this._companySecretaryForm = value;
  }

  get auditorForm(): AuditorForm {
    return this._auditorForm;
  }

  set auditorForm(value: AuditorForm) {
    this.form = value

    this._auditorForm = value;
  }

  get supportedCountry$(): Observable<SupportedCountry> {
    return this.supportedCountryService.findByCountryId$(this.data.company.country.id)
  }

  get officerType(): OfficerTypeEnum.Auditor | OfficerTypeEnum.Secretary {
    return this.data.officerType
  }

  get company(): Company {
    return this.data.company
  }

  get item(): AuthorizedOfficerDialogData['item'] {
    return this.data.item
  }

  get action(): string {
    return this.item ? 'Update' : 'Add'
  }

  get label(): string {
    return [
      this.action,
      this.officerTypeLabel,
    ].join(' ')
  }

  get officerTypeLabel(): string {
    return startCase(this.officerType.toLowerCase())
  }

  constructor(
    public readonly commonService: CommonService,
    @Inject(ACTIVE_USER) public readonly activeUser$: Observable<User>,
    @Inject(MAT_DIALOG_DATA) public data: AuthorizedOfficerDialogData,
    protected readonly supportedCountryService: SupportedCountryService,
    protected readonly dialogRef: MatDialogRef<AuthorizedOfficerDialogComponent>,
    private readonly companyEventService: CompanyEventService,
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.createForm();

    this.companyEventService
      .onCompanySecretaryCreatedSuccess
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushAlert('You\'ve successfully added company secretary', null, SnackBarTypeEnum.success)


          this.dialogRef.close()

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanySecretaryCreatedError
      .pipe(
        untilDestroyed(this),
        tap(errors => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushErrorMessage()

          if (errors) {
            this.commonService
              .errorService
              .getValidationErrorObjectList(errors)
              .setScrollEl(this.scrollEl)
              .showErrors(this.form, 'input')
          }

          this.cdr.markForCheck()
        })
      ).subscribe()


    this.companyEventService
      .onCompanySecretaryUpdatedSuccess
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushUpdateSuccessMessage()

          this.dialogRef.close()

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanySecretaryUpdatedError
      .pipe(
        untilDestroyed(this),
        tap(errors => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushErrorMessage()

          if (errors) {
            this.commonService
              .errorService
              .getValidationErrorObjectList(errors)
              .setScrollEl(this.scrollEl)
              .showErrors(this.form, 'input')
          }

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanyAuditorCreatedSuccess
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushAlert('You\'ve successfully added company auditor', null, SnackBarTypeEnum.success)

          this.dialogRef.close()

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanyAuditorCreatedError
      .pipe(
        untilDestroyed(this),
        tap(errors => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushErrorMessage()

          if (errors) {
            this.commonService
              .errorService
              .getValidationErrorObjectList(errors)
              .setScrollEl(this.scrollEl)
              .showErrors(this.form, 'input')
          }

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanyAuditorUpdatedSuccess
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushUpdateSuccessMessage()

          this.dialogRef.close()

          this.cdr.markForCheck()
        })
      ).subscribe()

    this.companyEventService
      .onCompanyAuditorUpdatedError
      .pipe(
        untilDestroyed(this),
        tap(errors => {
          this.commonService.setLoading(false)

          this.commonService.snackBarService.pushErrorMessage()

          if (errors) {
            this.commonService
              .errorService
              .getValidationErrorObjectList(errors)
              .setScrollEl(this.scrollEl)
              .showErrors(this.form, 'input')
          }

          this.cdr.markForCheck()
        })
      ).subscribe()
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    switch (this.officerType) {
      case OfficerTypeEnum.Secretary: {
        if (this.officerIndividualForm instanceof OfficerIndividualForm) {
          this.commonService.setLoading(true)

          /**
           * Update CompanySecretary
           */
          if (this.item instanceof CompanySecretary) {
            const {id} = this.item

            this.companyEventService
              .onCompanySecretaryUpdate
              .next({
                input: {
                  ...this.companySecretaryForm.value,
                  dateFrom: formatDateTime(this.form.value.dateFrom),
                  dateTo: this.form.value.dateTo ? formatDateTime(this.form.value.dateTo) : null,
                  id
                } as UpdateCompanySecretaryInput
              })

            return;
          }

          /**
           * Create CompanySecretary
           */
          this.companyEventService
            .onCompanySecretaryCreate
            .next({
              input: {
                ...this.companySecretaryForm.value,
                dateFrom: formatDateTime(this.form.value.dateFrom),
                dateTo: this.form.value.dateTo ? formatDateTime(this.form.value.dateTo) : null,
              } as CompanySecretaryInput,
            })
        }

        break;
      }
      case OfficerTypeEnum.Auditor: {
        /**
         * Update CompanyAuditor
         */
        if (this.item instanceof CompanyAuditor) {
          const {id} = this.item

          this.companyEventService
            .onCompanyAuditorUpdate
            .next({
              input: {
                ...this.auditorForm.value,
                dateFrom: formatDateTime(this.form.value.dateFrom),
                dateTo: this.form.value.dateTo ? formatDateTime(this.form.value.dateTo) : null,
                id
              } as UpdateCompanyAuditorInput
            })

          return
        }

        /**
         * Create CompanyAuditor
         */
        this.companyEventService
          .onCompanyAuditorCreate
          .next({
            input: {
              ...this.auditorForm.value,
              dateFrom: formatDateTime(this.form.value.dateFrom),
              dateTo: this.form.value.dateTo ? formatDateTime(this.form.value.dateTo) : null,
            } as CompanyAuditorInput,
          })

        break;
      }
      default: {
        console.error(`We don't support this case to submit, Please check data again!`)
      }
    }
  }

  hasMatPickerError(control: AbstractControl) {
    return hasMatDatePickerError(control)
  }

  private createForm() {
    const {fb} = this.commonService

    switch (this.officerType) {
      case OfficerTypeEnum.Secretary: {
        this.officerIndividualForm = officerFormMapper[this.officerType]();

        this.officerIndividualForm.createOfficerForm()

        const {officerForm} = this.officerIndividualForm

        this.companySecretaryForm = fb.group({
          companyId: fb.control(this.company.id, [Validators.required]),
          dateFrom: fb.control(new Date(Date.now()), [Validators.required]),
          dateTo: fb.control(null),
          officer: officerForm
        })

        this.companySecretaryForm
          .controls
          .officer
          .addValidators([Validators.required])

        this.companySecretaryForm.updateValueAndValidity()

        if (
          this.item instanceof CompanySecretary &&
          this.item.officer.officerProfile instanceof OfficerPerson
        ) {
          this.patchFormValueByCompanySecretary(this.item)
        }

        break;
      }
      case OfficerTypeEnum.Auditor: {
        this.auditorForm = fb.group({
          name: fb.control('', [Validators.required]),
          companyId: fb.control(this.company.id, [Validators.required]),
          dateFrom: fb.control(new Date(Date.now()), [Validators.required]),
          dateTo: fb.control(null),
        })

        if (this.item instanceof CompanyAuditor) {
          this.patchFormValueByCompanyAuditor(this.item)
        }

        break;
      }
      default: {
        this.dialogRef.close()

        throw new Error(`Can't initialize form`)
      }
    }

    this.cdr.detectChanges()
  }

  private patchFormValueByCompanySecretary(item: CompanySecretary): void {
    const officerProfile = cloneDeep(item.officer.officerProfile) as OfficerPerson

    const {address, identification} = officerProfile

    const {nationality, officerIdentificationType} = identification

    this.form.patchValue({
      ...this.item,
      officer: {
        ...officerProfile,
        ...address,
        countryId: address?.country?.id,
        identification: {
          ...identification,
          expiryDate: identification.expiryDate ? new Date(identification.expiryDate) : null,
          officerIdentificationTypeId: officerIdentificationType.id,
          nationalityId: nationality.id,
        },
      }
    })

    this.form.markAllAsTouched()

    this.cdr.markForCheck()
  }

  private patchFormValueByCompanyAuditor(item: CompanyAuditor): void {
    this.form.patchValue({...item})

    this.form.markAllAsTouched()

    this.cdr.markForCheck()
  }
}
