import { ModelFactory } from '@shared/factories/model.factory';
import { PaymentDetailType } from '@generated/graphql';
import { PaymentDetail } from '@modules/payment/models/payment-detail.model';

export class PaymentDetailFactory extends ModelFactory<PaymentDetailType, PaymentDetail> {
  create (state: PaymentDetailType): PaymentDetail {
    return new PaymentDetail(state);
  }

  fake (): PaymentDetail {
    throw new Error('Method not implemented yet!');
  }
}

export const paymentDetailFactory = new PaymentDetailFactory();
