<div
  *ngrxLet="{
    unreadCount: unreadCount$ | async,
    notifications: notifications$ | async
  } as context"
  class="notifications-wrapper"
>
  <button mat-icon-button [matMenuTriggerFor]="notificationsList">
    <mat-icon
      class="text-grey-900"
      [matBadge]="context.unreadCount"
      matBadgeColor="warn"
      [matBadgeHidden]="context.unreadCount === 0"
      >notifications
    </mat-icon>
  </button>
  <mat-menu #notificationsList="matMenu">
    <div class="notifications-container w-[38rem] max-w-[42rem]">
      <div class="inline-flex w-full items-center justify-between mb-2 px-4">
        <p class="mat-title !text-xbase text-darkGray !mb-0">Notifications</p>
        <button
          (click)="markReadAll($event)"
          mat-button
          color="primary"
          disableRipple
          class="text-sub"
          [disabled]="markingReadAll$ | async"
        >
          Mark all as read
        </button>
      </div>

      <div *ngIf="context.notifications.length === 0" class="w-full px-4 py-2">
        <div class="flex flex-col justify-center items-center">
          <img
            src="assets/images/icons/noti-empty.svg"
            alt="No Notification"
            class="object-contain !w-52 !h-52"
          />
          <p class="mat-title text-darkGray text-center !mb-2">
            No Notifications
          </p>
          <p class="text-sub text-lightGray text-center !mb-0">
            Clutter cleared! We'll notify you when there is something new
          </p>
        </div>
      </div>

      <ng-container *ngIf="context.notifications.length">
        <div
          *ngFor="let log of context.notifications"
          class="notification-item w-full px-4 py-2 cursor-pointer"
          [ngClass]="{ unread: !log.isRead }"
          (click)="showDetail(log)"
        >
          <div class="notification-item-icon mr-2">
            <img [src]="getNotificationIcon(log)" alt="notification type" />
          </div>
          <div class="notification-item-message mr-2">
            <p class="text-sub !mb-0 text-darkGray line-clamp-4">
              {{ log.description }}
            </p>
            <div class="notification-item-time">
              <p class="text-sm text-lightGray !mb-0">
                {{
                  (log.createdAt.includes("Z")
                    ? log.createdAt
                    : log.createdAt + "Z"
                  ) | date: "MMM dd, yyyy 'at' HH:mm a"
                }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-menu>
</div>
