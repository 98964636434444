import {BaseModel} from "@shared/models/base.model";

import {CountryType, OfficerType, OfficerTypeEnum} from "@generated/graphql";
import {OfficerDocument} from "@modules/officer/models/officer-document.model";
import {officerDocumentFactory} from "@modules/officer/factories/officer-document.factory";
import {OfficerProfile} from "@modules/officer/models/officer-profile.model";
import {officerProfileFactory} from "@modules/officer/factories/officer-profile.factory";
import {Country} from "@modules/country/models/country.model";

export class Officer extends BaseModel implements OfficerType {
  id: string;
  officerProfile: OfficerProfile
  officerDocuments: OfficerDocument[];
  officerType: OfficerTypeEnum;

  constructor(state: OfficerType) {
    super(state);

    this.officerProfile = officerProfileFactory.tryCreating(state.officerProfile)

    this.officerDocuments = officerDocumentFactory.createEntities(state.officerDocuments);
  }

  get identificationNumber(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return `${this.officerProfile?.identification?.officerIdentificationType.name} - ${this.officerProfile?.identification?.identificationNumber}`;
      }
      case 'OfficerIndirectPersonType': {
        return ''
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.registrationNumber;
      }
      case 'OfficerIndirectCompanyType': {
        return ''
      }
    }
  }

  get officerIDNumber(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.identification?.identificationNumber;
      }
      case 'OfficerIndirectPersonType': {
        return ''
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.registrationNumber;
      }
      case 'OfficerIndirectCompanyType': {
        return ''
      }
    }
  }


  get isIndividual(): boolean {
    return this.officerType === OfficerTypeEnum.Individual
  }

  get isCorporate(): boolean {
    return this.officerType === OfficerTypeEnum.Corporate
  }

  get name(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.fullName
      }
      case 'OfficerIndirectPersonType': {
        return this.officerProfile?.fullName
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.name;
      }
      case 'OfficerIndirectCompanyType': {
        return this.officerProfile?.name
      }
    }
  }

  get officerEmail(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.email
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.email
      }
      default: {
        return ''
      }
    }
  }


  get officerCountry(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.address?.country?.name
      }
      case 'OfficerIndirectPersonType': {
        return this.officerProfile?.nationality?.name
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.country?.name;
      }
      case 'OfficerIndirectCompanyType': {
        return this.officerProfile?.country?.name
      }
    }
  }

  get officerKYCResidenceCountry(): string {
    // return this.officerProfile instanceof OfficerPerson
    //   ? this.officerProfile?.address?.country?.name
    //   : this.officerProfile?.country?.name;

    // TODO temp fix
    return this.officerResidenceCountry?.name
  }

  get officerNationalCountry(): Country | CountryType {
    // return this.officerProfile instanceof OfficerPerson
    //   ? this.officerProfile?.identification?.nationality
    //   : this.officerProfile?.country;

    // TODO temp fix
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.identification?.nationality
      }
      case 'OfficerIndirectPersonType': {
        return this.officerProfile?.nationality
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.country;
      }
      case 'OfficerIndirectCompanyType': {
        return this.officerProfile?.country
      }
    }
  }

  get officerResidenceCountry(): Country | CountryType {
    // TODO temp fix
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return this.officerProfile?.address?.country
      }
      case 'OfficerIndirectPersonType': {
        return this.officerProfile?.country
      }
      case 'OfficerCompanyType': {
        return this.officerProfile?.country;
      }
      case 'OfficerIndirectCompanyType': {
        return this.officerProfile?.country
      }
    }
  }

  /**
   * We use angular material icon
   */
  get icon(): string {
    switch (this.officerProfile.__typename) {
      case 'OfficerPersonType': {
        return 'person'
      }
      case 'OfficerIndirectPersonType': {
        return 'person'
      }
      case 'OfficerCompanyType': {
        return 'apartment'
      }
      case 'OfficerIndirectCompanyType': {
        return 'apartment'
      }
    }
  }

  getModelName(): string {
    return Officer.name;
  }
}
