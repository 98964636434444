import { Inject, Injectable } from '@angular/core';
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { WireTransferBank } from "@modules/wire-transfer-bank/models/wire-transfer-bank.model";
import { WireTransferBankType } from "@generated/graphql";
import { Store } from "@ngrx/store";
import {
  WIRE_TRANSFER_BANK_STORE,
  WireTransferBankStore
} from "@modules/wire-transfer-bank/store/wire-transfer-bank.store";
import {
  wireTransferBankFactory,
  WireTransferBankFactory
} from "@modules/wire-transfer-bank/factories/wire-transfer-bank.factory";
import { Country } from "@modules/country/models/country.model";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WireTransferBankService extends EntityService<
  EntityFeatureNameEnum.WireTransferBank,
  WireTransferBankType,
  WireTransferBank
> {
  constructor (
    protected readonly store: Store,
    @Inject(WIRE_TRANSFER_BANK_STORE) protected readonly entityStore: WireTransferBankStore
  ) {
    super(store, entityStore)
  }

  get factory (): WireTransferBankFactory {
    return wireTransferBankFactory;
  }

  getByCountryId$ (id: Country['id']): Observable<WireTransferBank[]> {
    return this.entities$.pipe(
      map(items => items.filter(item => item.countryId === id || item.country.id === id))
    )
  }
}
