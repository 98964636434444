import { Inject, Injectable } from '@angular/core';
import { Order } from "@modules/order/models/order.model";
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { OrderType } from "@generated/graphql";
import { ORDER_STORE, OrderStore } from "@modules/order/store/order.store";
import { Store } from "@ngrx/store";
import { orderFactory, OrderFactory } from "@modules/order/factories/order.factory";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends EntityService<EntityFeatureNameEnum.Order, OrderType, Order> {
  get returnUrl$ (): Observable<string> {
    return this._returnUrl$.asObservable()
  }

  private readonly _returnUrl$ = new BehaviorSubject<string>(window.location.origin)

  constructor (
    protected readonly store: Store,
    @Inject(ORDER_STORE) protected readonly entityStore: OrderStore,
  ) {
    super(store, entityStore);
  }

  get factory (): OrderFactory {
    return orderFactory;
  }

  canMakePayment (orderable: Order['orderable']): boolean {
    return !!orderable.order?.canMakePayment
  }

  setReturnUrl (url: string): void {
    this._returnUrl$.next(url)
  }
}
