<div class="edit-estimated-time-wrapper">
  <h1 mat-dialog-title
      class="mb-0 !text-[2.2rem] text-darkGray text-center">
    Edit Estimated Time
  </h1>
  <mat-dialog-content>
    <form [formGroup]="estimatedForm">
      <mat-form-field appearance="outline"
                      class="w-3/4">
        <mat-label>Estimate time</mat-label>
        <input matInput
               type="number"
               placeholder="Estimate time"
               formControlName="estimatedTime"/>
        <div matSuffix
             class="text-lightGray !top-0">day(s)</div>
              <mat-error *ngIf="estimatedForm.controls.estimatedTime.hasError('required')">
                        Estimate time is required
              </mat-error>
              <mat-error *ngIf="estimatedForm.controls.estimatedTime.hasError('min')">
                        Estimate time can't be 0
              </mat-error>
      </mat-form-field>
    </form>
    <p>
      <span class="text-sub text-darkGray opacity-80">Estimated deadline&colon;&nbsp;</span>
      <span class="mat-subheading-1 !text-sub">{{
        calculateDeadline() | date: "d MMM, YYYY"
        }}</span>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button
            class="bg-grey-800 text-white w-48"
            mat-dialog-close>
      Cancel
    </button>
    <button mat-raised-button
            class="w-48"
            color="primary"
            [disabled]="estimatedForm.invalid || (submitting$ | async)"
            (click)="onSubmit()">
      Update
    </button>
  </mat-dialog-actions>
</div>
