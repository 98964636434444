import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionWpComponent } from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import { CommonHelper } from '@shared/helpers/common.helper';
import { Job } from '@app/modules/job/models/job.model';
import { Store } from '@ngrx/store';
import { countryStore } from '@app/modules/country/store/country.store';
import {
  CompanyDirectorInput,
  CountryType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { OfficerInputReviewInformationComponent } from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { CompanyDirector } from '@app/modules/company/models/company-director.model';

@Component({
  selector: 'app-replace-director-info',
  standalone: true,
  templateUrl: './replace-director-info.component.html',
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
  ],
})
export class ReplaceDirectorInfoComponent implements OnInit {
  public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  countries: CountryType[];

  constructor(
    protected readonly commonHelper: CommonHelper,
    private _store: Store,
    private _officerInputHelper: OfficerInputHelpers,
  ) { }

  ngOnInit(): void {
    this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public getOfficerType(): OfficerTypeEnum {
    return this.jobDetail.directorInput.director.officerType as OfficerTypeEnum;
  }

  public getCurrentOfficerInputValue(): CompanyDirectorInput {
    return this._officerInputHelper.transformDirectorModelToInput(
      new CompanyDirector(this.jobDetail.oldDirector),
    );
  }

  public getNewOfficerInputValue(): CompanyDirectorInput {
    return this.jobDetail.directorInput.director;
  }
}
