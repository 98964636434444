import { ModelFactory } from '@shared/factories/model.factory';
import { ProfileType, SalutationEnum } from '@generated/graphql';
import { Profile } from '@modules/profile/models/profile.model';
import { faker } from '@faker-js/faker';

export class ProfileFactory extends ModelFactory<ProfileType, Profile> {
  create (state: ProfileType): Profile {
    return new Profile(state);
  }

  fake (): Profile {
    return this.create({
      id: faker.datatype.uuid(),
      salutation: SalutationEnum.Dr,
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      phone: faker.phone.number(),
      address: faker.address.streetAddress(),
      createdAt: new Date(faker.date.past(1)),
      updatedAt: new Date(faker.date.future(1)),
    });
  }
}

export const profileFactory = new ProfileFactory();
