import { ModelFactory } from '@shared/factories/model.factory';
import { PaymentProviderKeyType } from '@generated/graphql';
import { PaymentProviderKey } from '@modules/payment/models/payment-provider-key.model';

export class PaymentProviderKeyFactory extends ModelFactory<PaymentProviderKeyType, PaymentProviderKey> {
  create (state: PaymentProviderKeyType): PaymentProviderKey {
    return new PaymentProviderKey(state);
  }

  fake (): PaymentProviderKey {
    throw new Error('Method not implemented yet!');
  }
}

export const paymentProviderKeyFactory = new PaymentProviderKeyFactory();
