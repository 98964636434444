import { Component, Input, OnInit } from '@angular/core';

const DEFAULT_AVATAR = 'assets/images/icons/profile.svg';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() src: string = '';
  @Input() width: number = 56;
  @Input() height: number = 56;
  @Input() type: 'circle' | 'rectangle' = 'circle';

  defaultAvatar = DEFAULT_AVATAR;

  constructor() { }

  ngOnInit(): void {
    //
  }

  getWidth(): number {
    return this.width;
  }

  getHeight(): number {
    return this.height;
  }

  getBorderRadius(): string | null {
    if (this.type === 'circle') {
      return '50%';
    }

    return null;
  }

  getStyles(): string {
    return `width: ${this.getWidth()}px; height: ${this.getHeight()}px; border-radius: ${this.getBorderRadius()}`;
  }
}
