import { CompanyExemptTypeEnum } from '@modules/company/models/types/company-corporate-service.enum';
import { SelectOption } from '@shared/helpers/convention.helpers';
import { FileTypeEnum, ServiceEnum } from '@generated/graphql';

export const basicOptions: SelectOption[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
export const IS_USING_ACCOUNTING_SERVICE: SelectOption[] = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];
export const COMPANY_EXEMPT_OPTIONS = [
  {
    type: CompanyExemptTypeEnum.ExemptedFromAuditRequirement,
    label: 'Is your company exempted from audit requirements?',
    options: basicOptions,
  },
  {
    type: CompanyExemptTypeEnum.ExemptPrivateCompany,
    label: 'Is your company an exempt private company?',
    options: basicOptions,
  },
];

export const PREPARE_DOCUMENT_IN_OPTIONS = [
  {
    type: ServiceEnum.PrepareFinancialStatement,
    label: 'Do you need support to prepare your financial statements?',
    options: basicOptions,
  },
  {
    type: ServiceEnum.PrepareManagementAccount,
    label: 'Do you need support to prepare your management accounts?',
    options: basicOptions,
  },
  {
    type: FileTypeEnum.PrepareXbrlReport,
    label: 'Do you need support to prepare your XBRL report?',
    options: basicOptions,
  },
  {
    type: ServiceEnum.PrepareAuditedFinancialStatements,
    label:
      'Do you need our support to liaise with the auditor pertaining to the preparation of the audited financial statements?',
    options: basicOptions,
  },
];
