<ng-container *ngrxLet="users$ as users">
  <mat-chip-list>
    <ng-container *ngFor="let user of users.slice(0, limitDisplayed); let i = index">
      <mat-chip
        [matTooltip]="user.profile.fullName + ' - ' + user.email"
        [disableRipple]="true"
        class="bg-primary-50"
        [selectable]="false">
        <img
          matChipAvatar
          [src]="user.profile.avatarUrl ? user.profile.avatarUrl : 'assets/images/icons/profile.svg'"
          [alt]="user.profile.fullName"
        />
        <span class="text-xs">{{ user.profile.firstName }}</span>
      </mat-chip>
    </ng-container>
    <ng-container *ngIf="users.length > limitDisplayed">
      <span class="text-sub text-primary-default ml-2">&plus;&nbsp;{{ users.length - limitDisplayed }} more</span>
    </ng-container>
  </mat-chip-list>
</ng-container>
