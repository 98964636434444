<ng-container>
  <div class="inline-flex items-center gap-2">
    <mat-icon class="!w-9 !h-9 !text-[3.6rem]">description</mat-icon>
    <div class="flex flex-col">
      <p class="text-sub !mb-0">{{ message.body }}</p>
      <p class="!mb-0">
        <span class="text-sub italic">{{
          message.file.fileSize | filesize
          }}</span>&nbsp;&dash;
        <span class="text-sub hover:underline">
          <a href="javascript:void(0);" [ngClass]="{ 'text-white': message.isMe }"
            (click)="onDownload.emit()">Download</a>
        </span>
      </p>
    </div>
  </div>
</ng-container>
