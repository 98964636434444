<div
  *ngIf="formGroup"
  [formGroup]="formGroup"
  class="shares-distribution-table-wrapper table-container mb-4">
  <section class="mat-elevation-z1">
    <table
      formArrayName="shareDistributions"
      class="mat-table cdk-table table-custom mb-3">
      <tr class="mat-header-row bg-primary-800">
        <th class="mat-header-cell !text-center !text-white">Share type</th>
        <th class="mat-header-cell !text-center !text-white">Currency</th>
        <th class="mat-header-cell !text-center !text-white">
          Number of shares
        </th>
        <th class="mat-header-cell w-16">&nbsp;</th>
      </tr>

      <ng-container *ngFor="let control of shareDistributions.controls; let i = index">
        <tr [formGroupName]="i"
            class="mat-row">
          <td class="mat-cell">
            <mat-form-field appearance="outline"
                            class="w-full">
              <mat-select
                formControlName="shareTypeId"
                class="mat-select-no-label !text-transform: capitalize"
                disableRipple
                (selectionChange)="onShareTypeChanged($event, i)">
                <mat-select-trigger>
                  <span class="capitalize">
                    {{control.get('shareTypeId').value ? getShareTypeName(control.get('shareTypeId').value).toLocaleLowerCase() : ''}}
                  </span>
                </mat-select-trigger>

                <mat-option *ngFor="let type of shareTypes"
                            [value]="type.id"
                            class="!text-transform: capitalize">
                  <div class="inline-flex items-center">
                                    <span class="capitalize mr-2">
                                      {{ type.name.toLowerCase() }}
                                    </span>
                    <mat-icon class="text-darkGray opacity-60 cursor-pointer"
                              matTooltip="{{commonHelper.getShareTypeHint(type.name)}}">
                      info
                    </mat-icon>
                  </div>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="control.get('shareTypeId')?.hasError('required')">
                Share type required
              </mat-error>
            </mat-form-field>
          </td>

          <td class="mat-cell">
            <mat-form-field
              *ngIf="shareDistributionsList.items[i]"
              appearance="outline"
              class="w-full">
              <mat-select
                formControlName="currency"
                class="mat-select-no-label !text-transform: capitalize"
                disableRipple
                (selectionChange)="onCurrencyChanged($event, i)">
                <mat-option
                  *ngFor="let currency of shareDistributionsList.items[i].currencies"
                  [value]="currency.currency"
                  class="!text-transform: capitalize"
                  [disabled]="currency.disabled">
                  {{ currency.currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="control.get('currency')?.hasError('required')">
                Currency required
              </mat-error>
            </mat-form-field>
          </td>

          <td class="mat-cell">
            <mat-form-field appearance="outline"
                            class="w-full">
              <input
                matInput
                type="number"
                min="0"
                placeholder="Number of shares"
                formControlName="numberOfShares"
                (change)="setMaximunNumberOfShareValidator(i)"/>
              <mat-hint *ngIf="showMaximumValidatorNumber[i]">Available shares is
                <strong style="color: #03a66d">{{
                  getMaximumNumberOfShareAt(i)
                  }}</strong></mat-hint>
              <mat-error *ngIf="control.get('numberOfShares')?.hasError('required')">
                Number of shares required
              </mat-error>
              <mat-error *ngIf="control.get('numberOfShares')?.hasError('min')">
                Shares number must be at least 1
              </mat-error>
              <mat-error *ngIf="control.get('numberOfShares')?.hasError('max')">
                The maximum allowed number of shares is
                {{ control.get("numberOfShares")?.errors.max.max }}
              </mat-error>
            </mat-form-field>
          </td>

          <td class="mat-cell text-right">
            <button
              mat-icon-button
              color="warn"
              (click)="removeShareDistribution(i)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </section>

  <section class="inline-flex justify-end w-full">
    <button mat-raised-button
            color="accent"
            (click)="addShareDistribution()">
      <mat-icon>add</mat-icon>
      Add
    </button>
  </section>
</div>
