import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OfficerTypeEnum, SupportedCountryType } from '@generated/graphql';
import { map, Observable, startWith } from 'rxjs';
import { OfficerHelper } from '@shared/helpers/officer.helper';
import { ExistsOfficerType } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/classes/exists-officer.type';

@Component({
  selector: 'app-exists-officers-select',
  templateUrl: './exists-officers-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class ExistsOfficersSelectComponent implements OnInit {
  @Input() existOfficers: ExistsOfficerType[] = [];
  @Input() isShareholder: boolean;
  @Input() isDirector: boolean;
  @Input() refId: string;
  @Input() countryOfIncorporation: SupportedCountryType;
  @Input() exceptIds: string[] = [];

  @Output() existsOfficerSelectedEvent = new EventEmitter();

  officerFilterInput = new FormControl('');

  filteredOptions$: Observable<ExistsOfficerType[]>;

  constructor(public readonly officerHelper: OfficerHelper) {}

  ngOnInit(): void {
    this.filteredOptions$ = this.officerFilterInput.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    if (this.refId) {
      this.officerFilterInput.setValue(this.refId);
      this.officerFilterInput.updateValueAndValidity();
    }
  }

  private _filter(value: string): ExistsOfficerType[] {
    const filterValue = value.toLowerCase();

    return this.existOfficers
      .filter((o) => !this.exceptIds.includes(o.id))
      .filter((option) => {
        return option.name.toLowerCase().includes(filterValue);
      });
  }

  getDisplayExistsOfficerName = (officerId: string): string => {
    const officer = this.existOfficers.find((o) => o.id === officerId);

    if (!officer) {
      return '';
    }

    return officer.name;
  };

  onExistsOfficerSelected({ option }: MatAutocompleteSelectedEvent): void {
    const id = option.value;

    const officer = this.existOfficers.find((o) => o.id === id);

    this.existsOfficerSelectedEvent.emit(officer);
  }

  isIndividual(officer: ExistsOfficerType): boolean {
    return officer.type === OfficerTypeEnum.Individual;
  }

  isCorporate(officer: ExistsOfficerType): boolean {
    return officer.type === OfficerTypeEnum.Corporate;
  }

  isDisabled(id: string): boolean {
    return this.exceptIds.includes(id);
  }
}
