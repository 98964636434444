import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CompanyDetailFormType,
  TransferCompanyDetailFormType,
} from '@app/modules/job/models/form/company-detail-form-type';
import { SupportedCountry } from '@app/modules/supported-country/models/supported-country.model';
import { uniqueCompanyNameValidator } from '@shared/helpers/validator.helpers';
import { DateTime } from 'luxon';
import { JobOrderServiceManagement } from './job-order-service-management.class';
import { RegisteredAddressForm } from './registered-address-form.class';

export class CompanyDetailForm {
  private _fb = new FormBuilder();

  private _form:
    | FormGroup<CompanyDetailFormType>
    | FormGroup<TransferCompanyDetailFormType>;
  get form():
    | FormGroup<CompanyDetailFormType>
    | FormGroup<TransferCompanyDetailFormType> {
    return this._form;
  }
  get registerForm(): FormGroup<CompanyDetailFormType> {
    return this._form as FormGroup<CompanyDetailFormType>;
  }
  get transferForm(): FormGroup<TransferCompanyDetailFormType> {
    return this._form as FormGroup<TransferCompanyDetailFormType>;
  }

  private _countryOfIncorporation: SupportedCountry;
  set countryOfIncorporation(country: SupportedCountry) {
    this._countryOfIncorporation = country;
  }

  private _registeredAddressForm: RegisteredAddressForm;
  get registeredAddressForm(): RegisteredAddressForm {
    return this._registeredAddressForm;
  }

  constructor(
    countryOfIncorporation: SupportedCountry,
    orderServiceManagement: JobOrderServiceManagement,
  ) {
    this._countryOfIncorporation = countryOfIncorporation;
    this._registeredAddressForm = new RegisteredAddressForm(
      this._countryOfIncorporation,
      orderServiceManagement,
    );
  }

  private getEndOfYearDate(): Date {
    const date = DateTime.now().endOf('year');

    return date.toJSDate();
  }

  updateCountryOfIncorporation(country: SupportedCountry) {
    this.countryOfIncorporation = country;
    this._form.controls.countryId.setValue(
      this._countryOfIncorporation.country.id,
    );
  }

  createRegisterForm(): this {
    this._registeredAddressForm.createForm();

    this._form = this._fb.group<CompanyDetailFormType>(
      {
        name: this._fb.control('', Validators.required),
        secondName: this._fb.control('', Validators.required),
        thirdName: this._fb.control('', [Validators.required]),
        website: this._fb.control(''),
        companyActivity: this._fb.control('', [Validators.required]),
        fyeDate: this._fb.control(this.getEndOfYearDate(), [
          Validators.required,
        ]),
        countryId: this._fb.control(this._countryOfIncorporation.country.id),
        registeredAddress: this._registeredAddressForm.form,
      },
      {
        validators: uniqueCompanyNameValidator,
      },
    );

    return this;
  }

  createTransferForm(): this {
    this._registeredAddressForm.createForm();

    this._form = this._fb.group<TransferCompanyDetailFormType>({
      countryId: this._fb.control(this._countryOfIncorporation.country.id),
      name: this._fb.control('', [Validators.required]),
      website: this._fb.control(''),
      companyActivity: this._fb.control('', [Validators.required]),
      fyeDate: this._fb.control(this.getEndOfYearDate(), [Validators.required]),
      registrationNumber: this._fb.control('', [Validators.required]),
      registrationDate: this._fb.control(null, [Validators.required]),
      numberOfEmployees: this._fb.control(0),
      specialLicenseDocument: this._fb.control(null),
      registeredAddress: this._registeredAddressForm.form,
    });

    return this;
  }
}
