import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { BusinessProfileType } from "@generated/graphql";
import { BusinessProfile } from "@modules/account-settings/pages/business-profile/models/business-profile.model";
import { InjectionToken, Provider } from "@angular/core";

export class BusinessProfileStore extends EntityStore<EntityFeatureNameEnum.BusinessProfile, BusinessProfileType, BusinessProfile> {
  getEntry (): EntityEntry<EntityFeatureNameEnum.BusinessProfile> {
    return 'BUSINESS_PROFILE';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.BusinessProfile> {
    return 'businessProfileFeature';
  }

  get selectState (): SelectState<BusinessProfileType> {
    return this.feature.selectBusinessProfileFeatureState;
  }
}

export const businessProfileStore = new BusinessProfileStore()

export const BUSINESS_PROFILE_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.BusinessProfileStore)

export const BUSINESS_PROFILE_STORE_CONFIG: Provider = {
  provide: BUSINESS_PROFILE_STORE,
  useValue: businessProfileStore,
}

export const selectBusinessProfile = businessProfileStore.selectSelectedEntity
