import { BaseModel } from '@shared/models/base.model';
import {ChangeFyeType, JobDetailType, Maybe, Scalars} from '@generated/graphql';

export class JobDetail extends BaseModel implements JobDetailType {
  description?: string;
  serviceName?: string
  changeFYE?: ChangeFyeType

  constructor (state: JobDetailType) {
    super(state);
  }

  getModelName (): string {
    return JobDetail.name;
  }
}
