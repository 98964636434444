import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BusinessProfileType } from "@generated/graphql";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BusinessExternalLinkTypeEnumType } from '@generated/graphql'
import { selectBusinessProfile } from "@modules/account-settings/pages/business-profile/store/business-profile.store";

@Component({
  standalone: true,
  selector: 'app-tenant-link-side-menu',
  templateUrl: './tenant-link-side-menu.component.html',
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class TenantLinkSideMenuComponent implements OnInit {
  @Input() isOpenSidebar = false;

  profile$: Observable<BusinessProfileType>;
  type = BusinessExternalLinkTypeEnumType;

  constructor(
    private _store: Store,
  ) { }

  ngOnInit(): void {
    this.profile$ = this._store.select(selectBusinessProfile);
  }
}
