import { BaseModel } from "@shared/models/base.model";
import { BusinessProfilePermissionEnum, PermissionEnum, PermissionType } from "@generated/graphql";

export type AppPermissionEnum = PermissionEnum | BusinessProfilePermissionEnum

export class Permission extends BaseModel implements PermissionType {
  id: string;
  name: string;

  constructor (state: PermissionType) {
    super(state);
  }

  getModelName (): string {
    return Permission.name;
  }
}
