import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OfficerTypeEnum } from '@generated/graphql';
import { AbstractOfficerForm } from '@shared/classes/officer/abstract-officer-form.class';
import { OfficerRoleEnum } from '@shared/types/officerEnum';
import { AddOfficerDialogComponent } from '@shared/component/job/component/company-incorporation/dialog/add-officer-dialog/add-officer-dialog.component';
import { CompanyIncorporationManagement } from '@app/modules/job/classes/company-incorporation-management.class';

export interface DirectorInfo {
  id: string;
  type: OfficerTypeEnum;
  name: string;
  email: string;
  nationality: string;
  residence: string;
}

export declare type DirectorAppearance =
  | 'register'
  | 'viewOnly'
  | 'request'
  | string;

@Component({
  selector: 'app-directors-table',
  templateUrl: './directors-table.component.html',
  styleUrls: ['./directors-table.component.scss'],
})
export class DirectorsTableComponent implements OnInit {
  private _componentAppearance: DirectorAppearance = 'viewOnly';

  @Input()
  get appearance(): DirectorAppearance {
    return this._componentAppearance;
  }

  set appearance(value: DirectorAppearance) {
    this._componentAppearance = value;
  }

  officerType = OfficerTypeEnum;
  displayedDirectorColumns: string[] = [
    'type',
    'name',
    'nationality',
    'residence',
    'actions',
  ];

  get companyStructureForm() {
    return this.companyIncorporationManagement.companyStructureForm;
  }

  get formStepTwo() {
    return this.companyStructureForm.form;
  }

  get directorControls() {
    return this.companyStructureForm.directorControls;
  }

  constructor(
    private dialog: MatDialog,
    private companyIncorporationManagement: CompanyIncorporationManagement,
  ) {}

  ngOnInit(): void {}

  getOfficerName(formData): string {
    if (formData.isNominee) {
      return formData.officerPerson.firstName;
    }

    return formData.officerPerson
      ? `${formData.officerPerson.firstName} ${formData.officerPerson.lastName}`
      : formData.officerCompany.name;
  }

  getOfficerEmail(formData): string {
    if (formData.isNominee) {
      return '';
    }

    return formData.officerCompany
      ? formData.officerCompany.email
      : formData.officerPerson.email;
  }

  getCountryId(formData) {
    if (formData.isNominee) {
      return '';
    }

    const officer = formData.officerPerson || formData.officerCompany;
    return officer.identification
      ? officer.identification.nationalityId
      : officer.countryId;
  }

  getResidenceCountryId(formData) {
    if (formData.isNominee) {
      return formData.officerPerson.countryId;
    }

    const officer = formData.officerPerson || formData.officerCompany;
    return officer.address.country
      ? officer.address.countryId
      : officer.countryId;
  }

  editDirector(formData, index: number) {
    const addOfficerDialog = this.dialog.open(AddOfficerDialogComponent, {
      width: '75%',
      data: {
        incorporateType: this.companyIncorporationManagement.type,
        officerRole: OfficerRoleEnum.DIRECTOR,
        companyCapitals: this.formStepTwo.get('companyCapitals') as FormArray,
        editData: formData,
        shareholders: this.formStepTwo.controls.shareholders,
        exceptIds: this.directorControls
          .getRawValue()
          .filter((d) => d.refId && !d.isNominee && d.id !== formData.id)
          .map((d) => d.refId),
      },
    });

    addOfficerDialog
      .afterClosed()
      .subscribe(
        (result: {
          refId: string;
          officerForm: AbstractOfficerForm;
          extraData?: any;
        }) => {
          if (result?.officerForm) {
            const currentControl = this.directorControls.at(index);

            this.companyStructureForm.companyDirectorForm.updateDirector(
              index,
              result.officerForm,
              result.refId,
              result.extraData,
            );

            if (!result.refId) {
              this.companyStructureForm.companySharehoderForm.updateShareholderByRef(
                currentControl,
                result.officerForm,
              );
            }
          }
        },
      );
  }

  deleteDirector(index: number) {
    const directorControl = this.directorControls.at(index);
    const isNominee = directorControl.controls.isNominee.value;

    if (!isNominee) {
      this.companyStructureForm.companySharehoderForm.removeByRef(
        directorControl.controls.id.value,
        directorControl.controls.officerCompany?.controls.representative
          .controls.id.value,
      );

      this.companyStructureForm.companyDirectorForm.removeAt(index);
    } else {
      this.companyStructureForm.companyDirectorForm.removeNominee();
    }
  }
}
