import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionWpComponent } from '@modules/job/pages/company-incorporation/components/common/section-wp/section-wp.component';
import { CommonHelper } from '@shared/helpers/common.helper';
import { Job } from '@app/modules/job/models/job.model';
import { Store } from '@ngrx/store';
import { countryStore } from '@app/modules/country/store/country.store';
import {
  CompanyDirectorInput,
  CountryType,
  OfficerTypeEnum,
} from '@generated/graphql';
import { OfficerInputReviewInformationComponent } from '@shared/component/officer/officer-input-review-information/officer-input-review-information.component';
import { OfficerInputHelpers } from '@shared/classes/officer/officer-input.helper';
import { CompanyDirector } from '@app/modules/company/models/company-director.model';
import { startCase } from 'lodash-es';

@Component({
  selector: 'app-remove-director-info',
  standalone: true,
  templateUrl: './remove-director-info.component.html',
  imports: [
    CommonModule,
    SectionWpComponent,
    OfficerInputReviewInformationComponent,
  ],
})
export class RemoveDirectorInfoComponent implements OnInit {
  @Input()
  public job: Job;

  get jobDetail() {
    return this.job.detailObject;
  }

  countries: CountryType[];

  constructor(
    protected readonly commonHelper: CommonHelper,
    private _store: Store,
    private _officerInputHelper: OfficerInputHelpers,
  ) { }

  ngOnInit(): void {
    this._store.select(countryStore.selectAllEntity).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public getOfficerType(): OfficerTypeEnum {
    return this.jobDetail.oldDirector.officer.officerProfile.__typename ===
      'OfficerPersonType'
      ? OfficerTypeEnum.Individual
      : OfficerTypeEnum.Corporate;
  }

  public getCurrentOfficerInputValue(): CompanyDirectorInput {
    return this._officerInputHelper.transformDirectorModelToInput(
      new CompanyDirector(this.jobDetail.oldDirector),
    );
  }

  public getReason(): string {
    return startCase(this.jobDetail.removeDirectorInput.reason);
  }
}
