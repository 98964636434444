import { Inject, Injectable } from '@angular/core';
import { EntityService } from "@shared/services/entity.service";
import { EntityFeatureNameEnum } from "@shared/contracts/entity-store.contract";
import { OfficerRequiredDocumentType, OfficerType, OfficerTypeEnum } from "@generated/graphql";
import { OfficerRequiredDocument } from "@modules/officer/models/officer-required-document.model";
import {
  OFFICER_REQUIRED_DOCUMENT_STORE,
  OfficerRequiredDocumentStore
} from "@modules/officer/store/officer-required-document.store";
import { Store } from "@ngrx/store";
import {
  officerRequiredDocumentFactory,
  OfficerRequiredDocumentFactory
} from "@modules/officer/factories/officer-required-document.factory";
import { map, Observable, of } from "rxjs";
import { Country } from "@modules/country/models/country.model";
import {
  KYCDocument
} from "@modules/company/pages/company-profile-page/components/kyc-ubo/components/dialog/kyc-collection-dialog/kyc-collection-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class OfficerRequiredDocumentService extends EntityService<
  EntityFeatureNameEnum.OfficerRequiredDocument,
  OfficerRequiredDocumentType,
  OfficerRequiredDocument
> {
  constructor (
    @Inject(OFFICER_REQUIRED_DOCUMENT_STORE) protected readonly entityStore: OfficerRequiredDocumentStore,
    protected readonly store: Store
  ) {
    super(store, entityStore)
  }

  get factory (): OfficerRequiredDocumentFactory {
    return officerRequiredDocumentFactory;
  }

  getByCountryId$ (countryId: Country['id']): Observable<OfficerRequiredDocument[]> {
    return this.entities$.pipe(
      map(items => items.filter(item => item.country.id === countryId))
    )
  }

  getByOfficerTypeAndCountry$ (officerType: OfficerType['officerType'], countryId: Country['id']): Observable<OfficerRequiredDocument[]> {
    return this.getByCountryId$(countryId).pipe(map(items => items.filter(item => item.officerType === officerType)))
  }
}
