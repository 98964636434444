import { $enum } from 'ts-enum-util';
import { sortBy, startCase } from 'lodash-es';

export interface SelectOption<T extends any = any> {
  label: string;
  value: T;
  caption?: string;
  selected?: boolean;
  disabled?: boolean;
}

export const createSelectOptionsFromEnum = <T extends any = any> (enumType: unknown): SelectOption<T>[] =>
  sortBy($enum(enumType).map((value, key) => ({value: enumType[key], label: startCase(key)})), 'label', 'asc');
