import { createReducer, on } from '@ngrx/store';
import { registerActions } from '@modules/auth/store/actions/register.actions';
import { login, loginError, loginSuccess } from '@modules/auth/store/actions/login.actions';
import { impersonateActions } from '@modules/auth/store/actions/impersonate.actions';

import { userFactory } from '@modules/user/factories/user.factory';
import { authUserActions } from '@modules/auth/store/actions/auth-user.actions';
import { RegisterVariables, SendResetPasswordLinkVariables, UserType } from '@generated/graphql';
import { authActions } from '@modules/auth/store/actions/auth.actions';
import { resendEmailVerificationActions } from '@modules/auth/store/actions/resend-email-verification.actions';
import { verifyEmailActions } from '@modules/auth/store/actions/verify-email.actions';
import { resettingPasswordActions } from '@modules/auth/store/actions/resetting-password.actions';
import { logout, logoutError, logoutSuccess } from '@modules/auth/store/actions/logout.actions';
import { cloneDeep } from 'lodash-es';
import { ValidationError } from '@utils/validation/app.validation';

export const authFeatureName = 'auth';

export const authUserFeature = 'user';

export const impersonatingUserFeatureName = 'impersonatingUser';

export interface AuthState {
  [authUserFeature]: UserType;
  [impersonatingUserFeatureName]: UserType;
  loginErrorMessages: string[];
  registerError: ValidationError<RegisterVariables>;
  mustVerifyEmail: boolean;
  loginLoading: boolean;
  registerLoading: boolean;
  resendVerificationEmailLoading: boolean;
  isRegisterSuccess: boolean;
  isResendVerificationEmailSuccess: boolean;

  verifyEmailLoading: boolean,
  isVerifyEmailSuccess: boolean,
  isVerifyEmailError: boolean

  sendResetPasswordsLinkLoading: boolean;
  isSendResetPasswordsLinkSuccess: boolean;
  isSendResetPasswordsLinkError: boolean;
  sendResetPasswordsLinkError: ValidationError<SendResetPasswordLinkVariables>,

  resetPasswordLoading: boolean
  isResetPasswordSuccess: boolean
  isResetPasswordError: boolean
  resetPasswordErrorMessages: string[]
}

export const authState: AuthState = {
  user: null,
  impersonatingUser: null,

  loginLoading: false,
  loginErrorMessages: [],
  mustVerifyEmail: false,

  resendVerificationEmailLoading: false,
  isResendVerificationEmailSuccess: false,

  registerLoading: false,
  isRegisterSuccess: null,
  registerError: null,

  verifyEmailLoading: false,
  isVerifyEmailSuccess: false,
  isVerifyEmailError: false,

  sendResetPasswordsLinkLoading: false,
  isSendResetPasswordsLinkSuccess: false,
  isSendResetPasswordsLinkError: false,
  sendResetPasswordsLinkError: null,

  resetPasswordLoading: false,
  isResetPasswordSuccess: false,
  isResetPasswordError: false,
  resetPasswordErrorMessages: [],
};

export const defaultAuthState = cloneDeep(authState);

export const authReducer = createReducer(
  authState,

  /**
   * Sign up
   */
  on(registerActions.register, (state) => {
    return {
      ...state,
      registerError: null,
      registerLoading: true,
      isRegisterSuccess: false,
    };
  }),

  on(registerActions.registerSuccess, (state) => {
    return {
      ...state,
      registerLoading: false,
      registerError: null,
      isRegisterSuccess: true,
    };
  }),

  on(registerActions.registerError, (state, { registerError }) => {
    return {
      ...state,
      registerError,
      registerLoading: false,
      isRegisterSuccess: false,
    };
  }),

  on(registerActions.refreshState, (state) => {
    return {
      ...state,
      registerError: null,
      registerLoading: false,
      isRegisterSuccess: false,
    };
  }),


  /**
   * Login
   */
  on(login, (state, action) => {
    return {
      ...state,
      loginErrorMessages: [],
      loginLoading: true,
      mustVerifyEmail: false,
      isResendVerificationEmailSuccess: false,
    };
  }),

  on(loginSuccess, (state, { user }) => {
    return {
      ...state,
      loginLoading: false,
      mustVerifyEmail: false,
    };
  }),

  on(loginError, (state, { loginErrorMessages, mustVerifyEmail }) => {
    return {
      ...state,
      loginErrorMessages,
      mustVerifyEmail,
      loginLoading: false,
    };
  }),

  /**
   * Logout
   */
  on(logout, (state, action) => {
    return {
      ...state,
      ...defaultAuthState,
    };
  }),

  on(logoutSuccess, (state, action) => {
    return {
      ...state,
      ...defaultAuthState,
    };
  }),

  on(logoutError, (state) => {
    return {
      ...state,
      ...defaultAuthState,
    };
  }),

  on(impersonateActions.setImpersonateUser, (state, { user }) => {
    return {
      ...state,
      [impersonatingUserFeatureName]: user,
    };
  }),

  on(impersonateActions.impersonateUser, (state) => {
    return { ...state };
  }),

  on(impersonateActions.impersonateUserSuccess, (state) => {
    return {
      ...state,
    };
  }),

  on(impersonateActions.impersonateUserError, (state, action) => {
    return {
      ...state,
      [impersonatingUserFeatureName]: null,
    };
  }),

  on(authUserActions.setAuthUser, (state, { user }) => {
    return {
      ...state,
      [authUserFeature]: userFactory.create(user),
    };
  }),

  /**
   * Auth user actions
   */
  on(authUserActions.resetAuthUser, (state) => {
    return {
      ...state,
      [authUserFeature]: null,
    };
  }),


  /**
   * Auth actions
   */
  on(authActions.setAuth, (state, { auth }) => {
    return {
      ...state,
      ...auth,
    };
  }),

  on(authActions.resetAuth, (state) => {

    return {
      ...state,
      ...defaultAuthState,
    };
  }),


  /**
   * Resend Verification
   */
  on(resendEmailVerificationActions.resendVerificationEmail, (state) => {
    return {
      ...state,
      resendVerificationEmailLoading: true,
      mustVerifyEmail: false,
      isResendVerificationEmailSuccess: false,
    };
  }),

  on(resendEmailVerificationActions.resendVerificationEmailSuccess, (state) => {
    return {
      ...state,
      resendVerificationEmailLoading: false,
      isResendVerificationEmailSuccess: true,
    };
  }),

  on(resendEmailVerificationActions.resendVerificationEmailError, (state) => {
    return {
      ...state,
      resendVerificationEmailLoading: false,
      isResendVerificationEmailSuccess: false,
    };
  }),


  /**
   * Verify email
   */
  on(verifyEmailActions.verifyEmail, (state) => {
    return {
      ...state,
      verifyEmailLoading: true,
      isVerifyEmailSuccess: false,
      isVerifyEmailError: false,
    };
  }),

  on(verifyEmailActions.verifyEmailSuccess, (state) => {
    return {
      ...state,
      verifyEmailLoading: false,
      isVerifyEmailSuccess: true,
      isVerifyEmailError: false,
    };
  }),

  on(verifyEmailActions.verifyEmailError, (state) => {
    return {
      ...state,
      verifyEmailLoading: false,
      isVerifyEmailSuccess: false,
      isVerifyEmailError: true,
    };
  }),


  /**
   * Resetting password actions
   */
  on(resettingPasswordActions.sendLink, (state) => ({
    ...state,
    sendResetPasswordsLinkLoading: true,
    isSendResetPasswordsLinkError: false,
    isSendResetPasswordsLinkSuccess: false,
    sendResetPasswordsLinkError: null,
  })),

  on(resettingPasswordActions.sendLinkSuccess, (state) => ({
    ...state,
    sendResetPasswordsLinkLoading: false,
    isSendResetPasswordsLinkError: false,
    isSendResetPasswordsLinkSuccess: true,
    sendResetPasswordsLinkError: null,
  })),

  on(resettingPasswordActions.sendLinkError, (state, { sendResetPasswordsLinkError }) => ({
    ...state,
    sendResetPasswordsLinkLoading: false,
    isSendResetPasswordsLinkError: true,
    isSendResetPasswordsLinkSuccess: false,
    sendResetPasswordsLinkError,
  })),

  on(resettingPasswordActions.resetPassword, (state, { input }) => ({
    ...state,
    resetPasswordLoading: true,
    isResetPasswordSuccess: false,
    isResetPasswordError: false,
    resetPasswordErrorMessages: [],
  })),

  on(resettingPasswordActions.resetPasswordSuccess, (state) => ({
    ...state,
    resetPasswordLoading: false,
    isResetPasswordSuccess: true,
    isResetPasswordError: false,
    resetPasswordErrorMessages: [],
  })),

  on(resettingPasswordActions.resetPasswordError, (state, { resetPasswordErrorMessages }) => ({
    ...state,
    resetPasswordErrorMessages,
    resetPasswordLoading: false,
    isResetPasswordSuccess: false,
    isResetPasswordError: true,
  })),


  on(resettingPasswordActions.refreshState, (state) => ({
    ...state,
    sendResetPasswordsLinkLoading: false,
    isSendResetPasswordsLinkError: false,
    isSendResetPasswordsLinkSuccess: false,
    resetPasswordLoading: false,
    isResetPasswordSuccess: false,
    isResetPasswordError: false,
    resetPasswordErrorMessages: [],
    sendResetPasswordsLinkError: null,
  })),
);

