import { ModelFactory } from "@shared/factories/model.factory";
import { ExtraJobType } from "@generated/graphql";
import { ExtraJob } from "@modules/extra-job/models/extra-job.model";

export class ExtraJobFactory extends ModelFactory<ExtraJobType, ExtraJob> {
  create (state: ExtraJobType): ExtraJob {
    return new ExtraJob(state);
  }

  fake (): ExtraJob {
    throw new Error('Method not implemented');
  }
}

export const extraJobFactory = new ExtraJobFactory()
