import {ModelConstructor} from "@shared/models/base.model";
import {CompanyGstReturnType} from "@generated/graphql";
import {Company} from "@modules/company/models/company.model";
import {User} from "@modules/user/models/user.model";
import {companyFactory} from "@modules/company/factories/company.factory";
import {userFactory} from "@modules/user/factories/user.factory";
import {CompanyGstReturnItem} from "@modules/company/models/company-gst-return-item.model";
import {companyGstReturnItemFactory} from "@modules/company/factories/company-gst-return-item.factory";

export class CompanyGstReturn extends ModelConstructor implements CompanyGstReturnType {
  company: Company;
  user: User;
  items: CompanyGstReturnItem[]
  years: number[]

  showInitialError(): void {
    throw new Error('Something wrong! when create new ' + CompanyGstReturn.name + ' instance');
  }

  constructor(state: CompanyGstReturnType) {
    super(state);

    this.company = companyFactory.tryCreating(state.company)

    this.user = userFactory.tryCreating(state.user)

    this.items = companyGstReturnItemFactory.createEntities(state.items)
  }
}
