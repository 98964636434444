import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {PhoneValidator} from '@utils/validation/app.validation';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ACTIVE_USER} from '@modules/auth/providers/auth.provider';
import {first, map, Observable, switchMap, tap} from 'rxjs';
import {User} from '@modules/user/models/user.model';
import {AddressTypeEnum, BillingAddressInput} from '@generated/graphql';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {
  AbstractAddressDialogComponent,
} from '@modules/address/components/dialog/abstract-address-dialog/abstract-address-dialog.component';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from "@angular/material/button";
import {
  CountrySelectInputComponent
} from "@shared/component/common/country-select-input/country-select-input.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {AddressType} from "@modules/address/models/address-type.model";
import {AddressTypeApi} from "@modules/address/api/address-type.api";
import {ReactiveComponentModule} from "@ngrx/component";
import {startWith} from "rxjs/operators";

export interface AddressDialogData {
  // TODO add dialog props here if we need
}

export interface BillingAddressForm {
  addressTypeId: FormControl<string>
  name: FormControl<string>
  firstName: FormControl<string>
  lastName: FormControl<string>
  address: FormControl<string>
  phone: FormControl<string>
  isDefault: FormControl<boolean>
  apartment: FormControl<string>
  city: FormControl<string>
  state: FormControl<string>
  zip: FormControl<string>
  countryId: FormControl<string>
  userId: FormControl<string>
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatIntlTelInputComponent,
    MatDialogModule,
    MatButtonModule,
    CountrySelectInputComponent,
    MatCheckboxModule,
    MatSelectModule,
  ],
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent extends AbstractAddressDialogComponent<FormGroup<BillingAddressForm>,
  AddressDialogComponent,
  AddressDialogData,
  BillingAddress> implements OnInit {
  static width = '60rem'

  get label(): string {
    return 'Add new address';
  }

  getQuery$(): Observable<BillingAddress> {
    return this.billingAddressApi.add$(this.control.value as BillingAddressInput);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: AddressDialogData,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>,
    protected readonly dialogRef: MatDialogRef<AddressDialogComponent>,
    protected readonly fb: FormBuilder,
    protected readonly billingAddressApi: BillingAddressApi,
    protected readonly snackBarService: SnackBarService,
    protected readonly addressTypeApi: AddressTypeApi,
  ) {
    super(data, activeUser$, dialogRef, fb, billingAddressApi, snackBarService, addressTypeApi);
  }

  ngOnInit(): void {
    super.init();

    this.addressTypes$.pipe(
      first(),
      tap(types => {
        const type = types.find(t => t.isCorporate())

        this.control.patchValue({addressTypeId: type?.id})
      })
    ).subscribe()

    this.control.controls.addressTypeId.valueChanges.pipe(
      untilDestroyed(this),
      switchMap(id => this.addressTypes$.pipe(
        map(types => types.find(type => type.id === id))
      )),
      tap(type => {
        this._selectedAddressType$.next(type)

        this.onAddressTypeChanged(type)
      })
    ).subscribe()
  }

  getControl(userId: string): FormGroup<BillingAddressForm> {
    return this.fb.group({
      name: this.fb.control('', [Validators.required]),
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      addressTypeId: this.fb.control('', [Validators.required]),
      address: this.fb.control('', [Validators.required]),
      phone: this.fb.control('', [Validators.required, PhoneValidator]),
      isDefault: this.fb.control(true, [Validators.required]),

      apartment: this.fb.control(null),
      city: this.fb.control(null),
      state: this.fb.control(null),
      zip: this.fb.control(null),

      countryId: this.fb.control(null),

      userId: this.fb.control(userId, [
        Validators.required,
      ]),
    });
  }

  private onAddressTypeChanged(type: AddressType) {
    this.control.patchValue({firstName: '', lastName: '', name: ''})

    const controls = this.control.controls

    switch (type.name) {
      case AddressTypeEnum.Individual: {
        controls.firstName.addValidators(Validators.required)
        controls.lastName.addValidators(Validators.required)
        controls.name.removeValidators(Validators.required)

        break;
      }

      case AddressTypeEnum.Corporate: {
        controls.firstName.removeValidators(Validators.required)
        controls.lastName.removeValidators(Validators.required)
        controls.name.addValidators(Validators.required)

        break;
      }
    }

    controls.firstName.updateValueAndValidity()
    controls.lastName.updateValueAndValidity()
    controls.name.updateValueAndValidity()
  }
}
