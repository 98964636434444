import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScreenSpinnerService {
  get loading$ (): Observable<boolean> {
    return this._loading$.asObservable()
  }

  protected readonly _loading$ = new BehaviorSubject(false)

  constructor () {
  }

  setLoading (value: boolean): void {
    this._loading$.next(value);
  }

  loading (): void {
    Promise.resolve().then(() => this.setLoading(true))
  }

  stopLoading (): void {
    Promise.resolve().then(() => this.setLoading(false))
  }
}
