import {ModelFactory} from "@shared/factories/model.factory";
import {AddressTypeType} from "@generated/graphql";
import {AddressType} from "@modules/address/models/address-type.model";

export class AddressTypeFactory extends ModelFactory<AddressTypeType, AddressType> {
  create(state: AddressTypeType): AddressType {
    return new AddressType(state);
  }

  fake(): AddressType {
    throw new Error('Method is not implemented');
  }
}

export const addressTypeFactory = new AddressTypeFactory();
