<div class="conversation-info-wrapper">
  <!--    HEADER-->
  <section class="info-header">
    <div class="flex flex-col justify-center w-full items-center">
      <div
        class="flex flex-col justify-center items-center bg-secondary-700 text-white p-6 rounded-full w-24 h-24 mb-1">
        <span class="text-[2.2rem]">{{
          getCompanyShortName(companyName)
          }}</span>
      </div>
      <p class="font-['OpenSans_Semibold'] font-semibold !text-base text-darkGray !mb-0">
        {{ companyName }}
      </p>
      <p class="text-sub text-lightGray">{{ chatMembers.length }} member(s)</p>
    </div>
  </section>
  <!--  <mat-divider class="!mb-4"></mat-divider>-->
  <section class="chat-data-container !max-w-lg !p-2 !pr-0">
    <mat-tab-group>
      <mat-tab label="Members" [labelClass]="'!text-sub !p-0 !min-w-[10rem]'" [bodyClass]="'!bg-white member-list'">
        <ng-container *ngFor="let member of members$ | async">
          <div class="flex flex-row items-center p-2">
            <img [src]="
                member.avatarUrl
                  ? member.avatarUrl
                  : 'assets/images/icons/profile.svg'
              " [alt]="member.displayName" class="w-9 h-9 rounded-full object-cover mr-2" />
            <div class="flex flex-col w-full">
              <p class="!mb-0 text-sub truncate text-darkGray w-[80%]">
                {{ member.displayName }}
              </p>
              <p class="mat-caption !mb-0 text-lightGray">
                <span *ngIf="
                    member.roles.includes(UserRoleEnum.Admin.toString()) ||
                    member.roles.includes(UserRoleEnum.SuperAdmin.toString())
                  ">Admin</span>
                <span *ngIf="member.roles.includes(UserRoleEnum.Expert.toString())">Expert</span>
                <span *ngIf="
                    member.roles.includes(UserRoleEnum.Customer.toString())
                  ">Customer</span>
              </p>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="File" [labelClass]="'!text-sub !p-0 !min-w-[10rem]'" [bodyClass]="'!bg-white file-uploaded-list'">
        <ng-container *ngIf="_conversationService.files$ | async as files">
          <ng-container *ngIf="files.length; else noFileUploaded">
            <div *ngFor="let file of files" class="flex flex-row items-center p-2 cursor-pointer"
              (click)="downloadFile(file)">
              <img src="assets/images/icons/document.svg" class="w-9 h-9 object-contain mr-2" />
              <div class="flex flex-col w-full">
                <p class="!mb-0 truncate text-darkGray w-[90%] text-sub" [matTooltip]="file.metadata.originalName">
                  {{ file.metadata.originalName }}
                </p>
                <p class="mat-caption !mb-0 text-lightGray">
                  {{ file.size | filesize }}
                </p>
              </div>
            </div>
          </ng-container>
          <ng-template #noFileUploaded>
            <div class="flex flex-col justify-center items-center p-6">
              <p class="!mb-1 !text-sub text-lightGray text-center">No files</p>
              <p class="!text-sub !mb-0 text-lightGray text-center">
                Files that you exchange with the others will appear here.
              </p>
            </div>
          </ng-template>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>
