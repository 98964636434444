import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { AuthService } from '@modules/auth/services/auth.service';
import { authActions } from '@modules/auth/store/actions/auth.actions';
import { logout } from '@modules/auth/store/actions/logout.actions';
import { AuthApi } from '@modules/auth/api/auth.api';

@Injectable()
export class LogoutEffects {
  onLogout$ = createEffect(() => this.actions$
    .pipe(
      ofType(logout.type),
      switchMap(() => this.authApi.logout()),
      map(() => authActions.resetAuth()),
      catchError(e => of(authActions.resetAuth()),
      ),
    ),
  );

  constructor (
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private authService: AuthService,
    private actions$: Actions,
    private authApi: AuthApi,
  ) {
  }
}
