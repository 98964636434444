import { SupportedCountryType } from '@generated/graphql';
import { BaseModel } from '@shared/models/base.model';
import { Country } from '@modules/country/models/country.model';

export class SupportedCountry extends BaseModel implements SupportedCountryType {
  static readonly defaultCurrency = 'USD'
  static readonly defaultCurrencies = [SupportedCountry.defaultCurrency, 'EUR']
  static readonly maxTaxRate: number = 999;
  static readonly minTaxRate: number = 0;

  id: string;
  country: Country;
  includeTax: boolean;
  taxRate: number;
  isActive: boolean;
  currency: string;

  constructor (state: SupportedCountryType) {
    super(state);
  }

  getModelName (): string {
    return SupportedCountry.name;
  }

  get supportedCurrencies (): string[] {
    return [...new Set([this.country.currency, ...SupportedCountry.defaultCurrencies])];
  }
}
