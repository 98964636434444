import { Inject, Injectable } from '@angular/core';
import { ServiceService } from "@modules/service/services/service.service";
import { catchError, distinctUntilKeyChanged, filter, Observable, of, switchMap, tap } from "rxjs";
import { ServiceCategoryApi } from "@modules/service/api/service-category.api";
import { ServiceCategoryService } from "@modules/service/services/service-category.service";
import { ServiceSupportedCountryService } from "@modules/service/services/service-supported-country.service";
import { User } from "@modules/user/models/user.model";
import { ServiceCategory } from "@modules/service/models/service-category.model";
import { ACTIVE_USER } from "@modules/auth/providers/auth.provider";

@Injectable({
  providedIn: 'root'
})
export class ServiceDataSyncService {
  constructor (
    protected readonly serviceService: ServiceService,
    protected readonly serviceCategoryService: ServiceCategoryService,
    protected readonly serviceSupportedCountryService: ServiceSupportedCountryService,
    protected readonly serviceCategoryApi: ServiceCategoryApi,
    @Inject(ACTIVE_USER) protected readonly activeUser$: Observable<User>
  ) {
  }

  clear (): void {
    this.serviceCategoryService.dispatchClearEntitiesAction()
    this.serviceService.dispatchClearEntitiesAction()
    this.serviceSupportedCountryService.dispatchClearEntitiesAction()
  }

  sync$ (): Observable<ServiceCategory[]> {
    return this.serviceCategoryApi.get()
      .pipe(
        tap(serviceCategories => {
          this.clear()

          this.serviceCategoryService.dispatchSetEntities(serviceCategories)
          this.serviceService.dispatchSetEntities(serviceCategories.map(item => item.services).flat())
          this.serviceSupportedCountryService.dispatchSetEntities(serviceCategories.map(service => service.serviceSupportedCountries).flat())
        }),
        catchError(e => {
          console.error(e)
          this.clear()

          return of(e)
        }),
      )
  }

  syncByActiveUser$ (): Observable<ServiceCategory[]> {
    return this.syncByUser$(this.activeUser$)
  }

  syncByUser$ (user: Observable<User>): Observable<ServiceCategory[]> {
    return user
      .pipe(
        filter(user => !!user),
        distinctUntilKeyChanged('id'),
        switchMap(() => this.sync$())
      )
  }
}
