import {BaseModel} from "@shared/models/base.model";
import {OfficerIdentificationType, OfficerIdentificationTypeType} from "@generated/graphql";
import {Country} from "@modules/country/models/country.model";
import {startCase} from "lodash-es";

export class OfficerPersonIdentificationType extends BaseModel implements OfficerIdentificationTypeType {
  id: string
  name: string

  constructor(state: OfficerIdentificationTypeType) {
    super(state);
  }

  get label(): string {
    return startCase(this.name.toLowerCase())
  }

  getModelName(): string {
    return OfficerPersonIdentificationType.name;
  }
}
