<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="businessProfile$ as businessProfile">
    <ul class="!text-sub px-8 py-4 border border-solid rounded-2xl border-[#0000001f]">
      <li>
        <span class="mr-2">Tax Year&colon;</span>
        <span class="mat-body-2 !text-sub">{{ taxReturnInput.year }}</span>
      </li>
      <li *ngIf="taxReturnInput.fileType">
        <span class="mr-2">Submit for&colon;</span>
        <span class="mat-body-2 !text-sub capitalize">{{startCase(taxReturnInput.fileType.toLowerCase())}}</span>
      </li>
      <li *ngIf="isSingapore">
        <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'the customer company'}} meet both criteria for ECI exemption:</span>
        <span class="mat-body-2 !text-sub">{{taxReturnInput.meetBothCriteriaForEciExemption ? "Yes" : "No"}}</span>
      </li>

      <ng-container *ngIf="(isSingapore && !taxReturnInput.meetBothCriteriaForEciExemption) ||isHongKong">
        <li>
          <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}}
                             using {{ businessProfile.name }} accounting service&colon;</span>
          <span class="mat-body-2 !text-sub">{{taxReturnInput.usingTenantAccount ? 'Yes' : 'No'}}</span>
        </li>
      </ng-container>

      <ng-container *ngIf="isSingapore && !taxReturnInput.meetBothCriteriaForEciExemption">
        <ng-container *ngIf="!taxReturnInput.usingTenantAccount">
          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare financial statement&colon;</span>
            <span class="mat-body-2 !text-sub">{{ taxReturnInput.prepareFinancialStatement ? 'Yes' : 'No'}}</span>
          </li>

          <li>
            <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare management account&colon;</span>
            <span class="mat-body-2 !text-sub">{{
              taxReturnInput.prepareManagementAccount ? "Yes" : "No"}}</span>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isHongKong && !taxReturnInput.usingTenantAccount">
        <li>
          <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare audited financial statement&colon;</span>
          <span class="mat-body-2 !text-sub">{{taxReturnInput.prepareAuditedFinancialStatements ? "Yes" : "No"}}</span>
        </li>

        <li *ngIf="!taxReturnInput.usingTenantAccount">
          <span class="mr-2">Is {{activeUser.isCustomer() ? 'your company' : 'customer'}} need to support to prepare management account&colon;</span>
          <span class="mat-body-2 !text-sub">{{taxReturnInput.prepareManagementAccount ? "Yes" : "No"}}</span>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>
