import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import { SupportedCountryType } from "@generated/graphql";
import { SupportedCountry } from "@modules/supported-country/models/supported-country.model";
import { InjectionToken, Provider } from "@angular/core";
import { createSelector } from "@ngrx/store";

export class SupportedCountryStore extends EntityStore<EntityFeatureNameEnum.SupportedCountry, SupportedCountryType, SupportedCountry> {
  get selectState (): SelectState<SupportedCountryType> {
    return this.feature.selectSupportedCountryFeatureState;
  }

  readonly selectAllActive = createSelector(
    this.selectIds,
    this.selectEntities,
    (ids, entities) => ids.map((id) => entities[id]).filter(item => item.isActive)
  );

  getEntry (): EntityEntry<EntityFeatureNameEnum.SupportedCountry> {
    return 'SUPPORTED_COUNTRY';
  }

  getFeatureName (): EntityFeatureName<EntityFeatureNameEnum.SupportedCountry> {
    return 'supportedCountryFeature';
  }
}

export const supportedCountryStore = new SupportedCountryStore()
export const SUPPORTED_COUNTRY_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.SupportedCountryStore)
export const SUPPORTED_COUNTRY_STORE_CONFIG: Provider = {
  provide: SUPPORTED_COUNTRY_STORE,
  useValue: supportedCountryStore,
}
