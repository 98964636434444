import {Injectable} from "@angular/core";
import {
  DefaultPaymentMethodQuery,
  DeletePaymentMethodMutation,
  PaymentMethodsQuery,
  PaymentProviderNameEnum,
  SyncAndGetPaymentMethodMutation,
  UpdatePaymentMethodInput,
  UpdatePaymentMethodMutation
} from "@generated/graphql";
import {map, Observable} from "rxjs";
import {PaymentMethod} from "@modules/payment/models/payment-method.model";

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  constructor(
    private readonly defaultPaymentMethodQuery: DefaultPaymentMethodQuery,
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly updatePaymentMethodMutation: UpdatePaymentMethodMutation,
    private readonly deletePaymentMethodMutation: DeletePaymentMethodMutation,
    private readonly syncAndPaymentMethodMutation: SyncAndGetPaymentMethodMutation,
  ) {
  }

  getUserDefaultPaymentMethod(): Observable<PaymentMethod> {
    return this.defaultPaymentMethodQuery.fetch({}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.defaultPaymentMethod as unknown as PaymentMethod)
    )
  }

  getUserPaymentMethods() {
    return this.paymentMethodsQuery.fetch({}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.paymentMethods.map(paymentMethod => paymentMethod as unknown as PaymentMethod))
    )
  }

  updatePaymentMethod(input: UpdatePaymentMethodInput) {
    return this.updatePaymentMethodMutation.mutate({input}).pipe(
      map(res => res.data.updatePaymentMethod as unknown as PaymentMethod)
    )
  }

  deletePaymentMethod(id: string): Observable<boolean> {
    return this.deletePaymentMethodMutation.mutate({id}).pipe(
      map(res => res.data.deletePaymentMethod)
    )
  }

  syncAndGetPaymentMethod(id: string, provider: PaymentProviderNameEnum) {
    return this.syncAndPaymentMethodMutation.mutate({id, provider}).pipe(
      map(res => res.data.syncAndGetPaymentMethod as unknown as PaymentMethod)
    )
  }
}
