import { ModelFactory } from "@shared/factories/model.factory";
import { AddressType } from "@generated/graphql";
import { Address } from "@modules/address/models/address.model";

export class AddressFactory extends ModelFactory<AddressType, Address> {
  create (state: AddressType): Address {
    return new Address(state);
  }

  fake (): Address {
    throw new Error('Method is not implemented');
  }
}

export const addressFactory = new AddressFactory();
