import { Injectable } from '@angular/core';
import { CountriesQuery, CountryType } from '@generated/graphql';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryApi {
  constructor (
    private countriesQuery: CountriesQuery,
  ) {
  }

  get (): Observable<CountryType[]> {
    return this.countriesQuery.watch().valueChanges.pipe(
      map(res => res.data.countries as CountryType[]),
    );
  }
}
