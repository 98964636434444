import { Injectable } from '@angular/core';
import {
  ConversationFilesQuery,
  ConverstationUploadMutation,
  DeleteConversationFileMutation,
  File as ServerFile,
  ManualSendNotificationMutation,
} from '@generated/graphql';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  public files$ = new BehaviorSubject<ServerFile[]>([]);

  public constructor(
    private _conversationFilesQuery: ConversationFilesQuery,
    private _conversationUploadMutation: ConverstationUploadMutation,
    private _deleteConversationFileMutation: DeleteConversationFileMutation,
    private _manualSendNotificationMutation: ManualSendNotificationMutation,
  ) { }

  public getFiles(firebaseId: string): Observable<ServerFile[]> {
    return this._conversationFilesQuery
      .fetch(
        {
          firebaseId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        map((response) => response.data.conversationFiles as ServerFile[]),
        tap((files) => this.files$.next(files)),
      );
  }

  public uploadFiles(
    firebaseId: string,
    files: File[],
  ): Observable<ServerFile[]> {
    return this._conversationUploadMutation
      .mutate(
        {
          firebaseId,
          files,
        },
        {
          context: {
            useMultipart: true,
          },
        },
      )
      .pipe(
        map((response) => response.data.conversationUpload as ServerFile[]),
      );
  }

  public deleteFile(fileId: string): Observable<boolean> {
    return this._deleteConversationFileMutation
      .mutate({
        fileId,
      })
      .pipe(map((response) => response.data.deleteConversationFile));
  }

  public manualSendNotification(
    jobId: string,
    senderId: string,
    receiverIds: string[] = [],
  ): Observable<boolean> {
    return this._manualSendNotificationMutation
      .mutate({
        jobId,
        senderId,
        receiverIds,
      })
      .pipe(map((response) => response.data.manualSendNotification));
  }
}
