import { Injectable } from '@angular/core';
import {
  ShareTypeEnum,
  ShareTypesQuery,
  ShareTypeType,
} from '@generated/graphql';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareTypeService {
  constructor(private shareTypesQuery: ShareTypesQuery) { }

  getAll(): Observable<ShareTypeType[]> {
    const shareTypes = this.shareTypesQuery
      .watch()
      .valueChanges.pipe(
        map((result) =>
          this.reorderSharetypes(result.data.shareTypes as ShareTypeType[]),
        ),
      );

    return shareTypes;
  }

  private reorderSharetypes(types: ShareTypeType[]): ShareTypeType[] {
    const reserveLenth = 3;
    const reserveOrder = {
      [ShareTypeEnum.Ordinary]: 0,
      [ShareTypeEnum.Preference]: 1,
      [ShareTypeEnum.Other]: 2,
    };

    const results: ShareTypeType[] = new Array(reserveLenth);

    types.forEach((t) => {
      const currentPos = reserveOrder[t.name.toUpperCase()] ?? -1;

      if (currentPos > -1) {
        results[currentPos] = t;
      } else {
        results.push(t);
      }
    });

    return results;
  }
}
