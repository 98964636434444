import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { AuthApi } from '@modules/auth/api/auth.api';
import { Store } from '@ngrx/store';
import { APP_ROUTES, AppRoutes } from '@config/app-routes.config';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthState } from '@modules/auth/store/reducers/auth.reducer';
import { ErrorService } from '@shared/services/error.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { resettingPasswordActions } from '@modules/auth/store/actions/resetting-password.actions';

@Injectable()
export class ResettingPasswordEffects {
  onSendLink$ = createEffect(() => this.actions$.pipe(
    ofType(resettingPasswordActions.sendLink.type),
    switchMap(({ email }: ReturnType<typeof resettingPasswordActions.sendLink>) =>
      this.authApi.sendResetPasswordsLink(email)
        .pipe(
          map(result => resettingPasswordActions.sendLinkSuccess({ result })),
          catchError(({ graphQLErrors }) => {
            const sendResetPasswordsLinkError = this.errorService.getValidationErrorObjectList(graphQLErrors);

            return of(resettingPasswordActions.sendLinkError({ sendResetPasswordsLinkError }));
          }),
        ),
    )));


  onResetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(resettingPasswordActions.resetPassword.type),
    switchMap(({ input }: ReturnType<typeof resettingPasswordActions.resetPassword>) =>
      this.authApi.resetPassword(input)
        .pipe(
          map(result => resettingPasswordActions.resetPasswordSuccess({ result })),
          catchError(({ graphQLErrors }) => {
            const resetPasswordErrorMessages = this.errorService.getValidationErrorMessages(graphQLErrors);

            return of(resettingPasswordActions.resetPasswordError({ resetPasswordErrorMessages }));
          }),
        ),
    )));

  constructor (
    @Inject(APP_ROUTES) private appRoutes: AppRoutes,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private errorService: ErrorService,
    private actions$: Actions,
    private store: Store,
    private authApi: AuthApi,
  ) {
  }
}


