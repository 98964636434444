import { ModelFactory } from '@shared/factories/model.factory';
import { PaymentType } from '@generated/graphql';
import { Payment } from '@modules/payment/models/payment.model';

export class PaymentFactory extends ModelFactory<PaymentType, Payment> {
  create (state: PaymentType): Payment {
    return new Payment(state);
  }

  fake (): Payment {
    throw new Error('Method not implemented yet!');
  }
}

export const paymentFactory = new PaymentFactory();
