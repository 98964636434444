import { Injectable } from "@angular/core";
import { BusinessPlanQuery, BusinessPlanType } from "@generated/graphql";
import { map, Observable } from "rxjs";
import { businessPlanFactory } from "@modules/account-settings/pages/business-profile/factories/business-plan.factory";
import { BusinessPlan } from "@modules/account-settings/pages/business-profile/models/business-plan.model";

@Injectable({
  providedIn: 'root'
})
export class BusinessPlanApi {
  constructor (
    protected readonly businessPlanQuery: BusinessPlanQuery
  ) {
  }

  first (): Observable<BusinessPlan> {
    return this.businessPlanQuery.fetch(null, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(res => res.data.businessPlan ? businessPlanFactory.create(res.data.businessPlan as BusinessPlanType) : null)
    )
  }
}
